import SessionTable from './SessionTable';
import SessionTypeTable from './SessionTypeTable';
import UpdateSessions from './UpdateSessions';

function MentorAvailability() {
	return (
		<div className="availability-container">
			<h1 className="mentor-availability-heading">
				Mentor Availability
			</h1>
			<div className="availability-sections">
				<div className="mentor-left">
					<UpdateSessions />
				</div>
				<div className="mentor-right">
					<SessionTable />
					<SessionTypeTable />
				</div>
			</div>
		</div>
	);
}

export default MentorAvailability;
