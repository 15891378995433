import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { services } from '../../../../apis';
import graduate from '../../../../assets/images/graduate.png';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import { fetchUpcomingOngoingSessions } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import FormModal from '../mentorSessions/FormModal';
import ConfirmGmeetModal from './ConfirmGmeetModal';
import DidntHappenModal from './DidntHappenModal';
import FinaliseQnsModal from './FinaliseQnsModal';

export enum SessionPlatform {
	INTERNAL_ZOOM = 'INTERNAL_ZOOM',
	EXTERNAL_GMEET = 'EXTERNAL_GMEET',
}

const LatestUpcomingSession = () => {
	const dispatch = useAppDispatch();

	const UserState = useSelector((state: any) => state.user);
	const { user } = UserState;

	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, upcomingOngoingSessions } = MentorState;

	interface Student {
		id: string;
		firstName: string;
		lastName: string;
		phoneNumber: string;
		email: string;
	}

	const studentInitialData = {
		id: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
	};

	const [open, setOpen] = useState(false);
	const [sessionsData, setSessionsData] =
		useState<any>(null);
	const [latestSession, setLatestSession] =
		useState<any>(null);
	const [showGmeet, setShowGmeet] = useState(false);
	const [showDidntHappen, setShowDidntHappen] =
		useState(false);
	// const [signature, setSignature] = useState(null);
	const [student, setStudent] = useState<Student>(
		studentInitialData
	);
	const [upcomingSessions, setUpcomingSessions] =
		useState<any>(null);
	const [confirmGmeet, setConfirmGmeet] = useState(false);
	const [showJoinNow, setShowJoinNow] = useState(false);
	const [reason, setReason] = useState<string>('');

	const [timeLeft, setTimeLeft] = useState<any>();
	const [startTimer, setStartTimer] =
		useState<boolean>(false);
	const [typeUser, setUserType] = useState('USER');
	const [openRandomQns, setOpenRandomQns] =
		useState<boolean>(false);
	const [newQns, setNewQns] = useState<any>(null);
	const [feedbackFormUrl, setFeedbackFormUrl] =
		useState<string>('');
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [selectedQns, setSelectedQns] = useState<any>(null);
	const [qnsFinalised, setQnsFinalised] =
		useState<boolean>(false);

	const [refresh, setRefresh] = useState<boolean>(false);

	const calculateTimeLeft = () => {
		//@ts-ignore
		const difference =
			//@ts-ignore
			new Date(latestSession.sessionStartTime) -
			//@ts-ignore
			new Date(Date.now());

		let timeLeft = {
			hours: 0,
			minutes: 0,
			seconds: 0,
		};

		if (difference > 0) {
			timeLeft = {
				hours: Math.floor(
					(difference / (1000 * 60 * 60)) % 24
				),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		}

		const { hours, minutes, seconds } = timeLeft;

		if (hours === 0 && minutes === 10 && seconds === 0) {
			setShowJoinNow(true);
			setRefresh(true);
		}

		if (hours === 0 && minutes === 0 && seconds === 1) {
			setShowJoinNow(true);
			setRefresh(true);
		}

		return timeLeft;
	};

	useEffect(() => {
		if (mentor !== null && upcomingOngoingSessions === null)
			dispatch(fetchUpcomingOngoingSessions());
	}, [mentor, upcomingOngoingSessions]);

	useEffect(() => {
		dispatch(fetchUpcomingOngoingSessions());
		if (refresh) setRefresh(false);
	}, [qnsFinalised, refresh]);

	useEffect(() => {
		if (upcomingOngoingSessions !== null) {
			setSessionsData(upcomingOngoingSessions);
		}
	}, [upcomingOngoingSessions]);

	useEffect(() => {
		const hasOngoingSessions =
			sessionsData &&
			Object.keys(sessionsData.ongoingSession).length > 0;
		const hasUpcomingSessions =
			sessionsData &&
			Object.keys(sessionsData.upcomingSessions).length > 0;

		if (hasOngoingSessions) {
			const user = sessionsData.ongoingSession.user;
			setStudent({ ...user });
			setLatestSession({ ...sessionsData.ongoingSession });
			if (hasUpcomingSessions) {
				if (sessionsData.upcomingSessions.length > 2)
					setUpcomingSessions([
						...sessionsData.upcomingSessions.slice(1, 3),
					]);
				else
					setUpcomingSessions([
						...sessionsData.upcomingSessions,
					]);
			}
			setStartTimer(true);
		} else if (hasUpcomingSessions) {
			const user = sessionsData.upcomingSessions[0].user;
			setStudent({ ...user });
			setLatestSession({
				...sessionsData.upcomingSessions[0],
			});
			if (sessionsData.upcomingSessions.length > 2)
				setUpcomingSessions([
					...sessionsData.upcomingSessions.slice(1, 3),
				]);
			else
				setUpcomingSessions([
					...sessionsData.upcomingSessions.slice(1),
				]);
			setStartTimer(true);
		} else {
			setLatestSession(null);
			setUpcomingSessions(null);
		}
	}, [sessionsData]);

	useEffect(() => {
		if (latestSession !== null) {
			if (
				latestSession.platform ===
				SessionPlatform.EXTERNAL_GMEET
			) {
				setShowJoinNow(false);
				setShowGmeet(false);
				setShowDidntHappen(true);
			} else {
				const isDuringSession = checkIsDuringSession(
					latestSession.sessionStartTime,
					latestSession.sessionDuration
				);
				setShowGmeet(isDuringSession);
				setShowDidntHappen(isDuringSession);
				const isSessionTime = checkJoiningTime(
					latestSession.sessionStartTime,
					latestSession.sessionDuration
				);
				setShowJoinNow(isSessionTime);
			}

			const hasNoSessionReason =
				latestSession.noSessionReason &&
				latestSession.noSessionReason !== null;
			if (hasNoSessionReason) {
				setShowGmeet(false);
				setShowDidntHappen(false);
				setShowJoinNow(false);
			}
		}
	}, [latestSession]);

	useEffect(() => {
		if (
			latestSession !== null &&
			Object.keys(latestSession).length > 0
		)
			setTimeout(
				() => setTimeLeft(calculateTimeLeft()),
				1000
			);
	}, [startTimer, timeLeft]);

	const openSessionDidntHappen = () => setOpen(true);

	const handleSessionDidntHappen = async (
		sessionId: string
	) => {
		const body = { noSessionReason: reason };
		console.log('body', body);
		const data = await services.mentorService.markDidntShow(
			sessionId,
			body
		);
		if (data && data.statusCode) notifyError(data.message);
		else {
			notifySuccess(data.message);
			setShowDidntHappen(false);
			setShowJoinNow(false);
			setShowGmeet(false);
		}
		setOpen(false);
	};

	const handleClose = () => setOpen(false);

	const joinNow = async () => {
		const typeUser =
			user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
		const userType = typeUser === 'MENTOR' ? 1 : 11;
		// console.log(room, 'sessionkey', typeof room);
		// const role = userType==1?1:0;
		const role = 1;
		const userId = user.id;
		const room = latestSession.roomId;
		const data =
			await services.sessionService.fetchZoomVideoLink(
				room,
				user.id,
				userType,
				{
					sessionName: room,
					role: role,
					sessionKey: room,
					userIdentity: user.email,
				}
			);

		if (data.joinLink) {
			window.open(data.joinLink, '_blank');
		} else {
			window.open(
				`https://meeting.acciojob.com/video?topic=${room}&role=${role}&sessionKey=${room}&userIdentity=${user.email}&signature=${data}&name=${user.email}`,
				'_blank'
			);
		}
	};

	const handleJoinNow = () => {
		const qnsFinalised =
			latestSession.selectedQuestions !== null;
		if (qnsFinalised) joinNow();
		else {
			setOpenRandomQns(true);
		}
	};

	const handleShiftedToGmeet = () => setConfirmGmeet(true);

	const handleConfirmGmeet = async () => {
		const data =
			await services.mentorService.markShiftedToGmeet(
				latestSession.sessionId
			);
		if (data && data.statusCode) notifyError(data.massage);
		else {
			notifySuccess(data.message);
			setConfirmGmeet(false);
			setShowJoinNow(false);
		}
	};

	const handleCloseGmeet = () => setConfirmGmeet(false);

	const checkIsDuringSession = (
		sessionDate: Date,
		sessionDuration: string
	) => {
		const startingTime = new Date(sessionDate);
		const endingTime = moment(sessionDate)
			.add(sessionDuration, 'm')
			.toDate();
		return (
			endingTime >= new Date(Date.now()) &&
			startingTime <= new Date(Date.now())
		);
	};

	const checkJoiningTime = (
		sessionDate: Date,
		sessionDuration: string
	) => {
		const lessThanTenminToStart = moment(sessionDate)
			.subtract(10, 'm')
			.toDate();
		const newSessionDuration = (
			+sessionDuration + 10
		).toString();
		const isDuringSession = checkIsDuringSession(
			lessThanTenminToStart,
			newSessionDuration
		);
		return isDuringSession;
	};

	const handleCloseRandomQns = () =>
		setOpenRandomQns(false);

	const handleFillFeedback = (url: string) => {
		const selectedQns = latestSession.selectedQuestions;
		if (
			selectedQns !== null &&
			Object.keys(selectedQns).length > 0
		) {
			setFeedbackFormUrl(url);
			setOpenForm(true);
			setSelectedQns(selectedQns);
		} else {
			setOpenRandomQns(true);
		}
	};

	const handleFormClose = () => setOpenForm(false);

	const checkShowFeedback = (): boolean => {
		return (
			checkIsDuringSession(
				latestSession.sessionStartTime,
				latestSession.sessionDuration
			) &&
			latestSession.noSessionReason === null &&
			!(
				latestSession.mentorFeedback['createdAt'] ||
				latestSession.mentorFeedback['created_at']
			)
		);
	};

	return (
		<>
			{open && (
				<DidntHappenModal
					open={open}
					handleClose={handleClose}
					session={latestSession}
					setReason={setReason}
					handleSessionDidntHappen={
						handleSessionDidntHappen
					}
				/>
			)}

			{confirmGmeet && (
				<ConfirmGmeetModal
					confirmGmeet={confirmGmeet}
					handleCloseGmeet={handleCloseGmeet}
					handleConfirmGmeet={handleConfirmGmeet}
				/>
			)}

			{openRandomQns && (
				<FinaliseQnsModal
					setQnsFinalised={setQnsFinalised}
					openRandomQns={openRandomQns}
					handleCloseRandomQns={handleCloseRandomQns}
					joinNow={joinNow}
					sessionId={latestSession.sessionId}
				/>
			)}

			{openForm && (
				<FormModal
					openForm={openForm}
					url={feedbackFormUrl}
					handleFormClose={handleFormClose}
					selectedQns={selectedQns}
				/>
			)}

			{latestSession ? (
				<div className="latest-upcoming-session-container flex-row-sb">
					<div className="latest-upcoming-session-left">
						<div className="flex-row-sb session-heading">
							<div style={{ textAlign: 'center' }}>
								<span>
									{timeLeft &&
									timeLeft.hours === 0 &&
									timeLeft.minutes === 0 &&
									timeLeft.seconds === 0
										? 'ONGOING SESSION'
										: 'UPCOMING SESSION'}
								</span>{' '}
								<br></br>
								<span>{latestSession.sessionType}</span>
							</div>

							<div style={{ textAlign: 'center' }}>
								<span>Session Start Time</span> <br></br>
								<span>
									{moment(
										latestSession.sessionStartTime
									).format('hh:mm A')}
								</span>
							</div>

							<div style={{ textAlign: 'center' }}>
								<span>Duration</span> <br></br>
								<span>
									{latestSession.sessionDuration} min
								</span>
							</div>
						</div>
						<div className="student-info-container">
							<div className="student-info-left">
								<img
									src={graduate}
									className="student-icon"
								/>
							</div>
							<div className="student-info-right">
								<h6 className="student-name">
									{student.firstName +
										' ' +
										student.lastName}
								</h6>
								<h6 className="student-contact">
									{student.phoneNumber}
								</h6>
								{/* <div className='student-profile'>Student Profile</div> */}
							</div>
						</div>
						{/* <button className="block fix-mock-qns">Fix Mock Question</button> */}
						{checkShowFeedback() && showDidntHappen && (
							<a
								className="feedback-form-link"
								onClick={() =>
									handleFillFeedback(
										latestSession.mentorFeedbackFormLink
									)
								}
							>
								Get feedback form link
							</a>
						)}
					</div>

					<div className="latest-upcoming-session-right">
						<div>
							<a
								className="feedback-form-link"
								href="https://docs.google.com/document/d/1dRt00ZZftxIyKPfxsBvH0ywgMEQyLU-FbjTc6uaNhi0/edit"
								target={'_blank'}
							>
								Session GuideLines
							</a>
						</div>
						<div>
							{checkIsDuringSession(
								latestSession.sessionStartTime,
								latestSession.sessionDuration
							) ? (
								<b>Session is live</b>
							) : (
								<>
									Session starts in <br />
									{timeLeft && (
										<b>
											<span className="value">
												{String(timeLeft.hours).padStart(
													2,
													'0'
												)}
												h{' '}
											</span>
											<span className="value">
												{String(timeLeft.minutes).padStart(
													2,
													'0'
												)}
												m{' '}
											</span>
											<span className="value">
												{String(timeLeft.seconds).padStart(
													2,
													'0'
												)}
												s{' '}
											</span>
										</b>
									)}
								</>
							)}
						</div>
						{showJoinNow && (
							<button
								className="block"
								onClick={handleJoinNow}
							>
								Join Now
							</button>
						)}
						{showGmeet && (
							<button
								className="block"
								onClick={handleShiftedToGmeet}
							>
								Shifted to G-meet
							</button>
						)}
						{showDidntHappen && (
							<button
								className="block"
								onClick={openSessionDidntHappen}
							>
								Session Didn't Happen
							</button>
						)}
					</div>
				</div>
			) : (
				<div className="latest-upcoming-session-container">
					<div className="flex-row-sb session-heading">
						<span>NO UPCOMING SESSIONS</span>
					</div>
				</div>
			)}

			{upcomingSessions && (
				<div>
					{upcomingSessions.map((session: any) => (
						<div className="upcoming-session-container">
							<h3 className="upcoming-session-type">
								{session.sessionType}
							</h3>
							<div className="upcoming-session-top">
								<span className="upcoming-session-duration">
									Duration:{' '}
									<b> {session.sessionDuration}min</b>
								</span>
								<span className="upcoming-session-date">
									Date:{' '}
									<b>
										{moment(
											session.sessionStartTime
										).format('DD:MM:YYYY')}
									</b>
								</span>
								<span className="upcoming-session-time">
									Starting Time:{' '}
									<b>
										{moment(
											session.sessionStartTime
										).format('hh:mm A')}
									</b>
								</span>
							</div>
							<div className="upcoming-session-bottom">
								<img
									src={graduate}
									className="student-icon"
								/>
								<div>
									<h6>
										{session.user.firstName +
											' ' +
											session.user.lastName}
									</h6>
									<p>{session.user.phoneNumber}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default LatestUpcomingSession;
