import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfileBlock from './ProfileBlock';
import FieldBlock from './FieldBlock';
import CustomDropdown from '../shared/Dropdown';
import InputField from '../shared/InputField';
import { fetchPlacementProfile } from '../../redux/user/userSlice';
import { useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { city } from '../../pages/profile/cities';
import UploadButton from '../../pages/fileSubmissions/FileUploadbutton';
import FileUploadButton from './FileUploadButton';
import { services } from '../../apis';
import { toast } from 'react-toastify';
import AccioButton from '../elements/Button';
// import { notifySuccess } from '../notification';

interface Option {
	[x: string]: any;
	value: string | number;
	label: string;
}

type Options = Option | Option[];

interface CityOptions {
	[key: string]: Option[];
}

const ProfileTab2 = () => {
	const notifySuccess = (msg: string) => {
		toast.success(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const notifyFailure = (msg: string) => {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const cityOptions: CityOptions = {
		'Andhra Pradesh': [
			{ value: 'Anantapur', label: 'Anantapur' },
			{ value: 'Chittoor', label: 'Chittoor' },
			{ value: 'East Godavari', label: 'East Godavari' },
			{ value: 'Guntur', label: 'Guntur' },
			{ value: 'Krishna', label: 'Krishna' },
			{ value: 'Kurnool', label: 'Kurnool' },
			{ value: 'Nellore', label: 'Nellore' },
			{ value: 'Prakasam', label: 'Prakasam' },
			{ value: 'Srikakulam', label: 'Srikakulam' },
			{ value: 'Visakhapatnam', label: 'Visakhapatnam' },
			{ value: 'Vizianagaram', label: 'Vizianagaram' },
			{ value: 'West Godavari', label: 'West Godavari' },
			{ value: 'YSR Kadapa', label: 'YSR Kadapa' },
		],
		'Arunachal Pradesh': [
			{ value: 'Anantapur', label: 'Anantapur' },
			{ value: 'Chittoor', label: 'Chittoor' },
			{ value: 'East Godavari', label: 'East Godavari' },
			{ value: 'Guntur', label: 'Guntur' },
			{ value: 'Krishna', label: 'Krishna' },
			{ value: 'Kurnool', label: 'Kurnool' },
			{ value: 'Nellore', label: 'Nellore' },
			{ value: 'Prakasam', label: 'Prakasam' },
			{ value: 'Srikakulam', label: 'Srikakulam' },
			{ value: 'Visakhapatnam', label: 'Visakhapatnam' },
			{ value: 'Vizianagaram', label: 'Vizianagaram' },
			{ value: 'West Godavari', label: 'West Godavari' },
			{ value: 'YSR Kadapa', label: 'YSR Kadapa' },
			{ value: 'Tawang', label: 'Tawang' },
			{ value: 'West Kameng', label: 'West Kameng' },
			{ value: 'East Kameng', label: 'East Kameng' },
			{ value: 'Papum Pare', label: 'Papum Pare' },
			{ value: 'Kurung Kumey', label: 'Kurung Kumey' },
			{ value: 'Kra Daadi', label: 'Kra Daadi' },
			{
				value: 'Lower Subansiri',
				label: 'Lower Subansiri',
			},
			{
				value: 'Upper Subansiri',
				label: 'Upper Subansiri',
			},
			{ value: 'West Siang', label: 'West Siang' },
			{ value: 'East Siang', label: 'East Siang' },
			{ value: 'Siang', label: 'Siang' },
			{ value: 'Upper Siang', label: 'Upper Siang' },
			{ value: 'Lower Siang', label: 'Lower Siang' },
			{
				value: 'Lower Dibang Valley',
				label: 'Lower Dibang Valley',
			},
			{ value: 'Dibang Valley', label: 'Dibang Valley' },
			{ value: 'Anjaw', label: 'Anjaw' },
			{ value: 'Lohit', label: 'Lohit' },
			{ value: 'Namsai', label: 'Namsai' },
			{ value: 'Changlang', label: 'Changlang' },
			{ value: 'Tirap', label: 'Tirap' },
			{ value: 'Longding', label: 'Longding' },
		],
		Assam: [
			{ value: 'Baksa', label: 'Baksa' },
			{ value: 'Barpeta', label: 'Barpeta' },
			{ value: 'Biswanath', label: 'Biswanath' },
			{ value: 'Bongaigaon', label: 'Bongaigaon' },
			{ value: 'Cachar', label: 'Cachar' },
			{ value: 'Charaideo', label: 'Charaideo' },
			{ value: 'Chirang', label: 'Chirang' },
			{ value: 'Darrang', label: 'Darrang' },
			{ value: 'Dhemaji', label: 'Dhemaji' },
			{ value: 'Dhubri', label: 'Dhubri' },
			{ value: 'Dibrugarh', label: 'Dibrugarh' },
			{ value: 'Goalpara', label: 'Goalpara' },
			{ value: 'Golaghat', label: 'Golaghat' },
			{ value: 'Hailakandi', label: 'Hailakandi' },
			{ value: 'Hojai', label: 'Hojai' },
			{ value: 'Jorhat', label: 'Jorhat' },
			{
				value: 'Kamrup Metropolitan',
				label: 'Kamrup Metropolitan',
			},
			{ value: 'Kamrup', label: 'Kamrup' },
			{ value: 'Karbi Anglong', label: 'Karbi Anglong' },
			{ value: 'Karimganj', label: 'Karimganj' },
			{ value: 'Kokrajhar', label: 'Kokrajhar' },
			{ value: 'Lakhimpur', label: 'Lakhimpur' },
			{ value: 'Majuli', label: 'Majuli' },
			{ value: 'Morigaon', label: 'Morigaon' },
			{ value: 'Nagaon', label: 'Nagaon' },
			{ value: 'Nalbari', label: 'Nalbari' },
			{ value: 'Dima Hasao', label: 'Dima Hasao' },
			{ value: 'Sivasagar', label: 'Sivasagar' },
			{ value: 'Sonitpur', label: 'Sonitpur' },
			{
				value: 'South Salmara-Mankachar',
				label: 'South Salmara-Mankachar',
			},
			{ value: 'Tinsukia', label: 'Tinsukia' },
			{ value: 'Udalguri', label: 'Udalguri' },
			{
				value: 'West Karbi Anglong',
				label: 'West Karbi Anglong',
			},
		],
		Bihar: [
			{ value: 'Araria', label: 'Araria' },
			{ value: 'Arwal', label: 'Arwal' },
			{ value: 'Aurangabad', label: 'Aurangabad' },
			{ value: 'Banka', label: 'Banka' },
			{ value: 'Begusarai', label: 'Begusarai' },
			{ value: 'Bhagalpur', label: 'Bhagalpur' },
			{ value: 'Bhojpur', label: 'Bhojpur' },
			{ value: 'Buxar', label: 'Buxar' },
			{ value: 'Darbhanga', label: 'Darbhanga' },
			{
				value: 'East Champaran (Motihari)',
				label: 'East Champaran (Motihari)',
			},
			{ value: 'Gaya', label: 'Gaya' },
			{ value: 'Gopalganj', label: 'Gopalganj' },
			{ value: 'Jamui', label: 'Jamui' },
			{ value: 'Jehanabad', label: 'Jehanabad' },
			{
				value: 'Kaimur (Bhabua)',
				label: 'Kaimur (Bhabua)',
			},
			{ value: 'Katihar', label: 'Katihar' },
			{ value: 'Khagaria', label: 'Khagaria' },
			{ value: 'Kishanganj', label: 'Kishanganj' },
			{ value: 'Lakhisarai', label: 'Lakhisarai' },
			{ value: 'Madhepura', label: 'Madhepura' },
			{ value: 'Madhubani', label: 'Madhubani' },
			{
				value: 'Munger (Monghyr)',
				label: 'Munger (Monghyr)',
			},
			{ value: 'Muzaffarpur', label: 'Muzaffarpur' },
			{ value: 'Nalanda', label: 'Nalanda' },
			{ value: 'Nawada', label: 'Nawada' },
			{ value: 'Patna', label: 'Patna' },
			{
				value: 'Purnia (Purnea)',
				label: 'Purnia (Purnea)',
			},
			{ value: 'Rohtas', label: 'Rohtas' },
			{ value: 'Saharsa', label: 'Saharsa' },
			{ value: 'Samastipur', label: 'Samastipur' },
			{ value: 'Saran', label: 'Saran' },
			{ value: 'Sheikhpura', label: 'Sheikhpura' },
			{ value: 'Sheohar', label: 'Sheohar' },
			{ value: 'Sitamarhi', label: 'Sitamarhi' },
			{ value: 'Siwan', label: 'Siwan' },
			{ value: 'Supaul', label: 'Supaul' },
			{ value: 'Vaishali', label: 'Vaishali' },
			{ value: 'West Champaran', label: 'West Champaran' },
		],
		Chandigarh: [
			{ value: 'Chandigarh', label: 'Chandigarh' },
		],
		Chhattisgarh: [
			{ value: 'Balod', label: 'Balod' },
			{ value: 'Baloda Bazar', label: 'Baloda Bazar' },
			{ value: 'Balrampur', label: 'Balrampur' },
			{ value: 'Bastar', label: 'Bastar' },
			{ value: 'Bemetara', label: 'Bemetara' },
			{ value: 'Bijapur', label: 'Bijapur' },
			{ value: 'Bilaspur', label: 'Bilaspur' },
			{
				value: 'Dantewada (South Bastar)',
				label: 'Dantewada (South Bastar)',
			},
			{ value: 'Dhamtari', label: 'Dhamtari' },
			{ value: 'Durg', label: 'Durg' },
			{ value: 'Gariyaband', label: 'Gariyaband' },
			{ value: 'Janjgir-Champa', label: 'Janjgir-Champa' },
			{ value: 'Jashpur', label: 'Jashpur' },
			{
				value: 'Kabirdham (Kawardha)',
				label: 'Kabirdham (Kawardha)',
			},
			{
				value: 'Kanker (North Bastar)',
				label: 'Kanker (North Bastar)',
			},
			{ value: 'Kondagaon', label: 'Kondagaon' },
			{ value: 'Korba', label: 'Korba' },
			{ value: 'Korea (Koriya)', label: 'Korea (Koriya)' },
			{ value: 'Mahasamund', label: 'Mahasamund' },
			{ value: 'Mungeli', label: 'Mungeli' },
			{ value: 'Narayanpur', label: 'Narayanpur' },
			{ value: 'Raigarh', label: 'Raigarh' },
			{ value: 'Raipur', label: 'Raipur' },
			{ value: 'Rajnandgaon', label: 'Rajnandgaon' },
			{ value: 'Sukma', label: 'Sukma' },
			{ value: 'Surajpur  ', label: 'Surajpur  ' },
			{ value: 'Surguja', label: 'Surguja' },
		],
		'Dadra Diu': [
			{
				value: 'Dadra & Nagar Haveli',
				label: 'Dadra & Nagar Haveli',
			},
			{ value: 'Daman', label: 'Daman' },
			{ value: 'Diu', label: 'Diu' },
		],
		'Andaman and Nicobar Islands': [
			{
				value: 'Andaman And Nicobar Islands',
				label: 'Andaman And Nicobar Islands',
			},
		],
		Delhi: [
			{ value: 'Central Delhi', label: 'Central Delhi' },
			{ value: 'East Delhi', label: 'East Delhi' },
			{ value: 'New Delhi', label: 'New Delhi' },
			{ value: 'North Delhi', label: 'North Delhi' },
			{
				value: 'North East  Delhi',
				label: 'North East  Delhi',
			},
			{
				value: 'North West  Delhi',
				label: 'North West  Delhi',
			},
			{ value: 'Shahdara', label: 'Shahdara' },
			{ value: 'South Delhi', label: 'South Delhi' },
			{
				value: 'South East Delhi',
				label: 'South East Delhi',
			},
			{
				value: 'South West  Delhi',
				label: 'South West  Delhi',
			},
			{ value: 'West Delhi', label: 'West Delhi' },
		],
		Goa: [
			{ value: 'North Goa', label: 'North Goa' },
			{ value: 'South Goa', label: 'South Goa' },
		],
		Gujarat: [
			{ value: 'Ahmedabad', label: 'Ahmedabad' },
			{ value: 'Amreli', label: 'Amreli' },
			{ value: 'Anand', label: 'Anand' },
			{ value: 'Aravalli', label: 'Aravalli' },
			{
				value: 'Banaskantha (Palanpur)',
				label: 'Banaskantha (Palanpur)',
			},
			{ value: 'Bharuch', label: 'Bharuch' },
			{ value: 'Bhavnagar', label: 'Bhavnagar' },
			{ value: 'Botad', label: 'Botad' },
			{ value: 'Chhota Udepur', label: 'Chhota Udepur' },
			{ value: 'Dahod', label: 'Dahod' },
			{ value: 'Dangs (Ahwa)', label: 'Dangs (Ahwa)' },
			{
				value: 'Devbhoomi Dwarka',
				label: 'Devbhoomi Dwarka',
			},
			{ value: 'Gandhinagar', label: 'Gandhinagar' },
			{ value: 'Gir Somnath', label: 'Gir Somnath' },
			{ value: 'Jamnagar', label: 'Jamnagar' },
			{ value: 'Junagadh', label: 'Junagadh' },
			{ value: 'Kachchh', label: 'Kachchh' },
			{ value: 'Kheda (Nadiad)', label: 'Kheda (Nadiad)' },
			{ value: 'Mahisagar', label: 'Mahisagar' },
			{ value: 'Mehsana', label: 'Mehsana' },
			{ value: 'Morbi', label: 'Morbi' },
			{
				value: 'Narmada (Rajpipla)',
				label: 'Narmada (Rajpipla)',
			},
			{ value: 'Navsari', label: 'Navsari' },
			{
				value: 'Panchmahal (Godhra)',
				label: 'Panchmahal (Godhra)',
			},
			{ value: 'Patan', label: 'Patan' },
			{ value: 'Porbandar', label: 'Porbandar' },
			{ value: 'Rajkot', label: 'Rajkot' },
			{
				value: 'Sabarkantha (Himmatnagar)',
				label: 'Sabarkantha (Himmatnagar)',
			},
			{ value: 'Surat', label: 'Surat' },
			{ value: 'Surendranagar', label: 'Surendranagar' },
			{ value: 'Tapi (Vyara)', label: 'Tapi (Vyara)' },
			{ value: 'Vadodara', label: 'Vadodara' },
			{ value: 'Valsad', label: 'Valsad' },
		],
		Haryana: [
			{ value: 'Ambala', label: 'Ambala' },
			{ value: 'Bhiwani', label: 'Bhiwani' },
			{ value: 'Charkhi Dadri', label: 'Charkhi Dadri' },
			{ value: 'Faridabad', label: 'Faridabad' },
			{ value: 'Fatehabad', label: 'Fatehabad' },
			{ value: 'Gurgaon', label: 'Gurgaon' },
			{ value: 'Hisar', label: 'Hisar' },
			{ value: 'Jhajjar', label: 'Jhajjar' },
			{ value: 'Jind', label: 'Jind' },
			{ value: 'Kaithal', label: 'Kaithal' },
			{ value: 'Karnal', label: 'Karnal' },
			{ value: 'Kurukshetra', label: 'Kurukshetra' },
			{ value: 'Mahendragarh', label: 'Mahendragarh' },
			{ value: 'Mewat', label: 'Mewat' },
			{ value: 'Palwal', label: 'Palwal' },
			{ value: 'Panchkula', label: 'Panchkula' },
			{ value: 'Panipat', label: 'Panipat' },
			{ value: 'Rewari', label: 'Rewari' },
			{ value: 'Rohtak', label: 'Rohtak' },
			{ value: 'Sirsa', label: 'Sirsa' },
			{ value: 'Sonipat', label: 'Sonipat' },
			{ value: 'Yamunanagar', label: 'Yamunanagar' },
		],
		'Himachal Pradesh': [
			{ value: 'Bilaspur', label: 'Bilaspur' },
			{ value: 'Chamba', label: 'Chamba' },
			{ value: 'Hamirpur', label: 'Hamirpur' },
			{ value: 'Kangra', label: 'Kangra' },
			{ value: 'Kinnaur', label: 'Kinnaur' },
			{ value: 'Kullu', label: 'Kullu' },
			{
				value: 'Lahaul &amp; Spiti',
				label: 'Lahaul &amp; Spiti',
			},
			{ value: 'Mandi', label: 'Mandi' },
			{ value: 'Shimla', label: 'Shimla' },
			{
				value: 'Sirmaur (Sirmour)',
				label: 'Sirmaur (Sirmour)',
			},
			{ value: 'Solan', label: 'Solan' },
			{ value: 'Una', label: 'Una' },
		],
		'Jammu and Kashmir': [
			{ value: 'Anantnag', label: 'Anantnag' },
			{ value: 'Bandipore', label: 'Bandipore' },
			{ value: 'Baramulla', label: 'Baramulla' },
			{ value: 'Budgam', label: 'Budgam' },
			{ value: 'Doda', label: 'Doda' },
			{ value: 'Ganderbal', label: 'Ganderbal' },
			{ value: 'Jammu', label: 'Jammu' },
			{ value: 'Kargil', label: 'Kargil' },
			{ value: 'Kathua', label: 'Kathua' },
			{ value: 'Kishtwar', label: 'Kishtwar' },
			{ value: 'Kulgam', label: 'Kulgam' },
			{ value: 'Kupwara', label: 'Kupwara' },
			{ value: 'Leh', label: 'Leh' },
			{ value: 'Poonch', label: 'Poonch' },
			{ value: 'Pulwama', label: 'Pulwama' },
			{ value: 'Rajouri', label: 'Rajouri' },
			{ value: 'Ramban', label: 'Ramban' },
			{ value: 'Reasi', label: 'Reasi' },
			{ value: 'Samba', label: 'Samba' },
			{ value: 'Shopian', label: 'Shopian' },
			{ value: 'Srinagar', label: 'Srinagar' },
			{ value: 'Udhampur', label: 'Udhampur' },
		],
		Jharkhand: [
			{ value: 'Bokaro', label: 'Bokaro' },
			{ value: 'Chatra', label: 'Chatra' },
			{ value: 'Deoghar', label: 'Deoghar' },
			{ value: 'Dhanbad', label: 'Dhanbad' },
			{ value: 'Dumka', label: 'Dumka' },
			{ value: 'East Singhbhum', label: 'East Singhbhum' },
			{ value: 'Garhwa', label: 'Garhwa' },
			{ value: 'Giridih', label: 'Giridih' },
			{ value: 'Godda', label: 'Godda' },
			{ value: 'Gumla', label: 'Gumla' },
			{ value: 'Hazaribag', label: 'Hazaribag' },
			{ value: 'Jamtara', label: 'Jamtara' },
			{ value: 'Khunti', label: 'Khunti' },
			{ value: 'Koderma', label: 'Koderma' },
			{ value: 'Latehar', label: 'Latehar' },
			{ value: 'Lohardaga', label: 'Lohardaga' },
			{ value: 'Pakur', label: 'Pakur' },
			{ value: 'Palamu', label: 'Palamu' },
			{ value: 'Ramgarh', label: 'Ramgarh' },
			{ value: 'Ranchi', label: 'Ranchi' },
			{ value: 'Sahibganj', label: 'Sahibganj' },
			{
				value: 'Seraikela-Kharsawan',
				label: 'Seraikela-Kharsawan',
			},
			{ value: 'Simdega', label: 'Simdega' },
			{ value: 'West Singhbhum', label: 'West Singhbhum' },
		],
		Karnataka: [
			{ value: 'Bagalkot', label: 'Bagalkot' },
			{
				value: 'Ballari (Bellary)',
				label: 'Ballari (Bellary)',
			},
			{
				value: 'Belagavi (Belgaum)',
				label: 'Belagavi (Belgaum)',
			},
			{
				value: 'Bengaluru (Bangalore) Rural',
				label: 'Bengaluru (Bangalore) Rural',
			},
			{
				value: 'Bengaluru (Bangalore) Urban',
				label: 'Bengaluru (Bangalore) Urban',
			},
			{ value: 'Bidar', label: 'Bidar' },
			{ value: 'Chamarajanagar', label: 'Chamarajanagar' },
			{ value: 'Chikballapur', label: 'Chikballapur' },
			{
				value: 'Chikkamagaluru (Chikmagalur)',
				label: 'Chikkamagaluru (Chikmagalur)',
			},
			{ value: 'Chitradurga', label: 'Chitradurga' },
			{
				value: 'Dakshina Kannada',
				label: 'Dakshina Kannada',
			},
			{ value: 'Davangere', label: 'Davangere' },
			{ value: 'Dharwad', label: 'Dharwad' },
			{ value: 'Gadag', label: 'Gadag' },
			{ value: 'Hassan', label: 'Hassan' },
			{ value: 'Haveri', label: 'Haveri' },
			{
				value: 'Kalaburagi (Gulbarga)',
				label: 'Kalaburagi (Gulbarga)',
			},
			{ value: 'Kodagu', label: 'Kodagu' },
			{ value: 'Kolar', label: 'Kolar' },
			{ value: 'Koppal', label: 'Koppal' },
			{ value: 'Mandya', label: 'Mandya' },
			{
				value: 'Mysuru (Mysore)',
				label: 'Mysuru (Mysore)',
			},
			{ value: 'Raichur', label: 'Raichur' },
			{ value: 'Ramanagara', label: 'Ramanagara' },
			{
				value: 'Shivamogga (Shimoga)',
				label: 'Shivamogga (Shimoga)',
			},
			{
				value: 'Tumakuru (Tumkur)',
				label: 'Tumakuru (Tumkur)',
			},
			{ value: 'Udupi', label: 'Udupi' },
			{
				value: 'Uttara Kannada (Karwar)',
				label: 'Uttara Kannada (Karwar)',
			},
			{
				value: 'Vijayapura (Bijapur)',
				label: 'Vijayapura (Bijapur)',
			},
			{ value: 'Yadgir', label: 'Yadgir' },
		],
		Ladakh: [
			{ value: 'Leh', label: 'Leh' },
			{ value: 'Ladakh', label: 'Ladakh' },
		],
		Kerala: [
			{ value: 'Alappuzha', label: 'Alappuzha' },
			{ value: 'Ernakulam', label: 'Ernakulam' },
			{ value: 'Idukki', label: 'Idukki' },
			{ value: 'Kannur', label: 'Kannur' },
			{ value: 'Kasaragod', label: 'Kasaragod' },
			{ value: 'Kollam', label: 'Kollam' },
			{ value: 'Kottayam', label: 'Kottayam' },
			{ value: 'Kozhikode', label: 'Kozhikode' },
			{ value: 'Malappuram', label: 'Malappuram' },
			{ value: 'Palakkad', label: 'Palakkad' },
			{ value: 'Pathanamthitta', label: 'Pathanamthitta' },
			{
				value: 'Thiruvananthapuram',
				label: 'Thiruvananthapuram',
			},
			{ value: 'Thrissur', label: 'Thrissur' },
			{ value: 'Wayanad', label: 'Wayanad' },
		],
		Lakshadweep: [
			{ value: 'Agatti', label: 'Agatti' },
			{ value: 'Amini', label: 'Amini' },
			{ value: 'Androth', label: 'Androth' },
			{ value: 'Bithra', label: 'Bithra' },
			{ value: 'Chethlath', label: 'Chethlath' },
			{ value: 'Kavaratti', label: 'Kavaratti' },
			{ value: 'Kadmath', label: 'Kadmath' },
			{ value: 'Kalpeni', label: 'Kalpeni' },
			{ value: 'Kilthan', label: 'Kilthan' },
			{ value: 'Minicoy', label: 'Minicoy' },
		],
		'Madhya Pradesh': [
			{ value: 'Agar Malwa', label: 'Agar Malwa' },
			{ value: 'Alirajpur', label: 'Alirajpur' },
			{ value: 'Anuppur', label: 'Anuppur' },
			{ value: 'Ashoknagar', label: 'Ashoknagar' },
			{ value: 'Balaghat', label: 'Balaghat' },
			{ value: 'Barwani', label: 'Barwani' },
			{ value: 'Betul', label: 'Betul' },
			{ value: 'Bhind', label: 'Bhind' },
			{ value: 'Bhopal', label: 'Bhopal' },
			{ value: 'Burhanpur', label: 'Burhanpur' },
			{ value: 'Chhatarpur', label: 'Chhatarpur' },
			{ value: 'Chhindwara', label: 'Chhindwara' },
			{ value: 'Damoh', label: 'Damoh' },
			{ value: 'Datia', label: 'Datia' },
			{ value: 'Dewas', label: 'Dewas' },
			{ value: 'Dhar', label: 'Dhar' },
			{ value: 'Dindori', label: 'Dindori' },
			{ value: 'Guna', label: 'Guna' },
			{ value: 'Gwalior', label: 'Gwalior' },
			{ value: 'Harda', label: 'Harda' },
			{ value: 'Hoshangabad', label: 'Hoshangabad' },
			{ value: 'Indore', label: 'Indore' },
			{ value: 'Jabalpur', label: 'Jabalpur' },
			{ value: 'Jhabua', label: 'Jhabua' },
			{ value: 'Katni', label: 'Katni' },
			{ value: 'Khandwa', label: 'Khandwa' },
			{ value: 'Khargone', label: 'Khargone' },
			{ value: 'Mandla', label: 'Mandla' },
			{ value: 'Mandsaur', label: 'Mandsaur' },
			{ value: 'Morena', label: 'Morena' },
			{ value: 'Narsinghpur', label: 'Narsinghpur' },
			{ value: 'Neemuch', label: 'Neemuch' },
			{ value: 'Panna', label: 'Panna' },
			{ value: 'Raisen', label: 'Raisen' },
			{ value: 'Rajgarh', label: 'Rajgarh' },
			{ value: 'Ratlam', label: 'Ratlam' },
			{ value: 'Rewa', label: 'Rewa' },
			{ value: 'Sagar', label: 'Sagar' },
			{ value: 'Satna', label: 'Satna' },
			{ value: 'Sehore', label: 'Sehore' },
			{ value: 'Seoni', label: 'Seoni' },
			{ value: 'Shahdol', label: 'Shahdol' },
			{ value: 'Shajapur', label: 'Shajapur' },
			{ value: 'Sheopur', label: 'Sheopur' },
			{ value: 'Shivpuri', label: 'Shivpuri' },
			{ value: 'Sidhi', label: 'Sidhi' },
			{ value: 'Singrauli', label: 'Singrauli' },
			{ value: 'Tikamgarh', label: 'Tikamgarh' },
			{ value: 'Ujjain', label: 'Ujjain' },
			{ value: 'Umaria', label: 'Umaria' },
			{ value: 'Vidisha', label: 'Vidisha' },
		],
		Maharashtra: [
			{ value: 'Ahmednagar', label: 'Ahmednagar' },
			{ value: 'Akola', label: 'Akola' },
			{ value: 'Amravati', label: 'Amravati' },
			{ value: 'Aurangabad', label: 'Aurangabad' },
			{ value: 'Beed', label: 'Beed' },
			{ value: 'Bhandara', label: 'Bhandara' },
			{ value: 'Buldhana', label: 'Buldhana' },
			{ value: 'Chandrapur', label: 'Chandrapur' },
			{ value: 'Dhule', label: 'Dhule' },
			{ value: 'Gadchiroli', label: 'Gadchiroli' },
			{ value: 'Gondia', label: 'Gondia' },
			{ value: 'Hingoli', label: 'Hingoli' },
			{ value: 'Jalgaon', label: 'Jalgaon' },
			{ value: 'Jalna', label: 'Jalna' },
			{ value: 'Kolhapur', label: 'Kolhapur' },
			{ value: 'Latur', label: 'Latur' },
			{ value: 'Mumbai City', label: 'Mumbai City' },
			{
				value: 'Mumbai Suburban',
				label: 'Mumbai Suburban',
			},
			{ value: 'Nagpur', label: 'Nagpur' },
			{ value: 'Nanded', label: 'Nanded' },
			{ value: 'Nandurbar', label: 'Nandurbar' },
			{ value: 'Nashik', label: 'Nashik' },
			{ value: 'Osmanabad', label: 'Osmanabad' },
			{ value: 'Palghar', label: 'Palghar' },
			{ value: 'Parbhani', label: 'Parbhani' },
			{ value: 'Pune', label: 'Pune' },
			{ value: 'Raigad', label: 'Raigad' },
			{ value: 'Ratnagiri', label: 'Ratnagiri' },
			{ value: 'Sangli', label: 'Sangli' },
			{ value: 'Satara', label: 'Satara' },
			{ value: 'Sindhudurg', label: 'Sindhudurg' },
			{ value: 'Solapur', label: 'Solapur' },
			{ value: 'Thane', label: 'Thane' },
			{ value: 'Wardha', label: 'Wardha' },
			{ value: 'Washim', label: 'Washim' },
			{ value: 'Yavatmal', label: 'Yavatmal' },
		],
		Manipur: [
			{ value: 'Bishnupur', label: 'Bishnupur' },
			{ value: 'Chandel', label: 'Chandel' },
			{ value: 'Churachandpur', label: 'Churachandpur' },
			{ value: 'Imphal East', label: 'Imphal East' },
			{ value: 'Imphal West', label: 'Imphal West' },
			{ value: 'Jiribam', label: 'Jiribam' },
			{ value: 'Kakching', label: 'Kakching' },
			{ value: 'Kamjong', label: 'Kamjong' },
			{ value: 'Kangpokpi', label: 'Kangpokpi' },
			{ value: 'Noney', label: 'Noney' },
			{ value: 'Pherzawl', label: 'Pherzawl' },
			{ value: 'Senapati', label: 'Senapati' },
			{ value: 'Tamenglong', label: 'Tamenglong' },
			{ value: 'Tengnoupal', label: 'Tengnoupal' },
			{ value: 'Thoubal', label: 'Thoubal' },
			{ value: 'Ukhrul', label: 'Ukhrul' },
		],
		Meghalaya: [
			{
				value: 'East Garo Hills',
				label: 'East Garo Hills',
			},
			{
				value: 'East Jaintia Hills',
				label: 'East Jaintia Hills',
			},
			{
				value: 'East Khasi Hills',
				label: 'East Khasi Hills',
			},
			{
				value: 'North Garo Hills',
				label: 'North Garo Hills',
			},
			{ value: 'Ri Bhoi', label: 'Ri Bhoi' },
			{
				value: 'South Garo Hills',
				label: 'South Garo Hills',
			},
			{
				value: 'South West Garo Hills ',
				label: 'South West Garo Hills ',
			},
			{
				value: 'South West Khasi Hills',
				label: 'South West Khasi Hills',
			},
			{
				value: 'West Garo Hills',
				label: 'West Garo Hills',
			},
			{
				value: 'West Jaintia Hills',
				label: 'West Jaintia Hills',
			},
			{
				value: 'West Khasi Hills',
				label: 'West Khasi Hills',
			},
		],
		Mizoram: [
			{ value: 'Aizawl', label: 'Aizawl' },
			{ value: 'Champhai', label: 'Champhai' },
			{ value: 'Kolasib', label: 'Kolasib' },
			{ value: 'Lawngtlai', label: 'Lawngtlai' },
			{ value: 'Lunglei', label: 'Lunglei' },
			{ value: 'Mamit', label: 'Mamit' },
			{ value: 'Saiha', label: 'Saiha' },
			{ value: 'Serchhip', label: 'Serchhip' },
		],
		Nagaland: [
			{ value: 'Dimapur', label: 'Dimapur' },
			{ value: 'Kiphire', label: 'Kiphire' },
			{ value: 'Kohima', label: 'Kohima' },
			{ value: 'Longleng', label: 'Longleng' },
			{ value: 'Mokokchung', label: 'Mokokchung' },
			{ value: 'Mon', label: 'Mon' },
			{ value: 'Peren', label: 'Peren' },
			{ value: 'Phek', label: 'Phek' },
			{ value: 'Tuensang', label: 'Tuensang' },
			{ value: 'Wokha', label: 'Wokha' },
			{ value: 'Zunheboto', label: 'Zunheboto' },
		],
		Odisha: [
			{ value: 'Angul', label: 'Angul' },
			{ value: 'Balangir', label: 'Balangir' },
			{ value: 'Balasore', label: 'Balasore' },
			{ value: 'Bargarh', label: 'Bargarh' },
			{ value: 'Bhadrak', label: 'Bhadrak' },
			{ value: 'Boudh', label: 'Boudh' },
			{ value: 'Cuttack', label: 'Cuttack' },
			{ value: 'Deogarh', label: 'Deogarh' },
			{ value: 'Dhenkanal', label: 'Dhenkanal' },
			{ value: 'Gajapati', label: 'Gajapati' },
			{ value: 'Ganjam', label: 'Ganjam' },
			{ value: 'Jagatsinghapur', label: 'Jagatsinghapur' },
			{ value: 'Jajpur', label: 'Jajpur' },
			{ value: 'Jharsuguda', label: 'Jharsuguda' },
			{ value: 'Kalahandi', label: 'Kalahandi' },
			{ value: 'Kandhamal', label: 'Kandhamal' },
			{ value: 'Kendrapara', label: 'Kendrapara' },
			{
				value: 'Kendujhar (Keonjhar)',
				label: 'Kendujhar (Keonjhar)',
			},
			{ value: 'Khordha', label: 'Khordha' },
			{ value: 'Koraput', label: 'Koraput' },
			{ value: 'Malkangiri', label: 'Malkangiri' },
			{ value: 'Mayurbhanj', label: 'Mayurbhanj' },
			{ value: 'Nabarangpur', label: 'Nabarangpur' },
			{ value: 'Nayagarh', label: 'Nayagarh' },
			{ value: 'Nuapada', label: 'Nuapada' },
			{ value: 'Puri', label: 'Puri' },
			{ value: 'Rayagada', label: 'Rayagada' },
			{ value: 'Sambalpur', label: 'Sambalpur' },
			{ value: 'Sonepur', label: 'Sonepur' },
			{ value: 'Sundargarh', label: 'Sundargarh' },
		],
		Puducherry: [
			{ value: 'Karaikal', label: 'Karaikal' },
			{ value: 'Mahe', label: 'Mahe' },
			{ value: 'Pondicherry', label: 'Pondicherry' },
			{ value: 'Yanam', label: 'Yanam' },
		],
		Punjab: [
			{ value: 'Amritsar', label: 'Amritsar' },
			{ value: 'Barnala', label: 'Barnala' },
			{ value: 'Bathinda', label: 'Bathinda' },
			{ value: 'Faridkot', label: 'Faridkot' },
			{
				value: 'Fatehgarh Sahib',
				label: 'Fatehgarh Sahib',
			},
			{ value: 'Fazilka', label: 'Fazilka' },
			{ value: 'Ferozepur', label: 'Ferozepur' },
			{ value: 'Gurdaspur', label: 'Gurdaspur' },
			{ value: 'Hoshiarpur', label: 'Hoshiarpur' },
			{ value: 'Jalandhar', label: 'Jalandhar' },
			{ value: 'Kapurthala', label: 'Kapurthala' },
			{ value: 'Ludhiana', label: 'Ludhiana' },
			{ value: 'Mansa', label: 'Mansa' },
			{ value: 'Moga', label: 'Moga' },
			{ value: 'Muktsar', label: 'Muktsar' },
			{
				value: 'Nawanshahr (Shahid Bhagat Singh Nagar)',
				label: 'Nawanshahr (Shahid Bhagat Singh Nagar)',
			},
			{ value: 'Pathankot', label: 'Pathankot' },
			{ value: 'Patiala', label: 'Patiala' },
			{ value: 'Rupnagar', label: 'Rupnagar' },
			{
				value: 'Sahibzada Ajit Singh Nagar (Mohali)',
				label: 'Sahibzada Ajit Singh Nagar (Mohali)',
			},
			{ value: 'Sangrur', label: 'Sangrur' },
			{ value: 'Tarn Taran', label: 'Tarn Taran' },
		],
		Rajasthan: [
			{ value: 'Ajmer', label: 'Ajmer' },
			{ value: 'Alwar', label: 'Alwar' },
			{ value: 'Banswara', label: 'Banswara' },
			{ value: 'Baran', label: 'Baran' },
			{ value: 'Barmer', label: 'Barmer' },
			{ value: 'Bharatpur', label: 'Bharatpur' },
			{ value: 'Bhilwara', label: 'Bhilwara' },
			{ value: 'Bikaner', label: 'Bikaner' },
			{ value: 'Bundi', label: 'Bundi' },
			{ value: 'Chittorgarh', label: 'Chittorgarh' },
			{ value: 'Churu', label: 'Churu' },
			{ value: 'Dausa', label: 'Dausa' },
			{ value: 'Dholpur', label: 'Dholpur' },
			{ value: 'Dungarpur', label: 'Dungarpur' },
			{ value: 'Hanumangarh', label: 'Hanumangarh' },
			{ value: 'Jaipur', label: 'Jaipur' },
			{ value: 'Jaisalmer', label: 'Jaisalmer' },
			{ value: 'Jalore', label: 'Jalore' },
			{ value: 'Jhalawar', label: 'Jhalawar' },
			{ value: 'Jhunjhunu', label: 'Jhunjhunu' },
			{ value: 'Jodhpur', label: 'Jodhpur' },
			{ value: 'Karauli', label: 'Karauli' },
			{ value: 'Kota', label: 'Kota' },
			{ value: 'Nagaur', label: 'Nagaur' },
			{ value: 'Pali', label: 'Pali' },
			{ value: 'Pratapgarh', label: 'Pratapgarh' },
			{ value: 'Rajsamand', label: 'Rajsamand' },
			{ value: 'Sawai Madhopur', label: 'Sawai Madhopur' },
			{ value: 'Sikar', label: 'Sikar' },
			{ value: 'Sirohi', label: 'Sirohi' },
			{ value: 'Sri Ganganagar', label: 'Sri Ganganagar' },
			{ value: 'Tonk', label: 'Tonk' },
			{ value: 'Udaipur', label: 'Udaipur' },
		],
		Sikkim: [
			{ value: 'East Sikkim', label: 'East Sikkim' },
			{ value: 'North Sikkim', label: 'North Sikkim' },
			{ value: 'South Sikkim', label: 'South Sikkim' },
			{ value: 'West Sikkim', label: 'West Sikkim' },
		],
		'Tamil Nadu': [
			{ value: 'Ariyalur', label: 'Ariyalur' },
			{ value: 'Chennai', label: 'Chennai' },
			{ value: 'Coimbatore', label: 'Coimbatore' },
			{ value: 'Cuddalore', label: 'Cuddalore' },
			{ value: 'Dharmapuri', label: 'Dharmapuri' },
			{ value: 'Dindigul', label: 'Dindigul' },
			{ value: 'Erode', label: 'Erode' },
			{ value: 'Kanchipuram', label: 'Kanchipuram' },
			{ value: 'Kanyakumari', label: 'Kanyakumari' },
			{ value: 'Karur', label: 'Karur' },
			{ value: 'Krishnagiri', label: 'Krishnagiri' },
			{ value: 'Madurai', label: 'Madurai' },
			{ value: 'Nagapattinam', label: 'Nagapattinam' },
			{ value: 'Namakkal', label: 'Namakkal' },
			{ value: 'Nilgiris', label: 'Nilgiris' },
			{ value: 'Perambalur', label: 'Perambalur' },
			{ value: 'Pudukkottai', label: 'Pudukkottai' },
			{ value: 'Ramanathapuram', label: 'Ramanathapuram' },
			{ value: 'Salem', label: 'Salem' },
			{ value: 'Sivaganga', label: 'Sivaganga' },
			{ value: 'Thanjavur', label: 'Thanjavur' },
			{ value: 'Theni', label: 'Theni' },
			{
				value: 'Thoothukudi (Tuticorin)',
				label: 'Thoothukudi (Tuticorin)',
			},
			{
				value: 'Tiruchirappalli',
				label: 'Tiruchirappalli',
			},
			{ value: 'Tirunelveli', label: 'Tirunelveli' },
			{ value: 'Tiruppur', label: 'Tiruppur' },
			{ value: 'Tiruvallur', label: 'Tiruvallur' },
			{ value: 'Tiruvannamalai', label: 'Tiruvannamalai' },
			{ value: 'Tiruvarur', label: 'Tiruvarur' },
			{ value: 'Vellore', label: 'Vellore' },
			{ value: 'Viluppuram', label: 'Viluppuram' },
			{ value: 'Virudhunagar', label: 'Virudhunagar' },
		],
		Telangana: [
			{ value: 'Adilabad', label: 'Adilabad' },
			{
				value: 'Bhadradri Kothagudem',
				label: 'Bhadradri Kothagudem',
			},
			{ value: 'Hyderabad', label: 'Hyderabad' },
			{ value: 'Jagtial', label: 'Jagtial' },
			{ value: 'Jangaon', label: 'Jangaon' },
			{
				value: 'Jayashankar Bhoopalpally',
				label: 'Jayashankar Bhoopalpally',
			},
			{
				value: 'Jogulamba Gadwal',
				label: 'Jogulamba Gadwal',
			},
			{ value: 'Kamareddy', label: 'Kamareddy' },
			{ value: 'Karimnagar', label: 'Karimnagar' },
			{ value: 'Khammam', label: 'Khammam' },
			{
				value: 'Komaram Bheem Asifabad',
				label: 'Komaram Bheem Asifabad',
			},
			{ value: 'Mahabubabad', label: 'Mahabubabad' },
			{ value: 'Mahabubnagar', label: 'Mahabubnagar' },
			{ value: 'Mancherial', label: 'Mancherial' },
			{ value: 'Medak', label: 'Medak' },
			{ value: 'Medchal', label: 'Medchal' },
			{ value: 'Nagarkurnool', label: 'Nagarkurnool' },
			{ value: 'Nalgonda', label: 'Nalgonda' },
			{ value: 'Nirmal', label: 'Nirmal' },
			{ value: 'Nizamabad', label: 'Nizamabad' },
			{ value: 'Peddapalli', label: 'Peddapalli' },
			{
				value: 'Rajanna Sircilla',
				label: 'Rajanna Sircilla',
			},
			{ value: 'Rangareddy', label: 'Rangareddy' },
			{ value: 'Sangareddy', label: 'Sangareddy' },
			{ value: 'Siddipet', label: 'Siddipet' },
			{ value: 'Suryapet', label: 'Suryapet' },
			{ value: 'Vikarabad', label: 'Vikarabad' },
			{ value: 'Wanaparthy', label: 'Wanaparthy' },
			{
				value: 'Warangal (Rural)',
				label: 'Warangal (Rural)',
			},
			{
				value: 'Warangal (Urban)',
				label: 'Warangal (Urban)',
			},
			{
				value: 'Yadadri Bhuvanagiri',
				label: 'Yadadri Bhuvanagiri',
			},
		],
		Tripura: [
			{ value: 'Dhalai', label: 'Dhalai' },
			{ value: 'Gomati', label: 'Gomati' },
			{ value: 'Khowai', label: 'Khowai' },
			{ value: 'North Tripura', label: 'North Tripura' },
			{ value: 'Sepahijala', label: 'Sepahijala' },
			{ value: 'South Tripura', label: 'South Tripura' },
			{ value: 'Unakoti', label: 'Unakoti' },
			{ value: 'West Tripura', label: 'West Tripura' },
		],
		Uttarakhand: [
			{ value: 'Almora', label: 'Almora' },
			{ value: 'Bageshwar', label: 'Bageshwar' },
			{ value: 'Chamoli', label: 'Chamoli' },
			{ value: 'Champawat', label: 'Champawat' },
			{ value: 'Dehradun', label: 'Dehradun' },
			{ value: 'Haridwar', label: 'Haridwar' },
			{ value: 'Nainital', label: 'Nainital' },
			{ value: 'Pauri Garhwal', label: 'Pauri Garhwal' },
			{ value: 'Pithoragarh', label: 'Pithoragarh' },
			{ value: 'Rudraprayag', label: 'Rudraprayag' },
			{ value: 'Tehri Garhwal', label: 'Tehri Garhwal' },
			{
				value: 'Udham Singh Nagar',
				label: 'Udham Singh Nagar',
			},
			{ value: 'Uttarkashi', label: 'Uttarkashi' },
		],
		'Uttar Pradesh': [
			{ value: 'Agra', label: 'Agra' },
			{ value: 'Aligarh', label: 'Aligarh' },
			{ value: 'Allahabad', label: 'Allahabad' },
			{ value: 'Ambedkar Nagar', label: 'Ambedkar Nagar' },
			{
				value: 'Amethi (Chatrapati Sahuji Mahraj Nagar)',
				label: 'Amethi (Chatrapati Sahuji Mahraj Nagar)',
			},
			{
				value: 'Amroha (J.P. Nagar)',
				label: 'Amroha (J.P. Nagar)',
			},
			{ value: 'Auraiya', label: 'Auraiya' },
			{ value: 'Azamgarh', label: 'Azamgarh' },
			{ value: 'Baghpat', label: 'Baghpat' },
			{ value: 'Bahraich', label: 'Bahraich' },
			{ value: 'Ballia', label: 'Ballia' },
			{ value: 'Balrampur', label: 'Balrampur' },
			{ value: 'Banda', label: 'Banda' },
			{ value: 'Barabanki', label: 'Barabanki' },
			{ value: 'Bareilly', label: 'Bareilly' },
			{ value: 'Basti', label: 'Basti' },
			{ value: 'Bhadohi', label: 'Bhadohi' },
			{ value: 'Bijnor', label: 'Bijnor' },
			{ value: 'Budaun', label: 'Budaun' },
			{ value: 'Bulandshahr', label: 'Bulandshahr' },
			{ value: 'Chandauli', label: 'Chandauli' },
			{ value: 'Chitrakoot', label: 'Chitrakoot' },
			{ value: 'Deoria', label: 'Deoria' },
			{ value: 'Etah', label: 'Etah' },
			{ value: 'Etawah', label: 'Etawah' },
			{ value: 'Faizabad', label: 'Faizabad' },
			{ value: 'Farrukhabad', label: 'Farrukhabad' },
			{ value: 'Fatehpur', label: 'Fatehpur' },
			{ value: 'Firozabad', label: 'Firozabad' },
			{
				value: 'Gautam Buddha Nagar',
				label: 'Gautam Buddha Nagar',
			},
			{ value: 'Ghaziabad', label: 'Ghaziabad' },
			{ value: 'Ghazipur', label: 'Ghazipur' },
			{ value: 'Gonda', label: 'Gonda' },
			{ value: 'Gorakhpur', label: 'Gorakhpur' },
			{ value: 'Hamirpur', label: 'Hamirpur' },
			{
				value: 'Hapur (Panchsheel Nagar)',
				label: 'Hapur (Panchsheel Nagar)',
			},
			{ value: 'Hardoi', label: 'Hardoi' },
			{ value: 'Hathras', label: 'Hathras' },
			{ value: 'Jalaun', label: 'Jalaun' },
			{ value: 'Jaunpur', label: 'Jaunpur' },
			{ value: 'Jhansi', label: 'Jhansi' },
			{ value: 'Kannauj', label: 'Kannauj' },
			{ value: 'Kanpur Dehat', label: 'Kanpur Dehat' },
			{ value: 'Kanpur Nagar', label: 'Kanpur Nagar' },
			{
				value: 'Kanshiram Nagar (Kasganj)',
				label: 'Kanshiram Nagar (Kasganj)',
			},
			{ value: 'Kaushambi', label: 'Kaushambi' },
			{
				value: 'Kushinagar (Padrauna)',
				label: 'Kushinagar (Padrauna)',
			},
			{
				value: 'Lakhimpur - Kheri',
				label: 'Lakhimpur - Kheri',
			},
			{ value: 'Lalitpur', label: 'Lalitpur' },
			{ value: 'Lucknow', label: 'Lucknow' },
			{ value: 'Maharajganj', label: 'Maharajganj' },
			{ value: 'Mahoba', label: 'Mahoba' },
			{ value: 'Mainpuri', label: 'Mainpuri' },
			{ value: 'Mathura', label: 'Mathura' },
			{ value: 'Mau', label: 'Mau' },
			{ value: 'Meerut', label: 'Meerut' },
			{ value: 'Mirzapur', label: 'Mirzapur' },
			{ value: 'Moradabad', label: 'Moradabad' },
			{ value: 'Muzaffarnagar', label: 'Muzaffarnagar' },
			{ value: 'Pilibhit', label: 'Pilibhit' },
			{ value: 'Pratapgarh', label: 'Pratapgarh' },
			{ value: 'RaeBareli', label: 'RaeBareli' },
			{ value: 'Rampur', label: 'Rampur' },
			{ value: 'Saharanpur', label: 'Saharanpur' },
			{
				value: 'Sambhal (Bhim Nagar)',
				label: 'Sambhal (Bhim Nagar)',
			},
			{
				value: 'Sant Kabir Nagar',
				label: 'Sant Kabir Nagar',
			},
			{ value: 'Shahjahanpur', label: 'Shahjahanpur' },
			{
				value: 'Shamali (Prabuddh Nagar)',
				label: 'Shamali (Prabuddh Nagar)',
			},
			{ value: 'Shravasti', label: 'Shravasti' },
			{
				value: 'Siddharth Nagar',
				label: 'Siddharth Nagar',
			},
			{ value: 'Sitapur', label: 'Sitapur' },
			{ value: 'Sonbhadra', label: 'Sonbhadra' },
			{ value: 'Sultanpur', label: 'Sultanpur' },
			{ value: 'Unnao', label: 'Unnao' },
			{ value: 'Varanasi', label: 'Varanasi' },
		],
		'West Bengal': [
			{ value: 'Alipurduar', label: 'Alipurduar' },
			{ value: 'Bankura', label: 'Bankura' },
			{ value: 'Birbhum', label: 'Birbhum' },
			{
				value: 'Burdwan (Bardhaman)',
				label: 'Burdwan (Bardhaman)',
			},
			{ value: 'Cooch Behar', label: 'Cooch Behar' },
			{
				value: 'Dakshin Dinajpur (South Dinajpur)',
				label: 'Dakshin Dinajpur (South Dinajpur)',
			},
			{ value: 'Darjeeling', label: 'Darjeeling' },
			{ value: 'Hooghly', label: 'Hooghly' },
			{ value: 'Howrah', label: 'Howrah' },
			{ value: 'Jalpaiguri', label: 'Jalpaiguri' },
			{ value: 'Kalimpong', label: 'Kalimpong' },
			{ value: 'Kolkata', label: 'Kolkata' },
			{ value: 'Malda', label: 'Malda' },
			{ value: 'Murshidabad', label: 'Murshidabad' },
			{ value: 'Nadia', label: 'Nadia' },
			{
				value: 'North 24 Parganas',
				label: 'North 24 Parganas',
			},
			{
				value: 'Paschim Medinipur (West Medinipur)',
				label: 'Paschim Medinipur (West Medinipur)',
			},
			{
				value: 'Purba Medinipur (East Medinipur)',
				label: 'Purba Medinipur (East Medinipur)',
			},
			{ value: 'Purulia', label: 'Purulia' },
			{
				value: 'South 24 Parganas',
				label: 'South 24 Parganas',
			},
			{
				value: 'Uttar Dinajpur (North Dinajpur)',
				label: 'Uttar Dinajpur (North Dinajpur)',
			},
		],
	};

	const preferredCodingLanguageOptions = [
		{ value: 'Java', label: 'Java' },
		{
			value: 'Python',
			label: 'Python',
		},
		{
			value: 'Javascript',

			label: 'Javascript',
		},
		{
			value: 'C/C++',
			label: 'C/C++',
		},
	];

	const otherCodingSkillsOptions = [
		{ value: 'Spring', label: 'Spring' },
		{ value: 'Angular', label: 'Angular' },
		{ value: 'Ruby on rails', label: 'Ruby on rails' },
		{ value: 'PHP', label: 'PHP' },
		{ value: '.NET', label: '.NET' },
		{ value: 'Django', label: 'Django' },
		{ value: 'Flask', label: 'Flask' },
		{ value: 'Springboot', label: 'Springboot' },
		{ value: 'Hibernate', label: 'Hibernate' },
		{
			value: 'Selenium/Testing',
			label: 'Selenium/Testing',
		},
		{ value: 'Salesforce', label: 'Salesforce' },
		{ value: 'Service Now', label: 'Service Now' },
		{ value: 'Node', label: 'Node' },
		{ value: 'React', label: 'React' },
		{ value: 'Vue', label: 'Vue' },
		{ value: 'Express', label: 'Express' },
		{ value: 'Mongo DB', label: 'Mongo DB' },
		{ value: 'SQL', label: 'SQL' },
		{ value: 'AWS', label: 'AWS' },
		{ value: 'HTML', label: 'HTML' },
		{ value: 'CSS', label: 'CSS' },
		{ value: 'Wordpress', label: 'Wordpress' },
		{ value: 'Basic DSA', label: 'Basic DSA' },
		{
			value: 'Intermediate DSA',
			label: 'Intermediate DSA',
		},
		{ value: 'Advanced DSA', label: 'Advanced DSA' },
		{ value: 'OOPS', label: 'OOPS' },
		{ value: 'OS', label: 'OS' },
		{ value: 'Networking', label: 'Networking' },
		{ value: 'Java', label: 'Java' },
		{ value: 'C++', label: 'C++' },
		{ value: 'Python', label: 'Python' },
		{ value: 'Javascript', label: 'Javascript' },
	];
	const stateOptions = [
		{
			value: 'Andaman and Nicobar Islands',
			label: 'Andaman and Nicobar Islands',
		},
		{ value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
		{
			value: 'Arunachal Pradesh',
			label: 'Arunachal Pradesh',
		},
		{ value: 'Assam', label: 'Assam' },
		{ value: 'Bihar', label: 'Bihar' },
		{ value: 'Chandigarh', label: 'Chandigarh' },
		{ value: 'Chhattisgarh', label: 'Chhattisgarh' },
		{
			value: 'Dadra & Nagar Haveli and Daman & Diu',
			label: 'Dadra & Nagar Haveli and Daman & Diu',
		},
		{ value: 'Delhi', label: 'Delhi' },
		{ value: 'Goa', label: 'Goa' },
		{ value: 'Gujarat', label: 'Gujarat' },
		{ value: 'Haryana', label: 'Haryana' },
		{
			value: 'Himachal Pradesh',
			label: 'Himachal Pradesh',
		},
		{
			value: 'Jammu and Kashmir',
			label: 'Jammu and Kashmir',
		},
		{ value: 'Jharkhand', label: 'Jharkhand' },
		{ value: 'Karnataka', label: 'Karnataka' },
		{ value: 'Kerala', label: 'Kerala' },
		{ value: 'Ladakh', label: 'Ladakh' },
		{ value: 'Lakshadweep', label: 'Lakshadweep' },
		{ value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
		{ value: 'Maharashtra', label: 'Maharashtra' },
		{ value: 'Manipur', label: 'Manipur' },
		{ value: 'Meghalaya', label: 'Meghalaya' },
		{ value: 'Mizoram', label: 'Mizoram' },
		{ value: 'Nagaland', label: 'Nagaland' },
		{ value: 'Odisha', label: 'Odisha' },
		{ value: 'Puducherry', label: 'Puducherry' },
		{ value: 'Punjab', label: 'Punjab' },
		{ value: 'Rajasthan', label: 'Rajasthan' },
		{ value: 'Sikkim', label: 'Sikkim' },
		{ value: 'Tamil Nadu', label: 'Tamil Nadu' },
		{ value: 'Telangana', label: 'Telangana' },
		{ value: 'Tripura', label: 'Tripura' },
		{ value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
		{ value: 'Uttarakhand', label: 'Uttarakhand' },
		{ value: 'West Bengal', label: 'West Bengal' },
	];

	const dispatch = useAppDispatch();
	const UserState = useSelector((state: any) => state.user);
	const { placementProfile, user, profile } = UserState;

	const options = [
		{ value: 'option1', label: 'Option 1' },
		{ value: 'option2', label: 'Option 2' },
		{ value: 'option3', label: 'Option 3' },
	];

	const [resumeUrl, setResumeUrl] = useState('');
	const [activeCollegeBacklogs, setActiveCollegeBacklogs] =
		useState('');
	const [
		preferredCodingLanguage,
		setPreferredCodingLanguage,
	] = useState<Option | null>(null);
	const [codingSkills, setCodingSkills] =
		useState<Option | null>(null);
	const [githubProfileLink, setGithubProfileLink] =
		useState('');
	const [currentState, setCurrentState] =
		useState<Option | null>(null);

	const [currentDistrict, setCurrentDistrict] =
		useState<Option | null>(null);

	const [workExperience, setWorkExperience] = useState('');
	const [currentCompany, setCurrentCompany] = useState('');
	const [currentCTC, setCurrentCTC] = useState('');
	const [noticePeriod, setNoticePeriod] = useState('');
	const [resumeLink, setResumeLink] = useState('');

	const [
		activeCollegeBacklogsError,
		setActiveCollegeBacklogsError,
	] = useState(false);
	const [
		preferredCodingLanguageError,
		setPreferredCodingLanguageError,
	] = useState(false);
	const [codingSkillsError, setCodingSkillsError] =
		useState(false);
	const [
		githubProfileLinkError,
		setGithubProfileLinkError,
	] = useState(false);
	const [currentStateError, setCurrentStateError] =
		useState(false);
	const [currentDistrictError, setCurrentDistrictError] =
		useState(false);
	const [workExperienceError, setWorkExperienceError] =
		useState(false);
	const [currentCompanyError, setCurrentCompanyError] =
		useState(false);
	const [currentCTCError, setCurrentCTCError] =
		useState(false);
	const [noticePeriodError, setNoticePeriodError] =
		useState(false);

	const handleActiveCollegeBacklogsChange = (e: any) => {
		setActiveCollegeBacklogs(e.target.value);
		setActiveCollegeBacklogsError(false);
	};

	const handlePreferredCodingLanguageChange = (
		selectedOption: any
	) => {
		setPreferredCodingLanguage(selectedOption);
		setPreferredCodingLanguageError(false);
	};

	const handleCodingSkillsChange = (
		selectedOptions: any
	) => {
		setCodingSkills(selectedOptions);
		setCodingSkillsError(false);
	};

	const handleGithubProfileLinkChange = (e: any) => {
		setGithubProfileLink(e.target.value);
		setGithubProfileLinkError(false);
	};

	// const handleCurrentStateChange = (
	// 	selectedOption: any
	// ) => {
	// 	setCurrentState(selectedOption);
	// 	setCurrentStateError(false);
	// };

	const handleCurrentStateChange = (
		selectedOption: Option
	) => {
		setCurrentState(selectedOption);
		setCurrentStateError(false);
		setCurrentDistrict(null);
	};

	const handleCurrentDistrictChange = (
		selectedOption: any
	) => {
		setCurrentDistrict(selectedOption);
		setCurrentDistrictError(false);
	};

	const handleWorkExperienceChange = (e: any) => {
		setWorkExperience(e.target.value);
	};

	const handleCurrentCompanyChange = (e: any) => {
		setCurrentCompany(e.target.value);
	};

	const handleCurrentCTCChange = (e: any) => {
		setCurrentCTC(e.target.value);
	};

	const handleNoticePeriodChange = (e: any) => {
		setNoticePeriod(e.target.value);
	};

	const handleSubmit = async (e: any) => {
		e.preventDefault();

		let hasError = false;

		if (!activeCollegeBacklogs) {
			setActiveCollegeBacklogsError(true);
			hasError = true;
		}
		if (!preferredCodingLanguage) {
			setPreferredCodingLanguageError(true);
			hasError = true;
		}
		//@ts-ignore
		if (codingSkills && codingSkills.length === 0) {
			setCodingSkillsError(true);
			hasError = true;
		}

		// if (!githubProfileLink) {
		// 	setGithubProfileLinkError(true);
		// 	hasError = true;
		// }

		if (!currentState) {
			setCurrentStateError(true);
			hasError = true;
		}
		if (!currentDistrict) {
			setCurrentDistrictError(true);
			hasError = true;
		}

		if (userProfessionDetails === 'Working_Professional') {
			if (!currentCompany) {
				setCurrentCompanyError(true);
				hasError = true;
			}

			if (!currentCTC) {
				setCurrentCTCError(true);
				hasError = true;
			}

			if (!noticePeriod) {
				setNoticePeriodError(true);
				hasError = true;
			}

			if (!workExperience) {
				setWorkExperienceError(true);
				hasError = true;
			}
		}

		if (!hasError) {
			const formData = {
				userId: user?.id,
				collegeBacklogs: activeCollegeBacklogs,
				currentCity: currentDistrict?.value,
				currentCompany: currentCompany,
				currentCtc: currentCTC,
				currentState: currentState?.value,
				githubProfileLink: githubProfileLink,
				leetcodeUserName:
					placementProfile?.leetcodeUserName,
				noticePeriod: noticePeriod,
				otherCodingSkills: codingSkills?.map(
					(codingSkills: any) => codingSkills.value
				),
				preferredCodingLanguage:
					preferredCodingLanguage?.value,
				resume: resumeLink,
				workExperienceMonths: workExperience,
			};

			try {
				const data =
					await services.userService.savePlacementProfile({
						...formData,
					});
				console.log('abc data', data);
				notifySuccess(
					' Your info has been saved successfully'
				);
			} catch (e) {
				console.log(e);
				notifyFailure(
					"Couldn't save your info. Please try again later"
				);
			}

			dispatch(fetchPlacementProfile());

			// const data =
			// 	await services.userService.savePlacementProfile(
			// 		formData
			// 	);

			console.log('my_form_data', formData);
		}
	};

	useEffect(() => {
		const fields: any[] = [
			{
				value: activeCollegeBacklogs,
				errorSetter: setActiveCollegeBacklogsError,
			},
			{
				value: preferredCodingLanguage,
				errorSetter: setPreferredCodingLanguageError,
			},
			{
				value: codingSkills,
				errorSetter: setCodingSkillsError,
			},
			{
				value: githubProfileLink,
				errorSetter: setGithubProfileLinkError,
			},
			{
				value: currentState,
				errorSetter: setCurrentStateError,
			},
			{
				value: currentDistrict,
				errorSetter: setCurrentDistrictError,
			},
			{
				value: workExperience,
				errorSetter: setWorkExperienceError,
			},
			{
				value: currentCompany,
				errorSetter: setCurrentCompanyError,
			},
			{
				value: currentCTC,
				errorSetter: setCurrentCTCError,
			},
			{
				value: noticePeriod,
				errorSetter: setNoticePeriodError,
			},
		];

		fields.forEach((field) => {
			if (field.value) {
				field.errorSetter(false);
			}
		});
	}, [
		activeCollegeBacklogs,
		preferredCodingLanguage,
		codingSkills,
		githubProfileLink,
		currentState,
		currentDistrict,
		workExperience,
		currentCompany,
		currentCTC,
		noticePeriod,
	]);

	useEffect(() => {
		if (user !== null) {
			dispatch(fetchPlacementProfile());
		}
	}, []);

	useEffect(() => {
		if (placementProfile) {
			setActiveCollegeBacklogs(
				placementProfile.collegeBacklogs || ''
			);

			setPreferredCodingLanguage({
				value:
					placementProfile.preferredCodingLanguage || '',
				label:
					placementProfile.preferredCodingLanguage || '',
			});

			setCodingSkills(
				(placementProfile.otherCodingSkills || []).map(
					(codingSkills: any) => ({
						value: codingSkills || '',
						label: codingSkills || '',
					})
				)
			);

			setGithubProfileLink(
				placementProfile.githubProfileLink || ''
			);
			setCurrentState({
				value: placementProfile.currentState || '',
				label: placementProfile.currentState || '',
			});
			setCurrentDistrict({
				value: placementProfile.currentCity || '',
				label: placementProfile.currentCity || '',
			});
			setWorkExperience(
				placementProfile.workExperienceMonths || ''
			);
			setCurrentCompany(
				placementProfile.currentCompany || ''
			);
			setCurrentCTC(placementProfile.currentCtc || '');
			setNoticePeriod(placementProfile.noticePeriod || '');
			setResumeLink(placementProfile.resume || '');
		}
	}, [placementProfile]);

	const [userProfileLoock, setUserProfileLoock] =
		useState<boolean>(false);

	useEffect(() => {
		if (user) {
			if (user.isProfileLocked) {
				setUserProfileLoock(true);
			} else {
				setUserProfileLoock(false);
			}
		}
	}, [user]);

	const [userProfessionDetails, setUserProfessionDetails] =
		useState<string>('');

	useEffect(() => {
		if (user) {
			if (profile && profile.jobStatus) {
				setUserProfessionDetails(profile.jobStatus);
			}
		}
	}, [profile]);

	console.log('userProfileLoock', userProfileLoock);

	return (
		<form
			onSubmit={handleSubmit}
			className="profile_form"
		>
			<Box sx={{ width: '100%' }}>
				{userProfileLoock ? (
					<p className="profile-lock-msg">
						*Your profile has been locked. For updating it
						please contact to your Batch manager
					</p>
				) : null}
				<ProfileBlock label="General Details">
					<FieldBlock
						label={'Active College Backlogs'}
						id={'activeCollegeBacklogs'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'activeCollegeBacklogs'}
							type={'number'}
							value={activeCollegeBacklogs}
							placeHolder={'Enter Active College Backlogs'}
							onChange={handleActiveCollegeBacklogsChange}
							required
							showError={activeCollegeBacklogsError}
							errorMessage={
								'Active College Backlogs should not be empty'
							}
						/>
					</FieldBlock>
					<FieldBlock
						label={'Preferred Coding Language'}
						id={'preferredCodingLanguage'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'preferredCodingLanguage'}
							options={preferredCodingLanguageOptions}
							onDropdownChange={
								handlePreferredCodingLanguageChange
							}
							value={preferredCodingLanguage}
							placeholder={
								'Select Preferred Coding Language'
							}
							required
							showError={preferredCodingLanguageError}
						/>
					</FieldBlock>
					<FieldBlock
						label={'Coding Skills'}
						id={'codingSkills'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'codingSkills'}
							options={otherCodingSkillsOptions}
							onDropdownChange={handleCodingSkillsChange}
							value={codingSkills}
							placeholder={'Select Coding Skills'}
							required
							isMulti={true}
							showError={codingSkillsError}
							errorMessage={
								'Coding Skills should be selected'
							}
						/>
					</FieldBlock>
					<FieldBlock
						label={'Github Profile Link'}
						id={'githubProfileLink'}
						required={false}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'githubProfileLink'}
							type={'text'}
							value={githubProfileLink}
							placeHolder={'Enter Github Profile Link'}
							onChange={handleGithubProfileLinkChange}
							required
							showError={githubProfileLinkError}
							errorMessage={
								'Github Profile Link should not be empty'
							}
						/>
					</FieldBlock>
					<FieldBlock
						label={'Current State'}
						id={'currentState'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'currentState'}
							options={stateOptions}
							onDropdownChange={handleCurrentStateChange}
							value={currentState}
							placeholder={'Select Current State'}
							required
							showError={currentStateError}
							errorMessage={
								'Current State should be selected'
							}
						/>
					</FieldBlock>
					<FieldBlock
						label={'Current District'}
						id={'currentDistrict'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'currentDistrict'}
							options={
								currentState && currentState.value
									? cityOptions[currentState.value]
									: []
							}
							onDropdownChange={handleCurrentDistrictChange}
							value={currentDistrict}
							placeholder={'Select Current District'}
							required
							showError={currentDistrictError}
							errorMessage={
								'Current District should be selected'
							}
						/>
					</FieldBlock>
				</ProfileBlock>
				{/* *****************************************----------------------------------------------------- Professional Details -----------------------------------------------------***************************************** */}
				<ProfileBlock label="Resume">
					<FieldBlock
						label={'Updated Resume'}
						id={'noticePeriod'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<FileUploadButton
							btnType={'button'}
							resumeLink={resumeLink}
							setResumeLink={setResumeLink}
						/>
					</FieldBlock>
				</ProfileBlock>

				<ProfileBlock label="Professional Details">
					<div style={{ flexGrow: 1, width: '100%' }}>
						<p className="block-text-msg">
							{userProfessionDetails ===
							'Working_Professional'
								? 'Since you are a working professional, please enter details regarding your work experience(compulsory)'
								: 'If you have professional working experience, please enter the info below. Or leave it blank.(Dont include internships)'}
						</p>
					</div>

					<FieldBlock
						label={'Work Experience (Months)'}
						id={'workExperience'}
						required={
							userProfessionDetails ===
							'Working_Professional'
						}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'workExperience'}
							type={'number'}
							value={workExperience}
							placeHolder={'Enter work experience'}
							onChange={handleWorkExperienceChange}
							required
							showError={workExperienceError}
							errorMessage={
								'Work experience should not be empty'
							}
						/>
					</FieldBlock>

					<FieldBlock
						label={'Current Company'}
						id={'currentCompany'}
						required={
							userProfessionDetails ===
							'Working_Professional'
						}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'currentCompany'}
							type={'text'}
							value={currentCompany}
							placeHolder={'Enter current company'}
							onChange={handleCurrentCompanyChange}
							required
							showError={currentCompanyError}
							errorMessage={
								'Current company should not be empty'
							}
						/>
					</FieldBlock>

					<FieldBlock
						label={'Current CTC'}
						id={'currentCTC'}
						required={
							userProfessionDetails ===
							'Working_Professional'
						}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'currentCTC'}
							type={'number'}
							value={currentCTC}
							placeHolder={'Enter Current CTC'}
							onChange={handleCurrentCTCChange}
							required
							showError={currentCTCError}
							errorMessage={
								'Current CTC should not be empty'
							}
						/>
					</FieldBlock>

					<FieldBlock
						label={'Notice Period (Months)'}
						id={'noticePeriod'}
						required={
							userProfessionDetails ===
							'Working_Professional'
						}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'noticePeriod'}
							type={'number'}
							value={noticePeriod}
							placeHolder={'Enter Notice Period'}
							onChange={handleNoticePeriodChange}
							required
							showError={noticePeriodError}
							errorMessage={
								'Notice Period should not be empty'
							}
						/>
					</FieldBlock>
				</ProfileBlock>

				<FieldBlock
					label={``}
					id={``}
					required={false}
					isProfileLocked={userProfileLoock}
					style={{ backgroundColor: '#fff ' }}
				>
					{/* <AccioButton
						customClass="file-view-button"
						className=""
						variant="primary"
						title={'Submit'}
						icon={''}
						action={handleSubmit}
					/> */}

					<input
						type="submit"
						className="submit__button"
					/>
				</FieldBlock>
			</Box>
		</form>
	);
};

export default ProfileTab2;

// collegeBacklogs: '0';
// currentCity: 'Nadia';
// currentCompany: 'Acciojob';
// currentCtc: '0';
// currentState: 'West_Bengal';
// githubProfileLink: 'https://github.com';
// leetcodeUserName: 'sss';
// noticePeriod: '0';
// otherCodingSkills: ['React'];
// preferredCodingLanguage: 'Javascript';
// resume: 'https://firebasestorage.googleapis.com/v0/b/accio-jobs.appspot.com/o/resumes%2Fimportant.pdf%20079bc03f-d262-47b1-a8fa-04930453f583?alt=media&token=601c487f-28cf-4011-be97-ec0ee1991def';
// userId: '079bc03f-d262-47b1-a8fa-04930453f583';
// workExperienceMonths: '0';
