import React, {
	Component,
	useState,
	useRef,
	useEffect,
} from 'react';
import Tree from './Tree';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import ReactMarkdown from 'react-markdown';
import { services } from '../../apis/index';
import remarkGfm from 'remark-gfm';
import remarkHTML from 'remark-html';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import values from 'lodash.values';
import slugify from 'slugify';
import {
	useNavigate,
	useLocation,
	useParams,
} from 'react-router-dom';
import Loader from '../../components/Loader';
// const useStyles = makeStyles({
//   '.MuiAccordion-root .Mui-expanded': {
//     margin: 0,
//   },
//   container: {
//     display: 'flex',
//     flexDirection: 'row',
//     backgroundColor: '#f8f8fb',
//     minHeight: window.innerHeight,
//     overflowX: 'hidden',
//   },
//   leftLogoBar: {
//     height: window.innerHeight,
//     width: 72,
//     backgroundColor: '#476de5',
//     position: 'fixed',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   rightBar: {
//     marginLeft: -20,
//     height: window.innerHeight,
//   },
//   header: {
//     width: window.innerWidth - 72,
//     height: 75,
//     position: 'fixed',
//     top: 0,
//     zIndex: 1000,
//     overflow: 'hidden',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'flex-end',
//     backgroundColor: 'white',
//     alignItems: 'center',
//   },
//   mainContainer: {
//     width: window.innerWidth - 72,
//     backgroundColor: 'white',
//     display: 'flex',
//     flexDirection: 'row',
//     // marginTop: 75
//   },
//   mainBar: {
//     width: window.innerWidth - 422,
//     backgroundColor: 'white',
//     padding: '10px 65px 20px',
//     marginLeft: '350px',
//     minHeight: '724px',
//     position: 'relative',
//     borderLeft: '2px solid #dfd8d8',
//   },
//   moduleContainer: {
//     width: '70%',
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   actionCardContainer: {
//     flex: 3,
//     width: (window.innerWidth - 72) * 0.3,
//     position: 'fixed',
//     right: 0,
//     display: 'flex',
//     flexDirection: 'column',
//     alignItems: 'center',
//     height: '100vh',
//     overflow: 'scroll',
//   },
//   headingText: {
//     fontSize: 20,
//     color: 'white',
//     letterSpacing: 0.24,
//     fontWeight: 500,
//     marginLeft: 32,
//   },
//   headingContainer: {
//     marginTop: 12,
//     marginBottom: 12,
//     width: '95%',
//     boxShadow: '5px 6px 16px 0 rgba(166, 172, 190, 0.2)',
//     borderRadius: 20,
//     display: 'flex',
//     alignItems: 'center',
//     paddingRight: 32,
//     paddingTop: 12,
//     paddingBottom: 12,
//   },
//   accContent: {
//     padding: '8px 50px 16px',
//   },
// })

const FileExplorer = ({ match }: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { slug } = useParams();
	const [mainNodes, setMainNodes] = useState<any>(null);

	const [selectedFile, setSelectedFile] = useState<any>({});
	const [selectedFolder, setSelectedFolder] = useState<any>(
		{}
	);
	const [highlightFolder, setHighlighFolder] =
		useState<any>({});
	const [nextFile, setNextFile] = useState<any>(null);
	const [prevFile, setPrevFile] = useState<any>(null);

	const [rootNodes, setRootNodes] = useState<any[]>([]);

	const fetchData = async () => {
		const data =
			await services.notesService.fetchAllNotes();

		if (slug !== undefined) {
			// traverse all nodes and get node with this slug
			const myFile = values(data).filter(
				(node: any) =>
					slugify(node.path.split('.')[0], {
						lower: true,
						strict: true,
					}) === slug && node.type === 'file'
			);
			// if found -> setState to that file
			if (myFile.length !== 0) {
				setSelectedFile(myFile[0]);
				setNextFile(data[myFile[0].next]);
				setPrevFile(data[myFile[0].prev]);

				// Open Root to Leaf Nodes
				const parent = new Map<string, string>();
				const leafToRoot: string[] = [];
				values(data).map((node: any) => {
					if (node.children.length) {
						node.children.forEach((child: string) => {
							parent.set(child, node.id);
						});
					}
				});

				// get path
				let curr: string | undefined = myFile[0].id;
				console.log('current print hora idhar', curr);
				while (curr) {
					leafToRoot.push(curr);
					curr = parent.has(curr)
						? parent.get(curr)
						: undefined;
				}

				// set to open
				console.log('check');
				leafToRoot.forEach((node: string) => {
					data[node].isOpen = true;
					console.log(node);
				});
			} else {
				// else -> go to /notes/
				navigate('/notes');
				// history.push('/notes/')
			}
		}
		setMainNodes(data);
		// console.log(data)
		// return data
	};
	console.log(match);
	useEffect(() => {
		fetchData();
	}, [slug]);

	useEffect(() => {
		getRootNodes();
	}, [mainNodes]);

	const getRootNodes = () => {
		setRootNodes(
			values(mainNodes).filter(
				(node: any) => node.isRoot === true
			)
		);
	};

	const dummy = useRef<any>();

	const onSelect = (file: any) => {
		if (file.type == 'file') {
			setSelectedFile(file);
		} else {
			setSelectedFolder(file);
		}
	};

	const handleNextFile = (file: any) => {
		setNextFile(file);
	};

	const handlePrevFile = (file: any) => {
		setPrevFile(file);
	};

	if (!mainNodes) {
		return <Loader />;
	}

	return (
		<div className={'mainContainer'}>
			<div className="notes_sidebar">
				<Tree
					rootNodes={rootNodes}
					onSelect={onSelect}
					nodes={mainNodes}
					setMainNodes={setMainNodes}
					handlePrevFile={handlePrevFile}
					selectedFile={selectedFile}
					selectedFolder={selectedFolder}
					highlightFolder={highlightFolder}
					handleNextFile={handleNextFile}
				/>
			</div>

			<div className="notes_mainbar">
				{/* {selectedFile && selectedFile.type === "file" && selectedFile.content} */}
				<ReactMarkdown
					className={`markdown-body`}
					remarkPlugins={[remarkGfm]}
					rehypePlugins={[rehypeHighlight, rehypeRaw]}
				>
					{selectedFile &&
						selectedFile.type === 'file' &&
						selectedFile.content}
				</ReactMarkdown>
				{selectedFile && (
					<div className="notes_bottombar">
						<a
							className="notes_leftbox"
							href={
								prevFile
									? slugify(prevFile.path.split('.')[0], {
											lower: true,
											strict: true,
									  })
									: ''
							}
							style={{
								visibility: prevFile ? 'visible' : 'hidden',
							}}
						>
							<p>Prev</p>
							<p className="notes_bottomlabel">
								{' '}
								<KeyboardDoubleArrowLeftIcon
									style={{
										fontSize: '1.2rem',
									}}
								/>
								<p style={{ margin: '0 10px 0' }}>
									{prevFile && prevFile.path}
								</p>{' '}
							</p>
						</a>
						<a
							href={
								nextFile
									? slugify(nextFile.path.split('.')[0], {
											lower: true,
											strict: true,
									  })
									: ''
							}
							className="notes_rightbox"
							style={{
								visibility: nextFile ? 'visible' : 'hidden',
							}}
						>
							<p>Next</p>
							<p
								className="notes_bottomlabel"
								style={{ justifyContent: 'flex-end' }}
							>
								<p style={{ margin: '0 10px 0' }}>
									{nextFile && nextFile.path}
								</p>{' '}
								<KeyboardDoubleArrowRightIcon
									style={{ fontSize: '1.2rem' }}
								/>
							</p>
						</a>
					</div>
				)}
			</div>
		</div>
	);
};

export default FileExplorer;
