import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Sheet from '@mui/joy/Sheet';
import Typography from '@mui/joy/Typography';
// import '../styles/recordings1v1.scss';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import OwlCarousel from 'react-owl-carousel';
import ReactPlayer from 'react-player';
import { VimeoVideoPlayer } from './shared/VimeoVideoPlayer';

const Recordings1v1 = ({
	isJitsi,
	open,
	handleClose,
	currentRecordings,
}: {
	isJitsi?: boolean;
	open: boolean;
	handleClose: any;
	currentRecordings: any;
}) => {
	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={open}
			onClose={handleClose}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Sheet
				variant="outlined"
				sx={{
					maxWidth: 800,
					borderRadius: 'md',
					p: 2.5,
					boxShadow: 'lg',
				}}
			>
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				{currentRecordings.length > 0 ? (
					<OwlCarousel
						className="owl-theme"
						items={1}
						nav
					>
						{currentRecordings.map(
							(recording: any, ind: number) => {
								return (
									<div
										className="item"
										key={ind}
									>
										{isJitsi ? (
											<>
												{recording ? (
													<ReactPlayer
														controls={true}
														playing={
															ind === 0 ? true : false
														}
														width={'640px'}
														height={'auto'}
														url={recording}
														style={{
															position: 'relative',
															display: 'flex',
															justifyContent: 'center',
															marginInline: 'auto',
															borderRadius: '5px',
															overflow: 'hidden',
															background: 'black',
															width: '640px',
														}}
													/>
												) : (
													<Typography
														id="modal-desc"
														textColor="text.tertiary"
														sx={{
															textAlign: 'center',
															fontSize: '16px',
															marginTop: '64px',
															marginBottom: '64px',
															width: '640px',
														}}
													>
														Recording not{' '}
														<code>Avaialble</code>.
													</Typography>
												)}
											</>
										) : (
											<VimeoVideoPlayer
												id={recording}
												options={{
													url: recording,
													width: 640,
													autoplay: true,
													controls: true,
													pip: true,
													transparent: true,
													title: false,
												}}
												style={{
													position: 'relative',
													display: 'flex',
													justifyContent: 'center',
													marginInline: 'auto',
													borderRadius: '5px',
													overflow: 'hidden',
													background: 'black',
													width: '640px',
												}}
											/>
										)}
									</div>
								);
							}
						)}
					</OwlCarousel>
				) : (
					<Typography
						id="modal-desc"
						textColor="text.tertiary"
					>
						Do not have any <code>recordings</code> .
					</Typography>
				)}
			</Sheet>
		</Modal>
	);
};

export default Recordings1v1;
