import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';

export class JupyterNoteBookService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getJupyterNoteBookRequestId(questionId: string) {
		const url = `${this.endpoint}/jupyter-hub/place-jupyter-notebook-iframe-request?questionId=${questionId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getJupyterNoteBookQuestionIframe(
		requestId: string
	) {
		const url = `${this.endpoint}/jupyter-hub/get-jupyter-notebook-iframe?requestId=${requestId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('state.iframeData', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getJupyterNoteBookAttemptId(questionId: string) {
		const url = `${this.endpoint}/jupyter-hub/submit-jupyter-notebook?questionId=${questionId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getJupyterNoteBookQuestionResultData(
		attemptId: string,
		questionId: string
	) {
		const url = `${this.endpoint}/jupyter-hub/get-data-science-question-attempt-details?questionId=${questionId}&attemptId=${attemptId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('datavalue service', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getJupyterNoteBookQuestionData(questionId: string) {
		const url = `${this.endpoint}/jupyter-hub/question-details/${questionId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
