import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';

import { AppDispatch, RootState } from '../../redux/store';

import upcoming from '../../assets/images/upcoming.svg';

import { FiArrowRight } from 'react-icons/fi';

import { setMeetingId } from '../../redux/course/courseSlice';
import {
	checkIfCommunityEnabled,
	checkStudentPlacementReadiness,
	fetchChartData,
	fetchLeetcodeUserName,
} from '../../redux/user/userSlice';

import { encryptData } from '@acciojob/event-analytics';
import { Grid } from '@mui/material';
import Tooltip, {
	TooltipProps,
	tooltipClasses,
} from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Cookies from 'js-cookie';
import { services } from '../../apis';
import EffortModal from '../../components/EffortModal';
import EffortScore from '../../components/EffortScore';
import Announcement from '../../components/home/Announcement';
import BottomBar from '../../components/home/Bottombar';
import ContestCards from '../../components/home/ContestCard';
import DashboardPopUps from '../../components/home/DashboardPopUps';
import LatestModule from '../../components/home/lastestModule';
import { PlacementReadyAnnouncement } from '../../components/home/placementReadyAnnouncement';
import { Leaderboard } from '../../components/leaderboard';
import PageHeading from '../../components/shared/PageHeading';
import { engagements } from '../../engagements';
import { fetchAllContests } from '../../redux/contest/contestSlice';
import { fetchPopUpToDisplay } from '../../redux/popups/popup.thunk';
import { isMainCourseUser } from '../../utils/events';

const LightTooltip = styled(
	({ className, ...props }: TooltipProps) => (
		<Tooltip
			{...props}
			classes={{ popper: className }}
		/>
	)
)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
	},
}));

const Dashboard = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch<AppDispatch>();

	const dummy =
		'https://cdn.pixabay.com/photo/2016/09/01/08/24/smiley-1635449_1280.png';

	const [showNotice, setShowNotice] = useState(false);
	const [notice, setNotice] = useState(false);
	const [noticeData, setNoticeData] = useState('');
	const [solved, setSolved] = useState(true);

	const featureController = useSelector(
		(state: RootState) => state.featureController
	);

	const { controllerJson } = featureController;

	const [pageController, setPageController] =
		useState<any>(null);

	useEffect(() => {
		setPageController(controllerJson['home']);
	}, [controllerJson]);

	const startZoomMeeting = async (
		meetingId: string,
		instructorId: string
	) =>
		await services.courseService.fetchStartUrl(
			meetingId,
			instructorId
		);
	const startZoomMeetingForStudents = async (
		meetingId: string
	) =>
		await services.courseService.fetchStartUrlForStudents(
			meetingId,
			user.firstName,
			user.lastName,
			user.email
		);

	const courseState = useSelector(
		(state: any) => state.course
	);
	const {
		latestAssignment,
		courseID,
		course,
		allCourses,
		courseName,
		leaderboard,
		latestAssignmentData,
		totalAssignmentQuestions,
		totalSolvedAssignmentQuestions,
		totalAssignmentQuestionOverall,
		totalSolvedAssignmentQuestionOverall,
		upcomingMeetings,
	} = courseState;

	const moduleState = useSelector(
		(state: any) => state.module
	);
	const contestState = useSelector(
		(state: any) => state.contest
	);

	const { currentContest } = contestState;

	const userState = useSelector((state: any) => state.user);
	const popupState = useSelector(
		(state: RootState) => state.popup
	);

	const {
		chartData,
		user,
		latestCourseName,
		announcement,
		latestCourseID,
		communityEnabled,
		leetcodeUserName,
		leetcodeLoading,
		userRole,
		isPlacementReady,
	} = userState;

	const { popupToDisplay } = popupState;

	const instructor =
		user !== null
			? user.roles.find((role: any) => role == 'instructor')
			: undefined;
	const role = instructor !== undefined ? 1 : 0;
	const localAccioToken = Cookies.get('acciojobs-token');
	const [latestModuleStatus, setLatestModuleStatus] =
		useState(false);

	const [upcomingLectures, setUpcomingLectures] = useState<
		any[]
	>([]);
	const [upcomingDoubts, setUpcomingDoubts] = useState<
		any[]
	>([]);

	useEffect(() => {
		if (user) {
			dispatch(checkIfCommunityEnabled());
			dispatch(fetchAllContests());
			console.log('Checking>>>>');
			dispatch(checkStudentPlacementReadiness());
			console.log('userState>>>', userState);
		}
	}, [user]);

	useEffect(() => {
		if (upcomingMeetings !== null) {
			const curr = moment().add('15', 'minutes');
			const currDate = moment().format('DD MMM YYYY');
			const currExact = moment();
			console.log('upcomingMeetings', upcomingMeetings);
			const upcomingLectureTemp: any[] = [];
			for (
				let i = 0;
				i < upcomingMeetings.lecture.length;
				i++
			) {
				const temp = { ...upcomingMeetings.lecture[i] };
				const el = upcomingMeetings.lecture[i];
				if (
					currDate ===
					moment(el.start_time).format('DD MMM YYYY')
				) {
					if (currExact < moment(el.end_time)) {
						temp.active = curr <= moment(el.start_time);
						upcomingLectureTemp.push(temp);
					} else {
						temp.active = el.isLive;
						upcomingLectureTemp.push(temp);
					}
				} else if (el.isLive) {
					temp.active = el.isLive;
					upcomingLectureTemp.push(temp);
				}
			}

			setUpcomingLectures(upcomingLectureTemp);

			const upcomingDoubtTemp: any[] = [];
			for (
				let i = 0;
				i < upcomingMeetings.doubt.length;
				i++
			) {
				const temp = { ...upcomingMeetings.doubt[i] };
				const el = upcomingMeetings.doubt[i];
				if (
					currDate ===
					moment(el.start_time).format('DD MMM YYYY')
				) {
					if (currExact < moment(el.end_time)) {
						temp.active = curr <= moment(el.start_time);
						upcomingDoubtTemp.push(temp);
					} else {
						temp.active = el.isLive;
						upcomingDoubtTemp.push(temp);
					}
				} else if (el.isLive) {
					temp.active = el.isLive;
					upcomingDoubtTemp.push(temp);
				}
			}

			setUpcomingDoubts(upcomingDoubtTemp);
		}
	}, [upcomingMeetings]);

	useEffect(() => {
		if (user) {
			if (
				announcement !== null &&
				announcement.userCourseMapping
			) {
				if (
					announcement.announcement.type === 'ANNOUNCEMENT'
				) {
					setNotice(true);
				} else setNotice(false);
				setNoticeData(announcement.announcement.text);
				setShowNotice(true);
			} else {
				// dispatch(fetchAnnouncements());
			}
		}
	}, [user, announcement]);

	useEffect(() => {
		if (
			user !== null &&
			chartData.length == 0 &&
			latestCourseID !== null
		) {
			dispatch(fetchChartData());
		}
	}, [chartData, user, latestCourseID]);

	const setZoomMeetingId = (
		meetingId: string,
		start_url: string,
		title: string
	) => {
		dispatch(setMeetingId(meetingId));
		const myWindow = window.open(
			'',
			new Date().getTime().toString()
		);
		if (role === 0) {
			startZoomMeetingForStudents(meetingId)
				.then((res) => {
					engagements.courseEngagement.joinClass(
						title,
						meetingId
					);
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.location.href = res.join_url;
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.focus();
				})
				.catch((e) =>
					alert(
						'Some error occured in joining the class. Please try again'
					)
				);
		} else {
			startZoomMeeting(meetingId, user.id)
				.then((res) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.location.href = res;
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.focus();
				})
				.catch((e) =>
					alert(
						'Some error occured in joining the class. Please try again'
					)
				);
		}
	};

	useEffect(() => {
		if (user !== null && leetcodeUserName === null) {
			dispatch(fetchLeetcodeUserName());
		}
		console.log('leetcode', leetcodeUserName);
	}, [leetcodeUserName, user]);

	const [isEffortModelOpened, setIsEffortModelOpened] =
		useState(false);

	const initialState = {
		data: '',
		popupType: '',
		showPopup: false,
	};

	const [popUpData, setPopUpData] = useState(initialState);
	const [displayPopUp, setDisplayPopUp] = useState(false);

	useEffect(() => {
		if (user !== null && popupToDisplay === null) {
			dispatch(fetchPopUpToDisplay('home'));
		}
		if (user !== null && popupToDisplay !== null) {
			console.log('popupToDisplay', popupToDisplay);
			setPopUpData(popupToDisplay);
			setDisplayPopUp(popupToDisplay.showPopup);
		}
	}, [popupToDisplay, user]);

	return pageController === null ? (
		<h3>Loading</h3>
	) : (
		<>
			<Grid
				container
				className={'dashboard'}
			>
				<DashboardPopUps
					popUpData={popUpData}
					displayPopUp={displayPopUp}
				/>

				{isEffortModelOpened ? (
					<div className={'effort-model-overlay'}>
						<EffortModal
							closeFunction={setIsEffortModelOpened}
						/>
					</div>
				) : null}
				<Grid
					lg={8}
					sm={8}
					className={`column-1 ${
						popupToDisplay?.shouldShrinkPopup
							? 'home-left-col-1'
							: ''
					}`}
				>
					<PageHeading
						pageName="Welcome Back"
						userName={user !== null ? user.firstName : null}
					/>
					{showNotice ? (
						<Announcement
							noticeType={notice}
							noticeData={noticeData}
						/>
					) : null}

					{isPlacementReady ? (
						<PlacementReadyAnnouncement />
					) : null}

					{/* {pageController['helpline'].status ? (
					<HelplineComponent />
				) : null} */}

					{pageController['latest-module'].status ? (
						<LatestModule
							latestModuleData={latestAssignmentData}
							moduleName={latestCourseName}
						/>
					) : null}

					{pageController['job-sincerity'].status &&
					latestCourseID !== null ? (
						<EffortScore
							openEffortModal={setIsEffortModelOpened}
							showGauge={false}
						/>
					) : null}
					{/* {pageController['streak-chart'].status ? (
					<Chart dataset={chartData} />
				) : null} */}
					{/* <ReferAndEarn /> */}
				</Grid>
				<Grid
					lg={4}
					sm={4}
					className={`column-2 ${
						popupToDisplay?.shouldShrinkPopup
							? 'home-right-col-2'
							: ''
					}`}
				>
					<div className={'upcoming-event'}>
						{upcomingMeetings !== null &&
						(upcomingLectures.length !== 0 ||
							upcomingDoubts.length !== 0) ? (
							<>
								<h3 className={'upcoming-heading'}>
									<span>Upcoming</span> Class
								</h3>
								{upcomingLectures.map(
									(el: any, index: number) => {
										return (
											<div
												className={'event'}
												key={index}
											>
												<div
													className={'event'}
													key={index}
												>
													<div
														className={
															'upcoming-event-date'
														}
													>
														<img
															src={upcoming}
															alt=""
														/>
														<div
															className={
																'upcoming-event-date-data'
															}
														>
															<span>
																{moment(
																	el.start_time
																).format('MMM')}
															</span>
															<p>
																{moment(
																	el.start_time
																).format('DD')}
															</p>
														</div>
													</div>
													<div className={'event-details'}>
														<h3>{el.title}</h3>
														<p>
															{moment(el.start_time).format(
																'hh:mm A'
															)}
														</p>
														<button
															disabled={el.active}
															onClick={() =>
																setZoomMeetingId(
																	el.roomId,
																	el.start_url,
																	el.title
																)
															}
															event-analytics={
																isMainCourseUser()
																	? 'ca5864d9-6879-4040-a03c-98dae2e84ea8'
																	: ''
															}
															data-config={encryptData(
																JSON.stringify({
																	name: el.title,
																})
															)}
														>
															Join Now <FiArrowRight />
														</button>
													</div>
												</div>
											</div>
										);
									}
								)}

								<span className={'divider'}></span>

								{upcomingDoubts.map(
									(el: any, index: any) => {
										return (
											<div
												className={'event'}
												key={index}
											>
												<div
													className={'upcoming-event-date'}
												>
													<img
														src={upcoming}
														alt={''}
													/>
													<div
														className={
															'upcoming-event-date-data'
														}
													>
														<span>
															{moment(el.start_time).format(
																'MMM'
															)}
														</span>
														<p>
															{moment(el.start_time).format(
																'DD'
															)}
														</p>
													</div>
												</div>
												<div className={'event-details'}>
													<h3>{el.title}</h3>
													<p>
														{moment(el.start_time).format(
															'hh:mm A'
														)}
													</p>
													<button
														disabled={el.active}
														onClick={() =>
															setZoomMeetingId(
																el.roomId,
																el.start_url,
																el.title
															)
														}
														event-analytics={
															isMainCourseUser()
																? '98c0b8d4-1308-4c6e-aad7-2017355c3cc3'
																: ''
														}
														data-config={encryptData(
															JSON.stringify({
																name: el.title,
															})
														)}
													>
														Join Now <FiArrowRight />
													</button>
												</div>
											</div>
										);
									}
								)}
							</>
						) : (
							<>
								<h3 className={'upcoming-heading'}>
									<span>Upcoming</span> Class
								</h3>

								<div className={'event'}>
									<p className={'class-to-be-updated'}>
										Class details will be updated soon!
									</p>
								</div>
							</>
						)}
					</div>
					<ContestCards currentContest={currentContest} />
					{communityEnabled && (
						<div className={'user-meta-info'}>
							<div className={'consistency upcoming-event'}>
								<h5>
									<span style={{ color: '#2F80ED' }}>
										Never
									</span>{' '}
									miss
									<br />
									a course update <br />
									{/* <span style={{ color: '#2F80ED' }}>again</span> */}
								</h5>
								<a
									href={
										'https://community.acciojob.com/oauth2/callback#access_token=' +
										localAccioToken
									}
									rel="noreferrer"
									target="_blank"
									event-analytics={
										isMainCourseUser()
											? '12cfd14c-1bcd-4b54-9b70-5eebc86785a0'
											: ''
									}
								>
									<button>
										Enter Community <FiArrowRight />
									</button>
								</a>
							</div>
						</div>
					)}
					{leaderboard.length === 11 ? (
						<Leaderboard leaderboard={leaderboard} />
					) : (
						<div className="no-leaderboard-view-wrapper">
							<p className="text-outer">
								Atleast solve{' '}
								<span style={{ color: '#2E80EC' }}>
									one problem
								</span>{' '}
								to see the{' '}
								<span style={{ color: '#2E80EC' }}>
									leaderboard
								</span>
							</p>
						</div>
					)}
				</Grid>
			</Grid>
			{popupToDisplay?.shouldShrinkPopup ? (
				<BottomBar popUpData={popUpData} />
			) : null}
		</>
	);
};

export default Dashboard;
