import { encryptData } from '@acciojob/event-analytics';
import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
	fetchCourseData,
	setCourseID,
	updateCourse,
} from '../redux/course/courseSlice';
import { isMainCourseUser } from '../utils/events';

const CourseDropDown = () => {
	const location = useLocation();

	const dispatch = useDispatch();

	const CourseState = useSelector(
		(state: any) => state.course
	);
	const { courseID, allCourses } = CourseState;

	const UserState = useSelector((state: any) => state.user);
	const { courseList } = UserState;

	const handleCourseChange = async (newCourseID: any) => {
		// const newCourseID: string = event.target.value;
		console.log('ID -> ', newCourseID);
		if (typeof newCourseID === 'string') {
			await localStorage.setItem(
				'selected-course-token',
				newCourseID
			);
			dispatch(setCourseID(newCourseID));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			console.log('CHECK', allCourses[newCourseID]);
			if (allCourses[newCourseID] === undefined) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				await dispatch(fetchCourseData());
			} else {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				await dispatch(updateCourse(newCourseID));
			}
		}
	};

	useEffect(() => {
		const search = new URLSearchParams(location.search);
		if (courseList.length) {
			const courseId = search.get('courseId');
			if (
				courseId &&
				(courseList as any[]).some(
					(el) => el.id === courseId
				)
			) {
				handleCourseChange(courseId);
			}
		}
	}, [location.pathname, courseList]);

	return (
		<select
			id="moduleDropDown"
			className={'courseDropDown'}
			value={courseID}
			name="Course"
			onChange={async (event) =>
				handleCourseChange(event.target.value)
			}
			event-analytics={
				isMainCourseUser()
					? '97ffd342-1c27-45c2-a39f-94052fa9962e'
					: ''
			}
			data-config={encryptData(
				JSON.stringify({
					name: courseID,
				})
			)}
		>
			{courseList.map((el: any, index: number) => (
				<option
					key={el.id}
					value={el.id}
				>
					{el.title}
				</option>
			))}
		</select>
	);
};

export default CourseDropDown;
