import { Dialog } from '@mui/material';

const StudentFeedbackModal = ({
	scoreModal,
	setScoreModal,
	qcScore,
}: any) => {
	return (
		<div>
			<Dialog
				open={scoreModal}
				onClose={() => setScoreModal(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div>
					<h1 className="feedback-title">
						Feedback Details
					</h1>
					{qcScore && (
						<ul className="feedback-points">
							<li>
								<span>All time QC Score</span>
								{qcScore.allTimeResult.averageQC}
							</li>
							<li>
								<span>Current Cycle QC Score</span>
								{qcScore.currenCycleResult.averageQC}
							</li>
							<li>
								<span>Performance Indicator</span>
								{qcScore.currenCycleResult.performance}
							</li>
							<li>
								<span>Mentor Camera On</span>
								{
									qcScore.currenCycleResult
										.mentorCameraOnPercentage
								}
								%
							</li>
							<li>
								<span>Mentor Joined On Time</span>
								{
									qcScore.currenCycleResult
										.mentorJoinedOnTimePercentage
								}
								%
							</li>
							<li>
								<span>Mentor's Introduction Given</span>
								{
									qcScore.currenCycleResult
										.mentorIntroGivenPercentage
								}
								%
							</li>
							<li>
								<span>Student's Introduction Taken</span>
								{
									qcScore.currenCycleResult
										.studentIntroTakenPercentage
								}
								%
							</li>
							<li>
								<span>Mentor Behaviour (How Nice?)</span>
								{
									qcScore.currenCycleResult
										.mentorGoodBehaviourPercentage
								}
								%
							</li>
							<li>
								<span>Mentor Explanation (How Good?)</span>
								{
									qcScore.currenCycleResult
										.mentorGoodInterviewExplanationPercentage
								}
								%
							</li>
							<li>
								<span>
									Constructive feedback to student
								</span>
								{
									qcScore.currenCycleResult
										.constructiveFeedbackByMentorPercentage
								}
								%
							</li>
						</ul>
					)}
				</div>
			</Dialog>
		</div>
	);
};

export default StudentFeedbackModal;
