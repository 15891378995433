import { Loader } from '@acciojob/loader';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { services } from '../../../apis';
import CourseContentRight from '../../../components/softSkillsDashboard/CourseContentRight';
import CourseSubSection from '../../../components/softSkillsDashboard/CourseSubSection';
import { SoftskillsActions } from '../../../redux/softskills/softskills.slice';
import { getSoftSkillsModuleDetails } from '../../../redux/softskills/softskills.thunk';
import { SoftSkillsModuleType } from '../../../redux/softskills/softskills.type';
import {
	RootState,
	useAppDispatch,
	useAppSelector,
} from '../../../redux/store';

const CourseContent = () => {
	const {
		selectedCourseTitle,
		moduleDetails,
		selectedModuleDetails,
		cachedModuleDetails,
	} = useAppSelector(
		(state: RootState) => state.softskills
	);
	const { user } = useAppSelector(
		(state: RootState) => state.user
	);

	const [softSkillsModules, setSoftSkillsModules] =
		useState<Array<SoftSkillsModuleType>>([]);
	const [selectedModuleId, setSelectedModuleId] =
		useState('');
	const dispatch = useAppDispatch();
	const [defaultTabValue, setDefaultTabValue] = useState(0);

	const handleGoToSection = async (
		id: string,
		activeTabIndex: number
	) => {
		dispatch(
			SoftskillsActions.setSelectedModuleDetails({
				selectedModuleId: id,
				activeTabIndex,
			})
		);
		if (
			cachedModuleDetails &&
			cachedModuleDetails[id] !== undefined
		) {
			dispatch(
				SoftskillsActions.updateModuleDetails({
					id,
				})
			);
		} else {
			dispatch(getSoftSkillsModuleDetails(id));
		}
		setSelectedModuleId(id);
		setDefaultTabValue(0);
	};

	const { id } = useParams();

	const [loadingModules, setLoadingModules] =
		useState(false);

	const getSoftSkillsModules = async (id: string) => {
		setLoadingModules(true);
		try {
			const res =
				await services.softskillsService.getSoftSkillsModules(
					id
				);
			setSoftSkillsModules(res);
			if (res.length > 0) {
				const { selectedModuleId, activeTabIndex } =
					selectedModuleDetails;
				if (selectedModuleId && activeTabIndex) {
					handleGoToSection(
						selectedModuleId,
						activeTabIndex
					);
				} else {
					handleGoToSection(res[0].id, 0);
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			setLoadingModules(false);
		}
	};

	useEffect(() => {
		if (user && id) {
			getSoftSkillsModules(id);
		}
	}, [user, id]);

	useEffect(() => {
		setTimeout(() => {
			const element = document.getElementById(
				'selected-module'
			);
			console.log('element', element);
			if (element) {
				element.scrollIntoView({
					behavior: 'smooth',
					block: 'center',
				});
			}
		}, 200);
	}, [selectedModuleId]);

	return (
		<div className="course-content-container">
			{loadingModules ? (
				<Loader />
			) : (
				<Fragment>
					<div className="course-content-left">
						<div className="course-content-left-top">
							<p className="skill-course-text">
								SKILL COURSE
							</p>
							<h3 className="course-content-left-top-heading">
								{selectedCourseTitle}
							</h3>
						</div>
						<div className="course-content-text">
							COURSE CONTENT
						</div>
						<div className="course-sub-sections">
							{softSkillsModules.map(
								(
									{ id, name, watchTime, assignmentCount },
									index
								) => (
									<CourseSubSection
										key={index}
										id={id}
										name={name}
										watchTime={watchTime}
										assignmentCount={assignmentCount}
										handleGoToSection={() => {
											handleGoToSection(id, 0);
										}}
										selectedModuleId={selectedModuleId}
									/>
								)
							)}
						</div>
					</div>
					<div className="course-content-right">
						<CourseContentRight
							selectedModuleId={selectedModuleId}
							videoContent={moduleDetails.videoContent}
							liveClasses={moduleDetails.liveClasses}
							assignments={moduleDetails.assignments}
							breakoutSession={
								moduleDetails.breakoutSession
							}
							readingContent={moduleDetails.readingContent}
							feedback={moduleDetails.feedback}
							defaultTabValue={defaultTabValue}
						/>
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default CourseContent;
