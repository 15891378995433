export const pickKeyFromAnArray = (
	records: any[],
	keyName: string
) => {
	const finalRecords: any[] = [];
	records.map((record) => {
		finalRecords.push(record[keyName]);
	});
	return finalRecords;
};
