import { createSlice } from '@reduxjs/toolkit';
import {
	checkStudentPlacementReadinessFinder,
	getEligibleSessionsOfUser,
	getMeetingLink,
	getRecommendedSessions,
	getRemainingBookings,
	getSessionDetailsOfUserFromSessionType,
	getSessionWithoutFeedback,
	getSessionsOfUserForAllSessionTypes,
	// getUpcomingSessions,
	getUpcomingSessionsOfUser,
	joinClassZoomLink,
	postFeedback,
	getSessionEligibility,
} from './sessionThunk';

interface StateStatusInterface {
	loading?: boolean;
	error?: {
		state?: boolean;
		message?: string;
	};
}

let initialStateStatus: StateStatusInterface = {
	loading: false,
	error: {
		state: false,
		message: '',
	},
};

interface RemainingSessionInterface {
	[key: string]: {
		remainingBookingLeft: number;
		bookingsDone: number;
	};
}

interface SessionState {
	sessions: any[];
	loading: boolean;
	classLinkload: boolean;
	joinLiveClassData: null;
	studentPlacementReadinessStatus: any;
	studentPlacementReadinessStatusloading: boolean;
	error: {
		state: boolean;
		message: string;
	};
	sessionsWithoutFeedback: any[] & StateStatusInterface;
	modalLoading: boolean;
	modalOpen: boolean;
	feedbackGiven: boolean;
	meetingLink: string;
	userType: string;
	showFeedbackModal: boolean;
	feedbacks: any[];
	videos: any[];
	score: any[];
	allBookingSessions: any;
	allSessionData: any;
	allUpcomingSessionData: any;
	allSessionDetails: any;
	allRecommendedSession_Suggested_Upcoming_Sessions: any;
	remainingBookings: RemainingSessionInterface | null;
	some: StateStatusInterface & { data: any };

	//reducer 'interface'

	stateMentorId: any;
	stateAllAvailableSlots: any;
	stateSessionTypeId: any;
	stateSessionCategory: any;
	stateRescheduleId: any;
	stateSelectedDateTimeForBooking: any;
	stateForRescheduleEnableTab: boolean;
	shouldShowSessionPage: boolean;
}

const initialState: SessionState = {
	sessions: [],
	studentPlacementReadinessStatus: null,
	studentPlacementReadinessStatusloading: true,
	showFeedbackModal: false,
	loading: false,
	classLinkload: false,
	joinLiveClassData: null,
	error: {
		state: false,
		message: '',
	},
	meetingLink: '',
	modalLoading: false,
	//feedback session
	sessionsWithoutFeedback: [],
	modalOpen: false,
	feedbackGiven: false,
	userType: '',
	feedbacks: [],
	videos: [],
	score: [],
	allBookingSessions: null,
	allSessionData: null,
	allUpcomingSessionData: null,
	allSessionDetails: null,
	// Experimental
	some: {
		data: null,
		...initialStateStatus,
	},

	remainingBookings: null,
	//reducer 'state'
	stateMentorId: null,
	stateAllAvailableSlots: [],
	stateSessionTypeId: null,
	stateSessionCategory: null,
	stateRescheduleId: null,
	stateSelectedDateTimeForBooking: null,
	stateForRescheduleEnableTab: false,
	allRecommendedSession_Suggested_Upcoming_Sessions: null,
	shouldShowSessionPage: false,
};

export const sessionSlice = createSlice({
	name: 'sessions',
	initialState,
	reducers: {
		setModalClose: (state) => {
			state.modalOpen = false;
		},
		closeFeedbackModal: (state) => {
			state.showFeedbackModal = false;
		},
		openFeedbackModal: (state) => {
			state.showFeedbackModal = true;
		},
		setUserTypeMentor: (state) => {
			state.userType = 'MENTOR';
		},
		setUserTypeStudent: (state) => {
			state.userType = 'STUDENT';
		},
		setStateMentorId: (state, action) => {
			state.stateMentorId = action.payload;
		},
		setAllAvailableSlots: (state, action) => {
			state.stateAllAvailableSlots = action.payload;
		},
		setStateSessionTypeId: (state, action) => {
			state.stateSessionTypeId = action.payload;
		},
		setStateSessionCategory: (state, action) => {
			state.stateSessionCategory = action.payload;
		},
		setStateRescheduleId: (state, action) => {
			state.stateRescheduleId = action.payload;
		},
		setStateSelectedDateTimeForBooking: (state, action) => {
			state.stateSelectedDateTimeForBooking =
				action.payload;
		},
		setStateForRescheduleEnableTab: (state, action) => {
			state.stateForRescheduleEnableTab = action.payload;
		},
	},
	extraReducers: {
		// ...other actions
		// [getUpcomingSessions.pending.type]: (
		// 	state: SessionState,
		// 	action: ReturnType<typeof getUpcomingSessions.pending>
		// ) => {
		// 	state.loading = true;
		// },
		// [getUpcomingSessions.fulfilled.type]: (
		// 	state: SessionState,
		// 	action: ReturnType<
		// 		typeof getUpcomingSessions.fulfilled
		// 	>
		// ) => {
		// 	state.loading = false;
		// 	state.sessions = action.payload.data.sessionData;
		// 	state.feedbacks = action.payload.data.feedbackData;
		// 	state.error.state = false;
		// 	state.error.message = '';
		// 	state.videos = action.payload.data.videoData;
		// 	state.score = action.payload.data.scoreData;
		// },
		// [getUpcomingSessions.rejected.type]: (
		// 	state: SessionState,
		// 	action: ReturnType<
		// 		typeof getUpcomingSessions.rejected
		// 	>
		// ) => {
		// 	state.loading = false;
		// 	state.error.state = true;
		// 	state.error.message = 'Error, please try again later';
		// },
		[getSessionWithoutFeedback.pending.type]: (
			state: SessionState,
			action: ReturnType<any>
		) => {
			state.loading = true;
		},
		[getSessionWithoutFeedback.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionWithoutFeedback.fulfilled
			>
		) => {
			state.sessionsWithoutFeedback = action.payload.data;
			state.modalOpen =
				state.sessionsWithoutFeedback.length > 0
					? true
					: false;
			state.error.state = false;
			state.error.message = '';
		},
		[getSessionWithoutFeedback.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionWithoutFeedback.rejected
			>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
		[postFeedback.pending.type]: (
			state: SessionState,
			action: ReturnType<typeof postFeedback.pending>
		) => {
			state.modalLoading = true;
		},
		[postFeedback.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<typeof postFeedback.fulfilled>
		) => {
			state.feedbackGiven = true;
			state.modalLoading = false;
		},
		[postFeedback.rejected.type]: (
			state: SessionState,
			action: ReturnType<typeof postFeedback.rejected>
		) => {
			state.modalLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
		[checkStudentPlacementReadinessFinder.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof checkStudentPlacementReadinessFinder.pending
			>
		) => {
			state.studentPlacementReadinessStatus = null;
		},
		[checkStudentPlacementReadinessFinder.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof checkStudentPlacementReadinessFinder.fulfilled
			>
		) => {
			state.studentPlacementReadinessStatus =
				action.payload;
		},
		[checkStudentPlacementReadinessFinder.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof checkStudentPlacementReadinessFinder.rejected
			>
		) => {
			state.studentPlacementReadinessStatus = false;
		},
		[joinClassZoomLink.pending.type]: (
			state: SessionState,
			action: ReturnType<typeof joinClassZoomLink.pending>
		) => {
			console.log('joinClassZoomLink pending');
			state.classLinkload = false;
		},
		[joinClassZoomLink.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<typeof joinClassZoomLink.fulfilled>
		) => {
			console.log('joinClassZoomLink fulfilled', action);
			state.classLinkload = true;
			state.joinLiveClassData = action.payload.data;
		},
		[joinClassZoomLink.rejected.type]: (
			state: SessionState,
			action: ReturnType<typeof joinClassZoomLink.rejected>
		) => {
			state.classLinkload = false;
		},
		[getMeetingLink.pending.type]: (
			state: SessionState,
			action: ReturnType<typeof getMeetingLink.pending>
		) => {
			state.loading = true;
		},
		[getMeetingLink.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<typeof getMeetingLink.fulfilled>
		) => {
			state.meetingLink = action.payload.data;
			state.loading = false;
		},
		[getMeetingLink.rejected.type]: (
			state: SessionState,
			action: ReturnType<typeof getMeetingLink.rejected>
		) => {
			state.modalLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		[getEligibleSessionsOfUser.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getEligibleSessionsOfUser.pending
			>
		) => {
			state.loading = true;
		},
		[getEligibleSessionsOfUser.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getEligibleSessionsOfUser.fulfilled
			>
		) => {
			state.allBookingSessions = action.payload;
			state.loading = false;
		},
		[getEligibleSessionsOfUser.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getEligibleSessionsOfUser.rejected
			>
		) => {
			state.modalLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		[getSessionsOfUserForAllSessionTypes.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionsOfUserForAllSessionTypes.pending
			>
		) => {
			state.loading = true;
		},
		[getSessionsOfUserForAllSessionTypes.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionsOfUserForAllSessionTypes.fulfilled
			>
		) => {
			state.allSessionData = action.payload;
			state.loading = false;
			console.log('action.payload.data', action.payload);
		},
		[getSessionsOfUserForAllSessionTypes.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionsOfUserForAllSessionTypes.rejected
			>
		) => {
			state.modalLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		[getUpcomingSessionsOfUser.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getUpcomingSessionsOfUser.pending
			>
		) => {
			state.loading = true;
		},
		[getUpcomingSessionsOfUser.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getUpcomingSessionsOfUser.fulfilled
			>
		) => {
			state.allUpcomingSessionData = action.payload;
			state.loading = false;
			console.log('action.payload.data', action.payload);
		},
		[getUpcomingSessionsOfUser.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getUpcomingSessionsOfUser.rejected
			>
		) => {
			state.modalLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		[getSessionDetailsOfUserFromSessionType.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionDetailsOfUserFromSessionType.pending
			>
		) => {
			state.loading = true;
		},
		[getSessionDetailsOfUserFromSessionType.fulfilled.type]:
			(
				state: SessionState,
				action: ReturnType<
					typeof getSessionDetailsOfUserFromSessionType.fulfilled
				>
			) => {
				state.allSessionDetails = action.payload;
				state.loading = false;
				console.log('action.payload.data', action.payload);
			},
		[getSessionDetailsOfUserFromSessionType.rejected.type]:
			(
				state: SessionState,
				action: ReturnType<
					typeof getSessionDetailsOfUserFromSessionType.rejected
				>
			) => {
				state.modalLoading = false;
				state.error.state = true;
				state.error.message =
					'Error, please try again later';
			},

		[getRecommendedSessions.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getRecommendedSessions.pending
			>
		) => {
			state.loading = true;
		},
		[getRecommendedSessions.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getRecommendedSessions.fulfilled
			>
		) => {
			state.allRecommendedSession_Suggested_Upcoming_Sessions =
				action.payload;
			state.loading = false;
			console.log('action.payload.data', action.payload);
		},
		[getRecommendedSessions.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getRecommendedSessions.rejected
			>
		) => {
			state.modalLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		[getRemainingBookings.pending.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getRemainingBookings.pending
			>
		) => {
			state.loading = true;
			state.remainingBookings = null;
		},
		[getRemainingBookings.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getRemainingBookings.fulfilled
			>
		) => {
			state.remainingBookings = action.payload;
			state.loading = false;
			console.log('action.payload.data', action.payload);
		},
		[getRemainingBookings.rejected.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getRemainingBookings.rejected
			>
		) => {
			state.loading = false;
			state.remainingBookings = null;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
		[getSessionEligibility.pending.type]: (
			state: SessionState
		) => {
			state.loading = true;
		},
		[getSessionEligibility.fulfilled.type]: (
			state: SessionState,
			action: ReturnType<
				typeof getSessionEligibility.fulfilled
			>
		) => {
			state.loading = false;
			state.shouldShowSessionPage = action.payload;
		},
		[getSessionEligibility.rejected.type]: (
			state: SessionState
		) => {
			state.loading = false;
		},
	},
});

//Exporting Actions
export const {
	setModalClose,
	setUserTypeMentor,
	setUserTypeStudent,
	closeFeedbackModal,
	openFeedbackModal,
	setStateMentorId,
	setAllAvailableSlots,
	setStateSessionCategory,
	setStateSessionTypeId,
	setStateSelectedDateTimeForBooking,
	setStateRescheduleId,
	setStateForRescheduleEnableTab,
} = sessionSlice.actions;
export default sessionSlice.reducer;
