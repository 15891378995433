import React from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import {
	FormDataType,
	FormFieldProps,
} from '../../jobPortal/CompleteProfilePopup';
// import {
// 	FormDataType,
// 	FormFieldProps,
// } from '../CompleteProfilePopup';

const MySelectField = ({
	id,
	label,
	items,
	formData,
	disabled,
	updateFormData,
}: FormFieldProps) => {
	return (
		<div className="form-item">
			<FormControl fullWidth>
				<InputLabel
					required
					disabled={disabled}
					sx={{
						color: 'rgba(0, 0, 0, 0.75)',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
				>
					{label}
				</InputLabel>
				<Select
					required
					disabled={disabled}
					value={formData[id as keyof FormDataType]}
					name={id}
					label={label}
					sx={{
						color: '#000',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
					onChange={(e) => {
						updateFormData(
							e.target.name,
							e.target.value as string
						);
					}}
				>
					{items &&
						items.map((item, idx) => (
							<MenuItem
								key={idx}
								value={item}
							>
								{item}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default MySelectField;
