import React from 'react';
import InfoBox from './InfoBox';
import JobStatusBox from './JobStatusBox';
import JobDescription from './JobDescription';
import ResourceBox from './ResourceBox';

const PopupBody = ({
	eligibility,
	placementFlag,
	popupSource,
	jobDetailsPopup,
	isHold,
	isRejected,
	endAt,
	jd,
	resources,
	disqualificationReasons,
	rejectedReason,
	isHoldReason,
	stageNotes,
}: any) => {
	return (
		<div className="popup-body">
			{placementFlag &&
				popupSource !== 'applications' &&
				jobDetailsPopup.identifier !== '' && (
					<InfoBox
						eligibility={eligibility}
						disqualificationReasons={
							disqualificationReasons
						}
					/>
				)}

			{popupSource == 'applications' &&
				jobDetailsPopup.identifier !== '' && (
					<JobStatusBox
						eligibility={eligibility}
						isHold={isHold}
						isRejected={isRejected}
						endAt={endAt}
						rejectedReason={rejectedReason}
						isHoldReason={isHoldReason}
						stageNotes={stageNotes}
					/>
				)}

			{jd && <JobDescription jd={jd} />}

			{resources && resources.length !== 0 && (
				<ResourceBox resources={resources} />
			)}
		</div>
	);
};
export default PopupBody;
