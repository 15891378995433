import React from 'react';
import { encryptData } from '@acciojob/event-analytics';
import { BiLinkExternal } from 'react-icons/bi';
import { BsCheck2Square } from 'react-icons/bs';
import { IoArrowForwardOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import AccioButton from '../elements/Button';

const ProjectCard = ({ elem, setSelectedProject }: any) => {
	const navigate = useNavigate();

	const statusColorMap = (diff: string): any => {
		const color = [
			{
				color: '#EF574C',
				bg: '#FDE6E4',
				border: 'rgba(239, 87, 76, 0.5)',
			},
			{
				color: '#FF940A',
				bg: '#FFEFDA',
				border: 'rgba(255, 148, 10, 0.5)',
			},
			{
				color: '#12B76A',
				bg: '#E3FFED',
				border: 'rgba(18, 183, 106, 0.5)',
			},
			{
				color: '#505862',
				bg: '#EBF3FE',
				border: '#505862',
			},
		];
		let statusColor = color[1].color;
		let statusBgColor = color[1].bg;
		let statusBorderColor = color[1].border;
		let diffColor = null;
		let diffBgColor = null;
		let diffBorderColor = null;

		switch (diff) {
			case 'Not Started':
				statusColor = color[3].color;
				statusBgColor = color[3].bg;
				statusBorderColor = 'transparent';
				break;
			case 'Ongoing':
				statusColor = color[1].color;
				statusBgColor = color[1].bg;
				statusBorderColor = 'transparent';
				break;
			case 'Completed':
				statusColor = color[2].color;
				statusBgColor = color[2].bg;
				statusBorderColor = 'transparent';
				break;
			case 'HARD':
			case 'Advanced':
				diffColor = color[0].color;
				diffBgColor = color[0].bg;
				diffBorderColor = color[0].border;
				break;
			case 'MEDIUM':
			case 'Intermediate':
				diffColor = color[1].color;
				diffBgColor = color[1].bg;
				diffBorderColor = color[1].border;
				break;
			case 'EASY':
			case 'Beginner':
				diffColor = color[2].color;
				diffBgColor = color[2].bg;
				diffBorderColor = color[2].border;
				break;
			default:
				break;
		}

		return {
			statusColor,
			statusBgColor,
			statusBorderColor,
			diffColor,
			diffBgColor,
			diffBorderColor,
		};
	};

	return (
		<div
			className={'project-card'}
			key={elem.project.id}
		>
			<div className={'projects-info'}>
				<div className={'head-tag'}>
					<h3>{elem.project.name}</h3>

					<div className={'project-tag'}>
						<p
							style={{
								backgroundColor: statusColorMap(
									elem.projectTag
								).statusBgColor,
							}}
							className={'skew-p'}
						></p>
						<p
							className={'data'}
							style={{
								backgroundColor: statusColorMap(
									elem.projectTag
								).statusBgColor,
							}}
						>
							{elem.projectTag !== 'Ongoing'
								? elem.projectTag
								: elem.userStepCompleted + ' Steps'}
						</p>
					</div>
				</div>
				<p
					className={'difficulty'}
					style={{
						color: statusColorMap(elem.project.difficulty)
							.diffColor,
						borderColor: statusColorMap(
							elem.project.difficulty
						).diffBorderColor,
						border: '1px solid',
						backgroundColor: statusColorMap(
							elem.project.difficulty
						).diffBgColor,
					}}
				>
					{elem.project.difficulty}
				</p>
				<p className={'description'}>
					{elem.project.description}
				</p>
				<div className={'pre-requisties'}>
					<p>
						<BsCheck2Square /> Pre-Requisite(s)
					</p>
					<ul>
						{elem.project.prerequisites.map(
							(el: string) => (
								<li
									key={el}
									className={'pre-requisite-card'}
								>
									{el}
								</li>
							)
						)}
					</ul>
				</div>
			</div>
			<div className={'bottom-buttons'}>
				{elem.project.sampleProject.length > 0 ? (
					<AccioButton
						title={'Sample Projects'}
						action={() => setSelectedProject(elem.project)}
						customClass={''}
						className={''}
						variant={'primary'}
						icon={<BiLinkExternal />}
					/>
				) : null}
				<div
					event-analytics={
						'4258d327-4cca-48ec-b48c-11cea7371821'
					}
					data-config={encryptData(
						JSON.stringify({
							name: elem.project.id,
						})
					)}
				>
					<AccioButton
						title={'Start Project'}
						action={() => {
							navigate(
								`/start-project?project=${elem.project.id}`
							);
						}}
						customClass={''}
						className={''}
						variant={'primary'}
						icon={<IoArrowForwardOutline />}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProjectCard;
