import {
	Chip,
	FormControl,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { toast } from 'react-toastify';
import { updatePersonalDetails } from '../../../../../redux/profile/profile.thunk';
import { PersonalDetailsInterface } from '../../../../../redux/profile/profile.type';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../redux/store';
import AccioButton from '../../../../elements/Button';
import { cityOptions } from './homeTownList';

interface InputProps {
	maxLength?: number;
	disabled?: boolean;
	required: boolean;
	value: string | string[] | boolean;
	name: string;
	label: string;
	placeholder: string;
	type:
		| 'text'
		| 'select'
		| 'combined'
		| 'multiSelect'
		| 'email'
		| 'hometown';
	options?: Array<{ value: string; label: string }>;
}

export interface SectionProps {
	title: string;
	inputs: InputProps[];
}

export const PersonalInfoForm = ({
	sections,
	type,
}: {
	sections: SectionProps[];
	type: string;
}) => {
	const formRef = useRef<HTMLFormElement>(null);
	const dispatch = useAppDispatch();

	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);

	const {
		control,
		handleSubmit,
		watch,
		formState: { errors, isDirty },
	} = useForm<PersonalDetailsInterface>();

	const currentState = watch('currentState');

	const isProfileLocked = () => {
		return personalDetails.data?.isProfileLocked ?? false;
	};

	const onSubmit = (data: PersonalDetailsInterface) => {
		console.log('PersonalDetailsData>>>', data);

		if (data.otherCodingSkills) {
			if (data.otherCodingSkills.length > 5) {
				toast.warning('You can only select upto 5 Skills!');
			} else {
				dispatch(
					updatePersonalDetails({
						data: {
							...data,
						},
					})
				);
			}
		} else {
			dispatch(
				updatePersonalDetails({
					data: {
						...data,
					},
				})
			);
		}
	};

	const renderInput = (input: InputProps) => {
		switch (input.type) {
			case 'text':
				return (
					<Controller
						name={
							input.name as keyof PersonalDetailsInterface
						}
						control={control}
						disabled={
							input.disabled
								? input.disabled
								: isProfileLocked()
						}
						defaultValue={input.value}
						render={({ field }) => (
							<>
								<p className="mui-custom-label">
									{input.label}
									{input.required ? (
										<span style={{ color: '#EF4444' }}>
											{' '}
											*
										</span>
									) : (
										''
									)}
								</p>
								<TextField
									{...field}
									required={input.required}
									placeholder={input.placeholder}
									error={Boolean(
										input?.maxLength === 10 &&
											isNaN(Number(field.value))
									)}
									helperText={
										input?.maxLength === 10 &&
										isNaN(Number(field.value))
											? 'Please enter a valid number'
											: ''
									}
									fullWidth
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '11px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor:
												input?.disabled || isProfileLocked()
													? 'not-allowed'
													: 'default',
										},
									}}
									inputProps={{
										maxLength: input?.maxLength,
									}}
								/>
							</>
						)}
					/>
				);
			case 'email':
				return (
					<Controller
						name={
							input.name as keyof PersonalDetailsInterface
						}
						disabled={
							input.disabled
								? input.disabled
								: isProfileLocked()
						}
						control={control}
						defaultValue={input.value}
						render={({ field }) => (
							<>
								<p className="mui-custom-label">
									{input.label}
									{input.required ? (
										<span style={{ color: '#EF4444' }}>
											{' '}
											*
										</span>
									) : (
										''
									)}
								</p>
								<TextField
									{...field}
									required={input.required}
									type={input.type}
									placeholder={input.placeholder}
									error={
										!!errors[
											input.name as keyof PersonalDetailsInterface
										]
									}
									helperText={
										errors[
											input.name as keyof PersonalDetailsInterface
										]?.message
									}
									fullWidth
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '11px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor:
												input?.disabled || isProfileLocked()
													? 'not-allowed'
													: 'default',
										},
									}}
								/>
							</>
						)}
					/>
				);
			case 'select':
				return (
					<FormControl fullWidth>
						<p className="mui-custom-label">
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>
						<Controller
							disabled={
								input.disabled
									? input.disabled
									: isProfileLocked()
							}
							defaultValue={input.value}
							name={
								input.name as keyof PersonalDetailsInterface
							}
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									required={input.required}
									displayEmpty
									placeholder={input.placeholder}
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor:
												input?.disabled || isProfileLocked()
													? 'not-allowed !important'
													: 'default',
										},
									}}
									renderValue={(selected) => {
										if (!selected) {
											return input.placeholder;
										}
										return input.options?.find(
											(item) => item.value === selected
										)?.label;
									}}
								>
									<MenuItem
										value=""
										disabled
									>
										{input.placeholder}
									</MenuItem>
									{input.options?.map((option) => (
										<MenuItem
											key={option.value}
											value={option.value}
											sx={{
												cursor: isProfileLocked()
													? 'not-allowed'
													: 'default',
											}}
										>
											{option.label}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
				);
			case 'multiSelect':
				return (
					<FormControl fullWidth>
						<p className="mui-custom-label">
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>
						<Controller
							disabled={
								input.disabled
									? input.disabled
									: isProfileLocked()
							}
							name={
								input.name as keyof PersonalDetailsInterface
							}
							control={control}
							defaultValue={input.value}
							render={({ field }) => (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
										alignItems: 'center',
										gap: '16px',
									}}
								>
									<Select
										{...field}
										displayEmpty
										multiple
										required={input.required}
										placeholder={input.placeholder}
										sx={{
											width: '100%',
											'& .MuiInputBase-input': {
												padding: '10px 12px',
												fontSize: '14px',
												color: '#8C9AAC',
												margin: ' 0px !important',
												cursor:
													input?.disabled ||
													isProfileLocked()
														? 'not-allowed'
														: 'default',
											},
										}}
										onChange={(e) => {
											console.log('change>>>', e);
											if (e.target.value) {
												if (
													(e.target.value as string)
														.length > 5
												) {
													toast.warning(
														'You can only select 5!'
													);
												}
											}
											field.onChange(e);
										}}
										renderValue={(selected) => {
											console.log('Selected?>>>', selected);
											if (selected) {
												if (
													(selected as string).length === 0
												) {
													return input.placeholder;
												} else {
													return 'Select More';
												}
											}
										}}
									>
										<MenuItem
											value=""
											disabled
											sx={{ fontSize: '14px' }}
										>
											{input.placeholder}
										</MenuItem>
										{input.options?.map((option) => (
											<MenuItem
												key={option.value}
												value={option.value}
											>
												{option.label}
											</MenuItem>
										))}
									</Select>

									<div
										style={{
											width: '100%',
											display: 'flex',
											alignItems: 'center',
											overflowX: 'auto',
											overflowY: 'hidden',
											border: '1px solid #ccc',
											borderRadius: '4px',
											height: '44px',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										}}
									>
										{(field.value as string[]).length >
										0 ? (
											(field.value as string[]).map(
												(selectedValue: string) => {
													const option =
														input.options?.find(
															(option) =>
																option.value ===
																selectedValue
														);
													return (
														option && (
															<Chip
																key={selectedValue}
																label={option.label}
																sx={{
																	margin: '4px',
																	fontSize: '10px',
																	color: '#2F80ED',
																	backgroundColor:
																		'#F0F4FA',
																}}
															/>
														)
													);
												}
											)
										) : (
											<Chip
												label={'Nothing Selected'}
												variant="outlined"
												sx={{
													margin: '4px',
													fontSize: '10px',
													color: '#2F80ED',
												}}
											/>
										)}
									</div>
								</div>
							)}
						/>
					</FormControl>
				);

			case 'combined':
				return (
					<FormControl
						variant="outlined"
						fullWidth
					>
						<p className="mui-custom-label">
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>

						<div className="mobile-input">
							<Controller
								disabled={
									input.disabled
										? input.disabled
										: isProfileLocked()
								}
								defaultValue={input.value}
								name={
									`${input.name}` as keyof PersonalDetailsInterface
								}
								control={control}
								render={({
									field: { onChange, value },
								}) => (
									<PhoneInput
										countryCodeEditable={false}
										disableDropdown={true}
										disableCountryCode={true}
										value={('+91' + value) as string}
										country="in"
										onChange={onChange}
										inputStyle={{
											width: ' 100%',
											paddingTop: '20px',
											paddingBottom: '20px',
											marginTop: '50px',
										}}
										inputClass="react-phone-input-2-custom"
										inputProps={{
											name: input.name,
											required: true,
										}}
									/>
								)}
							/>
						</div>
					</FormControl>
				);

			case 'hometown':
				return (
					<FormControl fullWidth>
						<p className="mui-custom-label">
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>
						<Controller
							disabled={
								input.disabled
									? input.disabled
									: isProfileLocked()
							}
							name={
								input.name as keyof PersonalDetailsInterface
							}
							control={control}
							defaultValue={input.value}
							render={({ field }) => (
								<div
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										width: '100%',
										alignItems: 'center',
										gap: '16px',
									}}
								>
									<Select
										{...field}
										displayEmpty
										required={input.required}
										placeholder={input.placeholder}
										sx={{
											width: '100%',
											'& .MuiInputBase-input': {
												padding: '10px 12px',
												fontSize: '14px',
												color: '#8C9AAC',
												margin: ' 0px !important',
												cursor:
													input?.disabled ||
													isProfileLocked()
														? 'not-allowed'
														: 'default',
											},
										}}
										onChange={(e) => {
											if (e.target.value) {
												field.onChange(e);
											}
										}}
										renderValue={(selected) => {
											console.log('Selected?>>>', selected);
											if (selected) {
												if (
													(selected as string).length === 0
												) {
													return input.placeholder;
												} else {
													return selected;
												}
											}
										}}
									>
										<MenuItem
											value=""
											disabled
											sx={{ fontSize: '14px' }}
										>
											{input.placeholder}
										</MenuItem>
										{currentState &&
											cityOptions[currentState].map(
												(option, i) => (
													<MenuItem
														key={option.value}
														value={option.value}
													>
														{option.label}
													</MenuItem>
												)
											)}
									</Select>
								</div>
							)}
						/>
					</FormControl>
				);

			default:
				return null;
		}
	};

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmit(onSubmit)}
			className="main-form"
		>
			{sections.map(
				(section: SectionProps, sectionIndex: number) => (
					<div
						key={sectionIndex}
						className="section-wrapper"
					>
						{sectionIndex != 0 ? (
							<h3 className="section-title">
								{section.title}
							</h3>
						) : null}
						<div className="input-flex-wrap">
							{section.inputs.map(
								(input: InputProps, inputIndex: number) => (
									<div
										className="input-wrapper"
										key={inputIndex}
										style={{
											width:
												input.name == 'city'
													? '100%'
													: section.title == 'Profile Links'
													? '32%'
													: section.title == 'Parents Info'
													? '49%'
													: sectionIndex != 0
													? '100%'
													: '',
										}}
									>
										{renderInput(input)}
									</div>
								)
							)}
						</div>
					</div>
				)
			)}

			<div className="save-form">
				<p className="text">
					Do you want to save changes ?
				</p>
				<AccioButton
					// disabled={!isDirty}
					variant={
						isProfileLocked() ? 'disable' : 'primary'
					}
					disabled={isProfileLocked()}
					btnType="submit"
					title={'Save'}
				/>
			</div>
		</form>
	);
};
