import { Loader } from '@acciojob/loader';
import moment from 'moment';
import React from 'react';
import { BsFillFilterSquareFill } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Filters, SessionTabs } from '../mentorTypes';
import { checkIntime } from '../mentorUtils';

const SessionsTable = ({
	sessionsData,
	fetchMoreDataCallback,
	tab,
	obj = '',
}: any) => {
	const {
		UPCOMING_ONGOING,
		PENDING,
		DIDNT_HAPPEN,
		COMPLETED,
	} = SessionTabs;

	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { sessionsPage, loading } = MentorState;

	return (
		<div
			id="sessions"
			className={`sessions-table ${
				tab === UPCOMING_ONGOING
					? 'upcoming-sessions'
					: tab === DIDNT_HAPPEN
					? 'didnt-happen-sessions'
					: tab === COMPLETED
					? 'completed-sessions'
					: 'pending-sessions'
			}`}
			style={{ height: '500px', overflow: 'scroll' }}
		>
			{loading ? <Loader /> : ''}
			<ul className="table-head">
				<li>Session Type</li>
				<li>Name & Contact</li>
				<li>Start Time</li>
				<li>Duration (min)</li>
				{tab !== DIDNT_HAPPEN ? <li>Score</li> : ''}
				{tab === PENDING ? (
					<li className="pending-status">
						Status
						<BsFillFilterSquareFill
							className="filter-icon"
							onClick={() =>
								obj.setDisplayFilters(!obj.displayFilters)
							}
						/>
						{obj.displayFilters && (
							<>
								<div className="filter-container">
									<ul>
										<li
											onClick={() =>
												obj.handleFilterSelect(
													Filters.INTERNAL_ZOOM
												)
											}
											className={`${
												obj.selectedFilter ===
													Filters.INTERNAL_ZOOM &&
												'filter-selected'
											}`}
										>
											ZOOM
										</li>
										<li
											onClick={() =>
												obj.handleFilterSelect(
													Filters.EXTERNAL_GMEET
												)
											}
											className={`${
												obj.selectedFilter ===
													Filters.EXTERNAL_GMEET &&
												'filter-selected'
											}`}
										>
											GMEET
										</li>
										<li
											onClick={() =>
												obj.handleFilterSelect(
													Filters.FEEDBACK_PENDING
												)
											}
											className={`${
												obj.selectedFilter ===
													Filters.FEEDBACK_PENDING &&
												'filter-selected'
											}`}
										>
											FEEDBACK PENDING
										</li>
										<li
											onClick={() =>
												obj.handleFilterSelect(
													Filters.RECORDING_PENDING
												)
											}
											className={`${
												obj.selectedFilter ===
													Filters.RECORDING_PENDING &&
												'filter-selected'
											}`}
										>
											RECORDING PENDING
										</li>
									</ul>
								</div>
							</>
						)}
					</li>
				) : (
					''
				)}
				{tab === DIDNT_HAPPEN ? <li>Status</li> : ''}
				{tab === PENDING ? <li>Action</li> : ''}
				{tab === COMPLETED || tab === PENDING ? (
					<li>Feedback Time</li>
				) : (
					''
				)}
				{tab === UPCOMING_ONGOING ? <li>Action</li> : ''}
			</ul>
			<InfiniteScroll
				dataLength={sessionsData.length}
				next={fetchMoreDataCallback}
				hasMore={true}
				loader={''}
				scrollableTarget={'sessions'}
			>
				{sessionsData.map((sessionData: any) => (
					<ul
						key={sessionData.sessionId}
						className="table-row"
					>
						<li>{sessionData.sessionType}</li>
						<li>
							<b>{sessionData.studentName.toUpperCase()}</b>
							<p>{sessionData.studentPhoneNumber}</p>
							<p>
								<b>BM: </b>
								{sessionData.bmPhoneNumber}
							</p>
						</li>
						<li>
							<>
								{moment(sessionData.sessionDate).format(
									'LLL'
								)}
							</>
						</li>
						<li>{sessionData.sessionDuration}</li>
						{
							<li>
								{tab !== 'DIDNT_HAPPEN'
									? sessionData.score
									: sessionData.sessionStatus}
							</li>
						}

						{tab === PENDING && (
							<li>
								<div>
									<b>Platform</b> <br />{' '}
									<span>
										{sessionData.platform ===
										Filters.INTERNAL_ZOOM
											? 'ZOOM'
											: 'GMEET'}
									</span>
								</div>
								<b>Pending</b>
								{!sessionData.mentorFeedbackFilled && (
									<p>Feedback Pending</p>
								)}
								{sessionData.platform ===
									Filters.EXTERNAL_GMEET &&
									!sessionData.recordingUploaded && (
										<p>Recording Pending</p>
									)}
							</li>
						)}

						{tab === PENDING &&
							!sessionData.mentorFeedbackFilled && (
								<li>
									<button
										className="action-btn"
										onClick={() =>
											obj.handleFillFeedback(sessionData)
										}
									>
										Fill feedback
									</button>
								</li>
							)}

						{tab === PENDING &&
							sessionData.platform ===
								Filters.EXTERNAL_GMEET &&
							!sessionData.recordingUploaded && (
								<li>
									<button
										className="action-btn"
										onClick={() =>
											obj.handleRecording(
												sessionData.sessionId
											)
										}
									>
										Upload Recording
									</button>
								</li>
							)}

						{tab === UPCOMING_ONGOING && (
							<li>
								{obj.checkCanCancel(
									sessionData.sessionDate
								) && (
									<button
										className="action-btn"
										disabled={
											obj.disabledSessionId ===
											sessionData.sessionId
										}
										onClick={() =>
											obj.handleCancelSession(
												sessionData.sessionId
											)
										}
									>
										Cancel
									</button>
								)}
								{sessionData.sessionId ===
									obj.latestUpcomingSessionId &&
									obj.showDidntHappen && (
										<button
											className="action-btn"
											onClick={() =>
												obj.handleDidntHappen(sessionData)
											}
										>
											Didn't Happen
										</button>
									)}
								{sessionData.sessionId ===
									obj.latestUpcomingSessionId &&
									obj.showJoinNow && (
										<button
											className="action-btn"
											onClick={() =>
												obj.handleJoinNow(sessionData)
											}
										>
											Join
										</button>
									)}
								{sessionData.sessionId ===
									obj.latestUpcomingSessionId &&
									obj.showGmeet && (
										<button
											className="action-btn"
											onClick={() =>
												obj.handleGmeet(
													sessionData.sessionId
												)
											}
										>
											Shifted To G-Meet
										</button>
									)}
								{obj.checkShowFeedback(
									sessionData,
									UPCOMING_ONGOING
								) &&
									obj.showDidntHappen && (
										<button
											className="action-btn"
											onClick={() =>
												obj.handleFillFeedback(sessionData)
											}
										>
											Fill feedback
										</button>
									)}
							</li>
						)}

						{tab === COMPLETED ||
						(tab === PENDING &&
							sessionData.mentorFeedbackFilled) ? (
							<li>
								{checkIntime(sessionData) === 'IN_TIME'
									? 'ON TIME'
									: checkIntime(sessionData)}
							</li>
						) : (
							''
						)}
					</ul>
				))}
				{sessionsData.length > 0 && sessionsPage > 1 ? (
					<Waypoint onEnter={fetchMoreDataCallback} />
				) : (
					''
				)}
			</InfiniteScroll>
		</div>
	);
};

export default SessionsTable;
