import { makeServices } from '../utils';
import { IdleEngagement } from './IdleEngagement';
import { CourseEngagement } from './CourseEngagement';
import { SessionEngagement } from './SessionEngagement';

const idleEngagement = new IdleEngagement();
const courseEngagement = new CourseEngagement();
const sessionEngagement = new SessionEngagement();

export interface Engagements {
	idleEngagement: IdleEngagement;
	courseEngagement: CourseEngagement;
	sessionEngagement: SessionEngagement;
}

export const { ServicesProvider, useServices } =
	makeServices<Engagements>({
		idleEngagement,
		courseEngagement,
		sessionEngagement,
	});

export const engagements = {
	idleEngagement,
	courseEngagement,
	sessionEngagement,
};
