export enum otherCodingSkillsOptions {
	Spring = 'Spring',
	Angular = 'Angular',
	Ruby_on_rails = 'Ruby on rails',
	PHP = 'PHP',
	NET = '.NET',
	Django = 'Django',
	Flask = 'Flask',
	Springboot = 'Springboot',
	Hibernate = 'Hibernate',
	Selenium_Testing = 'Selenium/Testing',
	Salesforce = 'Salesforce',
	Service_Now = 'Service Now',
	Node = 'Node',
	React = 'React',
	Vue = 'Vue',
	Express = 'Express',
	Mongo_DB = 'Mongo DB',
	SQL = 'SQL',
	AWS = 'AWS',
	HTML = 'HTML',
	CSS = 'CSS',
	Wordpress = 'Wordpress',
	basic_dsa = 'Basic DSA',
	intermediate_dsa = 'Intermediate DSA',
	advanced_dsa = 'Advanced DSA',
	oops = 'OOPS',
	os = 'OS',
	networking = 'Networking',
	java = 'Java',
	cpp = 'C++',
	python = 'Python',
	javascript = 'Javascript',
}

export enum preferredCodingLanguageOptions {
	Java = 'Java',
	Python = 'Python',
	Javascript = 'Javascript',
	CPP_C = 'C/C++',
}

export enum homeStateOptions {
	Andhra_Pradesh = 'Andhra Pradesh',
	Arunachal_Pradesh = 'Arunachal Pradesh',
	Assam = 'Assam',
	Bihar = 'Bihar',
	Chhattisgarh = 'Chhattisgarh',
	Goa = 'Goa',
	Gujarat = 'Gujarat',
	Haryana = 'Haryana',
	Himachal_Pradesh = 'Himachal Pradesh',
	Jharkhand = 'Jharkhand',
	Karnataka = 'Karnataka',
	Kerala = 'Kerala',
	Madhya_Pradesh = 'Madhya Pradesh',
	Maharashtra = 'Maharashtra',
	Manipur = 'Manipur',
	Meghalaya = 'Meghalaya',
	Mizoram = 'Mizoram',
	Nagaland = 'Nagaland',
	Odisha = 'Odisha',
	Punjab = 'Punjab',
	Rajasthan = 'Rajasthan',
	Sikkim = 'Sikkim',
	Tamil_Nadu = 'Tamil Nadu',
	Telangana = 'Telangana',
	Tripura = 'Tripura',
	Uttar_Pradesh = 'Uttar Pradesh',
	Uttarakhand = 'Uttarakhand',
	West_Bengal = 'West Bengal',
	Andaman_and_Nicobar_Islands = 'Andaman and Nicobar Islands',
	Chandigarh = 'Chandigarh',
	Dadra_Diu = 'Dadra & Nagar Haveli and Daman & Diu',
	Delhi = 'Delhi',
	Jammu_and_Kashmir = 'Jammu and Kashmir',
	Lakshadweep = 'Lakshadweep',
	Puducherry = 'Puducherry',
	Ladakh = 'Ladakh',
}

export enum collegeDegreeOptions {
	BTech_BE = 'BTech./BE',
	MTech_ME = 'MTech./ME',
	BCA = 'BCA',
	MCA = 'MCA',
	BBA = 'BBA',
	MBA = 'MBA',
	BSc = 'BSc.',
	MSc = 'MSc',
	BCom = 'BCom',
	BA = 'BA',
	MA = 'MA',
	BEd = 'B.Ed.',
	LLB = 'LLB',
	BArch = 'BArch.',
	BDes = 'BDes.',
	CA = 'CA',
	BJMC = 'BJMC',
	BHM = 'BHM',
	Other = 'Other',
}

export enum bachelorCollegeDegreeOptions {
	BTech_BE = 'BTech./BE',
	BCA = 'BCA',
	BBA = 'BBA',
	BSc = 'BSc.',
	BCom = 'BCom',
	BA = 'BA',
	BEd = 'BE,d.',
	LLB = 'LLB',
	BArch = 'BArch.',
	BDes = 'BDes.',
	CA = 'CA',
	BJMC = 'BJMC',
	BHM = 'BHM',
	Other = 'Other',
}

export enum masterCollegeDegreeOptions {
	MTech_ME = 'MTech./ME',
	MCA = 'MCA',
	MBA = 'MBA',
	MSc = 'MSc',
	Other = 'Other',
}

export enum collegeDepartmentOptions {
	CSE = 'Computer Science/CSE/Other CS related branch',
	Electrical = 'Electrical/Other electrical related branches',
	Chemical_Engineering = 'Chemical Engineering',
	Civil = 'Civil',
	Mechanical = 'Mechanical/Other mechanics related branch',
	Aeronautical = 'Aeronautical/Aerospace',
	Biotech = 'Biotech/Biomedical',
	Ceramic = 'Ceramic',
	Communications = 'Communications',
	Physics = 'Physics',
	Chemistry = 'Chemistry',
	Mathematics = 'Mathematics',
	English = 'English/Literature',
	Metallurgical = 'Metallurgical/Mining',
	Textile = 'Textile',
	Others = 'Others',
	Agriculture = 'Agriculture',
	IT = 'IT',
}

export enum jobStatusOptions {
	Working_Professional = 'Working_Professional',
	Student = 'Student',
	Unemployed = 'Unemployed',
}

export enum jobTypeOptions {
	Coding_Related = 'Coding Related',
	Not_Coding_Related = 'Not Coding Related',
}

export enum placementStatusOptions {
	Placed = 'Placed',
	Autoplaced = 'Autoplaced',
	Not_placed = 'Not placed',
	Internship = 'Internship',
}

export enum genderOptions {
	Male = 'Male',
	Female = 'Female',
	Others = 'Others',
}

export enum precoursePerformanceTagOptions {
	Bad = 'Bad',
	Average = 'Average',
	Good = 'Good',
}

export enum preferredSpeakingLanguageOptions {
	English = 'English',
	Hindi = 'Hindi',
	Bengali = 'Bengali',
	Gujarati = 'Gujarati',
	Kannada = 'Kannada',
	Malayalam = 'Malayalam',
	Marathi = 'Marathi',
	Tamil = 'Tamil',
	Telugu = 'Telugu',
}

export enum enrollmentMethodOptions {
	PaP = 'PaP',
	Moneyback = 'Moneyback',
	Upfront = 'Upfront',
}

export enum paymentTypeOptions {
	loan = 'loan',
	oneshot = 'oneshot',
}

export enum paymentStatusOptions {
	booking_received = 'booking received',
	total_received = 'total received',
	not_initiated = 'not initiated',
	initiated = 'initiated',
	process_stopped = 'process stopped',
}

export enum RoleEnum {
	PrecourseUser = 'precourse-user',
	OnboardedUser = 'onboarded-user',
	MainCourseUser = 'maincourse-user',
	Mentor = 'mentor',
	Instructor = 'instructor',
	Admin = 'admin',
	BatchManager = 'batch-manager',
	PlaygroundUser = 'playground-user',
}

export enum UserTag {
	UNASSIGNED = 'Un-assigned',
	ALARMING = 'Alarming',
	POOR = 'Poor',
	AVERAGE = 'Average',
	ONPOINT = 'On Point',
	LEADING = 'Leading',
}

export enum AnnouncementType {
	ANNOUNCEMENT = 'ANNOUNCEMENT',
	HEADER = 'HEADER',
}

export interface GeneralProfileFormFields {
	userId: string | null;
	firstName: string | null;
	lastName: string | null;
	whatsappNumber: string | null;
	gender: string | null;
	jobStatus: jobStatusOptions | null;
	jobType: jobTypeOptions | null;
	class12Percentage: string | null;
	class10Percentage: string | null;
	graduationYear: string | null;
	graduationPercentage: string | null;
	collegeName: string | null;
	collegeDepartment: collegeDepartmentOptions | null;
	collegeDegree: collegeDegreeOptions | null;
	postGraduationYear: string | null;
	postGraduationPercentage: string | null;
	pgCollegeName: string | null;
	pgCollegeDepartment: collegeDepartmentOptions | null;
	pgCollegeDegree: collegeDegreeOptions | null;
	preferredSpeakingLanguage:
		| preferredSpeakingLanguageOptions[]
		| null;
	parentsContact: string | null;
	homeState: homeStateOptions | null;
	dateOfBirth: Date | null;
	fatherName: string | null;
}

export interface PlacementProfileFormFields {
	userId: string | null;
	currentState: string | null;
	currentCity: string | null;
	resume: string | null;
	githubProfileLink: string | null;
	preferredCodingLanguage: preferredCodingLanguageOptions | null;
	otherCodingSkills: otherCodingSkillsOptions[] | null;
	workExperienceMonths: string | null;
	currentCompany: string | null;
	currentCtc: string | null;
	noticePeriod: string | null;
	collegeBacklogs: string | null;
	leetcodeUserName?: string | null;
}
