import Modal from '@mui/joy/Modal';
import { EditProfileModal } from './EditProfile/EditProfileModal';

export const StudentProfileModal = ({
	type,
	state,
	setState,
}: any) => {
	return (
		<div>
			<Modal
				open={state}
				onClose={() => setState(false)}
				className="modal-container"
			>
				<div className="profile-modal-wrapper">
					{type == 'edit-profile' ? (
						<EditProfileModal />
					) : null}
				</div>
			</Modal>
		</div>
	);
};
