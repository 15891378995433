import React from 'react';

import { BsFillArrowRightCircleFill } from 'react-icons/bs';

const FoundCompany = () => {
	return (
		<div className="offcampus-found-company-card-wrapper">
			<p className="offcampus-found-company-card-heading">
				Found a Job Opportunity !
			</p>
			<button
				className="offcampus-found-company-card-button"
				onClick={() =>
					window.open('https://tally.so/r/nG6RaQ', '_blank')
				}
				event-analytics={
					'e6a8597a-30c3-4bd4-a7c8-6a7ae0b2610b'
				}
			>
				<span className="text">Share with us</span>
				<span className="icon">
					<BsFillArrowRightCircleFill />
				</span>
			</button>
		</div>
	);
};

export default FoundCompany;
