import { DifficultyTypeEnum } from '../redux/gym/type';

export const DifficultyColors = {
	[DifficultyTypeEnum.EASY]: {
		color: '#12B76A',
		backgroundColor: '#E3FFED',
	},
	[DifficultyTypeEnum.MEDIUM]: {
		color: '#FFF',
		backgroundColor: '#FAC515',
	},
	[DifficultyTypeEnum.HARD]: {
		color: '#EF574C',
		backgroundColor: '#FDE6E4',
	},
};
