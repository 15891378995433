import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';

export class PopupService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getPopUpToDisplay(page: string) {
		console.log('getPopUpToDisplay', page);
		const url = `${this.endpoint}/popup/?page=${page}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('data1234', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async recordClick(clickData: any) {
		const url = `${this.endpoint}/clicks/record-clicks`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { ...clickData },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async recordView(clickData: any) {
		const url = `${this.endpoint}/views/record-view`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { ...clickData },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async recordRating(ratingData: any) {
		const url = `${this.endpoint}/feedback/record-feedback`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { ...ratingData },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async sendPopupDataToPipeDream(
		selectedPhone: string,
		referralLink: string
	) {
		const url = 'https://eof6gts6yj4wtsj.m.pipedream.net';
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { selectedPhone, referralLink },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getSectionalFeedbackPopup(section: string) {
		const url = `${this.endpoint}/popup/section/${section}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
