import React, { useEffect, useState } from 'react';
import data from './playground.json';
import { FaStar } from 'react-icons/fa';
import { BiShareAlt } from 'react-icons/bi';
import {
	BsArrowRightCircleFill,
	BsPlayCircleFill,
	BsCodeSlash,
	BsExclamationTriangle,
	BsCheckCircle,
} from 'react-icons/bs';
import { TbPhoneOutgoing, TbStack2 } from 'react-icons/tb';
import {
	FiUpload,
	FiLoader,
	FiArrowRight,
} from 'react-icons/fi';
import { IoMdCloudUpload } from 'react-icons/io';
import { SiNintendogamecube } from 'react-icons/si';
import highFive from './../../assets/images/high-five.svg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { services } from '../../apis';
import { notifySuccess } from '../../components/notification';
import Loader from '../../components/Loader';
import {
	fetchCourseData,
	setCourseID,
	updateCourse,
} from '../../redux/course/courseSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import VideoModal from './VideoModal';
import { RootState } from '../../redux/store';
import ReactPlayer from 'react-player';

const Playground = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const CourseState = useSelector(
		(state: any) => state.course
	);
	const { courseID, allCourses } = CourseState;

	const UserState = useSelector((state: any) => state.user);
	const { courseList, user } = UserState;

	const featureController = useSelector(
		(state: RootState) => state.featureController
	);

	const { controllerJson } = featureController;

	const [pageController, setPageController] =
		useState<any>(null);

	useEffect(() => {
		setPageController(controllerJson['playground']);
	}, [controllerJson]);

	console.log('play', pageController);

	const [onBoardingData, setOnBoardingData] =
		useState<any>(null);
	const [loading, setLoading] = useState(true);
	const [isaDocStatus, setIsaDocStatus] = useState<
		string | null
	>(null);
	const [uploadDocStatus, setUploadDocStatus] = useState<
		string | null
	>(null);
	const [enrollmentData, setEnrollmentData] =
		useState<any>(null);
	const [deadline, setDeadline] = useState<number>(0);

	const requestCallback = async () => {
		const data =
			await services.userService.playgroundCallBack();
		notifySuccess('We will reach out to you soon !');
	};

	const getOnboardingData = async () => {
		setLoading(true);
		const data =
			await services.userService.playgroundOnboardingDetails();
		console.log('playground', data);
		setOnBoardingData(data);
		if (data.userOnboardingDetails !== null) {
			setIsaDocStatus(
				data.userOnboardingDetails.isaDocumentStatus
			);
			setUploadDocStatus(
				data.userOnboardingDetails.personalDocumentStatus
			);
			const deadline = moment(data.isaDeadline.value);
			const curr = moment();
			console.log(
				'deadline',
				deadline,
				curr,
				deadline.diff(curr, 'days')
			);
			setDeadline(deadline.diff(curr, 'days'));
		}
		setLoading(false);
	};

	const getUserEnrollment = async () => {
		setLoading(true);
		const data =
			await services.userService.playgroundUserEnrollmentDetails();
		console.log('playground', data);
		setEnrollmentData(data);
		setLoading(false);
	};

	useEffect(() => {
		if (user !== null && onBoardingData === null) {
			getOnboardingData();
			getUserEnrollment();
		}
	}, [onBoardingData, user]);

	const handleCourseChange = async (newCourseID: any) => {
		// const newCourseID: string = event.target.value;
		console.log('ID -> ', newCourseID);
		if (typeof newCourseID === 'string') {
			await localStorage.setItem(
				'selected-course-token',
				newCourseID
			);
			dispatch(setCourseID(newCourseID));
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			console.log('CHECK', allCourses[newCourseID]);
			if (allCourses[newCourseID] === undefined) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				await dispatch(fetchCourseData());
			} else {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				await dispatch(updateCourse(newCourseID));
			}
			navigate('/modules');
		}
	};

	const uploadPersonalDocs = () => {
		window.open(
			`https://tally.so/r/3je85E?id=${user.id}`,
			'_blank'
		);
	};
	const uploadISA = () => {
		if (isaDocStatus !== 'VERIFIED')
			window.open(
				`https://tally.so/r/3xV84o?id=${user.id}`,
				'_blank'
			);
	};

	const [videoOpen, setVideoOpen] = useState(false);
	const [selectedCard, setSelectedCard] =
		useState<any>(null);

	return onBoardingData !== null && pageController ? (
		<div className="playground">
			{videoOpen ? (
				<VideoModal
					open={videoOpen}
					setOpen={setVideoOpen}
					data={selectedCard}
				/>
			) : null}
			<div className="column-1">
				{pageController.heading.status ? (
					<h1>{pageController.heading.data.heading}</h1>
				) : null}
				{pageController['welcome-banner'].status ? (
					<div className="welcome-banner">
						<h4>Welcome Coder</h4>
						<h3
							dangerouslySetInnerHTML={{
								__html: data.data.welcome.heading,
							}}
						/>
						<ul>
							{data.data.welcome.list.map((el, index) => (
								<li key={index}>
									<FaStar />{' '}
									<p
										dangerouslySetInnerHTML={{
											__html: el,
										}}
									/>
								</li>
							))}
						</ul>
						{/* <img
						src={highFive}
						className={'high-five'}
					/> */}
						<div className={'linkedIn-banner'}>
							<div>
								<div className={'icon'}>
									<BiShareAlt />
								</div>
								<div className={'content'}>
									<h4>Tell your friends</h4>
									<p>{data.data.welcome.para}</p>
								</div>
							</div>
							<h3
								onClick={() => {
									window.open(
										`https://www.linkedin.com/profile/add?startTask=CERTIFICATION_NAME&name=Full-Stack Development Learner&organizationId=14572424&issueYear=2023`,
										'_blank'
									);
								}}
							>
								Share on LinkedIn
								<BsArrowRightCircleFill />
							</h3>
						</div>
					</div>
				) : null}
				{pageController['portal-explainer'].status ? (
					<div className="portal-explainer">
						<h3 style={{ marginTop: '20px' }}>
							{
								pageController['portal-explainer'].data
									.title
							}
						</h3>
						<div className={'course-card'}>
							<div
								className={'course-video-thumbnail'}
								style={{
									backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url('https://storage.googleapis.com/acciojob-open-file-collections/Harsh%20Sharma_.png')`,
								}}
								onClick={() => {
									setVideoOpen(true);
									setSelectedCard(
										pageController['portal-explainer'].data
									);
								}}
							>
								<BsPlayCircleFill />
							</div>
							<div className={'course-guides-content'}>
								<div
									className={
										'course-guides-content-first-row'
									}
								>
									<h5 className={'course-guides-title'}>
										{
											pageController['portal-explainer']
												.data.title
										}
									</h5>
									{/* <div className={'course-guides-duration'}>
										{el.duration}
									</div> */}
								</div>
								{/*<p*/}
								{/*	className={*/}
								{/*		'course-guides-description'*/}
								{/*	}*/}
								{/*>*/}
								{/*	{el.date}{' '}*/}
								{/*	<span>{el.count} Videos</span>*/}
								{/*</p>*/}
							</div>
						</div>
					</div>
				) : null}
				{pageController['alumni-section'].status ? (
					<div className={'alumni-section'}>
						<h1>SEE WHAT ALUMNI HAS TO SAY</h1>
						<div className={'alumni-carousel'}>
							<OwlCarousel
								className="owl-theme"
								dots={true}
								nav={false}
								margin={10}
								loop={false}
							>
								{data.data.alumni.map((el, index) => (
									<div
										className={'alumni-card'}
										key={index}
									>
										<div
											className={'alumni-video-thumbnail'}
											style={{
												backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${el.url})`,
											}}
											onClick={() => {
												setVideoOpen(true);
												setSelectedCard(el);
											}}
										>
											<BsPlayCircleFill />
										</div>
										<div className={'alumni-content'}>
											<div
												className={
													'alumni-content-first-row'
												}
											>
												<div>
													<h5 className={'alumni-name'}>
														{el.name}
													</h5>
													<p className={'alumni-ctc'}>
														CTC: {el.ctc}
													</p>
												</div>
												<img
													src={el.company}
													alt={'company'}
												/>
											</div>
											<p className={'alumni-description'}>
												{el.desc}
											</p>
										</div>
									</div>
								))}
							</OwlCarousel>
						</div>
					</div>
				) : null}
				{loading ? (
					<Loader />
				) : onBoardingData.enrollmentMethod === 'PaP' &&
				  pageController['payment-methods-section']
						.status ? (
					<div className={'payment-methods-section'}>
						<div className={'payment-heading'}>
							<h1>Payment Methods</h1>
							<p>{deadline} days left</p>
						</div>

						<div className={'payment-options'}>
							<div className={'payment-content'}>
								<p className={'payment-question'}>
									{data.data.payment.options.ques}
								</p>
								<p className={'payment-answer'}>
									{data.data.payment.options.ans}
								</p>
								<h3
									onClick={() => {
										setVideoOpen(true);
										setSelectedCard(
											data.data.payment.options
										);
									}}
								>
									Watch video
									<BsArrowRightCircleFill />
								</h3>
							</div>
							<div
								className={'payment-video-thumbnail'}
								style={{
									backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${data.data.payment.options.videoThumbnail})`,
								}}
								onClick={() => {
									setVideoOpen(true);
									setSelectedCard(
										data.data.payment.options
									);
								}}
							>
								<BsPlayCircleFill />
							</div>
						</div>

						<div className={'payment-methods'}>
							<div className={'upfront'}>
								<div className={'highlighting'}></div>
								<div className={'payment-methods-content'}>
									<div className={'method-head'}>
										<h1>Upfront Payment</h1>
									</div>

									<ul>
										{data.data.payment.upfront.map(
											(el, index) => (
												<li key={index}>
													{index + 1}
													{'. '}
													{el}
												</li>
											)
										)}
									</ul>
								</div>
								<div
									className={'action-buttons'}
									onClick={requestCallback}
								>
									<TbPhoneOutgoing /> Request a Callback
								</div>
							</div>
							<div className={'post-placement'}>
								<div className={'highlighting'}></div>
								<div className={'payment-methods-content'}>
									<div className={'method-head'}>
										<h1>Post Placement Payment</h1>{' '}
										<p
											onClick={() =>
												window.open(
													'https://links.acciojob.com/isa',
													'_blank'
												)
											}
										>
											Download blank ISA
										</p>
									</div>

									<ul>
										{data.data.payment.post.map(
											(el, index) => (
												<li key={index}>
													{index + 1}
													{'. '}
													{el}
												</li>
											)
										)}
									</ul>
								</div>
								<div
									className={'action-buttons'}
									onClick={uploadISA}
								>
									<FiUpload />{' '}
									{isaDocStatus === 'VERIFIED'
										? 'Successfully Verified'
										: isaDocStatus === 'NOT UPLOADED'
										? 'Upload ISA'
										: 'Upload ISA Again'}
								</div>
							</div>
						</div>

						{isaDocStatus !== 'NOT UPLOADED' ? (
							<div className={'isa-doc'}>
								{isaDocStatus === 'UPLOADED' ? (
									<p className={'uploaded'}>
										<FiLoader />{' '}
										{data.data.payment.messages.underReview}
									</p>
								) : null}
								{isaDocStatus === 'REJECTED' ? (
									<p className={'rejected'}>
										<BsExclamationTriangle />{' '}
										{data.data.payment.messages.rejected}
									</p>
								) : null}
								{isaDocStatus === 'VERIFIED' ? (
									<p className={'verified'}>
										<BsCheckCircle />{' '}
										{data.data.payment.messages.verified}
									</p>
								) : null}
							</div>
						) : null}
					</div>
				) : null}
				{pageController['course-guides'].status ? (
					<div className={'course-guides'}>
						<h1>Course Guides</h1>
						<div className={'course-carousel-container'}>
							<OwlCarousel
								className="owl-theme"
								dots={true}
								nav={false}
								margin={10}
								loop={false}
							>
								{data.data.courseGuides.map((el, index) => (
									<div
										className={'course-card'}
										key={index}
									>
										<div
											className={'course-video-thumbnail'}
											style={{
												backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${el.thumb})`,
											}}
											onClick={() => {
												setVideoOpen(true);
												setSelectedCard(el);
											}}
										>
											<BsPlayCircleFill />
										</div>
										<div
											className={'course-guides-content'}
										>
											<div
												className={
													'course-guides-content-first-row'
												}
											>
												<h5
													className={'course-guides-title'}
												>
													{el.title}
												</h5>
												<div
													className={
														'course-guides-duration'
													}
												>
													{el.duration}
												</div>
											</div>
											{/*<p*/}
											{/*	className={*/}
											{/*		'course-guides-description'*/}
											{/*	}*/}
											{/*>*/}
											{/*	{el.date}{' '}*/}
											{/*	<span>{el.count} Videos</span>*/}
											{/*</p>*/}
										</div>
									</div>
								))}
							</OwlCarousel>
						</div>
					</div>
				) : null}
				{pageController['course-and-project'].status ? (
					<div className={'course-and-project'}>
						<div className={'course-and-project-card html'}>
							<BsCodeSlash />
							<h3
								className={
									'course-and-project-card-heading'
								}
							>
								{
									pageController['course-and-project'][
										'data'
									]['card-1']['head']
								}
							</h3>
							<p>
								{
									pageController['course-and-project'][
										'data'
									]['card-1']['description']
								}
							</p>
							<h4
								onClick={() => {
									if (enrollmentData !== null)
										if (
											pageController['course-and-project'][
												'data'
											]['card-1']['courseId']
										) {
											handleCourseChange(
												pageController[
													'course-and-project'
												]['data']['card-1']['courseId']
											);
										} else {
											handleCourseChange(
												'af0fbd53-0cfe-4599-bd5c-8b8dc989ac18'
											);
										}
								}}
							>
								Start Learning
								<BsArrowRightCircleFill />
							</h4>
							<h4
								onClick={() => {
									navigate(
										'/start-project?project=cbee3f2d-a3c6-460a-87c8-f45f211da7a0'
									);
								}}
							>
								Build your project
								<BsArrowRightCircleFill />
							</h4>
						</div>

						<div
							className={
								'course-and-project-card precourse'
							}
						>
							<TbStack2 />
							<h3
								className={
									'course-and-project-card-heading'
								}
							>
								{
									pageController['course-and-project'][
										'data'
									]['card-2']['head']
								}
							</h3>
							<p>
								{
									pageController['course-and-project'][
										'data'
									]['card-2']['description']
								}
							</p>
							<h4
								onClick={() => {
									if (enrollmentData !== null)
										if (
											pageController['course-and-project'][
												'data'
											]['card-2']['courseId']
										) {
											handleCourseChange(
												pageController[
													'course-and-project'
												]['data']['card-2']['courseId']
											);
										} else {
											console.log('herhe');
											handleCourseChange(
												enrollmentData.playground.courseId
											);
										}
								}}
							>
								Start Course
								<BsArrowRightCircleFill />
							</h4>
						</div>
					</div>
				) : null}
				<div className={'course-guides soft-skill-videos'}>
					<h1>Soft skill videos</h1>
					<div className={'course-carousel-container'}>
						<OwlCarousel
							className="owl-theme"
							dots={true}
							nav={false}
							margin={10}
							loop={true}
						>
							{data.data.softSkills.map((el, index) => (
								<div
									className={'course-card'}
									key={index}
								>
									<div
										className={'course-video-thumbnail'}
										style={{
											backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${el.thumb})`,
										}}
										onClick={() => {
											setVideoOpen(true);
											setSelectedCard(el);
										}}
									>
										<BsPlayCircleFill />
									</div>
									<div className={'course-guides-content'}>
										<div
											className={
												'course-guides-content-first-row'
											}
										>
											<h5 className={'course-guides-title'}>
												{el.title}
											</h5>
											<div
												className={'course-guides-duration'}
											>
												{el.duration}
											</div>
										</div>
										{/*<p*/}
										{/*	className={*/}
										{/*		'course-guides-description'*/}
										{/*	}*/}
										{/*>*/}
										{/*	{el.date}{' '}*/}
										{/*	<span>{el.count} Videos</span>*/}
										{/*</p>*/}
									</div>
								</div>
							))}
						</OwlCarousel>
					</div>
				</div>
			</div>
			<div
				className="column-2"
				style={{ paddingTop: '20px' }}
			>
				{pageController['upload-doc'].status ? (
					<div className={'upload-doc'}>
						<h3>Upload Document</h3>
						<p>{data.data.upload}</p>

						{uploadDocStatus !== 'NOT UPLOADED' ? (
							<div className={'isa-doc'}>
								{uploadDocStatus === 'UPLOADED' ? (
									<p className={'uploaded'}>
										<FiLoader />{' '}
										{
											data.data.payment.uploadMessages
												.underReview
										}
									</p>
								) : null}
								{uploadDocStatus === 'REJECTED' ? (
									<p className={'rejected'}>
										<BsExclamationTriangle />
										<div>
											<h4>
												{
													data.data.payment.uploadMessages
														.rejected.head
												}
											</h4>
											<p>
												{
													data.data.payment.uploadMessages
														.rejected.para
												}
											</p>
										</div>
									</p>
								) : null}
								{uploadDocStatus === 'VERIFIED' ? (
									<p className={'verified'}>
										<BsCheckCircle />{' '}
										{
											data.data.payment.uploadMessages
												.verified
										}
									</p>
								) : null}
							</div>
						) : null}
						<div
							className={'upload-btn'}
							onClick={uploadPersonalDocs}
						>
							<IoMdCloudUpload />
							<h4>Upload Personal Document</h4>
							<p>Click to upload</p>
						</div>
					</div>
				) : null}
				{pageController['aptitude-card'].status ? (
					<div className={'aptitude-card'}>
						<SiNintendogamecube />
						<h3
							className={'course-and-project-card-heading'}
						>
							Aptitude
						</h3>
						<p>
							Learn basics of logical reasoning and aptitude
							through our well designed tests.
						</p>
						<h4
							onClick={() => {
								if (
									pageController['aptitude-card'].data
										.courseId
								) {
									handleCourseChange(
										pageController['aptitude-card'].data
											.courseId
									);
								} else if (
									enrollmentData &&
									enrollmentData.aptitude &&
									enrollmentData.aptitude.courseId
								) {
									handleCourseChange(
										enrollmentData.aptitude.courseId
									);
								} else navigate('/contests');
							}}
						>
							Start Learning
							<BsArrowRightCircleFill />
						</h4>
					</div>
				) : null}
				{pageController['aptitude-contest'].status ? (
					<div className={'aptitude-contest'}>
						<h3>Aptitude Contest</h3>
						<p>Test out your aptitude skills</p>
						<button
							onClick={() => {
								if (
									pageController['aptitude-contest'][
										'data'
									]['functionType'] ===
									'goToContestsPlatform'
								) {
									window.open(
										'https://contest2.acciojob.com/?contestId=98fd0f88-9278-41dc-bfd7-971e63bc5f97',
										'_blank'
									);
								} else navigate('/contests');
							}}
						>
							Take the test <FiArrowRight />
						</button>
					</div>
				) : null}
			</div>
		</div>
	) : (
		<Loader />
	);
};

export default Playground;
