import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class PrecourseService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchPrecourse() {
		const url = `${this.endpoint}/course/fetchPreCourse`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getVideo(videoId: any) {
		const url = `${this.endpoint}/video/${videoId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMockTestLink(id: any) {
		const url = `${this.endpoint}/user-report-card/mock-job-test-url/${id}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
