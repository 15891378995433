import { Box } from '@mui/material';
import { useEffect, useMemo, type FC } from 'react';
import { GymPanel } from '../../components/gym/Panel';
import { PerformanceReview } from '../../components/gym/PerformanceReview';
import { CodingQuestionHeader } from '../../components/gym/TabHeader';
import { notifyError } from '../../components/notification';
import {
	TabPropElement,
	Tabs,
} from '../../components/shared/Tabs';
import { GymActions } from '../../redux/gym/gym.slice';
import {
	fetchAvailableTabsThunk,
	fetchHeatMapData,
	fetchUserQuestionSubmissionData,
} from '../../redux/gym/thunks/gym.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import { GymHeaderMap } from './constants';

export const Gym: FC = () => {
	const dispatch = useAppDispatch();
	const {
		userQuestionSubmissionData,
		availableTabs,
		heatMapData,
		error,
	} = useAppSelector((state) => state.gym);

	useEffect(() => {
		if (error?.message) notifyError(error.message);
	}, [error]);

	const onMount = async () => {
		await dispatch(fetchAvailableTabsThunk());
	};

	const tabData = useMemo(() => {
		const tabs: Array<TabPropElement> = [];
		if (!availableTabs) return [];
		dispatch(fetchHeatMapData());
		dispatch(fetchUserQuestionSubmissionData());
		return availableTabs.map((tab) => {
			return {
				key: tab.id,
				title: GymHeaderMap[tab.id] as string,
				show: true,
				element: <GymPanel tabId={tab.id} />,
			};
		});
	}, [availableTabs]);

	useEffect(() => {
		onMount();
	}, []);

	return (
		<>
			{Boolean(userQuestionSubmissionData) && (
				// heatMapData ? (
				<PerformanceReview
					header={{
						questionSolvedCount:
							userQuestionSubmissionData?.totalSolvedQuestions ??
							0,
						streakCount:
							userQuestionSubmissionData?.streak ?? 0,
						restoreStreakNumber:
							userQuestionSubmissionData?.restoreStreakNumber ??
							0,
					}}
					heatMap={heatMapData ?? []}
					questionAnalysis={
						userQuestionSubmissionData?.questionAnalysis ??
						[]
					}
				/>
			)}
			{tabData.length ? (
				<Box className="gym-container">
					<Box className="gym-content">
						<Tabs
							tabContainerClass="gym-header"
							tabs={tabData}
							onTabChange={(data) => {
								dispatch(
									GymActions.setSelectedTabId(data.key)
								);
							}}
							defaultTab={0}
							RightComponent={CodingQuestionHeader}
							selectedTabClass="gym-header-selected-tab"
							unselectedTabClass="gym-header-unselected-tab"
							selectedTabExtraContent={
								<Box className="gym-header-selected-tab-bottom-bar" />
							}
						/>
					</Box>
				</Box>
			) : null}
		</>
	);
};
