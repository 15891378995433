import React from 'react';

const FieldBlock = ({
	children,
	label,
	id,
	required,
	isProfileLocked,
	style,
}: any) => {
	return (
		<div
			className={`field-block ${
				isProfileLocked ? 'profile-locked' : ''
			}`}
			style={style}
		>
			<label
				className="field-block__label"
				htmlFor={`${id}`}
			>
				<span>{label}</span>
				<span className="must-required">
					{required ? '*' : ''}
				</span>
				{/* <span className="field-extra-msg">
					{extraMsg.length > 0 ? extraMsg : ''}
				</span> */}
			</label>
			<div className="field-block__fields">{children}</div>
		</div>
	);
};

export default FieldBlock;
