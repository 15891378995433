import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../apis';

export const getSectionalFeedbackPopupThunk =
	createAsyncThunk(
		'allFeedbackModal/startAssessment',
		async (section: string) => {
			const data =
				await services.popupService.getSectionalFeedbackPopup(
					section
				);
			return data;
		}
	);
