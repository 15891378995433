import React from 'react';
import moment from 'moment';
import { FiCalendar } from 'react-icons/fi';
import { MdOutlineTimer } from 'react-icons/md';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
	CircularProgressbarWithChildren,
	buildStyles,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Timer from './../timer';

interface BannerProps {
	msg: any;
	duration: string;
	startTime: string;
	endTime: string;
	active: boolean;
	showProgress?: boolean;
}

const Banner = ({
	msg,
	duration,
	startTime,
	endTime,
	active,
	showProgress = true,
}: BannerProps) => {
	const dispatch = useDispatch();

	const contestInfoState = useSelector(
		(state: any) => state.contest
	);

	const { flag } = contestInfoState;
	const [percentage, setPercentage] = useState(100);

	const [ciruclarBarColor, setCiruclarBarColor] =
		useState('#ffffff');

	useEffect(() => {
		const interval = setInterval(() => {
			const startDate = new Date(startTime);
			const endDate = new Date(endTime);
			const totalSeconds =
				(endDate.getTime() - startDate.getTime()) / 1000;
			const curr = new Date(Date.now());
			const currSeconds =
				(curr.getTime() - startDate.getTime()) / 1000;
			console.log(totalSeconds, currSeconds);
			const per =
				100 -
				Math.round((currSeconds / totalSeconds) * 100);
			setPercentage(per);
			if (per > 70) {
				setCiruclarBarColor('#12B76A');
			} else if (per > 40) {
				setCiruclarBarColor('#F0B416');
			} else {
				setCiruclarBarColor('#FF940A');
			}
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const LiveMsg = [
		{
			head: (
				<p>
					<span style={{ color: ciruclarBarColor }}>
						Speed up!
					</span>{' '}
					Less than half-time is remaining
				</p>
			),
			para: 'Complete as many questions as you can before time ends.',
		},
		{
			head: (
				<p>
					<span style={{ color: ciruclarBarColor }}>
						Godspeed!
					</span>{' '}
					You are near the half-time
				</p>
			),
			para: 'Complete as many questions as you can before time ends.',
		},
		{
			head: (
				<p>
					<span style={{ color: ciruclarBarColor }}>
						Best of luck!
					</span>{' '}
					Take care of your pace
				</p>
			),
			para: 'Complete as many questions as you can before time ends.',
		},
	];

	return (
		<div className="contestBanner">
			<div
				className={'timerContainer1'}
				style={{
					background: `${
						active || percentage > 0
							? percentage > 70
								? '#E3FFED'
								: percentage > 40
								? 'rgba(253, 242, 214, 0.6)'
								: '#FFEFDA'
							: 'rgba(253, 230, 228, 0.6)'
					}`,
				}}
			>
				<div className={'contest-banner-heading'}>
					{showProgress ? (
						<CircularProgressbarWithChildren
							className={'circular-progress-bar'}
							value={percentage}
							strokeWidth={10}
							styles={buildStyles({
								// Rotation of path and trail, in number of turns (0-1)
								rotation: 0,

								// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
								strokeLinecap: 'round',

								// Text size
								textSize: '16px',

								// How long animation takes to go from one percentage to another, in seconds
								pathTransitionDuration: 0.5,

								// Can specify path transition in more detail, or remove it entirely
								// pathTransition: 'none',

								// Colors
								pathColor: `${ciruclarBarColor}`,
								textColor: '#f88',
								trailColor: '#ffffff',
							})}
						>
							<Timer
								expiryTimestamp={new Date(
									endTime
								).setSeconds(
									new Date(endTime).getSeconds()
								)}
								show={[1, 1, 1, 1]}
								expiryHandler={() => {
									console.log('end');
								}}
							/>
						</CircularProgressbarWithChildren>
					) : null}

					<div className={'banner-msg'}>
						{active ? (
							<div
								className={`bannerActiveHeading ${
									percentage > 75
										? 'status75'
										: percentage > 40
										? 'status40'
										: 'status0'
								}`}
							>
								<h3>
									{
										LiveMsg[
											percentage > 75
												? 2
												: percentage > 40
												? 1
												: 0
										].head
									}
								</h3>
								<p>
									{
										LiveMsg[
											percentage > 75
												? 2
												: percentage > 40
												? 1
												: 0
										].para
									}
								</p>
							</div>
						) : (
							<div
								className={`bannerActiveHeading
										${
											flag === 'upcoming' ||
											flag === 'upcoming-given'
												? 'upcoming-heading'
												: 'past-heading'
										}`}
							>
								<h3>{msg.head}</h3>
								<p>{msg.para}</p>
							</div>
						)}
					</div>
				</div>

				<h4 className={'banner-duration'}>
					<MdOutlineTimer /> <span>{duration}min</span>
				</h4>
			</div>
		</div>
	);
};

export default Banner;
