const DoubtRecording = () => {
	// const courseState = useSelector(
	// 	(state: any) => state.course
	// );
	// const { recordingDataDoubt, courseName } = courseState;
	// const ModuleState = useSelector(
	// 	(state: any) => state.module
	// );
	// const { moduleActive, currentModuleId } = ModuleState;
	// const [firstTime, setFirstTime] = useState(true);
	// const [activeSessionId, setActiveSessionId] =
	// 	useState('');
	// const [videoURL, setVideoURL] = useState('');
	// const [currentInterval, setCurrentInterval] =
	// 	useState<any>(null);
	// const ref = useRef<any>(null);
	// const [videoName, setVideoName] = useState('');
	// const [videoId, setVideoId] = useState('');
	// useEffect(() => {
	// 	console.log('DOUBT RECORDING', recordingDataDoubt);
	// 	if (
	// 		recordingDataDoubt !== null &&
	// 		recordingDataDoubt.length > 0
	// 	) {
	// 		setVideoURL(recordingDataDoubt[0].url);
	// 		setVideoName(recordingDataDoubt[0].name);
	// 		setVideoId(recordingDataDoubt[0].videoId);
	// 	}
	// 	localStorage.setItem('playbackRate', '1');
	// }, [recordingDataDoubt]);
	// const postNewPlayAnalytic = async () => {
	// 	localStorage.setItem('duration', '0');
	// 	const response =
	// 		await services.courseService.postRecordingAnalytics({
	// 			videoId: recordingDataDoubt[0].video_id,
	// 			duration: 0,
	// 		});
	// 	const activeSessionId = response.data;
	// 	setActiveSessionId(activeSessionId);
	// };
	// const updatePlayAnalytic = async () => {
	// 	if (!activeSessionId) return;
	// 	let playbackRate = localStorage.getItem('playbackRate');
	// 	if (!playbackRate) playbackRate = '1';
	// 	const effectiveTimeIncrement =
	// 		(parseFloat(playbackRate) * POLLING_INTERVAL_MS) /
	// 		1000;
	// 	let currentSliceDuration =
	// 		localStorage.getItem('duration');
	// 	if (currentSliceDuration === null)
	// 		currentSliceDuration = '0';
	// 	const newDuration =
	// 		parseFloat(currentSliceDuration) +
	// 		effectiveTimeIncrement;
	// 	localStorage.setItem(
	// 		'duration',
	// 		newDuration.toString()
	// 	);
	// 	console.log(
	// 		'newDuration, currentSliceDuration, effectiveTimeIncrement:',
	// 		newDuration,
	// 		currentSliceDuration,
	// 		effectiveTimeIncrement
	// 	);
	// 	console.log('updating with ID', activeSessionId);
	// 	await services.courseService.updateRecordingAnalytics({
	// 		sessionId: activeSessionId,
	// 		duration: Math.floor(newDuration),
	// 	});
	// };
	// //poll the updatePlayAnalytic function every 5 seconds
	// // Store the interval id in a ref so that we can access its latest value in the cleanup function
	// const intervalRef = useRef<NodeJS.Timeout | null>(null);
	// // Clear interval when currentModuleId changes
	// useEffect(() => {
	// 	return () => {
	// 		if (intervalRef.current !== null) {
	// 			clearInterval(intervalRef.current);
	// 			intervalRef.current = null;
	// 		}
	// 	};
	// }, [currentModuleId]);
	// // Set up interval when activeSessionId changes
	// useEffect(() => {
	// 	if (activeSessionId && ref.current) {
	// 		intervalRef.current = setInterval(() => {
	// 			const isBuffering =
	// 				localStorage.getItem('buffering');
	// 			if (
	// 				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// 				// @ts-ignore
	// 				ref.current.player.isPlaying &&
	// 				isBuffering === 'false'
	// 			) {
	// 				updatePlayAnalytic(
	// 					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// 					// @ts-ignore
	// 					ref.current.player.player.currentTime
	// 				);
	// 			}
	// 		}, POLLING_INTERVAL_MS);
	// 	}
	// 	return () => {
	// 		if (intervalRef.current !== null) {
	// 			clearInterval(intervalRef.current);
	// 			intervalRef.current = null;
	// 		}
	// 	};
	// }, [activeSessionId]);
	// const [suggestedReading, setSuggestedReading] =
	// 	useState('');
	// useEffect(() => {
	// 	if (moduleActive !== null) {
	// 		setSuggestedReading(moduleActive.suggestedReading);
	// 	}
	// }, [moduleActive]);
	// return (
	// 	<div className={'lecture-and-notes'}>
	// 		<h4 style={{ marginBottom: '20px' }}>
	// 			{recordingDataDoubt !== null &&
	// 			recordingDataDoubt.length > 0
	// 				? recordingDataDoubt[0].name
	// 				: null}{' '}
	// 		</h4>
	// 		{/* Custom Video Player */}
	// 		{videoURL.includes('https://us06web.zoom.us/rec') ? (
	// 			<VideoPlayer
	// 				src={videoURL}
	// 				id={videoId}
	// 				name={videoName}
	// 			/>
	// 		) : (
	// 			<ReactPlayer
	// 				url={videoURL}
	// 				controls={true}
	// 				ref={ref}
	// 				onPlaybackRateChange={(rate: any) => {
	// 					localStorage.setItem(
	// 						'playbackRate',
	// 						rate.toString()
	// 					);
	// 				}}
	// 				onPlay={() => {
	// 					postNewPlayAnalytic();
	// 					if (firstTime) {
	// 						engagements.courseEngagement.watchRecording(
	// 							videoName,
	// 							videoId,
	// 							courseName,
	// 							moduleActive.name
	// 						);
	// 						setFirstTime(false);
	// 						console.log(
	// 							'on play',
	// 							videoName,
	// 							videoId,
	// 							courseName,
	// 							moduleActive.name
	// 						);
	// 					}
	// 				}}
	// 				config={{
	// 					vimeo: {
	// 						playerOptions: {
	// 							pip: true,
	// 						},
	// 					},
	// 				}}
	// 				width={640}
	// 				style={{
	// 					position: 'relative',
	// 					display: 'flex',
	// 					justifyContent: 'center',
	// 					marginInline: 'auto',
	// 					borderRadius: '5px',
	// 					overflow: 'hidden',
	// 					background: 'black',
	// 				}}
	// 				showTitle={false}
	// 			/>
	// 		)}
	// 		<div className={' video-carousel'}>
	// 			{recordingDataDoubt !== null
	// 				? recordingDataDoubt.map(
	// 						(el: any, index: number) => {
	// 							return (
	// 								<div
	// 									key={el.url}
	// 									className={'video-item'}
	// 									onClick={() => {
	// 										setVideoURL(el.url);
	// 										console.log('URL-->', el.url);
	// 									}}
	// 								>
	// 									{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
	// 									{/*@ts-ignore*/}
	// 									<h3 className={'videoName'}>
	// 										{el.name}
	// 									</h3>
	// 									<img
	// 										style={{ height: '120px' }}
	// 										src={`${window.location.origin}/assets/images/${el.thumbnail}`}
	// 									/>
	// 								</div>
	// 							);
	// 						}
	// 				  )
	// 				: null}
	// 		</div>
	// 		{suggestedReading !== null &&
	// 		suggestedReading !== undefined &&
	// 		suggestedReading !== '' ? (
	// 			<div className={'suggested-readings'}>
	// 				<h3 style={{ marginBottom: '20px' }}>
	// 					<span>
	// 						<RiLightbulbLine />
	// 					</span>{' '}
	// 					Suggested Readings
	// 				</h3>
	// 				<RenderMarkDown>
	// 					{suggestedReading}
	// 				</RenderMarkDown>
	// 			</div>
	// 		) : null}
	// 	</div>
	// );
};

export default DoubtRecording;
