import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchJobCards } from '../../redux/jobportal/jobPortalSlice';
import { AppDispatch, RootState } from '../../redux/store';
import JobCard from './JobCard';

const ExploreJobDrivesWrapper = ({
	placementFlag,
	setJobDetailsPopup,
	setApplyPopup,
	setBlackListModalOpen,
}: any) => {
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);
	const { jobCardsState } = jobPortalState;

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		if (
			jobCardsState.jobCards &&
			jobCardsState.jobCards.length === 0
		) {
			dispatch(fetchJobCards());
		}
	}, []);

	return (
		<div className="explore-job-drive-wrapper">
			{jobCardsState.loading === true ? (
				<div className="loader-container">
					<Loader
						factVisibility={true}
						width={'100%'}
						height={'100%'}
					/>
				</div>
			) : jobCardsState.jobCards &&
			  jobCardsState.jobCards.length !== 0 &&
			  //@ts-ignore
			  jobCardsState.jobCards !== 'no data found' ? (
				<div className="job-card-wrapper view-scroll-bar">
					{jobCardsState.jobCards.map(
						(card: any, idx: number) => (
							<JobCard
								placementFlag={placementFlag}
								key={idx}
								data={card}
								setJobDetailsPopup={setJobDetailsPopup}
								setApplyPopup={setApplyPopup}
								setBlackListModalOpen={
									setBlackListModalOpen
								}
							/>
						)
					)}
				</div>
			) : (
				<p>
					No new jobs are available, please try again later
					drives
				</p>
			)}
		</div>
	);
};

export default ExploreJobDrivesWrapper;
