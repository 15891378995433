import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';

interface RenderMarkDownProps {
	children: string;
}

const RenderMarkDown = ({
	children,
}: RenderMarkDownProps) => {
	return (
		<ReactMarkdown
			className={`markdown-body`}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeHighlight, rehypeRaw]}
		>
			{children}
		</ReactMarkdown>
	);
};

export default RenderMarkDown;
