import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	fetchMentorProfile,
	fetchMentorSessionTypes,
} from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import CompletedSessions from './CompletedSessions';
import DidntHappen from './DidntHappen';
import PendingSessions from './PendingSessions';
import UpcomingSessions from './UpcomingSessions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const MentorSessions = () => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, mentorSessionTypes } = MentorState;

	const [activeTab, setActiveTab] = useState(1);
	const [sessionTypes, setSessionTypes] =
		useState<any>(null);
	const [selectedSession, setSelectedSession] =
		useState<any>(null);
	const [selectedSessionId, setSelectedSessionId] =
		useState<any>(null);

	const [startDate, setStartDate] =
		React.useState<any>(null);
	const [endDate, setEndDate] = React.useState<any>(null);

	const handleSessionChange = (
		event: SelectChangeEvent
	) => {
		const value = event.target.value as string;
		setSelectedSession(value);
	};

	useEffect(() => {
		if (mentorSessionTypes === null)
			dispatch(fetchMentorSessionTypes());
	}, [mentor, mentorSessionTypes]);

	useEffect(() => {
		if (mentorSessionTypes !== null)
			setSessionTypes(mentorSessionTypes);
	}, [mentorSessionTypes]);

	const handleSessionSelect = (id: string) => {
		setSelectedSessionId(id);
	};

	return (
		<div className="profile-page">
			<h1>All Sessions</h1>

			<div className="filters">
				<div className="session-filter">
					<FormControl sx={{ width: '200px' }}>
						<InputLabel id="">Session Type</InputLabel>
						<Select
							labelId=""
							// id="demo-simple-select"
							value={selectedSession}
							label="Session Type"
							onChange={handleSessionChange}
							sx={{
								overflow: 'scroll',
								'&::-webkit-scrollbar': {
									width: '5px',
								},
								'&::-webkit-scrollbar-track': {
									backgroundColor: 'transparent',
								},
								'&::-webkit-scrollbar-thumb': {
									backgroundColor: '#D0D5DD',
									borderRadius: '5px',
								},
								'&::-webkit-scrollbar-thumb:hover': {
									backgroundColor: '#98A2B3',
								},
							}}
						>
							<MenuItem
								value=""
								onClick={() => handleSessionSelect('')}
							>
								None
							</MenuItem>
							{sessionTypes &&
								sessionTypes.map((session: any) => {
									return (
										<MenuItem
											key={session.id}
											value={session.id}
											onClick={() =>
												handleSessionSelect(session.id)
											}
										>
											{session.sessionType}
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				</div>
				<div className="date-filter">
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DatePicker
							label="Start Date"
							value={startDate}
							onChange={(newValue) =>
								newValue && newValue.$d != 'Invalid Date'
									? setStartDate(newValue)
									: ''
							}
							format="DD-MM-YYYY"
							sx={{ marginRight: '1em' }}
						/>
						<DatePicker
							label="End Date"
							value={endDate}
							onChange={(newValue) =>
								newValue && newValue.$d != 'Invalid Date'
									? setEndDate(newValue)
									: ''
							}
							format="DD-MM-YYYY"
						/>
					</LocalizationProvider>
				</div>
			</div>

			<div className="tab-container">
				<div className="tabs">
					<button
						className={activeTab === 1 ? 'active' : ''}
						onClick={() => {
							setActiveTab(1);
						}}
						style={{ width: '20%', display: 'inline' }}
					>
						Upcoming / Ongoing
					</button>
					<button
						className={activeTab === 2 ? 'active' : ''}
						onClick={() => {
							setActiveTab(2);
						}}
						style={{ width: '20%', display: 'inline' }}
					>
						Pending
					</button>
					<button
						className={activeTab === 3 ? 'active' : ''}
						onClick={() => {
							setActiveTab(3);
						}}
						style={{ width: '20%', display: 'inline' }}
					>
						Didn't Happen
					</button>

					<button
						className={activeTab === 4 ? 'active' : ''}
						onClick={() => {
							setActiveTab(4);
						}}
						style={{ width: '20%', display: 'inline' }}
					>
						Completed
					</button>
				</div>

				<div className="tab-content">
					{activeTab === 1 && (
						<UpcomingSessions
							sessionType={selectedSessionId}
							startDate={startDate}
							endDate={endDate}
						/>
					)}
					{activeTab === 2 && (
						<PendingSessions
							sessionType={selectedSessionId}
							startDate={startDate}
							endDate={endDate}
						/>
					)}
					{activeTab === 3 && (
						<DidntHappen
							sessionType={selectedSessionId}
							startDate={startDate}
							endDate={endDate}
						/>
					)}
					{activeTab === 4 && (
						<CompletedSessions
							sessionType={selectedSessionId}
							startDate={startDate}
							endDate={endDate}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default MentorSessions;
