import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import { MainProfile } from '../../components/studentProfile/MainProfile';
import { TopProfile } from '../../components/studentProfile/TopProfile';
import {
	fetchHeatMapData,
	fetchUserQuestionSubmissionData,
} from '../../redux/gym/thunks/gym.thunk';
import {
	fetchAcademicDetails,
	fetchExperienceDetails,
	fetchPerformanceUpdates,
	fetchPersonalDetails,
} from '../../redux/profile/profile.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import { userPauseStatusThunk } from '../../redux/user/userSlice';

export const StudentProfilePage = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(fetchPersonalDetails());
		dispatch(fetchAcademicDetails());
		dispatch(fetchExperienceDetails());
		dispatch(fetchPerformanceUpdates());
		dispatch(userPauseStatusThunk());
		dispatch(fetchUserQuestionSubmissionData());
		dispatch(fetchHeatMapData());
	}, []);

	const {
		personalDetails,
		academicDetails,
		experienceDetails,
	} = useAppSelector((state) => state.profile);

	if (
		personalDetails.loading ||
		experienceDetails.loading ||
		academicDetails.loading
	) {
		return <Loader />;
	} else
		return (
			<div className="student-profile-wrapper">
				<TopProfile />
				<MainProfile />
			</div>
		);
};
