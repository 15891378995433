import AddExperience from './AddExperience';
import AllExperiences from './AllExperiences';
import MentorSkills from './MentorSkills';

function MentorProfessionalInfo() {
	return (
		<div>
			<AddExperience />
			<AllExperiences />
			<MentorSkills />
		</div>
	);
}

export default MentorProfessionalInfo;
