import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import MUIButton from '@mui/material/Button';
import { styled } from '@mui/system';

const Button = styled(MUIButton)(({ theme }) => ({
	height: 50,
	width: 175,
	borderRadius: 12,
	fontSize: 18,
	letterSpacing: 0.24,
	color: 'white',
	fontWeight: 500,
	textTransform: 'none',
	backgroundColor: '#df4863',
	'&:hover': {
		backgroundColor: '#df4863',
	},
}));

interface GithubAuthPopupProps {
	githubAuthUri: string;
	setPopup: any;
	showPopup: boolean;
}

const GithubAuth: React.FC<GithubAuthPopupProps> = ({
	githubAuthUri,
	setPopup,
	showPopup,
}) => {
	const onClickLogin = () => {
		window.open(githubAuthUri);
	};

	return (
		<Dialog open={showPopup}>
			<DialogTitle>Login with Github</DialogTitle>
			<button
				style={{
					width: '20%',
					marginLeft: '85%',
					marginTop: '-10%',
					background: 'transparent',
					border: 'none',
				}}
				onClick={setPopup}
			>
				X
			</button>
			<DialogContent>
				To start this assignment you first need to login
				with Github
			</DialogContent>
			<DialogActions>
				<Button onClick={onClickLogin}>
					Login with Github
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default GithubAuth;
