import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAllProjects } from '../../redux/projects/projectSlice';
import Loader from '../../components/Loader';
import { TbTools } from 'react-icons/tb';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Rating } from '@mui/material';
import { formatDate } from '../../utils/formatDate';
import {
	formatTime,
	timeformatter,
} from '../../utils/timeformatter';
import Recordings1v1 from '../../components/Recordings1v1';
import VideoModalCarousel from '../../components/VideoCarousel';
import ProjectVideos from '../../config/project-videos';
import { useAppDispatch } from '../../redux/store';
const ProjectsPage = () => {
	const dispatch = useAppDispatch();
	const [componentArray, setComponentArray] = useState<
		number[]
	>([1, 0, 0]);

	const projectsState = useSelector(
		(state: any) => state.projects
	);
	const { projects, projectsLoading } = projectsState;

	const [totalPageCount, setTotalPageCount] = useState(1);
	const [isFilterActive, setIsFilterActive] =
		useState(false);

	// const [age, setAge] = React.useState('');

	useEffect(() => {
		dispatch(fetchAllProjects());
	}, []);

	const colorDIFFMap = (diff: string): any => {
		const color = [
			{
				color: '#EF574C',
				bg: '#FDE6E4',
			},
			{
				color: '#F0B416',
				bg: 'rgba(253, 242, 214, 0.5)',
			},
			{
				color: '#12B76A',
				bg: '#E3FFED',
			},
		];
		if (diff === 'HARD') {
			return color[0];
		} else if (diff === 'MEDIUM') {
			return color[1];
		} else if (diff === 'EASY') {
			return color[2];
		}
	};

	return (
		// <div className={'gym-dash'}>
		// 	<div className={'projects-content'}>
		// 		{!projectsLoading && projects ? (
		// 			<>
		// 				<h1>Welcome to Projects</h1>

		// 				<h2>Resources to help you get started quickly</h2>
		// 				<div className={'carousel-container'}>
		// 					<VideoModalCarousel
		// 						open={true}
		// 						handleClose={() => {}}
		// 						currentRecordings={ProjectVideos}
		// 					/>
		// 				</div>
		// 				{projects.activeProjects.length > 0 && (
		// 					<>
		// 						<h2>Your Ongoing Project Contests</h2>
		// 						<Grid
		// 							container
		// 							spacing={2}
		// 							className={'projects-container'}
		// 							alignItems="stretch"
		// 						>
		// 							{
		// 								//@ts-ignore
		// 								// projects.otherProjects.map((project) => (
		// 								projects.activeProjects.map(
		// 									(project) => (
		// 										<Grid
		// 											item
		// 											xs={5}
		// 											component={Card}
		// 											style={{
		// 												margin: 16,
		// 												display: 'flex',
		// 												flexDirection: 'column',
		// 												justifyContent:
		// 													'space-between',
		// 												border: '1px solid #e0e0e0',
		// 												borderRadius: '8px',
		// 												boxShadow: 'none',
		// 												paddingLeft: '16px',
		// 												paddingRight: '16px',
		// 											}}
		// 										>
		// 											<CardContent>
		// 												<Typography
		// 													variant="h5"
		// 													component="div"
		// 													sx={{
		// 														mb: 1,
		// 														fontWeight: 600,
		// 													}}
		// 												>
		// 													{project.name}
		// 												</Typography>

		// 												{project.difficulty ==
		// 													'Easy' && (
		// 													<Rating
		// 														readOnly
		// 														max={3}
		// 														value={1}
		// 													/>
		// 												)}
		// 												{project.difficulty ==
		// 													'Medium' && (
		// 													<Rating
		// 														readOnly
		// 														max={3}
		// 														value={2}
		// 													/>
		// 												)}
		// 												{project.difficulty ==
		// 													'Hard' && (
		// 													<Rating
		// 														readOnly
		// 														max={3}
		// 														value={3}
		// 													/>
		// 												)}

		// 												<Typography
		// 													variant="body2"
		// 													sx={{
		// 														mb: 1.5,
		// 														color: '#8c9aac',
		// 													}}
		// 												>
		// 													{
		// 														project.description
		// 													}
		// 												</Typography>
		// 												<Typography
		// 													variant="body2"
		// 													sx={{
		// 														mb: 1.5,
		// 														color: '#8c9aac',
		// 													}}
		// 												>
		// 													{/* {project.startTime} */}
		// 													<strong>
		// 														Contest start
		// 														time:{' '}
		// 													</strong>
		// 													{timeformatter(
		// 														project.startTime
		// 													)}
		// 												</Typography>
		// 												<Typography
		// 													variant="body2"
		// 													sx={{
		// 														mb: 1.5,
		// 														color: '#8c9aac',
		// 													}}
		// 												>
		// 													{/* {project.endTime} */}
		// 													<strong>
		// 														Contest end
		// 														time:
		// 													</strong>{' '}
		// 													{timeformatter(
		// 														project.endTime
		// 													)}
		// 												</Typography>
		// 											</CardContent>
		// 											<div>
		// 												<hr />

		// 												<div
		// 													style={{
		// 														marginBottom:
		// 															'20px',
		// 														// flexFlow: 'row-reverse',
		// 														flexDirection:
		// 															'row',
		// 														display: 'flex',
		// 														justifyContent:
		// 															'space-between',
		// 													}}
		// 												>
		// 													<div>
		// 														<Typography
		// 															color="text.secondary"
		// 															sx={{
		// 																mb: 1,
		// 															}}
		// 														>
		// 															<TbTools />{' '}
		// 															{
		// 																'  Pre-requisite(s) '
		// 															}
		// 														</Typography>
		// 														<div
		// 															className={
		// 																'prereq-container'
		// 															}
		// 														>
		// 															{/* @ts-ignore */}
		// 															{project.prerequisites.map(
		// 																(
		// 																	prereq
		// 																) => (
		// 																	<div
		// 																		// variant="outlined"
		// 																		className="tag"
		// 																		style={{
		// 																			margin: '2px 3px',
		// 																		}}
		// 																	>
		// 																		{
		// 																			prereq
		// 																		}
		// 																	</div>
		// 																)
		// 															)}
		// 														</div>
		// 													</div>
		// 													<button
		// 														className={
		// 															'start-project-button'
		// 														}
		// 														onClick={() => {
		// 															window.open(
		// 																`/start-project?project=${project.id}`,
		// 																'_blank'
		// 															);
		// 														}}
		// 													>
		// 														Start Project
		// 													</button>
		// 												</div>
		// 											</div>
		// 										</Grid>
		// 									)
		// 								)
		// 							}
		// 						</Grid>
		// 					</>
		// 				)}

		// 				{projects.activeProjects.length > 0 && (
		// 					<h2>Other Projects</h2>
		// 				)}
		// 				<Grid
		// 					container
		// 					spacing={2}
		// 					className={'projects-container'}
		// 					alignItems="stretch"
		// 				>
		// 					{!projectsLoading && projects ? (
		// 						//@ts-ignore
		// 						// projects.otherProjects.map((project) => (
		// 						projects.otherProjects.map((project) => (
		// 							<Grid
		// 								item
		// 								xs={5}
		// 								component={Card}
		// 								style={{
		// 									margin: 16,
		// 									display: 'flex',
		// 									flexDirection: 'column',
		// 									justifyContent: 'space-between',
		// 									border: '1px solid #e0e0e0',
		// 									borderRadius: '8px',
		// 									boxShadow: 'none',
		// 									paddingLeft: '16px',
		// 									paddingRight: '16px',
		// 								}}
		// 							>
		// 								<CardContent>
		// 									<Typography
		// 										variant="h5"
		// 										component="div"
		// 										sx={{ mb: 1, fontWeight: 600 }}
		// 									>
		// 										{project.name}
		// 									</Typography>

		// 									{project.difficulty == 'Easy' && (
		// 										<Rating
		// 											readOnly
		// 											max={3}
		// 											value={1}
		// 										/>
		// 									)}
		// 									{project.difficulty == 'Medium' && (
		// 										<Rating
		// 											readOnly
		// 											max={3}
		// 											value={2}
		// 										/>
		// 									)}
		// 									{project.difficulty == 'Hard' && (
		// 										<Rating
		// 											readOnly
		// 											max={3}
		// 											value={3}
		// 										/>
		// 									)}

		// 									<Typography
		// 										variant="body2"
		// 										sx={{
		// 											mb: 1.5,
		// 											color: '#8c9aac',
		// 										}}
		// 									>
		// 										{project.description}
		// 									</Typography>
		// 								</CardContent>
		// 								<div>
		// 									<hr />

		// 									<div
		// 										style={{
		// 											marginBottom: '20px',
		// 											// flexFlow: 'row-reverse',
		// 											flexDirection: 'row',
		// 											display: 'flex',
		// 											justifyContent:
		// 												'space-between',
		// 										}}
		// 									>
		// 										<div>
		// 											<Typography
		// 												color="text.secondary"
		// 												sx={{ mb: 1 }}
		// 											>
		// 												<TbTools />{' '}
		// 												{'  Pre-requisite(s) '}
		// 											</Typography>
		// 											<div
		// 												className={
		// 													'prereq-container'
		// 												}
		// 											>
		// 												{/* @ts-ignore */}
		// 												{project.prerequisites.map(
		// 													(prereq) => (
		// 														<div
		// 															// variant="outlined"
		// 															className="tag"
		// 															style={{
		// 																margin: '2px 3px',
		// 															}}
		// 														>
		// 															{prereq}
		// 														</div>
		// 													)
		// 												)}
		// 											</div>
		// 										</div>
		// 										<button
		// 											className={
		// 												'start-project-button'
		// 											}
		// 											onClick={() => {
		// 												window.open(
		// 													`/start-project?project=${project.id}`,
		// 													'_blank'
		// 												);
		// 											}}
		// 										>
		// 											Start Project
		// 										</button>
		// 									</div>
		// 								</div>
		// 							</Grid>
		// 						))
		// 					) : (
		// 						<Loader />
		// 					)}
		// 				</Grid>
		// 			</>
		// 		) : (
		// 			<Loader />
		// 		)}
		// 	</div>
		// </div>
		<></>
	);
};

export default ProjectsPage;
