import { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { VideoContentType } from '../../redux/softskills/softskills.type';
import MarkDown from '../contestComponents/MarkDown';
import VideoGallery from './VideoGallery';

const VideoContentSection: FC<{
	videoContent: Array<VideoContentType>;
}> = ({ videoContent }) => {
	const vimeoVideoPlayerRef = useRef<any>();

	const [selectedVideo, setSelectedVideo] = useState(
		videoContent[0]
	);

	useEffect(() => {
		setSelectedVideo(videoContent[0]);
	}, [videoContent]);

	return (
		<div className="video-content-section">
			<VideoGallery
				videoContent={videoContent}
				setSelectedVideo={setSelectedVideo}
			/>
			<ReactPlayer
				ref={vimeoVideoPlayerRef}
				url={selectedVideo.link}
				controls={true}
				width="100%"
				height="70%"
			/>
			<div className="video-details">
				<h1 className="video-title">
					{selectedVideo.title}
				</h1>
				{/* <p className="video-description">
					<MarkDown statement={selectedVideo.description} />
				</p> */}
				{selectedVideo.description ? (
					<iframe
						src={selectedVideo.description}
						width="100%"
						height="690px"
					></iframe>
				) : null}
			</div>
		</div>
	);
};

export default VideoContentSection;
