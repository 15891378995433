import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export const fetchModuleQuestions = createAsyncThunk(
	'assignments/fetchQuestions',
	async (moduleId: string, { getState }) => {
		// console.log(arg)
		const data =
			await services.courseService.getFullCourseQuestionsForModule(
				moduleId
			);
		const data2 = { ...data };
		data2.questions.forEach(
			(el: any) => (el.module_id = moduleId)
		);
		// console.log('👉', data2);
		return data;
	}
);

export const fetchAllModules = createAsyncThunk(
	'assignments/fetchAll',
	async () => {
		const data =
			await services.courseService.getCourseAssignments();
		// console.log("fetchAllModules", data)
		return data;
	}
);

interface AssignmentState {
	// filters
	difficulty: any;
	type: string;
	// final data
	modulesData: any;
	filteredData: any;
	allModulesData: any;
	switching: boolean;
	// extra
	loading: boolean;
	error: {
		state: boolean;
		message: string;
	};
}

const initialState: AssignmentState = {
	// filters
	difficulty: [],
	type: '',
	// final data
	modulesData: {},
	filteredData: undefined,
	allModulesData: null,
	switching: false,
	// extra
	loading: false,
	error: {
		state: false,
		message: '',
	},
};

export const assignmentSlice = createSlice({
	name: 'assignment',
	initialState,
	reducers: {
		// set difficulty
		setDifficulty: (state, action: PayloadAction<any>) => {
			state.difficulty = action.payload;
		},
		//Set Type --solved --unsolved --bookmarked
		setType: (state, action: PayloadAction<any>) => {
			state.type = action.payload;
		},
		//Set FilterData
		setFilterData: (state, action: PayloadAction<any>) => {
			state.loading = true;
			state.filteredData = action.payload;
			state.loading = false;
		},
		// handleFilterChange: (state, action: PayloadAction<any>) => {
		// 	const curModule = action.payload;

		// 	if (
		// 		(state.difficulty.length === 0 ||
		// 			state.difficulty.length === 3) &&
		// 		(state.type.length == 0 || state.type.length === 2)
		// 	) {
		// 		state.filteredData = curModule.codingQuestions;
		// 	} else if (
		// 		state.difficulty.length === 3 ||
		// 		state.difficulty.length === 0
		// 	) {
		// 		let x;

		// 		if (state.type.includes('2')) {
		// 			x = curModule.codingQuestions.filter(
		// 				(codingQuestion: any) =>
		// 					codingQuestion.bookmarked === true
		// 			);
		// 		} else if (state.type.includes('0')) {
		// 			x = curModule.codingQuestions.filter(
		// 				(codingQuestion: any) => codingQuestion.solved === true
		// 			);
		// 		} else {
		// 			x = curModule.codingQuestions.filter(
		// 				(codingQuestion: any) => codingQuestion.solved === false
		// 			);
		// 		}
		// 		state.filteredData = x;
		// 	} else if (state.type.length === 0 || state.type.length === 2) {
		// 		const x = curModule.codingQuestions.filter(
		// 			(codingQuestion: any) =>
		// 				state.difficulty.includes(
		// 					codingQuestion.difficulty === 'EASY'
		// 						? 0
		// 						: codingQuestion.difficulty === 'MEDIUM'
		// 						? 1
		// 						: 2
		// 				)
		// 		);
		// 		state.filteredData = x;
		// 	} else {
		// 		let x;

		// 		x = curModule.codingQuestions.filter(
		// 			(codingQuestion: any) =>
		// 				state.type.includes(
		// 					codingQuestion.solved ? '0' : '1'
		// 				) &&
		// 				state.difficulty.includes(
		// 					codingQuestion.difficulty === 'EASY'
		// 						? 0
		// 						: codingQuestion.difficulty === 'MEDIUM'
		// 						? 1
		// 						: 2
		// 				)
		// 		);
		// 		state.filteredData = x;
		// 	}
		// },

		handleFilterChange: (
			state,
			action: PayloadAction<any>
		) => {
			const curModule = action.payload;

			if (
				state.difficulty.length === 0 ||
				(state.difficulty.length === 3 &&
					state.type.length == 0) ||
				state.type.length === 2
			) {
				state.filteredData = curModule.codingQuestions;
			} else if (
				state.difficulty.length === 3 ||
				state.difficulty.length === 0
			) {
				const x = state.type.includes('2')
					? curModule.codingQuestions.filter(
							(codingQuestion: any) =>
								codingQuestion.bookmarked === true
					  )
					: state.type.includes('0')
					? curModule.codingQuestions.filter(
							(codingQuestion: any) =>
								codingQuestion.solved === true
					  )
					: curModule.codingQuestions.filter(
							(codingQuestion: any) =>
								codingQuestion.solved === false
					  );

				state.filteredData = x;
			} else if (
				state.type.length === 0 ||
				state.type.length === 2
			) {
				const x = curModule.codingQuestions.filter(
					(codingQuestion: any) =>
						state.difficulty.includes(
							codingQuestion.difficulty === 'EASY'
								? '0'
								: codingQuestion.difficulty === 'MEDIUM'
								? '1'
								: '2'
						)
				);
				state.filteredData = x;
			} else {
				const x = curModule.codingQuestions.filter(
					(codingQuestion: any) =>
						state.type.includes(
							codingQuestion.solved ? '0' : '1'
						) &&
						state.difficulty.includes(
							codingQuestion.difficulty === 'EASY'
								? '0'
								: codingQuestion.difficulty === 'MEDIUM'
								? '1'
								: '2'
						)
				);
				state.filteredData = x;
			}
		},

		handleSwitch: (state, action) => {
			state.loading = true;
			state.error.state = false;
			state.error.message = '';
		},
	},
	extraReducers: {
		[fetchModuleQuestions.pending as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		[fetchModuleQuestions.fulfilled as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.filteredData = action.payload.questions;
			state.modulesData[action.payload.module.id] =
				action.payload;
			state.error.state = false;
			state.error.message = '';
			// console.log('👉', state.filteredData);
			// console.log('👉', state.modulesData[action.payload.module.id]);
		},

		[fetchModuleQuestions.rejected as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		[fetchAllModules.pending as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		[fetchAllModules.fulfilled as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.allModulesData = action.payload;
			state.error.state = false;
			state.error.message = '';
		},

		[fetchAllModules.rejected as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
	},
});

//Exporting Actions
export const {
	setDifficulty,
	setType,
	setFilterData,
	handleFilterChange,
	handleSwitch,
} = assignmentSlice.actions;

export default assignmentSlice.reducer;
