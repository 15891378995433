import React, { useEffect, useState } from 'react';
import {
	OutlinedInput,
	InputLabel,
	MenuItem,
	FormControl,
	ListItemText,
	Select,
	Checkbox,
} from '@mui/material';
import { FormFieldProps } from '../types';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const MyMultiSelectField = ({
	id,
	label,
	items,
	formData,
	disabled,
	updateFormData,
	isProfileLocked,
	required,
}: FormFieldProps) => {
	const [value, setValue] = useState([]);

	useEffect(
		() => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			if (formData[id] !== null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				if (typeof formData[id] === 'string') {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					const temp = formData[id].split(',');
					// @ts-ignore
					console.log('temp12', formData[id]);
					setValue(temp);
				} else {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					if (formData && formData[id]) {
						//@ts-ignore
						setValue(formData[id]);
					}
				}
			}
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[formData[id], formData]
	);

	return (
		<div className="form-item">
			<FormControl fullWidth>
				<InputLabel
					required={required}
					disabled={disabled || isProfileLocked}
					sx={{
						color: 'rgba(0, 0, 0, 0.75)',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
				>
					{label}
				</InputLabel>
				<Select
					multiple
					disabled={disabled || isProfileLocked}
					required={required}
					input={<OutlinedInput label={label} />}
					// renderValue={(selected) => {
					// 	console.log('selected', selected);
					// 	return selected;
					// }}
					MenuProps={MenuProps}
					name={id}
					value={value}
					// value={
					// 	formData[
					// 		id as keyof (
					// 			| GeneralFormFields
					// 			| PlacementFormFields
					// 			)
					// 		] as string[]
					// }
					sx={{
						color: '#000',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
					onChange={(e) => {
						console.log(e.target.value);
						updateFormData(
							e.target.name,
							typeof e.target.value === 'string'
								? e.target.value.split(',')
								: e.target.value,
							e
						);
					}}
				>
					{items &&
						items.map((item, idx) => (
							<MenuItem
								key={idx}
								value={item}
							>
								{/*<Checkbox*/}
								{/*	checked={*/}
								{/*		(*/}
								{/*			formData[*/}
								{/*				id as keyof (*/}
								{/*					| GeneralFormFields*/}
								{/*					| PlacementFormFields*/}
								{/*					)*/}
								{/*				] as string[]*/}
								{/*		).indexOf(item) > -1*/}
								{/*	}*/}
								{/*/>*/}
								<ListItemText primary={item} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default MyMultiSelectField;
