import { Box } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import animation from './../assets/lottie/coming-soon.json';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography } from '@mui/joy';
import AccioButton from '../components/elements/Button';
import { FiArrowRight } from 'react-icons/fi';

const ComingSoonPage: FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const query = queryString.parse(location.search);
	const [pageType, setPageType] = useState<string | null>(
		'Session'
	);

	useEffect(() => {
		setPageType((query.pageType ?? 'Session') as string);
	}, []);

	return (
		<Box className={'comingSoon'}>
			<Lottie
				className="comingSoon-lottie"
				loop
				animationData={animation}
				play
			/>
			<Typography className={'comingSoon-text'}>
				{pageType} page is coming soon !
			</Typography>
			<AccioButton
				title=" Go Back to Home"
				className={'comingSoon-btn'}
				icon={<FiArrowRight />}
				action={() => {
					navigate('/');
				}}
			/>
		</Box>
	);
};

export default ComingSoonPage;
