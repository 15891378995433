import React from 'react';
import { encryptData } from '@acciojob/event-analytics';
import { AiFillPlayCircle } from 'react-icons/ai';

const ProjectResourceCard = ({
	el,
	setSelectedVideo,
	handleShow,
}: any) => {
	return (
		<div
			key={el.title + el.duration}
			className={'resource-card'}
			onClick={() => {
				setSelectedVideo(el);
				handleShow();
			}}
			event-analytics={
				'a59edca7-c9c3-4f54-9a7f-1a26e8ae75f9'
			}
			data-config={encryptData(
				JSON.stringify({
					name: el.title,
				})
			)}
		>
			<div
				className={'thumb'}
				style={{
					backgroundImage: `linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6)) ,url(${el.thumb})`,
				}}
			>
				<AiFillPlayCircle />
			</div>
			<div className={'details'}>
				<div className={'head'}>
					<h3>{el.title}</h3>
					<p>{el.duration}</p>
				</div>
				<p className={'meta'}>
					{el.date}
					<span>{el.totalVideo} Videos</span>
				</p>
			</div>
		</div>
	);
};

export default ProjectResourceCard;
