import { ClickAwayListener } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { RxEnter } from 'react-icons/rx';
import { services } from '../../apis';
import AccioButton from '../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../notification';
import InputField from '../shared/InputField';

// interface InputSearchBoxProps {
// 	typeCollege: any;
// 	collegeError: any;
// 	placeHolder: string;
// 	errorMessage: string;
// 	collegeNameval: string;
// 	setCollegeNameval: React.Dispatch<
// 		React.SetStateAction<string>
// 	>;
// }

const InputSearchBox = ({
	typeCollege,
	collegeError,
	placeHolder,
	errorMessage,
	collegeNameval,
	setCollegeNameval,
	setCollegeIdval,
	collegeIdval,
	id,
}: any) => {
	const [typedVal, setTypedVal] = useState<string>('');
	// const [collegeId, setCollegeId] = useState<string>("");
	const [results, setResults] = useState<Array<any>>([]);
	const [dropDownOpen, setDropDownOpen] =
		useState<boolean>(false);
	const [collegeSelected, setCollegeSelected] =
		useState<boolean>(false);
	const [collegeSubmitted, setCollegeSubmitted] =
		useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false); // Use a single loader state

	const handleCollegeNameChange = (
		value: string | null
	) => {
		console.log('value', value);
		setCollegeSelected(false);
		if (value !== null) setTypedVal(value);
		setCollegeNameval(value);

		if (!value) {
			setCollegeIdval(null);
			setCollegeNameval(null);
		}
	};

	const handleCollegeSelectionAndSubmit = async (result: {
		id: string | null;
		college_name: string;
	}) => {
		try {
			setLoading(true);
			let response: any;
			if (result.id === null) {
				response =
					await services.userService.updateCollegeName(
						result.college_name,
						result.id,
						typeCollege
					);
			} else {
				response =
					await services.userService.updateCollegeName(
						result.college_name,
						result.id,
						typeCollege
					);
			}

			console.log('response12', response);
			if (
				response &&
				response.statusCode !== 400 &&
				response.statusCode !== 500
			) {
				notifySuccess(response.message);
				setCollegeNameval(response.collegeDetails?.name);
				setCollegeIdval(response.collegeDetails?.id);
				if (
					collegeIdval &&
					setCollegeSubmitted.length === 0
				) {
					setCollegeSubmitted(true);
				}
			} else {
				notifyError(response.message);
			}
		} catch (error) {
			console.error('Error updating college name:', error);
		} finally {
			setLoading(false);
			setTypedVal('');
			setResults([]);
			setDropDownOpen(false);
		}
	};

	const handleCollegeSelection = async (result: any) => {
		handleCollegeSelectionAndSubmit(result);
	};

	const submitCollegeData = async () => {
		if (!collegeSelected) return;
		handleCollegeSelectionAndSubmit({
			id: null,
			college_name: typedVal,
		});
	};

	const searchColleges = useRef(
		debounce(async (searchText: string) => {
			if (searchText.length > 2) {
				try {
					setLoading(true);
					const results =
						(await services.userService.getCollegeNames(
							searchText
						)) as Array<any>;
					setResults(results);
				} catch (error) {
					console.error(
						'Error fetching college names:',
						error
					);
					// Handle error if needed
				} finally {
					setLoading(false);
				}
			} else {
				setResults([]);
			}
		}, 1000)
	);

	useEffect(() => {
		searchColleges.current(typedVal);
	}, [typedVal]);

	useEffect(() => {
		setDropDownOpen(results.length > 0);
	}, [results]);

	const handleBlur = () => {
		if (!collegeSubmitted) {
			setResults([]);
			setTypedVal('');
		}
	};

	return (
		<ClickAwayListener onClickAway={handleBlur}>
			<div className="college-suggestions-wrapper">
				<InputField
					id={id}
					type="text"
					value={collegeNameval ? collegeNameval : typedVal}
					onChange={(e: any) =>
						handleCollegeNameChange(e.target.value)
					}
					required
					placeHolder={placeHolder}
					showError={collegeError}
					errorMessage={errorMessage}
				/>
				{typedVal.length > 2 ? (
					<div className="college-suggestions-list-wrapper">
						<ul className="college-suggestions-lists">
							{results.length > 0 && dropDownOpen ? (
								results.map(
									(result: any, index: number) => (
										<li
											key={index}
											className={`college-suggestions-list ${
												result.college_name ===
												collegeNameval
													? 'selected-college'
													: ''
											}`}
											onClick={() =>
												handleCollegeSelection(result)
											}
										>
											{result.college_name}-
											{result.state && (
												<span className="college-suggestions-list-state">
													{result.state}
													{result.college_city && (
														<span className="college-suggestions-list-city">
															{'(' +
																result.college_city +
																')'}
														</span>
													)}
												</span>
											)}
										</li>
									)
								)
							) : (
								<li className="college-suggestions-list -no-data">
									No results found
								</li>
							)}
						</ul>
						<p
							className="create-college-instruction"
							onClick={() => setCollegeSelected(true)}
						>
							Create College
							<span className="college-name">
								{typedVal}
							</span>
						</p>
						{collegeSelected && (
							<AccioButton
								customClass="college-submit-btn"
								className=""
								variant="primary"
								title="Submit"
								icon={<RxEnter />}
								action={submitCollegeData}
							/>
						)}
					</div>
				) : (
					''
				)}
			</div>
		</ClickAwayListener>
	);
};

export default InputSearchBox;
