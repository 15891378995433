import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';
import { ENDPOINTS } from '../config/endpoints/stage';
import {
	AcademicDetailsInterface,
	ExperienceDetailsInterface,
	PersonalDetailsInterface,
} from '../redux/profile/profile.type';
import { Experience } from '../components/studentProfile/Modals/EditProfile/Forms/WorkExperience';

export class ProfileService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getContestData() {
		const url = `${this.endpoint}/contests/getContestPerformanceMetrics`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getPerformanceUpdates() {
		const url = `${this.endpoint}/users/getPerformanceMetrics`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getPersonalDetails() {
		const url = `${this.endpoint}/student-personal-details/getUserProfile`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAcademicDetails() {
		const url = `${this.endpoint}/student-personal-details/getUserAcademicDetails`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getExperienceDetails() {
		const url = `${this.endpoint}/student-personal-details/getWorkExperience`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postExperienceDetails({
		workExp,
		currentCtc,
	}: {
		workExp: Array<Experience>;
		currentCtc: number;
	}) {
		const url = `${this.endpoint}/student-personal-details/updateWorkExperience`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { workExp, currentCTC: currentCtc },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postPersonalDetails({
		body,
	}: {
		body: PersonalDetailsInterface;
	}) {
		const url = `${this.endpoint}/student-personal-details/updateUserProfile`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postAcademicDetails({
		body,
	}: {
		body: AcademicDetailsInterface;
	}) {
		const url = `${this.endpoint}/student-personal-details/updateUserAcademicDetails`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async deleteExperience({ id }: { id: string }) {
		const url = `${this.endpoint}/professional-experience/${id}`;
		try {
			const { data } = await this.httpService.delete<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
