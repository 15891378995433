export class SessionEngagement {
	mockInterviewBooked(
		sessionId: string,
		sessionTime: string,
		mentorId: string,
		sessionType: string
	) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('joined_live_doubt_session', {
				sessionType: sessionType,
				join_time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	mockInterviewCancelled(topic: string, slot: any) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('booked_live_doubt_session', {
				slot_time: slot,
				topic: topic,
			});
			console.log('booked session', topic, slot);
		} catch (e) {
			console.log(e);
		}
	}

	mockInterviewRescheduled(
		className: string,
		classId?: string
	) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('class_joined', {
				class_name: className,
				class_id: classId,
				start_time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	watchRecording(
		videoName: string,
		videoID: string,
		courseName: string,
		moduleName: string
	) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('question_submission', {
				video_id: videoID,
				video_name: videoName,
				time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}
}
