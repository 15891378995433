import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import moment from 'moment';
import { services } from '../../apis';

const timeChanger = (dateRaw: any) => {
	const dateRawArray = dateRaw.split('T');
	const date = dateRawArray[0];
	const time = dateRawArray[1].split('.')[0];
	return date + ' ' + time;
};

// export const fetchSessionType = createAsyncThunk(
// 	'onevone/fetchSessionType',
// 	async (payload: any) => {
// 		const data =
// 			await services.sessionService.fetchUpcomingSessions(
// 				payload.userId,
// 				payload.userType
// 			);
// 		// console.log('session data onevone slice', data);
// 		let noSession = true;
// 		const fullData = data.filter((el: any) => {
// 			let cardOpenView = false;

// 			if (el.upcomingInterviews.length > 0) {
// 				cardOpenView = true;
// 				for (
// 					let i = 0;
// 					i < el.upcomingInterviews.length;
// 					i++
// 				) {
// 					let joinbtn = false;
// 					const curr = moment().add('30', 'minutes');
// 					if (
// 						curr >=
// 						moment(el.upcomingInterviews[i].sessionDate)
// 					) {
// 						joinbtn = true;
// 					}
// 					let cancelReschedulBtnView = false;
// 					let joinBtnView = false;
// 					let joinBtnActive = false;
// 					let notShowBtnView = false;
// 					const current = moment(new Date());
// 					const session = moment(
// 						el.upcomingInterviews[i].sessionDate
// 					);

// 					if (session.diff(current, 'minutes') > 120) {
// 						cancelReschedulBtnView = true;
// 						joinBtnView = false;
// 						joinBtnActive = false;
// 						notShowBtnView = false;
// 					} else if (
// 						session.diff(current, 'minutes') <= 120 &&
// 						session.diff(current, 'minutes') > 15
// 					) {
// 						cancelReschedulBtnView = false;
// 						joinBtnView = true;
// 						joinBtnActive = false;
// 						notShowBtnView = false;
// 					} else if (
// 						session.diff(current, 'minutes') <= 15 &&
// 						session.diff(current, 'minutes') > 0
// 					) {
// 						cancelReschedulBtnView = false;
// 						joinBtnView = true;
// 						joinBtnActive = true;
// 						notShowBtnView = false;
// 					} else if (
// 						current.diff(session, 'minutes') >= 0 &&
// 						current.diff(session, 'minutes') <= 15
// 					) {
// 						cancelReschedulBtnView = false;
// 						joinBtnView = true;
// 						joinBtnActive = true;
// 						notShowBtnView = false;
// 					} else if (
// 						current.diff(session, 'minutes') > 15 &&
// 						current.diff(session, 'minutes') <= 35
// 					) {
// 						cancelReschedulBtnView = false;
// 						joinBtnView = true;
// 						joinBtnActive = true;
// 						notShowBtnView = true;
// 					} else if (
// 						current.diff(session, 'minutes') > 36
// 					) {
// 						cancelReschedulBtnView = false;
// 						joinBtnView = true;
// 						joinBtnActive = true;
// 						notShowBtnView = false;
// 					} else {
// 						cancelReschedulBtnView = false;
// 						joinBtnView = false;
// 						joinBtnActive = false;
// 						notShowBtnView = false;
// 					}

// 					// const date=timeChanger(el.upcomingInterviews[i].sessionDate)
// 					// // const data2=timeChanger("2023-01-14T6:44:00.000Z")

// 					// if (
// 					// 	new Date()>=new Date(new Date(date).getTime()+35*60*1000)
// 					// ) {
// 					// 	console.log("first",4);
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = true;
// 					// 	notShowBtnView = true;
// 					// }

// 					// if (
// 					// 	new Date()>=new Date(new Date(date).getTime()+15*60*1000)
// 					// ) {
// 					// 	console.log("first",3);
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = true;
// 					// 	notShowBtnView = false;
// 					// }

// 					// if (
// 					// 	new Date()>= new Date(new Date(date).getTime()-15*60*1000)
// 					// ) {
// 					// 	console.log("first",2);
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = false;
// 					// 	notShowBtnView = false;
// 					// }

// 					//  if (new Date()>=new Date(new Date(date).getTime()-2*60*60*1000)) {
// 					// 	console.log("first",1);
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = false;
// 					// 	notShowBtnView = false;
// 					//  }
// 					// // }else{
// 					// // 	console.log("first",5);
// 					// // 	cancelReschedulBtnView = true;
// 					// // 	joinBtnView = false;
// 					// // 	joinBtnActive = false;
// 					// // 	notShowBtnView = false;
// 					// // }
// 					// // console.log("first",(new Date(data1)).getTime())
// 					// // console.log("first","date1-->",new Date(((new Date(data1)).getTime()-2*60*60*1000)))
// 					// // console.log("first","date2-->",new Date(data2))
// 					// // console.log("first","cancelReschedulBtnView->"+cancelReschedulBtnView+" "+"joinBtnView->"+joinBtnView+" "+"joinBtnActive->"+joinBtnActive+" "+"notShowBtnView->"+notShowBtnView)
// 					// const beforeTwoHr = moment().add('2', 'hours');
// 					// const beforefiftenMin = moment().add('15', 'minutes');
// 					// const afterfiftenMin = moment().subtract('15', 'minutes');
// 					// const afterthirtyfiveMin = moment().subtract('35','minutes');
// 					// if (beforeTwoHr <=moment(el.upcomingInterviews[i].sessionDate)
// 					// ) {
// 					// 	cancelReschedulBtnView = true;
// 					// 	joinBtnView = false;
// 					// 	joinBtnActive = false;
// 					// 	notShowBtnView = false;
// 					// } else if (beforefiftenMin <=moment(el.upcomingInterviews[i].sessionDate)
// 					// ) {
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = false;
// 					// 	notShowBtnView = false;
// 					// } else if (afterfiftenMin <=moment(el.upcomingInterviews[i].sessionDate)
// 					// ) {
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = true;
// 					// 	notShowBtnView = false;
// 					// } else if (
// 					// 	afterthirtyfiveMin <=moment(el.upcomingInterviews[i].sessionDate)
// 					// ) {
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = true;
// 					// 	joinBtnActive = true;
// 					// 	notShowBtnView = true;
// 					// }else{
// 					// 	cancelReschedulBtnView = false;
// 					// 	joinBtnView = false;
// 					// 	joinBtnActive = false;
// 					// 	notShowBtnView = false;
// 					// }
// 					el.upcomingInterviews[i].enableJoinNow = joinbtn;
// 					el.upcomingInterviews[i].cancelReschedulBtnView =
// 						cancelReschedulBtnView;
// 					el.upcomingInterviews[i].joinBtnView =
// 						joinBtnView;
// 					el.upcomingInterviews[i].joinBtnActive =
// 						joinBtnActive;
// 					el.upcomingInterviews[i].notShowBtnView =
// 						notShowBtnView;
// 					el.cardOpenView = cardOpenView;
// 				}
// 			} else {
// 				cardOpenView = false;
// 			}

// 			if (el.pastInterviews.length > 0) {
// 				for (let i = 0; i < el.pastInterviews.length; i++) {
// 					let studentFeedbackview = false;
// 					let waitforMentorFeedback = false;
// 					let showMark = false;
// 					let viewRecording = false;
// 					let viewfeedback = false;

// 					if (
// 						el.pastInterviews[i].studentFeedback.length ===
// 						0
// 					) {
// 						studentFeedbackview = true;
// 						waitforMentorFeedback = false;
// 						showMark = false;
// 						viewRecording = false;
// 						viewfeedback = false;
// 					} else if (
// 						el.pastInterviews[i].mentorFeedback.length === 0
// 					) {
// 						studentFeedbackview = false;
// 						waitforMentorFeedback = true;
// 						showMark = false;
// 						viewRecording = false;
// 						viewfeedback = false;
// 					} else {
// 						studentFeedbackview = false;
// 						waitforMentorFeedback = false;
// 						showMark = true;
// 						viewfeedback = true;
// 					}

// 					if (
// 						el.pastInterviews[i].recordings.length === 0
// 					) {
// 						viewRecording = false;
// 					} else {
// 						viewRecording = true;
// 					}

// 					el.pastInterviews[i].studentFeedbackview =
// 						studentFeedbackview;
// 					el.pastInterviews[i].waitforMentorFeedback =
// 						waitforMentorFeedback;
// 					el.pastInterviews[i].showMark = showMark;
// 					el.pastInterviews[i].viewRecording =
// 						viewRecording;
// 					el.pastInterviews[i].viewfeedback = viewfeedback;
// 				}
// 			}

// 			if (
// 				el.upcomingInterviews.length > 0 ||
// 				el.pastInterviews.length > 0 ||
// 				el.isfutureEligible
// 			) {
// 				noSession = false;
// 			}
// 			return el;
// 		});

// 		const upcomingDataMentor = fullData.filter(
// 			(el: any) =>
// 				el.sessionInfo.isMentorShip &&
// 				el.upcomingInterviews.length > 0
// 		);
// 		const futureEligibleMentor = fullData.filter(
// 			(el: any) =>
// 				el.sessionInfo.isMentorShip &&
// 				el.isfutureEligible &&
// 				el.upcomingInterviews.length === 0
// 		);
// 		const pastMentor = fullData.filter(
// 			(el: any) =>
// 				el.sessionInfo.isMentorShip &&
// 				!el.isfutureEligible &&
// 				el.pastInterviews.length > 0 &&
// 				el.upcomingInterviews.length === 0
// 		);
// 		const remaingMentor = fullData.filter(
// 			(el: any) =>
// 				el.sessionInfo.isMentorShip &&
// 				!el.isfutureEligible &&
// 				el.pastInterviews.length === 0 &&
// 				el.upcomingInterviews.length === 0 &&
// 				!el.sessionInfo.isCustomSession
// 		);
// 		// const upcomingisfutureEligibleDataMentor = data.filter((el: any)=>el.isfutureEligible&&el.sessionInfo.isMentorShip&&el.upcomingInterviews.length === 0)
// 		// const pastDataMentor = data.filter((el: any)=>!el.sessionInfo.isMentorShip&&el.pastInterviews.length > 0)
// 		// const cancelDataMentor = data.filter((el: any)=>!el.sessionInfo.isMentorShip&&el.rescheduledInterviews.length > 0)
// 		// const rescheduleDataMentor = data.filter((el: any)=>!el.sessionInfo.isMentorShip&&el.rescheduledInterviews.length > 0)

// 		// upcomingDataMentor.sort(
// 		// 	(current: any, b: any) =>
// 		// 		new Date(a.sessionDate).getTime() -
// 		// 		new Date(b.sessionDate).getTime()
// 		// );

// 		// upcomingDataMentor.sort((a:any, b:any)=>{
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// })

// 		// futureEligibleMentor.sort((a:any, b:any)=>{
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// })

// 		// pastMentor.sort((a:any, b:any)=>{
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// })

// 		// remaingMentor.sort((a:any, b:any)=>{
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// })

// 		const fullMentorData = [
// 			...upcomingDataMentor,
// 			...futureEligibleMentor,
// 			...pastMentor,
// 			...remaingMentor,
// 		];

// 		// -------------------------------------
// 		const upcomingDataMock = fullData.filter(
// 			(el: any) =>
// 				!el.sessionInfo.isMentorShip &&
// 				el.upcomingInterviews.length > 0
// 		);
// 		const futureEligibleMock = fullData.filter(
// 			(el: any) =>
// 				!el.sessionInfo.isMentorShip &&
// 				el.isfutureEligible &&
// 				el.upcomingInterviews.length === 0
// 		);
// 		const pastMock = fullData.filter(
// 			(el: any) =>
// 				!el.sessionInfo.isMentorShip &&
// 				!el.isfutureEligible &&
// 				el.pastInterviews.length > 0 &&
// 				el.upcomingInterviews.length === 0
// 		);
// 		const remaingMock = fullData.filter(
// 			(el: any) =>
// 				!el.sessionInfo.isMentorShip &&
// 				!el.isfutureEligible &&
// 				el.pastInterviews.length === 0 &&
// 				el.upcomingInterviews.length === 0 &&
// 				!el.sessionInfo.isCustomSession
// 		);
// 		// const upcomingDataMock = data.filter((el: any)=>el.sessionInfo.isMentorShip&&el.upcomingInterviews.length > 0)
// 		// const pastDataMock = data.filter((el: any)=>el.sessionInfo.isMentorShip&&el.pastInterviews.length > 0)
// 		// const cancelDataMock = data.filter((el: any)=>el.sessionInfo.isMentorShip&&el.rescheduledInterviews.length > 0)
// 		// const rescheduleDataMock = data.filter((el: any)=>el.sessionInfo.isMentorShip&&el.rescheduledInterviews.length > 0)

// 		// upcomingDataMock.sort((a: any, b: any) => {
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// });

// 		// futureEligibleMock.sort((a: any, b: any) => {
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// });

// 		// pastMock.sort((a: any, b: any) => {
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// });

// 		// remaingMock.sort((a: any, b: any) => {
// 		// 	return moment(a.sessionDate).diff(b.sessionDate);
// 		// });
// 		const fullMockData = [
// 			...upcomingDataMock,
// 			...futureEligibleMock,
// 			...pastMock,
// 			...remaingMock,
// 		];
// 		console.log('FULL DATA', fullData);

// 		console.log(
// 			'upcomingDataMentor------------>',
// 			upcomingDataMentor
// 		);
// 		console.log(
// 			'upcomingisfutureEligibleDataMentor------------>',
// 			futureEligibleMentor
// 		);
// 		console.log('pastDataMentor------------>', pastMentor);
// 		console.log(
// 			'remaingMentor------------>',
// 			remaingMentor
// 		);
// 		// console.log('rescheduleDataMentor------------>', rescheduleDataMentor);

// 		console.log('fullMockData====>', fullMockData);

// 		console.log(
// 			'upcomingDataMock------------>',
// 			upcomingDataMock
// 		);
// 		console.log(
// 			'futureEligibleMock------------>',
// 			futureEligibleMock
// 		);
// 		console.log('pastMock------------>', pastMock);
// 		console.log('remaingMock------------>', remaingMock);

// 		console.log('fullMentorData====>', fullMentorData);

// 		return {
// 			fullData,
// 			noSession,
// 			data,
// 			fullMockData,
// 			fullMentorData,
// 			pastMentor,
// 			pastMock,
// 		};
// 	}
// );

export const fetchDate = createAsyncThunk(
	'onevone/fetchdate',
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	async (date: string, sessionId: string, slot: string) => {
		// const data = await services.courseService.fetchAvailabilityDate({
		// 	date,
		// 	sessionId,
		// 	slot,
		// });
		// console.log('fetchAllModules', data);
		// data.date = date;
		// return data;
	}
);

export const fetchAllUpcomingSessionType = createAsyncThunk(
	'onevone/fetchAllUpcomingSessionType',

	async (payload: any) => {
		const data =
			await services.sessionService.fetchUpcomingAllSessions(
				payload.userId,
				payload.userType
			);

		if (data.upcomingSessions.length > 0) {
			data.upcomingSessions.filter((el: any) => {
				const current = moment(new Date());
				const session = moment(el.sessionDate);
				let joinActive = false;
				// && current.diff(session, 'minutes')<=35
				if (session.diff(current, 'minutes') <= 15) {
					joinActive = true;
				} else {
					joinActive = false;
				}
				el.joinActive = joinActive;

				return el;
			});
		}

		return data;
	}
);

interface onevoneState {
	sessionData: any;
	status: any;
	date: string;
	sessionId: string;
	slot: string;
	noSession: boolean;
	isfutureEligible: boolean;
	allUpcomingSessionType: any;
	upcomingDataMentorData: [];
	futureEligibleMentorData: [];
	pastMentorData: [];
	remaingMentorData: [];
	upcomingDataMockData: [];
	futureEligibleMockData: [];
	pastMockData: [];
	remaingMockData: [];
	allMentorData: [];
	allMockData: [];
	error: {
		state: boolean;
		message: string;
	};
}

const initialState: onevoneState = {
	sessionData: null,
	status: null,
	date: '',
	sessionId: '',
	slot: '',
	noSession: false,
	isfutureEligible: false,
	allUpcomingSessionType: null,
	upcomingDataMentorData: [],
	futureEligibleMentorData: [],
	pastMentorData: [],
	remaingMentorData: [],
	upcomingDataMockData: [],
	futureEligibleMockData: [],
	pastMockData: [],
	remaingMockData: [],
	allMentorData: [],
	allMockData: [],
	error: {
		state: false,
		message: '',
	},
};

export const onevoneSlice = createSlice({
	name: 'onevone',
	initialState,
	reducers: {},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		// [fetchSessionType.pending]: (
		// 	state: onevoneState,
		// 	action: PayloadAction<any>
		// ) => {
		// 	state.sessionData = null;
		// 	//   state.sessionData.isEligible = false;
		// 	state.status = 'loading';
		// },
		// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// //@ts-ignore
		// [fetchSessionType.fulfilled]: (
		// 	state: onevoneState,
		// 	action: PayloadAction<any>
		// ) => {
		// 	// console.log('SESSION PAYLOAD', action.payload);
		// 	state.sessionData = action.payload.fullData;
		// 	state.noSession = action.payload.noSession;
		// 	state.status = 'success';
		// 	const temp = action.payload.fullData.find(
		// 		(el: any) =>
		// 			el.sessionInfo.sessionType === 'HR Mock'
		// 	);
		// 	state.isfutureEligible =
		// 		temp && temp.isfutureEligible;
		// 	state.error.state = false;
		// 	state.error.message = '';
		// 	const fullData = action.payload.data;
		// 	state.upcomingDataMentorData =
		// 		action.payload.upcomingDataMentor;
		// 	state.futureEligibleMentorData =
		// 		action.payload.futureEligibleMentor;
		// 	state.pastMentorData = action.payload.pastDataMentor;
		// 	state.remaingMentorData =
		// 		action.payload.remaingMentor;
		// 	state.upcomingDataMockData =
		// 		action.payload.upcomingDataMock;
		// 	state.futureEligibleMockData =
		// 		action.payload.futureEligibleMock;
		// 	state.pastMockData = action.payload.pastDataMock;
		// 	state.remaingMockData = action.payload.remaingMentor;
		// 	state.allMockData = action.payload.fullMockData;
		// 	state.allMockData = action.payload.fullMockData;
		// 	state.allMentorData = action.payload.fullMentorData;

		// 	// console.log('fullData', fullData);
		// },
		// // eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// //@ts-ignore
		// [fetchSessionType.rejected]: (
		// 	state: onevoneState,
		// 	action: PayloadAction<any>
		// ) => {
		// 	state.error.state = true;
		// 	state.error.message = action.payload;
		// 	state.status = 'error';
		// },

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllUpcomingSessionType.pending]: (
			state: onevoneState,
			action: PayloadAction<any>
		) => {
			state.allUpcomingSessionType = null;
			//   state.sessionData.isEligible = false;
			state.status = 'loading';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllUpcomingSessionType.fulfilled]: (
			state: onevoneState,
			action: PayloadAction<any>
		) => {
			state.allUpcomingSessionType = action.payload;
			// console.log('upcoming', action.payload);
			state.status = 'success';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllUpcomingSessionType.rejected]: (
			state: onevoneState,
			action: PayloadAction<any>
		) => {
			state.error.state = true;
			state.error.message = action.payload;
			state.status = 'error';
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchDate.pending]: (
			state: onevoneState,
			action: PayloadAction<any>
		) => {
			state.status = 'loading';
			state.sessionData = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchDate.fulfilled]: (
			state: onevoneState,
			action: PayloadAction<any>
		) => {
			state.sessionData = { ...action.payload };
			state.status = 'success';
			state.error.state = false;
			state.error.message = '';
			state.date = action.payload.date;
			state.sessionId = action.payload.sessionId;
			state.slot = action.payload.slot;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchDate.rejected]: (
			state: onevoneState,
			action: PayloadAction<any>
		) => {
			state.error.state = true;
			state.error.message = action.payload;
			state.status = 'error';
		},
	},
});

export default onevoneSlice.reducer;
