import { Loader } from '@acciojob/loader';
import building from '../../assets/images/building.svg';
import { useAppSelector } from '../../redux/store';
import { AchievementsCard } from './Cards/AchievementsCard';
import { ActivityCard } from './Cards/ActivityCard';
import { ContestRanking } from './Cards/ContestRanking';
import { PauseCard } from './Cards/PauseCard';
import { PerformanceHeatmap } from './Cards/PerformanceHeatmap';
import { ProjectsCard } from './Cards/ProjectsCard';
import { QuestionsCard } from './Cards/QuestionsCard';
import { ResumeCard } from './Cards/ResumeCard';

export const MainProfile = () => {
	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	const { isLoading } = useAppSelector(
		(state) => state.gym
	);

	if (
		performanceData.loading ||
		!performanceData.data ||
		isLoading
	) {
		return (
			<div className="main-profile-wrapper">
				<Loader height={'60vh'} />
			</div>
		);
	} else
		return (
			<div className="main-profile-wrapper">
				<img
					src={building}
					className="background-building-image"
				/>
				<div className="row-flex">
					<ActivityCard />
					<QuestionsCard />
					<ResumeCard />
				</div>
				<div className="row-flex">
					<AchievementsCard />
					<PerformanceHeatmap />
				</div>
				<div className="row-flex">
					<ProjectsCard />
					<ContestRanking />
				</div>
				<div className="row-flex">
					<PauseCard />
				</div>
			</div>
		);
};
