import store from '../redux/store';

export const convertToLowerCaseAndReplaceSpaces = (
	text: string
): string => {
	const lowercaseText = text.toLowerCase();
	const replacedText = lowercaseText.replace(/ /g, '_');
	return replacedText;
};

export const isRedirect = (label: string): boolean => {
	switch (label) {
		case 'Home':
			return true;

		case 'Open Editor':
			return true;

		default:
			return false;
	}
};

const userRole = (): string => {
	const state = store.getState();
	//@ts-ignore
	return state.user.userRole;
};

export const isMainCourseUser = () => {
	return userRole() === 'maincourse-user';
};
