import { ModalClose, Sheet } from '@mui/joy';
import { CircularProgress, Modal } from '@mui/material';
import {
	useEffect,
	useMemo,
	useRef,
	useState,
	type FC,
} from 'react';
import { AiOutlinePieChart } from 'react-icons/ai';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { FcClock } from 'react-icons/fc';
import { FiArrowRight } from 'react-icons/fi';
import { SiSimpleanalytics } from 'react-icons/si';
import { useNavigate } from 'react-router-dom';
import { services } from '../../apis';
import DATakeTour from '../../assets/images/da-playground-tour.svg';
import AccioButton from '../../components/elements/Button';
import { VimeoVideoPlayer } from '../../components/shared/VimeoVideoPlayer';
import { useAppSelector } from '../../redux/store';

type Stats = {
	codingQuestions: number;
	conceptualQuestions: number;
	sqlQuestions: number;
	excelQuestions: number;
	dataScienceQuestions: number;
};

type DAPlaygroundModuleCompletionStats = {
	totalPlaygroundQuestions: Stats;
	totalSolvedQuestions: Stats;
};

const traits = [
	{
		id: 'technicalProficiency',
		icon: <SiSimpleanalytics />,
		title: 'Technical Proficiency',
		description:
			'The first step is to become proficient in technical tools like Excel, SQL, PowerBI, Python, ML as they are essential skills for handling and interpreting data.',
		showProgress: true,
		link: '/modules?courseId=f202f2e1-add4-407e-9254-c7a1b9f7e93e',
	},
	{
		id: 'problemSolving',
		icon: <FcClock />,
		title: 'Problem Solving',
		description:
			'It is very important to break problems in to smaller parts that exhaustively cover the problem statement. Problem Solving skills are the key to identifying the root cause of a business problem and figuring out how data can be used to solve it.',
		showProgress: true,
		link: '/modules?courseId=fb89367f-7e7b-4008-aeaa-379967c57515',
	},
	{
		id: 'aptitude',
		icon: <AiOutlinePieChart />,
		title: 'Aptitude',
		description:
			'Building your aptitude skills is crucial for understanding and interpreting complex problems and solving them.',
		showProgress: true,
		link: '/modules?courseId=882110e1-5d5c-4c78-85eb-848042297b09',
	},
	{
		id: 'communicationSkills',
		icon: <AiOutlinePieChart />,
		title: 'Communication Skills',
		description:
			'Data analysts often need to present their findings to non-technical stakeholders. Being able to explain complex data in a clear, understandable way through a storyline is crucial.',
		showProgress: false,
		link: '/softskills',
	},
	{
		id: 'businessAcumen',
		icon: <AiOutlinePieChart />,
		title: 'Business Acumen',
		description:
			"Understanding the industry and the business's goals is important for aligning data insights with strategic objectives. This includes knowledge of the business's operations, the industry's trends, and how data can be used to support business decisions.",
		showProgress: false,
		link: '/projects',
	},
];

export const DAPlayground: FC = () => {
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);

	const [completionStats, setCompletionStats] = useState({
		technicalProficiency: 0,
		problemSolving: 0,
		aptitude: 0,
	});

	const [showIntroModal, setShowIntroModal] = useState<{
		source: string;
	}>();

	const fetchDAPlaygroundModuleCompletionStats =
		async () => {
			try {
				const stats =
					await services.daPlaygroundService.getDAPlaygroundModuleCompletionStats();
				const {
					totalPlaygroundQuestions,
					totalSolvedQuestions,
				} = stats as DAPlaygroundModuleCompletionStats;

				const technicalProficiencyPercentage = Math.round(
					((totalSolvedQuestions.dataScienceQuestions +
						totalSolvedQuestions.excelQuestions +
						totalSolvedQuestions.sqlQuestions) /
						(totalPlaygroundQuestions.dataScienceQuestions +
							totalPlaygroundQuestions.excelQuestions +
							totalPlaygroundQuestions.sqlQuestions)) *
						100
				);
				const problemSolvingPercentage = Math.round(
					(totalSolvedQuestions.codingQuestions /
						totalPlaygroundQuestions.codingQuestions) *
						100
				);
				const aptitudePercentage = Math.round(
					(totalSolvedQuestions.conceptualQuestions /
						totalPlaygroundQuestions.conceptualQuestions) *
						100
				);

				setCompletionStats({
					technicalProficiency:
						technicalProficiencyPercentage,
					problemSolving: problemSolvingPercentage,
					aptitude: aptitudePercentage,
				});
			} catch (error) {
				console.error(error);
			}
		};

	useEffect(() => {
		if (!localStorage.getItem('introModalShowed')) {
			setShowIntroModal({ source: 'tour' });
			localStorage.setItem('introModalShowed', 'true');
		}
		fetchDAPlaygroundModuleCompletionStats();
	}, []);

	useEffect(() => {}, []);

	return (
		<div className="da-playground-container">
			<div className="section1 section">
				<div className="welcome-section">
					<h3>Welcome</h3>
					<h3 className="username">{user?.firstName}</h3>
					<p>
						Your determination has led you here and now,
						your journey to becoming a Data Analytics wizard
						starts. Remember, every master was once a
						beginner. Gear up for an enriching and rewarding
						learning experience with us!
					</p>
				</div>
				<div className="take-tour">
					<div className="take-tour-content">
						<h3>
							Learn what you can do on our Launchpad ?
						</h3>
						<p>
							Take a short tour of our platform and learn
							more about what you can{' '}
						</p>
						<button
							onClick={() =>
								setShowIntroModal({ source: 'tour' })
							}
						>
							Take a tour <FiArrowRight />
						</button>
					</div>
					<div className="icon">
						<img src={DATakeTour} />
					</div>
				</div>
			</div>

			<div className="section2 section">
				<div className="section-header uppercase">
					<span>
						<FiArrowRight />
					</span>
					Traits of being a Great Data Analyst
				</div>

				<div className="video-container">
					<div
						className="video-dummy"
						onClick={() =>
							setShowIntroModal({ source: 'video' })
						}
					>
						<BsFillPlayCircleFill
							size={25}
							color="white"
						/>
					</div>
					<div className="texts">
						<h3>How to become a Data Analytics Expert?</h3>
						<p>
							To become proficient in Data Analytics,
							several essential skills are crucial for
							success in this field. Data analysis involves
							extracting valuable insights and patterns from
							large datasets, aiding in informed
							decision-making. Here's an introduction to the
							required skills to become a data analytics
							expert:
						</p>
					</div>
				</div>

				<div className="traits-section">
					{traits.map((trait, index) => (
						<div
							className="trait"
							key={index}
						>
							<div className="header">
								<div className="icon">{trait.icon}</div>
								<div className="title">{trait.title}</div>
							</div>
							<div className="description">
								{trait.description}
							</div>
						</div>
					))}
				</div>
			</div>

			<div className="section3 section">
				<div className="section-header uppercase">
					<span>
						<FiArrowRight />
					</span>
					Traits Section
				</div>

				<div className="trait-detail-cards">
					{traits.map((trait, index) => {
						const completionPercentage =
							// @ts-ignore
							completionStats[trait.id];
						return (
							<div
								className="trait-detail-card"
								key={index}
							>
								<div
									className={`icon-container ${
										trait.showProgress
											? 'show-progress'
											: null
									}`}
								>
									{completionPercentage ? (
										<div className="progress">
											<CircularProgress
												variant="determinate"
												value={completionPercentage}
												size={64}
												color="primary"
											/>
										</div>
									) : null}
									<div className="icon">{trait.icon}</div>
								</div>
								<div className="title">{trait.title}</div>
								<AccioButton
									title="Enter Module"
									icon={<FiArrowRight />}
									iconPosition="right"
									action={() => navigate(trait.link)}
								/>
							</div>
						);
					})}
				</div>
			</div>

			<Modal
				open={!!showIntroModal}
				onClose={() => setShowIntroModal(undefined)}
				disableAutoFocus
				disablePortal
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Sheet
					sx={{
						height: 550,
						width: 768,
						margin: 'auto',
						backgroundColor: 'white',
						display: 'flex',
						alignItems: 'stretch',
						justifyContent: 'stretch',
						borderRadius: 5,
					}}
				>
					<ModalClose
						sx={{
							top: 'calc(-1/4 * var(--IconButton-size))',
							right: 'calc(-1/4 * var(--IconButton-size))',
							boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
							borderRadius: '50%',
							bgcolor: 'background.body',
							zIndex: 10001,
						}}
						onClick={() => setShowIntroModal(undefined)}
					/>
					<IntroModalContainer
						onlyVideo={showIntroModal?.source === 'video'}
					/>
				</Sheet>
			</Modal>
		</div>
	);
};

export const IntroModalContainer: FC<{
	onlyVideo: boolean;
}> = ({ onlyVideo = false }) => {
	const [isVideoPlaying, setVideoPlaying] = useState(false);
	const [showButton, setShowButton] = useState(false);

	const timeout = useRef<any>();

	const videoRef = useRef<any>();

	const callbacks = useMemo(() => {
		return {
			onPause: () => {
				setVideoPlaying(false);
			},
			onPlay: () => {
				console.log('playing');
				setVideoPlaying(true);
			},
		};
	}, []);

	return (
		<div className="intro-modal-container">
			{!onlyVideo ? (
				<div className="left">
					<p>
						Welcome aboard! 🚀 Before diving in, watch our
						course and portal tour video. It's your roadmap
						to confidently navigating the course and portal.
					</p>
					<h6>
						After watching this video you will find answers
						and get clarity on:
					</h6>
					<ul>
						<li>Course and curriculum structure</li>
						<li>
							The skills which are important to become a
							Data Analytics Expert
						</li>
						<li>
							The AccioJob portal which will help you
							throughout your journey to acquire these
							skills
						</li>
						<li>
							How to seek and get assistance at any step
							during your learning journey
						</li>
						<li>
							Your roadmap to up-skilling and eventually to
							success at the end of the course
						</li>
					</ul>

					<h5>Happy Learning!</h5>
				</div>
			) : null}
			<div
				className="right"
				style={{
					width: onlyVideo ? '100%' : '50%',
					height: '100%',
				}}
			>
				<VimeoVideoPlayer
					ref={videoRef}
					options={{
						url: onlyVideo
							? 'https://vimeo.com/869105986'
							: 'https://vimeo.com/869106797',
						...(onlyVideo
							? { height: 550, width: 768 }
							: { height: 550, width: 384 }),
						controls: true,
						autoplay: true,
					}}
					style={{
						backgroundColor: 'black',
						...(onlyVideo
							? {
									height: '100%',
									width: '100%',
							  }
							: null),
					}}
					callbacks={callbacks}
				/>
				{/* <div
					className="play-pause-btn"
					onClick={() => {
						setShowButton(true);
						if (isVideoPlaying)
							videoRef.current?.getPlayer().pause();
						else videoRef.current?.getPlayer().play();
						clearTimeout(timeout.current);
						timeout.current = setTimeout(() => {
							setShowButton(false);
						}, 2500);
					}}
				>
					<div
						className={`icon ${
							showButton ? 'show' : 'hide'
						}`}
					>
						{isVideoPlaying ? (
							<BsFillPauseCircleFill />
						) : (
							<BsFillPlayCircleFill />
						)}
					</div>
				</div> */}
			</div>
		</div>
	);
};
