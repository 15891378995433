import { Loader } from '@acciojob/loader';
import { PieChart } from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../redux/store';
export const QuestionAnalysis = () => {
	const [loading, setLoading] = useState(true);
	const { userQuestionSubmissionData } = useAppSelector(
		(state) => state.gym
	);

	const [data, setData] = useState([
		{ value: 0, label: 'Easy', color: '#34D399' },
		{ value: 0, label: 'Medium', color: '#F9C74F' },
		{ value: 0, label: 'Hard', color: '#F94144' },
	]);

	useEffect(() => {
		if (userQuestionSubmissionData) {
			console.log(
				'userQuestionSubmissionData123>>>',
				userQuestionSubmissionData
			);
			if (userQuestionSubmissionData.questionAnalysis) {
				const myData = data;
				myData[0].value = parseInt(
					userQuestionSubmissionData.questionAnalysis[0]
						.solved
				);

				myData[1].value = parseInt(
					userQuestionSubmissionData.questionAnalysis[1]
						.solved
				);

				myData[2].value = parseInt(
					userQuestionSubmissionData.questionAnalysis[2]
						.solved
				);
				setData(myData);
				setLoading(false);
			}
		}
	}, []);

	const TOTAL = data
		.map((item) => item.value)
		.reduce((a, b) => a + b, 0);

	if (!loading)
		return (
			<div
				className="preview-card"
				style={{ gap: '0px', maxWidth: 300 }}
			>
				<div
					className="right-div"
					style={{ width: '30%' }}
				>
					<p className="heading">Question Analysis</p>
					<p
						className="days-text"
						style={{
							marginTop: '8px',
						}}
					>
						{TOTAL}
						<p
							className="grey"
							style={{
								textAlign: 'center',
								width: 'min-content',
							}}
						>
							Questions Solved
						</p>
					</p>
				</div>

				<PieChart
					sx={{
						transform: 'scale(0.85)',
						'& .MuiChartsLegend-root': {
							transform:
								'translateX(65%) translateY(10%) scale(0.9)',
						},
					}}
					colors={['#34D399', '#F9C74F', '#F94144']}
					series={[
						{
							data: data,
							innerRadius: 25,
							outerRadius: 50,
							paddingAngle: 0,
							cornerRadius: 0,
							startAngle: 0,
							endAngle: 360,
							// cx: 150,
							// cy: 150,
						},
					]}
					width={200}
					// height={200}
				/>
			</div>
		);
	else {
		return (
			<div
				className="preview-card"
				style={{ gap: '0px' }}
			>
				<Loader />
			</div>
		);
	}
};
