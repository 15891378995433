import AllSessionBox from '../../components/session/AllSessionBox';
import BookSessionBox from '../../components/session/BookSessionBox';
import SessionTopBox from '../../components/session/SessionTopBox';

const SessionHome = () => {
	return (
		<div className="session-home-wrapper">
			<SessionTopBox />
			<BookSessionBox />
			<AllSessionBox />
		</div>
	);
};

export default SessionHome;
