import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class NotesService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchAllNotes() {
		const url = `${this.endpoint}/notes/rootLevel`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data.itemtosend;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchRootLevels() {
		const url = `${this.endpoint}/notes/fetchroot`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data.allNotes;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async createNewFolder(body: any) {
		const url = `${this.endpoint}/notes/newfolder`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data.message;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async putFilesinFolder(body: any) {
		const url = `${this.endpoint}/notes/addfilestofolder`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data.message;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateContent(body: any) {
		const url = `${this.endpoint}/notes/updatecontent`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data.message;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
