import {
	Dialog,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { DayPicker } from 'react-day-picker';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const PaymentModal = ({
	showPaymentModal,
	handlePaymentClose,
	handleSelectedMonthPayment,
	paymentData,
	getPaymentInvoice,
}: any) => {
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorInvoice } = MentorState;

	const [defaultMonth, setDefaultMonth] = useState<any>();
	const [defaultYear, setDefaultYear] = useState<any>();
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);

	useEffect(() => {
		if (!showPaymentModal) setStartDate(null);
		else handleDefaultMonth();
	}, [showPaymentModal]);

	useEffect(() => {
		if (showPaymentModal && startDate !== null)
			handleSelectedMonthPayment(startDate, endDate);
	}, [showPaymentModal, startDate]);

	useEffect(() => {
		if (startDate === null && endDate === null)
			handleDefaultMonth();
		if (
			mentorInvoice === null &&
			startDate !== null &&
			endDate !== null
		)
			getPaymentInvoice(startDate, endDate);
	}, [startDate, endDate]);

	const handleDate = (date: Date) => {
		const startingMonth = String(
			date.getMonth() + 1
		).padStart(2, '0');
		const endingMonth = String(
			startingMonth === '12' ? 1 : +startingMonth + 1
		).padStart(2, '0');
		const year = date.getFullYear();
		const startDate = `${year}-${startingMonth}-28 00:00:00.000`;
		setStartDate(startDate);
		const endDate = `${
			startingMonth === '12' ? year + 1 : year
		}-${endingMonth}-27 00:00:00.000`;
		setEndDate(endDate);
		return { startDate, endDate };
	};

	const handleDefaultMonth = () => {
		const date = new Date(Date.now());
		const day = date.getDate();

		let defaultMonth;
		let defaultYear;

		if (day >= 28) {
			defaultMonth = date.getMonth();
			defaultYear = date.getFullYear();
		} else {
			defaultMonth =
				date.getMonth() - 1 === -1
					? 11
					: date.getMonth() - 1;
			defaultYear =
				date.getMonth() - 1 === -1
					? date.getFullYear() - 1
					: date.getFullYear();
		}
		handleDate(new Date(defaultYear, defaultMonth));
		setDefaultMonth(defaultMonth);
		setDefaultYear(defaultYear);
	};

	return (
		<Dialog
			open={showPaymentModal}
			onClose={handlePaymentClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div style={{ minWidth: '600px' }}>
				<DialogTitle className="payment-details-title">
					PAYMENT DETAILS
				</DialogTitle>
				<div className="select-month-container">
					<div className="month-picker">
						<DayPicker
							hideHead={true}
							hidden
							defaultMonth={
								new Date(defaultYear, defaultMonth)
							}
							onMonthChange={(date: Date) =>
								handleDate(date)
							}
							numberOfMonths={2}
						/>
					</div>
					{startDate !== null && (
						<button
							className="custom-months-payment-details-btn"
							onClick={() =>
								handleSelectedMonthPayment(
									startDate,
									endDate
								)
							}
						>
							Get Details
						</button>
					)}
				</div>
				<p className="to-from-dates">
					<>
						{moment(new Date(startDate)).format(
							'DD/MM/YYYY'
						)}{' '}
						-{' '}
						{moment(new Date(endDate)).format('DD/MM/YYYY')}
					</>
				</p>
				{paymentData && (
					<TableContainer
						component={Paper}
						style={{
							maxWidth: '550px',
							tableLayout: 'fixed',
							margin: '1em',
						}}
					>
						<Table
							size="small"
							aria-label="a dense table"
						>
							<TableHead>
								<TableRow>
									<TableCell align="center"></TableCell>
									<TableCell align="center">
										Sessions
									</TableCell>
									<TableCell align="center">
										Amount
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{paymentData.map((row: any) => (
									<TableRow
										key={row.name}
										// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell
											component="th"
											scope="row"
											style={{
												fontSize: '16px',
												fontWeight: '500',
											}}
										>
											{row.name}
										</TableCell>
										<TableCell
											align="center"
											className="payment-details-cell"
										>
											{row.count}{' '}
										</TableCell>
										<TableCell
											align="center"
											className="payment-details-cell"
										>
											{row.amount}{' '}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				)}
			</div>
		</Dialog>
	);
};

export default PaymentModal;
