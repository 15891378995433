import React from 'react';
import Banner from './Banner';
import MarkDown from './MarkDown';
import { useState, useEffect } from 'react';
import { fetchQuestions } from '../../redux/contest/contestSlice';
import { useSelector, useDispatch } from 'react-redux';
import {
	FiArrowRight,
	FiTarget,
	FiZap,
} from 'react-icons/fi';
import queryString from 'query-string';
import { IoRibbonOutline } from 'react-icons/io5';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { AppDispatch } from '../../redux/store';
import { useLocation } from 'react-router-dom';

interface ContestProps {
	contestData: any;
}

const MissedContest = ({ contestData }: ContestProps) => {
	const dispatch: AppDispatch = useDispatch();
	const location = useLocation();
	const contestId = queryString.parse(
		location.search
	).contestId;

	const contestInfoState = useSelector(
		(state: any) => state.contest
	);

	const {
		codingQuestions,
		allQuestions,
		message,
		conceptualQuestions,
		webDevQuestions,
		totalCodingQuestions,
		totalWebDevQuestions,
		totalConceptualQuestions,
	} = contestInfoState;

	useEffect(() => {
		dispatch(fetchQuestions(contestId));
	}, [contestData]);

	const style = {
		width: '100%' as const,
		textAlign: 'center' as const,
		marginLeft: '40%' as const,
		marginBottom: '20px' as const,
	};

	const getContestMarks = (questionArray: []) => {
		let totalMarks = 0;
		let maxTotalMarks = 0;
		questionArray.map((question: any) => {
			if (questionArray.length > 0) {
				question.scoreInContest
					? (totalMarks += Number(question.scoreInContest))
					: (totalMarks += Number(question.score));
				maxTotalMarks += Number(question.maxMarks);
			} else {
				totalMarks = totalMarks + 0;
			}
		});
		return { totalMarks, maxTotalMarks };
	};

	const colorDIFFMap = (diff: string): any => {
		const color = [
			{
				color: '#EF574C',
				bg: '#FDE6E4',
			},
			{
				color: '#F0B416',
				bg: 'rgba(253, 242, 214, 0.5)',
			},
			{
				color: '#12B76A',
				bg: '#E3FFED',
			},
		];
		if (diff === 'HARD') {
			return color[0];
		} else if (diff === 'MEDIUM') {
			return color[1];
		} else if (diff === 'EASY') {
			return color[2];
		}
	};

	useEffect(() => {
		localStorage.setItem(
			'backToContestURL',
			window.location.href
		);
	}, []);

	return contestData !== null ? (
		<div>
			<Banner
				msg={{
					head: 'Contest Ended',
					para: 'You have missed this contest',
				}}
				duration={contestData.duration}
				startTime={contestData.startTime}
				endTime={contestData.endTime}
				active={false}
			/>

			{message ? (
				<h4 style={style}>
					Questions will be available below a day after the
					contest is over.
				</h4>
			) : (
				<div className="contest-questions">
					<h4 className={'contest-score'}>
						Contest Marks :{' '}
						{getContestMarks(codingQuestions).totalMarks +
							getContestMarks(conceptualQuestions)
								.totalMarks +
							getContestMarks(webDevQuestions).totalMarks}
						<BsFillInfoCircleFill />
					</h4>

					<div
						className={'bifurcation-marks'}
						id={'bifurcation-marks'}
					>
						<h4>
							Problem Solving :{' '}
							{getContestMarks(codingQuestions).totalMarks}/
							{
								getContestMarks(codingQuestions)
									.maxTotalMarks
							}
						</h4>
						<h4>
							Conceptual Questions :{' '}
							{
								getContestMarks(conceptualQuestions)
									.totalMarks
							}
							/
							{
								getContestMarks(conceptualQuestions)
									.maxTotalMarks
							}
						</h4>
						{webDevQuestions.length > 0 ? (
							<h4>
								WebDev :
								{
									getContestMarks(webDevQuestions)
										.totalMarks
								}
								/
								{
									getContestMarks(webDevQuestions)
										.maxTotalMarks
								}
							</h4>
						) : null}
					</div>

					{totalConceptualQuestions.length > 0 ||
					totalCodingQuestions.length > 0 ||
					totalWebDevQuestions.length > 0 ? (
						<div className={'questions-container'}>
							<h3>
								All questions that were present in this
								contest
							</h3>
							{totalCodingQuestions.length > 0 ? (
								<>
									<p className={'question-category'}>
										Problem Solving
									</p>
									<ul className="question-list">
										{totalCodingQuestions.map(
											(question: any) => {
												return (
													<li
														className="codingQuestion"
														key={question.questionId}
													>
														<div
															className={
																'question-content-info'
															}
														>
															<div className="questionName">
																<p className="questionNameText">
																	{question.questionTitle}
																</p>
															</div>
															<div className="questionPoints">
																<p>
																	<FiZap />
																	<span
																		className={'difficulty'}
																		style={{
																			color: colorDIFFMap(
																				question.difficulty
																			).color,
																			backgroundColor:
																				colorDIFFMap(
																					question.difficulty
																				).bg,
																		}}
																	>
																		{question.difficulty}
																	</span>
																</p>
																<p className="questionPointsText">
																	<FiTarget /> Max Marks :{' '}
																	<span>
																		{question.maxMarks}{' '}
																		Points
																	</span>
																</p>
																<p className="questionPointsText">
																	<IoRibbonOutline /> Your
																	Score:{' '}
																	<span>
																		{question.score} Points
																	</span>
																</p>
															</div>
														</div>

														{!question.isSolved ? (
															<button
																className="questionButton"
																onClick={() =>
																	window.open(
																		`${window.origin}/idle?question=${question.questionId}`,
																		'_blank'
																	)
																}
															>
																Start Question{' '}
																<FiArrowRight />
															</button>
														) : (
															<button
																className="questionButton solvedBtn"
																onClick={() =>
																	window.open(
																		`${window.origin}/idle?question=${question.questionId}`,
																		'_blank'
																	)
																}
															>
																Solved <FiArrowRight />
															</button>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : null}

							{totalWebDevQuestions.length > 0 ? (
								<>
									<p>WebDev Questions</p>
									<ul className="question-list">
										{totalWebDevQuestions.map(
											(question: any) => {
												return (
													<li
														className="codingQuestion"
														key={question.questionId}
													>
														<div
															className={
																'question-content-info'
															}
														>
															<div className="questionName">
																<p className="questionNameText">
																	{question.questionTitle}
																</p>
															</div>
															<div className="questionPoints">
																<p>
																	<FiZap />
																	<span
																		className={'difficulty'}
																		style={{
																			color: colorDIFFMap(
																				question.difficulty
																			).color,
																			backgroundColor:
																				colorDIFFMap(
																					question.difficulty
																				).bg,
																		}}
																	>
																		{question.difficulty}
																	</span>
																</p>
																<p className="questionPointsText">
																	<FiTarget /> Max Marks :{' '}
																	<span>
																		{question.maxMarks}
																	</span>
																</p>
																<p className="questionPointsText">
																	<IoRibbonOutline /> Your
																	Score:{' '}
																	<span>
																		{question.score}
																	</span>
																</p>
															</div>
														</div>

														{!question.isSolved ? (
															<button
																className="questionButton"
																onClick={() =>
																	window.open(
																		`${window.origin}/web-idle?question=${question.questionId}`,
																		'_blank'
																	)
																}
															>
																Start Question{' '}
																<FiArrowRight />
															</button>
														) : (
															<button
																className="questionButton solvedBtn"
																onClick={() =>
																	window.open(
																		`${window.origin}/web-idle?question=${question.questionId}`,
																		'_blank'
																	)
																}
															>
																Solved <FiArrowRight />
															</button>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : null}

							{totalConceptualQuestions.length > 0 ? (
								<>
									<p>Conceptual Questions</p>
									<ul className="question-list">
										{totalConceptualQuestions.map(
											(question: any) => {
												return (
													<li
														className="codingQuestion"
														key={question.questionId}
													>
														<div
															className={
																'question-content-info'
															}
														>
															<div className="questionName">
																<p className="questionNameText">
																	{question.questionTitle}
																</p>
															</div>
															<div className="questionPoints">
																<p>
																	<FiZap />
																	<span
																		className={'difficulty'}
																		style={{
																			color: colorDIFFMap(
																				question.difficulty
																			).color,
																			backgroundColor:
																				colorDIFFMap(
																					question.difficulty
																				).bg,
																		}}
																	>
																		{question.difficulty}
																	</span>
																</p>
																<p className="questionPointsText">
																	<FiTarget /> Max Marks :{' '}
																	<span>
																		{question.maxMarks}
																	</span>
																</p>
																<p className="questionPointsText">
																	<IoRibbonOutline /> Your
																	Score:{' '}
																	<span>
																		{question.score}
																	</span>
																</p>
															</div>
														</div>

														{!question.isSolved ? (
															<button
																className="questionButton"
																onClick={() =>
																	window.open(
																		`${window.origin}/gym-aptitude?questionId=${question.questionId}&isFromContest=true`,
																		'_blank'
																	)
																}
															>
																Start Question{' '}
																<FiArrowRight />
															</button>
														) : (
															<button
																className="questionButton solvedBtn"
																onClick={() =>
																	window.open(
																		`${window.origin}/gym-aptitude?questionId=${question.questionId}&isFromContest=true`,
																		'_blank'
																	)
																}
															>
																Solved <FiArrowRight />
															</button>
														)}
													</li>
												);
											}
										)}
									</ul>
								</>
							) : null}
						</div>
					) : null}
				</div>

				// <div className="questions">
				// 	{allQuestions && codingQuestions ? (
				// 		<div className="assigned-questions">
				// 			<h3>Assigned Questions</h3>
				// 			<div className="question-list">
				// 				{codingQuestions.map((question: any) => {
				// 					return (
				// 						<li
				// 							className="codingQuestion"
				// 							key={question.questionId}
				// 						>
				// 							<div className="questionName">
				// 								<p className="questionNameText">
				// 									{question.questionTitle}
				// 								</p>
				// 							</div>
				// 							<div className="questionPoints">
				// 								<p className="questionPointsText">
				// 									Max Marks :{' '}
				// 									{question.maxMarks}{' '}
				// 								</p>
				// 								{!question.isSolved ? (
				// 									<button
				// 										className="questionButton"
				// 										onClick={() =>
				// 											window.open(
				// 												`${window.origin}/idle?question=${question.questionId}`,
				// 												'_blank'
				// 											)
				// 										}
				// 									>
				// 										Start Question{' '}
				// 										<FiArrowRight />
				// 									</button>
				// 								) : (
				// 									<button
				// 										className="questionButton solvedBtn"
				// 										onClick={() =>
				// 											window.open(
				// 												`${window.origin}/idle?question=${question.questionId}`,
				// 												'_blank'
				// 											)
				// 										}
				// 									>
				// 										Solved <FiArrowRight />
				// 									</button>
				// 								)}
				// 							</div>
				// 						</li>
				// 					);
				// 				})}
				// 			</div>
				// 			{allQuestions ? (
				// 				<div className="question-list">
				// 					<h3>All Questions</h3>
				// 					{allQuestions.map((question: any) => {
				// 						return (
				// 							<li
				// 								className="codingQuestion"
				// 								key={question.questionId}
				// 							>
				// 								<div className="questionName">
				// 									<p className="questionNameText">
				// 										{question.questionTitle}
				// 									</p>
				// 								</div>
				// 								<div className="questionPoints">
				// 									<p className="questionPointsText">
				// 										Max Marks :{' '}
				// 										{question.maxMarks}{' '}
				// 									</p>
				// 									{!question.isSolved ? (
				// 										<button
				// 											className="questionButton"
				// 											onClick={() =>
				// 												window.open(
				// 													`${window.origin}/idle?question=${question.questionId}`,
				// 													'_blank'
				// 												)
				// 											}
				// 										>
				// 											Start Question{' '}
				// 											<FiArrowRight />
				// 										</button>
				// 									) : (
				// 										<button
				// 											className="questionButton solvedBtn"
				// 											onClick={() =>
				// 												window.open(
				// 													`${window.origin}/idle?question=${question.questionId}`,
				// 													'_blank'
				// 												)
				// 											}
				// 										>
				// 											Solved{' '}
				// 											<FiArrowRight />
				// 										</button>
				// 									)}
				// 								</div>
				// 							</li>
				// 						);
				// 					})}
				// 				</div>
				// 			) : (
				// 				<h1>Questions not available yet </h1>
				// 			)}
				// 		</div>
				// 	) : codingQuestions ? (
				// 		<div className="question-list">
				// 			<h3>Contest Questions</h3>
				// 			{codingQuestions.map((question: any) => {
				// 				return (
				// 					<li
				// 						className="codingQuestion"
				// 						key={question.questionId}
				// 					>
				// 						<div className="questionName">
				// 							<p className="questionNameText">
				// 								{question.questionTitle}
				// 							</p>
				// 						</div>
				// 						<div className="questionPoints">
				// 							<p className="questionPointsText">
				// 								Max Marks : {question.maxMarks}{' '}
				// 							</p>
				// 							{!question.isSolved ? (
				// 								<button
				// 									className="questionButton"
				// 									onClick={() =>
				// 										window.open(
				// 											`${window.origin}/idle?question=${question.questionId}`,
				// 											'_blank'
				// 										)
				// 									}
				// 								>
				// 									Start Question{' '}
				// 									<FiArrowRight />
				// 								</button>
				// 							) : (
				// 								<button
				// 									className="questionButton solvedBtn"
				// 									onClick={() =>
				// 										window.open(
				// 											`${window.origin}/idle?question=${question.questionId}`,
				// 											'_blank'
				// 										)
				// 									}
				// 								>
				// 									Solved <FiArrowRight />
				// 								</button>
				// 							)}
				// 						</div>
				// 					</li>
				// 				);
				// 			})}
				// 		</div>
				// 	) : (
				// 		<h2>Questions not available Yet.</h2>
				// 	)}
				// </div>
			)}
		</div>
	) : null;
};

export default MissedContest;
