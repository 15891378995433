import { FC } from 'react';
import IntegerQuestion from './option/IntegerQuestion';
import MultiSelectMCQ from './option/MultiSelectMCQ';
import SingleSelectMCQ from './option/SingleSelectMCQ';
import SubjectiveQuestion from './option/SubjectiveQuestion';

type TRenderProps = {
	options?: Array<string>;
	saveQuestion: (answerArray: Array<string>) => void;
	correctAnswer?: Array<string>;
	alreadySubmitted: boolean;
	explanation?: string;
	answer?: Array<string>;
	questionType?: string;
};

const RenderOptions: FC<TRenderProps> = (props) => {
	const {
		options,
		saveQuestion,
		correctAnswer,
		alreadySubmitted,
		explanation,
		answer,
		questionType,
	} = props;

	return (
		<div>
			{questionType === 'SINGLEMCQ' ? (
				<SingleSelectMCQ
					options={options!}
					onSaveQuestion={saveQuestion}
					response={answer}
					correct={correctAnswer}
					submitted={alreadySubmitted}
					explanation={explanation}
				/>
			) : null}
			{questionType === 'INT' ? (
				<IntegerQuestion
					onSaveQuestion={saveQuestion}
					response={answer}
					correct={correctAnswer}
					submitted={alreadySubmitted}
					explanation={explanation}
				/>
			) : null}
			{questionType === 'TEXT' ? (
				<SubjectiveQuestion
					onSaveQuestion={saveQuestion}
					response={answer}
					correct={correctAnswer}
					submitted={alreadySubmitted}
					explanation={explanation}
				/>
			) : null}
			{questionType === 'MULTIMCQ' ? (
				<MultiSelectMCQ
					options={options!}
					onSaveQuestion={saveQuestion}
					response={answer}
					correct={correctAnswer}
					submitted={alreadySubmitted}
					explanation={explanation}
				/>
			) : null}
		</div>
	);
};

export default RenderOptions;
