import { encryptData } from '@acciojob/event-analytics';
import { Box, Tab, Tabs } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isMainCourseUser } from '../../utils/events';

interface PanelProps {
	children: ReactElement<
		any,
		string | React.JSXElementConstructor<any>
	>;
	index: number;
	value: number;
}

function Panel(props: PanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>{children}</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

interface TabPanelPropElement {
	title: string | ReactElement;
	element: ReactElement;
	show?: boolean;
	content?: ReactElement<
		any,
		string | React.JSXElementConstructor<any>
	> | null;
	disabled?: boolean;
}

interface TabPanelProps {
	tabData: TabPanelPropElement[];
	defaultValue?: number;
	panelBoxClass?: string;
	tabBoxClass?: string;
	onTabChange?: (tabIndex: number) => void;
	rightComponent?: (tabIndex: number) => React.ReactNode;
	rightComponentClass?: string;
	defaultIndexToView?: number;
}

const TabPanel = ({
	tabData,
	defaultValue,
	panelBoxClass,
	tabBoxClass,
	onTabChange,
	rightComponent,
	rightComponentClass,
	defaultIndexToView,
}: TabPanelProps) => {
	const moduleState = useSelector(
		(state: any) => state.module
	);

	const { currentModuleId } = moduleState;

	const [value, setValue] = useState<number>(
		defaultValue || 0
	);

	const handleChange = (e: any, newValue: number) => {
		if (newValue === value) return;
		setValue(newValue);
		if (onTabChange) {
			onTabChange(newValue);
		}
	};

	useEffect(() => {
		if (defaultIndexToView !== undefined) {
			setValue(defaultIndexToView);
		}
	}, [defaultIndexToView]);

	useEffect(() => {
		if (defaultValue !== undefined) {
			setValue(defaultValue);
		}
	}, [defaultValue]);

	const isModuleTab = (
		title:
			| string
			| ReactElement<
					any,
					string | React.JSXElementConstructor<any>
			  >
	): boolean => {
		switch (title) {
			case 'Assignment':
				return true;
			case 'Lecture':
				return true;
			default:
				return false;
		}
	};

	const getEventId = (
		title:
			| string
			| ReactElement<
					any,
					string | React.JSXElementConstructor<any>
			  >
	): string => {
		switch (title) {
			case 'Assignment':
				return 'd4184110-57b4-426c-a7e3-93bd40030ca3';
			case 'Lecture':
				return 'c80b572e-db2c-4dfe-b03b-4dd3102f5806';
			case 'Live':
				return '53bb3d63-3dd3-473d-b635-21703ea0981b';
			case 'Upcoming':
				return '6f7670b4-5160-4660-98f0-2363ea0d0833';
			case 'Past':
				return '626b4977-37ea-4efd-a117-1459df7218f3';
			default:
				return '';
		}
	};

	const filteredTabData = tabData.filter(
		(data) => data.show
	);

	// const goToTab = (tabIndex: number) => {
	// 	setValue(tabIndex);
	// 	if (onTabChange) {
	// 		onTabChange(tabIndex);
	// 	}
	// };

	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{ borderBottom: 1, borderColor: 'divider' }}
				className={`tab-panel-container ${tabBoxClass}`}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="acciojob-tab-panel"
					variant="scrollable"
				>
					{filteredTabData.map(
						(elm: TabPanelPropElement, index: number) =>
							elm.show ? (
								<Tab
									sx={{
										fontWeight: 'bold',
									}}
									key={index}
									className="tab-option"
									label={elm.title}
									// onClick={() => goToTab(index)}
									{...a11yProps(index)}
									event-analytics={
										isMainCourseUser()
											? getEventId(elm.title)
											: ''
									}
									data-config={
										isModuleTab(elm.title)
											? encryptData(
													JSON.stringify({
														name: currentModuleId,
													})
											  )
											: ''
									}
									disabled={elm.disabled}
								/>
							) : null
					)}
				</Tabs>

				{rightComponent ? (
					<div
						className={`tab-panel-right-component ${rightComponentClass}`}
					>
						{rightComponent(value)}
					</div>
				) : null}
			</Box>
			<Box className={panelBoxClass || ''}>
				{filteredTabData.map(
					(elm: TabPanelPropElement, index: number) =>
						elm.show ? (
							<Panel
								key={index}
								value={value}
								index={index}
								children={elm.element}
							/>
						) : null
				)}
				{filteredTabData.length === 0 ? (
					<div
						className="not-tab-available"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							height: '100%',
							width: '100%',
						}}
					>
						<h3>No data available</h3>
					</div>
				) : null}
			</Box>
		</Box>
	);
};

export default TabPanel;
