import {
	combineReducers,
	configureStore,
} from '@reduxjs/toolkit';

import { useDispatch, useSelector } from 'react-redux';
import {
	persistReducer,
	persistStore,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
	createStateSyncMiddleware,
	initStateWithPrevTab,
} from 'redux-state-sync';
import featureControllerReducer from './../featureController/featureControllerSlice';
import aiStudentAssessmentReducer from './aiStudentAssessment/aiStudentAssessment.slice';
import allFeedbackModalReducer from './allFeedbackModal/allFeedbackModal.slice';
import { contactBMReducer } from './contactBm/contactBM.slice';
import contestReducer from './contest/contestSlice';
import courseReducer from './course/courseSlice';
import editorReducer from './editor/editorSlice';
import fileUploadDownloadSliceReducer from './fileUploadDownloadSlice/fileUploadDownloadSlice';
import { GymReducers } from './gym/gym.slice';
import jobPortalReducer from './jobportal/jobPortalSlice';
import jupyterNoteBookReducer from './jupyterNoteBookSlice/jupyterNoteBookSlice';
import mentorReducer from './mentorSlice/mentorSlice';
import moduleReducer from './modules/moduleSlice';
import onevoneReducer from './onevone/onevoneSlice';
import { PopupReducers } from './popups/popup.slice';
import { profileReducer } from './profile/profile.slice';
import projectsReducer from './projects/projectSlice';
import sessionReducer from './session/sessionSlice';
import { SoftskillsReducers } from './softskills/softskills.slice';
import userReducer from './user/userSlice';
import webDevReducer from './webDevQuestion/webDevQuestionSlice';

const rootReducer = combineReducers({
	user: userReducer,
	editor: editorReducer,
	gym: GymReducers,
	contest: contestReducer,
	session: sessionReducer,
	module: moduleReducer,
	course: courseReducer,
	onevone: onevoneReducer,
	jobPortal: jobPortalReducer,
	projects: projectsReducer,
	fileUploadDownload: fileUploadDownloadSliceReducer,
	featureController: featureControllerReducer,
	webDevQuestion: webDevReducer,
	mentor: mentorReducer,
	jupyterNoteBook: jupyterNoteBookReducer,
	softskills: SoftskillsReducers,
	popup: PopupReducers,
	profile: profileReducer,
	aiStudentAssessment: aiStudentAssessmentReducer,
	allFeedbackModal: allFeedbackModalReducer,
	contactBM: contactBMReducer,
});

/*const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['softskills'], // reducer key in root reducer
};

const persistedReducer = persistReducer(
	persistConfig,
	rootReducer
);*/

const storeSync = configureStore({
	reducer: {
		user: userReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			createStateSyncMiddleware()
		),
});

const store = configureStore({
	reducer: rootReducer,
});

// export const persistor = persistStore(store);

initStateWithPrevTab(storeSync);
export default store;

// For better usage with typescript
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch =
	useDispatch;
export const useAppSelector = <Selected>(
	selector: (state: RootState) => Selected
) => useSelector(selector);
