import {
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import React from 'react';
import { CustomQuestionsData } from '../../../apis/JobPortalService';
import { FormDataType } from '../../jobPortal/ApplyPopup';

const RadioQuestion = ({
	id,
	questionString,
	answerOptions,
	sno,
	formData,
	updateFormData,
}: CustomQuestionsData & {
	sno: number;
	formData: FormDataType;
	updateFormData: (id: string, value: string) => void;
}) => {
	return (
		<div className="custom-question">
			<p>{sno + '. ' + questionString}</p>
			<div className="radio-field">
				<RadioGroup
					row
					value={formData[id]}
					onChange={(e) => {
						updateFormData(id, e.target.value);
					}}
				>
					{answerOptions.map((option, idx) => (
						<FormControlLabel
							key={idx}
							value={option}
							control={<Radio required />}
							label={option}
						/>
					))}
				</RadioGroup>
			</div>
		</div>
	);
};

export default RadioQuestion;
