export const month = [
	'Jan',
	'Feb',
	'March',
	'April',
	'May',
	'June',
	'July',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];
export const days = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thursday',
	'Friday',
	'Saturday',
];
export const formatDate = (date: Date) => {
	const dt = new Date(date).getDate();
	const mnth = new Date(date).getMonth();
	return dt + ' ' + month[mnth];
};
