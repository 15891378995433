import { SearchableDropdownOptionType } from '../../components/shared/SearchableDropdown';
import {
	AttemptStatusEnum,
	DifficultyTypeEnum,
} from '../../redux/gym/type';

export const QuestionDifficultyOptions: SearchableDropdownOptionType[] =
	[
		{
			value: DifficultyTypeEnum.EASY,
			label: 'Easy',
		},
		{
			value: DifficultyTypeEnum.MEDIUM,
			label: 'Medium',
		},
		{
			value: DifficultyTypeEnum.HARD,
			label: 'Hard',
		},
	];

export const QuestionStatusOptions: SearchableDropdownOptionType[] =
	[
		{
			value: AttemptStatusEnum.ATTEMPTED,
			label: 'Attempted',
		},
		{
			value: AttemptStatusEnum.UNATTEMPTED,
			label: 'Unattempted',
		},
		{
			value: AttemptStatusEnum.SOLVED,
			label: 'Solved',
		},
	];

export const QuestionTypeOptions: SearchableDropdownOptionType[] =
	[
		{
			value: 'modular',
			label: 'Assignment',
		},
		{
			value: 'gym',
			label: 'Practise Questions',
		},
	];

export const GymHeaderMap: Record<string, string> = {
	coding: 'Coding',
	webdev: 'Web Development',
	conceptual: 'Conceptual Questions',
};
