import moment from 'moment';
import React, { useState } from 'react';
import { AiOutlineMessage } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAllAvailableSlots,
	setStateForRescheduleEnableTab,
	setStateMentorId,
	setStateRescheduleId,
	setStateSelectedDateTimeForBooking,
	setStateSessionCategory,
	setStateSessionTypeId,
} from '../../redux/session/sessionSlice';
import { getSessionDetailsOfUserFromSessionType } from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import AccioButton from '../elements/Button';
import RightAlignModal from '../shared/RightAlignModal';
import SessionModalBox from './SessionModalBox';

interface BookSessionCardProps {
	isMentorship: boolean;
	lastAttemptDate: string;
	lastScore: string;
	sessionType: string;
	sessionTypeId: string;
	studentFeedbackLink: string;
	feedbackStatus: string;
	category: string;
}

interface PrevMentors {
	[key: string]: {
		[key: string]: string;
	};
}

interface MentorFeedback {
	createdAt: string;
	[key: string]: string | MentorQuestionFeedback | null;
}

interface MentorQuestionAnswer {
	[key: string]: string;
}

interface MentorQuestionFeedback {
	[key: string]: string | MentorQuestionAnswer | null;
}

interface SessionDetail {
	sessionTag: string;
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	totalScore: string | number;
	studentFeedbackLink: string;
	isAllowedToJoin: boolean;
	mentorFeedback: MentorFeedback | null;
	recordings: string[];
	sessionId: string;
	sessionTypeId: string;
	mentorName: string;
	mentorPhone: string;
}

interface SessionData {
	modalData: {
		allSessions: SessionDetail[];
		isEligibleToBookSession: boolean;
		prevMentors: PrevMentors;
		isMentorship: boolean;
		sessionTypeName: string;
	};
	typeBtn: string;
}

const BookSessionCard: React.FC<BookSessionCardProps> = ({
	isMentorship,
	lastAttemptDate,
	lastScore,
	sessionType,
	sessionTypeId,
	category,
	studentFeedbackLink,
	feedbackStatus,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [isOpened, setIsOpened] = useState<boolean>(false);

	const SessionState = useSelector(
		(state: any) => state.session
	);

	const { allSessionDetails } = SessionState;

	const handleOpen = () => {
		setIsOpened(true);
		setOpen(true);
		console.log('open abc aaaa');
	};

	const getColor = (score: number) => {
		if (score < 6) {
			return '#F63D68';
		} else if (score < 8) {
			return '#EAAA08';
		} else {
			return '#12B76A';
		}
	};

	const [typeBtn, setTypeBtn] = useState('');
	const dispatch = useDispatch<AppDispatch>();

	const ModalDataGenerator = (
		sessionTypeId: string,
		category: string
	) => {
		handleOpen();

		dispatch(
			getSessionDetailsOfUserFromSessionType(sessionTypeId)
		);

		dispatch(setStateSessionTypeId(sessionTypeId));
		dispatch(setStateSessionCategory(category));
	};

	const handleClose = () => {
		setIsOpened(false);
		setTimeout(() => {
			dispatch(setStateMentorId(null));
			dispatch(setAllAvailableSlots([]));
			dispatch(setStateSessionTypeId(null));
			dispatch(setStateForRescheduleEnableTab(false));
			dispatch(setStateSelectedDateTimeForBooking(null));
			dispatch(setStateRescheduleId(null));
			dispatch(setStateForRescheduleEnableTab(null));
			setOpen(false);
		}, 1000);
	};

	return (
		<div className="book-session-wrapper">
			<div className="book-session-top-small-border"></div>
			<div className="book-session-content-wrapper">
				<p className="book-session-title">{sessionType}</p>
				<div className="book-session-content">
					<div className="last-session-info">
						<span className="last-session-text">
							Last session :{' '}
						</span>
						<span className="last-session-data">
							{lastAttemptDate !== 'No Sessions Taken'
								? moment(lastAttemptDate).format('D MMM YY')
								: lastAttemptDate}
						</span>
					</div>
					{!isMentorship ? (
						<div className="last-session-remark">
							<span className="last-session-text">
								Remarks:
							</span>
							{feedbackStatus === 'Fill Feedback' ? (
								<AccioButton
									title="Fill Feedback"
									variant="outline"
									disabled={
										studentFeedbackLink?.length > 0
											? false
											: true
									}
									icon={
										<AiOutlineMessage
											style={{
												fontSize: '14px',
												marginRight: '-3px',
												marginTop: '-2px',
											}}
										/>
									}
									action={() =>
										window.open(
											studentFeedbackLink,
											'_blank'
										)
									}
									customClass="btn booking-session-btn-outline"
								/>
							) : feedbackStatus === 'Awaiting Feedback' ? (
								<span className="last-session-data">
									{feedbackStatus}
								</span>
							) : feedbackStatus === 'Filled' ? (
								<span
									className="last-session-data"
									style={{
										color: getColor(
											lastScore !== '-1'
												? Number(lastScore)
												: 0
										),
									}}
								>
									{lastScore}
								</span>
							) : feedbackStatus ===
							  'No Feedback Available' ? (
								<span className="last-session-data">
									{feedbackStatus}
								</span>
							) : null}
						</div>
					) : isMentorship &&
					  feedbackStatus === 'Fill Feedback' ? (
						<div className="mentor-feedback-mentorship-session">
							<span className="last-session-text">
								Remarks:
							</span>
							<AccioButton
								title="Fill Feedback"
								variant="outline"
								disabled={
									studentFeedbackLink?.length > 0
										? false
										: true
								}
								icon={
									<AiOutlineMessage
										style={{
											fontSize: '14px',
											marginRight: '-3px',
											marginTop: '-2px',
										}}
									/>
								}
								action={() =>
									window.open(studentFeedbackLink, '_blank')
								}
								customClass="btn booking-session-btn-outline"
							/>
						</div>
					) : null}
				</div>
				<div className="book-session-buttons">
					<AccioButton
						title="View details"
						variant="outline"
						icon={''}
						customClass="btn booking-session-btn-outline"
						action={() => {
							ModalDataGenerator(sessionTypeId, category);
							setTypeBtn('VIEW DETAILS');
						}}
					/>
					<AccioButton
						title="Book Session"
						variant="primary"
						icon={''}
						customClass="btn booking-session-btn-primary"
						action={() => {
							ModalDataGenerator(sessionTypeId, category);
							setTypeBtn('BOOK SESSION');
						}}
					/>
				</div>
			</div>
			<RightAlignModal
				children={
					<SessionModalBox
						modalData={allSessionDetails}
						typeBtn={typeBtn}
						setOpen={setOpen}
					/>
				}
				handleClose={handleClose}
				handleOpen={handleOpen}
				isOpened={isOpened}
				setIsOpened={setIsOpened}
				open={open}
				setOpen={setOpen}
			/>
		</div>
	);
};

export default BookSessionCard;
