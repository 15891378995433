import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import Dropdown, {
	SearchableDropdownOptionType,
} from '../shared/SearchableDropdown';

import DisplaySessionDetails from './DisplaySessionDetails';
import {
	PrevMentor,
	PrevMentors,
} from './MentorshipSessionBookingBox';

interface SessionDetail {
	sessionObject: {
		sessionTag: string;
		sessionDate: string;
	};
	sessionTag: string;
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	totalScore: string | number;
	studentFeedbackLink: string;
	isAllowedToJoin: boolean;
	mentorFeedback: MentorFeedback | null;
	recordings: string[];
	sessionId: string;
	sessionTypeId: string;
	mentorName: string;
	mentorPhone: string;
}

interface MentorFeedback {
	createdAt: string;
	[key: string]: string | MentorQuestionFeedback | null;
}

interface MentorQuestionFeedback {
	[key: string]: string | MentorQuestionAnswer | null;
}

interface MentorQuestionAnswer {
	[key: string]: string;
}

interface YourSessionModalBoxInterface {
	sessionData: {
		allSessions: SessionDetail[];
		isEligibleToBookSession: boolean;
		prevMentors: PrevMentors;
		isMentorship: boolean;
		sessionTypeName: string;
		numberOfCancellationsAllowed: number;
		numberOfSessionsCancelledOrRescheduledTillNow: number;
	};
	onYourSessionTabChange: (tabIndex: number) => void;
	setOpen: (open: boolean) => void;
}

interface ModifiedSessionData {
	sessionType: string;
	sessionTypeId: string;
	sessionDate: string;
	sessionId: string;
}

interface ModifiedSessionDataObj {
	allSessions: {
		[x: string]: any;
		sessionTag: string;
		sessionTypeId: string;
	}[];
}

interface selectedOptionVal {
	label: string;
	value: string;
	status: string;
}

interface sessionDetailsPropsInterFace {
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	isAllowedToJoin: boolean;
	mentorFeedback: any;
	mentorName: string;
	mentorPhone: string;
	recordings: any[];
	sessionId: string;
	sessionObject: {
		sessionDate: string;
		sessionTag: string;
	};
	sessionTypeId: string;
	sessionTypeName: string;
	studentFeedbackLink: string;
	totalScore: number;
}

const YourSessionModalBox = ({
	sessionData,
	onYourSessionTabChange,
	setOpen,
}: YourSessionModalBoxInterface) => {
	const [sessionState, setSessionState] = useState<
		ModifiedSessionData[]
	>([]);

	const [selectedSessionIdVal, setSelectedSessionIdVal] =
		useState<string>();

	console.log('sessionData', sessionState);

	// const getSessionDefaultValue = () => {
	// 	if (sessionState.length > 0) {
	// 		const firstSession = sessionState[0];
	// 		setSelectedSessionIdVal(firstSession.sessionId);
	// 		return options[0];
	// 	}

	// 	setSelectedSessionIdVal('');
	// 	return undefined;
	// };

	// Function to convert session tag to session type
	const getSessionType = (sessionTag: string): string => {
		if (sessionTag === 'Upcoming') {
			return 'Active Session';
		} else if (sessionTag === 'Past') {
			return ' ';
		} else {
			return 'Cancelled';
		}
	};

	// Function to transform session data
	const transformSessionData = (
		data: ModifiedSessionDataObj
	): ModifiedSessionData[] => {
		console.log('data ABC', data);
		if (
			!data ||
			!data.allSessions ||
			!Array.isArray(data?.allSessions)
		) {
			return [];
		}

		console.log('data', data);

		return data.allSessions.map((session) => {
			return {
				sessionType: getSessionType(
					session?.sessionObject?.sessionTag
				),
				sessionTypeId: session.sessionTypeId,
				sessionDate: session?.sessionObject?.sessionDate,
				sessionId: session.sessionId,
			};
		});
	};

	const getSessionBySessionId = (
		sessionId: string | undefined
	): SessionDetail | null => {
		console.log({ sessionId });
		if (
			!sessionData || // Add a null check for sessionData
			!sessionData.allSessions ||
			!Array.isArray(sessionData.allSessions)
		) {
			return null;
		}

		const session = sessionData.allSessions.find(
			(session) => session.sessionId === sessionId
		);
		return session ?? null;
	};

	useEffect(() => {
		const transformedData =
			transformSessionData(sessionData);
		setSessionState(transformedData);
	}, [sessionData]);

	const options = useMemo(() => {
		const tempSessions = sessionState.map(
			(session, index) => ({
				value: index.toString(),
				label: moment(session.sessionDate).format(
					'DD MMM YYYY'
				),
				status: session.sessionType,
			})
		);

		if (tempSessions[0])
			setSelectedSessionIdVal(tempSessions[0].value);

		return tempSessions;
	}, [sessionState]);

	console.log(
		'value',
		getSessionBySessionId(selectedSessionIdVal)
	);
	console.log('selectedSessionIdVal', selectedSessionIdVal);

	const handleDropdownSelect = (
		selectedOptionIndex: unknown
	) => {
		console.log('Selected Option:', selectedOptionIndex);
		setSelectedSessionIdVal(selectedOptionIndex as string);
	};

	console.log({ selectedSessionIdVal });
	return (
		<div className="your-session-modal-box-wrapper">
			{sessionState.length > 0 ? (
				<div className="your-session-modal-box">
					<div
						className="modal-box-drop-down-wrapper"
						style={{ width: '100%' }}
					>
						<span className="session-dropdown-lbl">
							Session :
						</span>
						<div className="session-dropdown-outer">
							<Dropdown
								outerContainerStyle={{
									minWidth: '300px',
									width: '100%',
								}}
								value={selectedSessionIdVal}
								placeholder="Select session"
								options={options}
								onSelect={handleDropdownSelect}
								OptionComponent={CustomOptionComponent}
								shouldDeselectOnReClick={false}
							/>
						</div>
					</div>
					<div className="your-session-modal-box-status">
						<DisplaySessionDetails
							data={getSessionBySessionId(
								sessionState[Number(selectedSessionIdVal)]
									?.sessionId
							)}
							onYourSessionTabChange={
								onYourSessionTabChange
							}
							setOpen={setOpen}
							numberOfCancellationsAllowed={
								sessionData.numberOfCancellationsAllowed
							}
							numberOfSessionsCancelledOrRescheduledTillNow={
								sessionData.numberOfSessionsCancelledOrRescheduledTillNow
							}
						/>
					</div>
				</div>
			) : (
				<div className="no-session-found-txt">
					<p>No past sessions !</p>
				</div>
			)}
		</div>
	);
};

export default YourSessionModalBox;

const CustomOptionComponent: React.FC<
	SearchableDropdownOptionType
> = ({ label, status }) => {
	return (
		<p className="session-drop-down-item">
			{label}{' '}
			<span
				className={`session-status ${
					status === 'Active Session'
						? 'active-session'
						: status === 'Cancelled'
						? 'cancelled-session'
						: ''
				}`}
			>
				{status}
			</span>
		</p>
	);
};
