import { useEffect, useState } from 'react';
import {
	ReflexContainer,
	ReflexElement,
	ReflexSplitter,
} from 'react-reflex';
import 'react-reflex/styles.css';
// import '../../styles/file.scss';
import Box from '@mui/material/Box';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import moment from 'moment';
import { TbDownload } from 'react-icons/tb';
import { fetchFileUploadDownloadQuestionData } from '../../redux/fileUploadDownloadSlice/fileUploadDownloadSlice';
import { AppDispatch } from '../../redux/store';
import QuestionPanel from '../webdevidle/QuestionPanel';
import UploadButton from './FileUploadbutton';
const FileUploadDownloadScreen = () => {
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const questionId: string = queryString.parse(
		location.search
	).question;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const moduleId: string = queryString.parse(
		location.search
	).moduleId;
	console.log('moduleId', typeof moduleId);

	const [selectedFile, setSelectedFile] =
		useState<File | null>(null);
	const [loadingState, setLoadingState] = useState(false);
	const handleFileChange = (file: File) => {
		setSelectedFile(file);
	};
	const openQuestion = async (
		questionID: string,
		moduleId: string
	) => {
		// console.log('Type', type);
		if (questionId) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const body = {
				questionId,
				moduleId,
			};
			await dispatch(
				fetchFileUploadDownloadQuestionData(body)
			);
		}
	};

	useEffect(() => {
		openQuestion(questionId, moduleId);
	}, [questionId]);

	const { questionData, loading, isSubmitted } =
		useSelector((state: any) => state.fileUploadDownload);

	useEffect(() => {
		if (loading) {
			setLoadingState(true);
		} else {
			setLoadingState(false);
		}
	}, [loading]);
	console.log('🐱', questionData);

	useEffect(() => {
		if (isSubmitted) {
			console.log('isSubmitted', isSubmitted);
			window.location.reload();
		}
	}, [isSubmitted]);

	const [btnDisable, setBtnDisable] = useState(false);

	useEffect(() => {
		if (
			questionData &&
			questionData.isEligibileToSubmit &&
			new Date().getTime() <
				new Date(questionData.deadLine).getTime()
		) {
			setBtnDisable(false);
			console.log(
				'ghvjbknl',
				questionData.userFileSubmissions[
					questionData.userFileSubmissions.length - 1
				]
			);
		} else {
			setBtnDisable(true);
		}
	}, [questionData]);

	return (
		<div className={'file'}>
			<ReflexContainer orientation="vertical">
				<ReflexElement className="left-pane">
					<div style={{ height: '100%', overflow: 'auto' }}>
						<Box sx={{ width: '100%' }}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
								}}
							>
								{questionData !== null ? (
									<QuestionPanel
										questionData={questionData.fileQuestion}
									/>
								) : null}
							</Box>
						</Box>
						<div className="download-question-wrapper">
							<p className="download-question-text">
								Download Question Template
							</p>
							{questionData ? (
								<button
									className="download-question-button"
									onClick={() =>
										window.open(
											`${questionData.fileQuestion.templateFileUrl}`,
											'_blank'
										)
									}
								>
									<span className="icon">
										<TbDownload />
									</span>
									<span className="text">Download</span>
								</button>
							) : null}
						</div>
					</div>
				</ReflexElement>

				<ReflexSplitter style={{ width: '10px' }} />

				<ReflexElement className="right-pane">
					<p className="heading">
						<span>Submission</span>

						{questionData &&
						questionData.deadLine != null ? (
							<span className="file-uploadDownload-text">
								Dead Line:
								<span className="file-uploadDownload-date-time">
									&nbsp;
									{moment(
										questionData && questionData.deadLine
									).format('MMMM Do YYYY, h:mm a')}
								</span>
							</span>
						) : null}
					</p>

					{questionData &&
					questionData.userFileSubmissions.length === 0 ? (
						<div className="div-right-wrapper">
							<p className="div-right-text-heading">
								Upload Submission
								{btnDisable ? (
									<span className="deadline-text">
										Deadline Passed
									</span>
								) : null}
							</p>
							<p className="div-right-text-p">
								Once you are done with your assignment,
								upload it here for our mentors to evaluate
								the question and give you feedback.
							</p>

							{selectedFile && (
								<div className={`browse-file-text`}>
									<span className="span-lebel">
										Uploaded file:
									</span>
									<span className="span-filename">
										{selectedFile.name}
									</span>
									<button
										className="reset-btn"
										onClick={() => setSelectedFile(null)}
									>
										X
									</button>
								</div>
							)}

							<UploadButton
								disableToSubmit={btnDisable}
								selectedFile={selectedFile}
								onChange={handleFileChange}
								questionId={questionId}
								loadingState={loadingState}
							/>
						</div>
					) : (
						<div className="div-right-submission-details">
							<div className="div-parent-data">
								<div className="div-status">
									<p className="text-p">Submission Name</p>
									<div className="submission-details">
										{/* <span className="submission-file-name">
											{questionData &&
												questionData
													.userFileSubmissions[
													questionData
														.userFileSubmissions
														.length - 1
												].solutionFileUrl}
										</span> */}
										<button
											className="submission-file"
											onClick={() =>
												window.open(
													`${
														questionData &&
														questionData
															.userFileSubmissions[0]
															.solutionFileUrl
													}`,
													'_blank'
												)
											}
										>
											Download Submission
										</button>
									</div>
								</div>

								<div className="div-status">
									<p className="text-p">Submission Date</p>
									<div className="submission-details">
										{moment(
											questionData &&
												questionData.userFileSubmissions[0]
													.created_at
										).format('MMMM Do YYYY, h:mm:ss')}
									</div>
								</div>

								<div className="div-status">
									<p className="text-p">score</p>
									<div className="submission-details">
										{questionData &&
										questionData.userScoreCardDetails.solved
											? questionData.userScoreCardDetails
													.score
											: 'Under valuation'}
									</div>
								</div>

								<div className="div-status">
									<p className="text-p">feedback</p>

									{questionData &&
									typeof questionData.userFileSubmissions[0]
										.feedback == 'string' ? (
										questionData &&
										questionData.userFileSubmissions[0]
											.feedbackType === 'FILE' ? (
											<div className="submission-details">
												<span className="feedback-file-name"></span>
												<button
													className="feedback-file"
													onClick={() =>
														window.open(
															`${
																questionData &&
																questionData
																	.userFileSubmissions[0]
																	.feedback
															}`,
															'_blank'
														)
													}
												>
													Download Feedback
												</button>
											</div>
										) : (
											<span className="feedback-text">
												{questionData &&
													questionData
														.userFileSubmissions[0]
														.feedback}
											</span>
										)
									) : (
										<span className="feedback-text">
											Under valuation
										</span>
									)}
								</div>

								{btnDisable &&
								questionData?.userFileSubmissions?.length >
									0 &&
								questionData?.fileQuestion?.solutionFileUrl
									?.length > 0 ? (
									<div className="div-status">
										<p className="text-p">
											Correct solution link
										</p>
										<div className="submission-details">
											<button
												className="submission-file"
												onClick={() =>
													window.open(
														`${
															questionData &&
															questionData.fileQuestion
																?.solutionFileUrl
														}`,
														'_blank'
													)
												}
											>
												Download Solution
											</button>
										</div>
									</div>
								) : null}
							</div>

							{questionData &&
							questionData.userFileSubmissions.length >
								0 ? (
								<div className="div-right-wrapper">
									<p className="div-right-text-heading">
										Upload Submission
										{btnDisable ? (
											<span className="deadline-text">
												Deadline Passed
											</span>
										) : null}
									</p>
									<p className="div-right-text-p">
										Once you are done with your assignment,
										upload it here for our mentors to
										evaluate the question and give you
										feedback.
									</p>

									{selectedFile && (
										<div className={`browse-file-text`}>
											<span className="span-lebel">
												Uploaded file:
											</span>
											<span className="span-filename">
												{selectedFile.name}
											</span>
											<button
												className="reset-btn"
												onClick={() =>
													setSelectedFile(null)
												}
											>
												X
											</button>
										</div>
									)}

									<UploadButton
										disableToSubmit={btnDisable}
										selectedFile={selectedFile}
										onChange={handleFileChange}
										questionId={questionId}
										loadingState={loadingState}
									/>
								</div>
							) : null}
						</div>
					)}
				</ReflexElement>
			</ReflexContainer>
		</div>
	);
};

export default FileUploadDownloadScreen;
