import { HeatMap } from '../SharedComponents/HeatMap';
import { QuestionAnalysis } from '../SharedComponents/QuestionAnalysis';
import { StreakCard } from '../SharedComponents/StreakCard';

export const CriticalStatistics = () => {
	return (
		<div
			className="preview-right-wrapper"
			style={{
				display: 'flex',
				width: '100%',
				gap: '16px',
				padding: '40px 0px',
			}}
		>
			<div
				className="preview-row"
				style={{ flexDirection: 'column', width: '45%' }}
			>
				<StreakCard />
				<QuestionAnalysis />
			</div>
			<div
				className="preview-row"
				style={{ width: '40%' }}
			>
				<HeatMap vertical={true} />
			</div>
		</div>
	);
};
