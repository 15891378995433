import { useState } from 'react';
import {
	MdKeyboardArrowDown,
	MdKeyboardArrowUp,
} from 'react-icons/md';
import ProgressBar from '../../shared/ProgressBar';

interface SessionFeedbackAccordionProps {
	title: string | null;
	typeOfQuestion: string | null;
	QuestionTopics: string[] | string | null;
	score: number | null;
	typeOfFeedback: string | null;
	allFeedbackData: any;
}

const SessionFeedbackAccordion = ({
	title,
	typeOfQuestion,
	QuestionTopics,
	score,
	typeOfFeedback,
	allFeedbackData,
}: SessionFeedbackAccordionProps) => {
	const [activeTab, setActiveTab] = useState(false);

	const getColor = (score: number | null) => {
		if (score === null) {
			return '#505862';
		} else {
			if (score >= 0 && score < 6) {
				return '#F63D68';
			} else if (score >= 6 && score < 8) {
				return '#EAAA08';
			} else if (score >= 8 && score <= 10) {
				return '#12B76A';
			} else {
				return '#505862';
			}
		}
	};

	const colorStyle = { color: getColor(score) };

	const normalizedScore =
		score === null
			? null
			: score <= 0
			? 0
			: score >= 10
			? 10
			: score;

	return (
		<div className="session-feedback-accordion-wrapper">
			<table
				className={`session-details-header-wrapper ${
					activeTab ? 'mentorship-header-table-active ' : ''
				}`}
			>
				<thead>
					<tr className="accordion-header-wrapper">
						<th className="accordion-header">
							<span style={colorStyle}>{title}</span>
							{typeOfQuestion && (
								<span className="coding-difficulty">
									{typeOfQuestion}
								</span>
							)}
						</th>
						<th className="accordion-header topics-wrapper">
							{QuestionTopics &&
								(Array.isArray(QuestionTopics)
									? QuestionTopics.map(
											(tpc: any, i: number) => (
												<span
													className="topic-tags"
													key={i}
												>
													{tpc}
												</span>
											)
									  )
									: QuestionTopics.split(',').map(
											(tpc: any, i: number) => (
												<span
													className="topic-tags"
													key={i}
												>
													{tpc}
												</span>
											)
									  ))}
						</th>
						<th className="accordion-header">
							{score !== null && (
								<div className="score-progress">
									<ProgressBar
										val={normalizedScore as number}
										statusShow={true}
										fontSize="10px"
									/>
								</div>
							)}
						</th>
						<th
							className="accordion-header feedback-show-icon"
							onClick={() => setActiveTab(!activeTab)}
						>
							{activeTab ? (
								<span>
									Close
									<MdKeyboardArrowUp
										style={{ fontSize: '20px' }}
									/>
								</span>
							) : (
								<span>
									Open
									<MdKeyboardArrowDown
										style={{ fontSize: '20px' }}
									/>
								</span>
							)}
						</th>
					</tr>
				</thead>
			</table>

			{activeTab ? (
				title !== 'Technical Introduction' ? (
					<table
						className={`session-content-wrapper mentorship-content-table-active`}
					>
						<tbody>
							{Object.entries(allFeedbackData).map(
								([parameter, value]: any, index) => (
									<tr
										className="topic-content-header"
										key={index}
									>
										{/* {parameter !== 'Score' && ( */}
										<td className="topic-header-text">
											{typeOfFeedback && (
												<p className="feedback-parameter-h">
													{typeOfFeedback}
												</p>
											)}
											<p className="feedback-parameter-q">
												{parameter}
											</p>
											<div className="feedback-parameter-a">
												{value}
											</div>
										</td>
										{/* )} */}
									</tr>
								)
							)}
						</tbody>
					</table>
				) : (
					<table
						className={`session-content-wrapper mentorship-content-table-active`}
					>
						<tbody>
							<tr
								className="topic-content-header"
								key={0}
							>
								<td className="topic-header-text">
									<div className="feedback-parameter-a">
										{allFeedbackData}
									</div>
								</td>
							</tr>
						</tbody>
					</table>
				)
			) : null}
		</div>
	);
};

export default SessionFeedbackAccordion;
