import queryString from 'query-string';
import { useEffect, useState } from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { services } from '../../apis';
import Exam from '../../assets/lottie/exams-preparation.json';
import Loader from '../../components/Loader';
import { RootState } from '../../redux/store';
import IntegerType from './components/Integer';
import Mcq from './components/Mcq';
import MultiSelect from './components/MultiSelect';
import Subjective from './components/Subjective';

const Aptitude = () => {
	const location = useLocation();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const questionIdFromGym: string = queryString.parse(
		location.search
	).questionId;

	const isFromSoftSkills = queryString.parse(
		location.search
	).isFromSoftSkills;

	const [questionTitle, setQuestionTitle] = useState('');
	const [questionStatement, setQuestionStatement] =
		useState('');
	const [options, setOptions] = useState([]);
	const [questionType, setQuestionType] = useState('');
	const [answerId, setAnswerId] = useState(undefined);
	const [answer, setAnswer] = useState<any>([]);

	const [questionId, setQuestionId] = useState<string>(
		questionIdFromGym
	);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [solved, setSolved] = useState(false);

	const [correctAnswer, setCorrectAnswer] = useState([]);
	const [explanation, setExplanation] = useState('');

	const [loadAptitudeQuestion, setLoadAptitudeQuestion] =
		useState(false);
	const [score, setScore] = useState(0);
	const [maxScore, setMaxScore] = useState(0);

	const getQuestionDataById = async (
		questionId: string
	) => {
		try {
			const res =
				await services.courseService.getAptitudeQuestionById(
					questionId
				);

			if (res.answer !== null) {
				return {
					answerId: res.answer.id,
					answer: res.answer.answer,
					isSubmitted: res.answer.submitted,
					correctAnswer: res.question.correctAnswer,
					explanation: res.question.explanation,
					solved: res.solved,
					question: res.question,
				};
			} else {
				return {
					answerId: undefined,
					answer: [],
					isSubmitted: false,
					correctAnswer: res.question.correctAnswer,
					explanation: res.question.explanation,
					solved: false,
					question: res.question,
				};
			}

			setCorrectAnswer(res.question.correctAnswer);
			setExplanation(res.question.explanation);
			setSolved(res.solved);
			setLoadAptitudeQuestion(false);
			return { ...res.question, solved: res.solved };
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	useEffect(() => {
		const fetchQuestionData = async () => {
			try {
				const questionData = await getQuestionDataById(
					questionId
				);

				setQuestionTitle(questionData.question.title);
				setQuestionStatement(
					questionData.question.statement
				);
				setQuestionType(questionData.question.questionType);
				setOptions(questionData.question.answerOptions);
				setScore(
					questionData.solved
						? questionData.question.score
						: 0
				);
				setMaxScore(questionData.question.score);
				setAnswerId(questionData.answerId);
				setAnswer(questionData.answer);
				setIsSubmitted(questionData.isSubmitted);
				setCorrectAnswer(questionData.correctAnswer);
				setExplanation(questionData.explanation);
			} catch (error) {
				console.error(error);
			} finally {
				setLoadAptitudeQuestion(false);
			}
		};

		setLoadAptitudeQuestion(true);
		fetchQuestionData();
	}, [isSubmitted]);

	const saveQuestion = async (answerArray: string[]) => {
		const data = {
			conceptualQuestionId: questionId,
			answer: answerArray,
			id: answerId,
		};

		setAnswer(answerArray);

		try {
			const res =
				await services.courseService.saveAptitudeQuestions(
					data
				);

			return res.question;
		} catch (e) {
			console.log(e);
		}
	};

	const submitQuestion = async () => {
		console.log('Hiiii?>>>');
		const data = {
			conceptualQuestionId: questionId,
			answer: answer,
			// id: answerId,
			submitted: true,
		};
		setLoadAptitudeQuestion(true);
		try {
			const res =
				await services.courseService.submitAptitudeByQuestionId(
					data
				);

			console.log(
				'Save Aptitude Question temp',
				res,
				res.answer
			);

			setIsSubmitted(true);

			setScore(() => (res.data.solved ? maxScore : 0));
		} catch (e) {
			console.log(e);
		}
		setLoadAptitudeQuestion(false);
	};

	const handleNavigate = () => {
		if (isFromSoftSkills) {
			navigate(-1);
		} else {
			navigate('/gym');
		}
	};

	return (
		<div className={'aptitude'}>
			<div className={'column-1'}>
				<div className={'heading'}>
					<h1>
						Objective Questions{' '}
						<button
							className={'go-back-btn'}
							onClick={handleNavigate}
						>
							Go to{' '}
							{isFromSoftSkills ? 'Soft Skills' : 'Gym'}
						</button>
					</h1>
					<h1 className={'total-score'}>
						Your Score : {score}
					</h1>
				</div>
				<p className={'meta-info'}>
					Individual multiple select questions answers are
					auto-saved, press save to save answers for
					subjective and integer type questions. Submit all
					questions to get score
				</p>
				{!loadAptitudeQuestion ? (
					<div className={'questionContainer'}>
						<div className={'question-bar'}>
							<h4>Question : {questionTitle}</h4>
							<h4>Marks : {maxScore}</h4>
						</div>
						<div className={'question-panel'}>
							{questionType === 'SINGLEMCQ' &&
							!loadAptitudeQuestion ? (
								<Mcq
									statement={questionStatement}
									options={options}
									save={saveQuestion}
									response={answer}
									correctAnswer={correctAnswer}
									submitted={isSubmitted}
									explanation={explanation}
								/>
							) : null}
							{questionType === 'INT' &&
							!loadAptitudeQuestion ? (
								<IntegerType
									statement={questionStatement}
									options={options}
									save={saveQuestion}
									response={answer}
									correct={correctAnswer}
									submitted={isSubmitted}
									explanation={explanation}
								/>
							) : null}
							{questionType === 'TEXT' &&
							!loadAptitudeQuestion ? (
								<Subjective
									statement={questionStatement}
									options={options}
									save={saveQuestion}
									response={answer}
									correct={correctAnswer}
									submitted={isSubmitted}
									explanation={explanation}
								/>
							) : null}
							{questionType === 'MULTIMCQ' &&
							!loadAptitudeQuestion ? (
								<MultiSelect
									statement={questionStatement}
									options={options}
									save={saveQuestion}
									response={answer}
									correct={correctAnswer}
									submitted={isSubmitted}
									explanation={explanation}
								/>
							) : null}
						</div>
					</div>
				) : (
					<Loader />
				)}
				<button
					className={'submit-btn'}
					onClick={submitQuestion}
					disabled={isSubmitted}
				>
					Submit
				</button>
			</div>
			<div className={'aptitude-submission'}>
				<div className={'user-submission-box'}>
					<h3>Submission</h3>
					{isSubmitted ? (
						<div className={'user-submission'}>
							<p>
								Your Submission has been recorded
								successfully !
							</p>
							<p>Please check the correct answer.</p>
						</div>
					) : (
						<div className={'user-not-submitted'}>
							<p>No Submission Available</p>
						</div>
					)}
				</div>
				<div className={'animation'}>
					<div className="lottie-animation">
						<Lottie
							loop
							animationData={Exam}
							play
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Aptitude;
