import { Radio } from '@mui/joy';
import { FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import CustomDropdown from '../shared/Dropdown';
import InputField from '../shared/InputField';
import FieldBlock from './FieldBlock';
import ProfileBlock from './ProfileBlock';

import moment from 'moment';
import { SiLeetcode } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { services } from '../../apis';
import { useAppDispatch } from '../../redux/store';
import {
	fetchGeneralProfile,
	fetchLeetcodeUserName,
} from '../../redux/user/userSlice';
import InputButtonField from './InputButtonField';
import InputSearchBox from './InputSearchBox';
// import { InputSearchBox } from './InputSearchBox';

interface Option {
	[x: string]: any;
	value: string | number;
	label: string;
}

type Options = Option | Option[];

const ProfileTab1 = () => {
	const dispatch = useAppDispatch();
	const UserState = useSelector((state: any) => state.user);
	const {
		profile,
		user,
		leetcodeUserName,
		leetcodeLoading,
	} = UserState;
	console.log('dat ✅ profile', profile);
	console.log('dat ✅ user', user);
	console.log('dat ✅ leetcodeUserName', leetcodeUserName);
	console.log('dat ✅ leetcodeLoading', leetcodeLoading);
	const [isProfileLocked, setIsProfileLocked] =
		useState(false);

	useEffect(() => {
		if (user !== null) {
			console.log('called');
			dispatch(fetchGeneralProfile());
			dispatch(fetchLeetcodeUserName());
		}
	}, [user]);

	const notifySuccess = (msg: string) => {
		toast.success(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const notifyFailure = (msg: string) => {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const genderOptions = [
		{ value: 'Male', label: 'Male' },
		{ value: 'Female', label: 'Female' },
		{ value: 'Others', label: 'Others' },
	];

	const stateOptions = [
		{
			value: 'Andaman and Nicobar Islands',
			label: 'Andaman and Nicobar Islands',
		},
		{ value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
		{
			value: 'Arunachal Pradesh',
			label: 'Arunachal Pradesh',
		},
		{ value: 'Assam', label: 'Assam' },
		{ value: 'Bihar', label: 'Bihar' },
		{ value: 'Chandigarh', label: 'Chandigarh' },
		{ value: 'Chhattisgarh', label: 'Chhattisgarh' },
		{
			value: 'Dadra & Nagar Haveli and Daman & Diu',
			label: 'Dadra & Nagar Haveli and Daman & Diu',
		},
		{ value: 'Delhi', label: 'Delhi' },
		{ value: 'Goa', label: 'Goa' },
		{ value: 'Gujarat', label: 'Gujarat' },
		{ value: 'Haryana', label: 'Haryana' },
		{
			value: 'Himachal Pradesh',
			label: 'Himachal Pradesh',
		},
		{
			value: 'Jammu and Kashmir',
			label: 'Jammu and Kashmir',
		},
		{ value: 'Jharkhand', label: 'Jharkhand' },
		{ value: 'Karnataka', label: 'Karnataka' },
		{ value: 'Kerala', label: 'Kerala' },
		{ value: 'Ladakh', label: 'Ladakh' },
		{ value: 'Lakshadweep', label: 'Lakshadweep' },
		{ value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
		{ value: 'Maharashtra', label: 'Maharashtra' },
		{ value: 'Manipur', label: 'Manipur' },
		{ value: 'Meghalaya', label: 'Meghalaya' },
		{ value: 'Mizoram', label: 'Mizoram' },
		{ value: 'Nagaland', label: 'Nagaland' },
		{ value: 'Odisha', label: 'Odisha' },
		{ value: 'Puducherry', label: 'Puducherry' },
		{ value: 'Punjab', label: 'Punjab' },
		{ value: 'Rajasthan', label: 'Rajasthan' },
		{ value: 'Sikkim', label: 'Sikkim' },
		{ value: 'Tamil Nadu', label: 'Tamil Nadu' },
		{ value: 'Telangana', label: 'Telangana' },
		{ value: 'Tripura', label: 'Tripura' },
		{ value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
		{ value: 'Uttarakhand', label: 'Uttarakhand' },
		{ value: 'West Bengal', label: 'West Bengal' },
	];

	// const stateOptions = [
	// 	{
	// 		value: 'Andaman_and_Nicobar_Islands',
	// 		label: 'Andaman and Nicobar Islands',
	// 	},
	// 	{ value: 'Andhra_Pradesh', label: 'Andhra Pradesh' },
	// 	{
	// 		value: 'Arunachal_Pradesh',
	// 		label: 'Arunachal Pradesh',
	// 	},
	// 	{ value: 'Assam', label: 'Assam' },
	// 	{ value: 'Bihar', label: 'Bihar' },
	// 	{ value: 'Chandigarh', label: 'Chandigarh' },
	// 	{ value: 'Chhattisgarh', label: 'Chhattisgarh' },
	// 	{
	// 		value: 'Dadra_Diu',
	// 		label: 'Dadra & Nagar Haveli and Daman & Diu',
	// 	},
	// 	{ value: 'Delhi', label: 'Delhi' },
	// 	{ value: 'Goa', label: 'Goa' },
	// 	{ value: 'Gujarat', label: 'Gujarat' },
	// 	{ value: 'Haryana', label: 'Haryana' },
	// 	{
	// 		value: 'Himachal_Pradesh',
	// 		label: 'Himachal Pradesh',
	// 	},
	// 	{
	// 		value: 'Jammu_and_Kashmir',
	// 		label: 'Jammu and Kashmir',
	// 	},
	// 	{ value: 'Jharkhand', label: 'Jharkhand' },
	// 	{ value: 'Karnataka', label: 'Karnataka' },
	// 	{ value: 'Kerala', label: 'Kerala' },
	// 	{ value: 'Ladakh', label: 'Ladakh' },
	// 	{ value: 'Lakshadweep', label: 'Lakshadweep' },
	// 	{ value: 'Madhya_Pradesh', label: 'Madhya Pradesh' },
	// 	{ value: 'Maharashtra', label: 'Maharashtra' },
	// 	{ value: 'Manipur', label: 'Manipur' },
	// 	{ value: 'Meghalaya', label: 'Meghalaya' },
	// 	{ value: 'Mizoram', label: 'Mizoram' },
	// 	{ value: 'Nagaland', label: 'Nagaland' },
	// 	{ value: 'Odisha', label: 'Odisha' },
	// 	{ value: 'Puducherry', label: 'Puducherry' },
	// 	{ value: 'Punjab', label: 'Punjab' },
	// 	{ value: 'Rajasthan', label: 'Rajasthan' },
	// 	{ value: 'Sikkim', label: 'Sikkim' },
	// 	{ value: 'Tamil_Nadu', label: 'Tamil Nadu' },
	// 	{ value: 'Telangana', label: 'Telangana' },
	// 	{ value: 'Tripura', label: 'Tripura' },
	// 	{ value: 'Uttar_Pradesh', label: 'Uttar Pradesh' },
	// 	{ value: 'Uttarakhand', label: 'Uttarakhand' },
	// 	{ value: 'West_Bengal', label: 'West Bengal' },
	// ];

	const indianLanguageNames = [
		{ value: 'English', label: 'English' },
		{ value: 'Hindi', label: 'Hindi' },
		{ value: 'Bengali', label: 'Bengali' },
		{ value: 'Gujarati', label: 'Gujarati' },
		{ value: 'Kannada', label: 'Kannada' },
		{ value: 'Malayalam', label: 'Malayalam' },
		{ value: 'Marathi', label: 'Marathi' },
		{ value: 'Tamil', label: 'Tamil' },
		{ value: 'Telugu', label: 'Telugu' },
	];

	const bachelorDepertmentOptions = [
		{
			value: 'Computer Science/CSE/Other CS related branch',
			label: 'Computer Science/CSE/Other CS related branch',
		},
		{
			value: 'Electrical/Other electrical related branches',
			label: 'Electrical/Other electrical related branches',
		},
		{
			value: 'Chemical Engineering',
			label: 'Chemical Engineering',
		},
		{ value: 'Civil', label: 'Civil' },
		{
			value: 'Mechanical/Other mechanics related branch',
			label: 'Mechanical/Other mechanics related branch',
		},
		{
			value: 'Aeronautical/Aerospace',
			label: 'Aeronautical/Aerospace',
		},
		{
			value: 'Biotech/Biomedical',
			label: 'Biotech/Biomedical',
		},
		{ value: 'Ceramic', label: 'Ceramic' },
		{ value: 'Communications', label: 'Communications' },
		{ value: 'Physics', label: 'Physics' },
		{ value: 'Chemistry', label: 'Chemistry' },
		{ value: 'Mathematics', label: 'Mathematics' },
		{
			value: 'English/Literature',
			label: 'English/Literature',
		},
		{
			value: 'Metallurgical/Mining',
			label: 'Metallurgical/Mining',
		},
		{ value: 'Textile', label: 'Textile' },
		{ value: 'Others', label: 'Others' },
		{ value: 'Agriculture', label: 'Agriculture' },
		{ value: 'IT', label: 'IT' },
	];

	const WorkingProfessionStatus = [
		{
			value: 'Working_Professional',
			label: 'Working_Professional',
		},
		{
			value: 'Student',
			label: 'Student',
		},
		{
			value: 'Unemployed',
			label: 'Unemployed',
		},
	];

	const WorkingProfessionTypes = [
		{
			value: 'Coding Related',
			label: 'Coding Related',
		},
		{
			value: 'Not Coding Related',
			label: 'Not Coding Related',
		},
	];

	const bachelorDegreeOptions = [
		{ value: 'BTech./BE', label: 'BTech./BE' },
		{ value: 'BCA', label: 'BCA' },
		{ value: 'BBA', label: 'BBA' },
		{ value: 'BSc.', label: 'BSc.' },
		{ value: 'BCom', label: 'BCom' },
		{ value: 'BA', label: 'BA' },
		{ value: 'BEd', label: 'BEd.' },
		{ value: 'LLB', label: 'LLB' },
		{ value: 'BArch', label: 'BArch.' },
		{ value: 'BDes', label: 'BDes.' },
		{ value: 'CA', label: 'CA' },
		{ value: 'BJMC', label: 'BJMC' },
		{ value: 'BHM', label: 'BHM' },
		{ value: 'Other', label: 'Other' },
	];

	const gradeYears = [];

	for (let year = 1990; year <= 2025; year++) {
		gradeYears.push({
			value: year.toString(),
			label: year.toString(),
		});
	}

	const pg_Years_Options = [];

	for (let year = 1990; year <= 2025; year++) {
		pg_Years_Options.push({
			value: year.toString(),
			label: year.toString(),
		});
	}

	const postGraduationDegreeOptions = [
		{ value: 'MTech./ME', label: 'MTech./ME' },
		{ value: 'MCA', label: 'MCA' },
		{ value: 'MBA', label: 'MBA' },
		{ value: 'MSc', label: 'MSc' },
		{ value: 'Other', label: 'Other' },
	];

	// const postGraduationDegreeOptions = [
	// 	{ value: 'MTech_ME', label: 'MTech./ME' },
	// 	{ value: 'MCA', label: 'MCA' },
	// 	{ value: 'MBA', label: 'MBA' },
	// 	{ value: 'MSc', label: 'MSc' },
	// 	{ value: 'Other', label: 'Other' },
	// ];

	// const postGraduationDepartmentOptions = [
	// 	{
	// 		value: 'CSE',
	// 		label: 'Computer Science/CSE/Other CS related branch',
	// 	},
	// 	{
	// 		value: 'Electrical',
	// 		label: 'Electrical/Other electrical related branches',
	// 	},
	// 	{
	// 		value: 'Chemical_Engineering',
	// 		label: 'Chemical Engineering',
	// 	},
	// 	{ value: 'Civil', label: 'Civil' },
	// 	{
	// 		value: 'Mechanical',
	// 		label: 'Mechanical/Other mechanics related branch',
	// 	},
	// 	{
	// 		value: 'Aeronautical',
	// 		label: 'Aeronautical/Aerospace',
	// 	},
	// 	{ value: 'Biotech', label: 'Biotech/Biomedical' },
	// 	{ value: 'Ceramic', label: 'Ceramic' },
	// 	{ value: 'Communications', label: 'Communications' },
	// 	{ value: 'Physics', label: 'Physics' },
	// 	{ value: 'Chemistry', label: 'Chemistry' },
	// 	{ value: 'Mathematics', label: 'Mathematics' },
	// 	{ value: 'English', label: 'English/Literature' },
	// 	{
	// 		value: 'Metallurgical',
	// 		label: 'Metallurgical/Mining',
	// 	},
	// 	{ value: 'Textile', label: 'Textile' },
	// 	{ value: 'Others', label: 'Others' },
	// 	{ value: 'Agriculture', label: 'Agriculture' },
	// 	{ value: 'IT', label: 'IT' },
	// ];

	console.log(' valueuser', user);

	const postGraduationDepartmentOptions = [
		{
			value: 'Computer Science/CSE/Other CS related branch',
			label: 'Computer Science/CSE/Other CS related branch',
		},
		{
			value: 'Electrical/Other electrical related branches',
			label: 'Electrical/Other electrical related branches',
		},
		{
			value: 'Chemical Engineering',
			label: 'Chemical Engineering',
		},
		{ value: 'Civil', label: 'Civil' },
		{
			value: 'Mechanical/Other mechanics related branch',
			label: 'Mechanical/Other mechanics related branch',
		},
		{
			value: 'Aeronautical/Aerospace',
			label: 'Aeronautical/Aerospace',
		},
		{
			value: 'Biotech/Biomedical',
			label: 'Biotech/Biomedical',
		},
		{ value: 'Ceramic', label: 'Ceramic' },
		{ value: 'Communications', label: 'Communications' },
		{ value: 'Physics', label: 'Physics' },
		{ value: 'Chemistry', label: 'Chemistry' },
		{ value: 'Mathematics', label: 'Mathematics' },
		{
			value: 'English/Literature',
			label: 'English/Literature',
		},
		{
			value: 'Metallurgical/Mining',
			label: 'Metallurgical/Mining',
		},
		{ value: 'Textile', label: 'Textile' },
		{ value: 'Others', label: 'Others' },
		{ value: 'Agriculture', label: 'Agriculture' },
		{ value: 'IT', label: 'IT' },
	];

	const [userProfileLoock, setUserProfileLoock] =
		useState<boolean>(false);

	const [fname, setFname] = useState<string | null>(null);
	const [lname, setLname] = useState<string | null>(null);
	const [fatherName, setFatherName] = useState<
		string | null
	>(null);
	const [whatsappNumber, setWhatsappNumber] = useState<
		string | null
	>(null);
	const [parentsContactNumber, setParentsContactNumber] =
		useState<string | null>(null);
	const [hometown, setHomeTown] = useState<Option | null>(
		null
	);
	const [languages, setLanguages] = useState<Option | null>(
		null
	);
	const [gender, setGender] = useState<Option | null>(null);
	const [dateOfBirth, setDateOfBirth] =
		useState<Date | null>(null);

	const [highSchoolPercentage, setHighSchoolPercentage] =
		useState<string | null>(null);
	const [
		IntermediatePercentage,
		setIntermediatePercentage,
	] = useState<string | null>(null);
	const [graduationYear, setGraduationYear] =
		useState<Option | null>(null);
	const [graduationPercentage, setGraduationPercentage] =
		useState<string | null>(null);
	const [degree, setDegree] = useState<Option | null>(null);
	const [depertment, setDepertment] =
		useState<Option | null>(null);

	const [collegeName, setCollegeName] = useState<
		string | null
	>(null);

	const [collegeId, setCollegeId] = useState<string | null>(
		null
	);

	const [githubUserName, setGithubUserName] = useState<
		string | null
	>(null);

	const [leetcodeUserNameVal, setleetcodeUserNameval] =
		useState<string | null>(null);

	const [EmploymentStatus, setEmploymentStatus] =
		useState<Option | null>(null);
	const [EmploymentType, setEmploymentType] =
		useState<Option | null>(null);

	const [pg_Status, setPg_Status] =
		useState<boolean>(false);

	const [pg_Year, setPg_Year] = useState<Option | null>(
		null
	);

	const [pg_Percentage, setPg_Percentage] = useState<
		string | null
	>(null);

	const [pg_Degree, setPg_Degree] = useState<Option | null>(
		null
	);

	const [pg_Depertment, setPg_Depertment] =
		useState<Option | null>(null);

	const [pg_CollegeName, setPg_CollegeName] = useState<
		string | null
	>(null);

	const [pg_CollegeId, setPg_CollegeId] = useState<
		string | null
	>(null);

	// error state
	const [fnameError, setFnameError] =
		useState<boolean>(false);
	const [lnameError, setLnameError] =
		useState<boolean>(false);
	const [fatherNameError, setFatherNameError] =
		useState<boolean>(false);
	const [whatsappNumberError, setWhatsappNumberError] =
		useState<boolean>(false);
	const [
		parentsContactNumberError,
		setParentsContactNumberError,
	] = useState<boolean>(false);
	const [hometownError, setHomeTownError] =
		useState<boolean>(false);
	const [languagesError, setLanguagesError] =
		useState<boolean>(false);
	const [genderError, setGenderError] =
		useState<boolean>(false);
	const [dateOfBirthError, setDateOfBirthError] =
		useState<boolean>(false);
	const [
		highSchoolPercentageError,
		setHighSchoolPercentageError,
	] = useState<boolean>(false);
	const [
		IntermediatePercentageError,
		setIntermediatePercentageError,
	] = useState<boolean>(false);
	const [graduationYearError, setGraduationYearError] =
		useState<boolean>(false);
	const [
		graduationPercentageError,
		setGraduationPercentageError,
	] = useState<boolean>(false);
	const [degreeError, setDegreeError] =
		useState<boolean>(false);
	const [depertmentError, setDepertmentError] =
		useState<boolean>(false);
	const [collegeError, setCollegeError] =
		useState<boolean>(false);
	const [EmploymentStatusError, setEmploymentStatusError] =
		useState<boolean>(false);
	const [EmploymentTypeError, setEmploymentTypeError] =
		useState<boolean>(false);
	const [pg_StatusError, setPg_StatusError] =
		useState<boolean>(false);
	const [pg_YearError, setPg_YearError] =
		useState<boolean>(false);
	const [pg_PercentageError, setPg_PercentageError] =
		useState<boolean>(false);
	const [pg_DegreeError, setPg_DegreeError] =
		useState<boolean>(false);
	const [pg_DepertmentError, setPg_DepertmentError] =
		useState<boolean>(false);
	const [pg_CollegeError, setPg_CollegeError] =
		useState<boolean>(false);

	const [leetcodeUserNameError, setleetCodeUserNameError] =
		useState<boolean>(false);

	const [typedValue, setTypedValue] = useState('');

	const handleInputChange = (value: string) => {
		setTypedValue(value);
	};

	const handleFnameChange = (event: any) => {
		setFname(event.target.value);
		setFnameError(false);
	};

	const handlefatherNameChange = (event: any) => {
		setFatherName(event.target.value);
		setFatherNameError(false);
	};

	const handleLnameChange = (event: any) => {
		setLname(event.target.value);
		setLnameError(false);
	};

	const handleWhatsappNumberChange = (event: any) => {
		setWhatsappNumber(event.target.value);
		setWhatsappNumberError(false);
	};

	const handleGenderChange = (selectedOption: any) => {
		setGender(selectedOption);
		setGenderError(false);
	};

	const handleDateOfBirthChange = (date: any) => {
		setDateOfBirth(date);
		setDateOfBirthError(false);
	};

	const handleHomeTownChange = (selectedOption: any) => {
		setHomeTown(selectedOption);
		setHomeTownError(false);
	};

	const handleParentContactNumber = (event: any) => {
		setParentsContactNumber(event.target.value);
		setParentsContactNumberError(false);
	};

	const handleLanguageChange = (selectedOption: any) => {
		setLanguages(selectedOption);
		setLanguagesError(false);
	};

	const handleHighSchoolPercentage = (event: any) => {
		setHighSchoolPercentage(event.target.value);
		setHighSchoolPercentageError(false);
	};

	const handleIntermediatePercentage = (event: any) => {
		setIntermediatePercentage(event.target.value);
		setIntermediatePercentageError(false);
	};

	const handleGraduationYear = (selectedOption: any) => {
		console.log('selectedOption', selectedOption);
		setGraduationYear(selectedOption);
		setGraduationYearError(false);
	};
	const handleGraduationPercentage = (event: any) => {
		setGraduationPercentage(event.target.value);
		setGraduationPercentageError(false);
	};
	const handleDegree = (selectedOption: any) => {
		setDegree(selectedOption);
		setDegreeError(false);
	};
	const handleDepertment = (selectedOption: any) => {
		setDepertment(selectedOption);
		setDepertmentError(false);
	};

	// const handleLeetcodeUserName = (event: any) => {
	// 	setleetcodeUserNameval(event.target.value);
	// };

	const handleEmploymentStatus = (selectedOption: any) => {
		setEmploymentStatus(selectedOption);
		setEmploymentStatusError(false);
	};
	//yes/no
	const handleEmploymentType = (selectedOption: any) => {
		setEmploymentType(selectedOption);
		setEmploymentTypeError(false);
	};

	const handleRadioChange = (event: any) => {
		const newValue = event.target.value === 'true';
		setPg_Status(newValue);
	};

	// const handlePg_Status = (event: any) => {
	// 	setPg_Status(event.target.value);
	// };
	const handlePg_Year = (selectedOption: any) => {
		setPg_Year(selectedOption);
		setPg_YearError(false);
	};
	const handlePg_Percentage = (event: any) => {
		setPg_Percentage(event.target.value);
		setPg_PercentageError(false);
	};
	const handlePg_Degree = (selectedOption: any) => {
		setPg_Degree(selectedOption);
		setPg_DegreeError(false);
	};
	const handlePg_Depertment = (selectedOption: any) => {
		setPg_Depertment(selectedOption);
		setPg_DepertmentError(false);
	};
	// const handlePg_College = (selectedOption: any) => {
	// 	setPg_College(selectedOption);
	// 	setPg_CollegeError(false);
	// };

	const handleSubmit = async (event: any) => {
		event.preventDefault();

		let isError = false;

		// First name validation
		if (!fname || fname.length < 2) {
			setFnameError(true);
			isError = true;
		} else {
			setFnameError(false);
		}

		// Last name validation
		if (!lname || lname.length < 2) {
			setLnameError(true);
			isError = true;
		} else {
			setLnameError(false);
		}

		// Father's name validation
		if (!fatherName || fatherName.length < 2) {
			setFatherNameError(true);
			isError = true;
		} else {
			setFatherNameError(false);
		}

		// Whatsapp number validation (assuming it should be a 10 digit number)
		const whatsappNumberPattern = /^\d{10}$/;
		if (
			!whatsappNumber ||
			!whatsappNumberPattern.test(whatsappNumber)
		) {
			setWhatsappNumberError(true);
			isError = true;
		} else {
			setWhatsappNumberError(false);
		}

		// Parents' contact number validation (assuming it should be a 10 digit number)
		const parentsContactNumberPattern = /^\d{10}$/;
		if (
			!parentsContactNumber ||
			!parentsContactNumberPattern.test(
				parentsContactNumber
			)
		) {
			setParentsContactNumberError(true);
			isError = true;
		} else {
			setParentsContactNumberError(false);
		}

		// Hometown validation (assuming it should not be empty)
		if (!hometown) {
			setHomeTownError(true);
			isError = true;
		} else {
			setHomeTownError(false);
		}

		// Languages validation (assuming it should not be empty)
		if (!languages) {
			setLanguagesError(true);
			isError = true;
		} else {
			setLanguagesError(false);
		}

		// Gender validation (assuming it should not be empty)
		if (!gender) {
			setGenderError(true);
			isError = true;
		} else {
			setGenderError(false);
		}

		// Date of birth validation (assuming it should be a valid date and the person should be at least 18 years old)
		const eighteenYearsAgo = new Date();
		eighteenYearsAgo.setFullYear(
			eighteenYearsAgo.getFullYear() - 18
		);
		if (!dateOfBirth || dateOfBirth > eighteenYearsAgo) {
			setDateOfBirthError(true);
			isError = true;
		} else {
			setDateOfBirthError(false);
		}

		// High school percentage validation (assuming it should be a number between 0 and 100)
		const highSchoolPercentageNumber = Number(
			highSchoolPercentage
		);
		if (
			!highSchoolPercentage ||
			isNaN(highSchoolPercentageNumber) ||
			highSchoolPercentageNumber < 0 ||
			highSchoolPercentageNumber > 100
		) {
			setHighSchoolPercentageError(true);
			isError = true;
		} else {
			setHighSchoolPercentageError(false);
		}

		// Intermediate percentage validation (assuming it should be a number between 0 and 100)
		const intermediatePercentageNumber = Number(
			IntermediatePercentage
		);
		if (
			!IntermediatePercentage ||
			isNaN(intermediatePercentageNumber) ||
			intermediatePercentageNumber < 0 ||
			intermediatePercentageNumber > 100
		) {
			setIntermediatePercentageError(true);
			isError = true;
		} else {
			setIntermediatePercentageError(false);
		}

		// Graduation year validation (assuming it should not be empty and should be a valid year)
		const currentYear = new Date().getFullYear();
		if (!graduationYear || graduationYear.value == null) {
			setGraduationYearError(true);
			isError = true;
		} else {
			const graduationYearNumber = Number(
				graduationYear.value
			);
			if (
				isNaN(graduationYearNumber) ||
				graduationYearNumber < 1990
			) {
				setGraduationYearError(true);
				isError = true;
			} else {
				setGraduationYearError(false);
			}
		}

		// Graduation percentage validation (assuming it should be a number between 0 and 100)
		const graduationPercentageNumber = Number(
			graduationPercentage
		);
		if (
			!graduationPercentage ||
			isNaN(graduationPercentageNumber) ||
			graduationPercentageNumber < 0 ||
			graduationPercentageNumber > 100
		) {
			setGraduationPercentageError(true);
			isError = true;
		} else {
			setGraduationPercentageError(false);
		}

		// Degree validation (assuming it should not be empty)
		if (!degree) {
			setDegreeError(true);
			isError = true;
		} else {
			setDegreeError(false);
		}

		//leetcodeFeild validation (assuming it should not be empty)
		if (
			!leetcodeUserNameVal &&
			user.roles.includes('maincourse-user')
		) {
			setleetCodeUserNameError(true);
			isError = true;
		} else {
			setleetCodeUserNameError(false);
		}

		// Department validation (assuming it should not be empty)
		if (!depertment) {
			setDepertmentError(true);
			isError = true;
		} else {
			setDepertmentError(false);
		}

		// College validation (assuming it should not be empty)
		if (!collegeName) {
			setCollegeError(true);
			isError = true;
		} else {
			setCollegeError(false);
		}

		// Employment status validation (assuming it should not be empty)
		if (!EmploymentStatus) {
			setEmploymentStatusError(true);
			isError = true;
		} else {
			setEmploymentStatusError(false);
		}

		// Employment type validation (assuming it should not be empty)
		if (!EmploymentType) {
			setEmploymentTypeError(true);
			isError = true;
		} else {
			setEmploymentTypeError(false);
		}

		// Post-graduation details validation
		if (pg_Status) {
			console.log('pg_Year', pg_Year);
			if (
				pg_Year &&
				pg_Year.value &&
				graduationYear &&
				graduationYear.value &&
				!isNaN(Number(pg_Year.value)) &&
				Number(pg_Year.value) > 1990 &&
				Number(pg_Year.value) > Number(graduationYear.value)
			) {
				setPg_YearError(false);
			} else {
				console.log('abc yel i');
				setPg_YearError(true);
				isError = true;
			}

			const pg_PercentageNumber = Number(pg_Percentage);
			if (
				!pg_Percentage ||
				isNaN(pg_PercentageNumber) ||
				pg_PercentageNumber < 0 ||
				pg_PercentageNumber > 100
			) {
				setPg_PercentageError(true);
				isError = true;
			} else {
				setPg_PercentageError(false);
			}

			if (!pg_Degree) {
				setPg_DegreeError(true);
				isError = true;
			} else {
				setPg_DegreeError(false);
			}

			if (!pg_Depertment) {
				setPg_DepertmentError(true);
				isError = true;
			} else {
				setPg_DepertmentError(false);
			}

			if (!pg_CollegeName) {
				setPg_CollegeError(true);
				isError = true;
			} else {
				setPg_CollegeError(false);
			}
		}

		if (!isError && user) {
			const languageValues: (string | number)[] = (
				languages !== null ? languages : []
			).map((language: Option) => language.value);

			const formData = {
				class10Percentage: highSchoolPercentage,
				class12Percentage: IntermediatePercentage,
				collegeDegree: degree?.value,
				collegeDepartment: depertment?.value,
				college: {
					id: collegeId,
				},
				dateOfBirth:
					moment(dateOfBirth).format('YYYY-MM-DD'),
				fatherName: fatherName,
				firstName: fname,
				gender: gender?.value,
				graduationPercentage: graduationPercentage,
				graduationYear: graduationYear?.value,
				homeState: hometown?.value,
				jobStatus: EmploymentStatus?.value,
				jobType: EmploymentType?.value,
				lastName: lname,
				leetcodeusername: leetcodeUserNameVal,
				parentsContact: parentsContactNumber,
				pgCollegeDegree: pg_Degree?.value
					? pg_Degree?.value
					: null,
				pgCollegeDepartment: pg_Depertment?.value
					? pg_Depertment?.value
					: null,
				pgCollege: {
					id: pg_CollegeId ?? null,
				},
				postGraduationPercentage: pg_Percentage,
				postGraduationYear:
					pg_Year?.value == null ? 0 : pg_Year?.value,
				preferredSpeakingLanguage: languageValues,
				userId: user.id,
				whatsappNumber: whatsappNumber,
			};

			try {
				const data = await services.userService.saveProfile(
					{ ...formData }
				);
				console.log('abc data', data);
				notifySuccess(
					'Personal and Educational Info saved!'
				);
			} catch (e) {
				console.log(e);
				notifyFailure(
					"Couldn't save personal and educational info"
				);
			}

			dispatch(fetchGeneralProfile());

			console.log('abc', formData);
		}
	};

	useEffect(() => {
		if (!pg_Status) {
			setPg_Year(null);
			setPg_Percentage(null);
			setPg_Degree(null);
			setPg_Depertment(null);
			setPg_CollegeId(null);
		}
	}, [pg_Status]);

	const getPgGradYearError = (): string => {
		if (
			pg_Year &&
			pg_Year.value &&
			graduationYear &&
			graduationYear.value &&
			!isNaN(Number(pg_Year.value)) &&
			Number(pg_Year.value) > 1990 &&
			Number(pg_Year.value) <= Number(graduationYear.value)
		) {
			return 'Post graduation year must be greater than graduation year';
		}
		return 'Select the post graduation year';
	};

	const handleLeetcodeUserNameChange = async () => {
		console.log('input 🎁changed', typedValue);
		if (typedValue.length > 2) {
			const response =
				await services.userService.saveLeetcodeUserName({
					leetcodeUsername: typedValue,
				});
			if (
				response &&
				response.statusCode !== 400 &&
				response.statusCode !== 500
			) {
				notifySuccess(response.message);
			} else {
				notifyFailure(response.message);
			}
		} else {
			notifyFailure('Please enter at least 3 characters');
		}
		setTypedValue('');
		setleetCodeUserNameError(false);
		dispatch(fetchLeetcodeUserName());
	};

	useEffect(() => {
		const fields = [
			{ value: fname, errorSetter: setFnameError },
			{ value: lname, errorSetter: setLnameError },
			{
				value: fatherName,
				errorSetter: setFatherNameError,
			},
			{
				value: whatsappNumber,
				errorSetter: setWhatsappNumberError,
			},
			{
				value: parentsContactNumber,
				errorSetter: setParentsContactNumberError,
			},
			{ value: hometown, errorSetter: setHomeTownError },
			{ value: languages, errorSetter: setLanguagesError },
			{ value: gender, errorSetter: setGenderError },
			{
				value: dateOfBirth,
				errorSetter: setDateOfBirthError,
			},
			{
				value: highSchoolPercentage,
				errorSetter: setHighSchoolPercentageError,
			},
			{
				value: IntermediatePercentage,
				errorSetter: setIntermediatePercentageError,
			},
			{
				value: graduationYear,
				errorSetter: setGraduationYearError,
			},
			{
				value: graduationPercentage,
				errorSetter: setGraduationPercentageError,
			},
			{
				value: leetcodeUserNameVal,
				errorSetter: setleetCodeUserNameError,
			},
			{ value: degree, errorSetter: setDegreeError },
			{
				value: depertment,
				errorSetter: setDepertmentError,
			},
			{ value: collegeName, errorSetter: setCollegeError },
			{
				value: EmploymentStatus,
				errorSetter: setEmploymentStatusError,
			},
			{
				value: EmploymentType,
				errorSetter: setEmploymentTypeError,
			},
			{ value: pg_Status, errorSetter: setPg_StatusError },
			{ value: pg_Year, errorSetter: setPg_YearError },
			{
				value: pg_Percentage,
				errorSetter: setPg_PercentageError,
			},
			{ value: pg_Degree, errorSetter: setPg_DegreeError },
			{
				value: pg_Depertment,
				errorSetter: setPg_DepertmentError,
			},
			{
				value: pg_CollegeName,
				errorSetter: setPg_CollegeError,
			},
		];

		fields.forEach((field) => {
			if (field.value) {
				field.errorSetter(false);
			}
		});
	}, [
		fname,
		lname,
		fatherName,
		whatsappNumber,
		parentsContactNumber,
		hometown,
		languages,
		gender,
		dateOfBirth,
		highSchoolPercentage,
		IntermediatePercentage,
		graduationYear,
		leetcodeUserNameVal,
		graduationPercentage,
		degree,
		depertment,
		collegeName,
		EmploymentStatus,
		EmploymentType,
		pg_Status,
		pg_Year,
		pg_Percentage,
		pg_Degree,
		pg_Depertment,
		pg_CollegeName,
	]);

	// 	class10Percentage: "93",
	//   class12Percentage: "97",
	//   collegeDegree: "BTech./BE",
	//   collegeDepartment: "Computer Science/CSE/Other CS related branch",
	//   collegeName: "Narasaraopeta Institute of Technology - [NIT]",
	//   dateOfBirth: "2002-06-06T00:00:00.000Z",
	//   fatherName: null,
	//   firstName: "Manoj Adithya Varma",
	//   gender: "Male",
	//   graduationPercentage: "70",
	//   graduationYear: "2023",
	//   homeState: "Andhra Pradesh",
	//   jobStatus: "Student",
	//   jobType: null,
	//   lastName: "uppalapati",
	//   parentsContact: "9948848586",
	//   pgCollegeDegree: null,
	//   pgCollegeDepartment: null,
	//   pgCollegeName: null,
	//   postGraduationPercentage: "0",
	//   postGraduationYear: "0",
	//   preferredSpeakingLanguage: ["Telugu", "English"],
	//   userId: "c4eb9a57-b642-4b65-b487-233028b52eff",
	//   whatsappNumber: "6309279262"

	useEffect(() => {
		if (profile) {
			setFname(profile.firstName);
			setLname(profile.lastName);
			setFatherName(profile.fatherName);
			setWhatsappNumber(profile.whatsappNumber);
			setParentsContactNumber(profile.parentsContact);
			setHomeTown({
				value: profile.homeState,
				label: profile.homeState,
			});
			setLanguages(
				profile.preferredSpeakingLanguage.map(
					(language: any) => ({
						value: language,
						label: language,
					})
				)
			);
			setGender({
				value: profile.gender,
				label: profile.gender,
			});
			setDateOfBirth(new Date(profile.dateOfBirth));
			setHighSchoolPercentage(profile.class10Percentage);
			setIntermediatePercentage(profile.class12Percentage);
			setGraduationYear({
				value: profile.graduationYear,
				label: profile.graduationYear,
			});
			setGraduationPercentage(profile.graduationPercentage);
			setDegree({
				value: profile.collegeDegree,
				label: profile.collegeDegree,
			});
			setDepertment({
				value: profile.collegeDepartment,
				label: profile.collegeDepartment,
			});
			console.log('collegeNameval', profile.collegeName);
			setCollegeName(profile.college?.fullName);
			setCollegeId(profile.college?.id);
			setEmploymentStatus({
				value: profile.jobStatus,
				label: profile.jobStatus,
			});
			setEmploymentType({
				value: profile.jobType,
				label: profile.jobType,
			});
			if (profile.pgCollegeName == null) {
				setPg_Status(false);
			} else {
				setPg_Status(true);
			}
			console.log(
				'dataval',
				profile.postGraduationYear,
				profile.pgCollegeDegree,
				profile.pgCollegeDepartment
			);
			setPg_Year({
				value: profile.postGraduationYear,
				label: profile.postGraduationYear,
			});
			setPg_Percentage(profile.postGraduationPercentage);
			setPg_Degree({
				value: profile.pgCollegeDegree,
				label: profile.pgCollegeDegree,
			});
			setPg_Depertment({
				value: profile.pgCollegeDepartment,
				label: profile.pgCollegeDepartment,
			});
			setPg_CollegeName(profile.pgCollege?.fullName);
			setPg_CollegeId(profile.pgCollege?.id);
			setGithubUserName(profile.githubUsername);
		}
	}, [profile]);

	useEffect(() => {
		if (leetcodeUserName && !leetcodeLoading) {
			setleetcodeUserNameval(leetcodeUserName);
		}
	}, [leetcodeUserName]);

	useEffect(() => {
		if (user) {
			if (user.isProfileLocked) {
				setUserProfileLoock(true);
			} else {
				setUserProfileLoock(false);
			}
		}
	}, [user]);

	return (
		<form
			onSubmit={handleSubmit}
			className="profile_form"
		>
			<Box sx={{ width: '100%' }}>
				{userProfileLoock ? (
					<p className="profile-lock-msg">
						*Your profile has been locked. For updating it
						please contact to your Batch manager
					</p>
				) : null}
				{/* *****************************************----------------------------------------------------- Persoanal Information -----------------------------------------------------***************************************** */}

				<ProfileBlock label="Personal Details">
					{/* -------------------***************** First Name ************************------------------- */}
					<FieldBlock
						label={'First Name'}
						id={'fname'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'fname'}
							type={'text'}
							value={fname}
							placeHolder={'Enter First Name'}
							onChange={handleFnameChange}
							required
							showError={fnameError}
							errorMessage={
								'First Name should not be empty'
							}
						/>
					</FieldBlock>

					{/* -------------------***************** Last Name ************************------------------- */}

					<FieldBlock
						label={'Last Name'}
						id={'lname'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'lname'}
							type={'text'}
							value={lname}
							onChange={handleLnameChange}
							placeHolder={'Enter Last Name'}
							required
							showError={lnameError}
							errorMessage={'Last Name should not be empty'}
						/>
					</FieldBlock>

					{/* -------------------***************** Whatsapp Number ************************------------------- */}

					<FieldBlock
						label={`Whatsapp Number`}
						id={`whatsapp_number`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={`whatsapp_number`}
							type={'number'}
							value={whatsappNumber}
							onChange={handleWhatsappNumberChange}
							placeHolder={'Enter Whatsapp Number'}
							required
							showError={whatsappNumberError}
							errorMessage={
								'Whatsapp Number should be a valid number'
							}
						/>
					</FieldBlock>

					{/* -------------------***************** Gender ************************------------------- */}
					<FieldBlock
						label={`Gender`}
						id={`gender`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'gender'}
							options={genderOptions}
							onDropdownChange={handleGenderChange}
							value={gender}
							placeholder={'Select Gender'}
							required
							isMulti={false}
							showError={genderError}
							errorMessage={'Select the gender '}
						/>
					</FieldBlock>
					{/*   -------------------***************** Date of Birth ************************------------------- */}
					<FieldBlock
						label={`Date of Birth`}
						id={`date_of_birth`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<div className="input-container">
							{dateOfBirthError ? (
								<span className="error-message">
									{'Date of Birth should be a valid date'}
								</span>
							) : null}
							<DatePicker
								className="date-picker"
								onChange={handleDateOfBirthChange}
								value={dateOfBirth}
							/>
						</div>
					</FieldBlock>
					{/* -------------------***************** Father's Name ************************------------------- */}
					<FieldBlock
						label={"Father's Name"}
						id={'father_name'}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={'father_name'}
							type={'text'}
							value={fatherName}
							onChange={handlefatherNameChange}
							placeHolder={"Enter Father's Name"}
							required
							showError={fatherNameError}
							errorMessage={
								'Father Name should not be empty'
							}
						/>
					</FieldBlock>
					{/*     -------------------***************** Home Town ************************------------------- */}
					<FieldBlock
						label={`Home Town`}
						id={` hometown`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'hometown'}
							options={stateOptions}
							onDropdownChange={handleHomeTownChange}
							value={hometown}
							placeholder={'Select home town'}
							required
							isMulti={false}
							showError={hometownError}
							errorMessage={'Select the home town '}
						/>
					</FieldBlock>
					{/*      -------------------***************** Languages ************************------------------- */}
					<FieldBlock
						label={`Languages you speak `}
						id={` language`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'language'}
							options={indianLanguageNames}
							onDropdownChange={handleLanguageChange}
							value={languages}
							placeholder={'Select languages'}
							required
							isMulti={true}
							showError={languagesError}
							errorMessage={
								'Select the languages you speak '
							}
						/>
					</FieldBlock>
					{/*      -------------------***************** Parent's Contact Number ************************------------------- */}
					<FieldBlock
						label={`Parent's Contact Number`}
						id={`getParentsContactNumber`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={`getParentsContactNumber`}
							type={'number'}
							value={parentsContactNumber}
							onChange={handleParentContactNumber}
							placeHolder={" Enter Parent's Contact Number"}
							required
							showError={parentsContactNumberError}
							errorMessage={
								'Parents Contact Number should be a valid number'
							}
						/>
					</FieldBlock>
				</ProfileBlock>

				{/* *****************************************----------------------------------------------------- Accademic Details -----------------------------------------------------***************************************** */}

				<ProfileBlock label="Academic Details">
					{/*      -------------------***************** High School (10th) Percentage(%) ************************------------------- */}
					<FieldBlock
						label={`High School (10th) Percentage(%) `}
						id={`highSchoolPercentage`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={`highSchoolPercentage`}
							type={'number'}
							value={highSchoolPercentage}
							onChange={handleHighSchoolPercentage}
							required
							placeHolder={
								'Enter High School (10th) Percentage(%)'
							}
							showError={highSchoolPercentageError}
							errorMessage={
								'High School Percentage should be a valid number'
							}
						/>
					</FieldBlock>

					{/*      -------------------***************** Intermediate (12th / diploma) Percentage ************************------------------- */}
					<FieldBlock
						label={`Intermediate (12th / diploma) Percentage(%) `}
						id={`intermediatePercentage`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={`intermediatePercentage`}
							type={'number'}
							value={IntermediatePercentage}
							onChange={handleIntermediatePercentage}
							required
							placeHolder={
								' Enter Intermediate (12th / diploma) Percentage(%)'
							}
							showError={IntermediatePercentageError}
							errorMessage={
								'Intermediate Percentage should be a valid number'
							}
						/>
					</FieldBlock>

					{/*      -------------------***************** Graduation Year  ************************------------------- */}
					<FieldBlock
						label={`Graduation Year  `}
						id={` graduationYear`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'graduationYear'}
							options={gradeYears}
							onDropdownChange={handleGraduationYear}
							value={graduationYear}
							placeholder={'Select graduation year'}
							required
							isMulti={false}
							showError={graduationYearError}
							errorMessage={'Select the graduation year '}
						/>
					</FieldBlock>

					{/*      -------------------*****************   Graduation Degree  ************************------------------- */}
					<FieldBlock
						label={`Graduation Degree  `}
						id={` graduationDegree`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'graduationDegree'}
							options={bachelorDegreeOptions}
							onDropdownChange={handleDegree}
							value={degree}
							placeholder={'Select graduation degree'}
							required
							isMulti={false}
							showError={degreeError}
							errorMessage={'Select the graduation degree '}
						/>
					</FieldBlock>

					{/*      -------------------*****************   Graduation Percentage   ************************------------------- */}
					<FieldBlock
						label={`Graduation Percentage(%) `}
						id={`graduationPercentage`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputField
							id={`graduationPercentage`}
							type={'number'}
							value={graduationPercentage}
							onChange={handleGraduationPercentage}
							required
							placeHolder={
								' Enter Graduation Percentage(%)'
							}
							showError={graduationPercentageError}
							errorMessage={
								'Graduation Percentage should be a valid number'
							}
						/>
					</FieldBlock>
					{/* >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
					{/*      -------------------*****************   Graduation College   ************************------------------- */}
					<FieldBlock
						label={`Graduation College  `}
						id={`graduationCollege`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<InputSearchBox
							id={'graduationCollege'}
							typeCollege={'UG'}
							collegeError={collegeError}
							placeHolder={'Type here your college name'}
							errorMessage={'Enter Graduation College'}
							collegeNameval={collegeName}
							setCollegeNameval={setCollegeName}
							collegeIdval={collegeId}
							setCollegeIdval={setCollegeId}
						/>
					</FieldBlock>

					{/*      -------------------*****************   Graduation Depertment     ************************------------------- */}
					<FieldBlock
						label={`Department/Stream  `}
						id={`   graduationDepartment`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'graduationDepartment'}
							options={bachelorDepertmentOptions}
							onDropdownChange={handleDepertment}
							value={depertment}
							placeholder={'Select Department/Stream'}
							required
							isMulti={false}
							showError={depertmentError}
							errorMessage={'Select the Department/Stream '}
						/>
					</FieldBlock>
				</ProfileBlock>

				{/* *****************************************----------------------------------------------------- Accademic Details -----------------------------------------------------***************************************** */}

				{user &&
				user.roles[0] === 'data-maincourse-user' ? null : (
					<ProfileBlock label=" Coding Platform Details">
						{/*      -------------------***************** Coding Platform Name    ************************------------------- */}
						<FieldBlock
							label={`Leetcode Profile Name`}
							id={`leetcodeProfileName`}
							required={true}
							isProfileLocked={userProfileLoock}
						>
							<InputButtonField
								id={`leetcodeProfileName`}
								type={'text'}
								value={leetcodeUserNameVal}
								setVal={setleetcodeUserNameval}
								showError={leetcodeUserNameError}
								errorMessage={
									'Enter your leetcode profile name'
								}
								placeHolder={
									'Enter your leetcode profile name'
								}
								icon={<SiLeetcode />}
								btnAction={handleLeetcodeUserNameChange}
								btnTitle={'Submit'}
								onInputChange={handleInputChange}
							/>
						</FieldBlock>

						<FieldBlock
							label={`Github Profile Name`}
							id={`giuthProfileName`}
							required={false}
							isProfileLocked={userProfileLoock}
						>
							<InputField
								disabled={true}
								id={`giuthProfileName`}
								type={'text'}
								value={githubUserName}
								onChange={() => console.log('first')}
								placeHolder={'Enter github profile'}
								showError={false}
								errorMessage={''}
							/>
						</FieldBlock>
					</ProfileBlock>
				)}

				{/* *****************************************----------------------------------------------------- Employ Details -----------------------------------------------------***************************************** */}
				<ProfileBlock label="Employment Details">
					{/*      -------------------*****************   Employment Status   ************************------------------- */}
					<FieldBlock
						label={`Employment Status  `}
						id={`employmentStatus`}
						required={true}
						isProfileLocked={userProfileLoock}
					>
						<CustomDropdown
							id={'employmentStatus'}
							options={WorkingProfessionStatus}
							onDropdownChange={handleEmploymentStatus}
							value={EmploymentStatus}
							placeholder={'Select Employment Status'}
							required
							isMulti={false}
							showError={EmploymentStatusError}
							errorMessage={'Select the Employment Status '}
						/>
					</FieldBlock>

					{/*      -------------------*****************   Employment Type   ************************------------------- */}
					{EmploymentStatus !== null &&
						EmploymentStatus.value ===
							'Working_Professional' && (
							<FieldBlock
								label={`Employment Type`}
								id={`employmentType`}
								required={true}
								isProfileLocked={userProfileLoock}
							>
								<>{console.log('log', EmploymentType)}</>
								<CustomDropdown
									id={'employmentType'}
									options={WorkingProfessionTypes}
									onDropdownChange={handleEmploymentType}
									value={EmploymentType}
									placeholder={'Select Employment Type'}
									required
									isMulti={false}
									showError={EmploymentTypeError}
									errorMessage={
										'Select the Employment Type '
									}
								/>
							</FieldBlock>
						)}
				</ProfileBlock>

				{/* *****************************************-----------------------------------------------------  Post Graduation Details -----------------------------------------------------***************************************** */}
				<ProfileBlock label="Post Graduation Details">
					<Box
						sx={{
							display: 'flex',
							gap: 2,
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'flex-start',
						}}
					>
						<p className="pg__checkbox_container_text ">
							Are you pursuing/have completed your Post
							Graduation?
						</p>
						<FormControlLabel
							control={
								<Radio
									disabled={userProfileLoock}
									checked={pg_Status === true}
									onChange={handleRadioChange}
									value={true}
									name="radio-buttons"
								/>
							}
							label="Yes"
							sx={{
								'& .MuiTypography-root': {
									fontSize: 14,
									fontWeight: 900,
									color: '#666666',
									marginLeft: '5px',
								},
							}}
						/>
						<FormControlLabel
							control={
								<Radio
									disabled={userProfileLoock}
									checked={pg_Status === false}
									onChange={handleRadioChange}
									value={false}
									name="radio-buttons"
								/>
							}
							label="No"
							sx={{
								'& .MuiTypography-root': {
									fontSize: 14,
									fontWeight: 900,
									color: '#666666',
									marginLeft: '5px',
								},
							}}
						/>
					</Box>
					{pg_Status ? (
						<div className="pg__checkbox_container_details">
							{/*      -------------------***************** Post Graduation Year  ************************------------------- */}
							<FieldBlock
								label={`Post Graduation Year`}
								id={`postGraduationYear`}
								required={true}
								isProfileLocked={userProfileLoock}
							>
								<CustomDropdown
									id={'postGraduationYear'}
									options={pg_Years_Options}
									onDropdownChange={handlePg_Year}
									value={pg_Year}
									placeholder={
										'Select post graduation year'
									}
									required
									isMulti={false}
									showError={pg_YearError}
									errorMessage={getPgGradYearError()}
								/>
							</FieldBlock>

							{/*      -------------------*****************   Post Graduation Percentage  ************************------------------- */}
							<FieldBlock
								label={`Post Graduation Percentage(%)  `}
								id={`postGraduationPercentage`}
								required={true}
								isProfileLocked={userProfileLoock}
							>
								<InputField
									id={`postGraduationPercentage`}
									type={'number'}
									value={pg_Percentage}
									onChange={handlePg_Percentage}
									required
									placeHolder={
										'Enter Post Graduation Percentage'
									}
									showError={pg_PercentageError}
									errorMessage={
										'Post Graduation Percentage should be a valid number'
									}
								/>
							</FieldBlock>

							{/* college */}

							{/*      -------------------***************** Post Graduation College ************************------------------- */}
							<FieldBlock
								label={`Post Graduation College    `}
								id={` postGraduationCollege`}
								required={true}
								isProfileLocked={userProfileLoock}
							>
								<InputSearchBox
									id={'postGraduationCollege'}
									typeCollege={'PG'}
									collegeError={pg_CollegeError}
									placeHolder={
										'Type here your college name'
									}
									errorMessage={
										'Enter your post graduation college'
									}
									collegeNameval={pg_CollegeName}
									setCollegeNameval={setPg_CollegeName}
									collegeIdval={pg_CollegeId}
									setCollegeIdval={setPg_CollegeId}
								/>
							</FieldBlock>

							{/*      -------------------***************** Post Graduation Degree/Course ************************------------------- */}
							<FieldBlock
								label={`Post Graduation Degree/Course `}
								id={` postGradutionDegree`}
								required={true}
								isProfileLocked={userProfileLoock}
							>
								<CustomDropdown
									id={'postGradutionDegree'}
									options={postGraduationDegreeOptions}
									onDropdownChange={handlePg_Degree}
									value={pg_Degree}
									placeholder={
										'Select post graduation degree/course'
									}
									required
									isMulti={false}
									showError={pg_DegreeError}
									errorMessage={
										'Select the post graduation degree/course'
									}
								/>
							</FieldBlock>

							{/*      -------------------***************** Post Graduation Depertment/Stream ************************------------------- */}
							<FieldBlock
								label={`Post Graduation Depertment/Stream `}
								id={`postGraduationdepertment`}
								required={true}
								isProfileLocked={userProfileLoock}
							>
								<CustomDropdown
									id={'postGraduationdepertment'}
									options={postGraduationDepartmentOptions}
									onDropdownChange={handlePg_Depertment}
									value={pg_Depertment}
									placeholder={
										'Select post graduation depertment/stream'
									}
									required
									isMulti={false}
									showError={pg_DepertmentError}
									errorMessage={
										'Select the post graduation depertment/stream'
									}
								/>
							</FieldBlock>
						</div>
					) : null}
				</ProfileBlock>
				<FieldBlock
					label={``}
					id={``}
					required={false}
					isProfileLocked={userProfileLoock}
					style={{ backgroundColor: '#fff ' }}
				>
					<input
						type="submit"
						className="submit__button"
					/>
				</FieldBlock>
			</Box>
		</form>
	);
};

export default ProfileTab1;

// const cityOptions = {
// 	'Andhra Pradesh': [
// 		'Anantapur',
// 		'Anantapur',
// 		'Chittoor',
// 		'East Godavari',
// 		'Guntur',
// 		'Krishna',
// 		'Kurnool',
// 		'Nellore',
// 		'Prakasam',
// 		'Srikakulam',
// 		'Visakhapatnam',
// 		'Vizianagaram',
// 		'West Godavari',
// 		'YSR Kadapa',
// 	],
// 	'Arunachal Pradesh': [
// 		'Tawang',
// 		'West Kameng',
// 		'East Kameng',
// 		'Papum Pare',
// 		'Kurung Kumey',
// 		'Kra Daadi',
// 		'Lower Subansiri',
// 		'Upper Subansiri',
// 		'West Siang',
// 		'East Siang',
// 		'Siang',
// 		'Upper Siang',
// 		'Lower Siang',
// 		'Lower Dibang Valley',
// 		'Dibang Valley',
// 		'Anjaw',
// 		'Lohit',
// 		'Namsai',
// 		'Changlang',
// 		'Tirap',
// 		'Longding',
// 	],
// 	Assam: [
// 		'Baksa',
// 		'Barpeta',
// 		'Biswanath',
// 		'Bongaigaon',
// 		'Cachar',
// 		'Charaideo',
// 		'Chirang',
// 		'Darrang',
// 		'Dhemaji',
// 		'Dhubri',
// 		'Dibrugarh',
// 		'Goalpara',
// 		'Golaghat',
// 		'Hailakandi',
// 		'Hojai',
// 		'Jorhat',
// 		'Kamrup Metropolitan',
// 		'Kamrup',
// 		'Karbi Anglong',
// 		'Karimganj',
// 		'Kokrajhar',
// 		'Lakhimpur',
// 		'Majuli',
// 		'Morigaon',
// 		'Nagaon',
// 		'Nalbari',
// 		'Dima Hasao',
// 		'Sivasagar',
// 		'Sonitpur',
// 		'South Salmara-Mankachar',
// 		'Tinsukia',
// 		'Udalguri',
// 		'West Karbi Anglong',
// 	],
// 	Bihar: [
// 		'Araria',
// 		'Arwal',
// 		'Aurangabad',
// 		'Banka',
// 		'Begusarai',
// 		'Bhagalpur',
// 		'Bhojpur',
// 		'Buxar',
// 		'Darbhanga',
// 		'East Champaran (Motihari)',
// 		'Gaya',
// 		'Gopalganj',
// 		'Jamui',
// 		'Jehanabad',
// 		'Kaimur (Bhabua)',
// 		'Katihar',
// 		'Khagaria',
// 		'Kishanganj',
// 		'Lakhisarai',
// 		'Madhepura',
// 		'Madhubani',
// 		'Munger (Monghyr)',
// 		'Muzaffarpur',
// 		'Nalanda',
// 		'Nawada',
// 		'Patna',
// 		'Purnia (Purnea)',
// 		'Rohtas',
// 		'Saharsa',
// 		'Samastipur',
// 		'Saran',
// 		'Sheikhpura',
// 		'Sheohar',
// 		'Sitamarhi',
// 		'Siwan',
// 		'Supaul',
// 		'Vaishali',
// 		'West Champaran',
// 	],
// 	Chandigarh: ['Chandigarh'],
// 	Chhattisgarh: [
// 		'Balod',
// 		'Baloda Bazar',
// 		'Balrampur',
// 		'Bastar',
// 		'Bemetara',
// 		'Bijapur',
// 		'Bilaspur',
// 		'Dantewada (South Bastar)',
// 		'Dhamtari',
// 		'Durg',
// 		'Gariyaband',
// 		'Janjgir-Champa',
// 		'Jashpur',
// 		'Kabirdham (Kawardha)',
// 		'Kanker (North Bastar)',
// 		'Kondagaon',
// 		'Korba',
// 		'Korea (Koriya)',
// 		'Mahasamund',
// 		'Mungeli',
// 		'Narayanpur',
// 		'Raigarh',
// 		'Raipur',
// 		'Rajnandgaon',
// 		'Sukma',
// 		'Surajpur  ',
// 		'Surguja',
// 	],
// 	'Dadra Diu': ['Dadra & Nagar Haveli', 'Daman', 'Diu'],
// 	'Andaman and Nicobar Islands': [
// 		'Andaman And Nicobar Islands',
// 	],
// 	Delhi: [
// 		'Central Delhi',
// 		'East Delhi',
// 		'New Delhi',
// 		'North Delhi',
// 		'North East  Delhi',
// 		'North West  Delhi',
// 		'Shahdara',
// 		'South Delhi',
// 		'South East Delhi',
// 		'South West  Delhi',
// 		'West Delhi',
// 	],
// 	Goa: ['North Goa', 'South Goa'],
// 	Gujarat: [
// 		'Ahmedabad',
// 		'Amreli',
// 		'Anand',
// 		'Aravalli',
// 		'Banaskantha (Palanpur)',
// 		'Bharuch',
// 		'Bhavnagar',
// 		'Botad',
// 		'Chhota Udepur',
// 		'Dahod',
// 		'Dangs (Ahwa)',
// 		'Devbhoomi Dwarka',
// 		'Gandhinagar',
// 		'Gir Somnath',
// 		'Jamnagar',
// 		'Junagadh',
// 		'Kachchh',
// 		'Kheda (Nadiad)',
// 		'Mahisagar',
// 		'Mehsana',
// 		'Morbi',
// 		'Narmada (Rajpipla)',
// 		'Navsari',
// 		'Panchmahal (Godhra)',
// 		'Patan',
// 		'Porbandar',
// 		'Rajkot',
// 		'Sabarkantha (Himmatnagar)',
// 		'Surat',
// 		'Surendranagar',
// 		'Tapi (Vyara)',
// 		'Vadodara',
// 		'Valsad',
// 	],
// 	Haryana: [
// 		'Ambala',
// 		'Bhiwani',
// 		'Charkhi Dadri',
// 		'Faridabad',
// 		'Fatehabad',
// 		'Gurgaon',
// 		'Hisar',
// 		'Jhajjar',
// 		'Jind',
// 		'Kaithal',
// 		'Karnal',
// 		'Kurukshetra',
// 		'Mahendragarh',
// 		'Mewat',
// 		'Palwal',
// 		'Panchkula',
// 		'Panipat',
// 		'Rewari',
// 		'Rohtak',
// 		'Sirsa',
// 		'Sonipat',
// 		'Yamunanagar',
// 	],
// 	'Himachal Pradesh': [
// 		'Bilaspur',
// 		'Chamba',
// 		'Hamirpur',
// 		'Kangra',
// 		'Kinnaur',
// 		'Kullu',
// 		'Lahaul &amp; Spiti',
// 		'Mandi',
// 		'Shimla',
// 		'Sirmaur (Sirmour)',
// 		'Solan',
// 		'Una',
// 	],
// 	'Jammu and Kashmir': [
// 		'Anantnag',
// 		'Bandipore',
// 		'Baramulla',
// 		'Budgam',
// 		'Doda',
// 		'Ganderbal',
// 		'Jammu',
// 		'Kargil',
// 		'Kathua',
// 		'Kishtwar',
// 		'Kulgam',
// 		'Kupwara',
// 		'Leh',
// 		'Poonch',
// 		'Pulwama',
// 		'Rajouri',
// 		'Ramban',
// 		'Reasi',
// 		'Samba',
// 		'Shopian',
// 		'Srinagar',
// 		'Udhampur',
// 	],
// 	Jharkhand: [
// 		'Bokaro',
// 		'Chatra',
// 		'Deoghar',
// 		'Dhanbad',
// 		'Dumka',
// 		'East Singhbhum',
// 		'Garhwa',
// 		'Giridih',
// 		'Godda',
// 		'Gumla',
// 		'Hazaribag',
// 		'Jamtara',
// 		'Khunti',
// 		'Koderma',
// 		'Latehar',
// 		'Lohardaga',
// 		'Pakur',
// 		'Palamu',
// 		'Ramgarh',
// 		'Ranchi',
// 		'Sahibganj',
// 		'Seraikela-Kharsawan',
// 		'Simdega',
// 		'West Singhbhum',
// 	],
// 	Karnataka: [
// 		'Bagalkot',
// 		'Ballari (Bellary)',
// 		'Belagavi (Belgaum)',
// 		'Bengaluru (Bangalore) Rural',
// 		'Bengaluru (Bangalore) Urban',
// 		'Bidar',
// 		'Chamarajanagar',
// 		'Chikballapur',
// 		'Chikkamagaluru (Chikmagalur)',
// 		'Chitradurga',
// 		'Dakshina Kannada',
// 		'Davangere',
// 		'Dharwad',
// 		'Gadag',
// 		'Hassan',
// 		'Haveri',
// 		'Kalaburagi (Gulbarga)',
// 		'Kodagu',
// 		'Kolar',
// 		'Koppal',
// 		'Mandya',
// 		'Mysuru (Mysore)',
// 		'Raichur',
// 		'Ramanagara',
// 		'Shivamogga (Shimoga)',
// 		'Tumakuru (Tumkur)',
// 		'Udupi',
// 		'Uttara Kannada (Karwar)',
// 		'Vijayapura (Bijapur)',
// 		'Yadgir',
// 	],
// 	Ladakh: ['Leh', 'Ladakh'],
// 	Kerala: [
// 		'Alappuzha',
// 		'Ernakulam',
// 		'Idukki',
// 		'Kannur',
// 		'Kasaragod',
// 		'Kollam',
// 		'Kottayam',
// 		'Kozhikode',
// 		'Malappuram',
// 		'Palakkad',
// 		'Pathanamthitta',
// 		'Thiruvananthapuram',
// 		'Thrissur',
// 		'Wayanad',
// 	],
// 	Lakshadweep: [
// 		'Agatti',
// 		'Amini',
// 		'Androth',
// 		'Bithra',
// 		'Chethlath',
// 		'Kavaratti',
// 		'Kadmath',
// 		'Kalpeni',
// 		'Kilthan',
// 		'Minicoy',
// 	],
// 	'Madhya Pradesh': [
// 		'Agar Malwa',
// 		'Alirajpur',
// 		'Anuppur',
// 		'Ashoknagar',
// 		'Balaghat',
// 		'Barwani',
// 		'Betul',
// 		'Bhind',
// 		'Bhopal',
// 		'Burhanpur',
// 		'Chhatarpur',
// 		'Chhindwara',
// 		'Damoh',
// 		'Datia',
// 		'Dewas',
// 		'Dhar',
// 		'Dindori',
// 		'Guna',
// 		'Gwalior',
// 		'Harda',
// 		'Hoshangabad',
// 		'Indore',
// 		'Jabalpur',
// 		'Jhabua',
// 		'Katni',
// 		'Khandwa',
// 		'Khargone',
// 		'Mandla',
// 		'Mandsaur',
// 		'Morena',
// 		'Narsinghpur',
// 		'Neemuch',
// 		'Panna',
// 		'Raisen',
// 		'Rajgarh',
// 		'Ratlam',
// 		'Rewa',
// 		'Sagar',
// 		'Satna',
// 		'Sehore',
// 		'Seoni',
// 		'Shahdol',
// 		'Shajapur',
// 		'Sheopur',
// 		'Shivpuri',
// 		'Sidhi',
// 		'Singrauli',
// 		'Tikamgarh',
// 		'Ujjain',
// 		'Umaria',
// 		'Vidisha',
// 	],
// 	Maharashtra: [
// 		'Ahmednagar',
// 		'Akola',
// 		'Amravati',
// 		'Aurangabad',
// 		'Beed',
// 		'Bhandara',
// 		'Buldhana',
// 		'Chandrapur',
// 		'Dhule',
// 		'Gadchiroli',
// 		'Gondia',
// 		'Hingoli',
// 		'Jalgaon',
// 		'Jalna',
// 		'Kolhapur',
// 		'Latur',
// 		'Mumbai City',
// 		'Mumbai Suburban',
// 		'Nagpur',
// 		'Nanded',
// 		'Nandurbar',
// 		'Nashik',
// 		'Osmanabad',
// 		'Palghar',
// 		'Parbhani',
// 		'Pune',
// 		'Raigad',
// 		'Ratnagiri',
// 		'Sangli',
// 		'Satara',
// 		'Sindhudurg',
// 		'Solapur',
// 		'Thane',
// 		'Wardha',
// 		'Washim',
// 		'Yavatmal',
// 	],
// 	Manipur: [
// 		'Bishnupur',
// 		'Chandel',
// 		'Churachandpur',
// 		'Imphal East',
// 		'Imphal West',
// 		'Jiribam',
// 		'Kakching',
// 		'Kamjong',
// 		'Kangpokpi',
// 		'Noney',
// 		'Pherzawl',
// 		'Senapati',
// 		'Tamenglong',
// 		'Tengnoupal',
// 		'Thoubal',
// 		'Ukhrul',
// 	],
// 	Meghalaya: [
// 		'East Garo Hills',
// 		'East Jaintia Hills',
// 		'East Khasi Hills',
// 		'North Garo Hills',
// 		'Ri Bhoi',
// 		'South Garo Hills',
// 		'South West Garo Hills ',
// 		'South West Khasi Hills',
// 		'West Garo Hills',
// 		'West Jaintia Hills',
// 		'West Khasi Hills',
// 	],
// 	Mizoram: [
// 		'Aizawl',
// 		'Champhai',
// 		'Kolasib',
// 		'Lawngtlai',
// 		'Lunglei',
// 		'Mamit',
// 		'Saiha',
// 		'Serchhip',
// 	],
// 	Nagaland: [
// 		'Dimapur',
// 		'Kiphire',
// 		'Kohima',
// 		'Longleng',
// 		'Mokokchung',
// 		'Mon',
// 		'Peren',
// 		'Phek',
// 		'Tuensang',
// 		'Wokha',
// 		'Zunheboto',
// 	],
// 	Odisha: [
// 		'Angul',
// 		'Balangir',
// 		'Balasore',
// 		'Bargarh',
// 		'Bhadrak',
// 		'Boudh',
// 		'Cuttack',
// 		'Deogarh',
// 		'Dhenkanal',
// 		'Gajapati',
// 		'Ganjam',
// 		'Jagatsinghapur',
// 		'Jajpur',
// 		'Jharsuguda',
// 		'Kalahandi',
// 		'Kandhamal',
// 		'Kendrapara',
// 		'Kendujhar (Keonjhar)',
// 		'Khordha',
// 		'Koraput',
// 		'Malkangiri',
// 		'Mayurbhanj',
// 		'Nabarangpur',
// 		'Nayagarh',
// 		'Nuapada',
// 		'Puri',
// 		'Rayagada',
// 		'Sambalpur',
// 		'Sonepur',
// 		'Sundargarh',
// 	],
// 	Puducherry: ['Karaikal', 'Mahe', 'Pondicherry', 'Yanam'],
// 	Punjab: [
// 		'Amritsar',
// 		'Barnala',
// 		'Bathinda',
// 		'Faridkot',
// 		'Fatehgarh Sahib',
// 		'Fazilka',
// 		'Ferozepur',
// 		'Gurdaspur',
// 		'Hoshiarpur',
// 		'Jalandhar',
// 		'Kapurthala',
// 		'Ludhiana',
// 		'Mansa',
// 		'Moga',
// 		'Muktsar',
// 		'Nawanshahr (Shahid Bhagat Singh Nagar)',
// 		'Pathankot',
// 		'Patiala',
// 		'Rupnagar',
// 		'Sahibzada Ajit Singh Nagar (Mohali)',
// 		'Sangrur',
// 		'Tarn Taran',
// 	],
// 	Rajasthan: [
// 		'Ajmer',
// 		'Alwar',
// 		'Banswara',
// 		'Baran',
// 		'Barmer',
// 		'Bharatpur',
// 		'Bhilwara',
// 		'Bikaner',
// 		'Bundi',
// 		'Chittorgarh',
// 		'Churu',
// 		'Dausa',
// 		'Dholpur',
// 		'Dungarpur',
// 		'Hanumangarh',
// 		'Jaipur',
// 		'Jaisalmer',
// 		'Jalore',
// 		'Jhalawar',
// 		'Jhunjhunu',
// 		'Jodhpur',
// 		'Karauli',
// 		'Kota',
// 		'Nagaur',
// 		'Pali',
// 		'Pratapgarh',
// 		'Rajsamand',
// 		'Sawai Madhopur',
// 		'Sikar',
// 		'Sirohi',
// 		'Sri Ganganagar',
// 		'Tonk',
// 		'Udaipur',
// 	],
// 	Sikkim: [
// 		'East Sikkim',
// 		'North Sikkim',
// 		'South Sikkim',
// 		'West Sikkim',
// 	],
// 	'Tamil Nadu': [
// 		'Ariyalur',
// 		'Chennai',
// 		'Coimbatore',
// 		'Cuddalore',
// 		'Dharmapuri',
// 		'Dindigul',
// 		'Erode',
// 		'Kanchipuram',
// 		'Kanyakumari',
// 		'Karur',
// 		'Krishnagiri',
// 		'Madurai',
// 		'Nagapattinam',
// 		'Namakkal',
// 		'Nilgiris',
// 		'Perambalur',
// 		'Pudukkottai',
// 		'Ramanathapuram',
// 		'Salem',
// 		'Sivaganga',
// 		'Thanjavur',
// 		'Theni',
// 		'Thoothukudi (Tuticorin)',
// 		'Tiruchirappalli',
// 		'Tirunelveli',
// 		'Tiruppur',
// 		'Tiruvallur',
// 		'Tiruvannamalai',
// 		'Tiruvarur',
// 		'Vellore',
// 		'Viluppuram',
// 		'Virudhunagar',
// 	],
// 	Telangana: [
// 		'Adilabad',
// 		'Bhadradri Kothagudem',
// 		'Hyderabad',
// 		'Jagtial',
// 		'Jangaon',
// 		'Jayashankar Bhoopalpally',
// 		'Jogulamba Gadwal',
// 		'Kamareddy',
// 		'Karimnagar',
// 		'Khammam',
// 		'Komaram Bheem Asifabad',
// 		'Mahabubabad',
// 		'Mahabubnagar',
// 		'Mancherial',
// 		'Medak',
// 		'Medchal',
// 		'Nagarkurnool',
// 		'Nalgonda',
// 		'Nirmal',
// 		'Nizamabad',
// 		'Peddapalli',
// 		'Rajanna Sircilla',
// 		'Rangareddy',
// 		'Sangareddy',
// 		'Siddipet',
// 		'Suryapet',
// 		'Vikarabad',
// 		'Wanaparthy',
// 		'Warangal (Rural)',
// 		'Warangal (Urban)',
// 		'Yadadri Bhuvanagiri',
// 	],
// 	Tripura: [
// 		'Dhalai',
// 		'Gomati',
// 		'Khowai',
// 		'North Tripura',
// 		'Sepahijala',
// 		'South Tripura',
// 		'Unakoti',
// 		'West Tripura',
// 	],
// 	Uttarakhand: [
// 		'Almora',
// 		'Bageshwar',
// 		'Chamoli',
// 		'Champawat',
// 		'Dehradun',
// 		'Haridwar',
// 		'Nainital',
// 		'Pauri Garhwal',
// 		'Pithoragarh',
// 		'Rudraprayag',
// 		'Tehri Garhwal',
// 		'Udham Singh Nagar',
// 		'Uttarkashi',
// 	],
// 	'Uttar Pradesh': [
// 		'Agra',
// 		'Aligarh',
// 		'Allahabad',
// 		'Ambedkar Nagar',
// 		'Amethi (Chatrapati Sahuji Mahraj Nagar)',
// 		'Amroha (J.P. Nagar)',
// 		'Auraiya',
// 		'Azamgarh',
// 		'Baghpat',
// 		'Bahraich',
// 		'Ballia',
// 		'Balrampur',
// 		'Banda',
// 		'Barabanki',
// 		'Bareilly',
// 		'Basti',
// 		'Bhadohi',
// 		'Bijnor',
// 		'Budaun',
// 		'Bulandshahr',
// 		'Chandauli',
// 		'Chitrakoot',
// 		'Deoria',
// 		'Etah',
// 		'Etawah',
// 		'Faizabad',
// 		'Farrukhabad',
// 		'Fatehpur',
// 		'Firozabad',
// 		'Gautam Buddha Nagar',
// 		'Ghaziabad',
// 		'Ghazipur',
// 		'Gonda',
// 		'Gorakhpur',
// 		'Hamirpur',
// 		'Hapur (Panchsheel Nagar)',
// 		'Hardoi',
// 		'Hathras',
// 		'Jalaun',
// 		'Jaunpur',
// 		'Jhansi',
// 		'Kannauj',
// 		'Kanpur Dehat',
// 		'Kanpur Nagar',
// 		'Kanshiram Nagar (Kasganj)',
// 		'Kaushambi',
// 		'Kushinagar (Padrauna)',
// 		'Lakhimpur - Kheri',
// 		'Lalitpur',
// 		'Lucknow',
// 		'Maharajganj',
// 		'Mahoba',
// 		'Mainpuri',
// 		'Mathura',
// 		'Mau',
// 		'Meerut',
// 		'Mirzapur',
// 		'Moradabad',
// 		'Muzaffarnagar',
// 		'Pilibhit',
// 		'Pratapgarh',
// 		'RaeBareli',
// 		'Rampur',
// 		'Saharanpur',
// 		'Sambhal (Bhim Nagar)',
// 		'Sant Kabir Nagar',
// 		'Shahjahanpur',
// 		'Shamali (Prabuddh Nagar)',
// 		'Shravasti',
// 		'Siddharth Nagar',
// 		'Sitapur',
// 		'Sonbhadra',
// 		'Sultanpur',
// 		'Unnao',
// 		'Varanasi',
// 	],
// 	'West Bengal': [
// 		'Alipurduar',
// 		'Bankura',
// 		'Birbhum',
// 		'Burdwan (Bardhaman)',
// 		'Cooch Behar',
// 		'Dakshin Dinajpur (South Dinajpur)',
// 		'Darjeeling',
// 		'Hooghly',
// 		'Howrah',
// 		'Jalpaiguri',
// 		'Kalimpong',
// 		'Kolkata',
// 		'Malda',
// 		'Murshidabad',
// 		'Nadia',
// 		'North 24 Parganas',
// 		'Paschim Medinipur (West Medinipur)',
// 		'Purba Medinipur (East Medinipur)',
// 		'Purulia',
// 		'South 24 Parganas',
// 		'Uttar Dinajpur (North Dinajpur)',
// 	],
// };
