import { Fragment, useEffect, useState } from 'react';
import {
	IoIosRadioButtonOff,
	IoIosRadioButtonOn,
} from 'react-icons/io';
import AccioButton from '../../../../components/elements/Button';
import { MarkDown } from '../../../../components/shared/MarkDown';

interface PropsInterface {
	options: Array<string>;
	onSaveQuestion: (answer: Array<string>) => void;
	onSubmitQuestion?: (answer: Array<string>) => void;
	response?: Array<string>;
	correct?: Array<string>;
	submitted: boolean;
	explanation?: string;
	isLoading?: boolean;
}

const SingleSelectMCQ = (props: PropsInterface) => {
	const [answer, setAnswer] = useState<Array<string>>([]);

	const saveAnswer = (option: string) => {
		const temp = [option];
		setAnswer(temp);
		props.onSaveQuestion(temp);
	};

	useEffect(() => {
		if (props.response) setAnswer(props.response);
	}, [props.response]);

	return (
		<div className={'options-content'}>
			<div className={'options'}>
				<ul>
					{props.options.map((el, index) => {
						const isSelected = answer.includes(el);
						return (
							<li
								key={index.toString() + el}
								onClick={() => {
									if (!props.submitted) {
										saveAnswer(el);
									}
								}}
								className={`option ${
									isSelected ? 'option-selected' : ''
								} ${
									props.submitted
										? props.correct?.includes(el)
											? 'correct'
											: isSelected
											? 'incorrect'
											: ''
										: ''
								}`}
							>
								{/* {"("}
{index+1}
{")"} */}
								{isSelected ? (
									<IoIosRadioButtonOn size={20} />
								) : (
									<IoIosRadioButtonOff size={20} />
								)}
								<div className={'mcq-option'}>
									<MarkDown>{el}</MarkDown>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			{props.onSubmitQuestion ? (
				<AccioButton
					title={'Submit'}
					disabled={props.submitted || answer.length === 0}
					action={() => {
						if (props.onSubmitQuestion)
							props.onSubmitQuestion(answer);
					}}
					className={'save-btn'}
					// isLoading={props.isLoading}
				/>
			) : null}

			{props.submitted ? (
				<Fragment>
					<div className={'options'}>
						<h3>Correct Answer</h3>
						<ul className={'option correct'}>
							{props.correct?.map((el) => (
								<li key={el}>{el}</li>
							))}
						</ul>
					</div>
					<div>
						{props.explanation &&
						props.explanation.length > 1 ? (
							<h3>Explanation</h3>
						) : null}
						{props.explanation &&
						props.explanation.length > 1 ? (
							<p>
								<MarkDown>{props.explanation}</MarkDown>
							</p>
						) : null}
					</div>
				</Fragment>
			) : null}
		</div>
	);
};

export default SingleSelectMCQ;
