import Tooltip from '@mui/joy/Tooltip';
import { BsFillLightningFill } from 'react-icons/bs';
import { CgUserlane } from 'react-icons/cg';
import { FiHelpCircle } from 'react-icons/fi';
import barsPurple from '../../../assets/images/bars-purple.svg';
import bars from '../../../assets/images/bars.svg';
import { useAppSelector } from '../../../redux/store';
export const ActivityCard = () => {
	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	if (performanceData.data) {
		const getText = (streak: number) => {
			let message = '';
			if (streak === -1) {
				message = `Your streak was broken, and please keep solving questions consistently`;
			} else {
				message = `The highest streak that you ever achieved was ${streak}. Keep Solving!`;
			}
			return message;
		};

		return (
			<div className="profile-card activity-card">
				<div className="sub-card">
					<div className="heading-flex">
						<CgUserlane style={{ color: '#2F80ED' }} />
						<div className="heading">Activity</div>
					</div>
					<div className="heading-flex">
						<img
							src={bars}
							className="bar-icon"
							alt="bar-icon"
						/>
						<h1 className="big-heading">
							{performanceData.data.totalActivity}
							<span className="small-text">Days</span>
						</h1>
					</div>
					<div className="based-on-div">
						<p className="based-on">BASED ON</p>
						<p className="text">
							Days of over 1.5 hours of activity on the
							platform.
						</p>
					</div>
				</div>
				<div className="sub-card sub-left">
					<div className="heading-flex">
						<BsFillLightningFill
							style={{ color: '#444CE7' }}
						/>
						<div className="heading">Streak</div>
						<Tooltip
							title={
								getText(performanceData.data.streak) ?? ''
							}
							variant="solid"
						>
							<div className="help-circle">
								<FiHelpCircle
									style={{ color: '#98A2B3' }}
								/>
							</div>
						</Tooltip>
					</div>
					<div className="heading-flex">
						<img
							src={barsPurple}
							className="bar-icon"
							alt="bar-icon"
						/>
						<h1 className="big-heading">
							{performanceData.data.streak}
							<span className="small-text">Days</span>
						</h1>
					</div>
					<div className="based-on-div">
						<p className="based-on">BASED ON</p>
						<p className="text">
							Most days of continuous question solving ever.
						</p>
					</div>
				</div>
			</div>
		);
	} else return null;
};
