import { Modal } from '@mui/material';
import React from 'react';
// import '../styles/recordings1v1.scss';
import CloseIcon from '@mui/icons-material/Close';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
// import '../styles/video-carousel.scss';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { VimeoVideoPlayer } from './shared/VimeoVideoPlayer';
const VideoModalCarousel = ({
	handleClose,
	currentRecordings,
}: {
	handleClose: any;
	currentRecordings: any;
}) => {
	const [activeIndex, setActiveIndex] = React.useState(0);
	const [open, setOpen] = React.useState(false);
	return (
		<>
			<OwlCarousel
				items={3}
				className="owl-theme"
				nav
				dots={false}
				// autoWidth
				// center

				// events={events}
			>
				{currentRecordings.map(
					(recording: any, ind: number) => (
						<div
							key={ind}
							className="video-item"
							onClick={() => {
								setActiveIndex(
									currentRecordings.indexOf(recording)
								);
								setOpen(true);
							}}
						>
							<img
								style={{ height: '95%' }}
								src={recording.thumbnail}
							/>
							<div className="overlay">
								<BsFillPlayCircleFill />
							</div>
						</div>
					)
				)}
			</OwlCarousel>

			<Modal
				open={open}
				onClose={() => setOpen(false)}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div
					className={'modal recordings1v1Modal'}
					style={{ overflow: 'hidden', padding: '10px' }}
				>
					<button
						className={'close-btn'}
						style={{
							backgroundColor: 'white',
							paddingBottom: '0px',
							color: 'grey',
						}}
						onClick={() => setOpen(false)}
					>
						<CloseIcon />
					</button>
					<h2 style={{ margin: '0px 0px 10px 0px' }}>
						{currentRecordings[activeIndex].title}
					</h2>
					<OwlCarousel
						className="owl-theme"
						id="owl-carousel-projects"
						// style={{ height: '100%' }}
						items={1}
						// nav
						dots={false}
					>
						{
							<div className="item">
								<VimeoVideoPlayer
									id={currentRecordings[activeIndex].url}
									options={{
										url: currentRecordings[activeIndex].url,
										width: 640,
										autoplay: true,
										controls: true,
										pip: true,
										transparent: true,
										title: false,
									}}
									style={{
										position: 'relative',
										display: 'flex',
										justifyContent: 'center',
										marginInline: 'auto',
										borderRadius: '5px',
										overflow: 'hidden',
										background: 'black',
										width: '640px',
									}}
								/>
							</div>
						}
					</OwlCarousel>
				</div>
			</Modal>
		</>
	);
};

export default VideoModalCarousel;
