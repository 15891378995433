import React from 'react';

const ReferralStyledCard = ({
	color,
	srcIcon,
	t1,
	t2,
	t3,
	t4,
}: any) => {
	return (
		<div
			className={`re-referral-benefits-card re-${color}-card`}
		>
			<div
				className={`re-referral-benefits-card-title re-${color}-title`}
			>
				{t1}
			</div>
			<div
				className={`re-referral-benefits-card-subtitle re-${color}-subtitle`}
			>
				{t2} <br />
				<span
					className={`re-subtitle-highlight re-${color}-highlight`}
				>
					{t3}
				</span>
				<img
					src={srcIcon}
					alt="₹ Bag"
					className="re-ruppee-bag"
				/>
				<br />
				{t4}
			</div>
		</div>
	);
};

export default ReferralStyledCard;
