import MentorTextFeedback from './feedbackModal/MentorTextFeedback';
import SectionWiseAnalysis from './feedbackModal/SectionWiseAnalysis';
import SessionFeedbackAccordion from './feedbackModal/SessionFeedbackAccrodion';

const SessionFeedback = ({ currentFeedback }: any) => {
	// Function to filter the keys in a feedback section
	const filterFeedbackSection = (sectionFeedback: any) => {
		return Object.fromEntries(
			Object.entries(sectionFeedback).filter(
				([key]) =>
					!['Score', 'Difficulty', 'Topics'].includes(
						key.trim()
					)
			)
		);
	};

	const renderCodingQuestions = () => {
		const codingQuestions = Object.entries(currentFeedback)
			.filter(([key]) => key.startsWith('Coding Question'))
			.map(([key, value]) => {
				const {
					Score,
					Difficulty,
					Topics,
					...questionData
				}: any = value;

				return (
					<SessionFeedbackAccordion
						key={key}
						title={`Coding Question - ${key.split(' ')[2]}`}
						typeOfQuestion={Difficulty}
						QuestionTopics={Topics}
						score={Score ?? null}
						typeOfFeedback={'Feedback Parameter :'}
						allFeedbackData={questionData}
					/>
				);
			});

		return codingQuestions;
	};

	const renderPracticalQuestions = () => {
		const practicalQuestions = Object.entries(
			currentFeedback
		)
			.filter(([key]) =>
				key.startsWith('Practical Question')
			)
			.map(([key, value]) => {
				const {
					Score,
					Difficulty,
					Topics,
					...questionData
				}: any = value;
				return (
					<SessionFeedbackAccordion
						key={key}
						title={`Practical Question - ${
							key.split(' ')[2]
						}`}
						typeOfQuestion={null}
						QuestionTopics={null}
						score={Score ?? null}
						typeOfFeedback={'Feedback Parameter :'}
						allFeedbackData={questionData}
					/>
				);
			});

		return practicalQuestions;
	};

	const isMockConvertedToMentorship =
		currentFeedback[
			"Mark this session as a Mentorship session(If a student's performance was very bad and you end up solving his/her doubts)"
		] === 'Yes' || false;

	// 	"Cheating Counter": {
	// 		"Did you find the student performing any misconduct (cheating) during the entire interview process?": "No"
	// },

	const isCheatingFound =
		currentFeedback['Cheating Counter']?.[
			'Did you find the student performing any misconduct (cheating) during the entire interview process?'
		] === 'Yes' || false;

	return (
		<div className="feedback-modal-wrapper-main">
			{isCheatingFound ? (
				<p className="text-cheating-found-warning">
					Warning : Cheating found during the session
				</p>
			) : null}

			{!currentFeedback?.isMentorship &&
			!isMockConvertedToMentorship ? (
				<SectionWiseAnalysis data={currentFeedback} />
			) : null}

			<MentorTextFeedback
				feedBackTextdata={
					currentFeedback?.['Feedback for the student'] ||
					currentFeedback?.['Feedback for student']
				}
				isMockConvertedToMentorship={
					isMockConvertedToMentorship
				}
			/>

			{!currentFeedback?.isMentorship &&
			!isMockConvertedToMentorship ? (
				<div className="section-wise-analysis-title-section">
					Section wise analysis
				</div>
			) : null}

			{!currentFeedback?.isMentorship &&
			!isMockConvertedToMentorship ? (
				<div className="section-wise-analysis-content-section">
					{/* ---------- 1. Technical Introduction ----------- */}
					{currentFeedback['Technical Introduction'] ? (
						<SessionFeedbackAccordion
							title={'Technical Introduction'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={
								currentFeedback['SS']?.['Introduction'] ??
								null
							}
							typeOfFeedback={null}
							allFeedbackData={
								currentFeedback['Technical Introduction']
							}
						/>
					) : null}

					{/* ---------- 2. Project  ----------- */}
					{currentFeedback['Project'] ? (
						<SessionFeedbackAccordion
							title={'Project'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={
								currentFeedback['SS']?.['Project'] ?? null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Project']
							)}
						/>
					) : null}

					{/* ---------- 3.Major Project  ----------- */}
					{currentFeedback['Major Project'] ? (
						<SessionFeedbackAccordion
							title={'Major Project'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={
								currentFeedback['SS']?.['Major Project'] ??
								null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Major Project']
							)}
						/>
					) : null}

					{/* ---------- 4.Minor Project  ----------- */}
					{currentFeedback['Minor Project'] ? (
						<SessionFeedbackAccordion
							title={'Minor Project'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={
								currentFeedback['SS']?.['Minor Project'] ??
								null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Minor Project']
							)}
						/>
					) : null}

					{/* ---------- 5. Resume  ----------- */}
					{currentFeedback['Resume'] ? (
						<SessionFeedbackAccordion
							title={'Resume'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={
								currentFeedback['SS']?.['Resume'] ?? null
							}
							typeOfFeedback={'Feedback Parameter :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Resume']
							)}
						/>
					) : null}

					{/* ---------- 6. Practical Questions ----------- */}
					{renderPracticalQuestions()}

					{/* ---------- 7. Conceptual Questions ----------- */}
					{currentFeedback['Conceptual'] ? (
						<SessionFeedbackAccordion
							title={'Conceptual'}
							typeOfQuestion={null}
							QuestionTopics={
								currentFeedback['Conceptual']?.['Topics'] ??
								null
							}
							score={
								currentFeedback['SS']?.['Conceptual'] ??
								null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Conceptual']
							)}
						/>
					) : null}

					{/* ---------- 8. Coding Questions ----------- */}
					{renderCodingQuestions()}

					{/* ---------- 7. Aptitude Questions ----------- */}
					{currentFeedback['Aptitude'] ? (
						<SessionFeedbackAccordion
							title={'Aptitude'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={
								currentFeedback['Aptitude Score'] ?? null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Aptitude']
							)}
						/>
					) : null}

					{/* ---------- 9. Theory Questions ----------- */}
					{currentFeedback['Theory'] ? (
						<SessionFeedbackAccordion
							title={'Theory'}
							typeOfQuestion={null}
							QuestionTopics={
								currentFeedback['Theory']?.['Topics'] ??
								null
							}
							score={
								currentFeedback['SS']?.['Theory'] ?? null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Theory']
							)}
						/>
					) : null}

					{currentFeedback['Theory Question'] ? (
						<SessionFeedbackAccordion
							title={'Theory'}
							typeOfQuestion={null}
							QuestionTopics={
								currentFeedback['Theory']?.['Topics'] ??
								null
							}
							score={
								currentFeedback['SS']?.['Theory'] ?? null
							}
							typeOfFeedback={'Question Asked :'}
							allFeedbackData={filterFeedbackSection(
								currentFeedback['Theory Question']
							)}
						/>
					) : null}

					{/* ---------- 10. Behavioral ----------- */}
					{currentFeedback['Behavioural'] ? (
						<SessionFeedbackAccordion
							title={'Behavioral'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={null}
							typeOfFeedback={'Feedback Parameter :'}
							allFeedbackData={
								currentFeedback['Behavioural']
							}
						/>
					) : null}

					{/* ---------- 11. Company Specific ----------- */}
					{currentFeedback['Company Specific'] ? (
						<SessionFeedbackAccordion
							title={'Company Specific'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={null}
							typeOfFeedback={'Feedback Parameter :'}
							allFeedbackData={
								currentFeedback['Company Specific']
							}
						/>
					) : null}

					{/* ---------- 12. Background and Experience  ----------- */}
					{currentFeedback['Background and Experience'] ? (
						<SessionFeedbackAccordion
							title={'Background and Experience'}
							typeOfQuestion={null}
							QuestionTopics={null}
							score={null}
							typeOfFeedback={'Feedback Parameter :'}
							allFeedbackData={
								currentFeedback['Background and Experience']
							}
						/>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default SessionFeedback;
