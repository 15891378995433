import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal } from '@mui/material';
import React from 'react';

interface RightAlignModalProps {
	open: boolean;
	handleOpen: any;
	isOpened: boolean;
	setIsOpened: any;
	setOpen: any;
	children: React.ReactNode;
	handleClose: () => void; // New prop for handling close action
}

// const handleClose = () => {
// 	setIsOpened(false);
// 	setTimeout(() => {
// 		setOpen(false);
// 	}, 1000);

const RightAlignModal = ({
	open,
	setOpen,
	handleOpen,
	isOpened,
	setIsOpened,
	children,
	handleClose,
}: RightAlignModalProps) => {
	console.log('isOpened', open, isOpened);
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<Box
					sx={{
						width: '50%',
						p: 2,
						height: '100vh',
					}}
					className={`session-details-slider ${
						isOpened ? 'entry' : 'exit'
					}`}
				>
					<div className="session-details-container">
						<IconButton
							className="close-btn"
							sx={{
								position: 'absolute',
								top: 8,
								left: 8,
								transform: 'scale(1.2)',
							}}
							onClick={handleClose}
						>
							<Close />
						</IconButton>
						<div className="children-container">
							{children}
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default RightAlignModal;
