import type { ReactNode } from 'react';

interface TextInterface {
	variant?:
		| 'b1'
		| 'b2'
		| 'b3'
		| 'b4'
		| 'epic'
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'l1'
		| 'l2'
		| 'l3'
		| 'l4'
		| 'l5';
	children: ReactNode;
	className?: string;
}

const Text: React.FC<TextInterface> = ({
	variant = 'b1',
	children,
	className,
}) => {
	const getComponentByVariant = (): JSX.Element => {
		switch (variant) {
			case 'epic':
			case 'h1':
			case 'h2':
			case 'h3':
			case 'h4':
			case 'h5':
				return (
					<h1
						className={`typography-${variant} ${
							className || ''
						}`}
					>
						{children}
					</h1>
				);

			case 'b1':
			case 'b2':
			case 'b3':
			case 'b4':
				return (
					<p
						className={`typography-${variant} ${
							className || ''
						}`}
					>
						{children}
					</p>
				);

			case 'l1':
			case 'l2':
			case 'l3':
			case 'l4':
			case 'l5':
				return (
					<span
						className={`typography-${variant} ${
							className || ''
						}`}
					>
						{children}
					</span>
				);

			default:
				return (
					<p
						className={`typography-default ${
							className || ''
						}`}
					>
						{children}
					</p>
				);
		}
	};

	return getComponentByVariant();
};

export default Text;
