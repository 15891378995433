import AliceCarousel from 'react-alice-carousel';

const Announcements = ({ slides }: any) => {
	const responsive = {
		0: { items: 1 },
		568: { items: 1 },
		1024: { items: 1 },
	};

	return (
		<div className="announcement-container">
			{slides != null && (
				<AliceCarousel
					mouseTracking={true}
					items={slides}
					responsive={responsive}
					// autoPlay={true}
					// autoPlayInterval={3000}
					infinite={true}
					disableDotsControls={true}
					// renderPrevButton={() => (
					// 	<div className="alice-left">
					// 		<AiOutlineArrowLeft />
					// 	</div>
					// )}
					// renderNextButton={() => (
					// 	<div className="alice-right">
					// 		<AiOutlineArrowRight />
					// 	</div>
					// )}
				/>
			)}
		</div>
	);
};

export default Announcements;
