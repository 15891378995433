import React, { useState } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ReferandearnTableModal from './ReferandearnTableModal';
import { encryptData } from '@acciojob/event-analytics';
import { convertToLowerCaseAndReplaceSpaces } from '../../utils/events';
const ReferAndEarnReferalCard = ({
	stats,
	index,
	referralData,
}: any) => {
	const [openModal, setOpenModal] =
		useState<boolean>(false);
	const [activeModal, setActiveModal] = useState<
		number | null
	>(null);
	return (
		<>
			<div className="re-stats-card">
				<div className="re-stats-card-title">
					{stats.title}
				</div>
				<div
					className={`re-stats-card-stat ${stats.statsClass}`}
				>
					{stats.stat}
				</div>
				<button
					className="re-stats-card-button"
					onClick={() => {
						setActiveModal(index);
						setOpenModal(true);
					}}
					event-analytics={
						'b1a6f260-e97c-4175-97f3-2a5aa79ac4ec'
					}
					data-config={encryptData(
						JSON.stringify({
							element: stats.title,
							name: convertToLowerCaseAndReplaceSpaces(
								stats.title
							),
						})
					)}
				>
					Know more <ChevronRightIcon />
				</button>
				<img
					src={require(`../../${stats.image}`)}
					className="re-stats-card-image"
					alt={stats.title}
				/>
			</div>
			<ReferandearnTableModal
				openModal={openModal}
				setOpenModal={setOpenModal}
				activeModal={activeModal}
				referralData={referralData}
			/>
		</>
	);
};

export default ReferAndEarnReferalCard;
