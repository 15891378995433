import { Modal, Sheet, Typography } from '@mui/joy';
import React, { useState } from 'react';

const PlacementDataModal = ({ data, popupType }: any) => {
	console.log('data Popup', data, popupType);

	const [showModal, setShowModal] = useState(true);
	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={showModal}
			onClose={() => setShowModal(false)}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Sheet
				variant="soft"
				color="danger"
				sx={{
					maxWidth: 500,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}
			>
				{/* <ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/> */}
				<Typography
					component="h2"
					id="modal-title"
					level="h4"
					color="danger"
					fontWeight="lg"
					mb={1}
				>
					Important !!
				</Typography>
				<Typography
					id="modal-desc"
					color="danger"
				>
					Your mock scores for{' '}
					{data?.length > 0
						? data.map((item: any, index: number) => (
								<React.Fragment key={index}>
									<b>{item.sessionName}</b>
									{index < data.length - 1 ? ', ' : null}
								</React.Fragment>
						  ))
						: null}{' '}
					{data?.length > 1 ? 'mocks' : 'mock'} is now
					outdated as you have not taken them in the last 60
					days. Please retake these mocks to become eligible
					for jobs that require them.
				</Typography>
			</Sheet>
		</Modal>
	);
};

export default PlacementDataModal;
