import {
	createSlice,
	PayloadAction,
	createAsyncThunk,
} from '@reduxjs/toolkit';
import {
	AiStudentAssessmentState,
	QuestionTypeEnum,
} from '../../types/aiStudentAssessment.types';
import {
	getNextQuestion,
	startAssessment,
	submitUserResponse,
} from './aiStudentAssessment.thunk';
import { notifySuccess } from '../../components/notification';

// 	let report = {
// "totalQuestionsAsked": 5,
// "totalCorrectAnswers": 4,
// "totalWrongAnswers": 1,
// "totalUnattemptedQuestions": 0,
// "scorePercentage": 80,
// "timeTakenByStudentInSeconds": 145,
// "feedback": "",
// "weakAreas": [
//     "Conditionals"
// ],
// "strongAreas": [
//     "2D-Arrays",
//     "Loops"
// ]
// }

const initialState: AiStudentAssessmentState = {
	assessmentId: null,
	testStarted: false,
	questionStatement: '',
	questionType: QuestionTypeEnum.SUBJECTIVE,
	options: [],
	expectedTimeToSolve: 0,
	isTestCompleted: false,
	loading: false,
	count: 0,
	report: null,
	totalQuestions: 0,
};

const studentAssessment = createSlice({
	name: 'ai-student-assessment',
	initialState,
	reducers: {
		resetStates: (state) => {
			state.assessmentId = null;
			state.testStarted = false;
			state.questionStatement = '';
			state.questionType = QuestionTypeEnum.SUBJECTIVE;
			state.options = [];
			state.expectedTimeToSolve = 0;
			state.isTestCompleted = false;
			state.loading = false;
			state.report = null;
			state.totalQuestions = 0;
			state.count = 0;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(startAssessment.pending, (state) => {
				state.assessmentId = null;
				state.testStarted = false;
				state.loading = true;
			})
			.addCase(
				startAssessment.fulfilled,
				(state, action: PayloadAction<any>) => {
					console.log({ payload: action.payload });
					state.assessmentId =
						action.payload.studentAssessmentId;
					state.totalQuestions =
						action.payload.totalQuestions;
					// state.testStarted = true;
					state.loading = false;
				}
			)
			.addCase(startAssessment.rejected, (state) => {
				state.assessmentId = null;
				state.testStarted = false;
			});

		builder
			.addCase(getNextQuestion.pending, (state) => {
				state.questionStatement = '';
				state.questionType = QuestionTypeEnum.SUBJECTIVE;
				state.loading = true;
			})
			.addCase(
				getNextQuestion.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.questionStatement =
						action.payload.message.questionStatement;
					state.questionType =
						action.payload.message.questionType;
					state.options = action.payload.message.options;
					state.expectedTimeToSolve =
						action.payload.message.expectedTimeToSolveForStudentInSeconds;
					state.testStarted = true;
					state.loading = false;
					state.count = state.count + 1;
					if (action.payload.message.report) {
						state.report = action.payload.message.report;
					}
				}
			)
			.addCase(getNextQuestion.rejected, (state) => {
				state.questionStatement = '';
				state.questionType = QuestionTypeEnum.SUBJECTIVE;
				state.loading = false;
			});

		builder
			.addCase(
				submitUserResponse.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.isTestCompleted =
						action.payload.isTestCompleted ?? false;

					if (action.payload.status) {
						notifySuccess(action.payload.msg);
						state.loading = false;
					}
				}
			)
			.addCase(submitUserResponse.rejected, (state) => {
				state.loading = false;
			})
			.addCase(submitUserResponse.pending, (state) => {
				state.loading = true;
			});
	},
});

//Exporting Actions
export const { resetStates } = studentAssessment.actions;

export default studentAssessment.reducer;
