import { Tooltip } from '@mui/material';
import { FiInfo } from 'react-icons/fi';
import ProgressBar from '../../shared/ProgressBar';

const SectionWiseAnalysis = ({ data }: any) => {
	const getColor = (score: number) => {
		if (score < 6) {
			return {
				color: '#F63D68',
				// backgroundColor: '#FFE4E8',
			};
		} else if (score < 8) {
			return {
				color: '#EAAA08',
				// backgroundColor: '#FEFBE8',
			};
		} else {
			return {
				color: '#12B76A',
				// backgroundColor: '#D3F8DF',
			};
		}
	};

	const sectionInfo: any = {
		Resume:
			'Resume content, explanation, formatting and presentation',
		Project:
			'Project functionality, understanding, novelity and explanation',
		'Coding Skill':
			'Coding Logic, optimality of code, judgement of final code',
		Introduction:
			'Communication of technical skills/experiences to interviewer',
		'Theoretical Knowledge':
			'Understanding of theoretical concepts',
		'Explanation and Code Quality':
			'Code modularity, variable naming and code comments',
	};

	const score: number =
		data?.totalScore !== '-1' ? Number(data?.score) : 0;

	return !data?.isMentorShip ? (
		<div className="section-wise-analysis-container">
			<p className="section-wise-analysis-title">
				Section Wise Analysis
			</p>
			{data?.['SS'] ? (
				<div className="section-wise-analysis-box">
					{Object.entries(data?.['SS']).map(
						(sessionTopics: any, i: number) => (
							<div
								key={i}
								className="section-wise-analysis-section"
							>
								{sessionTopics.length > 0 ? (
									<div className="section-wise-analysis-item">
										<p className="section-wise-analysis-item-title">
											<span className="text">
												{sessionTopics[0]}
											</span>
											<>
												{console.log(
													'sectionInfo[sessionTopics[0]]',
													sectionInfo[sessionTopics[0]]
												)}
											</>
											{sectionInfo[sessionTopics[0]] ? (
												<Tooltip
													placement="top"
													title={
														sectionInfo[sessionTopics[0]]
													}
												>
													<span className="icon">
														<FiInfo
															style={{
																fontSize: '13px',
																color: '#505862',
															}}
														/>
													</span>
												</Tooltip>
											) : null}
										</p>
										<div className="section-wise-analysis-progress">
											<ProgressBar
												val={Number(
													sessionTopics[1] >= 10
														? 10
														: sessionTopics[1].toFixed(2)
												)}
												fontSize="12px"
												statusShow={true}
											/>
										</div>
									</div>
								) : null}
							</div>
						)
					)}
				</div>
			) : null}

			<div className="section-wise-analysis-score">
				<span className="score-header">Total Score : </span>
				<p
					className="score"
					style={getColor(score)}
				>
					<span className="final-score">
						{score >= 10 ? 10 : score}
					</span>
					<span>/10</span>
				</p>
			</div>
		</div>
	) : null;
};

export default SectionWiseAnalysis;
