import React, { useEffect, useState } from 'react';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	TooltipItem,
	Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import annotationPlugin, {
	AnnotationOptions,
} from 'chartjs-plugin-annotation';
import moment from 'moment';
import { AiOutlineDash } from 'react-icons/ai';
// import '../../src/styles/charts.css';
// import '../../src/styles/charts.scss';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment

// import faker from 'faker';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
	annotationPlugin
);
interface Props {
	dataset: any[];
}
export const Chart = (props: Props) => {
	const recommendedPercentage = 80;
	const [labels, setLabels] = useState<string[]>([]);
	const [userData, setUserData] = useState<
		{ y: number; x: string }[]
	>([]);
	const { dataset } = props;
	const generateLabels = (questionsArray: any[]) => {
		const labelArray = [];
		for (let i = 0; i < questionsArray.length; i++) {
			labelArray.push(questionsArray[i].days);
		}
		const last_day = moment(
			labelArray[labelArray.length - 1]
		);
		const today = moment().format('YYYY-MM-DD');
		const days_diff = moment(today).diff(last_day, 'days');
		console.log(days_diff);
		//iterate over days and add to labels
		for (let i = 0; i < days_diff; i++) {
			labelArray.push(
				moment(last_day)
					.add(i + 1, 'days')
					.format('YYYY-MM-DD')
			);
		}
		return labelArray;
	};
	const scaleUsersData = (
		labels: string[],
		maxDataOriginal: any[],
		usersDataOriginal: any[]
	) => {
		const usersScaledData = [];
		for (let i = 0; i < labels.length; i++) {
			const maxVal = maxDataOriginal.find(
				(dayData) => dayData.days == labels[i]
			);
			const usersVal = usersDataOriginal.find(
				(dayData) => dayData.days == labels[i]
			);
			if (maxVal && usersVal) {
				usersScaledData.push({
					y: Math.round(
						(usersVal.count / maxVal.max) * 100
					),
					x: labels[i],
				});
			} else {
				if (usersVal && !maxVal) {
					usersScaledData.push({ y: 100, x: labels[i] });
				} else {
					usersScaledData.push({ y: 1, x: labels[i] });
				}
			}
		}
		return usersScaledData;
	};

	useEffect(() => {
		if (dataset.length === 2) {
			const newLabels: string[] = generateLabels(
				dataset[0]
			);
			const newUserData: { y: number; x: string }[] =
				scaleUsersData(newLabels, dataset[1], dataset[0]);
			setLabels(newLabels);
			setUserData(newUserData);
		}
	}, [dataset]);

	const data = {
		labels,
		datasets: [
			{
				label: 'Dataset 1',
				data: userData,
				backgroundColor: 'rgba(56,138,249,0.2)',
				borderRadius: 4.71116,
				barPercentage: 1,
				categoryPercentage: 0.92,

				// barThickness: '21',

				hoverBackgroundColor: 'rgba(47, 128, 237,0.7)',
			},
		],
	};
	const options = {
		responsive: true,
		scales: {
			x: {
				display: false,
				grid: {
					display: false,
				},
			},
			y: {
				grace: '5%',
				grid: {
					borderDash: [8, 4],
					color: function (context: {
						tick: { value: any };
					}) {
						if (!context.tick.value) {
							return 'rgba(56, 138, 249, 0.2)';
						}
						return 'rgba(174, 179, 187,0.2)';
					},
					borderWidth: 1,
				},
			},
		},
		plugins: {
			legend: {
				position: 'top' as const,
				display: false,
			},
			title: {
				display: false,
				text: "Your Daily Analysis vs Today's Topper",
			},
			annotation: {
				annotations: [
					{
						type: 'line',
						yMin: recommendedPercentage,
						yMax: recommendedPercentage,
						borderColor: 'rgba(255, 148, 10, 1)',
						borderWidth: 0.5,
						borderDash: [8, 4],
					} as AnnotationOptions<'line'>,
				],
			},
			tooltip: {
				backgroundColor: '#FFFFFF',
				displayColors: false,
				titleColor: 'rgba(52, 64, 84, 1)',
				bodyColor: 'rgba(47, 128, 237, 1)',
				callbacks: {
					title: function (context: any) {
						const date = moment(context[0].label).format(
							'Do MMM'
						);
						return date;
					},
					label: function (context: any) {
						const index = context.dataIndex;
						let labelval = context.dataset.data[index]['y'];
						if (context.dataset.data[index]['y'] <= 1) {
							labelval = 0;
						}
						return (
							labelval +
							"% questions solved. You v/s today's topper"
						);
					},
				},
			},
		},
	};

	return (
		<>
			<div>
				<h3>Your Daily Analysis v/s Today&#39;s Topper</h3>

				<div className={'container'}>
					<p className={'legend'}>
						{/* How many questions have you solved relative to the daily
					maximum? */}
						<span className={'recommended-dashed'}>
							<AiOutlineDash />
						</span>{' '}
						<span className={'recommended-text'}>
							{' '}
							Recommended{' '}
						</span>
					</p>
				</div>
			</div>
			<Bar
				options={options}
				data={data}
			/>
		</>
	);
};

export default Chart;
