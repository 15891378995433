import Modal from '@mui/joy/Modal';
import { BiMedal } from 'react-icons/bi';
import { AchievementsRow } from './AchievmentCard/AchievementsRow';

export interface AchievementsInterface {
	level: number | null;
	total: Array<number> | null;
	message: Array<string> | null;
	heading: string | null;
	desc: string | null;
	streak: number | null;
}
interface AcheivementModalInterface {
	state: boolean;
	setState: (value: boolean) => void;
	achievements: Array<AchievementsInterface>;
}

export const AchievementModal = ({
	state,
	setState,
	achievements,
}: AcheivementModalInterface) => {
	return (
		<div>
			<Modal
				open={state}
				onClose={() => setState(false)}
				className="achievement-modal-container"
			>
				<div className="achievement-modal-wrapper">
					<div className="header-wrapper">
						<div className="heading-flex">
							<BiMedal
								style={{ color: '#000', fontSize: '24px' }}
							/>
							<p className="heading">Badges</p>
						</div>
					</div>
					<div className="main-wrapper">
						{achievements.length > 0
							? achievements.map((item: any, i: number) => (
									<AchievementsRow
										key={i}
										data={item}
									/>
							  ))
							: null}
					</div>
				</div>
			</Modal>
		</div>
	);
};
