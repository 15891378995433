import React, { useEffect } from 'react';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import { getRemainingBookings } from '../../redux/session/sessionThunk';
import { Table } from '@mui/joy';
import bulb from '../../assets/page/session/bulb.svg';

function RemainingSessionBooking() {
	const dispatch = useAppDispatch();

	const { remainingBookings } = useAppSelector(
		(state) => state.session
	);

	if (remainingBookings)
		return (
			<div className="session-recommended-wrapper remaining-session-wrapper">
				<p
					className="session-recommended-title"
					style={{ marginBottom: '10px' }}
				>
					<img
						className="icon"
						src={bulb}
					/>
					<span className="text">Sessions Remaining</span>
				</p>
				<Table
					className="remaining-session-table"
					size="sm"
					variant="plain"
					sx={{
						borderTop: '1px solid #F2F4F7',
						'--Table-headerUnderlineThickness': '1px',
					}}
				>
					<thead>
						<tr className="remaining-session-tr">
							<th className="remaining-session-heading">
								Session Type
							</th>
							<th className="remaining-session-heading center-td">
								Bookings Left
							</th>
							<th className="remaining-session-heading center-td">
								Bookings Done
							</th>
						</tr>
					</thead>
					<tbody>
						{Object.entries(remainingBookings).map(
							([key, value]) => (
								<tr key={key}>
									<td className="remaining-td">{key}</td>
									<td className="remaining-td center-td">
										{value.remainingBookingLeft}
									</td>
									<td className="remaining-td center-td">
										{value.bookingsDone}
									</td>
								</tr>
							)
						)}
					</tbody>
				</Table>
			</div>
		);
	else {
		return null;
	}
}

export default RemainingSessionBooking;
