import { Loader } from '@acciojob/loader';
import greenGraph from '../../../../../assets/images/green-graph.svg';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../redux/store';
import { useEffect } from 'react';
import { fetchPerformanceUpdates } from '../../../../../redux/profile/profile.thunk';
export const ActiveEngagements = ({ streak }: any) => {
	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	if (performanceData.data && !performanceData.loading)
		return (
			<div className="transparent-div">
				<div className="heading-flex">
					<img
						src={greenGraph}
						className="icon"
					/>
					<p className="heading">
						{streak ? 'Streak' : 'Active engagement'}
					</p>
				</div>
				<p className="bigHeading">
					{streak
						? performanceData.data.streak
						: performanceData.data.totalActivity}
				</p>
				<p className="grey">DAYS</p>
			</div>
		);
	else {
		return <Loader />;
	}
};
