import { useEffect, useState } from 'react';
import LatestUpcomingSession from './LatestUpcomingSession';
import PaymentDetails from './PaymentDetails';
import { Dialog } from '@mui/material';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import {
	fetchMentorAnnouncements,
	fetchMentorProfile,
	fetchMentorQCScore,
} from '../../../../redux/mentorSlice/mentorSlice';
import EffortGuage from '../../../../components/EffortGuage';
import LatestSession2 from './LatestSession2';
import StudentFeedbackModal from '../../../../components/mentorHome/StudentFeedbackModal';
import Announcements from '../../../../components/mentorHome/Announcements';
import ScoreBlock from '../../../../components/mentorHome/ScoreBlock';
import { Loader } from '@acciojob/loader';

const MentorHome = () => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const {
		mentor,
		mentorQCScore,
		mentorProfileLoading,
		mentorAnnouncements,
	} = MentorState;

	const [qcScore, setQCScore] = useState<any>(null);
	const [displayScoreModal, setDisplayScoreModal] =
		useState(false);

	useEffect(() => {
		if (mentor === null) dispatch(fetchMentorProfile());
	}, [mentor]);

	useEffect(() => {
		if (mentor !== null && mentorQCScore === null)
			dispatch(fetchMentorQCScore());
		else if (mentorQCScore !== null)
			setQCScore(mentorQCScore);
	}, [mentor, mentorQCScore]);

	/*** Announcements ***/
	const [announcements, setAnnouncements] = useState<any>(
		[]
	);
	const [slides, setSlides] = useState();

	useEffect(() => {
		if (mentor !== null && mentorAnnouncements === null)
			dispatch(fetchMentorAnnouncements());
		else if (mentorAnnouncements !== null)
			setAnnouncements([...mentorAnnouncements]);
	}, [mentor, mentorAnnouncements]);

	useEffect(() => {
		if (announcements !== null) {
			const items = announcements.map((obj: any) => (
				<>
					<div>
						<h4 className="announcement-title">
							Announcement
						</h4>
						<div className="announcement">
							<div className="announcement-left">
								<p className="announcement-text">
									{obj.text}
								</p>
							</div>
							<div className="announcement-right"></div>
						</div>
					</div>
				</>
			));
			setSlides(items);
		}
	}, [announcements]);

	return (
		<>
			{!mentorProfileLoading ? (
				<div className="dashboard">
					<StudentFeedbackModal
						scoreModal={displayScoreModal}
						setScoreModal={setDisplayScoreModal}
						qcScore={qcScore}
					/>

					<div className="column-1">
						<p className="welcome-text">
							<span>Welcome Back</span>{' '}
							{mentor !== null
								? mentor.firstName
								: 'Mentor Name'}
						</p>

						<LatestSession2 />
						<PaymentDetails />
					</div>
					<div className="column-2">
						<Announcements slides={slides} />
						<ScoreBlock
							qcScore={qcScore}
							setDisplayScoreModal={setDisplayScoreModal}
						/>
					</div>
				</div>
			) : (
				<Loader />
			)}
		</>
	);
};

export default MentorHome;
