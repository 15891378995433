// import { ENDPOINTS } from '../config/endpoints/prod';
import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';

interface ApiResponse<T> {
	message: string;
	data: T;
}

export interface JobCardData {
	blacklisted: boolean;
	companyName: string;
	deadline: string;
	location: string[];
	ctcOffered: string;
	noOfPositions: string;
	otherCodingSkills: string[];
	selectionProcess: string[];
	eligibility: boolean;
	rolesOffered: string[];
	jobCardId: string;
	isBondOffering: boolean;
	isInterested?: boolean;
}

export interface MultiSelectValuesOffCampus {
	companyLocation: string[];
	collegeDegree: string[];
	skillRequired: string[];
}

export interface JobDetailsData {
	blacklisted: boolean;
	minCtcOffered: string;
	maxCtcOffered: string;
	deadline: string;
	id: string;
	jd: string;
	location: string[];
	noOfPositions: string;
	otherCodingSkills: string[];
	rolesOffered: string[];
	salaryComments: string;
	selectionProcess: string[];
	companyName: string;
	eligibility: boolean;
	disqualificationReasons: string[];
	resources: string[];
	isRejected: boolean;
	rejectedReason: string;
	isBondOffering: boolean;
	isHold: boolean;
	isHoldReason: string;
	stageNotes: string;
	endAt: Date | null;
	isInterested?: boolean;
}

export interface CustomQuestionsData {
	id: string;
	questionString: string;
	questionType: string;
	answerOptions: string[];
}

export interface CustomQuestionSubmission {
	jobCardId: string;
	customQuestionAnswers: {
		jobCardQuestion: string;
		answer: string[];
	}[];
}

export interface ApplicationCardData {
	companyName: string;
	location: string[];
	minCtcOffered: string;
	maxCtcOffered: string;
	noOfPositions: string;
	id: string;
	currentStage: string;
	jobCardId: string;
}

export interface OffCampusCardData {
	companyName: string;
	rolesOffered: string;
	dateAdded: string;
	eligibility: string;
	applicationLink: string;
	jobCardId: string;
	applied: string | null;
}

export interface fetchFeedBackOption {
	data: string[];
}

export interface postOffCampusApplicationStatus {
	jobCardId: string;
	option: string;
}

export interface MockScoreCardData {
	blacklisted: boolean;
	companyName: string;
	deadline: string;
	location: string[];
	ctcOffered: string;
	noOfPositions: string;
	otherCodingSkills: string[];
	selectionProcess: string[];
	eligibility: boolean;
	rolesOffered: string[];
	jobCardId: string;
	isBondOffering: boolean;
}

export interface JobPortalDashboardData {
	Applied: number;
	Shortlisted: number;
	'Not Applied': number;
	Ongoing: number;
	Rejected: number;
}

export interface MockSessionDetailsData {
	date: number | Date;
	resume: boolean;
	score: number;
}

export interface weeklyHighlightData {
	weeklyData: string[];
}

export interface StudentProfileMultiSelectValues {
	collegeDegree: string[];
	collegeDepartment: string[];
	homeState: string[];
	jobStatus: string[];
	jobType: string[];
	preferredSpeakingLanguage: string[];
}
export interface CompanyName {
	companyName: string;
}

export class JobPortalService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchJobCards() {
		const url = `${this.endpoint}/job-card/jobCardValuesMainPortal`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<JobCardData[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async checkBlacklistStatus() {
		const url = `${this.endpoint}/job-card/checkJobPortalEligibility`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMockScoreCards() {
		const url = `${this.endpoint}/job-card/jobCardValuesMockScorePortal`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<MockScoreCardData[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchUserMockScores() {
		const url = `${this.endpoint}/users/userMockScores`;
		try {
			const data = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchApplicationCards() {
		const url = `${this.endpoint}/job-card/jobCardValuesAppliedMainPortal`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<ApplicationCardData[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchOffCampusCards() {
		const url = `${this.endpoint}/job-card/jobCardValuesOffCampusMainPortal`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<OffCampusCardData[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
	async getOffCampusCardsWithFilters(body: any) {
		const url = `${this.endpoint}/job-card/offCampusCardsWithFilters`;
		try {
			const { data } = await this.httpService.post(url, {
				data: body,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchJobDetails(jobCardId: string) {
		const url = `${this.endpoint}/job-card/getJobCardDetails?jobCardId=${jobCardId}`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<JobDetailsData>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMultiSelectValuesJobCard() {
		const url = `${this.endpoint}/job-card/multiSelectValuesjobCardOffCampus`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<MultiSelectValuesOffCampus>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchCustomQuestions(jobCardId: string) {
		const url = `${this.endpoint}/job-card/getQuestions?jobCardId=${jobCardId}`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<CustomQuestionsData[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchFeedBackOption() {
		const url = `${this.endpoint}/job-card/offCampusFeedbackOptions`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<CustomQuestionsData[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postOffCampusApplicationStatus(
		jobCardId: string,
		feedback: string
	) {
		const url = `${this.endpoint}/job-card/feedbackOffCampus`;
		try {
			const { data } = await this.httpService.post(url, {
				data: {
					jobCardId: jobCardId,
					feedback: feedback,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postApplicationOffCampus(jobCardId: string) {
		const url = `${this.endpoint}/job-card/applicationOffCampus`;
		try {
			const { data } = await this.httpService.post(url, {
				data: {
					jobCardId: jobCardId,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postCustomQuestions(
		customQuestionData: CustomQuestionSubmission
	) {
		const url = `${this.endpoint}/job-card/applyJobCard`;
		try {
			const { data } = await this.httpService.post(url, {
				data: customQuestionData,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchJobPortalDashboard() {
		const url = `${this.endpoint}/job-card/getJobCardDashBoard`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<JobPortalDashboardData>
			>(url);
			console.log('dash, ', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchDrive(stage: string) {
		const url = `${this.endpoint}/job-card/getJobCardDashBoardSpecificValues?stageString=${stage}`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<string[]>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMockSessionDetails() {
		const url = `${this.endpoint}/job-card/getMockSessionDetails`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<MockSessionDetailsData>
			>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchGetPlacedStudentsThisMonth() {
		const url = `${this.endpoint}/job-card/getPlacedStudentsThisMonth`;
		try {
			const data = await this.httpService.get<
				ApiResponse<weeklyHighlightData>
			>(url);
			console.log('action.payload data', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchStudentProfile() {
		const url = `${this.endpoint}/student-personal-details`;
		try {
			const { data } = await this.httpService.get(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchStudentProfileMultiSelectValues() {
		const url = `${this.endpoint}/student-personal-details/multiSelectOptions`;
		try {
			const { data } =
				await this.httpService.get<StudentProfileMultiSelectValues>(
					url
				);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postStudentProfile(data1: any) {
		const url = `${this.endpoint}/student-personal-details/update`;
		try {
			const { data } = await this.httpService.post(url, {
				data: data1,
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async isProfileCompleted() {
		const url = `${this.endpoint}/student-personal-details/isProfileCompleted`;
		try {
			const { data } = await this.httpService.get(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchCompanyFromJobCardId(jobCardId: string) {
		const url = `${this.endpoint}/job-card/getJobCardCompanyFromJobCardId?jobCardId=${jobCardId}`;
		try {
			const { data } = await this.httpService.get<
				ApiResponse<CompanyName>
			>(url);
			return data.data.companyName;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchAppliedStudentsStatus(
		jobCardId: string,
		page: number
	) {
		console.log('popupIdentifer', jobCardId);
		const url = `${this.endpoint}/job-card/${jobCardId}/students?page=${page}`;
		try {
			const { data } = await this.httpService.get<any[]>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markNotInterested(
		jobCardId: string,
		reason: string
	) {
		const url = `${this.endpoint}/job-card/mark-not-interested`;
		try {
			const { status } = await this.httpService.post(url, {
				data: { jobCardId, reason },
			});
			console.log('Success', status);
			return status;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
