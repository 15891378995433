import React from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import {
	FormDataType,
	FormFieldProps,
} from '../../jobPortal/CompleteProfilePopup';
// import {
// 	FormDataType,
// 	FormFieldProps,
// } from '../CompleteProfilePopup';

const MyTextField = ({
	id,
	label,
	type,
	formData,
	disabled,
	updateFormData,
}: FormFieldProps) => {
	return (
		<div className="form-item">
			<MaterialTextField
				required
				disabled={disabled}
				type={type}
				id="outlined-basic"
				label={label}
				variant="outlined"
				style={{ width: '100%' }}
				name={id}
				InputProps={{
					style: {
						color: '#000',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					},
				}}
				InputLabelProps={{
					style: {
						color: 'rgba(0, 0, 0, 0.75)',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					},
				}}
				value={formData[id as keyof FormDataType]}
				onChange={(e) =>
					updateFormData(e.target.name, e.target.value)
				}
			/>
		</div>
	);
};

export default MyTextField;
