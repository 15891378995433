import {
	notifyError,
	notifySuccess,
} from '../components/notification';
import { ProjectReactionType } from '../redux/projects/projectSlice';
import {
	HttpService,
	apiFailureErrorMessage,
} from '../utils/httpService';

export class CourseService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchStartUrl(
		meetingId: string,
		instructorId: string
	) {
		const url = `${this.endpoint}/video/getNewUrl/${meetingId}/${instructorId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchStartUrlForStudents(
		meetingId: string,
		firstName: any,
		lastName: any,
		email: any
	) {
		const url = `${this.endpoint}/video/addRegistrantForZoomMeeting/${meetingId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { firstName, lastName, email },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchPreCourse() {
		const url = `${this.endpoint}/course/fetchPreCourse`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getVideo(videoId: any) {
		const url = `${this.endpoint}/video/${videoId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLiveSession(type: string) {
		const url = `${this.endpoint}/live-session/${type}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async completeOrientation(moduleId: string) {
		const url = `${this.endpoint}/course/complete-pre-course-orientation`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						moduleId: moduleId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingTestLink() {
		const url = `${this.endpoint}/user-report-card/get-admission-coding-test`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingQuestionsForModule(moduleId: string) {
		const url = `${this.endpoint}/user-course-enrollment/${moduleId}/codingQuestions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingTestResult() {
		const url = `${this.endpoint}/user-report-card/coding-test-status`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseData() {
		const url = `${this.endpoint}/course/fetchCourse`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getTotalSolvedAssignmentCounts() {
		const url = `${this.endpoint}/course/fetchQuestionsCount`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getUpcomingMeetingIds(courseId: string) {
		const url = `${this.endpoint}/video/getUpcomingZoomClasses/${courseId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getRecordings(moduleId: string) {
		const url = `${this.endpoint}/video/showLectures/${moduleId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postRecordingAnalytics(sessionData: any) {
		const url = `${this.endpoint}/recording-analytics/new`;
		try {
			const sessionId = await this.httpService.post<any>(
				url,
				{
					data: sessionData,
				}
			);
			return sessionId.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateRecordingAnalytics(data: any) {
		const url = `${this.endpoint}/recording-analytics/update`;
		try {
			const response = await this.httpService.patch<any>(
				url,
				{
					data: data,
				}
			);
			return response;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllCourseData() {
		const url = `${this.endpoint}/user-course-enrollment/getunits`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getFullCourseQuestionsForModule(moduleId: string) {
		const url = `${this.endpoint}/course/${moduleId}/questions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllAptitudeQuestionsForModule(moduleId: string) {
		const url = `${this.endpoint}/module/${moduleId}/conceptualQuestions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAptitudeQuestionById(questionId: string) {
		const url = `${this.endpoint}/conceptual-question/questionById?questionId=${questionId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitAptitudeByModuleId(moduleData: any) {
		const url = `${this.endpoint}/conceptual-question/submit`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: moduleData,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitAptitudeByQuestionId(Data: any) {
		const url = `${this.endpoint}/conceptual-question/submitQuestion`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: Data,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async saveAptitudeQuestions(questionData: any) {
		const url = `${this.endpoint}/conceptual-question/saveQuestion`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: questionData,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllCodingQuestions(body: any) {
		const url = `${this.endpoint}/coding-question/all/${body.page}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllCodingQuestionsModular(body: any) {
		const url = `${this.endpoint}/coding-question/all/modular/${body.page}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						...body,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllConceptualQuestions(body: any) {
		const url = `${this.endpoint}/conceptual-question/all/${body.page}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						...body,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllWebDevQuestions(body: any) {
		const url = `${this.endpoint}/github/all/${body.page}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						...body,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllSolvedCodingQuestions(
		page: number,
		difficulty: string[]
	) {
		const url = `${this.endpoint}/coding-question/all/solved/${page}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						difficulty,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllUnSolvedCodingQuestions(
		page: number,
		difficulty: string[]
	) {
		const url = `${this.endpoint}/coding-question/all/unsolvedQuestions/${page}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						difficulty,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCodingQuestionsStatus() {
		const url = `${this.endpoint}/coding-question/status`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseLeaderboard() {
		const url = `${this.endpoint}/course/leaderboard`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getUserTotalScore() {
		const url = `${this.endpoint}/course/myscore`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getActiveContests(courseDetails: any) {
		const url = `${this.endpoint}/contests/active`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: courseDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getContestById(courseDetails: any) {
		const url = `${this.endpoint}/contests/get`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: courseDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getContestLeaderBoard(contestDetails: any) {
		const url = `${this.endpoint}/contests/leaderboard`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: contestDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseAssignments() {
		const url = `${this.endpoint}/course/fetchAssignmentModules`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCourseEnrollment() {
		const url = `${this.endpoint}/course/fetchFullCourseEnrollment`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllTags() {
		const url = `${this.endpoint}/attachedtags`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllTagsByTab(val: any) {
		const url = `${this.endpoint}/attachedtags/filters`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: val,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAnnouncements() {
		const url = `${this.endpoint}/course/announcement/get`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLiveDoubtsSlots() {
		const url = `${this.endpoint}/course/unit/details`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllBookmark() {
		const url = `${this.endpoint}/bookmark/getbookmarks`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addBookmark(input: any) {
		const url = `${this.endpoint}/bookmark/addbookmark`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						questionId: input.questionId,
						type: input.type,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async removeBookmark(input: any) {
		const url = `${this.endpoint}/bookmark/removebookmark`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						questionId: input.questionId,
						type: input.type,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchAllCourses() {
		const url = `${this.endpoint}/course/fetch/all`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllCourseStudents(body: any) {
		const url = `${this.endpoint}/course/fetch/students`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { body },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLink(body: any) {
		const url = `${this.endpoint}/video/checkRoom`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addLiveDoubt(body: any) {
		const url = `${this.endpoint}/livedoubt/adddoubt`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchDetails(userID: any, userType: string) {
		const url = `${this.endpoint}/session/get/${userID}/${userType}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//we can remove move to the session service
	async cancelSession(sessionId: string) {
		const url = `${this.endpoint}/session/cancel`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						sessionId,
					},
				}
			);
			return data;
		} catch (error: any) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//we can remove move to the session service
	async rescheduleSession(
		sessionDate: string,
		sessionId: string,
		prevMentorId: string | null
	) {
		const url = `${this.endpoint}/session/reschedule`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						startTime: new Date(sessionDate).toLocaleString(
							'en-US'
						),
						rescheduleUid: sessionId,
						mentorId: prevMentorId,
					},
				}
			);
			return data;
		} catch (error: any) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//not using
	async markNoShow(sessionId: string, userType: string) {
		const url = `${this.endpoint}/session/marknoshow`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						sessionId,
					},
				}
			);
			if (userType === 'MENTOR') {
				notifySuccess(
					'You have successfully marked no show by mentor. Contact your batch manager to your book session again'
				);
			} else {
				notifySuccess(
					'You have successfully marked no show by student'
				);
			}

			return data;
		} catch (error: any) {
			if (error.response.status === 400) {
				notifyError(
					error.response.data.message ||
						'You cannot mark no show'
				);
			} else throw new Error(apiFailureErrorMessage);
		}
	}

	async getCalenderEvents(
		userId: string,
		userType: string
	) {
		const url = `${this.endpoint}/calender/events/${userId}/${userType}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllProjects() {
		const url = `${this.endpoint}/projects/`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getProjectSteps(projectId: string) {
		const url = `${this.endpoint}/projects/${projectId}/steps`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markStepComplete(
		projectId: string,
		stepId: string
	) {
		const url = `${this.endpoint}/projects/mark-step-done`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						projectId: projectId,
						stepId: stepId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitProject(body: any) {
		const url = `${this.endpoint}/projects/final-step-submission`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						...body,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getEffortScoreData(latestCourseID: string) {
		const url = `${this.endpoint}/users/jobSincerity/${latestCourseID}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getRevisionSheetByTab(tab: string) {
		const url = `${this.endpoint}/coding-question/v2/revision/sheets/?tab=${tab}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getRandomQuestionCoding(courseId: string) {
		const url = `${this.endpoint}/coding-question/random?courseId=${courseId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getRandomQuestionWebDev(courseId: string) {
		const url = `${this.endpoint}/github/random?courseId=${courseId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getRandomQuestionConceptual(courseId: string) {
		const url = `${this.endpoint}/conceptual-question/random?courseId=${courseId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAvailableGymTabs() {
		const url = `${this.endpoint}/coding-question/get-gym-tabs`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getVimeoVideoDownloadUrls(vimeoVideoLink: string) {
		const url = `${this.endpoint}/video/get-vimeo-video-download-urls`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						vimeoVideoLink,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async captureLike({
		projectId,
		likeStatus,
	}: {
		projectId: string;
		likeStatus: ProjectReactionType;
	}) {
		const url = `${this.endpoint}/projects/capture-reaction`;
		try {
			const { data } = await this.httpService.post<unknown>(
				url,
				{
					data: {
						projectId,
						likeStatus,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
