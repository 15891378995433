import Input from '@mui/joy/Input';

interface InputWithDecoratorProps {
	placeholder: string;
	value: string;
	setValue: Function;
	onChangeAction?: Function;
	startDecoration?: any;
	endDecoration?: any;
	customClass?: string;
}

export const InputWithDecorator = ({
	placeholder,
	value,
	setValue,
	onChangeAction,
	startDecoration,
	endDecoration,
	customClass,
}: InputWithDecoratorProps) => {
	return (
		<Input
			placeholder={placeholder}
			startDecorator={
				startDecoration ? startDecoration : null
			}
			endDecorator={endDecoration ? endDecoration : null}
			value={value}
			onChange={(e) => {
				setValue(e.target.value);
				if (onChangeAction) {
					onChangeAction(e);
				}
			}}
			className={`${customClass ? customClass : ''}`}
		/>
	);
};
