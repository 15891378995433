import './App.scss';
import './styles/styles.scss';
// Importing Providers

import { services } from './apis';

// Importing Routes
import { useDispatch } from 'react-redux';
import { setUserRole } from './featureController/featureControllerSlice';
import {
	fetchCourseList,
	fetchUserReport,
	fetchUserTrack,
} from './redux/user/userSlice';

import { Loader } from '@acciojob/loader';
import { Box } from '@mui/material';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import useAuthentication from './customHooks/useAuthentication';
import { AppDispatch } from './redux/store';

const userAuthStatusCheck = async () => {
	try {
		const data =
			await services.userService.getUserAuthStatus();
		if (data) {
			const {
				isDetailsFilled,
				isEmailSet,
				isPasswordSet,
				isUserExist,
			} = data;

			const check =
				isDetailsFilled &&
				isEmailSet &&
				isPasswordSet &&
				isUserExist;

			if (!check) {
				// Removing logged in user !!

				// localStorage.clear();
				// Cookies.remove('acciojobs-token', {
				// 	domain: `.acciojob.com`,
				// });
				window.open(
					'https://authentication.acciojob.com',
					'_self'
				);
			}
		}
	} catch (error) {
		console.error(error);
	}
};

function AuthenticationCheck() {
	const dispatch = useDispatch<AppDispatch>();

	const allowedRoles = [
		'maincourse-user',
		'instructor',
		'mentor',
		'community-user',
		'playground-user',
		'data-maincourse-user',
		'dsa-maincourse-user',
		'fsd-maincourse-user',
		'da-playground-user',
	];

	const authenticated = useAuthentication({
		allowedRoles,
		callBack: async () => {
			await userAuthStatusCheck();
			await dispatch(fetchUserReport());
			await dispatch(fetchUserTrack());
			await dispatch(fetchCourseList());
		},
		setRole: (userRole: string) => {
			dispatch(setUserRole(userRole));
		},
	});

	return (
		<>
			<ToastContainer />
			{authenticated ? (
				<App />
			) : (
				<Box className="authLoader">
					<Loader />
				</Box>
			)}
		</>
	);
}

export default AuthenticationCheck;
