import { type FC } from 'react';

type ProgressBarPropsType = {
	progressColor: string;
	borderColor: string;
	backgroundColor?: string;
	percentage: string;
	height?: string;
};

export const ProgressBar: FC<ProgressBarPropsType> = (
	props
) => {
	return (
		<div
			className="progress-container"
			style={{
				border: `1px solid ${props.borderColor}`,
				backgroundColor: props.backgroundColor,
				height: props.height,
			}}
		>
			<div
				className="progress-bar"
				style={{
					width: props.percentage,
					backgroundColor: props.progressColor,
				}}
			/>
		</div>
	);
};
