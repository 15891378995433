import { Loader } from '@acciojob/loader';

import moment from 'moment';
import React, { useState } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

interface ScheduleCalenderProps {
	selectableDates: string[];
	startTime?: string;
	endTime?: string;
	duration?: number;
	timeSlots?: string[];
	onTimeSelect: (value: string) => void;
	onDateSelect: (value: string) => void;
	isLoading?: boolean;
}

const ScheduleCalender: React.FC<ScheduleCalenderProps> = ({
	selectableDates,
	startTime,
	endTime,
	duration,
	timeSlots,
	onTimeSelect,
	onDateSelect,
	isLoading,
}) => {
	const currentDate = new Date();
	const [currentMonth, setCurrentMonth] = useState<number>(
		currentDate.getMonth()
	);
	const [currentYear, setCurrentYear] = useState<number>(
		currentDate.getFullYear()
	);
	const [selectedDate, setSelectedDate] = useState<
		number | null
	>(null);

	const [selectedDay, setSelectedDay] =
		useState<string>('');

	const [selectedSlot, setSelectedSlot] = useState<
		string | null
	>(null);

	const handlePrevMonth = (): void => {
		setCurrentMonth((prevMonth) => {
			const newMonth = prevMonth === 0 ? 11 : prevMonth - 1;
			const newYear =
				prevMonth === 0 ? currentYear - 1 : currentYear;
			setCurrentYear(newYear);
			return newMonth;
		});
		setSelectedDate(null);
		onDateSelect('');
		onTimeSelect('');
	};

	const handleNextMonth = (): void => {
		setCurrentMonth((prevMonth) => {
			const newMonth = prevMonth === 11 ? 0 : prevMonth + 1;
			const newYear =
				prevMonth === 11 ? currentYear + 1 : currentYear;
			setCurrentYear(newYear);
			return newMonth;
		});
		setSelectedDate(null);
		onDateSelect('');
		onTimeSelect('');
	};

	const handleDateClick = (date: number): void => {
		if (selectedDate === date) {
			setSelectedDate(null);
			setSelectedSlot(null);
			onDateSelect('');
			onTimeSelect('');
		} else {
			setSelectedDate(date);
			setSelectedSlot(null);
			const selectedDateTime = moment(
				new Date(currentYear, currentMonth, date)
			).format('YYYY-MM-DD');
			onDateSelect(selectedDateTime);
		}
	};

	const handleSlotClick = (slot: string): void => {
		if (selectedSlot === slot) {
			setSelectedSlot(null);
			onTimeSelect('');
		} else {
			setSelectedSlot(slot);
			const selectedDateTime =
				selectedDate !== null
					? moment(
							new Date(
								currentYear,
								currentMonth,
								selectedDate
							)
					  )
							.format('DD/MM/YYYY')
							.concat(' ', slot.toLowerCase())
					: '';
			onTimeSelect(selectedDateTime);
		}
	};

	const daysInMonth: number = new Date(
		currentYear,
		currentMonth + 1,
		0
	).getDate();
	const firstDayOfMonth: number = new Date(
		currentYear,
		currentMonth,
		1
	).getDay();
	const datesArray: number[] = [...Array(daysInMonth)].map(
		(_, index) => index + 1
	);

	const daysOfWeek: string[] = [
		'Mon',
		'Tue',
		'Wed',
		'Thu',
		'Fri',
		'Sat',
		'Sun',
	];
	const rearrangedDays: string[] = [...daysOfWeek];

	const generateTimeSlots = (): string[] => {
		if (timeSlots && timeSlots.length > 0) {
			return timeSlots.map((timeSlot) =>
				moment(timeSlot, 'hh:mm:ss A').format('hh:mm a')
			);
		} else if (startTime && endTime && duration) {
			const slots: string[] = [];
			let startTimeInMinutes: number =
				moment(startTime, 'hh:mm a').hours() * 60 +
				moment(startTime, 'hh:mm a').minutes();
			const endTimeInMinutes: number =
				moment(endTime, 'hh:mm a').hours() * 60 +
				moment(endTime, 'hh:mm a').minutes();

			while (
				startTimeInMinutes + duration <=
				endTimeInMinutes
			) {
				const time: string = moment
					.utc(startTimeInMinutes * 60000)
					.format('hh:mm a');
				slots.push(time);
				startTimeInMinutes += duration;
			}

			return slots;
		} else {
			return [];
		}
	};

	const generatedTimeSlots: string[] = generateTimeSlots();

	return (
		<div
			className={`schedule-calender-wrapper ${
				selectedDate ? 'center-content' : ''
			}`}
		>
			<div className="schedule-calender-container">
				<div className="header-title">Select Date</div>
				<div className="header">
					<button
						onClick={handlePrevMonth}
						className="calendar-prev-month-nav"
					>
						<FaAngleLeft />
					</button>
					<div className="display-month-year">
						{`${moment()
							.month(currentMonth)
							.year(currentYear)
							.format('MMMM YYYY')}`}
					</div>
					<button
						onClick={handleNextMonth}
						className="calendar-next-month-nav"
					>
						<FaAngleRight />
					</button>
				</div>
				<div className="calendar-days">
					{rearrangedDays.map((day) => (
						<div
							key={day}
							className="day"
						>
							{day}
						</div>
					))}
				</div>
				<div className="calendar-dates">
					{Array((firstDayOfMonth + 6) % 7)
						.fill(null)
						.map((_, index) => (
							<div
								key={index}
								className="date"
							></div>
						))}
					{datesArray.map((date) => {
						const dateString: string = `${date}/${
							currentMonth + 1
						}/${currentYear}`;
						const formattedDateString = moment(
							dateString,
							'DD/MM/YYYY'
						).format('YYYY-MM-DD');
						const isSelectable: boolean =
							selectableDates.includes(formattedDateString);
						const isSelected: boolean =
							selectedDate === date;

						return (
							<div
								key={date}
								className={`date ${
									isSelectable ? 'selectable' : ''
								} ${isSelected ? 'selected' : ''}`}
								onClick={() =>
									isSelectable && handleDateClick(date)
								}
							>
								{date}
							</div>
						);
					})}
				</div>
			</div>
			{selectedDate ? <div className="line"></div> : null}

			{selectedDate ? (
				<div
					className={`slots-container ${
						selectedDate
							? 'slide-in-right '
							: 'slide-out-right opacity-background'
					}`}
				>
					<p className="slots-title">Select time-slot</p>

					{isLoading ? (
						<div className="slots">
							<Loader
								factVisibility={false}
								height={'100%'}
							/>
						</div>
					) : (
						<div className="slots">
							{generatedTimeSlots.length > 0 ? (
								generatedTimeSlots.map((slot) => (
									<button
										key={slot}
										className={`slot ${
											selectedSlot === slot
												? 'selected'
												: ''
										}`}
										onClick={() => handleSlotClick(slot)}
									>
										{slot}
									</button>
								))
							) : (
								<span className="no-slots">
									No Slots Available..
								</span>
							)}
						</div>
					)}
				</div>
			) : null}
		</div>
	);
};

export default ScheduleCalender;
