import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { FiPlayCircle } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../apis';
import imgSrc from '../../assets/page/session/boy.svg';
import fillFeedback from '../../assets/page/session/feedback.svg';
import {
	setStateForRescheduleEnableTab,
	setStateRescheduleId,
} from '../../redux/session/sessionSlice';
import {
	getEligibleSessionsOfUser,
	getSessionsOfUserForAllSessionTypes,
	getUpcomingSessionsOfUser,
} from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import Recordings1v1 from '../Recordings1v1';
import AccioButton from '../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../notification';
import PopUpBox from '../shared/PopUpBox';
import SessionFeedback from './SessionFeedback';

const DisplaySessionDetails: React.FC<{
	data: any;
	setOpen: (open: boolean) => void;
	numberOfSessionsCancelledOrRescheduledTillNow: number;
	numberOfCancellationsAllowed: number;
	onYourSessionTabChange: (tabIndex: number) => void;
}> = ({
	data,
	onYourSessionTabChange,
	setOpen,
	numberOfSessionsCancelledOrRescheduledTillNow,
	numberOfCancellationsAllowed,
}) => {
	const dispatch = useDispatch<AppDispatch>();

	const [
		isCancellationLimitAvailable,
		setRemoveCancellationLimitAvailable,
	] = useState(false);

	useEffect(() => {
		if (numberOfCancellationsAllowed > 0) {
			setRemoveCancellationLimitAvailable(true);
		} else if (
			numberOfCancellationsAllowed === undefined ||
			numberOfSessionsCancelledOrRescheduledTillNow ===
				undefined
		) {
			setRemoveCancellationLimitAvailable(false);
		}
	}, [
		numberOfCancellationsAllowed,
		numberOfSessionsCancelledOrRescheduledTillNow,
	]);

	const [openRecordingModal, setOpenRecordingModal] =
		useState(false);

	const handelCloseRecordingModal = () =>
		setOpenRecordingModal(false);

	const handleChangeTab = (tabIndex: number) => {
		if (onYourSessionTabChange) {
			onYourSessionTabChange(tabIndex);
		}
	};

	const [openPopUpReschedule, setOpenPopUpReschedule] =
		useState(false);
	const [openPopUpCancel, setOpenPopUpCancel] =
		useState(false);

	const handelPopUpCancel = () => {
		setOpenPopUpCancel(false);
	};

	const userState = useSelector((state: any) => state.user);

	const { user } = userState;

	const handelPopUpReschedule = () => {
		setOpenPopUpReschedule(false);
		dispatch(setStateForRescheduleEnableTab(false));
	};

	const joinZoomVideoSDKSession = async (room: string) => {
		const typeUser =
			user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
		const userType = typeUser === 'MENTOR' ? 1 : 11;
		const role = 1;
		const data =
			await services.sessionService.fetchZoomVideoLink(
				room,
				user.id,
				userType,
				{
					sessionName: room,
					role: role,
					sessionKey: room,
					userIdentity: user.email,
				}
			);

		if (data.joinLink) {
			window.open(data.joinLink, '_blank');
		} else {
			window.open(
				`https://meeting.acciojob.com/video?topic=${room}&role=${role}&sessionKey=${room}&userIdentity=${user.email}&signature=${data}&name=${user.email}`,
				'_blank'
			);
		}
	};

	const handleSessionCancel = async (sessionId: string) => {
		try {
			const data =
				await services.sessionService.cancelSession(
					sessionId
				);
			if (data.status === 200 || data.status === 201) {
				notifySuccess(data?.data?.message);
			} else {
				notifyError(data?.data?.message);
			}
		} catch (err: any) {
			notifyError(err?.message);
		} finally {
			// Execute the following code regardless of success or failure
			dispatch(getSessionsOfUserForAllSessionTypes());
			dispatch(getUpcomingSessionsOfUser());
			dispatch(getEligibleSessionsOfUser());
		}
	};

	if (data == null) {
		return (
			<div className="no-feedback">
				<p>No feedback available !</p>{' '}
			</div>
		);
	} else {
		return (
			<div className="session-details-data-box">
				{data?.sessionObject?.sessionTag === 'Upcoming' ? (
					<div className="session-details-data-box-upcoming">
						<p className="session-details-data-box-upcoming-title">
							Session details
						</p>
						<div className="session-details-data-box-mentor-info">
							<div className="col col-1">
								<span className="text-1">Mentor </span>
								<span className="text-2">
									{data?.mentorName}
								</span>
							</div>
							<div className="col col-2">
								<span className="text-1">Phone no.</span>
								<span className="text-2">
									{data?.mentorPhone}
								</span>
							</div>
							<div className="col col-3">
								<span className="text-1">Session date</span>
								<span className="text-2">
									{moment(
										data?.sessionObject?.sessionDate
									).format('h:mm a, D MMM YY')}
								</span>
							</div>
						</div>
						<div className="session-details-data-box-btn-wrapper">
							<div className="session-details-btn-wrapper">
								{data?.isAllowedToJoin ? (
									<AccioButton
										title="Join"
										action={() => {
											console.log(
												'data?.sessionId',
												data?.sessionId
											);
											joinZoomVideoSDKSession(data?.room);
										}}
										icon={''}
										variant="primary"
										disabled={!data?.isAllowedToJoin}
										customClass={`${
											data?.isAllowedToJoin
												? ''
												: 'disabled'
										}`}
									/>
								) : (
									<AccioButton
										title="Reschedule"
										action={() =>
											setOpenPopUpReschedule(true)
										}
										variant="primary"
										icon={''}
										disabled={!data?.IsRescheduleAllowed}
										customClass={`${
											data?.IsRescheduleAllowed
												? ''
												: 'disabled'
										}`}
									/>
								)}

								<AccioButton
									title="Cancel"
									action={() => {
										setOpenPopUpCancel(true);
									}}
									icon={''}
									variant="primary"
									disabled={!data?.IsRescheduleAllowed}
									customClass={`${
										data?.IsRescheduleAllowed
											? ''
											: 'disabled'
									}`}
								/>
								<PopUpBox
									handleClose={handelPopUpCancel}
									openPopUp={openPopUpCancel}
								>
									<div className="popup-modal-box-wrapper">
										<p className="modal-box-title">
											Do you want to Cancel ?
										</p>
										{/* {numberOfCancellationsAllowed &&
									numberOfSessionsCancelledTillNow ? ( */}
										{isCancellationLimitAvailable ? (
											<p
												className={`modal-box-text-limit ${
													isCancellationLimitAvailable &&
													numberOfCancellationsAllowed -
														numberOfSessionsCancelledOrRescheduledTillNow <=
														0
														? 'modal-box-text-limit-cross'
														: ''
												}`}
											>
												Cancel Limit :{' '}
												{numberOfCancellationsAllowed -
													numberOfSessionsCancelledOrRescheduledTillNow}
											</p>
										) : null}
										{/* ) : null} */}
										<p className="modal-box-text">
											Are you sure you want to <b>Cancel</b>{' '}
											your <b>{data?.sessionTypeName}</b>{' '}
											Sessions?
										</p>
										<div className="modal-box-btn-wrapper">
											<AccioButton
												title="No"
												action={() =>
													setOpenPopUpCancel(false)
												}
												variant="outline"
												className="no"
											/>
											<AccioButton
												title="Yes"
												variant="primary"
												action={async () => {
													setOpen(false);
													setOpenPopUpCancel(false);
													await handleSessionCancel(
														data?.sessionId
													);
												}}
												className="yes"
											/>
										</div>
									</div>
								</PopUpBox>

								<PopUpBox
									handleClose={handelPopUpReschedule}
									openPopUp={openPopUpReschedule}
								>
									<div className="popup-modal-box-wrapper">
										<p className="modal-box-title">
											Do you want to Reschedule ?
										</p>
										{isCancellationLimitAvailable ? (
											<p
												className={`modal-box-text-limit ${
													isCancellationLimitAvailable &&
													numberOfCancellationsAllowed -
														numberOfSessionsCancelledOrRescheduledTillNow <=
														0
														? 'modal-box-text-limit-cross'
														: ''
												}`}
											>
												Reschedule Limit :{' '}
												{numberOfCancellationsAllowed -
													numberOfSessionsCancelledOrRescheduledTillNow}
											</p>
										) : null}
										<p className="modal-box-text">
											Are you sure you want to{' '}
											<b>Reschedule</b> your your{' '}
											<b>{data?.sessionTypeName}</b>{' '}
											Sessions?
										</p>

										<div className="modal-box-btn-wrapper">
											<AccioButton
												title="No"
												action={() => {
													setOpenPopUpReschedule(false);
													dispatch(
														setStateForRescheduleEnableTab(
															false
														)
													);
												}}
												variant="outline"
												customClass="no"
											/>

											<AccioButton
												title="Yes"
												customClass="yes"
												variant="primary"
												action={() => {
													setOpenPopUpReschedule(false);
													handleChangeTab(0);
													dispatch(
														setStateForRescheduleEnableTab(
															true
														)
													);
													dispatch(
														setStateRescheduleId(
															data?.sessionId
														)
													);
												}}
											/>
										</div>
									</div>
								</PopUpBox>
							</div>
							<div className="session-details-text-wrapper">
								{isCancellationLimitAvailable ? (
									<p
										className={`modal-box-text-limit ${
											numberOfCancellationsAllowed -
												numberOfSessionsCancelledOrRescheduledTillNow <=
											0
												? 'modal-box-text-limit-cross'
												: ''
										}`}
									>
										Available Cancellations :{' '}
										{numberOfCancellationsAllowed -
											numberOfSessionsCancelledOrRescheduledTillNow}
									</p>
								) : null}
							</div>
						</div>
					</div>
				) : data?.sessionObject?.sessionTag === 'Past' ? (
					<div className="session-details-data-box-past">
						{data?.feedbackStatus === 'Fill Feedback' ? (
							<div className="feedback-status-past-1">
								<div className="past-1">
									<p className="session-details-data-box-past-title">
										Session details
									</p>
									<div className="session-details-data-box-mentor-info">
										<div className="col col-1">
											<span className="text-1">
												Mentor{' '}
											</span>
											<span className="text-2">
												{data?.mentorName}
											</span>
										</div>
										<div className="col col-2">
											<span className="text-1">
												Phone no.
											</span>
											<span className="text-2">
												{data?.mentorPhone}
											</span>
										</div>
										<div className="col col-3">
											<span className="text-1">
												Session date
											</span>
											<span className="text-2">
												{moment(
													data?.sessionObject?.sessionDate
												).format('h:mm a, D MMM YY')}
											</span>
										</div>
									</div>
								</div>

								<div className="fill-feedback-box">
									<div className="left">
										<span className="text-1">
											Fill mentor feedback to view your
											score
										</span>
										<span className="text-2">
											Your feedback is important for us and
											for the mentor in order to improve
											your experience during sessions
										</span>
										<AccioButton
											action={() =>
												window.open(
													data?.studentFeedbackLink
												)
											}
											icon={<AiOutlineArrowRight />}
											variant="primary"
											title={'Fill Feedback'}
											iconPosition="right"
										/>
									</div>
									<div className="right">
										<img
											src={fillFeedback}
											alt=""
										/>
									</div>
								</div>
							</div>
						) : data?.feedbackStatus ===
						  'Awaiting Feedback' ? (
							<div className="feedback-status-2">
								<div className="past-2">
									<p className="session-details-data-box-past-title">
										Session details
									</p>
									<div className="session-details-data-box-mentor-info">
										<div className="col col-1">
											<span className="text-1">
												Mentor{' '}
											</span>
											<span className="text-2">
												{data?.mentorName}
											</span>
										</div>
										<div className="col col-2">
											<span className="text-1">
												Phone no.
											</span>
											<span className="text-2">
												{data?.mentorPhone}
											</span>
										</div>
										<div className="col col-3">
											<span className="text-1">
												Session date
											</span>
											<span className="text-2">
												{moment(
													data?.sessionObject?.sessionDate
												).format('h:mm a, D MMM YY')}
											</span>
										</div>
									</div>
								</div>
								<div className="text">
									Awaiting Feedback
								</div>
							</div>
						) : data?.feedbackStatus === 'Filled' ? (
							<div className="feedback-status-past-3">
								<div className="past-3">
									<p className="session-details-data-box-past-title">
										Session details
									</p>
									<div className="session-details-data-box-mentor-info">
										<div className="col col-1">
											<span className="text-1">
												Mentor{' '}
											</span>
											<span className="text-2">
												{data?.mentorName}
											</span>
										</div>
										<div className="col col-2">
											<span className="text-1">
												Phone no.
											</span>
											<span className="text-2">
												{data?.mentorPhone}
											</span>
										</div>
										<div className="col col-3">
											<span className="text-1">
												Session date
											</span>
											<span className="text-2">
												{moment(
													data?.sessionObject?.sessionDate
												).format('h:mm a, D MMM YY')}
											</span>
										</div>
									</div>
									<div className="session-video-wrapper">
										{data.recordings.length > 0 ? (
											<>
												<p className="text-1">
													{' '}
													View Recording{' '}
												</p>
												<AccioButton
													title={'View Record'}
													variant="outline"
													action={() =>
														setOpenRecordingModal(true)
													}
													icon={<FiPlayCircle />}
													iconPosition="left"
													customClass="view-recording-btn"
												/>
												<Recordings1v1
													isJitsi={
														data?.recordingType === 'jitsi'
													}
													currentRecordings={
														data.recordings
													}
													open={openRecordingModal}
													handleClose={
														handelCloseRecordingModal
													}
												/>
											</>
										) : (
											<p className="text-no-video">
												No Recordings Available
											</p>
										)}
									</div>
								</div>
								<div className="feedback-display-box">
									<SessionFeedback
										currentFeedback={{
											...data?.mentorFeedback,
											sessionType: data?.sessionTypeName,
											score: data?.totalScore,
											isMentorShip: data?.isMentorship,
										}}
									/>
								</div>
							</div>
						) : data?.feedbackStatus ===
						  'No Feedback Available' ? (
							<div className="feedback-status-past-4">
								No Feedback Available
							</div>
						) : null}
					</div>
				) : (
					<div className="session-details-data-box-other-wrapper">
						<div className="session-details-data-box-other">
							<p className="session-details-data-box-upcoming-title">
								Session details
							</p>
							<div className="session-details-data-box-mentor-info">
								<div className="col col-1">
									<span className="text-1">Mentor </span>
									<span className="text-2">
										{data?.mentorName}
									</span>
								</div>
								<div className="col col-2">
									<span className="text-1">Phone no.</span>
									<span className="text-2">
										{data?.mentorPhone}
									</span>
								</div>
								<div className="col col-3">
									<span className="text-1">
										Session date
									</span>
									<span className="text-2">
										{moment(
											data?.sessionObject?.sessionDate
										).format('h:mm a, D MMM YY')}
									</span>
								</div>
							</div>
						</div>
						<div className="session-details-data-box-cancel-history">
							<p className="cancel-history">
								Reason :&nbsp;{' '}
								{data?.sessionObject?.sessionTag}
							</p>
							<div className="cancel-history-img">
								<img src={imgSrc} />
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
};

export default DisplaySessionDetails;
