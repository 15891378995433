import { Loader } from '@acciojob/loader';
import {
	Badge,
	Box,
	Modal,
	ModalClose,
	ModalDialog,
} from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { services } from '../../apis';
import { PopupState } from '../../pages/jobportal/JobPortal';

interface PopupProps {
	drivesPopup: PopupState;
	setDrivesPopup: (newState: PopupState) => void;
}

const DrivesPopup = ({
	drivesPopup,
	setDrivesPopup,
}: PopupProps) => {
	const [loading, setLoading] = useState(false);
	const [companyNames, setCompanyNames] = useState(
		[] as any[]
	);

	useEffect(() => {
		const fetchDrive = async () => {
			if (drivesPopup.identifier !== '') {
				setLoading(true);
				const data =
					await services.jobPortalService.fetchDrive(
						drivesPopup.identifier
					);
				setCompanyNames(data.data);
				setTimeout(() => {
					setLoading(false);
				}, 300);
			}
		};

		fetchDrive();
	}, [drivesPopup]);

	const handleClose = () => {
		setDrivesPopup({
			isOpen: false,
			identifier: '',
		});
	};

	const [scroll, setScroll] = React.useState<boolean>(true);

	return (
		<Modal
			open={drivesPopup.isOpen}
			onClose={handleClose}
			className="thin-scrollbar"
		>
			<ModalDialog aria-labelledby="dialog-vertical-scroll-title">
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Box
					sx={{
						overflowY: scroll ? 'scroll' : 'initial',
						mx: 'calc(-1 * var(--ModalDialog-padding))',
						px: 'var(--ModalDialog-padding)',
						maxHeight: 'calc(100% - 64px)',

						'&::-webkit-scrollbar': {
							width: '5px',
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#D0D5DD',
							borderRadius: '5px',
						},
						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#98A2B3',
						},
					}}
				>
					<div className="job-portal-drives-popup-inner">
						{loading && (
							<div className="loader-container">
								<Loader
									factVisibility={false}
									width={'100%'}
									height={'100%'}
								/>
							</div>
						)}
						{!loading && (
							<>
								<div className="popup-header">
									<Badge
										badgeContent={companyNames.length}
										sx={{ color: '#2E80EC' }}
									>
										<h2>
											{drivesPopup.identifier} Details
										</h2>
									</Badge>
								</div>
								{companyNames.length > 0 ? (
									<div className="popup-body">
										<table>
											<thead>
												<tr>
													<th> S.No. </th>
													<th> Company Name</th>
													<th> Your Stage</th>
												</tr>
											</thead>
											<tbody>
												{companyNames.map((item, idx) => (
													<tr key={idx}>
														<td>{idx + 1}</td>
														<td>{item.companyNames}</td>
														<td>{item.companyStage}</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								) : (
									<p>No data found.</p>
								)}
							</>
						)}
					</div>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default DrivesPopup;
