import moment from 'moment';
import React, { useState } from 'react';
import { BsClock } from 'react-icons/bs';
import {
	MdOutlineArrowForwardIos,
	MdOutlineLogin,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../apis';
import {
	setAllAvailableSlots,
	setStateForRescheduleEnableTab,
	setStateMentorId,
	setStateRescheduleId,
	setStateSelectedDateTimeForBooking,
	setStateSessionCategory,
	setStateSessionTypeId,
} from '../../redux/session/sessionSlice';
import { getSessionDetailsOfUserFromSessionType } from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import AccioButton from '../elements/Button';
import RightAlignModal from '../shared/RightAlignModal';
import { PrevMentors } from './MentorshipSessionBookingBox';
import SessionModalBox from './SessionModalBox';

interface UpcomingSessionCardProps {
	sessionTypeId: string;
	sessionType: string;
	isAllowedToJoin: boolean;
	sessionDate: string;
	sessionDuration: number;
	index: number;
	sessionId: string;
	room: string;
	category: string;
}

interface MentorFeedback {
	createdAt: string;
	[key: string]: string | MentorQuestionFeedback | null;
}

interface MentorQuestionAnswer {
	[key: string]: string;
}

interface MentorQuestionFeedback {
	[key: string]: string | MentorQuestionAnswer | null;
}

interface SessionDetail {
	sessionTag: string;
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	totalScore: string | number;
	studentFeedbackLink: string;
	isAllowedToJoin: boolean;
	mentorFeedback: MentorFeedback | null;
	recordings: string[];
	sessionId: string;
	sessionTypeId: string;
	mentorName: string;
	mentorPhone: string;
}

interface SessionData {
	modalData: {
		allSessions: SessionDetail[];
		isEligibleToBookSession: boolean;
		prevMentors: PrevMentors;
		isMentorship: boolean;
		sessionTypeName: string;
	};
	typeBtn: string;
}

const UpcomingSessionCard: React.FC<
	UpcomingSessionCardProps
> = ({
	sessionTypeId,
	category,
	sessionType,
	isAllowedToJoin,
	sessionDate,
	sessionDuration,
	index,
	sessionId,
	room,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [isOpened, setIsOpened] = useState<boolean>(false);

	const handleOpen = () => {
		setIsOpened(true);
		setOpen(true);
	};
	const [typeBtn, setTypeBtn] = useState('');
	const dispatch = useDispatch<AppDispatch>();

	const SessionState = useSelector(
		(state: any) => state.session
	);

	const userState = useSelector((state: any) => state.user);

	const { user } = userState;

	const { allSessionDetails } = SessionState;

	console.log('userState', userState);

	const handleClose = () => {
		setIsOpened(false);
		setTimeout(() => {
			dispatch(setStateMentorId(null));
			dispatch(setAllAvailableSlots([]));
			dispatch(setStateSessionTypeId(null));
			dispatch(setStateForRescheduleEnableTab(false));
			dispatch(setStateSelectedDateTimeForBooking(null));
			dispatch(setStateRescheduleId(null));
			dispatch(setStateForRescheduleEnableTab(null));
			setOpen(false);
		}, 1000);
	};

	const ModalDataGenerator = (
		sessionTypeId: string,
		category: string
	) => {
		handleOpen();

		dispatch(
			getSessionDetailsOfUserFromSessionType(sessionTypeId)
		);

		dispatch(setStateSessionTypeId(sessionTypeId));
		dispatch(setStateSessionCategory(category));
	};

	const joinZoomVideoSDKSession = async (room: string) => {
		const typeUser =
			user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
		const userType = typeUser === 'MENTOR' ? 1 : 11;
		const role = 1;
		const data =
			await services.sessionService.fetchZoomVideoLink(
				room,
				user.id,
				userType,
				{
					sessionName: room,
					role: role,
					sessionKey: room,
					userIdentity: user.email,
				}
			);

		if (data.joinLink) {
			window.open(data.joinLink, '_blank');
		} else {
			window.open(
				`https://meeting.acciojob.com/video?topic=${room}&role=${role}&sessionKey=${room}&userIdentity=${user.email}&signature=${data}&name=${user.email}`,
				'_blank'
			);
		}
	};

	return (
		<div
			className="upcoming-session-card-wrapper"
			style={{
				borderLeft:
					index % 2 === 0
						? '8px solid #BA24D5'
						: '8px solid #875BF7',
			}}
		>
			<p className="card-title">{sessionType}</p>
			<div className="card-content">
				{isAllowedToJoin ? (
					<AccioButton
						title="Join Session"
						variant="primary"
						icon={
							<MdOutlineLogin
								style={{ fontSize: '18px' }}
							/>
						}
						disabled={false}
						customClass="card-join-live-btn"
						iconPosition="right"
						action={() => joinZoomVideoSDKSession(room)}
					/>
				) : (
					<div className="card-session-time">
						<span className="icon">
							<BsClock />
						</span>
						<span className="date-time-text">
							{moment(sessionDate).format(
								"D MMM YY' - h:mm A"
							)}
						</span>
					</div>
				)}
				<AccioButton
					title="View Details"
					variant="outline"
					icon={<MdOutlineArrowForwardIos />}
					iconPosition="right"
					disabled={false}
					customClass="card-view-details-btn"
					action={() => {
						ModalDataGenerator(sessionTypeId, category);
						setTypeBtn('VIEW DETAILS');
					}}
				/>
			</div>
			<RightAlignModal
				children={
					<SessionModalBox
						modalData={allSessionDetails}
						typeBtn={'VIEW DETAILS'}
						setOpen={setOpen}
					/>
				}
				// children={<></>}
				handleClose={handleClose}
				handleOpen={handleOpen}
				isOpened={isOpened}
				setIsOpened={setIsOpened}
				open={open}
				setOpen={setOpen}
			/>
		</div>
	);
};

export default UpcomingSessionCard;
