import React, {
	FC,
	ReactNode,
	useEffect,
	useState,
} from 'react';
import { services } from '../../../apis';
import {
	ContestFeedbackType,
	FeedbackCTA,
	FeedbackRating,
} from '../../../redux/allFeedbackModal/allFeedbackModal.slice';
import AccioButton from '../../elements/Button';
import { notifySuccess } from '../../notification';
import { Modal, ModalClose, Sheet } from '@mui/joy';
import logo from '../../../assets/images/acciojobLogo.svg';
import { useLocation } from 'react-router-dom';
import {
	PopupTypes,
	StudentAssesmentData,
} from '../../../redux/popups/popup.type';
import StudentLectureFeedbackPopUp from '../../studentLectureFeedbackPopUp/studentLectureFeedbackPopUp';
import StudentAssesmentModal from './StudentAssesmentModal';

const SectionFeedbackPopUp = (props: {
	sectionalFeedbackData: any;
	children: ReactNode;
}) => {
	const { sectionalFeedbackData, children } = props;

	const [showModal, setShowModal] = useState(true);

	const [selectedRatingVal, setSelectedRatingVal] =
		useState<number | null>(null);

	const location = useLocation();

	const [textAreaValue, setTextAreaValue] = useState('');

	const handleTextAreaChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setTextAreaValue(e.target.value);
	};

	const handleRatingClick = async (
		rating: number | null
	) => {
		setSelectedRatingVal(rating);

		try {
			const res = await services.popupService.recordRating({
				feedbackType:
					sectionalFeedbackData?.data.feedbackType,
				page: location.pathname,
				rating: rating,
				sectionId:
					sectionalFeedbackData?.contestId ?? undefined,
				hasClosedFeedbackPopup: false,
			});
			notifySuccess(res?.message);
		} catch (error) {
			console.error(error);
		}
	};

	const handleSubmitAction = async () => {
		try {
			const res = await services.popupService.recordRating({
				feedbackType:
					sectionalFeedbackData?.data.feedbackType,
				page: location.pathname,
				rating: selectedRatingVal,
				feedback: textAreaValue,
				sectionId: sectionalFeedbackData?.contestId,
				hasClosedFeedbackPopup: false,
			});
			notifySuccess(res?.message);
		} catch (error) {
			console.error(error);
		} finally {
			setShowModal(false);
		}
	};

	useEffect(() => {
		if (sectionalFeedbackData?.showPopup === false) {
			return;
		} else if (
			sectionalFeedbackData?.data.popupType ===
			PopupTypes.CONTEST_SECTION
		) {
			// Submitting initial value for feedback as null, So that feedback modal will be shown only once.
			(async () => {
				try {
					const res =
						await services.popupService.recordRating({
							feedbackType:
								sectionalFeedbackData?.data.feedbackType,
							page: location.pathname,
							rating: null,
							feedback: textAreaValue,
							sectionId: sectionalFeedbackData?.contestId,
							hasClosedFeedbackPopup: true,
						});
				} catch (error) {
					console.error(error);
				}
			})();
		}
	}, [sectionalFeedbackData]);

	if (sectionalFeedbackData?.showPopup === false) {
		return <>{children}</>;
	} else
		return (
			<>
				{!sectionalFeedbackData ? null : sectionalFeedbackData.popupType ===
				  PopupTypes.LIVE_LECTURE_FEEDBACK ? (
					<StudentLectureFeedbackPopUp
						data={sectionalFeedbackData.data}
						popupType={PopupTypes.LIVE_LECTURE_FEEDBACK}
					/>
				) : sectionalFeedbackData.popupType ===
				  PopupTypes.RECORDED_LECTURE_FEEDBACK ? (
					<StudentLectureFeedbackPopUp
						data={sectionalFeedbackData.data}
						popupType={PopupTypes.RECORDED_LECTURE_FEEDBACK}
					/>
				) : sectionalFeedbackData.popupType ===
				  PopupTypes.STUDENT_ASSESSMENT_TEST ? (
					<StudentAssesmentModal
						data={
							sectionalFeedbackData.data as StudentAssesmentData
						}
					/>
				) : (
					<Modal
						aria-labelledby="modal-title"
						aria-describedby="modal-desc"
						open={
							sectionalFeedbackData.data?.showPopup &&
							showModal
						}
						onClose={() => setShowModal(false)}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<Sheet
							variant="outlined"
							sx={{
								maxWidth: 500,
								borderRadius: 'md',
								p: 0,
								boxShadow: 'lg',
								border: 'none',
								outline: '1px solid transparent',
							}}
						>
							<ModalClose
								variant="plain"
								sx={{ m: 1, right: '-40px', top: '-40px' }}
							/>
							<div className="all-feedback-modal-rating-wrapper">
								<p className="modal-title">
									{
										sectionalFeedbackData.data
											?.popUpHeaderText
									}
								</p>
								<div className="modal-rating-container">
									<div className="rating-wrapper">
										<div className="rating-holder">
											{sectionalFeedbackData.data?.ratings
												?.length > 0
												? sectionalFeedbackData.data?.ratings?.map(
														(
															rating: FeedbackRating,
															i: number
														) => (
															<div
																className="rating-component-wrapper"
																key={i}
															>
																<span
																	className={`rating-component ${
																		rating?.rating <=
																		(selectedRatingVal || 0)
																			? 'selected'
																			: ''
																	}`}
																	onClick={() =>
																		handleRatingClick(
																			rating?.rating || null
																		)
																	}
																></span>
																<span className="rating-number">
																	{rating?.rating}
																</span>
															</div>
														)
												  )
												: null}
										</div>
										<div className="modal-rating-text">
											<span className="text">
												{
													sectionalFeedbackData.data
														?.lowestRatingText
												}
											</span>
											<span className="text">
												{
													sectionalFeedbackData.data
														?.highestRatingText
												}
											</span>
										</div>
									</div>

									{selectedRatingVal ? (
										<div className="modal-comment-wrapper">
											<div className="modal-input-feedback-wrapper">
												<p className="modal-input-feedback-title">
													{selectedRatingVal > 3
														? sectionalFeedbackData.data
																?.moreThanThree
														: sectionalFeedbackData.data
																?.lessThanFour}
												</p>
												<textarea
													cols={30}
													rows={5}
													className="modal-input-feedback"
													value={textAreaValue}
													onChange={handleTextAreaChange}
													placeholder="Describe here"
												/>
											</div>
										</div>
									) : null}

									<div className="modal-footer-wrapper">
										<img
											src={logo}
											alt="acciojob-logo"
										/>
										{sectionalFeedbackData.data?.ctas
											?.length
											? sectionalFeedbackData.data?.ctas.map(
													(cta: FeedbackCTA) => (
														<AccioButton
															title={cta?.ctaText}
															disabled={
																selectedRatingVal === null
															}
															customClass={
																selectedRatingVal === null
																	? 'disabled-btn'
																	: ''
															}
															action={handleSubmitAction}
														/>
													)
											  )
											: null}
									</div>
								</div>
							</div>
						</Sheet>
					</Modal>
				)}
				{children}
			</>
		);
};

export default SectionFeedbackPopUp;
