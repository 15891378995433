import React from 'react';
import exp from '../../assets/page/session/exp.svg';
import notify from '../../assets/page/session/notify.svg';
import reader from '../../assets/page/session/reader.svg';

const SessionInfo = () => {
	return (
		<div className="session-info-wrapper">
			<p className="session-info-title">
				1v1 Sessions are meant to help you with :
			</p>
			<p className="session-info-txt txt-1">
				<img
					className="icon"
					src={exp}
				/>
				<span className="text">
					Job Interview Experience
				</span>
			</p>
			<p className="session-info-txt txt-2">
				<img
					className="icon"
					src={notify}
				/>
				<span className="text">Comprehensive Feedback</span>
			</p>
			<p className="session-info-txt txt-3">
				<img
					className="icon"
					src={reader}
				/>
				<span className="text">
					Personalised Doubt Solving
				</span>
			</p>
		</div>
	);
};

export default SessionInfo;
