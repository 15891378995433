import { BiEdit } from 'react-icons/bi';
import { useAppSelector } from '../../../../redux/store';
import { EditProfileTabs } from './EditProfileTabs';

export const EditProfileModal = () => {
	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);
	return (
		<div className="edit-profile-modal">
			<div className="heading-flex">
				<BiEdit style={{ fontSize: '24px' }} />
				<p className="heading">
					Edit Profile{' '}
					{personalDetails.data?.isProfileLocked
						? ' (Locked)'
						: ''}
				</p>
			</div>
			{/* <p className="normal-text">
				Lorem ipsum dolor sit amet consectetur.
			</p> */}
			<EditProfileTabs />
		</div>
	);
};
