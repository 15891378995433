import React from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { BiLock } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const LockedScreen = () => {
	const navigate = useNavigate();
	return (
		<div className={'locked-screen'}>
			<div className={'locked-screen-bg'}></div>
			<div className={'lock-card'}>
				<BiLock />
				<p>
					This will be activated after your main batch
					starts
				</p>
				<button onClick={() => navigate('/playground')}>
					Go to Launchpad <AiOutlineArrowRight />
				</button>
			</div>
		</div>
	);
};

export default LockedScreen;
