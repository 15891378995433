import { ENDPOINTS as prodEndpoints } from './prod';
import { ENDPOINTS as stageEndPoints } from './stage';

const endpointProvider = () => {
	if (process.env.NODE_ENV === 'development') {
		return stageEndPoints.ROOT;
	} else return prodEndpoints.ROOT;
};

export default endpointProvider;
