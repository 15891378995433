import { useEffect } from 'react';

export const useOnClickOutside = (
	ref: React.RefObject<HTMLDivElement>,
	handler: () => void
) => {
	useEffect(() => {
		const listener = (event: MouseEvent) => {
			if (
				!ref.current ||
				ref.current.contains(event.target as Node)
			) {
				return;
			}
			handler();
		};
		document.addEventListener('mousedown', listener);
		return () => {
			document.removeEventListener('mousedown', listener);
		};
	}, [ref, handler]);
};
