import endpointProvider from '../config/endpoints';
import {
	CourseTokenService,
	HttpService,
	TokenService,
} from '../utils';
import { AiStudentAssessment } from './AiStudentAssessment';
import { CodingQuestionService } from './CodingQuestionService';
import { ContactBMService } from './ContactBM';
import { ContestService } from './ContestService';
import { CourseService } from './CourseService';
import { DAPlaygroundService } from './DAPlaygroundService';
import { FileUploadDownloadService } from './FileUploadDownLoadService';
import { JobPortalService } from './JobPortalService';
import { JupyterNoteBookService } from './JupyterNoteBookServices';
import { MentorService } from './MentorService';
import { MentorSessionFeedbackService } from './MentorSessionFeedbackService';
import { NotesService } from './NotesService';
import { PopupService } from './PopupService';
import { ProfileService } from './ProfileService';
import { ReferralService } from './ReferralService';
import { SessionService } from './SessionService';
import { SoftskillsService } from './SoftskillsService';
import { StudentLectureFeedbackService } from './StudentLectureFeedbackService';
import { UserService } from './UserService';
import { VariablesService } from './VariablesService';
import { WebDevQuestionService } from './WebDevQuestionService';
const endpoint = endpointProvider();

const tokenService = new TokenService('acciojobs-token');
const courseTokenService = new CourseTokenService(
	'selected-course-token'
);
const httpService = new HttpService(
	tokenService,
	courseTokenService
);
const userService = new UserService(httpService, endpoint);
const courseService = new CourseService(
	httpService,
	endpoint
);
const contestService = new ContestService(
	httpService,
	endpoint
);
const codingQuestionService = new CodingQuestionService(
	httpService,
	endpoint
);
const webDevQuestionService = new WebDevQuestionService(
	httpService,
	endpoint
);
const notesService = new NotesService(
	httpService,
	endpoint
);
const studentLectureFeedbackService =
	new StudentLectureFeedbackService(httpService, endpoint);
const mentorService = new MentorService(
	httpService,
	endpoint
);
const mentorSessionFeedbackService =
	new MentorSessionFeedbackService(httpService, endpoint);
const variablesService = new VariablesService(
	httpService,
	endpoint
);
const sessionService = new SessionService(
	httpService,
	endpoint
);
const referralService = new ReferralService(
	httpService,
	endpoint
);
const jobPortalService = new JobPortalService(
	httpService,
	endpoint
);
const fileUploadDownloadService =
	new FileUploadDownloadService(httpService, endpoint);

const jupyterNoteBookService = new JupyterNoteBookService(
	httpService,
	endpoint
);

const softskillsService = new SoftskillsService(
	httpService,
	endpoint
);

const daPlaygroundService = new DAPlaygroundService(
	httpService,
	endpoint
);

const popupService = new PopupService(
	httpService,
	endpoint
);

const aiStudentAssessmentService = new AiStudentAssessment(
	httpService,
	endpoint
);

const profileService = new ProfileService(
	httpService,
	endpoint
);

const contactBMService = new ContactBMService(
	httpService,
	endpoint
);

export interface Services {
	tokenService: TokenService;
	httpService: HttpService;
	userService: UserService;
	courseService: CourseService;
	contestService: ContestService;
	codingQuestionService: CodingQuestionService;
	notesService: NotesService;
	studentLectureFeedbackService: StudentLectureFeedbackService;
	mentorService: MentorService;
	mentorSessionFeedbackService: MentorSessionFeedbackService;
	variablesService: VariablesService;
	sessionService: SessionService;
	webDevQuestionService: WebDevQuestionService;
	referralService: ReferralService;
	jobPortalService: JobPortalService;
	fileUploadDownloadService: FileUploadDownloadService;
	jupyterNoteBookService: JupyterNoteBookService;
	softskillsService: SoftskillsService;
	aiStudentAssessmentService: AiStudentAssessment;
	daPlaygroundService: DAPlaygroundService;
	popupService: PopupService;
	profileService: ProfileService;
	contactBMService: ContactBMService;
}

export const services: Services = {
	tokenService,
	httpService,
	userService,
	courseService,
	contestService,
	codingQuestionService,
	notesService,
	studentLectureFeedbackService,
	mentorService,
	mentorSessionFeedbackService,
	variablesService,
	sessionService,
	webDevQuestionService,
	referralService,
	jobPortalService,
	fileUploadDownloadService,
	jupyterNoteBookService,
	softskillsService,
	daPlaygroundService,
	popupService,
	aiStudentAssessmentService,
	profileService,
	contactBMService,
};
