import { Typography } from '@mui/joy';
import { Box } from '@mui/material';
import { FC } from 'react';

export const PlacementReadyAnnouncement: FC = (props) => {
	return (
		<Box
			className="helpline-box"
			sx={{
				marginTop: '10px',
				backgroundColor: '#C70039',
				border: '1px solid #fff',
				outline: '1px solid #C70039',
				marginLeft: '2px',
			}}
		>
			<Typography
				component="h2"
				id="modal-title"
				level="h4"
				sx={{ color: '#fff !important' }}
				fontWeight="lg"
				mb={1}
			>
				Important! Placement Mock Validity
			</Typography>
			<Typography
				className={'placement-ready-msg'}
				sx={{ color: '#fff !important' }}
			>
				Mock Scores of Frontend Placement Readiness and
				Intermediate DSA Placement Readiness for Job
				eligiblity will be valid for 60 days w.e.f 4th
				September 2023.
			</Typography>
			<Typography
				className={'helpline-text'}
				sx={{
					marginTop: '0.5rem',
					color: '#fff !important',
				}}
			>
				Example : Score of Mock taken on 10th August will be
				valid till the 9th of October
			</Typography>
		</Box>
	);
};
