import { useState } from 'react';
import { GiPauseButton } from 'react-icons/gi';
import { useAppSelector } from '../../../redux/store';
import AccioButton from '../../elements/Button';
import { PauseModal } from '../Modals/PauseModal';

export const PauseCard = () => {
	const [open, setOpen] = useState(false);
	const { pauseStatus } = useAppSelector(
		(state) => state.user
	);

	console.log('pauseStatus>>>>', pauseStatus);
	if (pauseStatus) {
		if (pauseStatus.showPauseOption)
			return (
				<div className="profile-card pause-card">
					<div className="heading-flex">
						<GiPauseButton
							style={{ fontSize: '20px', color: '#eaaa08' }}
						/>
						<p className="heading">Pause Profile</p>
					</div>
					<div className="grey-flex">
						<div className="grey-box">
							<p className="grey-text number-text">
								{pauseStatus.availablePauses ?? '0'}
							</p>
							<p className="grey-text">Pauses Available</p>
						</div>
						<div className="grey-box">
							<p className="grey-text number-text">
								{pauseStatus.takenPauses ?? '0'}
							</p>
							<p className="grey-text">Pauses Taken</p>
						</div>
					</div>
					<AccioButton
						title="Pause Course"
						iconPosition="left"
						icon={<GiPauseButton />}
						customClass="yellow-btn"
						action={() => setOpen(true)}
					/>
					<PauseModal
						state={open}
						setState={setOpen}
					/>
				</div>
			);
		else {
			return null;
		}
	} else {
		return null;
	}
};
