import { Loader } from '@acciojob/loader';
import { debounce } from 'lodash';
import { useRef, type FC } from 'react';
import { BsBookmark, BsBookmarkFill } from 'react-icons/bs';
import { FiArrowRightCircle } from 'react-icons/fi';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import {
	addBookmark,
	fetchQuestions,
	removeBookmark,
} from '../../redux/gym/thunks/gym.thunk';
import {
	AttemptStatusEnum,
	DifficultyTypeEnum,
	QuestionDataType,
} from '../../redux/gym/type';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';

type GymQuestionsPropsType = {
	tabId: string;
};

const getDifficultyComponent = (
	difficulty: DifficultyTypeEnum
) => {
	switch (difficulty) {
		case DifficultyTypeEnum.EASY:
			return (
				<div className="difficulty-chip easy-chip">
					{DifficultyTypeEnum.EASY}
				</div>
			);
		case DifficultyTypeEnum.MEDIUM:
			return (
				<div className="difficulty-chip medium-chip">
					{DifficultyTypeEnum.MEDIUM}
				</div>
			);
		case DifficultyTypeEnum.HARD:
			return (
				<div className="difficulty-chip hard-chip">
					{DifficultyTypeEnum.HARD}
				</div>
			);
		default:
			return (
				<div className="difficulty-chip easy-chip">
					{DifficultyTypeEnum.EASY}
				</div>
			);
	}
};

export const GymQuestions: FC<GymQuestionsPropsType> = (
	props
) => {
	const dispatch = useAppDispatch();
	const { questionsByTabId, selectedTabId, isLoading } =
		useAppSelector((state) => state.gym);

	const questionsObj = questionsByTabId[props.tabId] ?? {
		questions: [],
		totalPages: 1,
		page: 0,
	};

	console.log({ questionsObj });

	const fetchMoreDataDebounced = useRef(
		debounce(() => {
			dispatch(
				fetchQuestions({ selectedTabId: props.tabId })
			);
		}, 500)
	);

	const fetchMoreData = () => {
		if (selectedTabId === props.tabId) {
			fetchMoreDataDebounced.current();
		}
	};

	const onBookmarkClick = (index: number) => {
		dispatch(
			addBookmark({ index, selectedTabId: props.tabId })
		);
	};

	const onBookmarkRemoveClick = (index: number) => {
		dispatch(
			removeBookmark({ index, selectedTabId: props.tabId })
		);
	};

	return (
		<div className="gym-questions">
			<div className="gym-row-distribution gym-question-head ">
				<div />
				<div>Problem name</div>
				<div>Max Score</div>
				<div>Your Score</div>
				<div>Difficulty</div>
				<div>Status</div>
				<div />
			</div>
			{questionsObj.questions.length ? (
				<InfiniteScroll
					hasMore={
						questionsObj.page <= questionsObj.totalPages
					}
					dataLength={questionsObj.questions.length}
					next={fetchMoreData}
					loader={
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								width: '100%',
								margin: '12px 0px',
								alignItems: 'center',
							}}
						>
							<Loader
								height={'75px'}
								borderVisibility={false}
								factVisibility={false}
							/>
						</div>
					}
					scrollableTarget={`gym-${props.tabId}-panel-scroll`}
				>
					{questionsObj.questions.map((question, index) => {
						return (
							<GymQuestion
								question={question}
								index={index}
								onBookmarkClick={onBookmarkClick}
								onBookmarkRemoveClick={
									onBookmarkRemoveClick
								}
								tabId={props.tabId}
							/>
						);
					})}
				</InfiniteScroll>
			) : (
				<div className="gym-no-questions">
					{isLoading ? (
						<Loader
							height={'75px'}
							borderVisibility={false}
							factVisibility={false}
						/>
					) : (
						'No questions found'
					)}
				</div>
			)}
		</div>
	);
};

const GymQuestion: FC<{
	question: QuestionDataType;
	index: number;
	onBookmarkClick: (index: number) => void;
	onBookmarkRemoveClick: (index: number) => void;
	tabId: string;
}> = ({
	index,
	onBookmarkClick,
	onBookmarkRemoveClick,
	question,
	tabId,
}) => {
	return (
		<div className="gym-question-row gym-row-distribution">
			<div>
				{question.bookmarked ? (
					<BsBookmarkFill
						className="bookmarked"
						onClick={() => onBookmarkRemoveClick(index)}
					/>
				) : (
					<BsBookmark
						className="not-bookmarked"
						onClick={() => onBookmarkClick(index)}
					/>
				)}
			</div>
			<div>
				{question.title}{' '}
				{question.questionType === 'JS' ? (
					<div className="js-only">JS Only</div>
				) : question.questionType === 'PYTHON' ? (
					<div className="python-only">Python Only</div>
				) : null}
			</div>
			<div>{question.score}</div>
			<div>{question.user_score}</div>
			<div>
				{getDifficultyComponent(question.difficulty)}
			</div>
			<div>{question.attemptStatus.toLowerCase()}</div>
			<SolvingButton
				attemptStatus={question.attemptStatus}
				questionId={question.id}
				tabId={tabId}
			/>
		</div>
	);
};

const SolvingButton: FC<{
	tabId: string;
	attemptStatus: AttemptStatusEnum;
	questionId: string;
}> = ({ tabId, attemptStatus, questionId }) => {
	const navigate = useNavigate();
	const label = (function () {
		switch (attemptStatus) {
			case AttemptStatusEnum.ATTEMPTED:
				return <>Continue Solving</>;
			case AttemptStatusEnum.UNATTEMPTED:
				return (
					<>
						Start Solving <FiArrowRightCircle />
					</>
				);
			case AttemptStatusEnum.SOLVED:
				return <>Solved</>;
		}
	})();

	const onClick = () => {
		switch (tabId) {
			case 'coding': {
				window.open(
					`${window.origin}/idle?question=${questionId}`,
					'_blank'
				);
				return;
			}
			case 'webdev': {
				window.open(
					`${window.origin}/web-idle?question=${questionId}`,
					'_blank'
				);
				return;
			}
			case 'conceptual': {
				window.open(
					`${window.origin}/gym-aptitude?questionId=${questionId}`,
					'_blank'
				);
			}
		}
	};

	return <div onClick={onClick}>{label}</div>;
};
