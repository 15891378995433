import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import React, {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import MyMultiSelectField from './formElements/MyMultiselectField';
import MySelectField from './formElements/MySelectField';
import MyTextField from './formElements/MyTextField';
import {
	jobStatusOptions,
	jobTypeOptions,
	// GeneralProfileFormFields,
	PlacementProfileFormFields,
	preferredSpeakingLanguageOptions,
	homeStateOptions,
	collegeDepartmentOptions,
	masterCollegeDegreeOptions,
} from './student_personal_details';
import { useAppDispatch } from '../../redux/store';
import {
	fetchGeneralProfile,
	fetchLeetcodeUserName,
	updateLeetcodeUserName,
	updateProfile,
} from '../../redux/user/userSlice';
import { useSelector } from 'react-redux';
import { services } from '../../apis';
import moment from 'moment';
import { GeneralProfileFormFields } from './GeneralProfileFormFields';
import {
	notifyError,
	notifySuccess,
} from '../../components/notification';

export enum bachelorCollegeDegreeOptions {
	BTech_BE = 'BTech./BE',
	BCA = 'BCA',
	BBA = 'BBA',
	BSc = 'BSc.',
	BCom = 'BCom',
	BA = 'BA',
	BEd = 'B.Ed.',
	LLB = 'LLB',
	BArch = 'BArch.',
	BDes = 'BDes.',
	CA = 'CA',
	BJMC = 'BJMC',
	BHM = 'BHM',
	Other = 'Other',
}

const enumToList = (data: any) => Object.values(data);

const FormFields = [
	{
		title: 'General Details',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			firstName: {
				label: 'First Name',
				type: 'text',
				disabled: false,
				required: true,
			},
			lastName: {
				label: 'Last Name',
				type: 'text',
				disabled: false,
				required: true,
			},
			whatsappNumber: {
				label: 'Whatsapp Number',
				type: 'number',
				disabled: false,
				required: true,
				min: 1000000000,
				max: 9999999999,
			},
			gender: {
				label: 'Gender',
				type: 'text',
				disabled: false,
			},
			dateOfBirth: {
				label: 'Date of Birth',
				type: 'date',
				disabled: false,
			},
			fatherName: {
				label: 'Father/Guardian Name',
				type: 'text',
				disabled: false,
			},
			class10Percentage: {
				label: 'High School (10th) Percentage',
				type: 'number',
				disabled: false,
				min: 30,
				max: 100,
			},
			class12Percentage: {
				label: 'Intermediate (12th/Diploma) Percentage',
				type: 'number',
				disabled: false,
				min: 30,
				max: 100,
			},
			jobStatus: {
				label: 'Employment Status',
				type: 'select',
				items: enumToList(jobStatusOptions),
				disabled: false,
			},
			jobType: {
				label: 'Employment Type',
				type: 'select',
				items: enumToList(jobTypeOptions),
				disabled: false,
			},
			preferredSpeakingLanguage: {
				label: 'Languages you speak',
				type: 'multiselect',
				items: enumToList(preferredSpeakingLanguageOptions),
				disabled: false,
			},
			homeState: {
				label: 'Home State',
				type: 'select',
				items: enumToList(homeStateOptions),
				disabled: false,
			},
			parentsContact: {
				label: "Parent's Contact",
				type: 'number',
				disabled: false,
				min: 1000000000,
				max: 9999999999,
			},
			leetcodeusername: {
				label: 'Leetcode user name',
				type: 'text',
				disabled: false,
			},
		},
	},
	{
		title: 'Graduation Details',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			graduationYear: {
				label: 'Graduation Year',
				type: 'number',
				disabled: false,
				min: 1900,
				max: 2025,
			},
			graduationPercentage: {
				label: 'Graduation Percentage',
				type: 'number',
				disabled: false,
				min: 30,
				max: 100,
			},
			collegeName: {
				label: 'College Name',
				type: 'text',
				disabled: false,
			},
			collegeDegree: {
				label: 'Degree/Course',
				type: 'select',
				items: enumToList(bachelorCollegeDegreeOptions),
				disabled: false,
			},
			collegeDepartment: {
				label: 'Department/Stream',
				type: 'select',
				items: enumToList(collegeDepartmentOptions),
				disabled: false,
			},
		},
	},
	{
		title:
			'Are you pursuing/have completed your Post Graduation?',
		show: true,
		type: 'radio-controller',
		controls: 3,
		items: {},
	},
	{
		title: 'Post Graduation Details',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			postGraduationYear: {
				label: 'PG Graduation Year',
				type: 'text',
				disabled: false,
				min: 1900,
				max: 2025,
			},
			postGraduationPercentage: {
				label: 'PG Graduation Percentage',
				type: 'number',
				disabled: false,
				min: 30,
				max: 100,
			},
			pgCollegeName: {
				label: 'PG College Name',
				type: 'text',
				disabled: false,
			},
			pgCollegeDegree: {
				label: 'PG Degree/Course',
				type: 'select',
				items: enumToList(masterCollegeDegreeOptions),
				disabled: false,
			},
			pgCollegeDepartment: {
				label: 'PG Department/Stream',
				type: 'select',
				items: enumToList(collegeDepartmentOptions),
				disabled: false,
			},
		},
	},
];

const InitialProfile = () => {
	const dispatch = useAppDispatch();
	const UserState = useSelector((state: any) => state.user);
	const {
		profile,
		user,
		leetcodeUserName,
		leetcodeLoading,
	} = UserState;
	console.log('SLICE EDSFGH', leetcodeUserName);
	const [isProfileLocked, setIsProfileLocked] =
		useState(false);
	const initialFormData: GeneralProfileFormFields = {
		userId: user !== null ? user.id : '',
		firstName: '',
		lastName: '',
		whatsappNumber: '',
		gender: '',
		dateOfBirth: null,
		fatherName: '',
		class12Percentage: null,
		class10Percentage: null,
		jobStatus: null,
		jobType: null,
		preferredSpeakingLanguage: [],
		parentsContact: '',
		homeState: null,
		graduationYear: '',
		graduationPercentage: '',
		collegeName: '',
		collegeDepartment: null,
		collegeDegree: null,
		postGraduationYear: '',
		postGraduationPercentage: '',
		pgCollegeName: '',
		pgCollegeDepartment: null,
		pgCollegeDegree: null,
		leetcodeusername: null,
	};

	useEffect(() => {
		if (user !== null) {
			console.log('called');
			dispatch(fetchGeneralProfile());
			dispatch(fetchLeetcodeUserName());
		}
	}, [user]);

	const [formFields, setFormFields] = useState(FormFields);
	const [formData, setFormData] =
		useState<GeneralProfileFormFields>(initialFormData);
	const [showPg, setShowPg] = useState('Yes');
	const [intialFillingDone, setIntialFillingDone] =
		useState(false);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[],
		event?: any
	) => {
		const newFormData = { ...formData };
		if (
			fieldId === 'jobStatus' &&
			fieldValue !== 'Working_Professional'
		) {
			newFormData.jobType = null;
		}
		console.log('newFormData', newFormData);
		setFormData({
			...newFormData,
			[fieldId]: fieldValue,
		});
	};

	useEffect(() => {
		if (
			profile !== null &&
			!intialFillingDone &&
			!leetcodeLoading
		) {
			console.log('profile', profile);
			if (leetcodeUserName !== null) {
				setFormData({
					...profile,
					leetcodeusername: leetcodeUserName,
					dateOfBirth: moment(profile.dateOfBirth).format(
						'YYYY-MM-DD'
					),
				});
			} else {
				setFormData({
					...profile,
					dateOfBirth: moment(profile.dateOfBirth).format(
						'YYYY-MM-DD'
					),
				});
			}
			// console.log('moment', moment(profile.dateOfBirth).format('YYYY-MM-DD'));
			setIntialFillingDone(true);
			if (profile.pgCollegeDegree === null) {
				setShowPg('No');
			}
		}
	}, [profile, leetcodeUserName, leetcodeLoading]);

	useEffect(() => {
		if (user != null)
			setIsProfileLocked(user.isProfileLocked);
	}, [user]);

	useEffect(() => {
		if (intialFillingDone) {
			console.log(formData);
			console.log('profile data', profile);
			dispatch(updateProfile(formData));
		}
	}, [formData]);

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		try {
			const data = await services.userService.saveProfile(
				formData
			);
			const response =
				await services.userService.saveLeetcodeUserName({
					leetcodeUsername: formData.leetcodeusername,
				});
			if (response.statusCode !== 400) {
				updateLeetcodeUserName(formData.leetcodeusername);
				notifySuccess(
					'Personal and Educational Info saved!'
				);
			} else {
				notifyError(response.message);
			}
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (showPg === 'No') {
			setFormData({
				...formData,
				postGraduationYear: null,
				postGraduationPercentage: null,
				pgCollegeName: null,
				pgCollegeDepartment: null,
				pgCollegeDegree: null,
			});
		}
	}, [showPg]);

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{profile !== null
				? formFields.map((fieldBatch, idx) => {
						console.log(fieldBatch.title, showPg);
						if (
							fieldBatch.title ===
								'Post Graduation Details' &&
							showPg === 'No'
						) {
							return null;
						}

						if (
							fieldBatch.show &&
							fieldBatch.type === 'batch'
						)
							return (
								<div
									key={idx}
									className="batch"
								>
									<h4>{fieldBatch.title}</h4>
									<div className="fields">
										{Object.entries(fieldBatch.items).map(
											([fieldId, field], idx) => {
												if (
													fieldId === 'jobType' &&
													formData.jobStatus !==
														'Working_Professional'
												) {
													return null;
												}

												if (field.type === 'select')
													return (
														<MySelectField
															key={idx}
															id={fieldId}
															{...field}
															updateFormData={
																updateFormData
															}
															formData={formData}
															isProfileLocked={
																isProfileLocked
															}
														/>
													);
												else if (
													field.type === 'multiselect'
												)
													return (
														<MyMultiSelectField
															key={idx}
															id={fieldId}
															{...field}
															updateFormData={
																updateFormData
															}
															formData={formData}
															isProfileLocked={
																isProfileLocked
															}
														/>
													);
												return (
													<MyTextField
														key={idx}
														id={fieldId}
														{...field}
														updateFormData={updateFormData}
														formData={formData}
														isProfileLocked={
															isProfileLocked
														}
													/>
												);
											}
										)}
									</div>
								</div>
							);
						else if (
							fieldBatch.show &&
							fieldBatch.type === 'radio-controller' &&
							user !== null &&
							!isProfileLocked
						)
							return (
								<div
									className="radio-controller"
									key={idx}
								>
									<h4>{fieldBatch.title}</h4>
									<div className="radio-field">
										<RadioGroup
											row
											value={showPg}
											onChange={(e) => {
												setShowPg(e.target.value);
												setFormFields((oldFormFields) => {
													const newFormFields = [
														...oldFormFields,
													];
													newFormFields[
														fieldBatch.controls
													].show =
														e.target.value === 'Yes'
															? true
															: false;
													return newFormFields;
												});
											}}
										>
											<FormControlLabel
												value="Yes"
												control={<Radio required />}
												label="Yes"
											/>
											<FormControlLabel
												value="No"
												control={<Radio required />}
												label="No"
											/>
										</RadioGroup>
									</div>
								</div>
							);
						return <></>;
				  })
				: null}
			{user !== null && !isProfileLocked ? (
				<Button
					className="profile-submit"
					type="submit"
				>
					Submit
				</Button>
			) : null}
		</form>
	);
};

export default InitialProfile;
