import { Box } from '@mui/material';
import {
	ReactElement,
	ReactNode,
	useEffect,
	useRef,
	useState,
	type FC,
} from 'react';

export interface TabPropElement {
	title: string | ReactElement;
	show?: boolean;
	element: ReactElement;
	key: string;
}

export type RightComponentPropsType = {
	tabData: TabPropElement;
	tabIndex: number;
};

export type PanelComponentPropsType = {
	index: number;
};

type TabPropsType = {
	tabs: Array<TabPropElement>;
	defaultTab?: number;
	onTabChange?: (
		tabData: TabPropElement,
		tabIndex: number
	) => void;
	RightComponent?: FC<RightComponentPropsType>;
	selectedTabExtraContent?: ReactNode;

	unselectedTabClass?: string;
	selectedTabClass?: string;
	panelBoxClass?: string;
	tabContainerClass?: string;
	rightComponentClass?: string;
};

export const Tabs: FC<TabPropsType> = (props) => {
	const { RightComponent } = props;

	const [currentIndex, setCurrentIndex] = useState(0);
	const [tabX, setTabX] = useState(0);
	const tabWidthsRef = useRef<Array<number>>([]);

	const calculateTabWidth = () => {
		const elements = document.querySelectorAll(
			'.tabs-panel-container .tab-option'
		);
		elements.forEach((element, index) => {
			const elementRect = element.getBoundingClientRect();
			const clientWidth = elementRect.width;
			tabWidthsRef.current[index] = clientWidth;
		});
	};

	useEffect(() => {
		calculateTabWidth();
	}, [props.defaultTab]);

	useEffect(() => {
		if (tabWidthsRef.current.length !== props.tabs.length) {
			calculateTabWidth();
		}
		let x = 0;
		for (let i = 0; i < currentIndex; i++) {
			x += tabWidthsRef.current[i];
		}
		setTabX(x);
	}, [currentIndex]);

	useEffect(() => {
		if (props.defaultTab !== undefined) {
			setCurrentIndex(props.defaultTab);
		}
	}, [props.defaultTab]);

	return (
		<>
			<Box
				className={`tabs-panel-container ${props.tabContainerClass}`}
			>
				<Box className="tabs">
					{props.tabs.map((tab, index) => {
						if (!tab.show) return null;
						return (
							<Box
								key={tab.key}
								className={`${
									props.unselectedTabClass
								} tab-option ${
									currentIndex === index
										? `tab-selected-${index}`
										: ''
								}`}
								onClick={() => {
									if (currentIndex === index) return;
									setCurrentIndex(index);
									props.onTabChange?.(tab, index);
								}}
							>
								{tab.title}
							</Box>
						);
					})}
				</Box>
				<Box
					className={`tab-panel-right-component ${props.rightComponentClass}`}
				>
					{RightComponent ? (
						<RightComponent
							tabIndex={currentIndex}
							tabData={props.tabs[currentIndex]}
						/>
					) : null}
				</Box>
				<Box
					className={`tab-selected-overlay ${props.selectedTabClass}`}
					style={{
						left: `${tabX}px`,
					}}
				>
					{props.tabs[currentIndex]?.title || <></>}
					{props.selectedTabExtraContent}
				</Box>
			</Box>
			{props.tabs.map((tab, index) => {
				return (
					<Box
						key={tab.key}
						style={{
							display:
								currentIndex !== index ? 'none' : 'flex',
							flexDirection: 'column',
							height: '100%',
							overflow: 'hidden',
						}}
					>
						{tab.element}
					</Box>
				);
			})}
		</>
	);
};
