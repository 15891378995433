import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { services } from '../../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import {
	fetchMentorSessions,
	setPrevEndTime,
	setPrevSessionId,
	setPrevStartTime,
	setPrevTab,
	setSessionsPage,
} from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import ConfirmGmeetModal from '../mentorHome/ConfirmGmeetModal';
import DidntHappenModal from '../mentorHome/DidntHappenModal';
import FinaliseQnsModal from '../mentorHome/FinaliseQnsModal';
import {
	checkNoShowByMentorOrStudent,
	checkShowFeedback,
	getParams,
	getRecommendedQns,
	handleBtnsDisplay,
	handleConfirmGmeet,
	handleSessionDidntHappen,
	joinNow,
	onPropsChange,
	removeDuplicatesById,
} from '../mentorUtils';
import CancelSessionModal from './CancelSessionModal';
import FormModal from './FormModal';
import SessionsTable from './SessionsTable';

const UPCOMING_ONGOING = 'UPCOMING_ONGOING';

export enum SessionPlatform {
	INTERNAL_ZOOM = 'INTERNAL_ZOOM',
	EXTERNAL_GMEET = 'EXTERNAL_GMEET',
}

const UpcomingSessions = ({
	sessionType,
	startDate,
	endDate,
}: any) => {
	const dispatch = useAppDispatch();

	const UserState = useSelector((state: any) => state.user);
	const { user } = UserState;

	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorSessions, sessionsPage } = MentorState;

	const [sessionsData, setSessionsData] = useState<any[]>(
		[]
	);
	const [openDidntHappenModal, setOpenDidntHappenModal] =
		useState<boolean>(false);
	const [targetSession, setTargetSession] =
		useState<any>(null);
	const [openGmeet, setOpenGmeet] =
		useState<boolean>(false);
	const [targetSessionId, setTargetSessionId] =
		useState<string>('');
	const [reason, setReason] = useState<string>('');
	const [showJoinNow, setShowJoinNow] =
		useState<boolean>(false);
	const [showDidntHappen, setShowDidntHappen] =
		useState<boolean>(false);
	const [showGmeet, setShowGmeet] =
		useState<boolean>(false);
	const [openCancel, setOpenCancel] =
		useState<boolean>(false);

	const [feedbackFormUrl, setFeedbackFormUrl] =
		useState<string>('');
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [selectedQns, setSelectedQns] = useState<any>(null);
	const [openRandomQns, setOpenRandomQns] =
		useState<boolean>(false);
	const [
		latestUpcomingSessionId,
		setLatestUpcomingSessionId,
	] = useState<string>('');
	const [qnsFinalised, setQnsFinalised] =
		useState<boolean>(false);
	const [latestSessionData, setLatestSessionData] =
		useState<any>();

	useEffect(() => {
		dispatch(setSessionsPage(1));
	}, []);

	useEffect(() => {
		if (mentorSessions !== null) {
			const temp = removeDuplicatesById([
				...sessionsData,
				...mentorSessions,
			]);
			setSessionsData([...temp]);
		}
	}, [mentorSessions]);

	useEffect(() => {
		const changed = onPropsChange(
			sessionType,
			startDate,
			endDate,
			UPCOMING_ONGOING
		);
		setSessionsData([]);
		switch (changed) {
			case 'SESSION_CHANGE': {
				dispatch(setSessionsPage(1));
				dispatch(setPrevSessionId(sessionType));
				getSessionsData();
				// dispatch(setSessionsPage(2));
				break;
			}
			case 'DATE_CHANGE': {
				dispatch(setSessionsPage(1));
				dispatch(setPrevStartTime(startDate));
				dispatch(setPrevEndTime(endDate));
				getSessionsData();
				// dispatch(setSessionsPage(2));
				break;
			}
			case 'TAB_CHANGE': {
				getSessionsData();
				break;
			}
			default:
				getSessionsData();
		}
		dispatch(setPrevTab(UPCOMING_ONGOING));
	}, [sessionType, startDate, endDate]);

	const getSessionsData = () => {
		const params = getParams(
			startDate,
			endDate,
			sessionType,
			UPCOMING_ONGOING
		);
		setSessionsData([]);
		dispatch(fetchMentorSessions(params));
	};

	useEffect(() => {
		if (qnsFinalised) {
			const params = getParams(
				startDate,
				endDate,
				sessionType,
				UPCOMING_ONGOING
			);
			dispatch(fetchMentorSessions(params));
		}
	}, [qnsFinalised]);

	useEffect(() => {
		dispatch(setSessionsPage(1));
	}, []);

	const [hasRecommendedQns, setHasRecommendedQns] =
		useState<boolean>(false);

	useEffect(() => {
		if (sessionsData !== null && sessionsData.length > 0) {
			setLatestSessionData(sessionsData[0]);
			const latestSession = sessionsData[0];
			setLatestUpcomingSessionId(latestSession.sessionId);
			handleBtnsDisplay(
				setShowJoinNow,
				setShowGmeet,
				setShowDidntHappen,
				latestSession,
				UPCOMING_ONGOING
			);

			getRecommendedQns(
				latestSession.sessionId,
				setHasRecommendedQns
			);

			const isNoShowByMentorOrStudent =
				checkNoShowByMentorOrStudent(
					latestSession,
					UPCOMING_ONGOING
				);
			if (isNoShowByMentorOrStudent) {
				setShowGmeet(false);
				setShowDidntHappen(false);
				setShowJoinNow(false);
			}
		}
	}, [sessionsData]);

	const checkCanCancel = (dateISO: Date): boolean => {
		const currentDate = new Date();
		const givenDate = new Date(dateISO);
		return (
			givenDate > currentDate &&
			givenDate.toDateString() !==
				currentDate.toDateString()
		);
	};

	const handleGmeet = (sessionId: string) => {
		setOpenGmeet(true);
		setTargetSessionId(sessionId);
	};

	const handleDidntHappen = (sessionData: any) => {
		setTargetSession(sessionData);
		setOpenDidntHappenModal(true);
	};

	const handleJoinNow = (sessionData: any) => {
		const qnsFinalised =
			sessionData.selectedQuestions !== null;
		if (qnsFinalised) joinNowCallback();
		else {
			setTargetSessionId(sessionData.sessionId);
			setOpenRandomQns(true);
		}
	};

	const joinNowCallback = () => {
		joinNow(latestSessionData, user);
	};

	const handleCloseDidntHappen = () =>
		setOpenDidntHappenModal(false);

	const handleSessionDidntHappenCallback = (
		sessionId: string
	) => {
		handleSessionDidntHappen(
			sessionId,
			reason,
			setShowDidntHappen,
			setShowJoinNow,
			setShowGmeet,
			setOpenDidntHappenModal
		);
	};

	const handleCloseGmeet = () => setOpenGmeet(false);

	const handleCancelSession = (sessionId: string) => {
		setTargetSessionId(sessionId);
		setOpenCancel(true);
	};

	const [disabledSessionId, setDisabledSessionId] =
		useState<string>('');

	const handleCloseCancelModal = () => setOpenCancel(false);

	const handleSessionCancel = async () => {
		const body = { sessionId: targetSessionId };
		setDisabledSessionId(targetSessionId);
		const response =
			await services.mentorService.cancelSession(
				targetSessionId,
				body
			);
		if (response && response.statusCode)
			notifyError(response.message);
		else {
			notifySuccess(response.message);
			setOpenCancel(false);
			getSessionsData();
		}
		setDisabledSessionId('');
	};

	const handleFillFeedback = (sessionData: any) => {
		const selectedQns = sessionData.selectedQuestions;
		if (
			(selectedQns !== null &&
				Object.keys(selectedQns).length > 0) ||
			!hasRecommendedQns
		) {
			setFeedbackFormUrl(sessionData.mentorFeedbackLink);
			setOpenForm(true);
			setSelectedQns(selectedQns);
		} else {
			setTargetSessionId(sessionData.sessionId);
			setOpenRandomQns(true);
		}
	};

	const handleCloseRandomQns = () =>
		setOpenRandomQns(false);

	const handleFormClose = () => setOpenForm(false);

	const handleConfirmGmeetCallback = () => {
		handleConfirmGmeet(
			targetSessionId,
			setOpenGmeet,
			setShowJoinNow,
			setShowGmeet
		);
	};

	const fetchMoreDataCallback = () => {
		const params = getParams(
			startDate,
			endDate,
			sessionType,
			UPCOMING_ONGOING
		);
		dispatch(fetchMentorSessions(params));
		dispatch(setSessionsPage(sessionsPage + 1));
	};

	return (
		<div>
			{openDidntHappenModal && (
				<DidntHappenModal
					open={openDidntHappenModal}
					handleClose={handleCloseDidntHappen}
					session={targetSession}
					setReason={setReason}
					handleSessionDidntHappen={
						handleSessionDidntHappenCallback
					}
				/>
			)}

			{openGmeet && (
				<ConfirmGmeetModal
					confirmGmeet={openGmeet}
					handleCloseGmeet={handleCloseGmeet}
					handleConfirmGmeet={handleConfirmGmeetCallback}
				/>
			)}

			{openCancel && (
				<CancelSessionModal
					openCancel={openCancel}
					handleCloseCancelModal={handleCloseCancelModal}
					handleSessionCancel={handleSessionCancel}
				/>
			)}

			{openRandomQns && (
				<FinaliseQnsModal
					setQnsFinalised={setQnsFinalised}
					openRandomQns={openRandomQns}
					handleCloseRandomQns={handleCloseRandomQns}
					joinNow={joinNowCallback}
					sessionId={targetSessionId}
				/>
			)}

			{openForm && (
				<FormModal
					openForm={openForm}
					url={feedbackFormUrl}
					handleFormClose={handleFormClose}
					selectedQns={selectedQns}
				/>
			)}

			<SessionsTable
				sessionsData={sessionsData}
				fetchMoreDataCallback={fetchMoreDataCallback}
				tab={UPCOMING_ONGOING}
				obj={{
					handleFillFeedback,
					checkCanCancel,
					handleCancelSession,
					latestUpcomingSessionId,
					showDidntHappen,
					handleDidntHappen,
					showJoinNow,
					handleJoinNow,
					showGmeet,
					handleGmeet,
					checkShowFeedback,
					disabledSessionId,
				}}
			/>
		</div>
	);
};

export default UpcomingSessions;
