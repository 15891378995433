import { TbDiscountCheckFilled } from 'react-icons/tb';
import bulb from '../../assets/page/session/bulb.svg';

interface SessionRecommendedProps {
	sessionRecommended: SessionRecommendedState[];
}

interface SessionRecommendedState {
	attempted: boolean;
	category: string;
	created_at: string;
	feedbackLink: string;
	id: string;
	isCustomSession: boolean;
	isMentorShip: boolean;
	sessionBookingNotice: string;
	sessionDuration: number;
	sessionType: string;
	studentFeedbackLink: string;
	updated_at: string;
}

const SessionRecommended = ({
	sessionRecommended,
}: SessionRecommendedProps) => {
	return (
		<div className="session-recommended-wrapper">
			<p className="session-recommended-title">
				<img
					className="icon"
					src={bulb}
				/>
				<span className="text">Recommended</span>
			</p>
			<p className="session-recommended-txt">
				You should attempt the following sessions:
			</p>
			{sessionRecommended.length > 0 ? (
				<ul className="session-recommended-list">
					{sessionRecommended.map(
						(elem: any, i: number) => (
							<li
								className="session-recommended-item"
								key={i}
							>
								<span
									className={`icon ${
										elem?.attempted ? 'attempted' : ''
									}`}
								>
									<TbDiscountCheckFilled />
								</span>
								<span className="text">
									{elem?.sessionType}
								</span>
							</li>
						)
					)}
				</ul>
			) : (
				<ul className="session-recommended-list">
					<li className="session-recommended-item">
						There’s nothing to recommend
					</li>
				</ul>
			)}
		</div>
	);
};

export default SessionRecommended;
