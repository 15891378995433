import {
	PayloadAction,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export interface EditorState {
	codingLanguage: string;
	languageId: number;
	editorTheme: string;
	defaultValue: string;
	questionData: any;
	testCases: any;
	editorLang: string;
	solution: string;
	loading: boolean;
	isThemeDark: boolean;
	fontSize: number;
	error: {
		state: boolean;
		message: string;
	};
	// submitLoading:boolean
}

export enum Theme {
	'light' = 'light',
	'dark' = 'dark',
}

export const languageMap = {
	cpp: {
		editorLang: 'cpp',
		languageId: 54,
		defaultText:
			'# include <iostream>\n' +
			'\n' +
			'int main() {\n' +
			'    // your code here\n' +
			'    return 0;\n' +
			'}',
	},
	c: {
		editorLang: 'c',
		languageId: 50,
		defaultText:
			'#include <stdio.h> // header file for Standard Input Output\n' +
			'#include <stdlib.h> // header file for Standard Library\n' +
			'\n' +
			'int main() {\n' +
			'\n' +
			'    //Your code here\n' +
			'    return 0;\n' +
			'}',
	},
	python: {
		editorLang: 'python',
		languageId: 71,
		defaultText: '# your code here',
	},
	javascript: {
		editorLang: 'javascript',
		languageId: 63,
		defaultText: '// your code here',
	},
	java: {
		editorLang: 'java',
		languageId: 62,
		defaultText: `import java.util.*;\nimport java.lang.*;\nimport java.io.*;\n\npublic class Main\n{\n\tpublic static void main (String[] args) throws java.lang.Exception\n\t{\n\t\t//your code here\n\t}\n}`,
	},
};

export const fetchQuestionData = createAsyncThunk(
	'editor/question',
	async (questionId: string, { getState }) => {
		const state = getState();
		console.log('STATE  HERE', state);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		const { languageId } = state.editor;

		const body = {
			languageId: languageId,
			questionId: questionId,
			type: '',
			contestId: '',
		};
		const data =
			await services.codingQuestionService.fetchQuestion(
				body
			);
		return data;
	}
);

const initialState: EditorState = {
	codingLanguage: 'java',
	editorLang: 'java',
	languageId: 62,
	editorTheme: 'githubDark',
	isThemeDark: true,
	loading: false,
	questionData: null,
	testCases: [],
	solution: '',
	fontSize: 16,
	error: {
		state: false,
		message: '',
	},
	// submitLoading:false,
	defaultValue: `import java.util.*;\nimport java.lang.*;\nimport java.io.*;\n\npublic class Main\n{\n\tpublic static void main (String[] args) throws java.lang.Exception\n\t{\n\t\tSystem.out.println("Hello World");\n\t}\n}`,
};

export const editorSlice = createSlice({
	name: 'editor',
	initialState,
	reducers: {
		changeLanguage: (
			state,
			action: PayloadAction<string>
		) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.languageId =
				//@ts-ignore
				languageMap[action.payload].languageId;
			state.codingLanguage = action.payload;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.editorLang =
				//@ts-ignore
				languageMap[action.payload].editorLang;
			// state.defaultValue = languageMap[action.payload].defaultText
		},
		changeLanguageForOpenEditor: (
			state,
			action: PayloadAction<string>
		) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.languageId =
				//@ts-ignore
				languageMap[action.payload].languageId;
			state.codingLanguage = action.payload;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.editorLang =
				//@ts-ignore
				languageMap[action.payload].editorLang;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.defaultValue =
				//@ts-ignore
				languageMap[action.payload].defaultText;
		},
		changeEditorTheme: (
			state,
			action: PayloadAction<string>
		) => {
			// console.log('heer')
			state.isThemeDark = !state.isThemeDark;
			if (state.isThemeDark) state.editorTheme = Theme.dark;
			else state.editorTheme = Theme.light;
		},
		setLoading: (state, action: PayloadAction<boolean>) => {
			state.loading = action.payload;
		},
		setFont: (state, action: PayloadAction<number>) => {
			state.fontSize = action.payload;
		},
		setEditorTheme: (
			state,
			action: PayloadAction<string>
		) => {
			state.editorTheme = action.payload;
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchQuestionData.pending]: (
			state: EditorState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
			state.questionData = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchQuestionData.fulfilled]: (
			state: EditorState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.questionData = action.payload;
			state.solution = action.payload.solution;
			state.testCases = action.payload.testCase;

			if (action.payload.question_type === 'JS') {
				state.languageId =
					languageMap['javascript'].languageId;
				state.codingLanguage = 'javascript';
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.defaultValue =
				action.payload.preSubmissionCode ||
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				//@ts-ignore
				languageMap[state.codingLanguage].defaultText;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchQuestionData.rejected]: (
			state: EditorState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
	},
});

//Exporting Actions
export const {
	changeLanguage,
	changeEditorTheme,
	setLoading,
	setFont,
	setEditorTheme,
	changeLanguageForOpenEditor,
} = editorSlice.actions;

export default editorSlice.reducer;
