import React, { useState } from 'react';
import InputField from '../shared/InputField';
import AccioButton from '../elements/Button';
import { SiLeetcode } from 'react-icons/si';

const InputButtonField = ({
	id,
	type,
	value,
	setVal,
	placeHolder,
	showError,
	errorMessage,
	btnAction,
	icon,
	btnTitle,
	onInputChange,
}: any) => {
	console.log('value', value);
	const [typedVal, setTypedVal] = useState('');

	const handleSearch = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setTypedVal(e.target.value);
		setVal('');
		onInputChange(e.target.value);
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		setVal(e.target.value);
		setTypedVal('');
		onInputChange(e.target.value);
	};

	return (
		<div className="input-button-field">
			<InputField
				inputParentClass={`${
					typedVal.length > 2
						? 'input-button-field-input-with-btn'
						: 'input-button-field-input'
				}`}
				id={id}
				type={type}
				value={value ? value : typedVal}
				onChange={value ? handleInputChange : handleSearch}
				required
				placeHolder={placeHolder}
				showError={showError}
				errorMessage={errorMessage}
			/>
			{typedVal.length > 2 ? (
				<AccioButton
					customClass="input-button-field-btn"
					className=""
					variant="primary"
					title={btnTitle}
					icon={icon}
					action={btnAction}
				/>
			) : null}
		</div>
	);
};

export default InputButtonField;
