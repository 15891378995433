import bars from '../../../../../assets/images/bars.svg';
import { useAppSelector } from '../../../../../redux/store';
function ActiveEngagementCard() {
	const { performanceData } = useAppSelector(
		(state) => state.profile
	);
	if (performanceData) {
		return (
			<div className="preview-card">
				<img
					src={bars}
					className="bars-img"
				/>
				<div className="right-div">
					<p className="heading">Activity Engagement</p>
					<p className="days-text">
						{performanceData.data?.totalActivity}
						<span className="grey">Days</span>
					</p>
				</div>
			</div>
		);
	} else return null;
}

export default ActiveEngagementCard;
