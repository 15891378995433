import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';

export class ContactBMService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getBMData() {
		const url = `${this.endpoint}/course/get-student-bm-details`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async bookSlot({
		startTime,
		endTime,
		reason,
		bmId,
	}: {
		startTime: string;
		endTime: string;
		reason: string;
		bmId: string;
	}) {
		const url = `${this.endpoint}/course/contact-bm`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						slot: {
							startTime,
							endTime,
						},
						query: reason,
						bmId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
