import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export enum QuestionType {
	SUBJECTIVE = 'SUBJECTIVE',
	OBJECTIVE = 'OBJECTIVE',
	OTHER = 'OTHER',
}

export interface QuestionAnswer {
	question: {
		questionType: QuestionType;
		text: string;
	};
	answer: string;
}

export class StudentLectureFeedbackService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async addLectureFeedback(feedback: any) {
		const url = `${this.endpoint}/studentLectureFeedback/`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: feedback,
				}
			);
			return data;
		} catch (err) {
			throw new Error(apiFailureErrorMessage);
		}
	}
	async getLectureFeedbackPopUpStatus() {
		const url = `${this.endpoint}/studentLectureFeedback/getLectureFeedbackPopUpStatus`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (err) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
