import { notifyError } from '../components/notification';
import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';

export class SessionService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchMeetingLink(
		roomId: string,
		userId: string,
		userType: number
	) {
		const url = `${this.endpoint}/session/joinMeeting/${roomId}/${userId}/${userType}`;
		try {
			const response = await this.httpService.get<any>(url);
			return response.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//only this require to join meeting for zoom
	async fetchZoomVideoLink(
		roomId: string,
		userId: string,
		userType: number,
		body: any
	) {
		const url = `${this.endpoint}/session/joinMeeting/${roomId}/${userId}/${userType}`;
		try {
			const response = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async joinZoomLink(roomId: string) {
		const url = `${this.endpoint}/video/getLiveClassesUrlOfAUser`;
		try {
			const response = await this.httpService.post<any>(
				url,
				{
					data: { roomId: roomId },
				}
			);
			return response.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	// async fetchUpcomingSessions(
	// 	userID: string,
	// 	userType: string
	// ) {
	// 	const url = `${this.endpoint}/session/get/${userID}/${userType}`;
	// 	// const fetchFeedbackUrl = `${this.endpoint}/session/feedback/get/${userID}/${userType}`
	// 	// const fetchVideosUrl = `${this.endpoint}/session/videos/${userType}/${userID}`
	// 	try {
	// 		const { data } = await this.httpService.get<any>(url);
	// 		console.log('fetch upcoming session', data);
	// 		// const feedback = await this.httpService.get<any>(fetchFeedbackUrl)
	// 		// const videos = await this.httpService.get<any>(fetchVideosUrl)
	// 		// return {
	// 		// 	sessionData: session.data.sessionData,
	// 		// 	feedbackData: session.data.feedbackData,
	// 		// 	videoData: session.data.videoData,
	// 		// 	scoreData: session.data.scoreData,
	// 		// };
	// 		return data;
	// 	} catch (error) {
	// 		throw new Error(apiFailureErrorMessage);
	// 	}
	// }

	async fetchUpcomingAllSessions(
		userID: string,
		userType: string
	) {
		const url = `${this.endpoint}/session/suggested/${userID}/${userType}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('👉🏻', data);

			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getSessionWithoutFeedback(userID: string) {
		const url = `${this.endpoint}/session/feedback/${userID}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			// console.log(data)
			return data.session;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async postFeedback(
		sessionId: string,
		userType: string,
		body: any
	) {
		const url = `${this.endpoint}/session/add/feedback/${sessionId}`;
		try {
			const postData: any = { userType, feedback: body };
			console.log(postData);
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: postData,
				}
			);
			console.log(data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async raiseARequest(userID: string, sessionId: string) {
		const url = `${this.endpoint}/session/eligibility/raiseRequestForSessionEligibilty/${userID}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { sessionId: { id: sessionId } },
				}
			);
			console.log(data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async checkStudentPlacementReadiness() {
		const url = `${this.endpoint}/userMainCourseDetails/studentPlacementReadiness`;
		try {
			const { data } = await this.httpService.get<any>(url);
			// console.log(data)
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async fetchEligibleSessionsOfUser() {
		const url = `${this.endpoint}/session/getEligibleSessionsOfUser`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('data i am calling', data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async fetchSessionsOfUserForAllSessionTypes() {
		const url = `${this.endpoint}/session/getSessionsOfUserForAllSessionTypes`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('data i am calling 2', data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async fetchUpcomingSessionsOfUser() {
		const url = `${this.endpoint}/session/getUpcomingSessionsOfUser`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('data i am calling 2', data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async fetchSessionDetailsOfUserFromSessionType(
		sessionTypeId: string
	) {
		const url = `${this.endpoint}/session/getSessionDetailsOfUserFromSessionType`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { sessionTypeId: sessionTypeId },
				}
			);
			console.log('data i am calling 2', data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async getAvailableSlots(
		date: string,
		sessionID: string,
		mentorId: null | string
	) {
		const url = `${this.endpoint}/session/getAvailaibleSlots`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						date: date,
						sessionId: sessionID,
						mentorId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async rescheduleSession(
		sessionDate: string,
		sessionId: string,
		prevMentorId: string | null
	) {
		const url = `${this.endpoint}/session/reschedule`;

		console.log('sessionDate', sessionDate);
		console.log(
			'sessionDate',
			new Date(sessionDate).toLocaleString('en-US')
		);
		try {
			const data = await this.httpService.post<any>(url, {
				data: {
					startTime: new Date(sessionDate).toLocaleString(
						'en-US'
					),
					rescheduleUid: sessionId,
					mentorId: prevMentorId,
				},
			});
			return data;
		} catch (error: any) {
			console.log('this error', error);
			notifyError(
				error.response.data.message ||
					'Something went wrong! Please try again later.'
			);
			throw new Error(apiFailureErrorMessage);
		}
	}

	async bookSessionWithMentor(
		date: Date,
		sessionID: string,
		slot: string,
		mentorId: null | string
	) {
		console.group('Session Details');
		console.log('Date:', date);
		console.log('Session ID:', sessionID);
		console.log('Slot:', slot);
		console.log('Mentor ID:', mentorId);
		console.groupEnd();

		const url = `${this.endpoint}/session/bookSessionWithMentor`;

		try {
			const data = await this.httpService.post<any>(url, {
				data: {
					date: date,
					sessionId: sessionID,
					slot: slot,
					mentorId,
				},
			});
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async cancelSession(sessionId: string) {
		const url = `${this.endpoint}/session/cancel`;
		try {
			const data = await this.httpService.post<any>(url, {
				data: {
					sessionId,
				},
			});
			return data;
		} catch (error: any) {
			console.log('this error', error);
			notifyError(
				error.response.data.message ||
					'Something went wrong! Please try again later.'
			);
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchRemainingBookings() {
		const url = `${this.endpoint}/session/getSessionBookingsLeftByCategory`;
		try {
			const response = await this.httpService.get<any>(url);
			return response.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchSessionEligibility() {
		const url = `${this.endpoint}/userMainCourseDetails/sessionEligibility`;
		try {
			const response = await this.httpService.get<any>(url);
			return response.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
