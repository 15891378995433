import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import { joinClassZoomLink } from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import './joinliveclass.css';
import NoClass from './Noclass';

interface ClassData {
	classFound: boolean;
	userClasses: Array<{
		join_url: string;
		title: string;
		roomId: string;
	}>;
}

const JoinLiveClass = () => {
	const dispatch = useDispatch<AppDispatch>();
	const response = useSelector(
		(state: any) => state.session
	);
	const [classData, setClassData] =
		useState<ClassData | null>(null);
	const [classOptionData, setClassOptionData] =
		useState(false);
	// const [classOptionundefind, setClassOptionundefind] = useState(false);

	const joinZoomClassSession = (room: string) => {
		if (room !== undefined) {
			dispatch(joinClassZoomLink(room));
		}
	};

	useEffect(() => {
		if (
			response &&
			response.classLinkload &&
			response.joinLiveClassData !== null
		) {
			setClassData(response.joinLiveClassData);
			console.log(
				'response.joinLiveClassData',
				response.joinLiveClassData
			);
		}
	}, [response]);

	useEffect(() => {
		if (
			classData !== null &&
			classData.classFound &&
			classData.userClasses.length < 2
		) {
			window.location.href =
				classData.userClasses[0].join_url;
			return;
		}
	}, [classData]);

	useEffect(() => {
		const params = new URLSearchParams(
			window.location.search
		);
		const roomId = params.get('roomId');
		if (typeof roomId === 'string' && roomId.length > 0) {
			joinZoomClassSession(roomId);
			setClassOptionData(false);
		} else if (
			typeof roomId === 'string' &&
			roomId === ''
		) {
			joinZoomClassSession('');
			setClassOptionData(true);
			//  setClassOptionundefind(true);
		} else {
			// setClassOptionundefind(true);
			setClassOptionData(false);
		}
	}, []);

	if (classData === null) {
		return <Loader />;
	} else {
		return (
			<div className="join-live-class-outer">
				{classData !== null &&
				classOptionData &&
				classData.userClasses ? (
					<div className="join-live-class-live-class-indicator">
						<div className="circle red"></div>
						<div className="text">Live Class Link </div>
					</div>
				) : null}

				{classData !== null &&
				classOptionData &&
				classData.userClasses ? (
					classData.userClasses.map((userClass, index) => (
						<div
							className="class-wrapper"
							key={index}
						>
							<p className="class-title">
								{userClass.title}
							</p>
							<a
								className="class-link"
								href={userClass.join_url}
								target="_blank"
								rel="noopener noreferrer"
							>
								{userClass.join_url}
							</a>
						</div>
					))
				) : (
					<div className="class-not-found">
						<p className="class-not-found-text">
							Oops ! No Live Class Found
						</p>
						<div className="class-not-found-img">
							<NoClass />
						</div>
					</div>
				)}

				{/* {classData !== null && !classOptionData ? (
					
				) : null} */}
			</div>
		);
	}
};

export default JoinLiveClass;
