import { useEffect, useState } from 'react';

import RenderMarkdown from './DisplayAsMarkDown';

interface Props {
	statement: string;
	options: string[];
	save: any;
	response: string[];
	correct: string[];
	submitted: boolean;
	explanation: string;
}

const IntegerType = (props: Props) => {
	const rm = '# This is a question';
	const option = '### This is an option';

	console.log('props', props);

	const [inputValue, setInputValue] = useState('');

	const saveAnswer = () => {
		const temp = [inputValue];
		console.log('Int temp', temp);
		props.save(temp);
	};

	useEffect(() => {
		console.log('props response', props.response[0]);
		setInputValue(props.response[0]);
	}, [props.response]);

	return (
		<div className={'integer-type conceptual-question'}>
			<div className={'question'}>
				{RenderMarkdown(props.statement)}
			</div>
			<div className={'answer'}>
				<input
					disabled={props.submitted}
					type={'number'}
					placeholder={'type your answer'}
					value={inputValue}
					onChange={(event) =>
						setInputValue(event.target.value)
					}
				/>
			</div>

			{!props.submitted ? (
				<button
					onClick={saveAnswer}
					className={'save-btn'}
				>
					Save
				</button>
			) : null}
			{props.submitted ? (
				<div className={'correct-answer'}>
					<h1>Correct Answer</h1>
					<ul>
						{props.correct.map((el: any) => {
							return <li key={el}>{el}</li>;
						})}
					</ul>
					{props.explanation &&
					props.explanation.length > 1 ? (
						<h1>Explanation</h1>
					) : null}
					{props.explanation &&
					props.explanation.length > 1 ? (
						<p>{RenderMarkdown(props.explanation)}</p>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default IntegerType;
