import { useAppSelector } from '../../../../../redux/store';

export const ContestRanking = () => {
	const { contestData } = useAppSelector(
		(state) => state.profile
	);

	if (contestData.data)
		return (
			<div
				className="preview-card"
				style={{
					flexDirection: 'column',
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
				}}
			>
				<p className="heading">Contest Ranking</p>
				<div className="ranks-flex-div">
					<p className="days-text">
						{contestData.data?.averageRank?.toFixed(0) ===
						'-1'
							? 'NA'
							: contestData.data?.averageRank?.toFixed(0)}
						<p className="grey">Avg. Rank</p>
					</p>

					<p className="days-text">
						{contestData.data?.maxScore?.toFixed(0) === '-1'
							? 'NA'
							: contestData.data?.maxScore?.toFixed(0)}
						<p className="grey">High Score</p>
					</p>

					<p className="days-text">
						{contestData.data?.lastRank?.toFixed(0) === '-1'
							? 'NA'
							: contestData.data?.lastRank?.toFixed(0)}
						<p className="grey">Latest Rank</p>
					</p>
				</div>
			</div>
		);
	else return null;
};
