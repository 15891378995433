import { number } from 'echarts';
import React, { useEffect, useState } from 'react';

const ModuleMonthJourney = ({ dataVal }: any) => {
	const getTextClassNameByStatus = (
		status: any
	): string => {
		if (status === 'completed') {
			return ' module-text-journey-stepper_item_completed ';
		} else if (status === 'partially-completed') {
			return 'module-text-journey-stepper_item_partially-completed ';
		} else if (status === 'ongoing') {
			return 'module-text-journey-stepper_item_ongoing ';
		} else if (status === 'upcoming') {
			return 'module-text-journey-stepper_item_upcoming ';
		} else if (status === 'failed') {
			return 'module-text-journey-stepper_item_failed ';
		} else {
			return '';
		}
	};

	const getBoxClassNameByStatus = (status: any): string => {
		if (status === 'completed') {
			return ' module-month-journey-stepper_item_circle module-text-journey-stepper_item_completed_Box ';
		} else if (status === 'partially-completed') {
			return 'module-month-journey-stepper_item_circle module-text-journey-stepper_item_partially-completed_Box ';
		} else if (status === 'ongoing') {
			return 'module-month-journey-stepper_item_circle module-month-journey-stepper_item_circle_Box module-month-journey-stepper_item_rotate-square module-text-journey-stepper_item_ongoing_Box ';
		} else if (status === 'upcoming') {
			return 'module-month-journey-stepper_item_circle module-text-journey-stepper_item_upcoming_Box ';
		} else if (status === 'failed') {
			return 'module-month-journey-stepper_item_circle module-text-journey-stepper_item_failed_Box ';
		} else {
			return '';
		}
	};

	const [showHoverText, setShowHoverText] = useState(false);
	const [hoveredID, setHoveredID] = useState<null | number>(
		null
	);

	const handleMouseEnter = () => {
		setShowHoverText(true);
	};

	const handleMouseLeave = () => {
		setShowHoverText(false);
	};

	return (
		<div className="stepper-month-journey-container">
			{dataVal.map((item: any, index: number) => (
				<div
					className="stepper-month-journey"
					key={index}
				>
					<div
						className={`stepper-month-journey-line ${
							item.status !== 'upcoming'
								? 'stepper-month-journey-line-active'
								: ''
						} ${
							index === dataVal.length - 1
								? 'last-content-line'
								: ''
						}  ${index === 0 ? 'first-content-line' : ''}`}
					></div>

					<div className="stepper-month-journey-display">
						<span
							className={`stepper-month-journey-step ${getTextClassNameByStatus(
								item.status
							)} ${
								index === dataVal.length - 1
									? 'last-content-step'
									: ''
							} ${index === 0 ? 'first-content-step' : ''}`}
						>
							{item.course}
						</span>

						<div
							onMouseEnter={() => {
								handleMouseEnter();
								setHoveredID(index);
							}}
							onMouseLeave={() => {
								handleMouseLeave();
								setHoveredID(null);
							}}
							key={index}
							className={`stepper-month-journey-display-shape ${getBoxClassNameByStatus(
								item.status
							)} ${
								index === dataVal.length - 1
									? 'last-content-shape'
									: ''
							}  ${
								index === 0 ? 'first-content-shape' : ''
							}`}
						>
							{showHoverText && hoveredID === index && (
								<div
									key={index}
									className={`stepper-month-journey-hover-textbox ${
										item.status === 'ongoing'
											? 'not-rotate'
											: ''
									}`}
								>
									<div className="month-journey-tool-tip-msgbox">
										<div className="month-journey-tool-tip-main-text-wrapper"></div>
										<div className="month-journey-tool-tip-arrow"></div>
									</div>

									{/* {item.status} */}
								</div>
							)}
						</div>

						<div className="stepper-month-journey-bottom-wrapper-mnth-name-yr">
							<span
								className={`stepper-month-journey-display-monthsymbol ${
									index === dataVal.length - 1
										? 'last-content-mnth'
										: ''
								} ${
									index === 0 ? 'first-content-mnth' : ''
								}`}
							>
								{item.monthDisplay}
							</span>
							<span
								className={`stepper-month-journey-display-mnth-yr ${
									index === dataVal.length - 1
										? 'last-content-mnth-yr'
										: ''
								} ${
									index === 0 ? 'first-content-mnth-yr' : ''
								}`}
							>
								{`${item.month}${"'"}${item.year}`}
							</span>
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default ModuleMonthJourney;

//if i hover to the stepper-month-journey-display-shape this div then stepper-month-journey-hover-text this div will be display like popup effect
