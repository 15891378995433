import React from 'react';
import {
	OutlinedInput,
	InputLabel,
	MenuItem,
	FormControl,
	ListItemText,
	Select,
	Checkbox,
} from '@mui/material';
import {
	FormDataType,
	FormFieldProps,
} from '../../jobPortal/CompleteProfilePopup';
// import {
// 	FormDataType,
// 	FormFieldProps,
// } from '../CompleteProfilePopup';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const MyMultiSelectField = ({
	id,
	label,
	items,
	formData,
	disabled,
	updateFormData,
}: FormFieldProps) => {
	return (
		<div className="form-item">
			<FormControl fullWidth>
				<InputLabel
					required
					disabled={disabled}
					sx={{
						color: 'rgba(0, 0, 0, 0.75)',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
				>
					{label}
				</InputLabel>
				<Select
					multiple
					disabled={disabled}
					required
					input={<OutlinedInput label={label} />}
					renderValue={(selected) => selected.join(', ')}
					MenuProps={MenuProps}
					name={id}
					value={
						formData[id as keyof FormDataType] as string[]
					}
					sx={{
						color: '#000',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
					onChange={(e) => {
						console.log(e.target.value);
						updateFormData(
							e.target.name,
							typeof e.target.value === 'string'
								? e.target.value.split(',')
								: e.target.value
						);
					}}
				>
					{items &&
						items.map((item: any, idx: number) => (
							<MenuItem
								key={idx}
								value={item}
							>
								<Checkbox
									checked={
										(
											formData[
												id as keyof FormDataType
											] as string[]
										).indexOf(item) > -1
									}
								/>
								<ListItemText primary={item} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default MyMultiSelectField;
