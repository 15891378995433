import { SerializedError } from '@reduxjs/toolkit';

export enum DifficultyTypeEnum {
	EASY = 'EASY',
	MEDIUM = 'MEDIUM',
	HARD = 'HARD',
}

export enum AttemptStatusEnum {
	UNATTEMPTED = 'UNATTEMPTED',
	ATTEMPTED = 'ATTEMPTED',
	SOLVED = 'SOLVED',
}

export enum QuestionTypeEnum {
	ASSIGNMENT = 'ASSIGNMENT',
	PRACTICE_QUESTION = 'PRACTICE_QUESTION',
}

export type GymTabType = {
	id: string;
	isVisible: boolean;
};

export type HeatMapDateType = {
	day: number;
	firstsolveddate: string;
	intensity: number;
	score: number;
};

export type QuestionAnalysisType = {
	difficulty: string | DifficultyTypeEnum;
	percentage: string;
	solved: string;
	total: string;
};

export type RevisionSheetDataType = {
	id: string;
	name: string;
	description: string;
	imageUrl?: string | null;
	type: string;
	suggestedReading: string;
	totalCount: number;
	solvedCount: number;
	isSelected: boolean;
};

export type IdNameRecordType = {
	id: string;
	name: string;
};

export type IdNameCountRecordType = IdNameRecordType & {
	count: number;
};

export type QuestionDataType = {
	id: string;
	title: string;
	difficulty: DifficultyTypeEnum;
	score: number;
	solved: false;
	attemptStatus: AttemptStatusEnum;
	user_score: number;
	bookmarked: boolean;
	tags: Array<IdNameRecordType>;
	questionSource: string;
	questionType: string;
	liked: boolean;
	likes: number;
};

export type GymFilterType = {
	selectedUnit?: {
		unitId?: string;
		courseId?: string;
	};
	topics?: Array<string>;
	difficulties?: Array<DifficultyTypeEnum>;
	questionAttemptStatus?: AttemptStatusEnum;
	questionType?: Array<QuestionTypeEnum>;
	query?: string;
	shouldShowBookmarkedQuestions?: boolean;
	shouldShowLikedQuestions?: boolean;
	shouldShowOnlyJsQuestions?: boolean;
	shouldShowOnlyPythonQuestions?: boolean;
	companyIds?: Array<string>;
	moduleIds?: Array<string>;
};

export type GymStateType = {
	availableTabs: Array<GymTabType> | null;
	isLoading: boolean;
	error?: SerializedError | null;
	heatMapData: Array<HeatMapDateType> | null;
	userQuestionSubmissionData: {
		questionAnalysis: Array<QuestionAnalysisType> | null;
		streak: number;
		totalSolvedQuestions: number;
		restoreStreakNumber: number;
	} | null;
	revisionSheetsByTabId: Record<
		string,
		Array<RevisionSheetDataType> | null
	>;
	companyTagsByTabId: Record<
		string,
		Array<IdNameCountRecordType>
	>;
	questionsByTabId: Record<
		string,
		{
			page: number;
			totalPages: number;
			questions: Array<QuestionDataType>;
		}
	>;
	topicsByTabId: Record<
		string,
		Array<IdNameRecordType> | null
	>;
	appliedFiltersForTabId: Record<
		string,
		Record<string, any> & GymFilterType
	>;
	selectedTabId: string | null;
};

export enum QuestionsTypeEnum {
	MULTIMCQ = 'MULTIMCQ',
	SINGLEMCQ = 'SINGLEMCQ',
	TEXT = 'TEXT',
	INT = 'INT',
}
