import {
	PopupTypes,
	ReferralData,
} from '../../redux/popups/popup.type';
import AllFeedbackModal from './Popups/AllFeedbackModal';
import ReferAndEarnModal from './Popups/ReferAndEarnModal';

const DashboardPopUps = ({
	popUpData,
	displayPopUp,
}: any) => {
	// const [openEnterClg, setOpenEnterClg] = useState(false);
	// useEffect(() => {
	// 	if (popUpData != null) {
	// 		setOpenEnterClg(
	// 			popUpData.popupType === PopupTypes.ENTER_COLLEGE
	// 		);
	// 	}
	// }, [popUpData]);
	return (
		<div className="college-name-container">
			{!displayPopUp ? null /* popUpData.popupType ===
			  PopupTypes.VERIFY_UG_COLLEGE ? (
				<VerifyCollege
					data={popUpData.data}
					type={PopupTypes.VERIFY_UG_COLLEGE}
				/>
			) : popUpData.popupType ===
			  PopupTypes.VERIFY_PG_COLLEGE ? (
				<VerifyCollege
					data={popUpData.data}
					type={PopupTypes.VERIFY_PG_COLLEGE}
				/>
			) : popUpData.popupType ===
			  PopupTypes.LEETCODE_USERNAME ? (
				<StudentLeetcodeProfileNamePopUp />
			) : popUpData.popupType ===
			  PopupTypes.ENTER_COLLEGE ? (
				<EnterCollegeName
					open={openEnterClg}
					setOpen={setOpenEnterClg}
					type={PopupTypes.VERIFY_UG_COLLEGE}
				/>
			) : popUpData.popupType ===
			  PopupTypes.LIVE_LECTURE_FEEDBACK ? (
				<StudentLectureFeedbackPopUp
					data={popUpData.data}
					popupType={PopupTypes.LIVE_LECTURE_FEEDBACK}
				/>
			) : popUpData.popupType ===
			  PopupTypes.RECORDED_LECTURE_FEEDBACK ? (
				<StudentLectureFeedbackPopUp
					data={popUpData.data}
					popupType={PopupTypes.RECORDED_LECTURE_FEEDBACK}
				/>
			) : popUpData.popupType ===
			  PopupTypes.PLACEMENT_ELIGIBILITY ? (
				<PlacementDataModal
					data={popUpData.data}
					popupType={PopupTypes.PLACEMENT_ELIGIBILITY}
				/> ) : */ : popUpData.popupType === PopupTypes.REFERRAL ? (
				<ReferAndEarnModal
					data={popUpData.data as ReferralData}
				/>
			) : // ) : popUpData.popupType ===
			//   PopupTypes.STUDENT_ASSESSMENT_TEST ? (
			// 	<StudentAssesmentModal
			// 		data={popUpData.data as StudentAssesmentData}
			// 	/>
			popUpData.popupType ===
			  PopupTypes.PLATFORM_FEEDBACK ? (
				<AllFeedbackModal popUpData={popUpData} />
			) : null}
		</div>
	);
};

export default DashboardPopUps;
