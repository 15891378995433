import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../apis';
import ApplyPopup from '../../components/jobPortal/ApplyPopup';
import {
	checkBlacklistStatus,
	fetchDataWeeklyHighlited as fetchDataWeeklyHighlighted,
	fetchJobPortalDashboard,
	fetchMockSessionDetails,
} from '../../redux/jobportal/jobPortalSlice';
import { AppDispatch, RootState } from '../../redux/store';

import BlackListModal from '../../components/jobPortal/BlackListModal';

import { nanoid } from 'nanoid';
import { PlacementReadyAnnouncement } from '../../components/home/placementReadyAnnouncement';
import DisplayPlaceMentSupport from '../../components/jobPortal/DisplayPlaceMentSupport';
import DrivesPopup from '../../components/jobPortal/DrivesPopup';
import ExploreJobDrivesWrapper from '../../components/jobPortal/ExploreJobDrivesWrapper';
import FoundCompany from '../../components/jobPortal/FoundCompany';
import HighlightMovingBanner from '../../components/jobPortal/HighlightMovingBanner';
import JobDetailsPopup from '../../components/jobPortal/JobDetailsPopup';
import JobsForYouWrapper from '../../components/jobPortal/JobsForYouWrapper';
import MockScorePopup from '../../components/jobPortal/MockScorePopup';
import OffCampusWrapper from '../../components/jobPortal/OffCampusWrapper';
import StudentPlacementOverview from '../../components/jobPortal/StudentPlacementOverview';
import StudentProfileOverview from '../../components/jobPortal/StudentProfileOverview';
import YourApplicationWrapper from '../../components/jobPortal/YourApplicationWrapper';
import TabPanel from '../../components/shared/TabPanel';
import { checkStudentPlacementReadinessFinder } from '../../redux/session/sessionThunk';
export interface PopupState {
	isOpen: boolean;
	identifier: string;
	notInterested?: boolean;
}

interface JobPortal {
	placementFlag: boolean;
}

const style = {
	outer: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 400,
		bgcolor: '#ECF9FF',
		border: '2px solid #2f80ed',
		outline: '1px solid transparent',
		borderRadius: '8px',
		boxShadow: 24,
		p: 2,
		'&:hover': {
			border: '2px solid #1f4d8b',
		},
	},
	txt: {
		fontSize: '16px',
		fontWeight: '500',
		color: '#000',
	},
	btn: {
		bgcolor: '#2f80ed',
		color: '#fff',
		fontSize: '14px',
		fontWeight: '500',
		width: '30%',
		marginTop: '15px',
		ml: '35%',
		'&:hover': {
			bgcolor: '#1f4d8b',
			color: '#fff',
		},
	},
};

const JobPortal = () => {
	const dispatch = useDispatch<AppDispatch>();
	// tab state
	const [activeTab, setActiveTab] = useState(1);

	const [openBlackListModal, setBlackListModal] =
		useState<boolean>(false);

	const userState = useSelector((state: any) => state.user);

	const { user, isPlacementReady } = userState;

	const responseStudentPlacementReadiness = useSelector(
		(state: any) => state.session
	);

	useEffect(() => {
		if (user) {
			dispatch(checkBlacklistStatus());
			dispatch(checkStudentPlacementReadinessFinder());
		}
	}, [user]);

	const [placementFlag, setPlacementFlag] =
		useState<boolean>(false);

	useEffect(() => {
		if (responseStudentPlacementReadiness) {
			const temp =
				responseStudentPlacementReadiness.studentPlacementReadinessStatus;
			setActiveTab(temp ? 4 : 1);
			setPlacementFlag(temp);
		}
	}, [placementFlag, responseStudentPlacementReadiness]);

	//Pagination state
	const [pageNumber, setPage] = useState(0);
	const [pageSize, setRowsPerPage] = useState(10);
	const [filters, setFilters] = useState({
		companyLocation: [],
		rolesOffered: '',
		companyName: '',
		collegeDegree: [],
		minYearOfGraduation: undefined,
		maxYearOfGraduation: undefined,
	});

	const handleFilterChange = (filterObj: any) => {
		setFilters((prev: any) => {
			return {
				...prev,
				...filterObj,
				// filter:value
			};
		});
	};

	const handleChangePage = (
		event: unknown,
		newPage: number
	) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value));
		setPage(1);
	};
	const [feedbackOption, setFeedbackOption] = useState<
		any[]
	>([]);
	// popup states
	const [profilePopup, setProfilePopup] =
		useState<PopupState>({
			isOpen: false,
			identifier: '',
		});
	const [drivesPopup, setDrivesPopup] =
		useState<PopupState>({
			isOpen: false,
			identifier: '',
		});
	const [mockScorePopup, setMockScorePopup] =
		useState<PopupState>({
			isOpen: false,
			identifier: '',
		});
	const [jobDetailsPopup, setJobDetailsPopup] =
		useState<PopupState>({
			isOpen: false,
			identifier: '',
		});
	const [applyPopup, setApplyPopup] = useState<PopupState>({
		isOpen: false,
		identifier: '',
	});

	const [open, setOpen] = useState<boolean>(false);

	const [isBlackListModalOpen, setBlackListModalOpen] =
		useState<boolean>(false);

	// get global state
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);
	const {
		jobPortalDashboardState,
		jobPortalMockState,
		weeklyPlacedDataState,
		blackListStatus,
	} = jobPortalState;

	//get tab data
	const handleTabChange = (newIndex: number) => {
		setSelectedIndex(newIndex);
	};

	useEffect(() => {
		if (
			blackListStatus !== null &&
			blackListStatus.blacklisted
		) {
			setBlackListModalOpen(true);
		}
	}, [blackListStatus]);

	const [selectedIndex, setSelectedIndex] =
		useState<number>(0);
	console.log('selectedIndex', selectedIndex);

	useEffect(() => {
		const isProfileCompleted = async () => {
			const data =
				await services.jobPortalService.isProfileCompleted();
			if (data !== true) {
				setProfilePopup({
					isOpen: true,
					identifier: '',
				});
			}
		};

		isProfileCompleted();

		if (jobPortalMockState.jobPortalMock === null)
			dispatch(fetchMockSessionDetails());

		if (jobPortalDashboardState.jobPortalDashboard === null)
			dispatch(fetchJobPortalDashboard());
		dispatch(fetchDataWeeklyHighlighted());
	}, []);

	useEffect(() => {
		const flagState = localStorage.getItem('flag-state');
		if (flagState === null || flagState === 'true') {
			setOpen(true);
		}
	}, []);

	const handleClose = () => {
		localStorage.setItem('flag-state', 'false');
		setOpen(false);
	};

	const handleBackdropClick = () => {
		localStorage.setItem('flag-state', 'true');
		setOpen(false);
	};

	const optiondataFilled = async () => {
		const res =
			await services.jobPortalService.fetchFeedBackOption();
		if (res) {
			setFeedbackOption([...res.data]);
		}
	};

	useEffect(() => {
		optiondataFilled();
	}, []);

	const [hideNumber, setHideNumber] = useState(false);

	const toggleNumber = () => {
		setHideNumber(!hideNumber);
	};

	const [jobPortalTabs, setJobPortalTabs] = useState<any[]>(
		[]
	);

	const hideExploreJobTab =
		user?.id === 'b027eeab-ec43-4fbc-9c68-f47fd66e3039';

	useEffect(() => {
		if (placementFlag !== null) {
			const tabsData = [
				{
					keyId: nanoid(),
					title: 'Jobs For You',
					element: (
						<JobsForYouWrapper
							placementFlag={placementFlag}
							setJobDetailsPopup={setJobDetailsPopup}
							setApplyPopup={setApplyPopup}
							setBlackListModalOpen={setBlackListModalOpen}
						/>
					),
					show: placementFlag,
				},
				{
					keyId: nanoid(),
					title: 'Explore Job Drives',
					element: (
						<ExploreJobDrivesWrapper
							placementFlag={placementFlag}
							setJobDetailsPopup={setJobDetailsPopup}
							setApplyPopup={setApplyPopup}
							setBlackListModalOpen={setBlackListModalOpen}
						/>
					),
					show: !hideExploreJobTab,
				},
				{
					keyId: nanoid(),
					title: 'Your Application',
					element: (
						<YourApplicationWrapper
							setJobDetailsPopup={setJobDetailsPopup}
						/>
					),
					show: placementFlag,
				},
				{
					keyId: nanoid(),
					title: 'Off Campus',
					element: (
						<OffCampusWrapper
							filters={filters}
							feedbackOption={feedbackOption}
							handleFilterChange={handleFilterChange}
							pageNumber={pageNumber}
							pageSize={pageSize}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={
								handleChangeRowsPerPage
							}
						/>
					),
					show: true,
				},
			];

			setJobPortalTabs([...tabsData]);
		}
	}, [
		placementFlag,
		hideExploreJobTab,

		filters,
		feedbackOption,

		pageNumber,
		pageSize,
		selectedIndex,
	]);

	return (
		<>
			{!placementFlag ? (
				<Modal
					open={open}
					onClose={handleBackdropClick}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style.outer}>
						<Typography
							id="modal-modal-description"
							sx={style.txt}
						>
							You will be able to see relevant openings here
							once you start becoming eligible for them
							after you’ve completed any of the placement
							readiness modules. Meanwhile, you’re welcome
							to browse the Explore Jobs and Off-campus Jobs
							sections. All the best!
						</Typography>
						<Button
							sx={style.btn}
							onClick={handleClose}
						>
							Continue
						</Button>
					</Box>
				</Modal>
			) : null}

			<div className="job-portal">
				<BlackListModal
					open={isBlackListModalOpen}
					setOpen={setBlackListModalOpen}
				/>

				<div className="column-1">
					<div className="tab-container-job">
						{placementFlag ? (
							<DisplayPlaceMentSupport />
						) : null}

						<div className="tabs">
							<TabPanel
								tabData={jobPortalTabs}
								onTabChange={handleTabChange}
							/>
						</div>
					</div>
				</div>
				<div className="column-2">
					{isPlacementReady ? (
						<div style={{ marginTop: '40px' }}>
							<PlacementReadyAnnouncement />
						</div>
					) : null}
					<FoundCompany />
					<HighlightMovingBanner
						weeklyPlacedDataState={weeklyPlacedDataState}
					/>

					<StudentProfileOverview
						setMockScorePopup={setMockScorePopup}
					/>
					<StudentPlacementOverview
						setDrivesPopup={setDrivesPopup}
					/>
				</div>
				{/* Popups START */}
				{/* <CompleteProfilePopup
				profilePopup={profilePopup}
				setProfilePopup={setProfilePopup}
			/> */}
				<DrivesPopup
					drivesPopup={drivesPopup}
					setDrivesPopup={setDrivesPopup}
				/>
				<MockScorePopup
					mockScorePopup={mockScorePopup}
					setMockScorePopup={setMockScorePopup}
				/>
				<JobDetailsPopup
					activeTab={activeTab}
					placementFlag={placementFlag}
					jobDetailsPopup={jobDetailsPopup}
					setJobDetailsPopup={setJobDetailsPopup}
					setApplyPopup={setApplyPopup}
					setBlackListModalOpen={setBlackListModalOpen}
				/>
				<ApplyPopup
					applyPopup={applyPopup}
					setApplyPopup={setApplyPopup}
				/>
				{/* Popups END */}
			</div>
		</>
	);
};

export default JobPortal;
