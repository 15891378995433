import React, { FC } from 'react';

interface JobDescriptionProps {
	jd: string | null;
}

const JobDescription: FC<JobDescriptionProps> = ({
	jd,
}) => {
	return jd ? (
		<div className="jd">
			<h3>Job Description: </h3>
			<div
				className="rich-text"
				dangerouslySetInnerHTML={{ __html: jd }}
			/>
		</div>
	) : null;
};

export default JobDescription;
