import { IoMdClose } from 'react-icons/io';
import { AiFillCheckCircle } from 'react-icons/ai';
import OwlCarousel from 'react-owl-carousel';
import React from 'react';
// import './../styles/effort-score.scss';

const modelData = [
	{
		name: 'Sumit Mauraya',
		company: 'MAERSK',
		position: 'SDE',
		package: '9 LPA',
		effort: '9.3',
		message: 'Cracked his placement within 6 months.',
		image: '/assets/images/effort-model-1.svg',
	},
	{
		name: 'Stuti Pandey',
		company: 'Walmart',
		position: 'SDE',
		package: '25 LPA',
		effort: '9.5',
		message: 'Cracked her placement within 6 months.',
		image: '/assets/images/effort-model-2.svg',
	},
	{
		name: 'Lalit K Tiwari',
		company: 'Think Future Tech.',
		position: 'SDE',
		package: '15 LPA',
		effort: '8.8',
		message: 'Cracked his placement within 7 months.',
		image: '/assets/images/effort-model-3.svg',
	},
	{
		name: 'Namrata Rathore',
		company: 'Searce',
		position: 'SDE',
		package: '8 LPA',
		effort: '9.1',
		message: 'Cracked her placement within 6 months.',
		image: '/assets/images/effort-model-4.svg',
	},
];

const CarouselModelItem = ({ data }: { data: any }) => {
	return (
		<div
			key={data.name}
			className={'modal-carousel-item'}
		>
			<img
				src={window.location.origin + data.image}
				alt={'image'}
			/>
			<div className={'meta'}>
				<div className={'meta-info'}>
					<h3>{data.name}</h3>
					<p>
						{data.position} at {data.company}
					</p>
					<p>{data.package} CTC</p>
				</div>
				<div className={'modal-effort'}>
					<p>
						<span>{data.effort}</span>
						Job Sincerity Score
					</p>
					<div className={'message'}>{data.message}</div>
				</div>
			</div>
		</div>
	);
};

interface EffortModelProps {
	closeFunction: any;
}

const EffortModel = ({
	closeFunction,
}: EffortModelProps) => {
	const listData = [
		'Job Sincerity score has direct correlation with a student’s performance. Higher job sincerity score guarantees better performance in Modules.',
		'Historically students acing placements have maintained very high job sincerity score.',
		'High job sincerity score and consistency also guarantees lesser time to get placed.',
	];

	return (
		<div className={'effort-model'}>
			<div className={'effort-heading'}>
				<h3>Why is your Job Sincerity Score Important ?</h3>
				<IoMdClose onClick={() => closeFunction(false)} />
			</div>
			<ul className={'effort-model-content'}>
				{listData.map((el: any, ind: number) => (
					<li key={ind}>
						<AiFillCheckCircle /> <p>{el}</p>
					</li>
				))}
			</ul>
			<img
				className={'effort-graph'}
				src={
					'https://quickchart.io/chart?v=undefined&c=%7B%0A%20%20type%3A%20%27line%27%2C%0A%20%20data%3A%20%7B%0A%20%20%20%20labels%3A%20%5B0%2C%202.5%2C%203.125%2C%203.75%2C%204.375%2C%205%2C%205.625%2C%206.25%2C%206.875%2C%207.5%2C%208.125%2C%208.75%2C%209.375%2C%2010%5D%2C%0A%20%20%20%20datasets%3A%20%5B%7B%0A%20%20%20%20%20%20data%3A%20%5B0%2C%209%2C%2016%2C%2027%2C%2034%2C%2042%2C%2047%2C%2054%2C%2060%2C%2066%2C%2071%2C%2075%2C%2082%2C%2094%5D%2C%0A%20%20%20%20%20%20fill%3A%20false%2C%0A%20%20%20%20%20%20borderColor%3A%20getGradientFillHelper(%27vertical%27%2C%20%5B%27GREEN%27%2C%20%27YELLOW%27%2C%20%27RED%27%5D)%2C%0A%20%20%20%20%20%20borderWidth%3A%205%2C%0A%20%20%20%20%20%20pointRadius%3A%200%2C%0A%20%20%20%20%7D%5D%0A%20%20%7D%2C%0A%20%20options%3A%20%7B%0A%20%20%20%20legend%3A%20%7B%0A%20%20%20%20%20%20display%3A%20false%0A%20%20%20%20%7D%2C%0A%09scales%3A%20%7B%0A%20%20%20%20%7D%0A%20%20%7D%0A%7D'
				}
			/>
			<div className={'carousel-div'}>
				<OwlCarousel
					className="owl-theme"
					loop
					margin={5}
					nav
					items={2}
				>
					{modelData.map((el: any, index: number) => (
						<div
							className="modal-item"
							key={index}
						>
							<CarouselModelItem data={el} />
						</div>
					))}
				</OwlCarousel>
			</div>
		</div>
	);
};

export default EffortModel;
