import { Button, TextField } from '@mui/material';
import React, {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import { AiFillMinusCircle } from 'react-icons/ai';
import { MdAddCircle } from 'react-icons/md';
import { services } from '../../../../apis';
import {
	notifyError,
	notifyInfo,
	notifySuccess,
} from '../../../../components/notification';
import MyTextField from '../../formElements/MyTextField';
import Fields from './Fields';

const enumToList = (data: any): string[] =>
	Object.values(data);

enum EmploymentTypes {
	PartTime = 'PART_TIME',
	FullTime = 'FULL_TIME',
	Contract = 'CONTRACT',
	Intern = 'INTERNSHIP',
}

const FormFields = [
	{
		title: 'Experience (Add Experience)*',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			position: {
				label: 'Designation',
				type: 'text',
				disabled: false,
				required: true,
			},
			companyName: {
				label: 'Company Name',
				type: 'text',
				disabled: false,
				required: false,
			},
			jobType: {
				label: 'Employment Type',
				type: 'select',
				items: enumToList(EmploymentTypes),
				disabled: false,
				required: true,
			},
			startDate: {
				label: 'Start Date',
				type: 'date',
				disabled: false,
				reuired: true,
			},
			isCurrent: {
				label: 'Is Current?',
				type: 'checkbox',
				disabled: false,
				required: true,
			},
			endDate: {
				label: 'End Date',
				type: 'date',
				disabled: false,
			},
			description: {
				label: 'Description',
				type: 'text',
				disabled: false,
			},
			// preferredCodingLanguage: {
			// 	label: 'Preferred Coding Language',
			// 	type: 'select',
			// 	disabled: false,
			// 	items: enumToList(preferredCodingLanguageOptions),
			// },
			// otherCodingSkills: {
			// 	label: 'Coding Skills',
			// 	type: 'multiselect',
			// 	disabled: false,
			// 	items: enumToList(otherCodingSkillsOptions),
			// },
		},
	},
];

const AddExperience = () => {
	const initialFormData = {
		// userId: user !== null ? user.id : '',
		companyName: '',
		position: '',
		startDate: '',
		endDate: '',
		description: '',
		isCurrent: false,
		jobType: '',
	};

	const [isProfileLocked, setIsProfileLocked] =
		useState(false);

	const [formFields, setFormFields] = useState(FormFields);

	const [formData, setFormData] = useState(initialFormData);

	const [displayFields, setDisplayFields] = useState(false);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[],
		event?: any
	) => {
		setFormData({
			...formData,
			[fieldId]:
				formData.isCurrent && fieldId == 'endDate'
					? null
					: fieldValue,
		});
	};

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		let temp = formData;
		temp.startDate = new Date(
			formData.startDate
		).toISOString();
		if (!formData.isCurrent)
			temp.endDate = new Date(
				formData.endDate
			).toISOString();
		try {
			const data =
				await services.mentorService.updateMentorExperience(
					temp
				);
			if (data && data.statusCode)
				notifyError(data.message);
			else notifySuccess('Added Succesfully');
		} catch (error: any) {}
	};

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{formFields.map((fieldBatch, idx) => {
				return (
					<div
						key={idx}
						className="batch"
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'start',
							}}
						>
							<h4>{fieldBatch.title}</h4>
							{!displayFields ? (
								<MdAddCircle
									fontSize={'24px'}
									style={{
										marginLeft: '0.25em',
										paddingTop: '0.20em',
									}}
									cursor="pointer"
									onClick={() => setDisplayFields(true)}
								/>
							) : (
								<AiFillMinusCircle
									fontSize={'24px'}
									style={{
										marginLeft: '0.25em',
										paddingTop: '0.20em',
									}}
									cursor="pointer"
									onClick={() => setDisplayFields(false)}
								/>
							)}
						</div>
						{displayFields && (
							<>
								<div className="fields">
									{Object.entries(fieldBatch.items).map(
										([fieldId, field], idx) => (
											<Fields
												field={field}
												idx={idx}
												fieldId={fieldId}
												updateFormData={updateFormData}
												formData={formData}
												isProfileLocked={isProfileLocked}
												setFormData={setFormData}
											/>
										)
									)}
								</div>
								<Button
									className="profile-submit"
									type="submit"
									style={{ marginTop: '1.25em' }}
								>
									Submit
								</Button>
							</>
						)}
					</div>
				);
			})}
		</form>
	);
};

export default AddExperience;
