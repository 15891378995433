import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import acciojobLogo from '../../../../assets/images/AccioJobLogoBig.svg';
import profileShareImg from '../../../../assets/images/profileShareImg.svg';
import {
	fetchHeatMapData,
	fetchUserQuestionSubmissionData,
} from '../../../../redux/gym/thunks/gym.thunk';
import {
	fetchContestData,
	fetchPerformanceUpdates,
} from '../../../../redux/profile/profile.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../redux/store';
import { ActiveEngagements } from './PreviewComponents/ActiveEngagements';
import CompleteProfile from './PreviewComponents/CompleteProfile';
import { ContestRankings } from './PreviewComponents/ContestRankings';
import { CriticalStatistics } from './PreviewComponents/CriticalStatistics';
import { HeatMapCard } from './PreviewComponents/HeatmapCard';
import { QuestionAnalysisCard } from './PreviewComponents/QuestionAnalysisCard';

export const Preview = ({ value }: any) => {
	const dispatch = useAppDispatch();
	const { performanceData, contestData } = useAppSelector(
		(state) => state.profile
	);
	const {
		userQuestionSubmissionData,
		isLoading,
		heatMapData,
	} = useAppSelector((state) => state.gym);

	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);

	useEffect(() => {
		if (!performanceData.data) {
			dispatch(fetchPerformanceUpdates());
		}
		if (!contestData.data) {
			dispatch(fetchContestData());
		}

		if (!userQuestionSubmissionData) {
			dispatch(fetchUserQuestionSubmissionData());
		}

		if (!heatMapData) {
			dispatch(fetchHeatMapData());
		}
	}, []);

	if (
		performanceData.data &&
		!performanceData.loading &&
		contestData.data &&
		!contestData.loading &&
		!isLoading
	)
		return (
			<div className="preview-wrapper">
				<div className="sharing-sheet">
					<div className="left">
						<img
							src={acciojobLogo}
							className="acciojob-logo"
						/>
						<div className="div-flex">
							<div className="left-div-card">
								<p className="grey-heading">Name</p>
								<p className="black-heading">
									{personalDetails?.data?.firstName +
										' ' +
										personalDetails?.data?.lastName}
								</p>
							</div>
							<div className="left-div-card">
								<p className="grey-heading">Skills</p>
								<p className="black-heading">
									{personalDetails?.data?.otherCodingSkills?.join(
										', '
									)}
								</p>
							</div>
						</div>
						<img
							src={profileShareImg}
							className="boy-img"
							alt="asset-img"
						/>
					</div>
					<div className="right">
						{value === 'COMPLETE_PROFILE' ? (
							<CompleteProfile />
						) : value === 'CRITICAL_STATISTICS' ? (
							<CriticalStatistics />
						) : value === 'ACTIVE_ENGAGEMENT' ? (
							<ActiveEngagements streak={false} />
						) : value === 'STREAK' ? (
							<ActiveEngagements streak={true} />
						) : value === 'QUESTION_ANALYSIS' ? (
							<QuestionAnalysisCard />
						) : value === 'CONTEST_RANKING' ? (
							<ContestRankings />
						) : value === 'HEATMAP' ? (
							<HeatMapCard />
						) : (
							<></>
						)}
					</div>
				</div>
			</div>
		);
	else {
		return (
			<div className="preview-wrapper">
				<Loader />
			</div>
		);
	}
};
