import { LocalStorageHelper } from '../LocalStorage';

export class CourseTokenService {
	// static TOKEN_PARAM = 'token';
	constructor(public courseKey: string) {}

	async getToken() {
		try {
			return await LocalStorageHelper.getItem(
				this.courseKey
			);
		} catch (error) {
			return '';
		}
	}

	async setToken(token: string) {
		await LocalStorageHelper.setItem(this.courseKey, token);
	}

	async clearToken() {
		await LocalStorageHelper.removeItem(this.courseKey);
	}
}
