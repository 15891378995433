import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class MentorSessionFeedbackService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async createSession(body: any) {
		const url = `${this.endpoint}/session-feedback/create`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getUserSessions() {
		const url = `${this.endpoint}/session-feedback/`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMentorSessions(mentor_id: string) {
		const url = `${this.endpoint}/session-feedback/${mentor_id}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
