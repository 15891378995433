import { encryptData } from '@acciojob/event-analytics';
import { Box } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import {
	FiArrowRight,
	FiCalendar,
	FiClock,
	FiTarget,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import AccioButton from '../elements/Button';

interface ContestListCardProps {
	data: any;
	type: 'live' | 'upcoming' | 'past' | 'open';
}

const addMinutes = function (dt: any, minutes: any) {
	const a = new Date(dt);
	const b = a.getTime() + minutes * 60000;
	return new Date(b);
};

interface ContestDataProps {
	data: any;
}

const ContestHighLight = ({ data }: ContestDataProps) => {
	return (
		<div className="contestHighLight">
			<h4
				className={`status ${
					data.flag === 'ongoing' ? 'warn' : ''
				}`}
			>
				{data.flag === 'ongoing' ? 'Submit Now !' : 'Live'}
			</h4>
			<h5 className="submission-time">
				<FiCalendar />
				<strong>
					{data.flag === 'ongoing' ? 'Deadline' : 'Ends On'}
					&nbsp;:
				</strong>
				{data.flag === 'ongoing'
					? moment(
							addMinutes(
								data.attemptStartTime,
								data.duration
							)
					  ).format('LLL')
					: moment(data.endTime).format('LLL')}
			</h5>
		</div>
	);
};

const ContestButton = ({ data }: ContestDataProps) => {
	const navigate = useNavigate();
	const [isGivenContest, setIsGivenContest] =
		useState<boolean>(false);
	useEffect(() => {
		const flag = data.flag;
		setIsGivenContest(
			flag === 'upcoming-given' ||
				flag === 'past-given' ||
				flag === 'live-given'
		);
	}, [data]);

	const getEventId = () => {
		const flag = data.flag;
		switch (flag) {
			case 'live-not-given':
				return '836f6deb-15c0-430c-9405-ed14458beb58';
			case 'live-given':
				return '255c7c20-8526-4085-83a8-f3b51f7af255';
			case 'ongoing':
				return '188c72d9-f3c5-4bbf-b4d8-12a6e43ca006';
			case 'upcoming-given':
				return '9f94a03e-3e56-4ebf-bda6-6967c6a5077c';
			case 'upcoming-not-given':
				return '5d3206e5-62ef-47d0-bd62-d873521e0d0d';
			case 'past-given':
				return 'b22e7908-b1c9-4bd8-a353-429757e60231';
			case 'past-not-given':
				return '10aa1dfd-420d-4430-8a91-5b693cf6cc03';
			default:
				return '';
		}
	};

	return (
		<AccioButton
			title={
				data.flag === 'ongoing'
					? 'Resume Contest'
					: 'View Contest'
			}
			action={() => {
				data.projectFlag
					? window.open(
							`https://course.acciojob.com/start-project?project=${data.id}`
					  )
					: navigate(
							`/contest?contestId=${data.contestId}&type=ongoing`
					  );
			}}
			className={`${
				data.flag === 'ongoing'
					? 'ongoingContest'
					: isGivenContest
					? 'contest-question-solved-btn2'
					: ''
			}`}
			variant={data.blackListed ? 'disable' : 'primary'}
			icon={<FiArrowRight />}
			iconPosition={'right'}
			disabled={data.blackListed}
		/>
	);
};

const ContestListCard = ({
	data,
	type,
}: ContestListCardProps) => {
	const [isGivenContest, setIsGivenContest] =
		useState<boolean>(false);
	useEffect(() => {
		const flag = data.flag;
		setIsGivenContest(
			flag === 'upcoming-given' ||
				flag === 'past-given' ||
				flag === 'live-given'
		);
	}, [data]);

	return (
		<Box
			key={data.contestId}
			className={'contest-card'}
		>
			<div className={'contest-details'}>
				<h3 className="contest-name">{data.name}</h3>

				{type === 'live' ? (
					<ContestHighLight data={data} />
				) : null}

				{data.tagArray && data.tagArray.length > 0 ? (
					<ul className={'topic-tags'}>
						{data.tagArray &&
							data.tagArray.map((tags: any) => (
								<li
									key={tags}
									className={'tag'}
								>
									{tags}
								</li>
							))}
					</ul>
				) : null}

				{data.questionTypes &&
				data.questionTypes.length > 0 ? (
					<ul className={'question-tags'}>
						{data.questionTypes.map((questionType: any) => (
							<li
								key={questionType}
								className={'question-tag'}
							>
								{questionType}
							</li>
						))}
					</ul>
				) : null}

				<ul className={'contest-meta'}>
					<li>
						<FiCalendar />
						{data.startTime
							? moment(data.startTime).format("Do MMM'YY")
							: 'access anytime'}
					</li>
					<li>
						<FiTarget />{' '}
						<span>Max Score: {data.totalScore} Points</span>
					</li>
					<li>
						<FiClock />{' '}
						<span>Duration: {data.duration} Minutes</span>
					</li>
				</ul>
				{isGivenContest ? (
					<i> ℹ Your attempt has already been recorded</i>
				) : null}
				{data.blackListed ? (
					<p className="blacklisted">
						You are ineligible for taking this contest as
						you have not given any contest in this module.
						If you have any questions, please contact your
						batch manager
					</p>
				) : null}
			</div>
			<div className={'question-btn'}>
				{!(data.projectFlag && data.blackListed) ? (
					<ContestButton data={data} />
				) : null}
			</div>
		</Box>
	);
};

export default ContestListCard;
