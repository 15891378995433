import React, { useEffect, useState } from 'react';

import { useTimer } from 'react-timer-hook';

interface TimerProps {
	expiryTimestamp: any;
	show: any[];
	expiryHandler: any;
}

const Timer = ({
	expiryTimestamp,
	show,
	expiryHandler,
}: TimerProps) => {
	// console.log(props);
	// const [days, setDays] = useState(0);
	// const [hours, setHours] = useState(0);
	// const [minutes, setMinutes] = useState(0);
	// const [seconds, setSeconds] = useState(0);
	const [active, setActive] = useState(true);
	const [activePercentage, setActivePercentage] =
		useState(0);

	// const timeleft = new Date();
	// timeleft.setSeconds(timeleft.getSeconds() + 100000);
	// const expiryTimestamp = timeleft;

	const { seconds, minutes, hours, days } = useTimer({
		expiryTimestamp,
		onExpire: () => {
			console.warn('onExpire called');
			expiryHandler();
		},
	});

	return (
		<div id="countdown">
			<p className="timeLeft">
				{days * hours !== 0 ? days * hours : hours}:{' '}
				{minutes}
			</p>
		</div>
	);
};

export default Timer;
