import { ENDPOINTS } from '../config/endpoints/stage';
import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils';

export class ReferralService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getReferralUrl() {
		const url = `${this.endpoint}/referral/code`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getReferralData() {
		const url = `${this.endpoint}/referral/getReferredUsers`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async potentialReferral(
		fullName: string,
		email: string,
		phoneNumber: any
	) {
		const url = `${this.endpoint}/referral/potential-referral`;
		const ph = parseInt(phoneNumber);
		const { data } = await this.httpService.post<any>(url, {
			data: {
				fullName,
				email,
				phoneNumber: ph,
			},
		});
		return data;
	}
}
