import React, { useEffect, useState } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import { FormFieldProps } from '../types';
import {
	GeneralProfileFormFields,
	PlacementProfileFormFields,
} from '../student_personal_details';

const MySelectField = ({
	id,
	label,
	items,
	formData,
	disabled,
	updateFormData,
	isProfileLocked,
}: FormFieldProps) => {
	const [value, setValue] = useState<string | null>('');
	useEffect(
		() => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (formData[id] !== null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				if (typeof formData[id] === 'string') {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const temp =
						formData[
							id as keyof (
								| GeneralProfileFormFields
								| PlacementProfileFormFields
							)
						];
					setValue(temp);
				}
			}
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		[formData[id]]
	);

	return (
		<div className="form-item">
			<FormControl fullWidth>
				<InputLabel
					required
					disabled={disabled || isProfileLocked}
					sx={{
						color: 'rgba(0, 0, 0, 0.75)',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
				>
					{label}
				</InputLabel>
				<Select
					required
					disabled={disabled || isProfileLocked}
					value={value}
					// value={
					// 	formData[
					// 		id as keyof (
					// 			| GeneralFormFields
					// 			| PlacementFormFields
					// 		)
					// 	]
					// }
					name={id}
					label={label}
					sx={{
						color: '#000',
						fontSize: '0.9rem',
						fontWeight: 400,
						fontFamily: "'Inter', sans-serif",
					}}
					onChange={(e) => {
						updateFormData(
							e.target.name,
							e.target.value as string,
							e
						);
					}}
				>
					{items &&
						items.map((item, idx) => (
							<MenuItem
								key={idx}
								value={item}
							>
								{item}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default MySelectField;
