import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import Fields from './Fields';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { fetchMentorProfile } from '../../../../redux/mentorSlice/mentorSlice';
import moment from 'moment';
import { services } from '../../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import {
	collegeDepartmentOptions,
	genderOptions,
	jobStatusOptions,
	preferredSpeakingLanguageOptions,
} from '../../student_personal_details';

export enum bachelorCollegeDegreeOptions {
	BTech_BE = 'BTech./BE',
	BCA = 'BCA',
	BBA = 'BBA',
	BSc = 'BSc.',
	BCom = 'BCom',
	BA = 'BA',
	BEd = 'B.Ed.',
	LLB = 'LLB',
	BArch = 'BArch.',
	BDes = 'BDes.',
	CA = 'CA',
	BJMC = 'BJMC',
	BHM = 'BHM',
	Other = 'Other',
}

const enumToList = (data: any) => Object.values(data);

const FormFields = [
	{
		title: 'Basic Details',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			firstName: {
				label: 'First Name',
				type: 'text',
				disabled: false,
				required: true,
			},
			lastName: {
				label: 'Last Name',
				type: 'text',
				disabled: false,
				required: true,
			},
			// email: {
			// 	label: 'Email',
			// 	type: 'text',
			// 	disabled: true,
			// 	required: true,
			// },
			whatsappNumber: {
				label: 'Whatsapp Number',
				type: 'number',
				disabled: false,
				required: true,
				min: 1000000000,
				max: 9999999999,
			},
			linkedInProfile: {
				label: 'LinkedIn URL',
				type: 'text',
				disabled: false,
			},
			dateOfBirth: {
				label: 'Date of Birth',
				type: 'date',
				disabled: false,
			},
			gender: {
				label: 'Gender',
				type: 'select',
				items: enumToList(genderOptions),
			},
			preferredSpeakingLanguages: {
				label: 'Languages Known',
				type: 'multiselect',
				items: enumToList(preferredSpeakingLanguageOptions),
				disabled: false,
			},
			aboutMe: {
				label: 'About Me',
				type: 'text',
				disabled: false,
			},
		},
	},
	{
		title: 'Education Section',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			jobStatus: {
				label: 'Employment Status',
				type: 'select',
				items: enumToList(jobStatusOptions),
				disabled: false,
			},
			collegeName: {
				label: 'College Name',
				type: 'text',
				disabled: false,
			},
			collegeDegree: {
				label: 'Degree/Course',
				type: 'select',
				items: enumToList(bachelorCollegeDegreeOptions),
				disabled: false,
			},
			collegeDepartment: {
				label: 'Department/Stream',
				type: 'select',
				items: enumToList(collegeDepartmentOptions),
				disabled: false,
			},
			graduationYear: {
				label: 'Graduation Year',
				type: 'number',
				disabled: false,
				min: 1900,
				max: 2025,
			},
		},
	},
];

const MentorPersonalInfo = () => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor } = MentorState;

	const initialFormData = {
		firstName: '',
		lastName: '',
		whatsappNumber: '',
		linkedInProfile: '',
		dateOfBirth: '',
		gender: '',
		preferredSpeakingLanguages: [],
		aboutMe: '',
		jobStatus: '',
		collegeName: '',
		collegeDegree: '',
		collegeDepartment: '',
		graduationYear: '',
		profilePhoto: null,
	};

	const [formFields, setFormFields] = useState(FormFields);

	const [formData, setFormData] =
		useState<any>(initialFormData);

	const [isProfileLocked, setIsProfileLocked] =
		useState(false);

	const [file, setFile] = useState<any>();

	const [photoUrl, setPhotoUrl] = useState('');

	const [progresspercent, setProgresspercent] =
		useState<number>();

	useEffect(() => {
		if (mentor !== null) {
			console.log(formData.profilePhoto);
			setFormData({
				...mentor,
				dateOfBirth: moment(mentor.dateOfBirth).format(
					'YYYY-MM-DD'
				),
			});
		}
	}, [mentor]);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[],
		event?: any
	) => {
		console.log('college name', [fieldId]);
		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		const body = {
			...formData,
			languages: formData['preferredSpeakingLanguages'],
		};

		try {
			const data =
				await services.mentorService.updateMentorProfile(
					body
				);
			if (data && data.statusCode)
				notifyError(data.message);
			else notifySuccess(data.message);
		} catch (e) {
			console.log(e);
		}
	};

	const uploadPhoto = async (event: any) => {
		event.preventDefault();
		let formDataVal = new FormData();
		formDataVal.append('file', file);
		const data: any =
			await services.mentorService.uploadMentorPhoto({
				file: file,
			});
		console.log('photoupload', data);
		if (data && data.statusCode) notifyError(data.message);
		else notifySuccess(data.message);
	};

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{formFields.map((fieldBatch, idx) => {
				if (fieldBatch.show && fieldBatch.type === 'batch')
					return (
						<div
							key={idx}
							className="batch"
						>
							<h4>{fieldBatch.title}</h4>
							<div className="fields">
								{Object.entries(fieldBatch.items).map(
									([fieldId, field], idx) => (
										<Fields
											field={field}
											idx={idx}
											fieldId={fieldId}
											updateFormData={updateFormData}
											formData={formData}
											isProfileLocked={isProfileLocked}
										/>
									)
								)}
								{fieldBatch.title === 'Basic Details' && (
									<div className={'upload-resume'}>
										<label>Upload Photo</label>
										{formData.profilePhoto !== null && (
											<a
												href={formData.profilePhoto}
												target={'_blank'}
												rel="noreferrer"
											>
												view Photo
											</a>
										)}
										<input
											type={'file'}
											className={'resumeUpload'}
											onChange={(event: any) =>
												setFile(event.target.files[0])
											}
										/>
										<p onClick={uploadPhoto}>
											upload photo
										</p>
										{photoUrl !== '' && (
											<div className="outerbar">
												<div
													className="innerbar"
													style={{
														width: `${progresspercent}%`,
													}}
												>
													<span>{progresspercent}%</span>
												</div>
											</div>
										)}
									</div>
								)}
							</div>
						</div>
					);
			})}
			<Button
				className="profile-submit"
				type="submit"
			>
				Submit
			</Button>
		</form>
	);
};

export default MentorPersonalInfo;
