import Tab, { tabClasses } from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import { AcademicDetails } from './AcademicDetails';
import { WorkExperience } from './Forms/WorkExperience';
import { PersonalDetails } from './PersonalDetails';

export const EditProfileTabs = () => {
	return (
		<Tabs
			size="md"
			aria-label="Basic tabs"
			defaultValue={0}
			sx={{
				backgroundColor: '#fff',
				margin: '16px 0px',
				borderTop: '1px solid #EAECF0',
				width: '100%',
			}}
		>
			<TabList
				disableUnderline
				sx={{
					borderBottom: '1px solid #EAECF0',
					width: '100%',
					[`&& .${tabClasses.root}`]: {
						flex: 'initial',
						bgcolor: 'transparent',
						color: '#8C9AAC',
						fontFamily: 'Inter',
						fontWeight: 600,
						padding: '12px 0px !important',
						marginRight: '1.5rem !important',
						'&:hover': {
							bgcolor: 'transparent',
						},
						[`&.${tabClasses.selected}`]: {
							color: '#2F80ED',
						},
					},
				}}
			>
				<Tab>Personal Details</Tab>
				<Tab>Academics</Tab>
				<Tab>Work Experience</Tab>
			</TabList>
			<div className="tab-panel-div">
				<TabPanel value={0}>
					<PersonalDetails />
				</TabPanel>
				<TabPanel value={1}>
					<AcademicDetails />
				</TabPanel>
				<TabPanel value={2}>
					<WorkExperience />
				</TabPanel>
			</div>
		</Tabs>
	);
};
