import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const SessionFilters = ({
	sessionTypes,
	startDate,
	endDate,
	setSelectedSessionId,
	setStartDate,
	setEndDate,
}: any) => {
	const [selectedSession, setSelectedSession] =
		useState<any>(null);

	const handleSessionSelect = (id: string) =>
		setSelectedSessionId(id);

	const handleSessionChange = (
		event: SelectChangeEvent
	) => {
		const value = event.target.value as string;
		setSelectedSession(value);
	};

	return (
		<div className="filters">
			<div className="session-filter">
				<FormControl sx={{ width: '200px' }}>
					<InputLabel id="">Session Type</InputLabel>
					<Select
						value={selectedSession}
						label="Session Type"
						onChange={handleSessionChange}
						sx={{
							overflow: 'scroll',
							'&::-webkit-scrollbar': {
								width: '5px',
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: 'transparent',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#D0D5DD',
								borderRadius: '5px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#98A2B3',
							},
						}}
					>
						<MenuItem
							value=""
							onClick={() => handleSessionSelect('')}
						>
							None
						</MenuItem>
						{sessionTypes &&
							sessionTypes.map((session: any) => {
								return (
									<MenuItem
										key={session.id}
										value={session.id}
										onClick={() =>
											handleSessionSelect(session.id)
										}
									>
										{session.sessionType}
									</MenuItem>
								);
							})}
					</Select>
				</FormControl>
			</div>
			<div className="date-filter">
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Start Date"
						value={startDate}
						onChange={(newValue: any) =>
							newValue && newValue.$d != 'Invalid Date'
								? setStartDate(newValue)
								: ''
						}
						format="DD-MM-YYYY"
						sx={{ marginRight: '1em' }}
					/>
					<DatePicker
						label="End Date"
						value={endDate}
						onChange={(newValue: any) =>
							newValue && newValue.$d != 'Invalid Date'
								? setEndDate(newValue)
								: ''
						}
						format="DD-MM-YYYY"
					/>
				</LocalizationProvider>
			</div>
		</div>
	);
};

export default SessionFilters;
