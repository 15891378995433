import { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { VimeoVideoPlayer } from '../../components/shared/VimeoVideoPlayer';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

interface VideoModalProps {
	open: boolean;
	setOpen: any;
	data: any;
}

export default function VideoModal({
	open,
	setOpen,
	data,
}: VideoModalProps) {
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const [videoUrl, setVideoUrl] = useState('');
	// const [volume, setvolume]

	useEffect(() => {
		if (data !== null) {
			if (data.videoUrl !== undefined) {
				setVideoUrl(data.videoUrl);
			}
		}
		console.log('video data', data);
	}, [data]);

	return (
		<div className={'video-overlay'}>
			<div className={'video-card'}>
				<AiOutlineClose onClick={handleClose} />
				{videoUrl !== '' ? (
					<VimeoVideoPlayer
						id={videoUrl}
						options={{
							url: videoUrl,
							width: 640,
							height: 480,
							autoplay: true,
						}}
					/>
				) : null}
			</div>
		</div>
	);
}
