import { useState } from 'react';
import {
	BiSolidErrorAlt,
	BiTimeFive,
} from 'react-icons/bi';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaLock } from 'react-icons/fa';
import resume from '../../../assets/images/resume.svg';
import { useAppSelector } from '../../../redux/store';
import { ResumeFeedbackModal } from '../Modals/ResumeFeedbackModal';

export const ResumeCard = () => {
	const [open, setOpen] = useState(false);
	const [verified, setVerified] = useState(false);

	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	if (performanceData.data) {
		const { resumeDetails } = performanceData.data;

		if (resumeDetails) {
			if (resumeDetails.resumeLink) {
				return (
					<div className="profile-card resume-card">
						<div className="resume-heading-flex">
							<p className="resume-heading">Resume</p>
							{resumeDetails.isResumeLocked ? (
								<p className="resume-chip">
									<FaLock />
									<p>Locked</p>
								</p>
							) : resumeDetails.isResumeVerified ? (
								<p className="resume-chip">
									<BsFillCheckCircleFill />
									<p>Verfied</p>
								</p>
							) : (
								<p className="resume-chip yellow">
									<BiTimeFive />
									<p>Pending</p>
								</p>
							)}
						</div>
						<div className="main-container">
							<img
								src={resume}
								className="resume-img"
							/>
							<p
								className="resume-title"
								onClick={() =>
									window.open(
										resumeDetails.resumeLink ?? '',
										'_blank'
									)
								}
							>
								View Resume
							</p>
						</div>
						{!resumeDetails.isResumeLocked ? (
							<div
								className="resume-bottom-div"
								onClick={() => setOpen(true)}
							>
								View Feedback
							</div>
						) : null}

						<ResumeFeedbackModal
							state={open}
							setState={setOpen}
						/>
					</div>
				);
			} else {
				return (
					<div className="profile-card resume-card">
						<div className="resume-heading-flex">
							<p className="resume-heading">Resume</p>
							<p className="resume-chip yellow">
								<BiTimeFive />
								<p>Pending</p>
							</p>
						</div>
						<div className="main-container">
							<BiSolidErrorAlt
								style={{
									color: '#F2F4F7',
									fontSize: '72px',
								}}
							/>
							<p className="resume-title">
								Upload Pending!
							</p>
						</div>
						<div
							className="resume-bottom-div"
							onClick={() => setOpen(true)}
						>
							Start Feedback
						</div>

						<ResumeFeedbackModal
							state={open}
							setState={setOpen}
						/>
					</div>
				);
			}
		} else {
			return null;
		}
	} else {
		return null;
	}
};
