import React, { useState } from 'react';
import ModuleJourneyCard from './ModuleJourneyCard';
// import {
// 	CAccordion,
// 	CAccordionItem,
// 	CAccordionHeader,
// 	CAccordionBody,
// } from '@coreui/react';
// import '@coreui/coreui/dist/css/coreui.min.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const data = [
	{
		category: 'Completed',
		units: [
			{
				unitId: '40fbe173-edfb-4d33-aaf3-8c916d3cba91',
				unitTag: 'Upcoming',
				coreTags: [],
				eligibility: {
					unitId: '13ba8c23-d78f-4976-8839-a9a67bff9a39',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
			{
				unitId: '548a11a8-7742-4a83-9f38-b506924e0d2b',
				unitTag: 'Ongoing',
				coreTags: [],
				eligibility: {
					unitId: '797d813d-1114-45b0-8014-e5c5761daf09',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
		],
	},
	{
		category: 'Ongoing',
		units: [
			{
				unitId: '40fbe173-edfb-4d33-aaf3-8c916d3cba91',
				unitTag: 'Upcoming',
				coreTags: [],
				eligibility: {
					unitId: '13ba8c23-d78f-4976-8839-a9a67bff9a39',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
			{
				unitId: '548a11a8-7742-4a83-9f38-b506924e0d2b',
				unitTag: 'Ongoing',
				coreTags: [],
				eligibility: {
					unitId: '797d813d-1114-45b0-8014-e5c5761daf09',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
		],
	},
	{
		category: 'Upcoming',
		units: [
			{
				unitId: '40fbe173-edfb-4d33-aaf3-8c916d3cba91',
				unitTag: 'Upcoming',
				coreTags: [],
				eligibility: {
					unitId: '13ba8c23-d78f-4976-8839-a9a67bff9a39',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
			{
				unitId: '548a11a8-7742-4a83-9f38-b506924e0d2b',
				unitTag: 'Ongoing',
				coreTags: [],
				eligibility: {
					unitId: '797d813d-1114-45b0-8014-e5c5761daf09',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
		],
	},
	{
		category: 'Failed',
		units: [
			{
				unitId: '40fbe173-edfb-4d33-aaf3-8c916d3cba91',
				unitTag: 'Upcoming',
				coreTags: [],
				eligibility: {
					unitId: '13ba8c23-d78f-4976-8839-a9a67bff9a39',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
			{
				unitId: '548a11a8-7742-4a83-9f38-b506924e0d2b',
				unitTag: 'Ongoing',
				coreTags: [],
				eligibility: {
					unitId: '797d813d-1114-45b0-8014-e5c5761daf09',
					contestCutOff: 8,
					sessionCutOff: 9,
					sessionTypeId:
						'abeaa856-a2d1-4929-b4f1-b929e55b8a8d',
				},
				mockScore: '9.50',
				mockScoreResponse: 'Passed',
				mockCutOff: 9,
				contestCutOff: 8,
				contestScoreResponse: 'Not Attempted',
				contestScore: 0,
			},
		],
	},
];

type Unit = {
	unitId: string;
	unitTag: string;
	coreTags: string[];
	eligibility: {
		unitId: string;
		contestCutOff: number;
		sessionCutOff: number;
		sessionTypeId: string;
	};
	mockScore: string;
	mockScoreResponse: string;
	mockCutOff: number;
	contestCutOff: number;
	contestScoreResponse: string;
	contestScore: number;
};

type Category = {
	category: string;
	units: Unit[];
};

const ModuleJourneyDetails = () => {
	const [activeIndex, setActiveIndex] = useState<number>(0);

	const handleClick = (index: number) => {
		setActiveIndex(index);
	};

	return (
		// <div>
		// 	{data.map((category: Category, index: number) => (
		// 		<CAccordion
		// 			alwaysOpen
		// 			activeItemKey={activeIndex}
		// 			key={index}
		// 		>
		// 			<CAccordionItem itemKey={category.category}>
		// 				<CAccordionHeader
		// 					onClick={() => handleClick(index)}
		// 				>
		// 					{category.category}
		// 				</CAccordionHeader>
		// 				<CAccordionBody>
		// 					<ModuleJourneyCard cardsData={category} />
		// 					{/* {category.units.map((unit: Unit, index: number) => (
		//             <div key={index}>
		//               <p>{unit.unitTag}</p>
		//               <p>{unit.mockScoreResponse}</p>
		//             </div>
		//           ))} */}
		// 				</CAccordionBody>
		// 			</CAccordionItem>
		// 		</CAccordion>
		// 	))}
		// </div>
		<></>
	);
};

export default ModuleJourneyDetails;
