import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
	fetchMentorSessions,
	setPrevEndTime,
	setPrevSessionId,
	setPrevStartTime,
	setPrevTab,
	setSessionsPage,
} from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import {
	getParams,
	onPropsChange,
	removeDuplicatesById,
} from '../mentorUtils';
import SessionsTable from './SessionsTable';

const COMPLETED = 'COMPLETED';
const CompletedSessions = ({
	sessionType,
	startDate,
	endDate,
}: any) => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorSessions, sessionsPage } = MentorState;

	const [sessionsData, setSessionsData] = useState<any[]>(
		[]
	);

	useEffect(() => {
		dispatch(setSessionsPage(1));
	}, []);

	useEffect(() => {
		if (mentorSessions !== null) {
			const temp = removeDuplicatesById([
				...sessionsData,
				...mentorSessions,
			]);
			setSessionsData([...temp]);
		}
	}, [mentorSessions]);

	useEffect(() => {
		const changed = onPropsChange(
			sessionType,
			startDate,
			endDate,
			COMPLETED
		);
		setSessionsData([]);
		console.log('changed', changed);
		switch (changed) {
			case 'SESSION_CHANGE': {
				dispatch(setSessionsPage(1));
				dispatch(setPrevSessionId(sessionType));
				getSessionsData();
				// dispatch(setSessionsPage(2));
				break;
			}
			case 'DATE_CHANGE': {
				dispatch(setSessionsPage(1));
				dispatch(setPrevStartTime(startDate));
				dispatch(setPrevEndTime(endDate));
				getSessionsData();
				// dispatch(setSessionsPage(2));
				break;
			}
			case 'TAB_CHANGE': {
				getSessionsData();
				dispatch(setPrevTab(COMPLETED));
			}
		}
	}, [sessionType, startDate, endDate]);

	const getSessionsData = () => {
		const params = getParams(
			startDate,
			endDate,
			sessionType,
			COMPLETED
		);
		dispatch(fetchMentorSessions(params));
	};

	const fetchMoreDataCallback = () => {
		console.log('Fetching more data');
		getSessionsData();
		dispatch(setSessionsPage(sessionsPage + 1));
	};

	return (
		<SessionsTable
			sessionsData={sessionsData}
			fetchMoreDataCallback={fetchMoreDataCallback}
			tab={COMPLETED}
		/>
	);
};

export default CompletedSessions;
