import { Loader } from '@acciojob/loader';
import notApplied from '../../assets/images/icons/notApplied.svg';
import mockScore from '../../assets/images/mockScore.svg';
import resumeSession from '../../assets/images/resumeSession.svg';
import tickActive from '../../assets/images/tickActive.svg';

import { MdOutlineNavigateNext } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { PopupState } from '../../pages/jobportal/JobPortal';
import { RootState } from '../../redux/store';

const StudentProfileOverview = ({
	setMockScorePopup,
}: {
	setMockScorePopup: (newState: PopupState) => void;
}) => {
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);
	const userState = useSelector((state: any) => state.user);

	const { jobPortalMockState } = jobPortalState;
	const { loading, jobPortalMock } = jobPortalMockState;

	const formatDate = (date: Date) => {
		const newDate = new Date(date);
		const day = newDate.getDate();
		const month = newDate
			.toLocaleString('default', { month: 'long' })
			.substring(0, 3);
		const year = newDate
			.getFullYear()
			.toString()
			.substring(2, 4);
		return day + ' ' + month + ' ' + year;
	};
	console.log('jobPortalMock', jobPortalMock);
	return (
		<div className="student-profile-overview">
			{/* <button className='suggestcompany-btn' >Suggest a company ?</button> */}
			{loading && (
				<div className="loader-container">
					<Loader
						factVisibility={false}
						width={'100%'}
						height={'100%'}
					/>
				</div>
			)}
			{!loading && (
				<>
					{userState.user && (
						<div className="profile">
							<div className="profile-text">
								<div>
									{userState.user.firstName +
										' ' +
										userState.user.lastName}
								</div>
								<div>{userState.user.email}</div>
							</div>
						</div>
					)}
					{jobPortalMock && (
						<>
							<div className="mock-status">
								<button
									className="mock-status-btn"
									onClick={() => {
										setMockScorePopup({
											isOpen: true,
											identifier: 'User Mock Scores',
										});
									}}
								>
									<div
										className="mock-data-cont"
										event-analytics={
											'7cc61cbb-8a0f-4add-94e2-3a7cda6c770b'
										}
									>
										<div className="mock-head">
											<img src={mockScore} />
											<div>Mock Score Details</div>
										</div>

										<div className="mock-data">
											<p className="mock-data-p">
												{/* {jobPortalMock.score > 0
													? jobPortalMock.score
													: '-'}
												/
												{jobPortalMock.date !== 0
													? formatDate(
															jobPortalMock.date as Date
													  )
													: '-'} */}
												<MdOutlineNavigateNext
													style={{ fontSize: '28px' }}
												/>
											</p>
										</div>
									</div>
									{/* <div>
									<img src={mockDate} />
									<div className='mock-data'>
										<div>
											{jobPortalMock.date !== 0
												? formatDate(
													jobPortalMock.date as Date,
												)
												: '-'}
										</div>
										<div>Last Mock Date</div>
									</div>
								</div> */}
								</button>
							</div>
							<span className="divider"></span>
							<div className="resume-status">
								<img src={resumeSession} />
								<div>Resume Uploaded</div>
								{jobPortalMock.resume ? (
									<img src={tickActive} />
								) : (
									<img src={notApplied} />
								)}
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

export default StudentProfileOverview;
