import { Modal } from '@mui/joy';
import whatsapp_icon from '../../../assets/images/icons/whatsapp.svg';
import { FC, useLayoutEffect, useState } from 'react';
import AccioButton from '../../elements/Button';
import { useAppDispatch } from '../../../redux/store';
import {
	recordClicks,
	recordView,
} from '../../../redux/popups/popup.thunk';
import {
	Action,
	ReferralData,
} from '../../../redux/popups/popup.type';
import {
	notifyError,
	notifySuccess,
} from '../../notification';
import { useNavigate } from 'react-router-dom';
import { services } from '../../../apis';

const ReferAndEarnModal: FC<{ data: ReferralData }> = ({
	data,
}) => {
	const {
		referralCode,
		backgroundImageUrl,
		primaryCTAs,
		secondaryCTAs,
		popFooter,
		viewRecordData,
	} = data;

	const [showModal, setShowModal] = useState(true);
	const [showSecondaryCTAs, setShowSecondaryCTAs] =
		useState(false);
	const [userChoicePhone, setUserChoicePhone] =
		useState('');

	const dispatch = useAppDispatch();

	const handleRecordClicks = async (data: any) => {
		const response =
			await services.popupService.recordClick(data);

		if (
			response.message.toLowerCase() ===
			'click recorded successfully'
		) {
			if (showSecondaryCTAs) {
				notifySuccess(
					'Referral will be sent on your whatsapp number'
				);
				setShowModal(false);
			}
		} else {
			notifyError(response.message);
		}
	};

	const handleCTAClick = (actions: Array<Action>) => {
		for (const action of actions) {
			switch (action.actionType) {
				case 'RECORD_CLICK':
					handleRecordClicks(action.data);
					continue;

				case 'RENDER_SECONDARY_CTAs':
					setShowSecondaryCTAs(true);
					continue;

				case 'NAVIGATION':
					window.open(
						`${window.location.origin}${action.redirectionLink}`,
						'_blank',
						'rel=noopener noreferrer'
					);
					continue;
			}
		}
	};

	const handleUserChoiceNumber = () => {
		if (userChoicePhone.length === 0) {
			notifyError(
				'Please enter your valid whatsapp number'
			);
			return;
		}

		const userChoiceCTA = secondaryCTAs.find(
			(cta) => cta.ctaId === 'send_via_user_choice_wa'
		);

		if (!userChoiceCTA) return;
		const { page, section } = userChoiceCTA.actions[0].data;
		handleRecordClicks({
			page,
			section,
			others: {
				phoneNumber: userChoicePhone,
			},
		});
		sendDataToPipeDream(userChoicePhone);
	};

	const sendDataToPipeDream = async (
		selectedPhone: string
	) => {
		await services.popupService.sendPopupDataToPipeDream(
			selectedPhone,
			`https://acciojob.com?ref=${referralCode}`
		);
	};

	useLayoutEffect(() => {
		if (!referralCode) return;
		dispatch(recordView(viewRecordData));
	}, [referralCode]);

	const handleModalClose = () => {
		if (showSecondaryCTAs) {
			handleRecordClicks({
				page: 'maincourse_home',
				section: 'referral_popup_exit_2',
			});
		} else {
			handleRecordClicks({
				page: 'maincourse_home',
				section: 'referral_popup_exit_1',
			});
		}
		setShowModal(false);
	};

	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={showModal}
			onClose={handleModalClose}
			sx={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<div className="refer-and-earn-container">
				<div>
					<img
						src={backgroundImageUrl}
						className="refer-and-earn-img"
						alt="refer-and-earn-img"
					/>

					{!showSecondaryCTAs ? (
						<div className="referral-link-bottom bottom-one">
							<div className="referral-link-left">
								<h4 className="referral-link-heading">
									Your Referral Link:
								</h4>
								<div className="referral-link-container">
									<p className="referral-link">
										https://acciojob.com/?ref={referralCode}
									</p>
								</div>
							</div>
							{primaryCTAs.map((cta, index) => (
								<AccioButton
									key={index}
									title={cta.ctaText}
									action={() => handleCTAClick(cta.actions)}
									icon={<img src={whatsapp_icon} />}
									iconPosition={'left'}
									className="referral-link-button"
								/>
							))}
						</div>
					) : (
						<div className="referral-link-bottom bottom-two">
							<div className="referral-link-left">
								{secondaryCTAs.map((cta, index) => {
									return cta.ctaId ===
										'send_via_whatsapp' ? (
										<AccioButton
											key={index}
											title={cta.ctaText}
											action={() => {
												handleCTAClick(cta.actions);
												sendDataToPipeDream(
													cta.actions[0].data.others!
														.phoneNumber
												);
											}}
											className="send-to-number-btn"
										/>
									) : null;
								})}
							</div>
							<div className="referral-link-right">
								<input
									className="mobile-number-input"
									placeholder="Enter your WhatsApp number"
									value={userChoicePhone}
									onChange={(e) =>
										setUserChoicePhone(e.target.value)
									}
								/>
								<AccioButton
									title={'Send'}
									className="send-btn"
									action={() => handleUserChoiceNumber()}
								/>
							</div>
						</div>
					)}
				</div>
				<div className="footer">
					<div
						className="footer-text"
						dangerouslySetInnerHTML={{
							__html: popFooter.footerText,
						}}
					/>

					{popFooter.ctas.map((cta, index) => (
						<div
							key={index}
							className="learn-more"
							onClick={() => handleCTAClick(cta.actions)}
							dangerouslySetInnerHTML={{
								__html: cta.ctaText,
							}}
						/>
					))}
				</div>
			</div>
		</Modal>
	);
};

export default ReferAndEarnModal;
