import {
	Dialog,
	DialogTitle,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import React, { useState } from 'react';

function DidntHappenModal({
	open,
	handleClose,
	session,
	setReason,
	handleSessionDidntHappen,
}: any) {
	const labels = {
		wrongSession: 'Wrong session type booked by student',
		internetIssue:
			'Internet Issue from Student / Mentor side',
		identifyMismatch: 'Identify mismatch',
		studentLeft: 'Student left the session in between',
		studentDenied: 'Student denied to give the mock',
		studentNotResponding:
			'Student not answering the calls / text',
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ padding: '1em' }}
			>
				<DialogTitle>
					Reason for not conducting the session
				</DialogTitle>
				<FormControl sx={{ padding: '1em' }}>
					<RadioGroup name="radio-buttons-group">
						{Object.keys(labels).map((label: string) => (
							<FormControlLabel
								key={label}
								control={
									<Radio onClick={() => setReason(label)} />
								}
								//@ts-ignore
								value={labels[label]}
								//@ts-ignore
								label={labels[label]}
							/>
						))}
					</RadioGroup>
				</FormControl>
				{session && (
					<button
						className="btn-blue session-didnt-happen"
						onClick={() =>
							handleSessionDidntHappen(session.sessionId)
						}
					>
						Submit
					</button>
				)}
			</Dialog>
		</div>
	);
}

export default DidntHappenModal;
