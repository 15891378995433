import React, { useEffect, useState } from 'react';
import './modulejourneystepper.css';
import {
	RiCheckDoubleFill,
	RiCheckFill,
} from 'react-icons/ri';
import { TfiLineDotted } from 'react-icons/tfi';
import { MdOutlineClose } from 'react-icons/md';
import { HiOutlineTrophy } from 'react-icons/hi2';

type StatusType =
	| 'completed'
	| 'partially-completed'
	| 'ongoing'
	| 'failed'
	| 'upcoming';

interface Item {
	name: string;
	status: StatusType;
	IsPlacement: boolean;
}

interface ModuleJourneyStepperProps {
	data: Item[][];
}

const ModuleJourneyStepper: React.FC<
	ModuleJourneyStepperProps
> = ({ data }) => {
	type DataType = {
		name: string;
		status: string;
		IsPlacement: boolean;
		isDevided?: boolean;
	}[][];

	const addIsDevidedProperty = (data: DataType): void => {
		const lastOneObjectArrayIndex = data.findIndex(
			(item) => item.length !== 1
		);

		if (lastOneObjectArrayIndex > 0) {
			data[lastOneObjectArrayIndex - 1][0].isDevided = true;
		}

		for (
			let i = lastOneObjectArrayIndex;
			i < data.length;
			i++
		) {
			data[i].forEach((item) => (item.isDevided = false));
		}
	};

	const getIconElement = (
		status: StatusType
	): JSX.Element => {
		if (status === 'completed') {
			return <RiCheckDoubleFill />;
		} else if (status === 'partially-completed') {
			return <RiCheckFill />;
		} else if (
			status === 'ongoing' ||
			status === 'upcoming'
		) {
			return <TfiLineDotted />;
		} else if (status === 'failed') {
			return <MdOutlineClose />;
		} else {
			return <TfiLineDotted />;
		}
	};

	const getIconColor = (status: StatusType): string => {
		if (status === 'completed') {
			return '#fff';
		} else if (status === 'partially-completed') {
			return '#fff';
		} else if (status === 'ongoing') {
			return '#388af9';
		} else if (status === 'upcoming') {
			return '#e0e0e0';
		} else if (status === 'failed') {
			return '#fff';
		} else {
			return '#fff';
		}
	};
	const getIconTrophyColor = (
		status: StatusType
	): string => {
		if (status === 'completed') {
			return '#12b76a';
		} else if (status === 'partially-completed') {
			return '#ff940a';
		} else if (status === 'ongoing') {
			return '#388af9';
		} else if (status === 'upcoming') {
			return '#e1e1e1';
		} else if (status === 'failed') {
			return '#ef574c';
		} else {
			return '#fff';
		}
	};

	const getStatusClassName = (
		status: StatusType
	): string => {
		if (status === 'completed') {
			return ' module-journey-stepper_item_circle module-journey-stepper_item_completed ';
		} else if (status === 'partially-completed') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_partially-completed ';
		} else if (status === 'ongoing') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_ongoing ';
		} else if (status === 'upcoming') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_upcoming ';
		} else if (status === 'failed') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_failed ';
		} else {
			return '';
		}
	};

	const getClassForPlacement = (
		status: StatusType
	): string => {
		if (status === 'completed') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_placement module-journey-stepper_item_circle module-journey-stepper_item_completed ';
		} else if (status === 'partially-completed') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_placement module-journey-stepper_item_circle module-journey-stepper_item_partially-completed ';
		} else if (status === 'ongoing') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_placement module-journey-stepper_item_circle module-journey-stepper_item_ongoing-placement ';
		} else if (status === 'upcoming') {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_placement module-journey-stepper_item_circle module-journey-stepper_item_upcoming ';
		} else if (status === 'failed') {
			return ' module-journey-stepper_item_circle module-journey-stepper_item_placement module-journey-stepper_item_circle module-journey-stepper_item_failed ';
		} else {
			return 'module-journey-stepper_item_circle module-journey-stepper_item_placement';
		}
	};

	const getItemClassName = (item: Item): string => {
		if (item.IsPlacement) {
			return 'module-journey-stepper_item_circle module-journey-stepper-job-ready-class';
		} else {
			return getStatusClassName(item.status);
		}
	};

	const getItemIconElement = (item: Item): JSX.Element => {
		if (item.IsPlacement) {
			const iconElement = <HiOutlineTrophy />;
			const iconColor = getIconTrophyColor(item.status);
			const iconStyle = {
				color: iconColor,
				fontSize: '42px',
				marginTop: '-15px',
			};
			return (
				<>
					<span style={iconStyle}>
						{React.cloneElement(iconElement)}
					</span>
				</>
			);
		} else {
			const iconElement = getIconElement(item.status);
			const iconColor = getIconColor(item.status);
			const iconStyle = {
				color: iconColor,
				fontSize: '16px',
			};
			return (
				<>
					<span style={iconStyle}>
						{React.cloneElement(iconElement)}
					</span>
				</>
			);
		}
	};

	const [dataUpgraded, setDataUpgraded] =
		useState<Item[][]>(data);

	useEffect(() => {
		if (data.length > 0) {
			addIsDevidedProperty(data);
		}
	}, [data]);

	return (
		<div className="module-journey-stepper">
			{dataUpgraded.map((item: any, i: number) => (
				<div
					key={i}
					className={`module-journey-stepper_item ${
						item.length === 1
							? 'stepper-style-flex-center'
							: 'stepper-style-flex-space-between'
					}`}
				>
					{item.map((item: any, index: number) => (
						<div
							key={index}
							className="stepper-container-name-path"
						>
							<div
								key={index}
								className="stepper-container-full"
							>
								<div
									key={index}
									className={getItemClassName(item)}
								>
									{getItemIconElement(item)}
								</div>
								{i !== dataUpgraded.length - 1 ? (
									item.isDevided ? (
										<div
											className="line-draw-stepper-container line-draw-stepper-container-double "
											key={index}
										>
											<div
												className="line-draw-stepper line-draw-stepper-1"
												style={{
													borderColor: `${
														item.status === 'upcoming'
															? '#e1e1e1'
															: '#000'
													}`,
													borderStyle: `${
														item.status === 'upcoming'
															? 'dashed'
															: 'solid'
													}`,
												}}
											></div>
											<div
												className="line-draw-stepper line-draw-stepper-2"
												style={{
													borderColor: `${
														item.status === 'upcoming'
															? '#e1e1e1'
															: '#000'
													}`,
													borderStyle: `${
														item.status === 'upcoming'
															? 'dashed'
															: 'solid'
													}`,
												}}
											></div>
										</div>
									) : (
										<div
											className="line-draw-stepper-container"
											key={index}
										>
											<div
												className="line-draw-stepper"
												style={{
													borderColor: `${
														item.status === 'upcoming'
															? '#e1e1e1'
															: '#000'
													}`,
												}}
											></div>
										</div>
									)
								) : null}
							</div>
							<span
								key={index}
								className="stepper-name"
							>
								{item.name}
							</span>
						</div>
					))}
				</div>
			))}
		</div>
	);
};

export default ModuleJourneyStepper;
