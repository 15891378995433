import { Loader } from '@acciojob/loader';
import { Modal, Sheet, Tooltip } from '@mui/joy';
import React, { useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

interface TestCase {
	testCaseEvaluationStatus: string;
}
interface PopUpDialogProps {
	data: {
		testCases?: TestCase[];
		attemptEvaluationStatus?: string;
	};
	loader1: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	selectedValue: boolean;
	open: boolean;
	loader2: boolean;
	attemptStatus: boolean;
	attemptMessage: string | null;
	attemptId: string | null;
	questionId: string | null;
	lastAttemptMsg: boolean | null;
}
const PopupModal: React.FC<PopUpDialogProps> = ({
	data,
	loader1,
	setOpen,
	selectedValue,
	open,
	loader2,
	attemptStatus,
	attemptMessage,
	attemptId,
	questionId,
	lastAttemptMsg,
}) => {
	const handleClose = () => {
		setOpen(selectedValue);
	};

	const [finalStatus, setFinalStatus] =
		useState<string>('');

	useEffect(() => {
		const passTestCase = data?.testCases?.filter(
			(item) => item.testCaseEvaluationStatus === 'PASSED'
		);
		const failTestCase = data?.testCases?.filter(
			(item) => item.testCaseEvaluationStatus === 'FAILED'
		);

		console.log('passTestCase', passTestCase, failTestCase);

		if (
			passTestCase &&
			failTestCase &&
			passTestCase.length >= 0 &&
			failTestCase.length >= 0 &&
			passTestCase.length > failTestCase.length
		) {
			setFinalStatus('You have cleared all the test cases');
		} else {
			setFinalStatus(
				`You have failed ${
					failTestCase?.length ?? 0
				} test cases`
			);
		}
	}, [data?.testCases]);
	const [headerMsg, setHeaderMsg] = useState('');

	useEffect(() => {
		let newHeaderMsg = '';

		if (loader1) {
			newHeaderMsg =
				'Wait test cases are under evolution..';
		} else if (loader2) {
			newHeaderMsg = 'Evaluating Test Cases';
		} else {
			if (
				data?.attemptEvaluationStatus === 'PASSED' ||
				data?.attemptEvaluationStatus === 'FAILED'
			) {
				newHeaderMsg = 'Test Cases Evaluation Status';
			} else {
				newHeaderMsg =
					attemptMessage || 'Checking Test Cases';
			}
		}

		setHeaderMsg(newHeaderMsg);
	}, [loader1, data, attemptMessage]);

	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={open}
			onClose={() => data && setOpen(false)}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				backgroundColor: 'none ',
				backdropFilter: 'blur(0px)',
			}}
		>
			<Sheet
				className={`modal-sheet-view ${
					open ? 'open-from-bottom' : ''
				}`}
				variant="outlined"
				sx={{
					maxWidth: 300,
					borderRadius: 'md',
					p: 1.5,
					boxShadow: 'lg',
					position: 'absolute',
					right: '10px',
				}}
			>
				<div className="modal-header">
					<span>{headerMsg}</span>
				</div>
				{lastAttemptMsg ? (
					<p className="last-attempt-msg">
						This is last attempt status
					</p>
				) : null}

				{attemptId ? (
					loader2 ? (
						<div className="test-cases-loader-wrapper">
							<Loader />
						</div>
					) : (
						<div className="test-cases-status-wrapper">
							<p className="final-status">
								{'Final Status : '}
								<span
									className={`${
										data?.attemptEvaluationStatus ===
										'PASSED'
											? 'passed-test-case'
											: 'failed-test-case'
									}`}
								>
									{data?.attemptEvaluationStatus}
								</span>
								<Tooltip
									arrow
									size="lg"
									color={
										finalStatus.includes('all')
											? 'success'
											: 'danger'
									}
									placement="top"
									variant="outlined"
									title={finalStatus}
								>
									<span>
										<FiInfo />
									</span>
								</Tooltip>
							</p>

							<hr className="hr" />
							<div className="test-cases">
								{data?.testCases?.length
									? data.testCases.map(
											(item: any, index: number) => (
												<div
													key={index}
													className={`test-case ${
														item.testCaseEvaluationStatus ===
														'PASSED'
															? 'passed-test-case-bg'
															: item.testCaseEvaluationStatus ===
															  'FAILED'
															? 'failed-test-case-bg'
															: ''
													}`}
												>
													<span>{`Test Case ${
														index + 1
													} : `}</span>

													<span>
														{item.testCaseEvaluationStatus ===
														'EVALUATING' ? (
															<div className="load-pulse">
																<div className="progress"></div>
																<div className="progress"></div>
																<div className="progress"></div>
															</div>
														) : (
															item.testCaseEvaluationStatus
														)}
													</span>
												</div>
											)
									  )
									: null}
							</div>
						</div>
					)
				) : (
					<div className="no-test-cases">
						{' '}
						Wait until test cases are ready{' '}
					</div>
				)}
			</Sheet>
		</Modal>
	);
};

export default PopupModal;
