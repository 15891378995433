import React, { useState } from 'react';
import MentorPersonalInfo from './MentorPersonalInfo';
import MentorProfessionalInfo from './MentorProfessionalInfo';
import MentorSessionPreference from './MentorSessionPreference';
const MentorProfile = () => {
	// tab state
	const [activeTab, setActiveTab] = useState(1);

	return (
		<div className="profile-page">
			<h1>
				Your <span>Profile</span>
			</h1>
			<p>
				Complete your profile to unlock rest of the pages.
			</p>

			<div className="tab-container">
				<div className="tabs">
					<button
						className={activeTab === 1 ? 'active' : ''}
						onClick={() => {
							setActiveTab(1);
						}}
					>
						<span>1</span>Personal & Education Info
					</button>
					<button
						className={activeTab === 2 ? 'active' : ''}
						onClick={() => {
							setActiveTab(2);
						}}
					>
						<span>2</span>Professional Info
					</button>
					<button
						className={activeTab === 3 ? 'active' : ''}
						onClick={() => {
							setActiveTab(3);
						}}
					>
						<span>3</span>Preference for Sessions
					</button>
				</div>

				<div className="tab-content">
					{activeTab === 1 && <MentorPersonalInfo />}
					{activeTab === 2 && <MentorProfessionalInfo />}
					{activeTab === 3 && <MentorSessionPreference />}
				</div>
			</div>
		</div>
	);
};

export default MentorProfile;
