import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../apis';

export const getSoftSkillsModuleDetails = createAsyncThunk(
	'softSkills/moduleDetails',
	async (id: string) => {
		return await services.softskillsService.getSoftSkillsModuleDetails(
			id
		);
	}
);

export const fetchLiveClassJoinLink = createAsyncThunk(
	'softSkills/liveClassJoinLink',
	async (moduleId: string) => {
		return await services.softskillsService.getLiveClassJoinLink(
			moduleId
		);
	}
);

export const registerForSession = createAsyncThunk(
	'softSkills/registerBreakoutSession',
	async (args: { moduleId: string; videoId: string }) => {
		return await services.softskillsService.registerForSession(
			args
		);
	}
);
