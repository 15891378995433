import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import moment from 'moment';

import eventBg from '../../assets/images/eventbg.svg';
import AccioButton from '../elements/Button';
import { FiArrowRight } from 'react-icons/fi';
import { encryptData } from '@acciojob/event-analytics';
import { isMainCourseUser } from '../../utils/events';

interface contestProps {
	currentContest: any[];
}

const ContestCards = ({ currentContest }: contestProps) => {
	const dispatch = useDispatch<AppDispatch>();
	const userState = useSelector((state: any) => state.user);
	const { userRole } = userState;

	return (
		<div>
			{currentContest !== null
				? currentContest.map((el: any) => (
						<div
							className={'upcoming-event contest-event'}
							key={el.contestId}
						>
							<h3 className={'upcoming-heading'}>
								<span>Your Contest</span>
							</h3>
							<div className={'event'}>
								<div className={'upcoming-event-date'}>
									<img
										src={eventBg}
										alt=""
									/>
									<div
										className={'upcoming-event-date-data'}
									>
										<span>
											{moment(el.startTime).format('MMM')}
										</span>
										<p>
											{moment(el.startTime).format('DD')}
										</p>
									</div>
								</div>
								<div className={'event-details'}>
									<h3>{el.name}</h3>
									<p>
										{moment(el.startTime).format('hh:mm A')}
									</p>
									<AccioButton
										title="Start"
										icon={<FiArrowRight />}
										action={() =>
											el.projectFlag
												? window.open(
														`https://course.acciojob.com/start-project?project=${el.id}`
												  )
												: window.open(
														`https://contest2.acciojob.com?contestID=${el.contestId}`
												  )
										}
									/>
								</div>
							</div>
							<div
								style={{
									fontSize: '12px',
									paddingBottom: '10px',
								}}
								event-analytics={
									isMainCourseUser() &&
									el.flag === 'ongoing'
										? '4317384d-b62d-47cd-863d-e9df4b26356a'
										: ''
								}
								data-config={encryptData(
									JSON.stringify({
										name: el.name,
									})
								)}
							>
								{el.flag === 'ongoing' ? (
									<u>Your test is ongoing! Please join!</u>
								) : (
									<u>
										Available between{' '}
										{moment(el.startTime).format(
											' hh:mm A DD MMM'
										)}{' '}
										-{' '}
										{moment(el.endTime).format(
											' hh:mm A DD MMM'
										)}
									</u>
								)}
							</div>
						</div>
				  ))
				: null}
		</div>
	);
};

export default ContestCards;
