import ActiveEngagementCard from '../SharedComponents/ActiveEngagementCard';
import { ContestRanking } from '../SharedComponents/ContestRanking';
import { HeatMap } from '../SharedComponents/HeatMap';
import { QuestionAnalysis } from '../SharedComponents/QuestionAnalysis';
import { StreakCard } from '../SharedComponents/StreakCard';

const CompleteProfile = () => {
	return (
		<div className="preview-right-wrapper">
			<div className="preview-row">
				<ActiveEngagementCard />
				<StreakCard />
			</div>
			<div className="preview-row">
				<QuestionAnalysis />
				<ContestRanking />
			</div>
			<div className="preview-row">
				<HeatMap vertical={false} />
			</div>
		</div>
	);
};

export default CompleteProfile;
