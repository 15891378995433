import {
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { getSoftSkillsModuleDetails } from './softskills.thunk';
import { SoftskillsState } from './softskills.type';

const initialState: SoftskillsState = {
	isLoadingCourses: false,
	isloadingModules: false,
	isLoadingModuleDetails: false,
	selectedCourseTitle: '',
	cachedModuleDetails: {},
	moduleDetails: {
		videoContent: [],
		liveClasses: {
			upcomingLiveClasses: [],
			ongoingLiveClasses: [],
			pastLiveClasses: [],
		},
		assignments: [],
		breakoutSession: [],
		readingContent: [],
		feedback: [],
	},
	selectedModuleDetails: {
		selectedModuleId: null,
		activeTabIndex: null,
	},
};

export const softskillsSlice = createSlice({
	name: 'softskills',
	initialState,
	reducers: {
		setSelectedCourseTitle: (
			state,
			action: PayloadAction<string>
		) => {
			state.selectedCourseTitle = action.payload;
			if (state.selectedCourseTitle) {
				localStorage.setItem(
					'selected-course-title',
					state.selectedCourseTitle
				);
			}
		},
		setSelectedModuleDetails: (
			state,
			action: PayloadAction<{
				selectedModuleId?: string | null;
				activeTabIndex?: number | null;
			}>
		) => {
			state.selectedModuleDetails = {
				...state.selectedModuleDetails,
				...action.payload,
			};
			console.log(
				'1 1',
				state.selectedModuleDetails.selectedModuleId,
				state.selectedModuleDetails.activeTabIndex
			);
			if (
				state.selectedModuleDetails.selectedModuleId &&
				Number.isInteger(
					state.selectedModuleDetails.activeTabIndex
				)
			) {
				console.log('1 2');
				localStorage.setItem(
					'selected-module-details',
					JSON.stringify(state.selectedModuleDetails)
				);
			}
		},
		updateModuleDetails: (
			state,
			action: PayloadAction<{ id: string }>
		) => {
			state.moduleDetails =
				state.cachedModuleDetails[action.payload.id];
		},
	},
	extraReducers: {
		[getSoftSkillsModuleDetails.pending.type]: (state) => {
			state.isLoadingModuleDetails = true;
		},
		[getSoftSkillsModuleDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof getSoftSkillsModuleDetails.fulfilled
			>
		) => {
			state.isLoadingModuleDetails = false;
			state.moduleDetails = action.payload;
			if (state.selectedModuleDetails.selectedModuleId) {
				state.cachedModuleDetails = {
					...state.cachedModuleDetails,
					[state.selectedModuleDetails.selectedModuleId]:
						action.payload,
				};
			}
		},
		[getSoftSkillsModuleDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof getSoftSkillsModuleDetails.rejected
			>
		) => {
			state.isLoadingModuleDetails = false;
		},
	},
});

export const SoftskillsActions = softskillsSlice.actions;
export const SoftskillsReducers = softskillsSlice.reducer;
