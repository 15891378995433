import React, { useState } from 'react';

interface MentorTextFeedbackProps {
	feedBackTextdata: string;
	isMockConvertedToMentorship: boolean;
}

const MentorTextFeedback: React.FC<
	MentorTextFeedbackProps
> = ({ feedBackTextdata, isMockConvertedToMentorship }) => {
	const [showMore, setShowMore] = useState(false);

	const handleToggleShowMore = () => {
		setShowMore((prevShowMore) => !prevShowMore);
	};

	const getLimitedFeedback = () => {
		const maxWords = 100;
		if (feedBackTextdata && feedBackTextdata.length > 0) {
			const words = feedBackTextdata.split(' ');
			return words.slice(0, maxWords).join(' ');
		} else {
			return null;
		}
	};

	return feedBackTextdata && feedBackTextdata.length > 0 ? (
		<div
			className={`mentor-text-feedback-outer-container ${
				isMockConvertedToMentorship
					? 'outer-active-border'
					: ''
			}`}
		>
			{isMockConvertedToMentorship ? (
				<p className="mock-converted-to-mentorship-text">
					This mock interview was converted into a
					mentorship session by the mentor
				</p>
			) : null}
			<div
				className={`mentor-text-feedback-container ${
					isMockConvertedToMentorship ? 'active-border' : ''
				}`}
			>
				<p className="mentor-text-feedback-title">
					Let’s see what our Mentor has to say about the
					Interview!
				</p>
				<p
					className={`mentor-text-feedback ${
						showMore ? 'expanded' : 'collapsed'
					}`}
					style={{
						maxHeight: showMore ? '100%' : 'max-content',
					}}
				>
					{getLimitedFeedback()}
				</p>
				{feedBackTextdata &&
					feedBackTextdata.length > 0 &&
					feedBackTextdata.split(' ').length > 100 && (
						<button
							className="show-more-button"
							onClick={handleToggleShowMore}
						>
							{showMore ? 'Hide more' : 'Show more'}
						</button>
					)}
			</div>
		</div>
	) : null;
};

export default MentorTextFeedback;
