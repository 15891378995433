import { Radio } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import html2canvas from 'html2canvas';
import { useState } from 'react';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { services } from '../../../../apis';
import { useAppSelector } from '../../../../redux/store';
import AccioButton from '../../../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../../../notification';
import { Preview } from './Preview';
import slugify from 'slugify';
export const ShareModal = ({ state, setState }: any) => {
	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);

	const arr = [
		{
			heading: 'Complete Profile',
			value: 'COMPLETE_PROFILE',
			desc: 'Days of active engagement & Continous Question Solving',
		},
		{
			heading: 'Critical Statistics',
			value: 'CRITICAL_STATISTICS',
			desc: 'Activity , Streak and Performance Heatmap',
		},
		{
			heading: 'Active Engagement',
			value: 'ACTIVE_ENGAGEMENT',
			desc: 'Days of over 1.5 hours of activity on the platform',
		},
		{
			heading: 'Streak',
			value: 'STREAK',
			desc: 'Days of Continous Question Solving',
		},
		{
			heading: 'Question Analysis',
			value: 'QUESTION_ANALYSIS',
			desc: 'Activity , Streak and Performance Heatmap',
		},
		{
			heading: 'Contest Ranking',
			value: 'CONTEST_RANKING',
			desc: 'Activity , Streak and Performance Heatmap',
		},
		{
			heading: 'Heatmap',
			value: 'HEATMAP',
			desc: 'Performance Heatmap',
		},
	];
	const [value, setValue] = useState('COMPLETE_PROFILE');

	const uploadImage = async (file: File, name: string) => {
		try {
			const { signedUrl, publicUrl } =
				await services.userService.getProfileShareLink(
					slugify(name, {
						remove: /"<>#%\{\}\|\\\^~\[\]`;\?:@=&/g,
					})
				);

			await services.fileUploadDownloadService.uploadFile(
				signedUrl,
				file,
				'image/jpeg'
			);
			toast.success('Image uploaded successfully');
			return publicUrl;
		} catch (error) {
			console.log(error);
			toast.error('Image Could Not Be Uploaded!');
		}
	};

	const handleDownloadProfile = async () => {
		const previewElement = document.querySelector(
			'.sharing-sheet'
		) as HTMLElement;
		if (previewElement) {
			const canvas = await html2canvas(previewElement);
			const imgURL = canvas.toDataURL('image/jpeg');
			const link = document.createElement('a');
			link.href = imgURL;
			link.download = 'profile-preview.jpeg';
			link.click();
		}
	};

	const handleShareProfile = async () => {
		const previewElement = document.querySelector(
			'.sharing-sheet'
		) as HTMLElement;
		if (previewElement) {
			const canvas = await html2canvas(previewElement);
			canvas.toBlob(async (blob) => {
				if (blob) {
					const file = new File(
						[blob],
						personalDetails.data?.email +
							new Date().toISOString(),
						{
							type: 'image/jpeg',
						}
					);
					console.log('Fileee>>>', file);
					const name =
						personalDetails.data?.email +
						new Date().toISOString() +
						'.jpeg';
					console.log('name>>>>', name);
					const imgURL = await uploadImage(file, name);
					console.log('imgURL>>>>', imgURL);
					// Copy the URL to clipboard
					navigator.clipboard.writeText(imgURL).then(
						() => {
							notifySuccess(
								'Image URL copied to clipboard!'
							);
						},
						(err) => {
							notifyError(
								'Could not copy URL to clipboard!'
							);
						}
					);
				}
			});
		}
	};

	return (
		<div>
			<Modal
				open={state}
				onClose={() => setState(false)}
				className="share-modal-container"
			>
				<div className="share-modal-wrapper">
					<div className="header-wrapper">
						<div className="heading-flex">
							<AiOutlineShareAlt
								style={{ color: '#000', fontSize: '24px' }}
							/>
							<p className="heading">Share Your Profile</p>
						</div>
						{/* <p className="desc">
							Lorem ipsum dolor sit amet consectetur.
						</p> */}
					</div>
					<div className="main-flex">
						<div className="options">
							{arr.map((item, i) => (
								<div
									key={i}
									className="option"
									onClick={() => setValue(item.value)}
								>
									<div className="left">
										<p className="heading">
											{item.heading}
										</p>
										<p className="desc">{item.desc}</p>
									</div>
									<Radio
										checked={value === item.value}
										onClick={() => setValue(item.value)}
									/>
								</div>
							))}

							<div className="share-profile">
								<AccioButton
									title="Download Profile"
									action={handleDownloadProfile}
								/>
								<AccioButton
									title="Share Profile"
									action={handleShareProfile}
									variant="outline"
									customClass="border-btn"
								/>
							</div>
						</div>
						<Preview value={value} />
					</div>
				</div>
			</Modal>
		</div>
	);
};
