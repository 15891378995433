import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

const ItemList = ({
	items,
	fetchMoreData,
	isLoading,
}: any) => {
	return (
		<>
			{items && items.length > 0 ? (
				<ul className="job-card-applied-list-items">
					<li
						id="job-card-applied-list-item-head"
						className="job-card-applied-list-item job-card-applied-list-item-head"
					>
						<span className="job-card-applied-head1">
							S.No.
						</span>
						<span className="job-card-applied-head2">
							Name
						</span>
						<span className="job-card-applied-head2">
							Stage
						</span>
					</li>

					<InfiniteScroll
						dataLength={items.length}
						next={fetchMoreData}
						hasMore={true}
						loader={''}
						scrollableTarget="job-data-applied-now"
					>
						{items.map((item: any, idx: number) => (
							<li
								key={idx}
								className="job-card-applied-list-item"
							>
								<span className="job-card-applied-text1">
									{idx + 1}
								</span>
								<span className="job-card-applied-text2">{`${item?.user?.firstName} ${item?.user?.lastName}`}</span>
								<span className="job-card-applied-text3">
									{item?.stage}
								</span>
							</li>
						))}
					</InfiniteScroll>
				</ul>
			) : (
				<p className="no-found-text">
					Awaiting update from company, will update the
					status as soon as we hear from company.
				</p>
			)}
			{isLoading && <div>Loading more items...</div>}
		</>
	);
};

export default ItemList;
