import queryString from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { services } from '../../apis';
import Loader from '../../components/Loader';
import { RootState } from '../../redux/store';
import RenderOptions from '../aptitute/components/RenderOptions';
import TabPanel from '../../components/shared/TabPanel';
import { QuestionPanel } from '../aptitute/components/QuestionPanel';
import { nanoid } from 'nanoid';
import { DifficultyTypeEnum } from '../../redux/gym/type';
import {
	BiExitFullscreen,
	BiFullscreen,
	BiLeftArrowAlt,
} from 'react-icons/bi';
import {
	ReflexContainer,
	ReflexElement,
	ReflexSplitter,
} from 'react-reflex';
import AccioButton from '../../components/elements/Button';
import { QnsNavigation } from '../../components/shared/QuestionsNavigation';

export const SubmissionTab: FC<{
	isSubmitted: boolean;
}> = ({ isSubmitted }) => {
	return (
		<div className={'aptitude-submission'}>
			{isSubmitted ? (
				<div className={'user-submission'}>
					<p>
						Your Submission has been recorded successfully !
					</p>
					<p>Please check the correct answer.</p>
				</div>
			) : (
				<div className={'user-not-submitted'}>
					<p>No Submission Available</p>
				</div>
			)}
		</div>
	);
};

const GymAptitudeNew = () => {
	const location = useLocation();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const questionIdFromGym: string = queryString.parse(
		location.search
	).questionId;

	const { assignments } = useSelector(
		(state: RootState) => state.softskills.moduleDetails
	);

	const isFromSoftSkills = queryString.parse(
		location.search
	).isFromSoftSkills;

	const isFromContest = queryString.parse(
		location.search
	).isFromContest;

	const [questionTitle, setQuestionTitle] = useState('');
	const [questionStatement, setQuestionStatement] =
		useState('');
	const [options, setOptions] = useState([]);
	const [questionType, setQuestionType] = useState('');
	const [answerId, setAnswerId] = useState(undefined);
	const [answer, setAnswer] = useState<any>([]);

	const [questionId, setQuestionId] = useState<string>(
		questionIdFromGym
	);
	const [isSubmitted, setIsSubmitted] = useState(false);

	const [correctAnswer, setCorrectAnswer] = useState([]);
	const [explanation, setExplanation] = useState('');

	const [loadAptitudeQuestion, setLoadAptitudeQuestion] =
		useState(false);
	const [score, setScore] = useState(0);
	const [maxScore, setMaxScore] = useState(0);
	const [difficulty, setDifficulty] =
		useState<DifficultyTypeEnum>(DifficultyTypeEnum.EASY);

	const getQuestionDataById = async (
		questionId: string
	) => {
		try {
			const res =
				await services.courseService.getAptitudeQuestionById(
					questionId
				);

			if (res.answer !== null) {
				return {
					answerId: res.answer.id,
					answer: res.answer.answer,
					isSubmitted: res.answer.submitted,
					correctAnswer: res.question.correctAnswer,
					explanation: res.question.explanation,
					solved: res.solved,
					question: res.question,
					difficulty: res.question.difficulty,
				};
			} else {
				return {
					answerId: undefined,
					answer: [],
					isSubmitted: false,
					correctAnswer: res.question.correctAnswer,
					explanation: res.question.explanation,
					solved: false,
					question: res.question,
					difficulty: res.question.difficulty,
				};
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	};

	useEffect(() => {
		const fetchQuestionData = async () => {
			try {
				const questionData = await getQuestionDataById(
					questionId
				);

				setQuestionTitle(questionData.question.title);
				setQuestionStatement(
					questionData.question.statement
				);
				setQuestionType(questionData.question.questionType);
				setOptions(questionData.question.answerOptions);
				setScore(
					questionData.solved
						? questionData.question.score
						: 0
				);
				setMaxScore(questionData.question.score);
				setAnswerId(questionData.answerId);
				setAnswer(questionData.answer);
				setIsSubmitted(questionData.isSubmitted);
				setCorrectAnswer(questionData.correctAnswer);
				setExplanation(questionData.explanation);
				setDifficulty(questionData.difficulty);
			} catch (error) {
				console.error(error);
			} finally {
				setLoadAptitudeQuestion(false);
			}
		};

		setLoadAptitudeQuestion(true);
		fetchQuestionData();
	}, [isSubmitted, questionId]);

	const saveQuestion = async (answerArray: string[]) => {
		const data = {
			conceptualQuestionId: questionId,
			answer: answerArray,
			id: answerId,
		};

		setAnswer(answerArray);

		try {
			const res =
				await services.courseService.saveAptitudeQuestions(
					data
				);

			return res.question;
		} catch (e) {
			console.log(e);
		}
	};

	const submitQuestion = async () => {
		const data = {
			conceptualQuestionId: questionId,
			answer: answer,
			// id: answerId,
			submitted: true,
		};
		setLoadAptitudeQuestion(true);
		try {
			const res =
				await services.courseService.submitAptitudeByQuestionId(
					data
				);

			setIsSubmitted(true);

			setScore(() => (res.data.solved ? maxScore : 0));
		} catch (e) {
			console.log(e);
		}
		setLoadAptitudeQuestion(false);
	};

	const handleNavigate = () => {
		const backToContestURL = localStorage.getItem(
			'backToContestURL'
		);
		if (backToContestURL && isFromContest) {
			window.open(backToContestURL, '_self');
		} else if (isFromSoftSkills) {
			navigate(-1);
		} else {
			navigate('/gym');
		}
	};

	const [tabs, setTabs] = useState<
		{
			key: string;
			title: string;
			element: JSX.Element;
			show: boolean;
		}[]
	>([]);

	const [questionNumber, setQuestionNumber] =
		useState<number>();

	useEffect(() => {
		const temp = [
			{
				key: nanoid(),
				title: 'Question',
				element: (
					<QuestionPanel
						questionStatement={questionStatement}
						difficulty={difficulty}
						score={score}
						questionNumber={questionNumber}
					/>
				),
				show: true,
			},
			{
				key: nanoid(),
				title: 'Submissions',
				element: (
					<SubmissionTab isSubmitted={isSubmitted} />
				),
				show: true,
			},
		];
		setTabs(temp);
	}, [questionStatement, difficulty, score]);

	const [isFullScreen, setIsFullScreen] = useState(false);

	const toggleFullScreen = async () => {
		setIsFullScreen(!isFullScreen);

		if (isFullScreen) await document.exitFullscreen();
		else await document.documentElement.requestFullscreen();
	};

	return (
		<div className={'aptitude-new'}>
			<div className="back-containter">
				<div
					className="left"
					onClick={handleNavigate}
				>
					<BiLeftArrowAlt
						size={22}
						className="icon"
					/>
					<p className="text">Back</p>
				</div>
				<div className="right">
					<div onClick={toggleFullScreen}>
						{isFullScreen ? (
							<BiExitFullscreen
								cursor={'pointer'}
								className="grey-icon"
							/>
						) : (
							<BiFullscreen
								cursor={'pointer'}
								className="grey-icon"
							/>
						)}
					</div>
				</div>
			</div>

			<p className={'meta-info'}>
				Individual multiple select questions answers are
				auto-saved, press save to save answers for
				subjective and integer type questions. Submit all
				questions to get score.
			</p>

			<div className={'aptitude-container'}>
				{isFromSoftSkills && assignments?.length > 0 ? (
					<QnsNavigation
						qnsArray={assignments}
						questionId={questionId}
						setQuestionId={setQuestionId}
						setQuestionNumber={setQuestionNumber}
					/>
				) : null}

				{!loadAptitudeQuestion ? (
					<ReflexContainer
						orientation="vertical"
						className={
							'aptitude-question-panel-web-container'
						}
						windowResizeAware
					>
						<ReflexElement
							className={'vertical-reflex-element'}
							minSize={350}
						>
							<TabPanel tabData={tabs} />
						</ReflexElement>

						<ReflexSplitter style={{ width: '20px' }}>
							<img
								alt="3 dots"
								style={{
									width: '20px',
									height: '100%',
								}}
								src={
									'https://www.svgrepo.com/show/345223/three-dots-vertical.svg'
								}
							/>
						</ReflexSplitter>

						<ReflexElement minSize={350}>
							<div className={'options-panel'}>
								<div className={'header-container'}>
									<div>
										<label></label>
										<div className={'bottom-line'} />
									</div>
								</div>
								<RenderOptions
									options={options}
									saveQuestion={saveQuestion}
									correctAnswer={correctAnswer}
									alreadySubmitted={isSubmitted}
									explanation={explanation}
									answer={answer}
									questionType={questionType}
								/>

								<div className="btn-container">
									<AccioButton
										title={'Submit'}
										className={'submit-btn'}
										action={submitQuestion}
										variant={
											isSubmitted ? 'disable' : 'primary'
										}
										disabled={isSubmitted}
									/>
								</div>
							</div>
						</ReflexElement>
					</ReflexContainer>
				) : (
					<Loader />
				)}
			</div>
		</div>
	);
};

export default GymAptitudeNew;
