import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';

import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const config = {
	apiKey: 'AIzaSyCZq7Okfiwdl9FA3fQDEZrRSF4xFZ7bfIQ',
	authDomain: 'accio-jobs.firebaseapp.com',
	projectId: 'accio-jobs',
	storageBucket: 'accio-jobs.appspot.com',
	messagingSenderId: '1057395112688',
	appId: '1:1057395112688:web:0b4d89e02cd294d8e86035',
	measurementId: 'G-DPYRPWCRJR',
};

export const app = initializeApp(config);
export const firebaseAuth = getAuth(app);
export const storage = getStorage(app);
