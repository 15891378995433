import { Typography } from '@mui/joy';
import { Box, Grid } from '@mui/material';
import React, { FC } from 'react';
import { BsCircle, BsCircleFill } from 'react-icons/bs';
import AccioButton from '../elements/Button';

const StreakData = {
	rules: [
		'Solving Conceptual Questions will not be considered as part of the streak count.',
		'To have questions contribute to the streak count, it is imperative that the User attain a score exceeding 0 in Coding or Web Development question.',
		"In the event that no questions have been solved by the user within a span of 'x' days, the user shall be provided with the opportunity to restore their streak solely on the 'x+1'th day. This can be accomplished by successfully solving '3x' questions on 'x+1'th day, thereby restoring the entire streak. However if no question has been solved for more than 3 days then the streak can not be restored.",
	],
	example: [
		'Streak is 62 on the 10th of August.',
		'Question Solving is missed on the 11th and 12th.',
		'If 6 Questions are solved on the 13th then streak will become 65.',
		'If less than 6 Questions are solved on the 13th then the streak will restart from the 13th and solving 6 questions on the 14th will not restore the streak.',
	],
};

export const HelplineComponent: FC = (props) => {
	const [open, setOpen] = React.useState(false);

	return (
		<Box
			className={`helpline-box ${open ? 'show' : 'hide'}`}
		>
			<h1>
				Important Streak Rules are Changing !!!   (Live from
				the 11th of September)
			</h1>

			<Grid
				container
				className="helpline-grid"
			>
				<Grid
					item
					xs={9}
					md={12}
					lg={12}
					className="help-col-1"
				>
					<ul>
						{StreakData.rules.map(
							(el: any, ind: number) => (
								<li key={ind}>
									<BsCircleFill />
									<Typography className="column-head">
										<p>{el}</p>
									</Typography>
								</li>
							)
						)}
					</ul>
					{/* <Typography className="column-head">
						For immediate assistance contact our direct
						helpline
					</Typography>
					<Typography className="contact-number">
						+91 8178050663
					</Typography>
					<Typography className="timing">
						11 AM to 8 PM (Monday to Saturday)
					</Typography> */}
				</Grid>
				<Typography className={'helpline-text'}>
					Example -
				</Typography>
				<Grid
					item
					xs={9}
					md={12}
					lg={12}
					className="help-col-1"
				>
					<ul>
						{StreakData.example.map(
							(el: any, ind: number) => (
								<li key={ind}>
									<BsCircleFill />
									<Typography className="column-head">
										<p>{el}</p>
									</Typography>
								</li>
							)
						)}
					</ul>
					{/* <Typography className="column-head">
						For immediate assistance contact our direct
						helpline
					</Typography>
					<Typography className="contact-number">
						+91 8178050663
					</Typography>
					<Typography className="timing">
						11 AM to 8 PM (Monday to Saturday)
					</Typography> */}
				</Grid>
				{/* <Grid
					item
					xs={9}
					md={6}
					lg={6}
					className="help-col-2"
				>
					<Typography className="column-head">
						Queries can also be shared in the chat section
						of the portal.
					</Typography>
					<Typography className="timing">
						11 AM to 8 PM (Monday to Saturday)
					</Typography>
				</Grid> */}
			</Grid>
			<Box className="read-more">
				<AccioButton
					action={() => setOpen(!open)}
					title={open ? 'Show less' : 'Read More'}
				/>
			</Box>
		</Box>
	);
};
