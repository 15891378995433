import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchEligibleSessionTypes } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';

function SessionTypeTable() {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { eligibleSessionTypes } = MentorState;

	const [rowsData, setRowsData] = useState<any>(null);
	const [mentoreligibleSessions, setEligibleSession] =
		useState<any>(null);

	useEffect(() => {
		if (eligibleSessionTypes === null)
			dispatch(fetchEligibleSessionTypes());
		else setEligibleSession(eligibleSessionTypes);
	}, [eligibleSessionTypes]);

	useEffect(() => {
		if (mentoreligibleSessions !== null) {
			let rows: any = [];
			mentoreligibleSessions.forEach((session: any) => {
				const { sessionType } = session;
				rows = [...rows, { sessionType }];
			});
			setRowsData([...rows]);
		}
	}, [mentoreligibleSessions]);

	return (
		<div>
			<TableContainer component={Paper}>
				<Table aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell align="left">
								Session Type
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rowsData &&
							rowsData.map((row: any) => (
								<TableRow
									key={row.startTime}
									sx={{
										'&:last-child td, &:last-child th': {
											border: 0,
										},
									}}
								>
									<TableCell align="left">
										{row.sessionType}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default SessionTypeTable;
