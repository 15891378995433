import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export const fetchModuleQuestions = createAsyncThunk(
	'assignments/fetchQuestions',
	async (moduleId: string, { getState }) => {
		// console.log(arg)
		const data =
			await services.courseService.getFullCourseQuestionsForModule(
				moduleId
			);
		console.log('fetchQuestions', moduleId, data);
		return data;
	}
);

export const fetchAllModules = createAsyncThunk(
	'assignments/fetchAll',
	async (courseID) => {
		const data =
			await services.courseService.getCourseAssignments();
		console.log('fetchAllModules', data);
		data.courseID = courseID;
		return data;
	}
);

export const fetchAptitudeQuestions = createAsyncThunk(
	'assignments/fetchAptitudeQuestions',
	async (moduleId: string) => {
		// console.log(arg)
		const data =
			await services.courseService.getAllAptitudeQuestionsForModule(
				moduleId
			);
		console.log('fetchQuestions', moduleId, data);
		return data;
	}
);

export const submitAptitudeQuestions = createAsyncThunk(
	'assignments/submitAptitudeQuestions',
	async (moduleId: string) => {
		// console.log(arg)

		const moduleData = { moduleId: moduleId };

		const data =
			await services.courseService.submitAptitudeByModuleId(
				moduleData
			);
		console.log('submitAptitudeQuestions', moduleId, data);
		return data;
	}
);

interface ModuleState {
	allModules: any;
	modulesData: any;
	allModulesData: any;
	moduleActive: any;
	currentModuleId: string | null;
	aptitudeQuestions: any[];
	loadingAptitude: boolean;
	submitAptitudeLoading: boolean;
	switching: boolean;
	alreadySubmitted: boolean;
	// extra
	moduleListloading: boolean;
	moduleloading: boolean;
	aptitudeLoading: boolean;
	aptitudeScore: number;
	error: {
		state: boolean;
		message: string;
	};
}

const initialState: ModuleState = {
	allModules: {},
	modulesData: {},
	moduleActive: null,
	currentModuleId: null,
	allModulesData: null,
	aptitudeQuestions: [],
	loadingAptitude: false,
	submitAptitudeLoading: false,
	switching: false,
	alreadySubmitted: false,
	// extra
	moduleListloading: false,
	moduleloading: false,
	aptitudeLoading: true,
	aptitudeScore: 0,
	error: {
		state: false,
		message: '',
	},
};

export const moduleSlice = createSlice({
	name: 'assignment',
	initialState,
	reducers: {
		handleSwitch: (state, action) => {
			state.moduleListloading = true;
			state.error.state = false;
			state.error.message = '';
			state.modulesData = {};
			state.allModulesData = null;
		},
		updateModules(state, action) {
			state.moduleListloading = false;
			state.allModulesData =
				state.allModules[action.payload];
			state.error.state = false;
			state.error.message = '';
		},
		selectActiveModule(state, action) {
			console.log(
				'current module is selecting',
				action.payload
			);
			state.moduleActive = action.payload;
		},
		changeCurrentModule(state, action) {
			console.log('action', action.payload);
			state.currentModuleId = action.payload;
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchModuleQuestions.pending]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.moduleloading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchModuleQuestions.fulfilled]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.moduleloading = false;
			state.modulesData[action.payload.module.id] =
				action.payload;
			state.moduleActive = action.payload.module;
			state.error.state = false;
			state.error.message = '';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchModuleQuestions.rejected]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.moduleloading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAptitudeQuestions.pending]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.aptitudeLoading = true;
			state.aptitudeQuestions = [];
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAptitudeQuestions.fulfilled]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			console.log('aptitude', action.payload);

			state.aptitudeLoading = false;
			state.aptitudeQuestions = action.payload.questions;
			state.alreadySubmitted = action.payload.submitted;
			state.aptitudeScore = action.payload.totalScore;

			state.error.state = false;
			state.error.message = '';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAptitudeQuestions.rejected]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.aptitudeLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllModules.pending]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.moduleListloading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllModules.fulfilled]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.moduleListloading = false;
			state.allModulesData = action.payload;
			state.error.state = false;
			state.error.message = '';
			const temp = { ...action.payload };
			if (
				state.allModules[action.payload.courseID] ===
				undefined
			) {
				state.allModules[action.payload.courseID] = temp;
			}
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllModules.rejected]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.moduleListloading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[submitAptitudeQuestions.pending]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.submitAptitudeLoading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[submitAptitudeQuestions.fulfilled]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			console.log('Payload', action.payload);
			state.submitAptitudeLoading = false;
			state.aptitudeScore = action.payload.totalScore;
			state.alreadySubmitted = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[submitAptitudeQuestions.rejected]: (
			state: ModuleState,
			action: PayloadAction<any>
		) => {
			state.submitAptitudeLoading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
	},
});

//Exporting Actions
export const {
	handleSwitch,
	updateModules,
	selectActiveModule,
	changeCurrentModule,
} = moduleSlice.actions;

export default moduleSlice.reducer;
