export const nextElementOfArray = (
	array: any[],
	currentKey: string
) => {
	const currentIndex = array.indexOf(currentKey);
	const nextIndex = (currentIndex + 1) % array.length;
	return array[nextIndex];
};

export const prevElementOfArray = (
	array: any[],
	currentKey: string
) => {
	const currentIndex = array.indexOf(currentKey);
	const nextIndex = (currentIndex - 1) % array.length;
	return array[nextIndex];
};
