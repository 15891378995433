import React, { useState, useEffect } from 'react';
import InitialProfile from './InitialProfile';
import PlacementProfile from './PlacementProfile';
import { useSelector } from 'react-redux';
import { TbLock } from 'react-icons/tb';
const Profile = () => {
	// tab state
	const [activeTab, setActiveTab] = useState(1);

	// general form state
	const initialGeneralFormState = {};

	// placement form state
	const initialPlacementFormState = {};
	const UserState = useSelector((state: any) => state.user);
	const { profile, user } = UserState;
	const [isProfileLocked, setIsProfileLocked] =
		useState(false);
	useEffect(() => {
		if (user != null)
			setIsProfileLocked(user.isProfileLocked);
	}, [user]);

	return (
		<div className="profile-page">
			<h1>
				Your <span>Profile</span>
			</h1>
			{isProfileLocked ? (
				<div
					style={{ display: 'flex', flexDirection: 'row' }}
				>
					<div
						style={{
							alignItems: 'center',
							marginTop: '1.8px',
						}}
					>
						<TbLock />
					</div>
					<div style={{ alignItems: 'center' }}>
						<p style={{ color: 'grey' }}>
							Your profile is locked . Please ask your batch
							manager to unlock it.
						</p>
					</div>
				</div>
			) : null}

			<div className="tab-container">
				<div className="tabs">
					<button
						className={activeTab === 1 ? 'active' : ''}
						onClick={() => {
							setActiveTab(1);
						}}
					>
						<span>1</span>Personal & Education Info
					</button>
					<button
						className={activeTab === 2 ? 'active' : ''}
						onClick={() => {
							setActiveTab(2);
						}}
					>
						<span>2</span>Professional Info
					</button>
				</div>

				<div className="tab-content">
					{activeTab === 1 && <InitialProfile />}
					{activeTab === 2 && <PlacementProfile />}
				</div>
			</div>
		</div>
	);
};

export default Profile;
