import {
	useRef,
	useState,
	type FC,
	useEffect,
} from 'react';
import { BsFillClipboard2CheckFill } from 'react-icons/bs';
import { FaChevronDown } from 'react-icons/fa';
import { useOnClickOutside } from '../../customHooks/useOnClickOutside';
import { DifficultyTypeEnum } from '../../redux/gym/type';
import { ProgressBar } from '../shared/ProgressBarNew';
import Heatmap, { HeatmapPropsType } from './HeatMap';
import { BiInfoCircle } from 'react-icons/bi';
import { streakNumber } from '../EffortScore';

type PerformanceReviewPropsType = {
	header: {
		streakCount: number;
		questionSolvedCount: number;
		restoreStreakNumber: number;
	};
	heatMap: HeatmapPropsType['data'];
	questionAnalysis: Array<{
		difficulty: string;
		percentage: string;
		solved: string;
		total: string;
	}>;
};

export const PerformanceReview: FC<
	PerformanceReviewPropsType
> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	useOnClickOutside(containerRef, () => {
		setIsOpen(false);
	});

	return (
		<div
			className="performance-review-container"
			ref={containerRef}
		>
			<PerformanceReviewHeader
				data={props.header}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
			/>

			<PerformanceReviewStatistics
				data={props}
				isOpen={isOpen}
			/>
		</div>
	);
};

const PerformanceReviewHeader: FC<
	{ data: PerformanceReviewPropsType['header'] } & {
		isOpen: boolean;
		setIsOpen: (isOpen: boolean) => void;
	}
> = (props) => {
	const [streakData, setStreakData] = useState<{
		streak: number;
		message: string | null;
	} | null>(null);
	useEffect(() => {
		console.log(
			'restoreStreakNumber',
			props.data.streakCount,
			props.data.restoreStreakNumber
		);
		setStreakData(
			streakNumber(
				props.data.streakCount,
				props.data.restoreStreakNumber
			)
		);
	}, [props.data]);
	return (
		<div
			className="performance-review-header"
			onClick={() => props.setIsOpen(!props.isOpen)}
		>
			<div>
				<div>Performance Review</div>
				{!props.isOpen ? (
					<>
						<div className="info-chip">
							<span>Day Learning Streak: </span>
							<span className="icon-small icon_fire">
								🔥
							</span>
							<span>{props.data.streakCount}</span>
							<p className={'info'}>
								<BiInfoCircle />
								{streakData?.message ? (
									<p className="streak-message">
										{streakData?.message}
									</p>
								) : null}
							</p>
						</div>
						<div className="info-chip">
							<span>Question Solved: </span>
							<span className="icon-small">
								<BsFillClipboard2CheckFill />
							</span>
							<span>{props.data.questionSolvedCount}</span>
						</div>
					</>
				) : null}
			</div>
			<div className="toggle-button">
				{props.isOpen ? 'Close' : 'Open'} Statistics{' '}
				<div
					style={{
						transform: props.isOpen
							? 'rotate(180deg)'
							: 'rotate(0deg)',
						transition: 'transform 0.3s ease-in-out',
					}}
				>
					<FaChevronDown />
				</div>
			</div>
		</div>
	);
};

const PerformanceReviewStatistics: FC<{
	data: PerformanceReviewPropsType;
	isOpen: boolean;
}> = (props) => {
	const [streakData, setStreakData] = useState<{
		streak: number;
		message: string | null;
	} | null>(null);
	useEffect(() => {
		setStreakData(
			streakNumber(
				props.data.header.streakCount,
				props.data.header.restoreStreakNumber
			)
		);
	}, [props]);
	return (
		<div
			className="performance-review-statistics-container"
			style={{
				opacity: props.isOpen ? '1' : '0',
				maxHeight: props.isOpen ? '50vh' : '0vh',
				transition: 'all 0.5s ease-in-out',
				overflow: 'hidden',
			}}
		>
			<div className="performance-review-statistics">
				<div className="performance-review-statistics-question-analysis">
					<PerformanceReviewQuestionAnalysis
						data={props.data.questionAnalysis}
					/>
				</div>
				<div className="performance-review-statistics-overall">
					<div className="info-chip">
						<div>
							<span className="icon icon_fire">🔥</span>
							<span>{props.data.header.streakCount}</span>

							<p className={'info'}>
								<BiInfoCircle />
								{streakData?.message ? (
									<p className="streak-message">
										{streakData?.message}
									</p>
								) : null}
							</p>
						</div>
						<span>Day Learning Streak </span>
					</div>
					<div className="info-chip">
						<div>
							<span className="icon ">
								<BsFillClipboard2CheckFill />
							</span>
							<span>
								{props.data.header.questionSolvedCount}
							</span>
						</div>
						<span>Question Solved </span>
					</div>
				</div>
				<div className="performance-review-statistics-heatmap">
					<Heatmap data={props.data.heatMap} />
				</div>
			</div>
		</div>
	);
};

const PerformanceReviewQuestionAnalysis: FC<{
	data: PerformanceReviewPropsType['questionAnalysis'];
}> = (props) => {
	return (
		<>
			<div className="performance-review-statistics-question-analysis-header">
				<div>Question Analysis</div>
			</div>
			{props.data.map((ele: any, index: number) => {
				const {
					backgroundColor,
					borderColor,
					progressColor,
				} = getProgressBarColorsBasedOnDifficulty(
					ele.difficulty
				);
				return (
					<div
						className="performance-review-statistics-question-analysis-progress"
						key={ele.percentage}
					>
						<div className="performance-review-statistics-question-analysis-progress-title">
							<span className="performance-review-difficulty">
								{ele.difficulty}
							</span>
							<span className="performance-review-questions-data">
								{`${ele.solved}/${ele.total} Solved`}
							</span>
						</div>
						<div className="performance-review-progressbar-track">
							<ProgressBar
								borderColor={borderColor}
								progressColor={progressColor}
								backgroundColor={backgroundColor}
								percentage={`${ele.percentage}%`}
							/>
						</div>
					</div>
				);
			})}
		</>
	);
};

const getProgressBarColorsBasedOnDifficulty = (
	difficulty: DifficultyTypeEnum
) => {
	if (difficulty === DifficultyTypeEnum.EASY) {
		return {
			progressColor: '#12B76A',
			backgroundColor: 'rgba(227, 255, 237, 0.5)',
			borderColor: 'rgba(18, 183, 106, 0.2)',
		};
	}
	if (difficulty === DifficultyTypeEnum.MEDIUM) {
		return {
			progressColor: '#F0B416',
			backgroundColor: 'rgba(255, 232, 173, 0.5)',
			borderColor: 'rgba(255, 207, 85, 0.5)',
		};
	}
	if (difficulty == DifficultyTypeEnum.HARD) {
		return {
			progressColor: '#EF574C',
			backgroundColor: 'rgba(255, 223, 220, 0.5)',
			borderColor: 'rgba(239, 87, 76, 0.2)',
		};
	}
	return {
		progressColor: '#12B76A',
		backgroundColor: 'rgba(227, 255, 237, 0.5)',
		borderColor: 'rgba(18, 183, 106, 0.2)',
	};
};
