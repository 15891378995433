import { type FC } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

type MarkdownPropsType = {
	children: string;
};

export const MarkDown: FC<MarkdownPropsType> = ({
	children,
}) => {
	return (
		<ReactMarkdown
			className={'markdown-body'}
			remarkPlugins={[remarkGfm]}
			rehypePlugins={[rehypeHighlight, rehypeRaw]}
		>
			{children}
		</ReactMarkdown>
	);
};
