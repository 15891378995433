import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class WebDevQuestionService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetch(questionId: string) {
		const url = `${this.endpoint}/github/questions/${questionId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async startAssignment(questionId: string, code?: string) {
		const url = `${this.endpoint}/github/accept-assignment`;
		const reqBody = {
			id: questionId,
			code: code || '',
		};
		console.log('req Body', reqBody);
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: reqBody,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
