import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CryptoJS from 'crypto-js';
import moment from 'moment';

export const fetchAllContests = createAsyncThunk(
	'contest/fetchAllContests',
	async () => {
		try {
			const res =
				await services.contestService.fetchAllContest();
			console.log('ALL CONTESTS RESPONSE', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchContests = createAsyncThunk(
	'contest/fetchContests',
	async (contestId: string) => {
		try {
			const res =
				await services.contestService.fetchContest({
					contestId: contestId,
				});
			console.log('CONTEST SLICE', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const submitContest = createAsyncThunk(
	'contest/submitContest',
	async (contestId: string) => {
		try {
			const res =
				await services.contestService.submitContest({
					contestId: contestId,
				});
			console.log('CONTEST SUBMITTED', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchQuestions = createAsyncThunk(
	'contest/questions-after-contest',
	async (contestId: any) => {
		// const testAttemptId = await localStorage.getItem('testAttemptId');
		// const hashedTestAttemptId = await localStorage.getItem(
		// 	'hashedTestAttemptId'
		// );
		console.log('Question Body', contestId);
		const data =
			await services.contestService.fetchQuestions({
				contestId: contestId,
				// contestHash: body.contestHash,
				// testAttemptId: testAttemptId,
				// hashedTestAttemptId: hashedTestAttemptId,
			});
		console.log('CONTEST QUESTION', data);
		return data.data;
	}
);

export const fetchProctorInfo = createAsyncThunk(
	'contest/fetchProctorInfo',
	async (contestId: string) => {
		const data =
			await services.contestService.fetchProctingInformation(
				{
					contestId: contestId,
				}
			);
		console.log('fetchProtorInfo', data);
		return data;
	}
);

export const fetchLeaderboard = createAsyncThunk(
	'contest/fetchLeaderboard',
	async (contestId, { getState }) => {
		const state: any = getState();
		const { courseId } = state.contest;
		console.log('old leaderboad', courseId, contestId);
		return await services.courseService.getContestLeaderBoard(
			{
				contestId,
				courseId,
			}
		);
	}
);
export const fetchContestsLeaderboard = createAsyncThunk(
	'contest/fetchNextContestLeaderboard',
	async (data: any) => {
		try {
			const res =
				await services.contestService.fetchContestLeaderboard(
					data
				);
			console.log(
				'CONTESTS LEADERBOARD RESPONSE NEXT',
				res
			);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);
export const fetchTopContestsLeaderboard = createAsyncThunk(
	'contest/fetchAllContestLeaderboard',
	async (contestId: string) => {
		try {
			const contestDetails = {
				contestId: contestId,
			};
			const res =
				await services.contestService.fetchTopContestLeaderboard(
					contestDetails
				);
			console.log('CONTESTS LEADERBOARD RESPONSE', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

interface ContestState {
	contestId: string | null;
	allContest: any[] | null;
	upcomingContest: any[] | null;
	codingQuestions: any[];
	currentContest: any;
	webDevQuestions: any[];
	conceptualQuestions: any[];
	fileUploadedDownloadQuestions: any[];
	totalCodingQuestions: any[];
	totalWebDevQuestions: any[];
	totalConceptualQuestions: any[];
	totalFileUploadedDownloadQuestions: any[];
	allQuestions: any[];
	totalQuestion: number | null;
	solvedQuestion: number | null;
	contestStarted: boolean;
	contestEnded: boolean;
	testAttemptId: string | null;
	hashedTestAttemptId: string | null;
	contestData: any;
	questionData: any;
	isAttempted: boolean;
	isContestLive: boolean;
	timeElapsed: number;
	contestName: string | null;
	totalAttempts: number;
	contestHash: string;
	contestSelected: boolean;
	eligibility: boolean;
	ongoing: any;
	upcoming: any;
	past: any;
	missed: any;
	live: any;
	openContest: any;
	questionsStatusMap: any;
	instructions: any;
	duration: any;
	startTime: any;
	endTime: any;
	allContestLeaderboard: any[];
	contestLeaderboard: any[];
	allContestLeaderboardLoading: boolean;
	nextContestLeaderboard: any[];
	nextContestLeaderboardLoading: boolean;
	currentUser: any;
	type: string | null;
	flag: string;
	attemptStartTime: any;
	message: string;

	// extra
	loading: boolean;
	error: {
		state: boolean;
		message: string;
	};
}

const initialState: ContestState = {
	contestId: null,
	allContest: [],
	currentContest: null,
	upcomingContest: null,
	contestData: null,
	totalQuestion: 0,
	solvedQuestion: 0,
	codingQuestions: [],
	webDevQuestions: [],
	conceptualQuestions: [],
	fileUploadedDownloadQuestions: [],
	totalCodingQuestions: [],
	totalWebDevQuestions: [],
	totalConceptualQuestions: [],
	totalFileUploadedDownloadQuestions: [],
	allQuestions: [],
	contestEnded: false,
	contestStarted: false,
	testAttemptId: null,
	hashedTestAttemptId: null,
	questionData: null,
	isAttempted: false,
	isContestLive: true,
	timeElapsed: 0,
	contestName: null,
	instructions: '',
	duration: '',
	startTime: '',
	endTime: '',
	totalAttempts: 0,
	contestHash: CryptoJS.lib.WordArray.random(6).toString(),
	contestSelected: false,
	eligibility: true,
	questionsStatusMap: {},
	ongoing: [],
	upcoming: [],
	past: [],
	missed: [],
	live: [],
	openContest: [],
	contestLeaderboard: [],
	allContestLeaderboard: [],
	allContestLeaderboardLoading: true,
	nextContestLeaderboard: [],
	nextContestLeaderboardLoading: true,
	currentUser: null,
	type: null,
	flag: '',
	attemptStartTime: '',
	message: '',
	// extra
	loading: false,
	error: {
		state: false,
		message: '',
	},
};

// function isEmpty(obj: any) {
// 	for (let prop in obj) {
// 		if (obj.hasOwnProperty(prop)) return false;
// 	}
// 	return true;
// }

function isEmpty(obj: any) {
	for (const prop in obj) {
		if (Object.prototype.hasOwnProperty.call(obj, prop))
			return false;
	}
	return true;
}

export const contestSlice = createSlice({
	name: 'contest',
	initialState,
	reducers: {
		startContest(state) {
			state.contestStarted = true;
			state.contestEnded = false;
		},
		endContest(state) {
			state.contestEnded = true;
			state.contestStarted = false;
		},
		setContestId(state, action) {
			console.log('action.payload', action.payload);
			state.contestId = action.payload;
			console.log('state.contestId', state.contestId);
		},
		setContestSelected(state, action) {
			state.contestSelected = action.payload;
		},
		resetContestData(state) {
			state.contestData = null;
		},
		updateQuestionsStatusMap(state, action) {
			state.questionsStatusMap[action.payload.id] =
				action.payload;
		},
		resetContestLeaderboard(state) {
			state.contestLeaderboard = [];
		},
	},
	extraReducers: {
		[fetchAllContests.pending.toString()]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
			state.error.state = false;
			state.error.message = '';
			state.ongoing = [];
			state.upcoming = [];
			state.past = [];
			state.live = [];
		},

		[fetchAllContests.fulfilled as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			// console.log("action.payload.courseContestMapping",action.payload.courseContestMapping)

			const all = [
				...action.payload.ongoing,
				...action.payload.live,
				...action.payload.upcoming,
				...action.payload.past,
				...action.payload.openContest,
			];
			let live = action.payload.live.filter((e: any) => {
				const playgroundCourseIds = [
					'c0c4fb25-14dc-4f5b-b45d-d34fb2d6ed29',
					'eaf14d47-7c70-427d-b959-f43b91ce9b70',
					'9044fc93-25d0-42aa-b9ba-802e507eea74',
					'af0fbd53-0cfe-4599-bd5c-8b8dc989ac18',
				];
				return (
					e.flag === 'live-not-given' &&
					!(e.blackListed === true) &&
					!playgroundCourseIds.includes(e.courseId)
				);
			});
			const ongoing = action.payload.ongoing.filter(
				(e: any) => !(e.blackListed === true)
			);
			const upcoming = action.payload.upcoming.filter(
				(e: any) => !(e.blackListed === true)
			);
			const openContest = action.payload.openContest.filter(
				(e: any) => !(e.blackListed === true)
			);
			let contestsForToday: any[] = [];
			let latestContestDate: string = moment(
				new Date()
			).format('DD MMM YYYY');

			const filterContestForToday = (
				arr: any[],
				latestContestDate: string
			) => {
				const temp = arr.filter((el: any) => {
					// const curr = moment(new Date()).format("DD MMM YYYY");
					const contestDate = moment(el.startTime).format(
						'DD MMM YYYY'
					);
					console.log(
						'aaj ki date',
						latestContestDate,
						contestDate
					);
					return latestContestDate === contestDate;
				});
				return temp;
			};

			if (ongoing.length > 0) {
				latestContestDate = moment(
					ongoing[0].startTime
				).format('DD MMM YYYY');
				const temp = filterContestForToday(
					ongoing,
					latestContestDate
				);
				contestsForToday = [...contestsForToday, ...temp];
				console.log('aaj ke contest', temp);
			} else if (live.length > 0) {
				// state.currentContest = live[0];
				latestContestDate = moment(
					live[0].startTime
				).format('DD MMM YYYY');
				const temp = filterContestForToday(
					live,
					latestContestDate
				);
				contestsForToday = [...contestsForToday, ...temp];
				console.log('aaj ke contest', temp);
			} else if (upcoming.length > 0) {
				// state.currentContest= upcoming[0];
				latestContestDate = moment(
					upcoming[0].startTime
				).format('DD MMM YYYY');
				const temp = filterContestForToday(
					upcoming,
					latestContestDate
				);
				contestsForToday = [...contestsForToday, ...temp];
				console.log('aaj ke contest', temp);
			}
			state.currentContest = contestsForToday;
			state.loading = false;
			state.allContest = all;
			state.upcoming = action.payload.upcoming;
			state.past = action.payload.past;
			state.openContest = action.payload.openContest;
			if (action.payload.ongoing !== null) {
				state.ongoing = action.payload.ongoing;
			} else {
				state.ongoing = null;
			}
			state.live = action.payload.live;
			console.log('LIVE', action.payload.live);
			// state.missed = action.payload.missedContests;
			// if (Object.keys(action.payload.ongoingContest).length > 0) {
			// 	state.ongoing = action.payload.ongoingContest;
			// }

			// const allcontests = all.sort(function (a: any, b: any) {
			// 	return new Date(a.startTime) < new Date(b.startTime);
			// });
			const temp = action.payload.upcoming.filter(
				(el: any) => {
					return new Date(el.endTime) > new Date();
				}
			);
			console.log('CONTEST TEMP', temp);
			const upcomingContest = temp.sort(function (
				a: any,
				b: any
			) {
				return (
					new Date(a.startTime) < new Date(b.startTime)
				);
			});

			state.upcomingContest =
				upcomingContest.length > 0
					? upcomingContest[0]
					: null;
		},

		[fetchAllContests.rejected as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Some Error Occurred';
		},

		[fetchContests.pending as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			console.log(
				'FETCH CONTEST API PENDING',
				action.payload
			);
			state.loading = true;
			state.contestData = null;
			state.eligibility = false;
			state.contestName = null;

			state.error.state = false;
			state.error.message = '';
			state.instructions = null;
			state.duration = '';
			state.isAttempted = false;
			state.startTime = '';
			state.endTime = '';
			state.flag = '';
			state.isContestLive = true;
			state.codingQuestions = [];
			state.webDevQuestions = [];
		},

		[fetchContests.fulfilled as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			if (action.payload.contest !== null) {
				state.eligibility = action.payload.eligibility;
				state.contestData = action.payload.contest;
				state.contestName = action.payload.contest.name;
				state.contestId = action.payload.contest.contestId;
				state.instructions =
					action.payload.contest.instructions;
				state.duration = action.payload.contest.duration;
				state.startTime = action.payload.contest.startTime;
				state.endTime = action.payload.contest.endTime;
				state.isAttempted =
					action.payload.attemptStatus.isAttempted;
				state.isContestLive =
					action.payload.attemptStatus.isContestLive;
				state.contestEnded =
					moment(action.payload.contest.endTime) < moment();
				state.contestStarted =
					moment(action.payload.contest.startTime) <
					moment();
				state.flag = action.payload.contest.flag;
				state.attemptStartTime =
					action.payload.contest.attemptStartTime;
			} else {
				state.contestName = null;
				state.contestId = null;
				state.instructions = null;
				state.duration = '';
				state.isAttempted = false;
				state.isContestLive = false;
				state.startTime = '';
				state.endTime = '';
			}
			state.error.state = false;
			state.error.message = '';
			state.codingQuestions = [];
			state.webDevQuestions = [];
		},

		[fetchContests.rejected as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			console.log('FETCH CONTEST API REJECTED', action);
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Some Error Occurred';
		},

		[fetchQuestions.pending as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
			state.webDevQuestions = [];
			state.codingQuestions = [];
			state.allQuestions = [];
		},

		[fetchQuestions.fulfilled as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			if (
				action.payload !== null &&
				!action.payload.message
			) {
				state.loading = false;
				state.questionData = action.payload;
				state.codingQuestions =
					action.payload.codingQuestions !== null
						? action.payload.codingQuestions
						: [];
				state.webDevQuestions =
					action.payload.webDevQuestions !== null
						? action.payload.webDevQuestions
						: [];
				state.conceptualQuestions =
					action.payload.conceptualQuestions !== null
						? action.payload.conceptualQuestions
						: [];
				state.fileUploadedDownloadQuestions =
					action.payload.fileUploadedDownloadQuestions !==
					null
						? action.payload.fileUploadedDownloadQuestions
						: [];
				state.totalCodingQuestions =
					action.payload.totalCodingQuestions !== null
						? action.payload.totalCodingQuestions
						: [];
				state.totalWebDevQuestions =
					action.payload.totalWebDevQuestions !== null
						? action.payload.totalWebDevQuestions
						: [];
				state.totalConceptualQuestions =
					action.payload.totalConceptualQuestions !== null
						? action.payload.totalConceptualQuestions
						: [];
				state.totalFileUploadedDownloadQuestions =
					action.payload
						.totalFileUploadedDownloadQuestions !== null
						? action.payload
								.totalFileUploadedDownloadQuestions
						: [];
				console.log(
					'gbfhfd',
					action.payload.totalFileUploadedDownloadQuestions
				);
				state.allQuestions =
					action.payload.totalCodingQuestions;
				// state.totalQuestion = action.payload.codingQuestions.length;
				state.timeElapsed = action.payload.timeElapsed;
			} else {
				state.loading = false;
				state.questionData = null;
				state.codingQuestions = [];
				state.webDevQuestions = [];
				state.allQuestions = [];
				state.totalQuestion = 0;
				state.timeElapsed = 0;
				state.message = action.payload.message;
			}
			state.error.state = false;
			state.error.message = '';
		},

		[fetchQuestions.rejected as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Some Error Occurred';
		},

		[fetchContestsLeaderboard.pending as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		[fetchContestsLeaderboard.fulfilled as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			console.log(
				'LEADERBOARD',
				action.payload.leaderboard
			);
			state.loading = false;
			if (action.payload.leaderboard !== undefined) {
				if (state.contestLeaderboard.length === 0)
					state.contestLeaderboard =
						action.payload.leaderboard;
				else {
					console.log(
						'action payload leaderboard',
						action.payload.leaderboard
					);
					console.log('action payload old leaderboard', [
						...state.contestLeaderboard,
					]);
					if (action.payload.leaderboard.length !== 0) {
						const temp = [
							...state.contestLeaderboard,
							...action.payload.leaderboard,
						];
						console.log('temp', temp);
						state.contestLeaderboard = temp;
						console.log('LEADERBOARD', temp);
					}
				}
			}
		},

		[fetchContestsLeaderboard.rejected as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Some Error Occurred';
		},

		[fetchTopContestsLeaderboard.pending as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		[fetchTopContestsLeaderboard.fulfilled as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			console.log('bug', action.payload);
			if (action.payload.leaderboard !== undefined) {
				// state.contestLeaderboard = action.payload.leaderboard;
				if (!isEmpty(action.payload.currentUser))
					state.currentUser = action.payload.currentUser;
				console.log(
					'LEADERBOARD',
					action.payload.leaderboard
				);
			}
		},

		[fetchTopContestsLeaderboard.rejected as any]: (
			state: ContestState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Some Error Occurred';
		},
	},
});

//Exporting Actions
export const {
	startContest,
	endContest,
	setContestId,
	setContestSelected,
	resetContestData,
	updateQuestionsStatusMap,
	resetContestLeaderboard,
} = contestSlice.actions;
export default contestSlice.reducer;
