import { RiBubbleChartFill } from 'react-icons/ri';
import Heatmap from '../../gym/HeatMap';
import { useAppSelector } from '../../../redux/store';

export const PerformanceHeatmap = () => {
	const { heatMapData, isLoading } = useAppSelector(
		(state) => state.gym
	);
	if (heatMapData)
		return (
			<div className="profile-card heatmap-card">
				<div className="headings-flex">
					<RiBubbleChartFill
						style={{ color: '#E87F4F', fontSize: '1.4rem' }}
					/>
					<div className="heading">
						Performance Heatmap
						{/* <p className="random-text">
							Lorem ipsum dolor sit amet consectetur.
						</p> */}
					</div>
				</div>
				<Heatmap
					data={heatMapData}
					legend={false}
				/>
			</div>
		);
	else {
		return null;
	}
};
