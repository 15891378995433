import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../apis';
import {
	AcademicDetailsInterface,
	ExperienceDetailsInterface,
	PersonalDetailsInterface,
} from './profile.type';
import { Experience } from '../../components/studentProfile/Modals/EditProfile/Forms/WorkExperience';

export const fetchContestData = createAsyncThunk(
	'profile/fetchContestData',
	async () => services.profileService.getContestData()
);

export const fetchPerformanceUpdates = createAsyncThunk(
	'profile/fetchPerformanceUpdates',
	async () =>
		services.profileService.getPerformanceUpdates()
);

export const fetchPersonalDetails = createAsyncThunk(
	'profile/fetchPersonalDetails',
	async () => services.profileService.getPersonalDetails()
);

export const updatePersonalDetails = createAsyncThunk(
	'profile/updatePersonalDetails',
	async ({ data }: { data: PersonalDetailsInterface }) =>
		services.profileService.postPersonalDetails({
			body: data,
		})
);

export const fetchAcademicDetails = createAsyncThunk(
	'profile/fetchAcademicDetails',
	async () => services.profileService.getAcademicDetails()
);

export const updateAcademicDetails = createAsyncThunk(
	'profile/updateAcademicDetails',
	async ({ data }: { data: AcademicDetailsInterface }) =>
		services.profileService.postAcademicDetails({
			body: data,
		})
);

export const fetchExperienceDetails = createAsyncThunk(
	'profile/fetchExperienceDetails',
	async () => services.profileService.getExperienceDetails()
);

export const updateExperienceDetails = createAsyncThunk(
	'profile/updateExperienceDetails',
	async ({
		workExp,
		currentCtc,
	}: {
		workExp: Array<Experience>;
		currentCtc: number;
	}) =>
		services.profileService.postExperienceDetails({
			workExp,
			currentCtc,
		})
);

export const deleteWorkExperience = createAsyncThunk(
	'profile/deleteWorkExperience',
	async ({ id }: { id: string }) =>
		services.profileService.deleteExperience({
			id,
		})
);
