import React from 'react';
import { FaPhone } from 'react-icons/fa';

const DisplayPlaceMentSupport = () => {
	return (
		<div className="quarry-banner">
			<div className="banner-content">
				<div className="phone-wrapper">
					<div className="phone-icon-wrapper">
						<FaPhone className="phone-icon" />
					</div>
					<div className="phone-number-wrapper">
						<a
							href="tel:+91-8595944288"
							className="phone-number"
						>
							<span className="number-prefix">
								Call Us :
							</span>
							<span className="actual-number">
								+91-8595944288
							</span>
						</a>
					</div>
				</div>
				<div className="banner-text">
					Need help? Call our placement support team.{' '}
					<span className="time-available">
						Time: 11:00AM-07:00PM(Monday-Saturday){' '}
					</span>
				</div>
			</div>
		</div>
	);
};

export default DisplayPlaceMentSupport;
