import { Chip } from '@mui/material';
import React from 'react';

const ProfileBlock = ({ label, children }: any) => {
	return (
		<div className="profile-block">
			<span className="profile-block__label">{label}</span>
			{children}
		</div>
	);
};

export default ProfileBlock;
