import { CircularProgress } from '@mui/material';
import VimeoPlayer from '@vimeo/player';
import {
	CSSProperties,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { services } from '../../apis';
import { convertByteToMB } from '../../utils/bytes';

const eventNames: Record<string, string> = {
	play: 'onPlay',
	playing: 'onPlaying',
	pause: 'onPause',
	ended: 'onEnd',
	timeupdate: 'onTimeUpdate',
	progress: 'onProgress',
	seeked: 'onSeeked',
	texttrackchange: 'onTextTrackChange',
	cuechange: 'onCueChange',
	cuepoint: 'onCuePoint',
	volumechange: 'onVolumeChange',
	playbackratechange: 'onPlaybackRateChange',
	error: 'onError',
	loaded: 'onLoaded',
	bufferstart: 'onBufferStart',
	bufferend: 'onBufferEnd',
	enterpictureinpicture: 'onEnterPictureInPicture',
	fullscreenchange: 'onFullscreenChange',
	qualitychange: 'onQualityChange',
};

type VimeoVideoPlayerPropsType = {
	options: VimeoPlayer.Options;
	id?: string;
	className?: string;
	style?: CSSProperties;
	start?: number;
	volume?: number;
	playbackRate?: number;
	callbacks?: {
		onReady?: () => void;
		onError?: (error: any) => void;
		onPlay?: () => void;
		onPlaying?: () => void;
		onPause?: () => void;
		onEnd?: () => void;
		onTimeUpdate?: () => void;
		onProgress?: () => void;
		onSeeked?: () => void;
		onVolumeChange?: () => void;
		onPlaybackRateChange?: (e: any) => void;
		onLoaded?: () => void;
		onBufferStart?: () => void;
		onBufferEnd?: () => void;
		onQualityChange?: () => void;
		onEnterPictureInPicture?: () => void;
		onFullscreenChange?: () => void;
	};
};

type DownloadData = Array<{
	link: string;
	quality: string;
	type: string;
	size: number;
	label: string;
}>;

export const VimeoVideoPlayer = forwardRef(
	(props: VimeoVideoPlayerPropsType, ref) => {
		const vimeoVideoPlayerRef = useRef<VimeoPlayer>();
		const [downloadData, setDownloadData] =
			useState<DownloadData>([]);
		const [loading, setLoading] = useState(false);

		const {
			callbacks,
			options,
			id,
			className,
			style,
			start,
			volume,
			playbackRate,
		} = props;

		const onIssue = async (error: any) => {
			if (options.url) {
				try {
					setLoading(true);
					const downloadUrls =
						await services.courseService.getVimeoVideoDownloadUrls(
							options.url
						);
					setLoading(false);
					setDownloadData(downloadUrls);
				} catch (error) {
					setLoading(false);
					console.error(error);
				}
			}
		};

		const updateProps = (propNames: Array<string>) => {
			if (!vimeoVideoPlayerRef.current) return;

			propNames.forEach((name) => {
				// @ts-ignore
				const value = props[name];
				switch (name) {
					case 'autopause':
						vimeoVideoPlayerRef.current!.setAutopause(
							value
						);
						break;
					case 'color':
						vimeoVideoPlayerRef.current!.setColor(value);
						break;
					case 'loop':
						vimeoVideoPlayerRef.current!.setLoop(value);
						break;
					case 'volume':
						vimeoVideoPlayerRef.current!.setVolume(value);
						break;
					case 'paused':
						vimeoVideoPlayerRef
							.current!.getPaused()
							.then((paused) => {
								if (value && !paused) {
									return vimeoVideoPlayerRef.current!.pause();
								}
								if (!value && paused) {
									return vimeoVideoPlayerRef.current!.play();
								}
								return null;
							});
						break;
					case 'video':
						if (value) {
							const loaded =
								vimeoVideoPlayerRef.current!.loadVideo(
									value
								);
							// Set the start time only when loading a new video.
							// It seems like this has to be done after the video has loaded, else it just starts at
							// the beginning!
							if (typeof props.start === 'number') {
								loaded.then(() => {
									vimeoVideoPlayerRef.current!.setCurrentTime(
										props.start!
									);
								});
							}
						} else {
							vimeoVideoPlayerRef.current!.unload();
						}
						break;
					case 'playbackRate':
						vimeoVideoPlayerRef.current!.setPlaybackRate(
							value
						);
						break;
					case 'quality':
						vimeoVideoPlayerRef.current!.setQuality(value);
						break;
					default:
					// Nothing
				}
			});
		};

		useEffect(() => {
			setDownloadData([]);
			if (!options.url && !options.id) return;

			vimeoVideoPlayerRef.current = new VimeoPlayer(
				id ?? 'vimeo-video-player',
				options
			);
			Object.keys(eventNames).forEach((dmName) => {
				const reactName = eventNames[dmName];
				vimeoVideoPlayerRef.current!.on(dmName, (event) => {
					// @ts-ignore
					const handler = props.callbacks?.[reactName];
					if (handler) handler(event);
				});
			});

			vimeoVideoPlayerRef.current.ready().then(
				() => {
					callbacks?.onReady?.();
				},
				(err) => {
					if (callbacks?.onError) callbacks.onError(err);
					else throw err;
				}
			);

			if (typeof start === 'number') {
				vimeoVideoPlayerRef.current.setCurrentTime(start);
			}

			if (typeof volume === 'number') {
				updateProps(['volume']);
			}

			if (typeof playbackRate === 'number') {
				updateProps(['playbackRate']);
			}

			return () => {
				vimeoVideoPlayerRef.current?.destroy();
			};
		}, [options.url]);

		useImperativeHandle(
			ref,
			() => ({
				getPlayer: () => vimeoVideoPlayerRef.current,
			}),
			[options.url]
		);

		return (
			<>
				<div
					id={id ?? 'vimeo-video-player'}
					className={className}
					style={style}
				/>
				<div className="vimeo-player-additional-content">
					{downloadData.length ? (
						<div className="vimeo-video-player-error-view">
							<p>
								Please download video from below link(s).
							</p>
							<div className="urls">
								{downloadData.map((item) => (
									<a
										key={item.link}
										href={item.link}
										target="_blank"
										rel="noreferrer"
									>
										{item.label} -{' '}
										{item.quality.toUpperCase()} - (
										{convertByteToMB(item.size)}MB)
									</a>
								))}
							</div>
						</div>
					) : (
						<div className="inner-content">
							{loading && <CircularProgress />}
							<div
								className="vimeo-issue"
								onClick={onIssue}
							>
								Having any issue?
							</div>
						</div>
					)}
				</div>
			</>
		);
	}
);
