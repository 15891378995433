export default [
	{
		title: 'Pre-installations and intro to git',
		url: 'https://vimeo.com/770760443/b73b2b3ba0',
		thumbnail: `${window.location.origin}/assets/images/videos/intro-to-git-thumbnail.png`,
	},
	{
		title: 'How to debug / deploy a static',
		url: 'https://vimeo.com/770730389/46a14e8ebb',
		thumbnail: `${window.location.origin}/assets/images/videos/how-to-deploy-debug-static.png`,
	},
	{
		title: 'How to debug / deploy a react app',
		url: 'https://vimeo.com/770740979/2320d1e11e',
		thumbnail: `${window.location.origin}/assets/images/videos/deploy-debug-react-app.png`,
	},
	{
		title: 'Intro to Figma',
		url: 'https://vimeo.com/770721192/308c5c22c6',
		thumbnail: `${window.location.origin}/assets/images/videos/intro-to-figma.png`,
	},
];
