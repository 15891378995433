import barsRed from '../../../../../assets/images/bars-red.svg';
import { useAppSelector } from '../../../../../redux/store';
export const StreakCard = () => {
	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	if (performanceData) {
		return (
			<div
				className="preview-card"
				style={{ flex: 0.7 }}
			>
				<img
					src={barsRed}
					className="bars-img"
				/>
				<div className="right-div">
					<p className="heading">Streaks</p>
					<p className="days-text">
						{performanceData.data?.streak}
						<span className="grey">Days</span>
					</p>
				</div>
			</div>
		);
	} else return null;
};
