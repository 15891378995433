import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useSelector } from 'react-redux';
import RenderMarkDown from '../shared/RenderMarkdown';
import { useEffect, useState } from 'react';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
};

interface modalInterface {
	open: boolean;
	setOpen: any;
}

export default function BlackListModal({
	open,
	setOpen,
}: modalInterface) {
	// const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const { blackListStatus } = useSelector(
		(state: any) => state.jobPortal
	);

	console.log('blackListStatus', blackListStatus);

	const [statement, setStatement] = useState('');

	useEffect(() => {
		if (
			blackListStatus !== null &&
			blackListStatus !== undefined
		) {
			let temp = blackListStatus.blacklistedReason;
			temp = temp.replaceAll('\n', '\n\n');
			setStatement(temp);
		}
	}, [blackListStatus]);

	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Typography
					id="modal-modal-title"
					variant="h6"
					component="h2"
				>
					You are BlackListed !
				</Typography>
				<Typography
					id="modal-modal-description"
					sx={{ mt: 2 }}
				>
					<RenderMarkDown>{statement}</RenderMarkDown>
				</Typography>
				<Button
					onClick={handleClose}
					variant="contained"
					style={{ marginTop: '10px' }}
				>
					Close
				</Button>
			</Box>
		</Modal>
	);
}
