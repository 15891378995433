import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';

export class UserService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async login(loginDetails: any) {
		const url = `${this.endpoint}/users/login`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: loginDetails,
				}
			);
			console.log('Login', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async loginTemp(loginDetails: any) {
		const url = `${this.endpoint}/users/admin/login/only`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: loginDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async signup(signupDetails: any) {
		const url = `${this.endpoint}/users/signup`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: signupDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAptTestResult() {
		const url = `${this.endpoint}/user-report-card/apt-test-status`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('apt-test-status', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getReport() {
		const url = `${this.endpoint}/user-report-card`;
		console.log('ENDPOINT', this.endpoint);
		try {
			console.log('dome', url);
			const { data } = await this.httpService.get<any>(url);
			console.log('report', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getUserAuthStatus() {
		const url = `${this.endpoint}/users/userAuthStatus`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async enrollPreCourse() {
		const url = `${this.endpoint}/course/enrollPreCourse`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addedEvent(id: string) {
		const url = `${this.endpoint}/users/added_event/` + id;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMonthyChartData() {
		const url = `${this.endpoint}/user-score-card/monthlyQuestions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markChoseRecorded(id: string) {
		const url = `${this.endpoint}/users/get_recorded/` + id;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async sendOTP(phoneNumber: string) {
		const url = `${this.endpoint}/users/sendOTP`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						phoneNumber,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async verifyOTP(
		phoneNumber: string,
		otp: string,
		countrycode: number
	) {
		const url = `${this.endpoint}/users/verifyOTP`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						phoneNumber,
						otp,
						countrycode,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitUgandaOTP(
		phoneNumber: string,
		countrycode: number
	) {
		const url = `${this.endpoint}/users/verifyUgandaOTP`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						phoneNumber,
						countrycode,
					},
				}
			);

			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateCollegeDetails(graduationYear: number) {
		const url = `${this.endpoint}/users/updateCollegeDetails`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						graduationYear,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateUserDetails(
		firstName: any,
		lastName: any,
		graduationYear: any
	) {
		const url = `${this.endpoint}/users/updateUser`;
		try {
			const { data } = await this.httpService.patch<any>(
				url,
				{
					data: {
						firstName,
						lastName,
						graduationYear,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getMockTestLink(id: any) {
		const url = `${this.endpoint}/user-report-card/mock-job-test-url/${id}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getNewAptTestLink() {
		const url = `${this.endpoint}/user-report-card/get-admission-apt-test-link-after-failure`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getReferralsCount() {
		const url = `${this.endpoint}/users/referrals`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getProfile() {
		const url = `${this.endpoint}/student-personal-details/getGeneralProfileFormFields`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async saveProfile(body: any) {
		console.log('body', body);
		const url = `${this.endpoint}/student-personal-details/saveGeneralProfileFormFields`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getPlacementProfile() {
		const url = `${this.endpoint}/student-personal-details/getPlacementProfileFormFields`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async savePlacementProfile(body: any) {
		const url = `${this.endpoint}/student-personal-details/savePlacementProfileFormFields`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async saveLeetcodeUserName(body: any) {
		const url = `${this.endpoint}/student-personal-details/leetcode-username`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLeetcodeUserName() {
		const url = `${this.endpoint}/student-personal-details/leetcode-username`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async postProfile(
		resume_link: string,
		resume_file_name: string
	) {
		const url = `${this.endpoint}/user-profile/update`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						resume_link,
						resume_file_name,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getStudentPlacementReadiness() {
		const url = `${this.endpoint}/userMainCourseDetails/studentPlacementReadiness`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('placement readinessss>>>>', data);
			return data;
		} catch (err) {
			console.log(err);
			throw err;
		}
	}

	async confirmCollege(type: String) {
		const url = `${this.endpoint}/student-personal-details/college-verification-status`;
		try {
			const { data } = await this.httpService.patch<any>(
				url,
				{
					data: {
						status: true,
						type: type,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async bookInterview(
		college: FormDataEntryValue,
		degree: FormDataEntryValue,
		whatsappNumber: FormDataEntryValue,
		class_10_percentage: FormDataEntryValue,
		class_12_percentage: FormDataEntryValue,
		cgpa: FormDataEntryValue,
		resume: string,
		department: FormDataEntryValue,
		parentsContactNumber: FormDataEntryValue,
		collegeState: FormDataEntryValue,
		homestate: FormDataEntryValue
	) {
		const url = `${this.endpoint}/user-profile/book-interview`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						college,
						degree,
						whatsappNumber,
						class_10_percentage,
						class_12_percentage,
						cgpa,
						resume,
						department,
						parentsContactNumber,
						collegeState,
						homestate,
						// interview_slot,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async callbackRequest(
		callbackRequest: boolean,
		callbackRequestTime: string
	) {
		const url = `${this.endpoint}/users/callbackRequest`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { callbackRequest, callbackRequestTime },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async joinedLiveClass() {
		const url = `${this.endpoint}/users/joinedLiveClass`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//we can remove move to the session service
	async getAvailableSlots(
		date: string,
		sessionID: string,
		mentorId: null | string
	) {
		const url = `${this.endpoint}/session/getAvailaibleSlots`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						date: date,
						sessionId: sessionID,
						mentorId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//not using
	async getPrevMentors(sessionID: string, userId: string) {
		const url = `${this.endpoint}/session/prevmentors/${userId}/${sessionID}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//we can remove move to the session service
	async bookSessionWithMentor(
		date: string,
		sessionID: string,
		slot: string,
		mentorId: null | string
	) {
		const url = `${this.endpoint}/session/bookSessionWithMentor`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						date: date,
						sessionId: sessionID,
						slot: slot,
						mentorId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async checkIfCommunityEnabled() {
		const url = `${this.endpoint}/buildonscenes/does-any-role-exist`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (err) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async playgroundCallBack() {
		const url = `${this.endpoint}/users/onboardedUserCallbackRequest`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (err) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async playgroundOnboardingDetails() {
		const url = `${this.endpoint}/playground/getUserOnboardingInfo`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (err) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async playgroundUserEnrollmentDetails() {
		const url = `${this.endpoint}/playground/getUserEnrollmentDetails`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (err) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getCollegeNames(input: string) {
		const url = `${this.endpoint}/college?query=${input}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateCollegeName(
		collegeName: string,
		collegeId: string | null,
		type: String
	) {
		const url = `${this.endpoint}/student-personal-details/user-college-id`;
		try {
			const { data } = await this.httpService.patch<any>(
				url,
				{
					data: {
						collegeName: collegeName,
						collegeId: collegeId,
						type: type,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getResumeSignedUrl(fileName: string) {
		const url = `${
			this.endpoint
		}/student-personal-details/resume-signed-url?fileName=${encodeURIComponent(
			fileName
		)}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getProfileShareLink(name: string) {
		const url = `${this.endpoint}/student-personal-details/profileShareLink?fileName=${name}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async uploadProfilePicture(file: File) {
		const url = `${this.endpoint}/student-personal-details/upload-profile-picture`;
		console.log('file in service>>>', file);
		const fileData = new FormData();
		fileData.append('file', file);
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: fileData,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async saveResume(resumeLink: string) {
		const url = `${this.endpoint}/student-personal-details/resume`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						resumeLink,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async chatFeedback(
		sessionId: string,
		rating: number,
		chatFeedbackText: string
	) {
		const url = `https://asia-south1-acciojob-prod.cloudfunctions.net/crisp-session-feedback`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						sessionId: sessionId,
						feedback: rating,
						feedbackText: chatFeedbackText,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async crispChatData(sessionId: string, userId: string) {
		const url = `https://asia-south1-acciojob-prod.cloudfunctions.net/crisp-session-feedback`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						sessionId: sessionId,
						userId: userId,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getProfilePauseStatus() {
		const url = `${this.endpoint}/users/get-course-pause-button-details`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async raiseCoursePauseRequest(body: any) {
		const url = `${this.endpoint}/users/register-course-profile-pause-request`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { ...body },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getTrackDetails() {
		const url = `${this.endpoint}/users/get-user-track-details`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
