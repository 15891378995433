import React, { ReactElement } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

interface AccioCarouselProps {
	items: any[];
	options: any;
}

const AccioCarousel = ({
	items,
	options,
}: AccioCarouselProps) => {
	return (
		<AliceCarousel
			{...options}
			items={items}
		/>
	);
};

export default AccioCarousel;
