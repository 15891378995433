import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export const fetchMentorProfile = createAsyncThunk(
	'mentor/getProfile',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorProfile();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const updateMentorProfile = createAsyncThunk(
	'mentor/updateProfile',
	async (body: any) => {
		try {
			const res =
				await services.mentorService.updateMentorProfile(
					body
				);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorExperiences = createAsyncThunk(
	'mentor/experiences',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorExperiences();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorPreferences = createAsyncThunk(
	'mentor/mentorPreferences',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorPreferences();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorSkills = createAsyncThunk(
	'mentor/mentorSkills',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorSkills();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorDocs = createAsyncThunk(
	'mentor/mentorDocs',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorDocs();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorAnnouncements = createAsyncThunk(
	'mentor/announcements',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorAnnouncements();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorSessions = createAsyncThunk(
	'mentor/Sessions',
	async (params: string) => {
		try {
			const res =
				await services.mentorService.fetchMentorSessions(
					params
				);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorSessionTypes = createAsyncThunk(
	'mentor/SessionsTypes',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorSessionTypes();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchUpcomingOngoingSessions =
	createAsyncThunk('mentor/UpcomingOngoing', async () => {
		try {
			const res =
				await services.mentorService.fetchUpcominOngoingSessions();
			return res;
		} catch (error) {
			console.log(error);
		}
	});

export const fetchMentorAvailability = createAsyncThunk(
	'mentor/mentorAvailability',
	async () => {
		try {
			const res =
				await services.mentorService.getMentorAvailability();
			console.log('respoinse', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorQCScore = createAsyncThunk(
	'mentor/mentorQCScore',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorQCScore();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchEligibleSessionTypes = createAsyncThunk(
	'mentor/eligibleSessionTypes',
	async () => {
		try {
			const res =
				await services.mentorService.fetchEligibleSessionTypes();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorInvoice = createAsyncThunk(
	'mentor/paymentInvoice',
	async (obj: any) => {
		try {
			const res =
				await services.mentorService.fetchMentorInvoice(
					obj.startDate,
					obj.endDate
				);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorProfileStatus = createAsyncThunk(
	'mentor/profileStatus',
	async () => {
		try {
			const res =
				await services.mentorService.fetchMentorProfileStatus();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMentorPreferencesByRole =
	createAsyncThunk('mentor/preferencesByRole', async () => {
		try {
			const res =
				await services.mentorService.fetchMentorPreferencesByRole();
			return res;
		} catch (error) {
			console.log(error);
		}
	});

interface mentorState {
	mentor: any;
	mentorExperiences: any;
	mentorSkillsdata: any;
	mentorDocs: any;
	mentorAnnouncements: any;
	mentorSessions: any;
	mentorSessionTypes: any;
	upcomingOngoingSessions: any;
	mentorProfileLoading: boolean;
	mentorAvailability: any;
	mentorQCScore: any;
	eligibleSessionTypes: any;
	mentorInvoice: any;
	preferencesByRole: any;
	mentorProfileStatus: any;
	prevSessionId: string;
	prevStartTime: string;
	prevEndTime: string;
	sessionsPage: number;
	prevTab: string;
	loading: boolean;
}

const initialState: mentorState = {
	mentor: null,
	mentorExperiences: [],
	mentorSkillsdata: [],
	mentorDocs: null,
	mentorAnnouncements: null,
	mentorSessions: null,
	mentorSessionTypes: null,
	upcomingOngoingSessions: null,
	mentorAvailability: null,
	mentorQCScore: null,
	eligibleSessionTypes: null,
	mentorInvoice: null,
	preferencesByRole: null,
	mentorProfileStatus: null,
	mentorProfileLoading: true,
	prevSessionId: '',
	prevStartTime: '',
	prevEndTime: '',
	sessionsPage: 0,
	prevTab: '',
	loading: false,
};

export const mentorSlice = createSlice({
	name: 'mentor',
	initialState,
	reducers: {
		setPrevSessionId: (
			state,
			action: PayloadAction<any>
		) => {
			state.prevSessionId = action.payload;
		},
		setPrevStartTime: (
			state,
			action: PayloadAction<any>
		) => {
			state.prevStartTime = action.payload;
		},
		setPrevEndTime: (state, action: PayloadAction<any>) => {
			state.prevEndTime = action.payload;
		},
		setSessionsPage: (
			state,
			action: PayloadAction<any>
		) => {
			state.sessionsPage = action.payload;
		},
		setPrevTab: (state, action: PayloadAction<any>) => {
			state.prevTab = action.payload;
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorProfile.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			return;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorProfile.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentor = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorProfile.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorExperiences.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			return;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorExperiences.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorExperiences = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorExperiences.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSkills.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorSkillsdata = [];
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSkills.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorSkillsdata = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSkills.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorDocs.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorDocs = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorDocs.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorDocs = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorDocs.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorAnnouncements.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorAnnouncements = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorAnnouncements.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorAnnouncements = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorAnnouncements.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSessions.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorSessions = null;
			state.loading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSessions.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorSessions = action.payload;
			state.loading = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSessions.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSessionTypes.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorSessionTypes = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSessionTypes.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorSessionTypes = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorSessionTypes.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUpcomingOngoingSessions.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.upcomingOngoingSessions = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUpcomingOngoingSessions.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.upcomingOngoingSessions = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUpcomingOngoingSessions.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorAvailability.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorAvailability = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorAvailability.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorAvailability = action.payload;
			console.log('payload', action.payload);
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorAvailability.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorQCScore.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorQCScore = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorQCScore.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorQCScore = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorQCScore.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchEligibleSessionTypes.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.eligibleSessionTypes = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchEligibleSessionTypes.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.eligibleSessionTypes = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchEligibleSessionTypes.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorInvoice.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorInvoice = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorInvoice.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorInvoice = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorInvoice.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorPreferencesByRole.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.preferencesByRole = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorPreferencesByRole.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.preferencesByRole = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorPreferencesByRole.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorProfileStatus.pending]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorProfileLoading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorProfileStatus.fulfilled]: (
			state: mentorState,
			action: PayloadAction<any[]>
		) => {
			state.mentorProfileStatus = action.payload;
			state.mentorProfileLoading = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMentorProfileStatus.rejected]: (
			state: mentorState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},
	},
});

//Exporting Actions
export const {
	setPrevEndTime,
	setPrevSessionId,
	setPrevStartTime,
	setSessionsPage,
	setPrevTab,
} = mentorSlice.actions;

export default mentorSlice.reducer;
