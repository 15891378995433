import React, { useState, useEffect } from 'react';
import {
	ReflexContainer,
	ReflexSplitter,
	ReflexElement,
} from 'react-reflex';
import 'react-reflex/styles.css';
import { FaGithub } from 'react-icons/fa';
import GithubAuth from './GithubAuth';

import QuestionPanel from './QuestionPanel';
import { Button } from '@mui/material';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { services } from '../../apis/index';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {
	fetchWebDevQuestionData,
	startAssignment,
} from '../../redux/webDevQuestion/webDevQuestionSlice';
import { FiGithub } from 'react-icons/fi';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

// interface RouterProps {
// 	match: any;
// }

const WebDevScreen = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const questionId: string = queryString.parse(
		location.search
	).question;
	const editorState = useSelector(
		(state: any) => state.webDevQuestion
	);

	const [showPopup, setShowPopup] = useState(false);

	const setPopup = () => {
		setShowPopup(!showPopup);
	};
	// const code: string = queryString.parse(location.search).code;
	// const { codingQuestiondata } = useSelector((state: any) => state.precourse);
	const {
		loading,
		isThemeDark,
		questionData,
		error,
		startingAssignment,
		acceptedInvitation,
		solutionRepo,
		githubUserName,
		isTokenValid,
		githubLoginLink,
	} = editorState;

	const searchParams = new URLSearchParams(location.search);

	console.log('searchParams', searchParams.get('code'));
	console.log(
		'githubLoginLink',
		isTokenValid,
		githubLoginLink
	);

	useEffect(() => {
		if (questionId) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(fetchWebDevQuestionData(questionId));
			localStorage.setItem('questionId', questionId);
			console.log('i am running');
		}
	}, [questionId]);

	useEffect(() => {
		if (searchParams.get('code')) {
			console.log('CODE ==== ', searchParams.get('code'));
			dispatch(
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				startAssignment({
					questionId: questionId,
					code: searchParams.get('code') || undefined,
				})
			);
		}
	}, []);

	const onClickStartSolving = async () => {
		dispatch(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			startAssignment({
				questionId: questionId,
				code: searchParams.get('code') || undefined,
			})
		);
		setPopup();
	};

	return (
		<div className={'idle'}>
			<ReflexContainer orientation="vertical">
				<ReflexElement className="left-pane">
					<div style={{ height: '100%', overflow: 'auto' }}>
						<Box sx={{ width: '100%' }}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
								}}
							></Box>
							{questionData !== null ? (
								<QuestionPanel
									questionData={questionData}
								/>
							) : null}
						</Box>
					</div>
				</ReflexElement>

				<ReflexSplitter style={{ width: '10px' }} />

				<ReflexElement className="right-pane">
					{isTokenValid &&
					githubUserName &&
					githubUserName.length > 0 ? (
						<p className="github-user-name-text">
							You are logged in with
							<span className="user-name-text">
								{githubUserName}
							</span>
							github account
						</p>
					) : (
						<p className="github-user-name-text">
							Before start coding login with your
							<span
								className="user-name-text-error"
								onClick={() =>
									window.open(githubLoginLink, '_self')
								}
							>
								<FaGithub style={{ marginRight: '5px' }} />
								github
							</span>
							account.
						</p>
					)}
					{solutionRepo ? (
						<div className={'git-panel'}>
							<FaGithub />
							<h2>The name of your solution Github Repo</h2>
							<a
								href={`https://github.com/acciojob/${solutionRepo}`}
								target="_blank"
								rel="noreferrer"
							>
								{solutionRepo}
							</a>
							{questionData.scoreReceived !== -1 ? (
								<div style={{ fontSize: 18 }}>
									Your Score:{' '}
									{questionData.scoreReceived !== 'NaN'
										? questionData.scoreReceived
										: 0}
									/{questionData.score}
								</div>
							) : null}
							{questionData.questionType === 'BASIC' ? (
								<div>
									<button
										className={'open-editor-btn'}
										onClick={() => {
											window.open(
												`${window.origin}/open-web-editor?question=${questionId}`,
												'_blank'
											);
										}}
									>
										Open Editor
									</button>
								</div>
							) : null}
							{!acceptedInvitation && (
								<div style={{ fontSize: 19, color: 'red' }}>
									We encountered some problem regarding
									automatically accepting the assignment.
									However, an invitation link is sent to
									your github registered email. Please
									accept the assignment by clicking on the
									invitation link.
								</div>
							)}
						</div>
					) : (
						<>
							<Button
								onClick={onClickStartSolving}
								className={'startbtn'}
							>
								{startingAssignment ? (
									<>
										{/*<CircularProgress />*/}
										Starting
									</>
								) : (
									<>Start Solving</>
								)}
							</Button>
						</>
					)}
					{/* {error.state ? (
						error.message === 'GITHUB_AUTH_NEEDED' &&
						error.githubAuthLink ? (
							<GithubAuth
								githubAuthUri={error.githubAuthLink}
								setPopup={setPopup}
								showPopup={showPopup}
							/>
						) : (
							<div className="error-message-text">
								Error: {error.message}
							</div>
						)
					) : null} */}

					{/* {error.state ? (
						error.message.startsWith === 'Github token' &&
						error.githubAuthLink ? (
							<GithubAuth
								githubAuthUri={error.githubAuthLink}
								setPopup={setPopup}
								showPopup={showPopup}
							/>
						) : (
							<div className="error-message-text">
								Error: {error.message}
							</div>
						)
					) : null}

					{error.state &&
					error.message.startsWith === 'Template repo' ? (
						<div className="error-message-text">
							Error: {error.message}
						</div>
					) : null} */}

					{error.state ? (
						(error.message === 'GITHUB_AUTH_NEEDED' ||
							error.message.startsWith('Github token')) &&
						error.githubAuthLink ? (
							<GithubAuth
								githubAuthUri={error.githubAuthLink}
								setPopup={setPopup}
								showPopup={showPopup}
							/>
						) : error.message.startsWith(
								'Template repo'
						  ) ? (
							<div className="error-message-text">
								Error: {error.message}
							</div>
						) : (
							<div className="error-message-text">
								Error: {error.message}
							</div>
						)
					) : null}
				</ReflexElement>
			</ReflexContainer>
		</div>
	);
};

export default WebDevScreen;
