import { Box } from '@mui/material';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { BsFillFilePersonFill } from 'react-icons/bs';
import { FaLock } from 'react-icons/fa';
import { HiDocumentText } from 'react-icons/hi2';
import { useSelector } from 'react-redux';
import PauseProfileComponent from '../../components/pauseCourse/pauseProfile';
import ProfileTab1 from '../../components/profile/ProfileTab1';
import ProfileTab2 from '../../components/profile/ProfileTab2';
import PageHeading from '../../components/shared/PageHeading';
import TabPanel from '../../components/shared/TabPanel';

const Profile = () => {
	const { user } = useSelector((state: any) => state.user);

	const userProfileLock = user?.isProfileLocked ?? false;

	const [ProfileTabs, setProfileTabs] = useState<any[]>([]);

	useEffect(() => {
		setProfileTabs([
			{
				keyId: nanoid(),
				title: (
					<span>
						{!userProfileLock ? (
							<HiDocumentText className="profile-tab-icon" />
						) : (
							<FaLock
								className="profile-tab-icon"
								style={{
									color: 'rgb(248, 38, 108)',
									marginRight: '5px',
								}}
							/>
						)}
						Profile and Educational Info
					</span>
				),
				element: <ProfileTab1 />,
				show: true,
				tabBoxClass: '',
			},
			{
				keyId: nanoid(),
				title: (
					<span>
						{!userProfileLock ? (
							<BsFillFilePersonFill className="profile-tab-icon" />
						) : (
							<FaLock
								className="profile-tab-icon"
								style={{
									color: 'rgb(248, 38, 108)',
									marginRight: '5px',
								}}
							/>
						)}
						Personal Info
					</span>
				),
				element: <ProfileTab2 />,
				show: true, // Set show property to true
			},
		]);
	}, [userProfileLock]);

	return (
		<Box
			sx={{
				width: '100%',
				height: '100vh',
				overflow: 'auto',
				padding: '0px 25px',
			}}
		>
			<div className="page-heading-wrapper">
				<PageHeading pageName={'Your Profile'} />
			</div>

			<Box sx={{ width: '100%' }}>
				<TabPanel tabData={ProfileTabs} />
			</Box>
			<PauseProfileComponent />
		</Box>
	);
};

export default Profile;
