import { AiFillTrophy } from 'react-icons/ai';
import { useAppSelector } from '../../../../../redux/store';

export const ContestRankings = () => {
	const { contestData } = useAppSelector(
		(state) => state.profile
	);

	const name = [
		'Avg. Rank',
		'Highest Score',
		'Latest Rank',
	];

	if (contestData.data)
		return (
			<div className="transparent-div">
				<div className="heading-flex">
					<AiFillTrophy
						style={{
							color: '#2F80ED',
							fontSize: '24px',
						}}
					/>
					<p className="heading">Contest Rankings</p>
				</div>
				<div className="rankings">
					{[
						contestData.data?.averageRank,
						contestData.data?.maxScore,
						contestData.data?.lastRank,
					].map((item, i) => (
						<div
							key={i}
							className="ranking"
						>
							<p className="black">
								{item?.toFixed(0) === '-1'
									? 'NA'
									: item?.toFixed(0)}
							</p>
							<p className="grey">{name[i]}</p>
						</div>
					))}
				</div>
			</div>
		);
	else return null;
};
