import { Modal, ModalClose, Sheet } from '@mui/joy';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../../apis';
import logo from '../../../assets/images/acciojobLogo.svg';
import {
	setAllFeedbackOpenModal,
	setNextPopupId,
	setSelectedRatingVal,
} from '../../../redux/allFeedbackModal/allFeedbackModal.slice';
import { fetchPopUpToDisplay } from '../../../redux/popups/popup.thunk';
import {
	AppDispatch,
	RootState,
} from '../../../redux/store';
import AccioButton from '../../elements/Button';
import { notifySuccess } from '../../notification';

interface AllFeedbackModalProps {
	popUpData: {
		popUpHeaderText: string;
		popupType: string;
		showPopup: boolean;
		shouldShrinkPopup: boolean;
		feedbackType: string;
		ratings: Rating[];
		lowestRatingText: string;
		highestRatingText: string;
		optionWiseFeedbackPopup: OptionWiseFeedbackPopup;
		reviewPopup: ReviewPopup;
		ctas: CTA[];
		[key: string]: any;
	};
}

interface Rating {
	rating: number;
	nextPopupId: string;
}

interface OptionWiseFeedbackPopup {
	options: Option[];
	commentBox: CommentBox;
}

interface Option {
	optionName: string;
}

interface CommentBox {
	commentBoxPlaceholder: string;
}

interface ReviewPopup {
	commentBox: CommentBox;
}

interface CTA {
	ctaType: string;
	ctaId: string;
	ctaText: string;
	actions: Action[];
}

interface Action {
	actionType: string;
	actionUrl: string;
}
const AllFeedbackModal: FC<AllFeedbackModalProps> = ({
	popUpData,
}) => {
	const modalData = useSelector(
		(state: RootState) => state.allFeedbackModal
	);
	const dispatch = useDispatch<AppDispatch>();

	console.log('modalData', modalData, popUpData);

	const closeModal = async () => {
		dispatch(setAllFeedbackOpenModal(false));
		try {
			const res = await services.popupService.recordRating({
				feedbackType: popUpData.feedbackType,
				page: '/home',
				hasClosedFeedbackPopup: true,
			});
			// notifySuccess(res?.message);
		} catch (error) {
			console.error(error);
		}
	};

	const [selectedCheckboxValue, setSelectedCheckboxValue] =
		useState<string[]>([]);

	// const handleRatingHover = (
	// 	rating: number | null,
	// 	nextPopupId: string | null
	// ) => {
	// 	dispatch(setSelectedRatingVal(rating));
	// 	dispatch(setNextPopupId(nextPopupId));
	// 	if (rating && rating > 8) {
	// 		setSelectedCheckboxValue([]);
	// 		setTextAreaValue('');
	// 	}
	// };

	const handleRatingClick = async (
		rating: number | null,
		nextPopupId: string | null
	) => {
		dispatch(setSelectedRatingVal(rating));
		dispatch(setNextPopupId(nextPopupId));
		if (rating && rating > 8) {
			setSelectedCheckboxValue([]);
			setTextAreaValue('');
		}

		// TODO: Have to perform an API call to save the rating
		try {
			const res = await services.popupService.recordRating({
				feedbackType: popUpData.feedbackType,
				page: '/home',
				rating: rating,
			});
			// notifySuccess(res?.message);
		} catch (error) {
			console.error(error);
		}
	};

	const handleCheckboxChange = (
		e: React.ChangeEvent<HTMLInputElement>
	) => {
		const value = e.target.value;

		if (e.target.checked) {
			setSelectedCheckboxValue((prevChecked) => [
				...prevChecked,
				value,
			]);
		} else {
			setSelectedCheckboxValue((prevChecked) =>
				prevChecked.filter((checked) => checked !== value)
			);
		}
	};

	const [textAreaValue, setTextAreaValue] = useState(''); // State for the text area value

	const handleTextAreaChange = (
		e: React.ChangeEvent<HTMLTextAreaElement>
	) => {
		setTextAreaValue(e.target.value);
	};

	useEffect(() => {
		if (popUpData?.shouldShrinkPopup) {
			dispatch(setAllFeedbackOpenModal(false));
		}
	}, []);

	const handleSubmitAction = async () => {
		// TODO: Have to perform an API call to save the rating
		try {
			const res = await services.popupService.recordRating({
				feedbackType: popUpData.feedbackType,
				page: '/home',
				rating: modalData?.selectedRatingVal,
				feedback: textAreaValue,
				relatedEntities: selectedCheckboxValue,
			});
			notifySuccess(res?.message);
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(setAllFeedbackOpenModal(false));
			dispatch(fetchPopUpToDisplay('home'));
		}
	};

	return (
		<Modal
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={modalData.allFeedbackModalOpen}
			onClose={closeModal}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Sheet
				variant="outlined"
				sx={{
					maxWidth: 500,
					borderRadius: 'md',
					p: 0,
					boxShadow: 'lg',
					border: 'none',
					// borderTop: '3px solid #2e90fa',
					outline: '1px solid transparent',
				}}
			>
				<ModalClose
					variant="plain"
					sx={{ m: 1, right: '-40px', top: '-40px' }}
				/>
				<div className="all-feedback-modal-rating-wrapper">
					<p className="modal-title">
						{popUpData?.popUpHeaderText}
					</p>
					<div className="modal-rating-container">
						<div className="rating-wrapper">
							<div className="rating-holder">
								{popUpData?.ratings?.length > 0
									? popUpData?.ratings?.map(
											(rating: Rating, i: number) => (
												<div
													className="rating-component-wrapper"
													key={i}
												>
													<span
														className={`rating-component ${
															rating?.rating <=
															(modalData?.selectedRatingVal ||
																0)
																? 'selected'
																: ''
														}`}
														// onMouseEnter={() =>
														// 	handleRatingHover(
														// 		rating?.rating || null,
														// 		rating?.nextPopupId || null
														// 	)
														// }
														// onMouseLeave={() => handleRatingHover(null, null)}
														onClick={() =>
															handleRatingClick(
																rating?.rating || null,
																rating?.nextPopupId || null
															)
														}
													></span>
													<span className="rating-number">
														{rating?.rating}
													</span>
												</div>
											)
									  )
									: null}
							</div>
							<div className="modal-rating-text">
								<span className="text">
									{popUpData?.lowestRatingText}
								</span>
								<span className="text">
									{popUpData?.highestRatingText}
								</span>
							</div>
						</div>

						{modalData?.nextPopupId !== null &&
						popUpData[modalData?.nextPopupId] ? (
							<div className="modal-comment-wrapper">
								{popUpData[modalData?.nextPopupId]
									?.options ? (
									<div className="checkbox-wrapper">
										{popUpData[
											modalData?.nextPopupId
										].options.map(
											(checkbox: Option, i: number) => (
												<div
													key={i}
													className="checkbox-item"
												>
													<input
														type="checkbox"
														value={checkbox?.optionName}
														onChange={handleCheckboxChange}
														checked={selectedCheckboxValue.includes(
															checkbox?.optionName
														)}
													/>
													<span className="text">
														{checkbox?.optionName}
													</span>
												</div>
											)
										)}
									</div>
								) : null}
								<div className="modal-input-feedback-wrapper">
									<p className="modal-input-feedback-title">
										{
											popUpData[modalData?.nextPopupId]
												?.commentBox?.commentBoxPlaceholder
										}
									</p>
									<textarea
										cols={30}
										rows={5}
										className="modal-input-feedback"
										value={textAreaValue}
										onChange={handleTextAreaChange}
										placeholder="Describe here"
									/>
								</div>
							</div>
						) : null}
						<div className="modal-footer-wrapper">
							<img
								src={logo}
								alt="acciojob-logo"
							/>
							{popUpData?.ctas?.length
								? popUpData?.ctas.map((cta: CTA) => (
										<AccioButton
											title={cta?.ctaText}
											disabled={
												modalData?.selectedRatingVal ===
												null
											}
											customClass={
												modalData?.selectedRatingVal ===
												null
													? 'disabled-btn'
													: ''
											}
											action={handleSubmitAction}
										/>
								  ))
								: null}
						</div>
					</div>
				</div>
			</Sheet>
		</Modal>
	);
};

export default AllFeedbackModal;
