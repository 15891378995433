export class CourseEngagement {
	joinLiveDoubtSession(topic: string) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('joined_live_doubt_session', {
				topic: topic,
				join_time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	bookLiveDoubtSession(topic: string, slot: any) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('booked_live_doubt_session', {
				slot_time: slot,
				topic: topic,
			});
			console.log('booked session', topic, slot);
		} catch (e) {
			console.log(e);
		}
	}

	joinClass(className: string, classId?: string) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('class_joined', {
				class_name: className,
				class_id: classId,
				start_time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	watchRecording(
		videoName: string,
		videoID: string,
		courseName: string,
		moduleName: string
	) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('watch_recording', {
				video_id: videoID,
				video_name: videoName,
				time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}
}
