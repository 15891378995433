import { FC } from 'react';
import clock_grey from '../../assets/softSkillsNew/clock_grey.svg';
import angle_right_blue from '../../assets/softSkillsNew/angle_right_blue.svg';
import {
	BaseClassesType,
	VideoContentType,
} from '../../redux/softskills/softskills.type';

type CardPropsType = {
	videoDetails: VideoContentType | BaseClassesType;
	setSelectedVideo: any;
};

const PastSessionsCard: FC<CardPropsType> = ({
	videoDetails,
	setSelectedVideo,
}) => {
	return (
		<div className="past-session-card">
			<h3 className="video-title">{videoDetails.title}</h3>
			<p className="video-duration">
				<img
					src={clock_grey}
					style={{ width: '14px' }}
				/>
				<p className="video-duration-text">Duration:</p>
				<p className="video-duration-value">
					{videoDetails.duration} min
				</p>
			</p>
			<a
				className="watch-session"
				onClick={() => {
					setSelectedVideo(videoDetails);
				}}
			>
				Watch Session <img src={angle_right_blue} />
			</a>
		</div>
	);
};

export default PastSessionsCard;
