import { LinearProgress, Tooltip } from '@mui/joy';
import trophy from '../../../assets/images/trophy.svg';
import level0 from '../../../assets/images/acheivments/level0.svg';
import level1 from '../../../assets/images/acheivments/level1.svg';
import level2 from '../../../assets/images/acheivments/level2.svg';
import level3 from '../../../assets/images/acheivments/level3.svg';
import level4 from '../../../assets/images/acheivments/level4.svg';
import level5 from '../../../assets/images/acheivments/level5.svg';

export const VerticleAchievement = ({
	level,
	streak,
	total,
	learnMore,
	heading,
}: {
	level: number;
	streak: number;
	total: Array<number>;
	learnMore: string;
	heading: string;
}) => {
	console.log(level, streak, total, learnMore);
	const getImage = () => {
		if (level == 0) {
			return level1;
		}
		if (level == 1) {
			return level1;
		} else if (level == 2) {
			return level2;
		} else if (level == 3) {
			return level3;
		} else if (level == 4) {
			return level4;
		} else if (level == 5) {
			return level5;
		}
	};

	const naming = [
		'Noobie',
		'Apprentice',
		'Capable',
		'Proficient',
		'Master',
	];

	const getValue = () => {
		if (level === 0) return 0;
		else {
			if ((streak / total[level - 1]) * 100 > 100) {
				return 100;
			} else {
				return (streak / total[level - 1]) * 100;
			}
		}
	};

	return (
		<div className="vertical-achievement">
			<img
				src={getImage()}
				className="trophy-img"
				style={{
					filter:
						level == 0 ? 'grayscale(0.9)' : 'grayscale(0)',
				}}
			/>
			<p className="main-heading">{heading}</p>
			<p className="sub-heading">Level {level}</p>
			<LinearProgress
				determinate
				size="sm"
				value={getValue()}
				variant="solid"
				sx={{
					width: '100%',
					backgroundColor: '#D0D5DD',
					color: '#2F80ED',
				}}
			/>
			<Tooltip
				title={learnMore}
				placement="bottom-end"
				size="sm"
			>
				<p className="learn-more">Learn More</p>
			</Tooltip>
		</div>
	);
};
