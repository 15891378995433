import { async } from '@firebase/util';
import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	InputLabel,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import moment from 'moment';
import React, {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import {
	MdAddCircle,
	MdDelete,
	MdEdit,
	MdEditOff,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import { fetchMentorExperiences } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import { MentorExperienceFormFields } from '../mentorTypes';
import Fields from './Fields';

const enumToList = (data: any): string[] =>
	Object.values(data);

enum EmploymentTypes {
	PartTime = 'PART_TIME',
	FullTime = 'FULL_TIME',
	Contract = 'CONTRACT',
	Intern = 'INTERNSHIP',
}

const FormFields = [
	{
		title: 'Experience (Add Experience)',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			position: {
				label: 'Designation',
				type: 'text',
				disabled: false,
				required: true,
				items: [],
			},
			companyName: {
				label: 'Company Name',
				type: 'text',
				disabled: false,
				required: false,
				items: [],
			},
			jobType: {
				label: 'Employment Type',
				type: 'select',
				items: enumToList(EmploymentTypes),
				disabled: false,
				required: true,
			},
			startDate: {
				label: 'Start Date',
				type: 'date',
				disabled: false,
				required: true,
				items: [],
			},
			isCurrent: {
				label: 'Is Current?',
				type: 'checkbox',
				disabled: false,
				required: true,
			},
			endDate: {
				label: 'End Date',
				type: 'date',
				disabled: false,
				// required: true,
				items: [],
			},
			description: {
				label: 'Description',
				type: 'text',
				disabled: false,
				items: [],
			},
		},
	},
];

const AllExperiences = () => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, mentorExperiences } = MentorState;
	const initialFormData = [
		{
			// userId: user !== null ? user.id : '',
			id: '',
			companyName: '',
			position: '',
			startDate: '',
			endDate: null,
			description: '',
			isCurrent: false,
			jobType: '',
			createdAt: '',
			updatedAt: '',
		},
	];

	const [isProfileLocked, setIsProfileLocked] =
		useState(false);

	const [formFields, setFormFields] = useState(FormFields);

	const [formData, setFormData] = useState(initialFormData);
	const [edit, setEdit] = useState(false);
	const [activeId, setActiveId] = useState('');

	useEffect(() => {
		if (mentor !== null) dispatch(fetchMentorExperiences());
	}, [mentor]);

	useEffect(() => {
		if (mentorExperiences !== null) {
			let tempOne: any = [];
			//changing dates from ISO to 'YYYY-MM-DD'
			for (let i = 0; i < mentorExperiences.length; i++) {
				const tempTwo = {
					...mentorExperiences[i],
					startDate: moment(
						mentorExperiences[i].startDate
					).format('YYYY-MM-DD'),
					endDate: moment(
						mentorExperiences[i].endDate
					).format('YYYY-MM-DD'),
				};
				tempOne = [...tempOne, tempTwo];
			}
			setFormData([...tempOne]);
		}
	}, [mentorExperiences]);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[] = '',
		id: string,
		isCurrent: boolean = false,
		event?: any
	) => {
		let temp = [...formData];
		let index: number = 0;
		for (let i = 0; i < temp.length; i++) {
			const currObj = temp[i];
			if (currObj.id === id) index = i;
		}

		let targetObj: any = {
			...temp.filter((obj) => obj.id === id)[0],
		};
		if (isCurrent) {
			targetObj.isCurrent = !targetObj.isCurrent;
			targetObj.endDate = null;
		} else {
			//@ts-ignore
			targetObj[fieldId] = fieldValue;
			temp = [...temp.filter((obj) => obj.id !== id)];
		}

		temp.splice(index, 0, targetObj);
		setFormData([...temp]);
	};

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		let temp = {
			...formData.filter((obj) => obj.id === activeId)[0],
		};
		temp.startDate = new Date(temp.startDate).toISOString();
		if (!temp.isCurrent) {
			//@ts-ignore
			temp.endDate = new Date(temp.endDate).toISOString();
		} else {
			temp.endDate = null;
		}
		try {
			const data =
				await services.mentorService.updateMentorExperience(
					temp
				);
			if (
				data &&
				data.statusCode &&
				data.statusCode === 500
			)
				notifyError(data.message);
			else {
				setEdit(false);
				notifySuccess('Updated Succesfully');
			}
		} catch (error) {
			console.log(error);
		}
	};

	const deleteExperience = async (e: any, id: string) => {
		e.preventDefault();
		try {
			const data =
				await services.mentorService.deleteMentorExperience(
					id
				);
			if (data && data.statusCode)
				notifyError(data.message);
			else notifySuccess('Deleted Succesfully');
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{mentorExperiences &&
				formData.map((experience, index) => {
					return formFields.map((fieldBatch, idx) => {
						return (
							<div
								key={idx}
								className="batch"
							>
								<div style={{ display: 'flex' }}>
									<h4>{'Experience ' + (index + 1)}</h4>
									<div>
										<>
											{edit &&
											activeId === experience.id ? (
												<MdEditOff
													onClick={() => {
														setEdit(false);
														setActiveId(experience.id);
													}}
													fontSize={'24px'}
													style={{
														margin: '0 0.5em 0 1em',
													}}
													cursor={'pointer'}
												/>
											) : (
												<MdEdit
													onClick={() => {
														setEdit(true);
														setActiveId(experience.id);
													}}
													fontSize={'24px'}
													style={{
														margin: '0 0.5em 0 1em',
													}}
													cursor={'pointer'}
												/>
											)}
											<MdDelete
												fontSize={'24px'}
												cursor={'pointer'}
												onClick={(e) => {
													deleteExperience(
														e,
														experience.id
													);
												}}
											/>
										</>
									</div>
								</div>
								{edit && activeId === experience.id && (
									<>
										<div className="fields">
											{Object.entries(fieldBatch.items).map(
												([fieldId, field], idx) => (
													<>
														{field.type === 'date' ? (
															<TextField
																required={
																	formData[index]
																		.isCurrent &&
																	field.label == 'End Date'
																		? false
																		: true
																}
																disabled={
																	formData[index]
																		.isCurrent &&
																	field.label == 'End Date'
																		? true
																		: false
																}
																type={
																	formData[index]
																		.isCurrent &&
																	field.label == 'End Date'
																		? 'text'
																		: field.type
																}
																label={field.label}
																variant="outlined"
																style={{ width: '100%' }}
																name={fieldId}
																InputProps={{
																	inputProps: { ...field },
																	style: {
																		color: '#000',
																		fontSize: '0.9rem',
																		fontWeight: 400,
																		fontFamily:
																			"'Inter', sans-serif",
																	},
																}}
																InputLabelProps={{
																	style: {
																		color:
																			'rgba(0, 0, 0, 0.75)',
																		fontSize: '0.9rem',
																		fontWeight: 400,
																		fontFamily:
																			"'Inter', sans-serif",
																	},
																}}
																value={
																	formData[index][
																		fieldId as keyof MentorExperienceFormFields
																	]
																}
																onChange={(e) =>
																	updateFormData(
																		fieldId,
																		e.target.value,
																		formData[index].id
																	)
																}
															/>
														) : field.type === 'text' ? (
															<TextField
																//@ts-ignore
																required={field.required}
																disabled={field.disabled}
																type={field.type}
																id="outlined-basic"
																label={field.label}
																variant="outlined"
																style={{ width: '100%' }}
																name={formData[index].id}
																InputProps={{
																	// inputProps: { ...args },
																	style: {
																		color: '#000',
																		fontSize: '0.9rem',
																		fontWeight: 400,
																		fontFamily:
																			"'Inter', sans-serif",
																	},
																}}
																InputLabelProps={{
																	style: {
																		color:
																			'rgba(0, 0, 0, 0.75)',
																		fontSize: '0.9rem',
																		fontWeight: 400,
																		fontFamily:
																			"'Inter', sans-serif",
																	},
																}}
																value={
																	formData[index][
																		fieldId as keyof MentorExperienceFormFields
																	]
																}
																onChange={(e) =>
																	updateFormData(
																		fieldId,
																		e.target.value,
																		formData[index].id
																	)
																}
															/>
														) : field.type ===
														  'checkbox' ? (
															<FormControlLabel
																control={
																	<Checkbox
																		checked={
																			formData[index]
																				.isCurrent
																		}
																	/>
																}
																label="Is Present?"
																labelPlacement="start"
																onClick={() => {
																	formData[
																		index
																	].isCurrent =
																		!formData[index]
																			.isCurrent;
																	setFormData([
																		...formData,
																	]);
																}}
															/>
														) : field.type === 'select' ? (
															<FormControl fullWidth>
																<InputLabel
																	required
																	// disabled={disabled || isProfileLocked}
																	sx={{
																		color:
																			'rgba(0, 0, 0, 0.75)',
																		fontSize: '0.9rem',
																		fontWeight: 400,
																		fontFamily:
																			"'Inter', sans-serif",
																	}}
																>
																	{field.label}
																</InputLabel>
																<Select
																	required
																	// disabled={disabled || isProfileLocked}
																	// value={value}
																	value={
																		formData[index][
																			fieldId as keyof MentorExperienceFormFields
																		]
																	}
																	name={formData[index].id}
																	label={field.label}
																	sx={{
																		color: '#000',
																		fontSize: '0.9rem',
																		fontWeight: 400,
																		fontFamily:
																			"'Inter', sans-serif",
																	}}
																	onChange={(e) => {
																		updateFormData(
																			fieldId,
																			e.target
																				.value as string,
																			formData[index].id
																		);
																	}}
																>
																	{
																		//@ts-ignore
																		field.items &&
																			//@ts-ignore
																			field.items.map(
																				//@ts-ignore
																				(item, idx) => (
																					<MenuItem
																						key={idx}
																						value={item}
																					>
																						{item}
																					</MenuItem>
																				)
																			)
																	}
																</Select>
															</FormControl>
														) : null}
														{/* <Fields
														field={field}
														idx={idx}
														fieldId={fieldId}
														updateFormData={updateFormData}
														formData={formData[index]}
														isProfileLocked={
															isProfileLocked
														}
														setFormData = {setFormData}
												/> */}
													</>
												)
											)}
										</div>
										<Button
											className="profile-submit"
											type="submit"
											style={{ marginTop: '1.25em' }}
										>
											Update
										</Button>
									</>
								)}
							</div>
						);
					});
				})}
		</form>
	);
};

export default AllExperiences;
