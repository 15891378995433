import { Box, Dialog } from '@mui/material';
import React from 'react';

function ConfirmGmeetModal({
	confirmGmeet,
	handleCloseGmeet,
	handleConfirmGmeet,
}: any) {
	return (
		<div>
			<Dialog
				open={confirmGmeet}
				onClose={handleCloseGmeet}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ padding: '1em' }}
			>
				<Box sx={{ padding: '1em' }}>
					<div className="comfirm-meet-text">
						<h1>
							Please choose Gmeet only if you cannot join
							using Internal Zoom.{' '}
						</h1>
						<p>
							<span className="note-text">Note:</span> You
							cannot join by clicking on 'Join Now' button.
						</p>
					</div>
					<div className="comfirm-meet-btns">
						<button
							className="confirm-yes"
							onClick={handleConfirmGmeet}
						>
							Proceed
						</button>
						<button
							className="confirm-no"
							onClick={handleCloseGmeet}
						>
							Cancel
						</button>
					</div>
				</Box>
			</Dialog>
		</div>
	);
}

export default ConfirmGmeetModal;
