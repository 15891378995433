import React from 'react';

const JobStatusBox = ({
	isHold,
	isRejected,
	endAt,
	eligibility,
	rejectedReason,
	isHoldReason,
	stageNotes,
}: any) => {
	const getjobStatus = (
		isHold: boolean,
		isRejected: boolean,
		endAt: Date | null
	) => {
		let cssText = 'isEligible';
		let message =
			stageNotes.length === 0
				? 'THIS DRIVE IS ONGOING.'
				: stageNotes;
		if (endAt) {
			cssText = 'isEnded';
			message =
				'This Drive Is Ended On ' +
				new Date(endAt).toDateString();
		}
		if (isRejected) {
			cssText = 'isNotEligible';
			message = 'You are rejected from this drive.';
		}
		if (isHold) {
			cssText = 'isOnHold';
			message =
				'This Drive Is Currently On Hold.' +
				' ' +
				isHoldReason;
		}
		return {
			message: message,
			cssText: cssText,
		};
	};

	return (
		<div
			className={`info-box ${
				getjobStatus(isHold, isRejected, endAt).cssText
			}`}
		>
			<h3>
				{getjobStatus(isHold, isRejected, endAt).message}
			</h3>
			{eligibility === false && (
				<p
					dangerouslySetInnerHTML={{
						__html: rejectedReason,
					}}
				></p>
			)}
		</div>
	);
};

export default JobStatusBox;
