import React from 'react';

const JourneyCustomCard = ({
	cardData,
	cardCatgory,
}: any) => {
	if (cardCatgory === 'Completed') {
		return (
			<div className="module-journey-card-container">
				<div className="card-header">
					<h2>{cardData.title}</h2>
				</div>
				<div className="card-body">
					<p>{cardData.description}</p>
				</div>
			</div>
		);
	} else if (cardCatgory === 'Upcoming') {
		return (
			<div className="module-journey-card-container">
				<div className="card-header">
					<h2>{cardData.title}</h2>
				</div>
				<div className="card-body">
					<p>{cardData.description}</p>
				</div>
			</div>
		);
	} else if (cardCatgory === 'Ongoing') {
		return (
			<div className="module-journey-card-container">
				<div className="card-header">
					<h2>{cardData.title}</h2>
				</div>
				<div className="card-body">
					<p>{cardData.description}</p>
				</div>
			</div>
		);
	} else {
		//upcoming
		return (
			<div className="module-journey-card-container">
				<div className="card-header">
					<h2>{cardData.title}</h2>
				</div>
				<div className="card-body">
					<p>{cardData.description}</p>
				</div>
			</div>
		);
	}
};

export default JourneyCustomCard;
