import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TabPanel from '../shared/TabPanel';
import BookSessionModalBox from './BookSessionModalBox'; // Renamed component
import YourSessionModalBox from './YourSessionModalBox';
import {
	PrevMentor,
	PrevMentors,
} from './MentorshipSessionBookingBox';

interface Session {
	sessionTypeId: string;
	bookSessionTag: string;
	bookSessionAvailability: string;
	lastAttemptDate: string;
	feedbackStatus: string;
	lastScore: number | string;
	studentFeedbackLink: string;
	sessionType: string;
}

interface SessionDetail {
	sessionObject: {
		sessionTag: string;
		sessionDate: string;
	};
	sessionTag: string;
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	totalScore: string | number;
	studentFeedbackLink: string;
	isAllowedToJoin: boolean;
	mentorFeedback: MentorFeedback | null;
	recordings: string[];
	sessionId: string;
	sessionTypeId: string;
	mentorName: string;
	mentorPhone: string;
}

interface MentorFeedback {
	createdAt: string;
	[key: string]: string | MentorQuestionFeedback | null;
}

interface MentorQuestionFeedback {
	[key: string]: string | MentorQuestionAnswer | null;
}

interface MentorQuestionAnswer {
	[key: string]: string;
}

interface SessionData {
	modalData: {
		allSessions: SessionDetail[];
		isEligibleToBookSession: boolean;
		prevMentors: PrevMentors;
		isMentorship: boolean;
		sessionTypeName: string;
		numberOfCancellationsAllowed: number;
		numberOfSessionsCancelledOrRescheduledTillNow: number;
	};
	typeBtn: string;
	setOpen: (open: boolean) => void;
}

const SessionModalBox = ({
	modalData,
	typeBtn,
	setOpen,
}: SessionData) => {
	console.log('modalData', modalData);

	const [tabData, setTabData] = useState<
		Array<{
			keyId: string;
			title: string;
			element: JSX.Element;
			show: boolean;
			disabled: boolean;
		}>
	>([]);

	const [activeTab, setActiveTab] = useState(0);

	const handleYourSessionTabChange = (tabIndex: number) => {
		console.log('stateForRescheduleEnableTab', tabIndex);
		setActiveTab(tabIndex);
	};

	const { allSessionData } = useSelector(
		(state: any) => state.session
	);

	const SessionState = useSelector(
		(state: any) => state.session
	);

	const { stateForRescheduleEnableTab } = SessionState;

	console.log(
		'stateForRescheduleEnableTab',
		allSessionData
	);

	useEffect(() => {
		setTabData([
			{
				keyId: nanoid(),
				title: !stateForRescheduleEnableTab
					? 'Book Session'
					: 'Reschedule Session',
				element: (
					<BookSessionModalBox
						bookSessionData={modalData}
						setOpen={setOpen}
					/>
				),
				show: true,
				disabled:
					!modalData?.isEligibleToBookSession ||
					allSessionData?.restrictUserSessions,
			},
			{
				keyId: nanoid(),
				title: 'Your sessions',
				element: (
					<YourSessionModalBox
						sessionData={modalData}
						onYourSessionTabChange={
							handleYourSessionTabChange
						}
						setOpen={setOpen}
					/>
				),
				show: true,
				disabled: false,
			},
		]);
	}, [
		modalData,
		stateForRescheduleEnableTab,
		allSessionData,
	]);

	const [defaultVal, setDefaultVal] = useState(0);

	useEffect(() => {
		if (stateForRescheduleEnableTab) {
			setDefaultVal(activeTab);
		} else {
			setDefaultVal(typeBtn === 'BOOK SESSION' ? 0 : 1);
		}
	}, [stateForRescheduleEnableTab]);

	if (modalData === null) {
		return null;
	} else {
		return (
			<div className="session-modal-box-wrapper">
				<div className="session-type">
					{modalData?.isMentorship
						? 'Mentorship Session'
						: 'Mock Session'}
				</div>
				<div className="session-name">
					{modalData?.sessionTypeName}
				</div>
				<hr className="hr" />
				<div className="session-modal-tab-box">
					<TabPanel
						tabData={tabData}
						defaultValue={defaultVal}
						onTabChange={handleYourSessionTabChange}
						// defaultIndexToView={

						// 		? activeTab
						// 		: defaultVal
						// }
					/>
					<div className="line-style-right"></div>
				</div>
			</div>
		);
	}
};

export default SessionModalBox;
