import {
	Button,
	Dialog,
	DialogTitle,
	Input,
	Rating,
} from '@mui/material';
import { useState } from 'react';
import StarIcon from '@mui/icons-material/Star';
import { notifyError } from '../../notification';
import { services } from '../../../apis';

const ChatFeedbackPopUp = ({
	open,
	sessionId,
	handleCloseChatFeedback,
}: any) => {
	const [rating, setRating] = useState<number | null>(null);
	const [chatFeedbackText, setChatFeedbackText] =
		useState<string>('');

	const getLabelText = (rating: number) => {
		return `${rating} Star${rating !== 1 ? 's' : ''}`;
	};

	const handleSubmit = async () => {
		console.log('ratingValue', rating);
		if (rating == null) {
			return notifyError('Please enter your rating');
		} else {
			const data: any =
				await services.userService.chatFeedback(
					sessionId,
					rating,
					chatFeedbackText
				);
			if (data && data.statusCode)
				notifyError(data.message);
			else {
				handleCloseChatFeedback();
			}
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleCloseChatFeedback}
			className="chat-feedback-modal"
		>
			<h4 className="modal-title">
				Chat Interaction Feedback
			</h4>

			<p>Please enter your recent chat feedback....</p>

			<div className="chat-feedback-block">
				<h4 className="feedback-title">
					Feedback Rating &nbsp;{' '}
				</h4>
				<Rating
					name="hover-feedback"
					size="large"
					value={rating}
					getLabelText={getLabelText}
					onChange={(event, newValue) =>
						setRating(newValue)
					}
					emptyIcon={
						<StarIcon
							style={{ opacity: 0.55 }}
							fontSize="inherit"
						/>
					}
				/>
				<br />
				<Input
					onChange={(e: any) =>
						setChatFeedbackText(e.target.value)
					}
					value={chatFeedbackText}
					placeholder="Please Enter Your Chat Feedback"
					style={{ width: '80%', margin: '1em auto' }}
				/>
			</div>
			<div style={{ marginTop: '10px' }}>
				<Button
					style={{ width: '100%' }}
					variant="contained"
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</div>
		</Dialog>
	);
};

export default ChatFeedbackPopUp;
