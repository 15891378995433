import React from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import slugify from 'slugify';
import { Link } from 'react-router-dom';
import { encryptData } from '@acciojob/event-analytics';

const getPaddingLeft = (level: any, type: string) => {
	if (level == 0) return 8;

	const paddingLeft = level * 20;
	//   if (type === 'file') paddingLeft += 20
	return paddingLeft;
};

const getNodeLabel = (node: any) =>
	node.path.split('.')[0].toLowerCase();

const TreeNode = (props: any) => {
	const {
		node,
		getChildNodes,
		level,
		onToggle,
		onNodeSelect,
		selectedFolder,
		selectedFile,
		highlightFolder,
	} = props;
	//   console.log("idhar dikh rha hai node",node)

	return (
		<React.Fragment>
			{node.type === 'file' &&
			node.isRoot === true ? null : node.type === 'file' ? (
				<Link
					to={
						`/notes/` +
						slugify(node.path.split('.')[0], {
							lower: true,
							strict: true,
						})
					}
					event-analytics={
						'26b6e53b-ac45-45f7-8bbb-974ebe7ab92c'
					}
					data-config={encryptData(
						JSON.stringify({
							name: `${getNodeLabel(node)}/${slugify(
								node.path.split('.')[0],
								{
									lower: true,
									strict: true,
								}
							)}`,
						})
					)}
				>
					<div
						style={{
							paddingLeft: getPaddingLeft(level, node.type),
							background:
								selectedFile.id === node.id
									? '#e9e8e8'
									: 'initial',
							margin: '6px 0',
						}}
						className="notes_label_div"
					>
						<span
							role="button"
							className="notes_label"
							style={{
								color:
									selectedFile.id === node.id
										? 'rgb(47 128 236)'
										: '#606770',
								// fontWeight: selectedFile.id === node.id ? 700 : 600,
							}}
						>
							{getNodeLabel(node)}
						</span>
					</div>
				</Link>
			) : (
				<div
					style={{
						paddingLeft: getPaddingLeft(level, node.type),
						background:
							selectedFile.id === node.id
								? '#e9e8e8'
								: 'initial',
					}}
					className="foldernode"
					onClick={() => {
						onToggle(node);
						onNodeSelect(node);
					}}
				>
					<span
						role="button"
						className="notes_label"
						style={{
							color:
								selectedFile.id === node.id
									? 'rgb(47 128 236)'
									: '#606770',
							fontWeight:
								selectedFile.id === node.id ? 700 : 600,
						}}
					>
						{getNodeLabel(node)}
					</span>
					<div>
						{node.type === 'folder' &&
							(node.isOpen ? (
								<KeyboardArrowDownIcon />
							) : (
								<KeyboardArrowRightIcon />
							))}
					</div>
				</div>
			)}

			{node.isOpen &&
				getChildNodes(node).map(
					(childNode: object, i: any) => (
						<TreeNode
							{...props}
							node={childNode}
							level={level + 1}
							key={i}
						/>
					)
				)}
		</React.Fragment>
	);
};

export default TreeNode;
