import { encryptData } from '@acciojob/event-analytics';
import { useEffect } from 'react';
import { isMainCourseUser } from '../../utils/events';

type QuestionNavigationProps = {
	qnsArray: Array<{
		id: string;
	}>;
	questionId: string;
	setQuestionId: (id: string) => void;
	setQuestionNumber?: (num: number) => void;
};

export const QnsNavigation = (
	props: QuestionNavigationProps
) => {
	const {
		qnsArray,
		questionId,
		setQuestionId,
		setQuestionNumber,
	} = props;

	useEffect(() => {
		setTimeout(() => {
			const element = document.getElementById(
				'selected-question'
			);
			if (element) {
				element.scrollIntoView({
					behavior: 'smooth',
				});
			}
		}, 200);
	}, []);

	return (
		<div className={'qns-nav-column'}>
			<ul>
				{qnsArray.map((el: any, index: number) => (
					<li
						key={el.id}
						className={`${
							el.id === questionId ? 'active' : ''
						}`}
						id={
							el.id === questionId && index > 14
								? 'selected-question'
								: ''
						}
						onClick={() => {
							setQuestionId(el.id);
							if (setQuestionNumber)
								setQuestionNumber(index + 1);
						}}
						event-analytics={
							isMainCourseUser()
								? '2014650f-246f-47ae-bb1b-2f380401b97b'
								: ''
						}
						data-config={encryptData(
							JSON.stringify({
								name: questionId,
							})
						)}
					>
						{index + 1}
					</li>
				))}
			</ul>
		</div>
	);
};
