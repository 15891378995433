import { Tooltip } from '@mui/material';
import { BiBuildings } from 'react-icons/bi';
import { IoInformationCircleSharp } from 'react-icons/io5';
import { ApplicationCardData } from '../../apis/JobPortalService';
import mapPin from '../../assets/images/icons/mapPin.svg';
import money from '../../assets/images/icons/money.svg';
import positions from '../../assets/images/icons/positions.svg';
import { PopupState } from '../../pages/jobportal/JobPortal';
import AccioButton from '../elements/Button';

interface ApplicationCardProps {
	data: ApplicationCardData;
	setJobDetailsPopup: (newState: PopupState) => void;
}

const ApplicationCard = ({
	data,
	setJobDetailsPopup,
}: ApplicationCardProps) => {
	const {
		companyName,
		location,
		minCtcOffered,
		maxCtcOffered,
		noOfPositions,
		currentStage,
		jobCardId,
	} = data;

	const locationString =
		location.constructor === Array
			? location.join(', ')
			: '';

	let stageStyle: {
		color: string;
		backgroundColor: string;
		border: string;
		borderColor: string;
	} = {
		color: '#2f80ed',
		backgroundColor: '#f0f8ff',
		border: '1px solid',
		borderColor: '#2f80ed',
	};
	if (currentStage.includes('rejected')) {
		stageStyle = {
			color: '#e74c3c',
			borderColor: '#e74c3c',
			backgroundColor: '#ffefef',
			border: '1px solid #e74c3c',
		};
	} else if (currentStage.includes('selected')) {
		stageStyle = {
			color: '#12b76a',
			borderColor: '#12b76a',
			backgroundColor: '#f8fff5',
			border: '1px solid #12b76a',
		};
	} else if (
		currentStage.includes('hold') ||
		currentStage.includes('ended')
	) {
		stageStyle = {
			color: '#ff940a',
			borderColor: '#ff940a',
			backgroundColor: '#fffaf0',
			border: '1px solid #ff940a',
		};
	}

	const updatedCurrentStage = currentStage.includes(
		'rejected'
	)
		? 'Current Stage - ' + currentStage
		: currentStage;

	return (
		<div className="application-card">
			<div className="header-area">
				<Tooltip
					title={
						currentStage.includes('rejected')
							? currentStage
							: ''
					}
				>
					<p
						style={stageStyle}
						className={
							currentStage.includes('rejected')
								? 'rejected-stage'
								: ''
						}
					>
						{currentStage}
					</p>
				</Tooltip>
				<div className="company-overview">
					<h3>
						<BiBuildings />
						{companyName}
					</h3>
				</div>
				{/* <div className="deadline"></div> */}
			</div>
			<div className="body-area">
				{noOfPositions && (
					<div>
						<img src={positions} /> {noOfPositions} Open
						Positions
					</div>
				)}
				{location && (
					<div
						className={
							location.length > 1 ? 'custom-tooltip' : ''
						}
						data-tooltip={locationString}
					>
						<img src={mapPin} />
						{location.length > 1
							? 'Multiple Locations'
							: location[0]}
					</div>
				)}
				{minCtcOffered && maxCtcOffered && (
					<div>
						<img src={money} />{' '}
						{minCtcOffered +
							'LPA - ' +
							maxCtcOffered +
							'LPA'}
					</div>
				)}

				<AccioButton
					action={() => {
						setJobDetailsPopup({
							isOpen: true,
							identifier: jobCardId + 'source:applications',
							notInterested: false,
						});
					}}
					title="More details"
					variant="outline"
					icon={
						<IoInformationCircleSharp
							style={{ fontSize: '15px' }}
						/>
					}
					customClass="jobportal-application-card-btn"
					iconPosition={'left'}
				/>
			</div>
		</div>
	);
};

export default ApplicationCard;
