import {
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { getSectionalFeedbackPopupThunk } from './allFeedbackModal.thunk';

type LectureFeebackType = {};

export enum SectionFeedbackEnum {
	CONTEST_SECTION = 'CONTEST_SECTION',
}

export type ContestFeedbackType = {
	contestId: string;
	showPopup: boolean;
	data: {
		popUpHeaderText: string;
		popupType: string;
		showPopup: boolean;
		feedbackType: SectionFeedbackEnum;
		ratings: Array<FeedbackRating>;
		lowestRatingText: string;
		highestRatingText: string;
		lessThanFour: string;
		moreThanThree: string;
		ctas: Array<FeedbackCTA>;
	};
};

export type FeedbackRating = {
	rating: number;
	nextPopupId: string;
};

export type FeedbackCTA = {
	ctaType: string;
	ctaId: string;
	ctaText: string;
	actions: Array<Action>;
};

type Action = {
	actionType: string;
	actionUrl: string;
};

interface AllFeedbackModalState {
	selectedRatingVal: number | null;
	allFeedbackModalOpen: boolean;
	nextPopupId: string | null;
	sectionalFeedbackData: ContestFeedbackType | null;
	sectionalFeedbackLoading: boolean;
}

const initialState: AllFeedbackModalState = {
	selectedRatingVal: null,
	allFeedbackModalOpen: true,
	nextPopupId: null,
	sectionalFeedbackData: null,
	sectionalFeedbackLoading: false,
};

const allFeedbackModal = createSlice({
	name: 'allFeedbackModal',
	initialState,
	reducers: {
		// resetStates: (state) => {
		// },

		setSelectedRatingVal: (state, action) => {
			state.selectedRatingVal = action.payload;
		},

		setAllFeedbackOpenModal: (state, action) => {
			state.allFeedbackModalOpen = action.payload;
		},
		setNextPopupId: (state, action) => {
			state.nextPopupId = action.payload;
		},
	},
	extraReducers: (builder) => {
		// builder
		// 	.addCase(<THUNK>.pending, (state) => {
		// 	})
		// 	.addCase(
		// 		<THUNK>.fulfilled,
		// 		(state, action: PayloadAction<any>) => {
		// 		}
		// 	)
		// 	.addCase(<THUNK>.rejected, (state) => {
		// 	});
		builder
			.addCase(
				getSectionalFeedbackPopupThunk.pending,
				(state) => {
					state.sectionalFeedbackData = null;
					state.sectionalFeedbackLoading = true;
				}
			)
			.addCase(
				getSectionalFeedbackPopupThunk.fulfilled,
				(state, action: PayloadAction<any>) => {
					state.sectionalFeedbackData = action.payload;
					state.sectionalFeedbackLoading = false;
				}
			)
			.addCase(
				getSectionalFeedbackPopupThunk.rejected,
				(state) => {
					state.sectionalFeedbackData = null;
					state.sectionalFeedbackLoading = false;
				}
			);
	},
});

//Exporting Actions
export const {
	setSelectedRatingVal,
	setAllFeedbackOpenModal,
	setNextPopupId,
} = allFeedbackModal.actions;

export default allFeedbackModal.reducer;
