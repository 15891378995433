import { Box, Dialog } from '@mui/material';
import React from 'react';

function CancelSessionModal({
	openCancel,
	handleCloseCancelModal,
	handleSessionCancel,
}: any) {
	return (
		<div>
			<Dialog
				open={openCancel}
				onClose={handleCloseCancelModal}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				sx={{ padding: '1em' }}
			>
				<Box sx={{ padding: '1em' }}>
					<div className="comfirm-meet-text">
						<h1>Do you want to cancel session for sure?</h1>
						{/* <p><span className='note-text'>Note:</span> You cannot join by clicking on 'Join Now' button.</p> */}
					</div>
					<div className="comfirm-meet-btns">
						<button
							className="confirm-yes"
							onClick={handleSessionCancel}
						>
							Proceed
						</button>
						<button
							className="confirm-no"
							onClick={handleCloseCancelModal}
						>
							Close
						</button>
					</div>
				</Box>
			</Dialog>
		</div>
	);
}

export default CancelSessionModal;
