import { color } from 'echarts';
import React from 'react';

const ReferralStepCard = ({
	color,
	iconSource,
	text1,
	text2,
}: any) => {
	return (
		<div
			className={`re-how-it-works-steps-card re-${color}-steps-card`}
		>
			<div className="re-how-it-works-steps-card-container">
				<div
					className={`re-how-it-works-steps-card-icon re-${color}-icon-container`}
				>
					<img
						src={iconSource}
						alt="icon"
					/>
				</div>
				<div
					className={`re-how-it-works-steps-card-title re-${color}-text`}
				>
					{text1}
				</div>
				<div
					className={`re-how-it-works-steps-card-subtitle re-${color}-text`}
				>
					{text2}
				</div>
			</div>
		</div>
	);
};

export default ReferralStepCard;
