import { nanoid } from 'nanoid';
import { FC, useEffect, useState } from 'react';
import { SoftSkillsModuleDetailsType } from '../../redux/softskills/softskills.type';
import TabPanel from '../shared/TabPanel';
import AssignmentCards from './AssignmentCards';
import BreakoutSessionCard from './BreakoutSessionCard';
import FeedbackSection from './FeedbackSection';
import LiveClassSection from './LiveClassSection';
import ResourseLinkItems from './ResourseLinkItems';
import VideoContentSection from './VideoContentSection';
import {
	AiOutlineLink,
	AiOutlineVideoCamera,
} from 'react-icons/ai';
import { GoPeople } from 'react-icons/go';
import { FiTarget } from 'react-icons/fi';
import { BsPlayBtnFill } from 'react-icons/bs';
import { HiOutlineDocumentText } from 'react-icons/hi2';
import {
	RootState,
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import { SoftskillsActions } from '../../redux/softskills/softskills.slice';

type SoftskillsPropsType = SoftSkillsModuleDetailsType & {
	selectedModuleId: string;
	defaultTabValue: number;
};

const CourseContentRight: FC<SoftskillsPropsType> = ({
	selectedModuleId,
	videoContent,
	liveClasses,
	assignments,
	breakoutSession,
	readingContent,
	feedback,
	defaultTabValue,
}) => {
	const [courseTabs, setCourseTabs] = useState<any[]>([]);
	const { selectedModuleDetails } = useAppSelector(
		(state: RootState) => state.softskills
	);

	const dispatch = useAppDispatch();

	useEffect(() => {
		const temp = [];
		if (videoContent.length > 0) {
			temp.push({
				keyId: nanoid(),
				title: (
					<span className="tab-text">
						<BsPlayBtnFill size={17} />
						<p>Video Content</p>
					</span>
				),
				element: (
					<VideoContentSection
						videoContent={videoContent}
					/>
				),
				show: true,
				tabBoxClass: '',
			});
		}
		if (Object.keys(liveClasses).length > 0) {
			temp.push({
				keyId: nanoid(),
				title: (
					<span className="tab-text">
						<FiTarget size={17} />
						<p>Live Class</p>
					</span>
				),
				element: (
					<LiveClassSection
						ongoingLiveClasses={
							liveClasses.ongoingLiveClasses
						}
						pastLiveClasses={liveClasses.pastLiveClasses}
						upcomingLiveClasses={
							liveClasses.upcomingLiveClasses
						}
					/>
				),
				show:
					liveClasses.ongoingLiveClasses.length > 0 ||
					liveClasses.pastLiveClasses.length > 0,
			});
		}
		if (assignments.length > 0) {
			temp.push({
				keyId: nanoid(),
				title: (
					<span className="tab-text">
						<HiOutlineDocumentText size={18} />
						<p>Assignments</p>
					</span>
				),
				element: (
					<AssignmentCards assignments={assignments} />
				),
				show: true,
			});
		}
		if (breakoutSession.length > 0) {
			temp.push({
				keyId: nanoid(),
				title: (
					<span className="tab-text">
						<GoPeople size={19} />
						<p>Breakout Session</p>
					</span>
				),
				element: (
					<BreakoutSessionCard
						id={breakoutSession[0].id}
						selectedModuleId={selectedModuleId}
						title={breakoutSession[0].title}
						description={breakoutSession[0].description}
						isLive={breakoutSession[0].isLive}
						isRegistered={breakoutSession[0].isRegistered}
						totalSeats={breakoutSession[0].totalSeats}
						occupiedSeats={breakoutSession[0].occupiedSeats}
						start_time={breakoutSession[0].start_time}
						end_time={breakoutSession[0].end_time}
					/>
				),
				show: true,
			});
		}
		if (readingContent.length > 0) {
			temp.push({
				keyId: nanoid(),
				title: (
					<span className="tab-text">
						<AiOutlineLink size={19} />
						<p>Resource Links</p>
					</span>
				),
				element: (
					<ResourseLinkItems
						resourceLinks={readingContent}
					/>
				),
				show: true,
			});
		}
		if (
			videoContent.length > 0 &&
			videoContent[0].title.toLocaleLowerCase() !==
				'introduction'
		) {
			temp.push({
				keyId: nanoid(),
				title: (
					<span className="tab-text">
						<AiOutlineVideoCamera size={19} />
						<p>Feedback</p>
					</span>
				),
				element: (
					<FeedbackSection
						feedback={feedback}
						selectedModuleId={selectedModuleId}
					/>
				),
				show: true,
			});
		}
		setCourseTabs(temp);
	}, [
		videoContent,
		liveClasses,
		assignments,
		breakoutSession,
		readingContent,
		feedback,
	]);

	const handleTabChange = (value: number) => {
		dispatch(
			SoftskillsActions.setSelectedModuleDetails({
				activeTabIndex: value,
			})
		);
	};

	return (
		<TabPanel
			tabData={courseTabs}
			defaultValue={
				selectedModuleDetails.activeTabIndex ||
				defaultTabValue
			}
			onTabChange={handleTabChange}
		/>
	);
};

export default CourseContentRight;
