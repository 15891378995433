// DON'T DARE TO CHANGE THIS FILE,
// UNLESS YOU ARE LOOKING FORWARD
// FOR AN ONE ON ONE WITH CTO !!

// PROD ENDPOINT
// ROOT: 'https://acciojob-backend-eobnd7jx2q-el.a.run.app',

export const ENDPOINTS = {
	ROOT: 'https://acciojob-backend-eobnd7jx2q-el.a.run.app',
};
