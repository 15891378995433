import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { useAppSelector } from '../../redux/store';
import { Loader } from '@acciojob/loader';

interface ModalProps {
	open: boolean;
	handleClose: any;
	startTest: () => void;
}

const StartAssessmentModal: React.FC<ModalProps> = ({
	open,
	handleClose,
	startTest,
}) => {
	const { loading } = useAppSelector(
		(state) => state.aiStudentAssessment
	);

	return (
		<React.Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-desc"
				open={open}
				onClose={handleClose}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Sheet
					variant="outlined"
					sx={{
						maxWidth: 500,
						borderRadius: 'md',
						p: 3,
						boxShadow: 'lg',
					}}
				>
					<ModalClose
						variant="plain"
						sx={{ m: 1 }}
					/>
					<Typography
						component="h2"
						id="modal-title"
						level="h4"
						textColor="inherit"
						fontWeight="lg"
						mb={1}
					>
						Take Assessment !!
					</Typography>
					{!loading ? (
						<>
							<Typography
								id="modal-desc"
								textColor="text.tertiary"
							>
								This is just an revision assessment that
								will help you in understanding your
								weaknesses and strengths. In every question
								you will be given a limited amount of time.
								So, make sure to answer all the questions
								correctly. Do not refresh the page while
								giving the test.
							</Typography>
							<Button
								sx={{ marginTop: '20px' }}
								onClick={startTest}
								variant="outlined"
								color="primary"
							>
								Start Test
							</Button>
						</>
					) : (
						<Loader />
					)}
				</Sheet>
			</Modal>
		</React.Fragment>
	);
};

export default StartAssessmentModal;
