import React, { useEffect, useState } from 'react';
import { Modal } from '@mui/material';
// import '../styles/sessionFeedbackmodal.scss';
const SessionFeedbackModal = ({
	open,
	handleClose,
	questions,
}: {
	open: boolean;
	handleClose: any;
	questions: any;
}) => {
	const [weakPoints, setWeakPoints] = useState<string[]>(
		[]
	);
	const [strongPoints, setStrongPoints] = useState<
		string[]
	>([]);
	const [codeProf, setCodeProf] = useState<number>(0);
	const [logicalAb, setLogicalAb] = useState<number>(0);
	const [commPoints, setCommPoints] = useState<number>(0);
	const [explanPoints, setExplainPoints] =
		useState<number>(0);
	const [feedback, setFeedback] = useState<string>('');
	const [isDoubtSession, setIsDoubtSession] =
		useState<boolean>(false);
	const segregator = () => {
		const calcstrongPoints = [];
		const calcweakPoints = [];
		let hintcount = 0;
		for (const entry in questions.mentorFeedback) {
			const key: string = entry;
			const value = questions.mentorFeedback[entry];
			if (
				key === 'Did students use comments ? ' ||
				key === 'Was the student using Comments?'
			) {
				if (value === 'No')
					calcweakPoints.push(
						"Student didn't use any comments throughout the code."
					);
				else
					calcstrongPoints.push(
						'Student was using comments in the code which made it more understandable.'
					);
			}
			if (
				key ===
					'Did student do the proper variable naming ? ' ||
				key === 'Did the student do Variable Naming?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student used proper variable naming in the code.'
					);
				else
					calcweakPoints.push(
						"Student didn't used proper variable names."
					);
			}
			if (
				key ===
				'Did student write the complete working code ? '
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student wrote the complete working code during the interview.'
					);
				else
					calcweakPoints.push(
						"Student didn't wrote the complete working code."
					);
			}
			if (
				key ===
					'Did student used functions and write modular code? ' ||
				key ===
					'Was the student using Functions and writing Modular code?' ||
				key ===
					'Was the student using Functions and writing modular code?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student used functions and wrote modular code.'
					);
				else
					calcweakPoints.push(
						"Student didn't used functions and didn't write modular code."
					);
			}
			if (
				key ===
				'Did student attempt to write the code of question 1 ?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student was able to write the code for Question 1.'
					);
				else
					calcweakPoints.push(
						'Student was not able to write code for Question 1.'
					);
			}
			if (
				key ===
				'Did student write the complete working code of Question 2 ? '
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student was able to write the code for Question 2.'
					);
				else
					calcweakPoints.push(
						'Student was able to write the code for Question 2.'
					);
			}
			if (
				key ===
				'Did student write 80% of the code ,but had some silly errors ?'
			) {
				if (value === 'No')
					calcstrongPoints.push(
						'Student wrote the complete code without any silly mistakes for Question 1.'
					);
				else
					calcweakPoints.push(
						'Student wrote 80% of the code and made some silly errors for Question 1.'
					);
			}
			if (
				key ===
				'Did student write 80% of the code of Question 2, but had some silly errors ?'
			) {
				if (value === 'No')
					calcstrongPoints.push(
						'Student wrote the complete code without any silly mistakes for Question 2.'
					);
				else
					calcweakPoints.push(
						'Student wrote 80% of the code and made some silly errors for Question 2.'
					);
			}
			if (
				key ===
				'Did the student discuss the most optimal Solution for the Question 2 and also discussed the brute force solution?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student discussed the most optimal solution for Question 2 and also discussed the brute force solution'
					);
				else
					calcweakPoints.push(
						"Student didn't discussed the most optimal solution for Question 2."
					);
			}
			if (
				key ===
				'Was the student not able to come up with most optimal solution but discussed a working solution/brute force for Question 2 ?'
			) {
				if (value === 'No')
					calcstrongPoints.push(
						'Student discussed the most optimal solution for Question 2 and also discussed the brute force solution'
					);
				else
					calcweakPoints.push(
						"Student didn't discussed the most optimal solution for Question 2."
					);
			}
			if (
				key ===
					'Was the student explaining while Coding?' ||
				key === 'Was the student explaining while coding?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student was explaing while coding.'
					);
				else
					calcweakPoints.push(
						'Student was not explaining while coding.'
					);
			}
			if (
				key ===
					'Did he submit the most optimal solution?' ||
				key ===
					'Did student submit the most optimal solution?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student submitted the most optimal solution.'
					);
				else
					calcweakPoints.push(
						"Student didn't submit the most optimal solution."
					);
			}
			if (
				key ===
				'Did he submit the most optimal solution for Q1?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student submitted the most optimal solution for Q1.'
					);
				else
					calcweakPoints.push(
						"Student didn't submit the most optimal solution for Q1."
					);
			}
			if (
				key ===
				'Did he submit the most optimal solution for Q2?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student submitted the most optimal solution for Q2.'
					);
				else
					calcweakPoints.push(
						"Student didn't submit the most optimal solution for Q2."
					);
			}
			if (
				key ===
					'Was the student writing Small Pseudo Code with discussing approach?' ||
				key ===
					'Was the student writing Small Pseudo Code with discussing Approach?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student was writing small pseudo code and discussing approach.'
					);
				else
					calcweakPoints.push(
						"Student didn't write any pseudo code while discussing."
					);
			}
			if (key === 'Was the code clean/modular ') {
				if (value === 'Yes')
					calcstrongPoints.push(
						"Student's code was clean and modular"
					);
				else
					calcweakPoints.push(
						"Student didn't write clean and modular code."
					);
			}
			if (key === 'Was the design part good enough ?') {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Design part was good enough.'
					);
				else
					calcweakPoints.push(
						'Design part was not at all good.'
					);
			}
			if (key === 'Was it good and functional enough?') {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Code was good and functional enough.'
					);
				else
					calcweakPoints.push(
						"Student didn't write functional code."
					);
			}
			if (
				key ===
				'Did he do something innovative/or an uncommon project?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student came up with an innovative uncommon project'
					);
				else
					calcweakPoints.push(
						"Student didn't come with innovative project idea and wrote a common one."
					);
			}
			if (
				key ===
				'Was the Beautification of (HTML,CSS) Design Satisfactory '
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Beautification of (HTML,CSS) design was satisfcatory.'
					);
				else
					calcweakPoints.push(
						'Beautification of (HTML,CSS) design was not satisfcatory.'
					);
			}
			if (key === 'Was it deployed online?') {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student deployed the project online.'
					);
				else
					calcweakPoints.push(
						"Student didn't deploy the code online."
					);
			}
			if (
				key ===
					'Was the Resume Introduction Satisfactory?' ||
				key === 'Was the Introduction Satisfactory?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						"Student's introduction was satisfactory."
					);
				else
					calcweakPoints.push(
						"Student's introduction was not satisfactory."
					);
			}
			if (
				key ===
				'Was the Resume Format &amp; Presentation Satisfactory? '
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Resume format and presentation was satisfactory.'
					);
				else
					calcweakPoints.push(
						'Resume format and presentation was not satisfactory.'
					);
			}
			if (
				key ===
				'How was the level of the Projects \nMajor Projects are like :  major/good project like involving some databases(management systems) ,ML projects, MERN stack project\nMinor Project are like : simple project like tic tac toe, sudoku, calculator  etc'
			) {
				if (
					value ===
					'Yes, both of the projects are major/good projects'
				)
					calcstrongPoints.push(
						'Both the projects were major projects involving some databases, ML projects, MERN stack project. '
					);
				if (
					value ===
					'Only 1 Project out of them was a major/good project'
				)
					calcstrongPoints.push(
						'Only 1 Project out of them was a major/good project involving some databases, ML projects, MERN stack project. '
					);
				if (
					value ===
					'Both the projects were simple projects.'
				)
					calcweakPoints.push(
						'None of the projects was a major/good project involving some databases, ML projects, MERN stack project. '
					);
			}

			if (
				key ===
					'How was the confidence of the student while answering this question?' ||
				key ===
					'How was the confidence of the student while answering this question? ' ||
				key ===
					'How was the confidence of the student while answering this question?  '
			) {
				if (value === 'Good to go') {
					calcstrongPoints.push(
						'Student was confidant while ansering the questions.'
					);
				}
				if (value === 'Average') {
					calcweakPoints.push(
						'Student needs to work on his/her confidance while ansering the questions.'
					);
				}
				if (value === 'Weak') {
					calcweakPoints.push(
						'Student was not at all confidant while ansering the questions.'
					);
				}
			}

			if (key === 'Communication of the candidate') {
				if (value === 'Good to go') {
					calcstrongPoints.push(
						"Student's communication skills were good."
					);
				}
				if (value === 'Average') {
					calcweakPoints.push(
						"Student's communication skills were average."
					);
				}
				if (value === 'Weak') {
					calcweakPoints.push(
						"Student's communication skills were not at all good."
					);
				}
			}

			if (
				key ===
				'How is the english communication skill of the student? '
			) {
				if (value === 'Good to go') {
					calcstrongPoints.push(
						"Student's communication skills were good. He/She was speaking english fluently."
					);
				}
				if (value === 'Average') {
					calcweakPoints.push(
						"Student's communication skills were good. But he/she was not able to speak english fluently."
					);
				}
				if (value === 'Weak') {
					calcweakPoints.push(
						"Student's communication skills were not at all good and not fluent."
					);
				}
			}

			if (
				key ===
				"How was the student's content of the answer?"
			) {
				if (value === 'Good to go') {
					calcstrongPoints.push(
						"Student's content of the answer was good to go."
					);
				}
				if (value === 'Average') {
					calcweakPoints.push(
						"Student's content of the answer was average."
					);
				}
				if (value === 'Weak') {
					calcweakPoints.push(
						"Student's was not able to write the content of the answer properly."
					);
				}
			}

			if (
				key === 'How will you overall rate the student? '
			) {
				if (value === 'Good to go') {
					calcstrongPoints.push(
						"Student's overall performance was good to go."
					);
				}
				if (value === 'Average') {
					calcweakPoints.push(
						"Student's overall performance was average."
					);
				}
				if (value === 'Weak') {
					calcweakPoints.push(
						"Student's overall performance was not good."
					);
				}
			}

			if (
				key === 'Project 1 : Was it deployed online?' ||
				key ===
					'Project 1 : Project Quality Was it deployed online?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 1 was deployed online.'
					);
				else
					calcweakPoints.push(
						'Project 1 was not deployed online.'
					);
			}

			if (
				key === 'Project 2 : Was it deployed online?' ||
				key ===
					'Project 2 : Project Quality Was it deployed online?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 2 was deployed online.'
					);
				else
					calcweakPoints.push(
						'Project 2 was not deployed online.'
					);
			}
			if (
				key ===
					'Project 1 : Was it a responsive website?' ||
				key ===
					'Project 1 : Project Quality Was it a responsive website?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 1 was a responsive website.'
					);
				else
					calcweakPoints.push(
						'Project 1 was not a responsive website.'
					);
			}
			if (
				key ===
					'Project 2 : Was it a responsive website?' ||
				key ===
					'Project 2 : Project Quality Was it a responsive website?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 2 was a responsive website.'
					);
				else
					calcweakPoints.push(
						'Project 2 was not a responsive website.'
					);
			}
			if (
				key === 'Was the Resume Introduction Satisfactory?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Resume introduction of the student was satisfactory.'
					);
				else
					calcweakPoints.push(
						'Student was not able to introduce himself/herself.'
					);
			}
			if (
				key ===
					'Project 1 : Was the design part good enough?' ||
				key ===
					'Project 1 : Project Quality Was the design part good enough?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 1 had a good overall design'
					);
				else
					calcweakPoints.push(
						"Project 1 didn't have a good overall design."
					);
			}
			if (
				key ===
					'Project 2 : Was the design part good enough?' ||
				key ===
					'Project 2 : Project Quality Was the design part good enough?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 2 had a good overall design'
					);
				else
					calcweakPoints.push(
						"Project 2 didn't have a good overall design."
					);
			}
			if (
				key ===
				'Was the Resume Format &amp; Presentation Satisfactory? '
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Resume format and presentation was satisfactory.'
					);
				else
					calcweakPoints.push(
						'Resume format and presentation was not satisfactory.'
					);
			}
			if (
				key ===
				'Project Quality : Was it having good number of functional features?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Projects were having a good number of functional features.'
					);
				else
					calcweakPoints.push(
						'Projects were not having a good number of functional features.'
					);
			}
			if (
				key ===
					'Project 1 : Was it having good number of functional features?' ||
				key ===
					'Project 1 : Project Quality Was it having good number of functional features?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 1 was having a good number of functional features.'
					);
				else
					calcweakPoints.push(
						'Project 1 was not having a good number of functional features.'
					);
			}
			if (
				key ===
					'Project 2 : Was it having good number of functional features?' ||
				key ===
					'Project 2 : Project Quality Was it having good number of functional features?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Project 2 was having a good number of functional features.'
					);
				else
					calcweakPoints.push(
						'Project 2 was not having a good number of functional features.'
					);
			}
			if (
				key ===
					'Project 1 : Did he do something innovative/or an uncommon project ?' ||
				key ===
					'Project 1 : Project Quality Did he do something innovative/or an uncommon project ?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student did something innovative in Project 1.'
					);
				else
					calcweakPoints.push(
						"Student didn't do anything innovative in Project 1."
					);
			}
			if (
				key ===
					'Project 2 : Did he do something innovative/or an uncommon project ?' ||
				key ===
					'Project 2 : Project Quality Did he do something innovative/or an uncommon project ?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student did something innovative in Project 2.'
					);
				else
					calcweakPoints.push(
						"Student didn't do anything innovative in Project 2."
					);
			}
			if (
				key ===
				'Did the student make any resume/project/intro changes since the last mock?'
			) {
				if (value === 'Yes')
					calcstrongPoints.push(
						'Student did make resume/project/intro changes since last mock.'
					);
				else
					calcweakPoints.push(
						"Student didn't make resume/project/intro changes since last mock."
					);
			}
			if (
				key ===
				'Mark this session as a Mentorship session(If a student’s performance was very bad and you end up solving his/her doubts)'
			) {
				if (value === 'Yes') {
					setIsDoubtSession(true);
				}
			}

			//Feedback
			if (
				key === 'Detailed feedback of the student' ||
				key === 'Feedback for student' ||
				key === 'Feedback for the student' ||
				key === 'Feedback for the student '
			) {
				setFeedback(value);
			}

			//Special point for weakness (Major in 1 or Minor in 2)
			if (
				key === 'Did student take hints for Question 1? ' ||
				key === 'Did he take hints for Q1 based code?' ||
				key === 'Did student took hints for Q1 based code?'
			) {
				if (value === 'Minor Hint' || value === 'Yes') {
					hintcount++;
				}
				if (value === 'Major Hint') hintcount += 2;
			}
			if (hintcount >= 2) {
				calcweakPoints.push(
					'Student took considerable hints while solving the questions.'
				);
				hintcount = 0;
			}
			if (
				key === 'Did student take hints for Question 2? ' ||
				key === 'Did he take hints for Q2 based code?' ||
				key === 'Did student took hints for Q2 based code?'
			) {
				if (value === 'Minor Hint' || value === 'Yes') {
					hintcount++;
				}
				if (value === 'Major Hint') hintcount += 2;
			}
			if (hintcount >= 2)
				calcweakPoints.push(
					'Student took considerable hints while solving the questions.'
				);
			if (
				key === 'Did he take hints for task based code?'
			) {
				if (
					value === 'Major Hint' ||
					value == 'Major hint' ||
					value === 'Major Hints'
				)
					calcweakPoints.push(
						'Student took major hint in task based code.'
					);
				if (
					value === 'Minor Hint' ||
					value == 'Minor hint' ||
					value === 'Minor Hints'
				)
					calcweakPoints.push(
						'Student took minor hint in task based code.'
					);
				if (
					value === 'No Hint' ||
					value === 'No hint taken' ||
					value === 'no hint' ||
					value === 'No Hints Taken'
				)
					calcstrongPoints.push(
						"Student didn't take any hints in task based code."
					);
			}
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		setStrongPoints([...new Set(calcstrongPoints)]);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		setWeakPoints([...new Set(calcweakPoints)]);
	};

	const logicalAbility = () => {
		let points1 = 0;
		let points2 = 0;
		for (const entry in questions.mentorFeedback) {
			const key: string = entry;
			const value = questions.mentorFeedback[entry];
			if (
				key === 'Approach Q1' ||
				key === 'Approach for Question 1'
			) {
				if (value === 'Correct/Thought the Logic') {
					points1 = 10;
				}
				if (
					value ===
					'Discussed the most optimal Solution for the question and also discussed the brute force solution'
				) {
					points1 = 10;
				} else if (
					value ===
					'Just discussed the most optimal solution'
				) {
					points1 = 8;
				} else if (
					value ===
					'Could not come up with most optimal solution but discussed a working solution/brute force'
				) {
					points1 = 6;
				} else if (value === 'Not attempted') {
					points1 = 0;
				}
			}
			if (
				key === 'Approach Q2' ||
				key === 'Approach for Question 2' ||
				key === 'Approach for question 2'
			) {
				if (
					value ===
					'Discussed the most optimal Solution for the question and also discussed the brute force solution'
				) {
					points2 = 10;
				} else if (
					value ===
					'Just discussed the most optimal solution'
				) {
					points2 = 8;
				} else if (
					value ===
					'Could not come up with most optimal solution but discussed a working solution/brute force'
				) {
					points2 = 6;
				} else if (value === 'Not attempted') {
					points2 = 0;
				}
			}
		}
		setLogicalAb((points1 * 4 + points2 * 6) / 10);
	};
	const codingProficieny = () => {
		let points1 = 0;
		let points2 = 0;
		for (const entry in questions.mentorFeedback) {
			const key: string = entry;
			const value = questions.mentorFeedback[entry];
			if (
				key === 'Q1 Code Evaluation' ||
				key === 'Code Evaluation Q1'
			) {
				if (value === 'Complete working code') {
					points1 += 10;
				} else if (
					value === '80% code working, silly errors'
				) {
					points1 += 8;
				} else if (
					value ===
					"Somewhat on the right path but couldn't complete"
				) {
					points1 += 6;
				} else if (
					value === 'Code not working/Not attempted'
				) {
					points1 += 0;
				}
			}
			if (
				key === 'Q2 Code Evaluation' ||
				key === 'Code Evaluation Q2'
			) {
				if (value === 'Complete working code') {
					points2 += 10;
				} else if (
					value === '80% code working, silly errors'
				) {
					points2 += 8;
				} else if (
					value ===
					"Somewhat on the right path but couldn't complete"
				) {
					points2 += 6;
				} else if (
					value === 'Code not working/Not attempted'
				) {
					points2 += 0;
				}
			}
			setCodeProf((points1 + points2) / 2);
			if (
				key === 'Task Code evaluation' ||
				key === 'Task based code evaluation'
			) {
				if (value === 'Full Working') points1 += 10;
				if (value === 'Partial Working') points1 += 8;
				if (value === 'Not able to code') points1 += 2;
				setCodeProf(points1 > 10 ? points1 / 2 : points1);
			}
		}
	};
	const communication = () => {
		let points = 0;
		for (const entry in questions.mentorFeedback) {
			const key: string = entry;
			const value = questions.mentorFeedback[entry];
			if (
				key === 'Communication Level of the student' ||
				key === 'Communication Level of Student  ' ||
				key === "How was student's communication?" ||
				key === 'Rate the communication of the student' ||
				key === "How was the student's communication?"
			) {
				if (value === 'Excellent') points = 10;
				else if (value === 'Good to Go') points = 8;
				else if (value === 'Average') points = 6;
				else if (
					value ===
					'Weak (Need to Improve on Communication before Interviews)'
				)
					points = 4;
				else if (value === 'Weak') points = 4;
			}
			if (
				key === 'Rate the Communication Skills of Student'
			) {
				points = parseInt(value);
			}
		}
		setCommPoints(points);
	};
	const explanation = () => {
		let points1 = 0;
		let points2 = 0;
		for (const entry in questions.mentorFeedback) {
			const key: string = entry;
			const value = questions.mentorFeedback[entry];
			if (
				key === 'Was he able to explain his code well?' ||
				key === 'Student explained the code well?' ||
				key === 'Did the student explain his code well?' ||
				key === 'Was student able to explain his code well?'
			) {
				if (value === 'No') points1 = 0;
				if (value === 'Yes') points1 = 10;
			}
			if (
				key ===
				'Did student explain the code well of Question 1 ? '
			) {
				if (value === 'No') points1 = 0;
				if (value === 'Yes') points1 = 10;
			}
			if (
				key ===
					'Did student explain the code well for Question 2 ?' ||
				key === 'Was he able to explain his Q2 code well?'
			) {
				if (value === 'No') points2 = 0;
				if (value === 'Yes') points2 = 10;
			}
		}
		setExplainPoints((points1 + points2) / 2);
	};

	useEffect(() => {
		if (questions) {
			segregator();
			codingProficieny();
			logicalAbility();
			communication();
			explanation();
		}
	}, [questions]);

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<div className={'modal feedbackmodal'}>
				<h2>Your Performance Report</h2>
				<div className="mentorfeedback">
					<p style={{ fontWeight: '500' }}>** {feedback}</p>
				</div>
				{!isDoubtSession ? (
					<div className={'feedbackgrid'}>
						<div className="feedback-weak">
							<h4>Weak Points</h4>
							<ul>
								{weakPoints.length > 0 &&
									weakPoints.map((sn: any, ind: number) => (
										<li key={ind}>{sn}</li>
									))}
							</ul>
						</div>
						<div className="feedback-score">
							<h4>Total Score</h4>
							<div className="fb-score">
								<p>{questions.score}/10</p>
							</div>
						</div>
						<div className="feedback-strong">
							<h4>Strong Points</h4>
							<ul>
								{strongPoints.length > 0 &&
									strongPoints.map(
										(sn: any, ind: number) => (
											<li key={ind}>{sn}</li>
										)
									)}
							</ul>
						</div>
						<div className="feedback-sectionwise">
							<h4>Section Wise Evaluation</h4>
							<div style={{ marginTop: '20px' }}>
								<div className="fb-section">
									<div>Logical Ability</div>
									<div className="fb-section-score">
										{logicalAb}/10
									</div>
								</div>
								<div className="fb-section">
									<div>Coding Proficiency</div>
									<div className="fb-section-score">
										{codeProf}/10
									</div>
								</div>
								<div className="fb-section">
									<div>Explanation</div>
									<div className="fb-section-score">
										{explanPoints}/10
									</div>
								</div>
								{/* <div className="fb-section" >
                                <div>Theory</div>
                                <div className="fb-section-score">Approach</div>
                            </div> */}
								<div className="fb-section">
									<div>Communication</div>
									<div className="fb-section-score">
										{commPoints}/10
									</div>
								</div>
							</div>
						</div>
					</div>
				) : (
					<div className="feedback-weak">
						<h4>Weak Point</h4>
						<h3>
							{' '}
							Your session was judged to be a doubt clearing
							session because your performance was
							unsatisfactory. Please contact your batch
							manager to book a mock session again.
						</h3>
					</div>
				)}
			</div>
		</Modal>
	);
};

export default SessionFeedbackModal;
