import { FC, useEffect, useState } from 'react';
import { QuestionsTypeEnum } from '../../../redux/gym/type';
import IntegerQuestion from '../../aptitute/components/option/IntegerQuestion';
import MultiSelectMCQ from '../../aptitute/components/option/MultiSelectMCQ';
import SingleSelectMCQ from '../../aptitute/components/option/SingleSelectMCQ';
import SubjectiveQuestion from '../../aptitute/components/option/SubjectiveQuestion';

export const RenderOptions = ({
	question,
	submitResponse,
	onSaveQuestion,
	onSubmitQuestion,
	isLoading,
}: {
	question: any; // AptitudeQuestionInterface
	submitResponse?: {
		correctAnswer: Array<string>;
		explanation: string;
		submitted: boolean;
	};
	onSaveQuestion: (answerArray: Array<string>) => void;
	onSubmitQuestion: (answerArray: Array<string>) => void;
	isLoading: boolean;
}) => {
	const {
		questionType,
		answerOptions,
		previousAnswerDetails,
		isAttempted,
		correctAnswer,
		previouslySelectedAnswerDetails,
		explanation,
	} = question;

	const props = {
		onSaveQuestion,
		onSubmitQuestion,
		response:
			previousAnswerDetails?.answer ??
			previouslySelectedAnswerDetails,
		correct:
			submitResponse?.correctAnswer || correctAnswer || [],
		submitted: Boolean(submitResponse) || isAttempted,
		explanation:
			submitResponse?.explanation ?? explanation ?? '',
		isLoading,
	};

	switch (questionType) {
		case QuestionsTypeEnum.SINGLEMCQ:
			return (
				<SingleSelectMCQ
					options={answerOptions}
					{...props}
				/>
			);
		case QuestionsTypeEnum.MULTIMCQ:
			return (
				<MultiSelectMCQ
					options={answerOptions}
					{...props}
				/>
			);
		case QuestionsTypeEnum.INT:
			return <IntegerQuestion {...props} />;
		case QuestionsTypeEnum.TEXT:
			return <SubjectiveQuestion {...props} />;
		default:
			return <></>;
	}
};

// AptitudeOptionPropsInterface

export const AptitudeOptions: FC<any> = ({ question }) => {
	console.log('@question', question);
	const { conceptualQuestionId, previousAnswerDetails } =
		question;

	const [submitResponse, setSubmitResponse] = useState<{
		correctAnswer: Array<string>;
		explanation: string;
		submitted: boolean;
	}>();

	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		setSubmitResponse(undefined);
	}, [question]);

	const onSubmit = async (answerArray: Array<string>) => {
		const data = {
			conceptualQuestionId,
			answer: answerArray,
			...(previousAnswerDetails?.id && {
				id: previousAnswerDetails.id,
			}),
		};

		// try {
		// 	setLoading(true);
		// 	const response =
		// 		(await Services.aptitudeService.submitAptitudeQuestions(
		// 			data
		// 		)) as {
		// 			data: {
		// 				correctAnswer: Array<string>;
		// 				explanation: string;
		// 				submitted: boolean;
		// 			} | null;
		// 		};
		// 	if (response.data) {
		// 		setSubmitResponse(response.data);
		// 	}
		// } catch (e) {
		// 	console.error(e);
		// } finally {
		// 	setLoading(false);
		// }
	};

	const onSave = async (answerArray: Array<string>) => {
		const data = {
			conceptualQuestionId,
			answer: answerArray,
			...(previousAnswerDetails?.id && {
				id: previousAnswerDetails.id,
			}),
		};

		// await Services.aptitudeService.saveAptitudeQuestions(
		// 	data
		// );
	};

	return (
		<RenderOptions
			question={question}
			submitResponse={submitResponse}
			onSaveQuestion={(answerArray) =>
				void onSave(answerArray)
			}
			onSubmitQuestion={(answerArray) =>
				void onSubmit(answerArray)
			}
			isLoading={isLoading}
		/>
	);
};
