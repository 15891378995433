import {
	Button,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import React, {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import Fields from './Fields';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { services } from '../../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import { MentorPreferences } from '../mentorTypes';
import { fetchMentorPreferencesByRole } from '../../../../redux/mentorSlice/mentorSlice';

const enumToList = (data: any): string[] =>
	Object.values(data);

let allPreferences = [
	'FRONTEND',
	'BACKEND_NODEJS',
	'BACKEND_SPRING',
	'DSA',
	'SAPLING',
	'MENTORSHIP',
];

const MentorSessionPreferences = () => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, preferencesByRole } = MentorState;

	const initialFormData = {
		// userId: user !== null ? user.id : '',
		preferenceOne: '',
		preferenceTwo: '',
		preferenceThree: '',
		preferenceFour: '',
		preferenceFive: '',
	};
	const [avlPreferences, setAvlPreferences] = useState<any>(
		[]
	);

	const [formFields, setFormFields] = useState<any>();

	const [formData, setFormData] = useState(initialFormData);

	const [selectedPref, setSelectedPref] = useState<any>([]);
	const [
		mentorPreferencesByRole,
		setMentorPreferencesByRole,
	] = useState<any>([]);

	const fetchPreferences = async () => {
		try {
			const priorities: any =
				await services.mentorService.fetchMentorPreferences();
			console.log('priorities', priorities);
			let temp = { ...formData };
			priorities.forEach((obj: any) => {
				if (obj.priority === 1)
					temp = {
						...temp,
						['preferenceOne']: obj.sessionType.sessionType,
					};
				else if (obj.priority === 2)
					temp = {
						...temp,
						['preferenceTwo']: obj.sessionType.sessionType,
					};
				else if (obj.priority === 3)
					temp = {
						...temp,
						['preferenceThree']:
							obj.sessionType.sessionType,
					};
				else if (obj.priority === 4)
					temp = {
						...temp,
						['preferenceFour']: obj.sessionType.sessionType,
					};
				else if (obj.priority === 5)
					temp = {
						...temp,
						['preferenceFive']: obj.sessionType.sessionType,
					};
			});
			setFormData(temp);
			setSelectedPref([...Object.values(temp)]);
		} catch (error) {}
	};

	useEffect(() => {
		if (mentor != null) {
			fetchPreferences();
		}
	}, [mentor]);

	useEffect(() => {
		if (preferencesByRole === null)
			dispatch(fetchMentorPreferencesByRole());
		else {
			setMentorPreferencesByRole([...preferencesByRole]);
			let temp: any = [];
			preferencesByRole.map((preference: any) => {
				temp.push(preference.sessionType);
			});
			temp.sort();
			setAvlPreferences([...temp]);
			const FormFields = [
				{
					title: 'Preferences',
					show: true,
					type: 'batch',
					controls: -1,
					items: {
						preferenceOne: {
							label: 'Preference 1',
							type: 'select',
							disabled: false,
							items: temp,
							required: false,
						},
						preferenceTwo: {
							label: 'Preference 2',
							type: 'select',
							disabled: false,
							items: temp,
							required: false,
						},
						preferenceThree: {
							label: 'Preference 3',
							type: 'select',
							disabled: false,
							items: temp,
							required: false,
						},
						preferenceFour: {
							label: 'Preference 4',
							type: 'select',
							disabled: false,
							items: temp,
							required: false,
						},
						preferenceFive: {
							label: 'Preference 5',
							type: 'select',
							disabled: false,
							items: temp,
							required: false,
						},
					},
				},
			];
			setFormFields(FormFields);
		}
	}, [preferencesByRole]);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[],
		event?: any
	) => {
		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
		setSelectedPref([...selectedPref, fieldValue]);
	};

	const getId = (value: string): string => {
		let id = '';
		let temp = [...mentorPreferencesByRole];
		for (let i = 0; i < temp.length; i++) {
			const role = temp[i];
			if (role.sessionType === value) {
				id = role.id;
				break;
			}
		}
		return id;
	};

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		const body: any = [];
		const preferences = [
			'preferenceOne',
			'preferenceTwo',
			'preferenceThree',
			'preferenceFour',
			'preferenceFive',
		];
		Object.keys(formData).forEach((preference: any) => {
			//@ts-ignore
			const selectedVal = formData[preference];
			if (selectedVal !== '') {
				body.push({
					sessionTypeId: getId(selectedVal),
					priority: preferences.indexOf(preference) + 1,
				});
			}
		});
		try {
			const data =
				await services.mentorService.addMentorPreferences(
					body
				);
			if (data && data.statusCode)
				notifyError(data.message);
			else notifySuccess(data.message);
		} catch (error) {}
	};

	const updateAvailablePreferences = (
		e: any,
		fieldId: any
	) => {
		//@ts-ignore
		const val = formData[fieldId];
		if (val !== '') {
			//@ts-ignore
			const temp = selectedPref.filter(
				//@ts-ignore
				(pref) => pref !== val
			);
			temp.push(e.target.value);
			setSelectedPref([...temp]);
		}
	};

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{formFields &&
				formFields.map((fieldBatch: any, idx: number) => {
					return (
						<div
							key={idx}
							className="batch"
						>
							<h4>{fieldBatch.title}</h4>
							<>
								<div className="fields">
									{fieldBatch.items &&
										Object.entries(fieldBatch.items).map(
											([fieldId, field], idx) => (
												<div
													className="form-item"
													key={idx}
												>
													<FormControl fullWidth>
														<InputLabel
															sx={{
																color:
																	'rgba(0, 0, 0, 0.75)',
																fontSize: '0.9rem',
																fontWeight: 400,
																fontFamily:
																	"'Inter', sans-serif",
															}}
														>
															{
																//@ts-ignore
																field.label
															}
														</InputLabel>
														<Select
															value={
																formData[
																	fieldId as keyof MentorPreferences
																]
															}
															name={fieldId}
															label={
																//@ts-ignore
																field.label
															}
															sx={{
																color: '#000',
																fontSize: '0.9rem',
																fontWeight: 400,
																fontFamily:
																	"'Inter', sans-serif",
															}}
															onChange={(e) => {
																updateFormData(
																	e.target.name,
																	e.target.value as string,
																	e
																);
																updateAvailablePreferences(
																	e,
																	fieldId
																);
															}}
														>
															<MenuItem value="">
																NONE
															</MenuItem>
															{
																//@ts-ignore
																field.items &&
																	//@ts-ignore
																	field.items.map(
																		(
																			item: any,
																			idx: any
																		) => (
																			<MenuItem
																				style={{
																					display: `${
																						selectedPref.includes(
																							item
																						)
																							? 'none'
																							: 'block'
																					}`,
																				}}
																				key={idx}
																				value={item}
																			>
																				{item}
																			</MenuItem>
																		)
																	)
															}
														</Select>
													</FormControl>
												</div>
											)
										)}
								</div>
								<Button
									className="profile-submit"
									type="submit"
									style={{ marginTop: '1.25em' }}
								>
									Submit
								</Button>
							</>
						</div>
					);
				})}
		</form>
	);
};

export default MentorSessionPreferences;
