import { type FC } from 'react';
import { DifficultyChip } from '../../../components/shared/DifficultyChip';
import { MarkDown } from '../../../components/shared/MarkDown';
import { DifficultyTypeEnum } from '../../../redux/gym/type';

export const QuestionPanel: FC<{
	questionStatement: string;
	difficulty: DifficultyTypeEnum;
	score: number;
	questionNumber?: number;
}> = ({
	questionStatement,
	difficulty,
	score,
	questionNumber,
}) => {
	return (
		<div className="question-panel">
			<div className="content">
				<div className="question-header">
					<div className="question-header-text">
						Question {questionNumber}
					</div>
					<div className="question-difficulty-score">
						<DifficultyChip difficulty={difficulty} />
						<div className="chip score-chip">
							Max Score: {score}
						</div>
					</div>
				</div>
				<MarkDown>{questionStatement}</MarkDown>
			</div>
		</div>
	);
};
