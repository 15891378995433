import React, { useRef, useState } from 'react';
import { BiUpload } from 'react-icons/bi';
import { TbDownload } from 'react-icons/tb';
import { storage } from '../../config/firebase';
import {
	ref,
	getDownloadURL,
	uploadBytesResumable,
} from 'firebase/storage';
import {
	notifyError,
	notifySuccess,
} from '../../components/notification';
import { useDispatch } from 'react-redux';
import { AiOutlineCheck } from 'react-icons/ai';
import { fileAssignmentUploaderData } from '../../redux/fileUploadDownloadSlice/fileUploadDownloadSlice';
import Loader from '../../components/Loader';
import {
	ClipLoader,
	PropagateLoader,
	SyncLoader,
} from 'react-spinners';
import { AppDispatch } from '../../redux/store';

interface UploadButtonProps {
	onChange: (file: File) => void;
	questionId: string;
	selectedFile: any;
	loadingState: any;
	disableToSubmit: boolean;
}

const UploadButton: React.FC<UploadButtonProps> = ({
	onChange,
	questionId,
	selectedFile,
	loadingState,
	disableToSubmit,
}) => {
	const [fileData, setFileData] = useState<File | null>(
		null
	);
	const fileInputRef = useRef<HTMLInputElement>(null);
	const dispatch = useDispatch<AppDispatch>();
	const handleFileInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const file = event.target.files
			? event.target.files[0]
			: null;

		setFileData(file);

		if (file) {
			onChange(file);
		}
	};

	const handleClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleAssignmentSubmit = (event: any) => {
		event.preventDefault();
		const fileQuestionId = questionId;
		const formData = new FormData();
		formData.append('file', fileData as File);
		formData.append('fileQuestionId', fileQuestionId);
		dispatch(fileAssignmentUploaderData(formData));
	};
	return (
		<>
			{selectedFile ? (
				<button
					onClick={handleAssignmentSubmit}
					className={`download-upload-button ${
						disableToSubmit ? 'disable-to-submit' : ' '
					}`}
				>
					{loadingState ? (
						<ClipLoader color={'#fff'} />
					) : (
						<>
							<span className="icon">
								<AiOutlineCheck />
							</span>
							<span className="text">Submit</span>
						</>
					)}
				</button>
			) : (
				<button
					onClick={handleClick}
					className={`download-upload-button ${
						disableToSubmit ? 'disable-to-submit' : ' '
					}`}
				>
					<span className="icon">
						<BiUpload />
					</span>
					<span className="text">Upload</span>
				</button>
			)}
			<input
				type="file"
				accept=".jpg,
	.jpeg,
	.png,
	.gif,
	.bmp,
	.tiff,
	.tif,
	.svg,.xlsx,
	.xlsm,
	.xlsb,
	.xls,
	.xltm,
	.xltx,
	.xlt,
	.csv,.docx,
	.docm,
	.dotx,
	.dotm,
	.doc,
	.rtf,
	.txt,.pdf,
	.pdfa,
	.pdfx,
	.pdfe,
	.pdfua,
	.pdfvt,.txt,
	.md,
	.html,
	.xml,
	.json,
	.yaml,
	.csv,"
				style={{ display: 'none' }}
				ref={fileInputRef}
				onChange={handleFileInputChange}
			/>
		</>
	);
};

export default UploadButton;
