import { Loader } from '@acciojob/loader';
import moment from 'moment';
import { FC, useState } from 'react';
import { services } from '../../apis';
import { getSoftSkillsModuleDetails } from '../../redux/softskills/softskills.thunk';
import { BreakoutSessionType } from '../../redux/softskills/softskills.type';
import { useAppDispatch } from '../../redux/store';
import AccioButton from '../elements/Button';
import { notifyError, notifyInfo } from '../notification';

const BreakoutSessionCard: FC<
	BreakoutSessionType & { selectedModuleId: string }
> = ({
	id,
	title,
	description,
	selectedModuleId,
	isLive,
	isRegistered,
	totalSeats,
	occupiedSeats,
	start_time,
	end_time,
}) => {
	const dispatch = useAppDispatch();
	const [showLoader, setShowLoader] = useState(false);

	const joinBreakoutSession = async (id: string) => {
		setShowLoader(true);
		try {
			const res =
				await services.softskillsService.getBreakoutClassJoinLink(
					{
						videoId: id,
						moduleId: selectedModuleId,
					}
				);
			if (res.join_url) {
				window.open(res.join_url, '_blank');
			}
		} catch (error) {
			console.log(error);
		} finally {
			setShowLoader(false);
		}
	};

	const handleRegisterForSession = async (id: string) => {
		setShowLoader(true);
		try {
			const res =
				await services.softskillsService.registerForSession(
					{
						moduleId: selectedModuleId,
						videoId: id,
					}
				);
			dispatch(
				getSoftSkillsModuleDetails(selectedModuleId)
			);
		} catch (error) {
			console.log(error);
		} finally {
			setShowLoader(false);
		}
	};

	const handleClick = async (id: string) => {
		if (!isRegistered) {
			if (totalSeats > occupiedSeats) {
				handleRegisterForSession(id);
			} else {
				notifyError(
					"All seats are occupied. You can't register for this session."
				);
			}
		} else if (isRegistered && isLive) {
			joinBreakoutSession(id);
		}
	};

	const getBtnText = () => {
		if (isLive && isRegistered) {
			return 'Join Session';
		} else if (!isLive && isRegistered) {
			return 'Registered';
		} else return 'Register For Session';
	};

	return (
		<div className="breakout-session">
			{showLoader ? (
				<Loader
					width={'100%'}
					height={'100%'}
				/>
			) : (
				<div className="breakout-session-card">
					<div className="breakout-session-left">
						<h2 className="breakout-session-heading">
							{title}
						</h2>
						<p className="breakout-session-description">
							{description}
						</p>
						<p className="next-breakout-session-text">
							{isLive
								? 'Session Is Live'
								: 'Next Breakout Session'}
						</p>
						<h3 className="next-breakout-session-timing">
							{moment(start_time).format('hh:mm A')},
							{moment(start_time).format('Do MMM YY')}
						</h3>
						<AccioButton
							title={getBtnText()}
							disabled={!isLive && isRegistered}
							className={
								isLive
									? 'register-for-session-btn'
									: 'join-session-btn'
							}
							action={() => {
								handleClick(id);
							}}
						/>
					</div>
					<div className="breakout-session-right">
						<img
							src={
								'https://storage.googleapis.com/acciojob-open-file-collections/5ecd306c-333e-4a73-8b3b-a027ac6033db_breakout_session.gif'
							}
							className="breakout-session-img"
						/>
					</div>
				</div>
			)}
		</div>
	);
};

export default BreakoutSessionCard;
