// External

import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Functions

import { services } from '../../apis';
import { engagements } from '../../engagements';
import { setMeetingId } from '../../redux/course/courseSlice';

// Components

import ReactJoyride, { Step } from 'react-joyride';
import CourseDropDown from '../../components/CourseDropDown';
import ModuleData from '../../components/module/moduleData';
import ModuleNavigator from '../../components/module/moduleNavigator';
import ModuleTopBar from '../../components/module/moduleTopBar';
import {
	fetchAllModules,
	handleSwitch,
	updateModules,
} from '../../redux/modules/moduleSlice';

const ModulesDash = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userState = useSelector((state: any) => state.user);
	const { user } = userState;

	const instructor =
		user !== null
			? user.roles.find((role: any) => role == 'instructor')
			: undefined;
	const role = instructor !== undefined ? 1 : 0;

	const moduleState = useSelector(
		(state: any) => state.module
	);
	const {
		allModules,
		moduleListloading,
		moduleloading,
		moduleActive,
	} = moduleState;

	const courseState = useSelector(
		(state: any) => state.course
	);
	const { courseID, courseName, upcomingClass } =
		courseState;

	useEffect(() => {
		if (courseID !== null) {
			console.log('MODULE COURSE ID ', courseID);
			if (allModules[courseID] === undefined) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(fetchAllModules(courseID));
			} else {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(handleSwitch);
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(updateModules(courseID));
			}
		}
	}, [courseID]);

	const [moduleName, setModuleName] = useState('');

	useEffect(() => {
		if (moduleActive) {
			setModuleName(moduleActive.name);
		}
	}, [moduleActive]);

	// Zoom

	const startZoomMeeting = async (
		meetingId: string,
		instructorId: string
	) =>
		await services.courseService.fetchStartUrl(
			meetingId,
			instructorId
		);
	const startZoomMeetingForStudents = async (
		meetingId: string
	) =>
		await services.courseService.fetchStartUrlForStudents(
			meetingId,
			user.firstName,
			user.lastName,
			user.email
		);

	const setZoomMeetingId = (
		meetingId: string,
		start_url: string,
		title: string
	) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(setMeetingId(meetingId));
		const myWindow = window.open(
			'',
			new Date().getTime().toString()
		);
		if (role === 0) {
			// window.open(
			// 	`${window.origin}/liveclass?meetingId=${meetingId}`,
			// 	'_blank'
			// );

			startZoomMeetingForStudents(meetingId)
				.then((res) => {
					engagements.courseEngagement.joinClass(
						title,
						meetingId
					);
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.location.href = res.join_url;
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.focus();
				})
				.catch((e) =>
					alert(
						'Some error occured in joining the class. Please try again'
					)
				);
		} else {
			startZoomMeeting(meetingId, user.id)
				.then((res) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.location.href = res;
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					//@ts-ignore
					myWindow.focus();
				})
				.catch((e) =>
					alert(
						'Some error occured in joining the class. Please try again'
					)
				);
		}
	};

	const steps: Array<Step> = [
		{
			content: (
				<div>
					<h2>Module Selection</h2>
					<p>
						Our course is divided in modules. Here's where
						you can select any of your current or past
						modules.
					</p>
				</div>
			),
			target: '#moduleDropDown',
		},
		{
			content: (
				<div>
					<h2>Class Selection</h2>
					<p>
						These are all the classes of a module. Select
						any class to access its lectures and
						assignments.
					</p>
				</div>
			),
			target: '#module-navigator',
		},
		{
			content: (
				<div>
					<h2>Assignments Tab</h2>
					<p>
						Here's where you can see all the assignments of
						a class.
					</p>
				</div>
			),
			target: '#simple-tab-0',
		},
		{
			content: (
				<div>
					<h2>Assignment</h2>
					<p>
						Click on this to start solving the questions
						related to the topic
					</p>
				</div>
			),
			target: '.assignments li:first-child',
		},
		{
			content: (
				<div>
					<h2>Lecture Tab</h2>
					<p>
						A class contains lectures and assignments.
						Clicking on this tab will bring you to lecture
						section.
					</p>
				</div>
			),
			target: '#simple-tab-1',
		},
	];

	useEffect(() => {
		if (
			userState.user?.roles[0] === 'da-playground-user' &&
			!localStorage.getItem('moduleWalkthroughShown')
		) {
			const beacon = document.querySelector(
				'.react-joyride__beacon'
			) as HTMLButtonElement;
			if (beacon) {
				beacon.style.visibility = 'hidden';

				if (allModules) {
					beacon?.click();
					localStorage.setItem(
						'moduleWalkthroughShown',
						'true'
					);
				}
			}
		}
	}, [allModules, userState]);

	return (
		<>
			<Grid
				container
				className={'module-dash'}
			>
				<Grid
					item
					lg={4}
					md={4}
					sm={4}
				>
					<CourseDropDown />
					<ModuleNavigator />
				</Grid>
				<Grid
					item
					lg={8}
					md={8}
					sm={8}
					className={'module-data-container'}
				>
					<ModuleTopBar
						courseLoading={moduleListloading}
						moduleLoading={moduleloading}
						courseName={courseName}
						moduleName={moduleName}
						upcomingClassData={{
							dataPresent: upcomingClass !== null,
							disable: upcomingClass
								? !(new Date() > upcomingClass.activeAt)
								: true,
							action: () =>
								upcomingClass !== null
									? setZoomMeetingId(
											upcomingClass.room,
											upcomingClass.url,
											upcomingClass.name
									  )
									: null,
						}}
					/>
					<ModuleData />
				</Grid>
			</Grid>
			<ReactJoyride
				steps={steps}
				continuous
				run={true}
				styles={{
					tooltipContent: {
						textAlign: 'start',
						fontSize: 14,
					},
					tooltipContainer: {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: 12,
					},
					tooltipFooter: {
						marginTop: 0,
					},
					tooltip: {
						borderRadius: 12,
						padding: 12,
					},
					buttonBack: {
						borderRadius: 8,
						color: '#2F80ED',
					},
					buttonNext: {
						borderRadius: 8,
						backgroundColor: '#2F80ED',
					},
					spotlight: {
						borderRadius: 12,
					},
				}}
			/>
		</>
	);
};

export default ModulesDash;
