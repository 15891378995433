import { Box } from '@mui/material';
import { FaArrowCircleRight } from 'react-icons/fa';
import AccioButton from '../elements/Button';
import { useNavigate } from 'react-router-dom';
import refer from './../../assets/images/refer.svg';

const ReferAndEarn = () => {
	const navigate = useNavigate();
	return (
		<Box className={'refer'}>
			<div className={'refer-content'}>
				<h3>Tell your friends about us !</h3>
				<p>
					Refer your friends and earn huge discounts on your
					course The more the merrier !
				</p>
				<AccioButton
					title="Refer Now"
					icon={<FaArrowCircleRight />}
					action={() => navigate('/refer-and-earn')}
				/>
			</div>
			<img
				src={refer}
				alt={'refer and earn'}
			/>
		</Box>
	);
};

export default ReferAndEarn;
