import { useState } from 'react';
import {
	Dialog,
	TextField as MaterialTextField,
	Box,
} from '@mui/material';
import {
	GeneralProfileFormFields,
	PlacementProfileFormFields,
} from '../student_personal_details';
import EnterCollegeName from './EnterCollegeName';

function CollegeField({ formData, id, type }: any) {
	const [displaySearch, setDisplaySearch] = useState(false);
	const [open, setOpen] = useState(true);
	const closeDialog = () => {
		setOpen(false);
		setDisplaySearch(false);
	};
	const fieldValue =
		formData[
			id as keyof (
				| GeneralProfileFormFields
				| PlacementProfileFormFields
			)
		];

	return (
		<div>
			<MaterialTextField
				style={{ width: '100%' }}
				value={fieldValue}
				onClick={() => {
					setDisplaySearch(true);
					setOpen(true);
				}}
				name={id}
			/>
			{displaySearch && (
				<EnterCollegeName
					open={open}
					setOpen={setOpen}
					type={type}
					selectedCollege={fieldValue}
				/>
			)}
		</div>
	);
}

export default CollegeField;
