import React, { FC, useEffect } from 'react';
import AccioButton from '../elements/Button';
import { useTimer } from 'react-timer-hook';
import { useAppSelector } from '../../redux/store';

interface TopBarProps {
	onExpire: () => void;
	expiryTimestamp: Date;
	timeTaken: (time: number) => void;
	submitTest: () => void;
}
const TopBar: FC<TopBarProps> = ({
	onExpire,
	expiryTimestamp,
	timeTaken,
	submitTest,
}) => {
	const {
		isTestCompleted,
		count,
		loading,
		testStarted,
		totalQuestions,
	} = useAppSelector((state) => state.aiStudentAssessment);

	const { totalSeconds, restart, pause, resume } = useTimer(
		{
			expiryTimestamp,
			onExpire: isTestCompleted ? () => {} : onExpire,
		}
	);

	useEffect(() => {
		restart(expiryTimestamp);
	}, [expiryTimestamp]);

	useEffect(() => {
		if (testStarted) {
			if (loading) {
				pause();
			} else {
				resume();
			}
		}
	}, [loading, testStarted]);

	useEffect(() => {
		timeTaken(totalSeconds);
	}, [totalSeconds]);

	return (
		<div className="top-bar">
			<h3>Student Assessment</h3>

			<div className="top-bar-right">
				{!isTestCompleted ? (
					<>
						<h3>
							{count < totalQuestions
								? count
								: totalQuestions}
							/{totalQuestions}
						</h3>
						<div className="timer">
							<span>Time Left : </span>
							<span>{totalSeconds}s</span>
						</div>
						<AccioButton
							title="SUBMIT TEST"
							action={submitTest}
						/>
					</>
				) : (
					<AccioButton title="EXIT" />
				)}
			</div>
		</div>
	);
};

export default TopBar;
