import moment from 'moment';
import Marquee from 'react-fast-marquee';

import { Loader } from '@acciojob/loader';
const HighlightMovingBanner = ({
	weeklyPlacedDataState,
}: any) => {
	const timeConverter = (
		givenTime: string
	): string | null => {
		const now = moment();
		const time = moment(givenTime);
		const duration = moment.duration(now.diff(time));

		if (duration.asSeconds() < 0) {
			return null;
		}

		const minute = 60;
		const hour = minute * 60;
		const day = hour * 24;
		const month = day * 30;
		const year = day * 365;

		const durationSeconds = duration.asSeconds();

		if (durationSeconds < minute) {
			return `${Math.floor(durationSeconds)} sec${
				durationSeconds !== 1 ? 's' : ''
			}`;
		} else if (durationSeconds < hour) {
			const minutes = Math.floor(durationSeconds / minute);
			return `${minutes} min${minutes !== 1 ? 's' : ''}`;
		} else if (durationSeconds < day) {
			const hours = Math.floor(durationSeconds / hour);
			return `${hours} hr${hours !== 1 ? 's' : ''}`;
		} else if (durationSeconds < month) {
			const days = Math.floor(durationSeconds / day);
			return `${days} day${days !== 1 ? 's' : ''}`;
		} else if (durationSeconds < year) {
			const months = Math.floor(durationSeconds / month);
			return `${months} month${months !== 1 ? 's' : ''}`;
		} else {
			const years = Math.floor(durationSeconds / year);
			return `${years} year${years !== 1 ? 's' : ''}`;
		}
	};

	if (weeklyPlacedDataState.loading) {
		return (
			<div className="loader-container">
				<Loader
					factVisibility={false}
					width={'100%'}
					height={'100%'}
				/>
			</div>
		);
	} else {
		return weeklyPlacedDataState.placementdata &&
			weeklyPlacedDataState.placementdata.length > 0 ? (
			<div className="highlight-content-holder">
				<>
					<p className="highlight-content-header">
						Recent Placement Highlights
					</p>
					<div className="marquee-slider-content">
						<Marquee
							speed={50}
							gradient={false}
						>
							{weeklyPlacedDataState.placementdata &&
								weeklyPlacedDataState.placementdata.map(
									(ele: any, index: number) => (
										<div
											className="card-hightlight"
											style={{
												background: `${
													index % 4 === 0
														? '#EF574C'
														: index % 4 === 1
														? '#12B76A'
														: index % 4 === 2
														? '#2F80ED'
														: '#F0B416'
												}`,
											}}
										>
											{timeConverter(ele.placementMonth) !==
											null ? (
												<p className="card-hightlight-placement-stat">
													{timeConverter(
														ele.placementMonth
													)}{' '}
													ago
												</p>
											) : null}
											<p className="card-hightlight-name">
												{ele.student}
											</p>
											<p className="card-hightlight-position-company">
												{ele.designation == null
													? 'Software developer' +
													  ' , ' +
													  ele.placementCompany
													: ele.designation +
													  ' , ' +
													  ele.placementCompany}
											</p>
											<p className="card-hightlight-date">
												{/* {ele.placementMonth} */}
												{moment(ele.placementMonth).format(
													'LL'
												)}
											</p>
											<p className="card-hightlight-date">
												{'Course Duration : ' +
													ele.courseDuration +
													' months'}
											</p>
											<p className="card-hightlight-bm-name">
												{'Batch Manager : ' +
													ele.batchManager}
											</p>
											{/* <img
												className="badge-img"
												src={badge_2}
												alt="badge"
											/> */}
										</div>
									)
								)}
						</Marquee>
					</div>
				</>
			</div>
		) : null;
	}
};

export default HighlightMovingBanner;
