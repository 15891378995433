import React, {
	FormEventHandler,
	forwardRef,
	ReactElement,
	Ref,
	useEffect,
	useState,
} from 'react';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { PopupState } from '../../pages/jobportal/JobPortal';
// import MyTextField from './formElements/MyTextField';
import { Button } from '@mui/material';
// import MySelectField from './formElements/MySelectField';
// import MyMultiSelectField from './formElements/MyMultiselectField';
import { services } from '../../apis';
import { StudentProfileMultiSelectValues } from '../../apis/JobPortalService';
import Loader from '../Loader';
import { fetchJobCards } from '../../redux/jobportal/jobPortalSlice';
import { useAppDispatch } from '../../redux/store';
import { RiArrowGoBackFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import MySelectField from '../components/formElements/MySelectField';
import MyMultiSelectField from '../components/formElements/MyMultiselectField';
import MyTextField from '../components/formElements/MyTextField';

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: ReactElement;
	},
	ref: Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

interface PopupProps {
	profilePopup: PopupState;
	setProfilePopup: (newState: PopupState) => void;
}

export interface FormFieldProps {
	id: string;
	label: string;
	type: string;
	items?: Array<string>;
	disabled: boolean;
	updateFormData: (
		fieldId: string,
		fieldValue: string | string[]
	) => void;
	formData: FormDataType;
}

export interface FormDataType {
	name?: string;
	emailId?: string;
	phoneNumber?: string;
	whatsappNumber: string;
	homeState: string;
	jobStatus: string;
	jobType: string;
	preferredSpeakingLanguage: string[];
	graduationYear: string;
	graduationPercentage: number;
	collegeName: string;
	collegeDegree: string;
	collegeDepartment: string;
	class12Percentage: number;
	class10Percentage: number;
	collegeBacklogs: number;
}

const FormFields = {
	name: {
		label: 'Name',
		type: 'text',
		disabled: true,
	},
	emailId: {
		label: 'Registered Email ID',
		type: 'email',
		disabled: true,
	},
	phoneNumber: {
		label: 'Registered Phone Number',
		type: 'text',
		disabled: true,
	},
	whatsappNumber: {
		label: 'Whatsapp Number',
		type: 'text',
		disabled: false,
	},
	homeState: {
		label: 'Home State',
		type: 'select',
		items: [],
		disabled: false,
	},
	jobStatus: {
		label: 'Employment Status',
		type: 'select',
		items: [],
		disabled: false,
	},
	jobType: {
		label: 'Employment Type',
		type: 'select',
		items: [],
		disabled: false,
	},
	preferredSpeakingLanguage: {
		label: 'Languages you speak',
		type: 'multiselect',
		items: [],
		disabled: false,
	},
	graduationYear: {
		label: 'Graduation Year',
		type: 'text',
		disabled: false,
	},
	graduationPercentage: {
		label: 'Graduation Percentage',
		type: 'number',
		disabled: false,
	},
	collegeName: {
		label: 'College Name',
		type: 'text',
		disabled: false,
	},
	collegeDegree: {
		label: 'Degree/Course',
		type: 'select',
		items: [],
		disabled: false,
	},
	collegeBacklogs: {
		label: 'Active College Backlogs',
		type: 'number',
		disabled: false,
		min: 0,
		max: 10,
	},
	collegeDepartment: {
		label: 'Department/Stream',
		type: 'select',
		items: [],
		disabled: false,
	},
	class12Percentage: {
		label: 'Intermediate (12th/Diploma) Percentage',
		type: 'number',
		disabled: false,
		min: 30,
		max: 100,
	},
	class10Percentage: {
		label: 'High School (10th) Percentage',
		type: 'number',
		disabled: false,
		min: 30,
		max: 100,
	},
};

const CompleteProfilePopup = ({
	profilePopup,
	setProfilePopup,
}: PopupProps) => {
	const [formFields, setFormFields] = useState(FormFields);
	const [loading, setLoading] = useState(false);

	const initialFormData: FormDataType = {
		name: '',
		emailId: '',
		phoneNumber: '',
		whatsappNumber: '',
		homeState: '',
		jobStatus: '',
		jobType: '',
		preferredSpeakingLanguage: [] as string[],
		graduationYear: '',
		graduationPercentage: NaN,
		collegeName: '',
		collegeDegree: '',
		collegeDepartment: '',
		class12Percentage: NaN,
		class10Percentage: NaN,
		collegeBacklogs: NaN,
	};

	const [formData, setFormData] = useState(initialFormData);
	const [userIdentifier, setUserIdentifier] = useState(
		{} as { id: string; firstName: string }
	);

	useEffect(() => {
		setLoading(true);
		const fetchMultiselectValues = async () => {
			const data =
				await services.jobPortalService.fetchStudentProfileMultiSelectValues();
			setFormFields((oldFormFields) => {
				const newFormFields = { ...oldFormFields };
				for (const [key, value] of Object.entries(data)) {
					newFormFields[
						key as keyof StudentProfileMultiSelectValues
					].items = value;
				}
				return newFormFields;
			});
		};
		const fetchProfileData = async () => {
			const data: any =
				await services.jobPortalService.fetchStudentProfile();
			setFormData((oldFormData) => {
				let newFormData: FormDataType = { ...oldFormData };
				for (const key of Object.keys(newFormData)) {
					if (key in data) {
						newFormData = {
							...newFormData,
							[key]:
								data[key] !== null
									? data[key]
									: initialFormData[
											key as keyof FormDataType
									  ],
						};
					}
				}
				newFormData.name =
					data.user.firstName + ' ' + data.user.lastName;
				newFormData.emailId = data.user.email;
				newFormData.phoneNumber = data.user.phoneNumber;
				return newFormData;
			});
			setUserIdentifier({
				id: data.id,
				firstName: data.user.firstName,
			});
			setTimeout(() => {
				setLoading(false);
			}, 300);
		};

		fetchMultiselectValues();
		fetchProfileData();
	}, []);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[]
	) => {
		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	useEffect(() => {
		console.log(formData);
	}, [formData]);

	const handleClose = () => {
		setProfilePopup({
			isOpen: false,
			identifier: '',
		});
	};

	const navigate = useNavigate();

	const goBackToCourse = () => {
		handleClose();
		navigate('/');
	};

	// dispatch
	const dispatch = useAppDispatch();

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();

		const finalFormSubmission = { ...formData };
		// deleting extra fields
		delete finalFormSubmission['name'];
		delete finalFormSubmission['emailId'];
		delete finalFormSubmission['phoneNumber'];

		// submitting data
		await services.jobPortalService.postStudentProfile({
			...finalFormSubmission,
			...userIdentifier,
		});

		// fetch jobDrives again
		dispatch(fetchJobCards());

		// close popup
		handleClose();
	};

	return (
		<Dialog
			open={profilePopup.isOpen}
			TransitionComponent={Transition}
			fullWidth={true}
			maxWidth="md"
			className="job-portal-profile-popup-parent"
		>
			<div className="job-portal-profile-popup">
				{loading && (
					<div style={{ transform: 'scale(0.75)' }}>
						<Loader />
					</div>
				)}
				{!loading && (
					<>
						<div className="popup-header">
							<span>
								<h2>Complete Your Profile</h2>
								<p>
									Please complete your profile to access the
									job portal.
								</p>
							</span>
							<button onClick={() => goBackToCourse()}>
								Back to course <RiArrowGoBackFill />
							</button>
						</div>

						<form
							className="popup-body"
							onSubmit={handleSubmit}
						>
							<div className="form-fields-container">
								{Object.entries(formFields).map(
									([id, field], idx): ReactElement => {
										if (field.type === 'select')
											return (
												<MySelectField
													key={idx}
													id={id}
													{...field}
													updateFormData={updateFormData}
													formData={formData}
												/>
											);
										else if (field.type === 'multiselect')
											return (
												<MyMultiSelectField
													key={idx}
													id={id}
													{...field}
													updateFormData={updateFormData}
													formData={formData}
												/>
											);
										return (
											<MyTextField
												key={idx}
												id={id}
												{...field}
												updateFormData={updateFormData}
												formData={formData}
											/>
										);
									}
								)}
							</div>
							<Button
								className="profile-submit"
								type="submit"
							>
								Submit
							</Button>
						</form>
					</>
				)}
			</div>
		</Dialog>
	);
};

export default CompleteProfilePopup;
