import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Route,
	Routes,
	useLocation,
} from 'react-router-dom';
import ReferAndEarn from '../pages/ReferAndEarn/ReferAndEarn';
import ContestDash from '../pages/contests';
import Dashboard from '../pages/dashboard/dashboard';

import { Loader } from '@acciojob/loader';
import { services } from '../apis';
import FullScreenChat from '../components/FullScreenChat';
import { setActiveRoutes } from '../featureController/featureControllerSlice';
import AiStudentAssessment from '../pages/AiStudentAssessment/AiStudentAssessment';
import ModuleJourney from '../pages/ModuleJourney/ModuleJourney';
import OpenCodeEditor from '../pages/OpenCodeEditor/OpenCodeEditor';
import CalenderView from '../pages/calender/Calender';
import IDEScreen from '../pages/codeEditors/IDEScreen';
import SqlEditor from '../pages/codeEditors/SQLEditor';
import ComingSoonPage from '../pages/comingSoon.page';
import { ContactBMPage } from '../pages/contactBM/ContactBMPage';
import ContestInfo from '../pages/contests/contestInfo';
import LeaderboardMain from '../pages/contests/leaderboard';
import { DAPlayground } from '../pages/da-playground';
import FileUploadDownloadScreen from '../pages/fileSubmissions/File';
import { Gym } from '../pages/gym';
import JobPortal from '../pages/jobportal/JobPortal';
import JoinLiveClass from '../pages/joinLiveClass/JoinLiveClass';
import JupyterNoteBook from '../pages/jupyterNoteBook/JupyterNoteBook';
import LiveDoubtDash from '../pages/livedoubts/LiveDoubtsDash';
import ModulesDash from '../pages/modules/ModuleDash';
import FileExplorer from '../pages/notes/FileExplorer';
import PageNotFound from '../pages/pagenotfound/PageNotFound';
import LockedScreen from '../pages/playground/lockedScreen';
import Playground from '../pages/playground/playground';
import MentorLock from '../pages/profile/mentor/MentorLock';
import MentorAvailability from '../pages/profile/mentor/mentorAvailability/MentorAvailability';
import MentorHome from '../pages/profile/mentor/mentorHome/MentorHome';
import MentorProfile from '../pages/profile/mentor/mentorProfile/MentorProfile';
import MentorSessions from '../pages/profile/mentor/mentorSessions/MentorSessions';
import MentorTutorials from '../pages/profile/mentor/mentorTutorials/MentorTutorials';
import Projects from '../pages/projects/projects';
import StartProject from '../pages/projects/startProject';
import SessionHome from '../pages/sessionPage/SessionHome';
import CourseContent from '../pages/softskills/SoftSkillsNew/CourseContent';
import SoftSkillsDashboard from '../pages/softskills/SoftSkillsNew/SoftSkillsDash';
import { StudentProfilePage } from '../pages/student-profile-page/StudentProfilePage';
import GithubAuthRedirect from '../pages/webdevidle/GithubAuthRedirect';
import WebDevEditor from '../pages/webdevidle/WebDevEditor';
import WebDevScreen from '../pages/webdevidle/WebDevScreen';
import { AppDispatch } from '../redux/store';
import ActiveRouter from './ActiveRouter';
import AptitudeNew from '../pages/aptitute/AptitudeNew';
import GymAptitudeNew from '../pages/gym-aptitute/GymAptitudeNew';

const AppRouter = () => {
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();

	const responseStudentPlacementReadiness = useSelector(
		(state: any) => state.session
	);
	const { user } = useSelector((state: any) => state.user);
	const mentor = useSelector((state: any) => state.mentor);

	const { mentorProfileStatus, mentorProfileLoading } =
		mentor;
	const [typeUser, setUserType] = useState('USER');

	const FeatureController = useSelector(
		(state: any) => state.featureController
	);

	const { shouldShowSessionPage } = useSelector(
		(state: any) => state.session
	);

	const { userRole, activeRoutes } = FeatureController;

	const [showLoader, setShowLoader] = useState(false);

	const sessionRouteAccess = async () => {
		try {
			setShowLoader(true);
			const canAccessSessionRoute =
				await services.sessionService.fetchSessionEligibility();
			if (!canAccessSessionRoute) {
				const temp = { ...activeRoutes };
				temp['/session'] = {
					active: false,
					name: 'sessions',
				};
				dispatch(setActiveRoutes({ ...temp }));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setShowLoader(false);
		}
	};

	useEffect(() => {
		if (user !== null) {
			setUserType(
				user.roles[0] === 'mentor' ? 'MENTOR' : 'USER'
			);
			sessionRouteAccess();
		}
	}, [user]);

	const RouteMap = [
		{
			path: '/',
			element: <Dashboard />,
		},
		{
			path: '/home',
			element: <Dashboard />,
		},
		{
			path: '/modules',
			element: <ModulesDash />,
		},
		{
			path: '/contests',
			element: <ContestDash />,
		},
		{
			path: '/contest',
			element: <ContestInfo />,
		},
		{
			path: '/doubt-support',
			element: <FullScreenChat />,
		},
		{
			path: '/live-doubts',
			element: <LiveDoubtDash />,
		},
		{
			path: '/gym',
			element: <Gym />,
		},
		{
			path: '/gym-aptitude',
			element: <GymAptitudeNew />,
		},
		{
			path: '/projects',
			element: <Projects />,
		},
		{
			path: '/notes',
			element: <FileExplorer />,
		},
		{
			path: '/notes/:slug',
			element: <FileExplorer />,
		},
		{
			path: '/softskills',
			element: <SoftSkillsDashboard />,
		},
		{
			path: '/softskills/:id',
			element: <CourseContent />,
		},
		{
			path: '/idle',
			element: <IDEScreen />,
		},
		{
			path: '/sqlEditor',
			element: <SqlEditor />,
		},
		{
			path: '/web-idle',
			element: <WebDevScreen />,
		},
		{
			path: '/open-editor',
			element: <OpenCodeEditor />,
		},
		{
			path: '/open-web-editor',
			element: <WebDevEditor />,
		},
		{
			path: '/file',
			element: <FileUploadDownloadScreen />,
		},
		{
			path: '/profile',
			element: <StudentProfilePage />,
		},
		{
			path: '/github/auth',
			element: <GithubAuthRedirect />,
		},
		// {
		// 	path: '/onevone',
		// 	element:
		// 		typeUser === 'USER' ? (
		// 			<OnevoneSession />
		// 		) : (
		// 			<Onevone />
		// 		),
		// },
		{
			path: '/aptitude',
			element: <AptitudeNew />,
		},
		{
			path: '/refer-and-earn',
			element: <ReferAndEarn />,
		},
		{
			path: '/mycalendar',
			element: <CalenderView />,
		},
		{
			path: '/projects',
			element: <Projects />,
		},
		{
			path: '/start-project',
			element: <StartProject />,
		},
		{
			path: '/leaderboard',
			element: <LeaderboardMain />,
		},
		{
			path: '/join-live-class',
			element: <JoinLiveClass />,
		},
		{
			path: '/playground',
			element: <Playground />,
		},
		{
			path: '/job-portal',
			element: <JobPortal />,
		},
		{
			path: '/mentor-profile',
			element: <MentorProfile />,
		},
		{
			path: '/mentor-availability',
			element: <MentorAvailability />,
		},
		{
			path: '/mentor-sessions',
			element: <MentorSessions />,
		},
		{
			path: '/mentor-docs',
			element: <MentorTutorials />,
		},
		{
			path: '/module-journey',
			element: <ModuleJourney />,
		},
		{
			path: '/session',
			element: <SessionHome />,
		},
		{
			path: '/da-playground',
			element: <DAPlayground />,
		},

		{
			path: '/jupyter-notebook',
			element: <JupyterNoteBook />,
		},
	];

	const [routeState, setRouteState] = useState(RouteMap);

	useEffect(() => {
		const temp: any = RouteMap.map((el: any) => {
			const elm = { ...el };
			// console.log('el', el, el.path);
			if (
				userRole === 'launchpad' ||
				userRole === 'community'
			) {
				if (activeRoutes[el.path] !== undefined) {
					if (el.path === '/' || el.path === '/home') {
						elm.element = <Playground />;
						return elm;
					} else return el;
				} else {
					elm.element = <LockedScreen />;
					return elm;
				}
			} else if (userRole === 'dalaunchpad') {
				if (activeRoutes[el.path] !== undefined) {
					if (el.path === '/' || el.path === '/home') {
						elm.element = <DAPlayground />;
						return elm;
					} else return el;
				} else {
					elm.element = <LockedScreen />;
					return elm;
				}
			} else if (userRole === 'mentor') {
				if (
					mentorProfileStatus &&
					!mentorProfileStatus.isComplete
				) {
					if (el.path === '/profile') {
						elm.element = <MentorProfile />;
						return elm;
					} else if (el.path === '/mentor-docs') {
						elm.element = <MentorTutorials />;
						return elm;
					} else {
						elm.element = <MentorLock />;
						return elm;
					}
				} else if (activeRoutes[el.path] !== undefined) {
					if (el.path === '/' || el.path === '/home') {
						console.log('is home');
						elm.element = <MentorHome />;
						return elm;
					} else if (el.path === '/profile') {
						console.log('is profile');
						elm.element = <MentorProfile />;
						return elm;
					} else if (el.path === '/mentor-docs') {
						console.log('is docs');
						elm.element = <MentorTutorials />;
						return elm;
					} else return el;
				} else {
					elm.element = <LockedScreen />;
					return elm;
				}
			} else if (
				userRole === 'fsdcourse' ||
				userRole === 'dsacourse'
			) {
				if (el.path === '/onevone') {
					elm.element = <ComingSoonPage />;
					return elm;
				} else return elm;
			} else return elm;
		});
		setRouteState([...temp]);
	}, [userRole, mentorProfileStatus, activeRoutes]);

	return (
		<Fragment>
			{showLoader ? (
				<Loader />
			) : (
				<Routes>
					<Route
						element={
							<ActiveRouter path={location.pathname} />
						}
					>
						{routeState?.map((el: any, index) => {
							if (!el) {
								console.log('check el', el, el.path);
							}
							return (
								<Route
									key={index + el.path}
									path={el.path}
									element={el.element}
								/>
							);
						})}
					</Route>
					<Route
						path={'*'}
						element={<PageNotFound />}
					/>
					<Route
						path={'coming-soon'}
						element={<ComingSoonPage />}
					/>
					<Route
						path={'/locked'}
						element={<LockedScreen />}
					/>
					<Route
						path="/student-assessment"
						element={<AiStudentAssessment />}
					/>
					<Route
						path="/contact-batch-manager"
						element={<ContactBMPage />}
					/>
				</Routes>
			)}
		</Fragment>
	);
};

export default AppRouter;
