import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../../apis';
import { GymStateType } from '../type';

export const fetchRandomQuestions = createAsyncThunk(
	'gym/fetchRandomQuestions',
	async (
		{
			courseId,
			selectedTabId,
		}: { courseId: string; selectedTabId: string },
		{ getState }
	) => {
		const state = getState() as { gym: GymStateType };
		switch (selectedTabId) {
			case 'coding':
				return (
					await services.courseService.getRandomQuestionCoding(
						courseId
					)
				)?.questionId;
			case 'webdev':
				return (
					await services.courseService.getRandomQuestionWebDev(
						courseId
					)
				)?.questionId;
			case 'conceptual':
				return (
					await services.courseService.getRandomQuestionConceptual(
						courseId
					)
				)?.questionId;
			default:
				return (
					await services.courseService.getRandomQuestionCoding(
						courseId
					)
				)?.questionId;
		}
	}
);

export const fetchHeatMapData = createAsyncThunk(
	'gym/heatMapData',
	async (arg) => {
		return await services.codingQuestionService.fetchHeatMapData();
	}
);
export const fetchUserQuestionSubmissionData =
	createAsyncThunk(
		'gym/questionSubmissionData',
		async (arg) => {
			return await services.codingQuestionService.fetchUserQuestionSubmissionData();
		}
	);

export const fetchRevisionSheetByTab = createAsyncThunk(
	'gym/revision-sheet',
	async (
		{ selectedTabId }: { selectedTabId: string },
		{ getState }
	) => {
		const state = getState() as { gym: GymStateType };

		return await services.courseService.getRevisionSheetByTab(
			selectedTabId
		);
	}
);

export const fetchTopicFilters = createAsyncThunk(
	'gym/sheet-tags',
	async (
		{ selectedTabId }: { selectedTabId: string },
		{ getState }
	) => {
		const state = getState() as { gym: GymStateType };
		const { appliedFiltersForTabId } = state.gym;

		const tabFilters =
			appliedFiltersForTabId[selectedTabId]!;
		const body = {
			tab: selectedTabId,
			unitId: tabFilters?.selectedUnit?.unitId ?? '',
			moduleIds: tabFilters?.moduleIds ?? [],
		};

		return await services.courseService.getAllTagsByTab(
			body
		);
	}
);

const createFilterBody = (
	state: GymStateType,
	selectedTabId: string
) => {
	const tabFilters =
		state.appliedFiltersForTabId[selectedTabId] ?? {};
	const questions =
		state.questionsByTabId[selectedTabId] ?? {};

	const tagFilters = tabFilters.topics ?? '';
	const difficulty = tabFilters.difficulties ?? '';
	const questionSource = tabFilters.questionType ?? '';
	const questionType = [
		...(tabFilters.shouldShowOnlyJsQuestions ? ['JS'] : []),
		...(tabFilters.shouldShowOnlyPythonQuestions
			? ['PYTHON']
			: []),
	];
	return {
		attemptStatus: tabFilters.questionAttemptStatus ?? '',
		bookmarked:
			tabFilters.shouldShowBookmarkedQuestions ?? false,
		tagFilters: tagFilters?.length === 0 ? '' : tagFilters,
		difficulty: difficulty?.length === 0 ? '' : difficulty,
		name: tabFilters.query ?? '',
		courseId: tabFilters.selectedUnit?.courseId ?? '',
		questionSource:
			questionSource?.length === 0 ? '' : questionSource,
		liked: tabFilters.shouldShowLikedQuestions ?? false,
		moduleIds: tabFilters.moduleIds ?? [],
		questionType,
		companyIds: tabFilters.companyIds ?? [],
		page: questions.page ?? 1,
	};
};

export const fetchQuestions = createAsyncThunk(
	'gym/fetchQuestions',
	async (
		{ selectedTabId }: { selectedTabId: string },
		{ getState }
	) => {
		const state = getState() as { gym: GymStateType };
		switch (selectedTabId) {
			case 'coding':
				return await services.courseService.getAllCodingQuestions(
					createFilterBody(state.gym, selectedTabId)
				);
			case 'webdev':
				return await services.courseService.getAllWebDevQuestions(
					createFilterBody(state.gym, selectedTabId)
				);
			case 'conceptual':
				return await services.courseService.getAllConceptualQuestions(
					createFilterBody(state.gym, selectedTabId)
				);
			default:
				return await services.courseService.getAllCodingQuestions(
					createFilterBody(state.gym, selectedTabId)
				);
		}
	}
);

export const addBookmark = createAsyncThunk(
	'gym/addBookmark',
	async (
		input: {
			index: number;
			selectedTabId: string;
		},
		{ getState }
	) => {
		const state = getState() as { gym: GymStateType };

		const { questionsByTabId, availableTabs } = state.gym;

		const questions =
			questionsByTabId[input.selectedTabId!].questions;

		const tabIndex = availableTabs!.findIndex(
			(tab) => tab.id === input.selectedTabId
		);

		await services.courseService.addBookmark({
			questionId: questions[input.index].id,
			type: tabIndex,
		});
	}
);

export const removeBookmark = createAsyncThunk(
	'gym/removeBookmark',
	async (
		input: { index: number; selectedTabId: string },
		{ getState }
	) => {
		const state = getState() as { gym: GymStateType };

		const { questionsByTabId, availableTabs } = state.gym;

		const questions =
			questionsByTabId[input.selectedTabId!].questions;

		const tabIndex = availableTabs!.findIndex(
			(tab) => tab.id === input.selectedTabId
		);

		await services.courseService.removeBookmark({
			questionId: questions[input.index].id,
			type: tabIndex,
		});
	}
);

export const fetchAvailableTabsThunk = createAsyncThunk(
	'gym/fetchAvailableTabs',
	async (arg, { getState }) => {
		const data =
			await services.courseService.getAvailableGymTabs();
		return data;
	}
);
