import clock_grey from '../../assets/softSkillsNew/clock_grey.svg';
import docs from '../../assets/softSkillsNew/docs.svg';
import angle_right from '../../assets/softSkillsNew/angle_right.svg';
import AccioButton from '../elements/Button';
import { FC } from 'react';
import { SoftSkillsModuleType } from '../../redux/softskills/softskills.type';

type CourseSubSectionProps = SoftSkillsModuleType & {
	selectedModuleId: string;
	handleGoToSection: (id: string) => void;
};

const CourseSubSection: FC<CourseSubSectionProps> = ({
	id,
	name,
	watchTime,
	assignmentCount,
	selectedModuleId,
	handleGoToSection,
}) => {
	return (
		<div
			className={`course-sub-section ${
				id === selectedModuleId ? 'selected-section' : ''
			}`}
			id={id === selectedModuleId ? 'selected-module' : ''}
		>
			<p className="sub-section-text">SUB-SECTION</p>
			<h3 className="sub-section-heading">{name}</h3>
			<div className="sub-section-details">
				<div className="content-details">
					<img
						src={clock_grey}
						className="clock"
						alt="clock"
					/>
					<p className="text">Watch Time: </p>
					<p className="count">{watchTime}</p>
				</div>
				<div className="content-details">
					<img
						src={docs}
						alt="docs"
					/>
					<p className="text">Assignments: </p>
					<p className="count">{assignmentCount}</p>
				</div>
			</div>
			{id === selectedModuleId ? null : (
				<AccioButton
					title="Go to section"
					className="go-to-section-btn"
					icon={
						<img
							src={angle_right}
							alt="angle right"
						/>
					}
					action={() => handleGoToSection(id)}
				/>
			)}
		</div>
	);
};

export default CourseSubSection;
