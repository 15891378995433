import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApplicationCards } from '../../redux/jobportal/jobPortalSlice';
import { AppDispatch, RootState } from '../../redux/store';
import ApplicationCard from './ApplicationCard';

const YourApplicationWrapper = ({
	setJobDetailsPopup,
}: any) => {
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);
	const { applicationCardsState } = jobPortalState;

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		if (
			applicationCardsState.applicationCards &&
			applicationCardsState.applicationCards.length === 0
		) {
			dispatch(fetchApplicationCards());
		}
	}, []);
	return (
		<div className="your-application-wrapper">
			{applicationCardsState.loading === true ? (
				<div className="loader-container">
					<Loader
						factVisibility={true}
						width={'100%'}
						height={'100%'}
					/>
				</div>
			) : applicationCardsState.applicationCards &&
			  applicationCardsState.applicationCards.length !==
					0 ? (
				<div className="job-card-wrapper-application view-scroll-bar">
					{applicationCardsState.applicationCards.map(
						(card: any, idx: number) => (
							<ApplicationCard
								data={card}
								key={idx}
								setJobDetailsPopup={setJobDetailsPopup}
							/>
						)
					)}
				</div>
			) : (
				<p>You have no existing applications</p>
			)}
		</div>
	);
};

export default YourApplicationWrapper;
