export class IdleEngagement {
	runCode(questionId: string, assignmentFlag?: string) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('code_run', {
				questionId: questionId,
				assignmentFlag: assignmentFlag,
				time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}

	submitCode(
		questionId: string,
		score: string,
		solved: string,
		topic: string,
		assignmentFlag?: string
	) {
		try {
			//eslint-disable-next-line
			//@ts-ignore
			window.webengage.track('question_submission', {
				questionId: questionId,
				assignmentFlag: assignmentFlag,
				score: score,
				solved: solved,
				topic: topic,
				time: new Date(),
			});
		} catch (e) {
			console.log(e);
		}
	}
}
