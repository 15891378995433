import { RiBubbleChartFill } from 'react-icons/ri';
import { useAppSelector } from '../../../../../redux/store';
import HeatmapCardShare from './HeatmapComponent';

export const HeatMapCard = () => {
	const { heatMapData, isLoading } = useAppSelector(
		(state) => state.gym
	);
	if (heatMapData)
		return (
			<div className="transparent-div">
				<div
					style={{
						transform: 'scale(0.7)',
						backgroundColor: '#fff',
						borderRadius: 8,
						padding: 20,
						width: '70%',
					}}
				>
					<div className="heading-flex">
						<RiBubbleChartFill
							style={{ color: '#E87F4F', fontSize: '24px' }}
						/>
						<p className="heading">HeatMap</p>
					</div>
					<HeatmapCardShare
						data={heatMapData}
						legend={false}
					/>
				</div>
			</div>
		);
	else return null;
};
