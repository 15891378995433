import React from 'react';

interface ProgressBarProps {
	val: number;
	fontSize?: string;
	statusShow?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
	val,
	fontSize,
	statusShow,
}) => {
	const getColorClass = (value: number): string => {
		if (value < 6) {
			return 'container-30';
		} else if (value >= 6 && value < 8) {
			return 'container-60';
		} else if (value >= 8 && value <= 10) {
			return 'container-90';
		}
		return '';
	};

	const getBarColorClass = (value: number): string => {
		if (value < 6) {
			return 'barCompleted-30';
		} else if (value >= 6 && value < 8) {
			return 'barCompleted-60';
		} else if (value >= 8 && value <= 10) {
			return 'barCompleted-90';
		}
		return '';
	};

	const getTextColorClass = (value: number): string => {
		if (value < 6) {
			return 'barCompleted-text-30';
		} else if (value >= 6 && value < 8) {
			return 'barCompleted-text-60';
		} else if (value >= 8 && value <= 10) {
			return 'barCompleted-text-90';
		}
		return '';
	};

	const containerClass = `progress-bar-outer ${getColorClass(
		val
	)}`;
	const barClass = `progress-inner ${getBarColorClass(
		val
	)}`;

	return (
		<div className="progress-bar-outer-container">
			<div className={containerClass}>
				<div className="progress-bar-wrapper">
					<div
						className={barClass}
						style={{ width: `${val * 10}%` }}
					></div>
				</div>
			</div>
			{statusShow ? (
				<div
					className={`progress-info ${getTextColorClass(
						val
					)}`}
					style={{
						fontSize: fontSize,
					}}
				>
					{(val * 10).toFixed(2)}%
				</div>
			) : null}
		</div>
	);
};

export default ProgressBar;
