import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { BiComment } from 'react-icons/bi';
import { BsBook, BsCodeSquare } from 'react-icons/bs';
import { CgProfile } from 'react-icons/cg';
import { FiHome, FiLogOut } from 'react-icons/fi';
import { GrDocumentNotes } from 'react-icons/gr';
import { ImStack } from 'react-icons/im';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import MilitaryTechRoundedIcon from '@mui/icons-material/MilitaryTechRounded';
import { FaPeopleArrows } from 'react-icons/fa';
import { IoPeopleOutline } from 'react-icons/io5';
import { MdEventAvailable, MdWork } from 'react-icons/md';

import { encryptData } from '@acciojob/event-analytics';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import { nanoid } from '@reduxjs/toolkit';
import { AiOutlineCodeSandbox } from 'react-icons/ai';
import { BsFillChatLeftTextFill } from 'react-icons/bs';
import { RootState } from '../../redux/store';
import {
	convertToLowerCaseAndReplaceSpaces,
	isRedirect,
} from '../../utils/events';

type Props = {
	children: JSX.Element;
};

const allTopNavigation = [
	{
		id: nanoid(),
		text: 'home',
		icon: <FiHome />,
		path: '/',
	},
	{
		id: nanoid(),
		text: 'modules',
		icon: <BsBook />,
		path: '/modules',
	},
	{
		id: nanoid(),
		text: 'live doubt',
		icon: <BiComment />,
		path: '/live-doubts',
	},
	{
		id: nanoid(),
		text: 'gym',
		icon: <ImStack />,
		path: '/gym',
	},
	{
		id: nanoid(),
		text: 'contests',
		icon: <MilitaryTechRoundedIcon />,
		path: '/contests',
	},
	{
		id: nanoid(),
		text: 'doubt support',
		icon: <BsFillChatLeftTextFill />,
		path: '/doubt-support',
	},
	{
		id: nanoid(),
		text: 'sessions',
		icon: <FaPeopleArrows />,
		path: '/session',
	},
	{
		id: nanoid(),
		text: 'notes',
		icon: <GrDocumentNotes />,
		path: '/notes',
	},
	{
		id: nanoid(),
		text: 'open editor',
		icon: <BsCodeSquare />,
		path: '/open-editor',
	},
	{
		id: nanoid(),
		text: 'my calendar',
		icon: <CalendarTodayIcon />,
		path: '/mycalendar',
	},
	{
		id: nanoid(),
		text: 'soft skills',
		icon: <Diversity2Icon />,
		path: '/softskills',
	},
	{
		id: nanoid(),
		text: 'projects',
		icon: <AiOutlineCodeSandbox />,
		path: '/projects',
	},
	{
		id: nanoid(),
		text: 'refer & earn',
		icon: <AttachMoneyIcon />,
		path: '/refer-and-earn',
	},
	{
		id: nanoid(),
		text: 'job portal',
		icon: <MdWork />,
		path: '/job-portal',
	},
	{
		id: nanoid(),
		text: 'Important Docs',
		icon: <BsBook />,
		path: '/mentor-docs',
	},
	{
		id: nanoid(),
		text: 'Sessions',
		icon: <FaPeopleArrows />,
		path: '/mentor-sessions',
	},
	{
		id: nanoid(),
		text: 'Availability',
		icon: <MdEventAvailable fontSize={'24px'} />,
		path: '/mentor-availability',
	},
	{
		id: nanoid(),
		text: 'profile',
		icon: <CgProfile />,
		path: '/mentor-profile',
	},
	{
		id: nanoid(),
		text: 'Contact Batch Manager',
		icon: <IoPeopleOutline />,
		path: '/contact-batch-manager',
	},
];

const allBottomNavigation = [
	{
		id: nanoid(),
		text: 'profile',
		icon: <CgProfile />,
		path: '/profile',
	},
];

const NavigationDrawer = ({ children }: Props) => {
	const userState = useSelector((state: any) => state.user);
	const courseState = useSelector(
		(state: any) => state.course
	);
	const location = useLocation();

	const [activeNavId, setActiveNavId] =
		useState<string>('');

	const highlightActiveNav = () => {
		const currPath = location.pathname;
		let activeNav: any = allTopNavigation.find(
			(el) => el.path === currPath
		);
		if (activeNav === undefined) {
			activeNav = allBottomNavigation.find(
				(el) => el.path === currPath
			);
		}
		console.log('active Nav', activeNav);
		console.log('currPath', currPath);
		if (activeNav !== undefined)
			setActiveNavId(activeNav.id);
	};

	useEffect(() => {
		highlightActiveNav();
	}, [location]);

	const { batchManagerPhoneNumber, courseName, course } =
		courseState;
	console.log('courseState>>>>', courseState);

	const { user, announcement, userTrackDetails } =
		userState;

	const featureController = useSelector(
		(state: RootState) => state.featureController
	);

	const { userRole, activeRoutes } = featureController;

	const navigate = useNavigate();
	const [componentArray, setComponentArray] = useState<
		number[]
	>([1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

	const componentToggler = (num: number) => {
		const temp = [...componentArray];
		for (let i = 0; i < componentArray.length; i++)
			temp[i] = num === i ? 1 : 0;
		setComponentArray([...temp]);
	};

	const checkMentorRoutes = (path: string) => {
		switch (path) {
			case '/mentor':
				return false;
			case '/mentor-profile':
				return false;
			case '/mentor-availability':
				return false;
			case '/mentor-sessions':
				return false;
			case '/mentor-docs':
				return false;
			default:
				return true;
		}
	};

	const logout = () => {
		localStorage.clear();
		Cookies.remove('acciojobs-token', {
			domain: `.acciojob.com`,
		});
		window.open(
			'https://authentication.acciojob.com',
			'_self'
		);
		console.log('logout');
	};

	const [headerMsg, setHeaderMsg] = useState('');
	const [showHeaderNotice, setShowHeaderNotice] =
		useState(false);
	useEffect(() => {
		if (
			announcement !== null &&
			announcement.userCourseMapping
		) {
			if (announcement.header.type === 'HEADER') {
				setShowHeaderNotice(true);
			} else setShowHeaderNotice(false);
			setHeaderMsg(announcement.header.text);
		}
	}, [announcement]);

	return (
		<div
			className={'dashboard-panel'}
			id={'dashID'}
		>
			{showHeaderNotice ? (
				<div className={'banner'}>
					<p>{headerMsg}</p>
				</div>
			) : null}
			<div className={'side-panel'}>
				<div className={'top'}>
					<div className={'brand'}>
						<img
							src={`${window.location.origin}/acciojob_logo.svg`}
							alt={'acciojob'}
						/>
						<div>
							<h3>AccioJob</h3>
						</div>
					</div>
					<ul className={'menu'}>
						{allTopNavigation.map((elm, ind) =>
							activeRoutes[elm.path].active ? (
								<li
									key={elm.id}
									className={`${
										activeNavId === elm.id ? 'active' : ''
									}`}
									// style= {{border: "1px solid red"}}
									onClick={() => {
										if (elm.path !== undefined)
											navigate(elm.path);
										componentToggler(0);
									}}
									event-analytics={
										'deaae0f1-3262-4f94-be12-0997e9b66885'
									}
									data-config={encryptData(
										JSON.stringify({
											type: isRedirect(elm.text)
												? 'REDIRECT'
												: 'API_CALL',
											element: elm.text,

											target: '',
											name: convertToLowerCaseAndReplaceSpaces(
												elm.text
											),
											label: elm.text,
										})
									)}
								>
									{elm.icon}
									<div className={'menu-item-name'}>
										<p>{elm.text}</p>
									</div>
								</li>
							) : userRole === 'launchpad' &&
							  checkMentorRoutes(elm.path) ? (
								<li
									key={elm.id}
									className={`${
										activeNavId === elm.id ? 'active' : ''
									}`}
									onClick={() => {
										navigate('/locked');
									}}
								>
									{elm.icon}
									<div className={'menu-item-name'}>
										<p>{elm.text}</p>
									</div>
								</li>
							) : null
						)}
					</ul>
				</div>

				<div className={'bottom'}>
					<ul className={'menu'}>
						<li
							className={`${
								componentArray[10] === 1 ? 'active' : ''
							}`}
							onClick={() => {
								navigate('/profile');
								componentToggler(10);
							}}
							event-analytics={
								'b3960689-66e4-4892-b751-5b5bbeec5d65'
							}
						>
							<CgProfile />
							<div>
								<p>Profile</p>
							</div>
						</li>
					</ul>
					<div className={'user-info'}>
						<p className={'name-initial'}>
							{user !== null ? user.firstName[0] : null}
						</p>
						<div>
							<div className={'user-details'}>
								<h4>
									{user !== null ? user.firstName : null}{' '}
									{user !== null ? user.lastName : null}
								</h4>
								{/*<p>{user !== null ? user.email : null}</p>*/}
							</div>
							<div
								className={'logout-btn'}
								onClick={logout}
							>
								<FiLogOut />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={'main-window'}
				style={{
					paddingTop: `${
						showHeaderNotice ? '30px' : '0px'
					}`,
				}}
			>
				{children}
			</div>
		</div>
	);
};

export default NavigationDrawer;
