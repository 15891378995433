import {
	PayloadAction,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export interface FileUploadDownloadState {
	questionData: any;
	loading: boolean;
	isSubmitted: boolean;
	error: {
		state: boolean;
		message: string;
	};
}

export const fetchFileUploadDownloadQuestionData =
	createAsyncThunk(
		'fetchFileUploadDownload/fetchFileUploadDownloadQuestionData',
		async (body: any) => {
			const data =
				await services.fileUploadDownloadService.fetchFileUploadDownload(
					body.questionId,
					body.moduleId
				);

			return data;
		}
	);

export const fileAssignmentUploaderData = createAsyncThunk(
	'fileAssignmentUploader/fileUploader',
	async (body: any) => {
		const data =
			await services.fileUploadDownloadService.fileAssignmentUploader(
				body
			);

		return data;
	}
);

const initialState: FileUploadDownloadState = {
	loading: false,
	questionData: null,
	isSubmitted: false,
	error: {
		state: false,
		message: '',
	},
};

export const fileUploadDownloadQuestionSlice = createSlice({
	name: 'assignment',
	initialState,
	reducers: {},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchFileUploadDownloadQuestionData.pending]: (
			state: FileUploadDownloadState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchFileUploadDownloadQuestionData.fulfilled]: (
			state: FileUploadDownloadState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.questionData = action.payload;

			state.error.state = false;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchFileUploadDownloadQuestionData.rejected]: (
			state: FileUploadDownloadState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fileAssignmentUploaderData.pending]: (
			state: FileUploadDownloadState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fileAssignmentUploaderData.fulfilled]: (
			state: FileUploadDownloadState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			console.log('wsdefrgty', action.payload);
			state.isSubmitted =
				action.payload.message === 'Submitted'
					? true
					: false;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fileAssignmentUploaderData.rejected]: (
			state: FileUploadDownloadState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},
	},
});

export default fileUploadDownloadQuestionSlice.reducer;
