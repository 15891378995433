import { useEffect, useState } from 'react';

import { services } from '../../apis/index';
import chatIcon from '../../assets/refer-and-earn/chat-icon.svg';
import classroomIcon from '../../assets/refer-and-earn/classroom-icon.svg';
import emailIcon from '../../assets/refer-and-earn/email-icon.png';
import faqsIcon from '../../assets/refer-and-earn/faqs-image.svg';
import giftIcon from '../../assets/refer-and-earn/gift-icon.svg';
import groupIcon from '../../assets/refer-and-earn/group-icon.svg';
import medalIcon from '../../assets/refer-and-earn/medal-icon.svg';
import mobIcon from '../../assets/refer-and-earn/mob.svg';
import rocketIcon from '../../assets/refer-and-earn/rocket.svg';
import salaryIcon from '../../assets/refer-and-earn/salary-icon.svg';
import shareIcon from '../../assets/refer-and-earn/share-icon.svg';
import userIcon from '../../assets/refer-and-earn/user-icon.png';
import verifiedIcon from '../../assets/refer-and-earn/verified-icon.svg';
import whatsAppicon from '../../assets/refer-and-earn/whatsapp-icon.svg';
import bagIcon from '../../assets/refer-and-earn/₹-bag.svg';
import bag2Icon from '../../assets/refer-and-earn/₹-bag2.svg';
import {
	notifyError,
	notifySuccess,
	notifyWarning,
} from '../../components/notification/index';
import Faq from '../../components/referAndEarnModified/Faq';
import ReferAndEarnReferalCard from '../../components/referAndEarnModified/ReferAndEarnReferralCard';
import ReferralStepCard from '../../components/referAndEarnModified/ReferralStepCard';
import ReferralStyledCard from '../../components/referAndEarnModified/ReferralStyledCard';
import ReferWhyCard from '../../components/referAndEarnModified/referWhyCard';
import { useAppSelector } from '../../redux/store';
import { AxiosError } from 'axios';

const faqs = [
	{
		question: 'Who all can I refer?',
		answer: [
			"Whoever meets the eligibility criteria of our Sapling or Pioneer program can be referred. For more details, visit AccioJob's homepage.",
		],
	},
	{
		question:
			'I have not enrolled in AccioJob’s course yet. Can I still make a referral?',
		answer: [
			'Of course, you can! The “Refer & Earn” option is visible to all who sign up on the website.',
		],
	},
	{
		question:
			'When am I eligible to get the bonus and how is the bonus amount determined?',
		answer: [
			'If you have referred a friend who is a part of the Pioneer program and has signed the ISA agreement, you will get ₹1500 cash. And in case of someone who has made the full payment of the course prior to joining the batch, be it Sapling or Pioneer, you get ₹3000 cash.',
		],
	},
	{
		question:
			'What does my friend get if he/she registers with my referral code?',
		answer: [
			'He/She will get a scholarship of ₹5000 on the course fee if they choose to pay the full amount Upfront. ',
		],
	},
	{
		question: 'Who can I not refer using my referral code?',
		answer: [
			'You cannot refer yourself using your referral code and someone who has already enrolled in the course before the referral code is generated.',
		],
	},
	{
		question: 'Is there any expiry for my referral code?',
		answer: [
			'Yes, your code is valid for 60 days from the day your friend signs up on our platform using your referral code or link. If he/she registers for any course during the first 60 days after signing up and completes an ISA or upfront payment, you’re eligible for the rewards. The date on which you’ve shared the code with them or entered their details on the referral form is not considered for the code validity period.',
		],
	},
	{
		question:
			'Is there any limit to the number of people I can refer?',
		answer: [
			'No, there is no limit to the number of people you can refer. You can refer as many as you want!',
		],
	},
	{
		question:
			'What happens if my friend forgets to input the referral code on sign-up?',
		answer: [
			'In this case, we would not be able to help you. This will not be counted as a successful referral.',
		],
	},
	{
		question:
			'Can I avail the student referral discount if I have already availed some other discount?',
		answer: [
			"No, you can't. Multiple discounts can’t be clubbed together. For example, if you already have a Pratigya offer, you will not be given a discount once you sign up using a referral code.",
		],
	},
	{
		question:
			'My query is not covered here and I have a problem with my referral, whom can I get in touch with?',
		answer: [
			<>
				Please reach out to us at{' '}
				<a
					className="font-semibold hover:underline"
					href="mailto:contactus@acciojob.com?subject=Query%20for%20Payment%20Page%20Id:%20pl_JZRhZVN9ursMdV"
				>
					contactus@acciojob.com
				</a>{' '}
				and we will reach out to you with a response within
				72 working hours.
			</>,
		],
	},
];

const ReferAndEarn = () => {
	const [referralData, setReferralData] = useState<any[]>([
		{
			title: 'Earned Cash',
			stat: '₹0',
			statsClass: 're-color-purple',
			image: 'assets/refer-and-earn/dollar-icon.svg',
			table: {
				columns: [
					'S.No.',
					'Name',
					'Email Id',
					'Cash Earned',
				],
				data: [],
			},
		},
		{
			title: 'Your Referrals',
			stat: '-',
			statsClass: 're-color-green',
			image: 'assets/refer-and-earn/referral-icon.svg',
			table: {
				columns: [
					'S.No.',
					'Name',
					'Reg Date',
					'Status',
					'Cash Earned',
				],
				data: [],
			},
		},
		{
			title: 'Potential Earnings',
			stat: '₹0',
			statsClass: 're-color-orange',
			image: 'assets/refer-and-earn/notepad-icon.svg',
			table: {
				columns: [
					'S.No.',
					'Name',
					'Email Id',
					'Cash To Be Earned',
				],
				data: [],
			},
		},
	]);

	const [referralCode, setReferralCode] =
		useState<string>('');

	const [fullName, setFullName] = useState<string>('');
	const [phoneNumber, setPhoneNumber] =
		useState<string>('');
	const [email, setEmail] = useState<string>('');
	const { user } = useAppSelector(
		(state: any) => state.user
	);

	const getURL = async () => {
		const response: any =
			await services.referralService.getReferralUrl();
		setReferralCode(response.referralCode);
	};

	const getData = async () => {
		const response: any =
			await services.referralService.getReferralData();
		referralData[1].stat = response.length;
		referralData[1].table.data = response.map((r: any) => ({
			name: `${r.user.firstName} ${r.user.lastName}`,
			regDate: `${r.created_at}`,
			status: `${r.status}`,
			cashEarned: r.amount === null ? 0 : r.amount,
		}));
		referralData[2].stat = `₹${response.length * 3000}`;
		referralData[2].table.data = response.map((r: any) => ({
			name: `${r.user.firstName} ${r.user.lastName}`,
			email: r.user.email,
			cashEarned: `₹3000`,
		}));
		setReferralData([...referralData]);
	};

	const isValidEmail = (email: string) => {
		const emailRegex = /\S+@\S+\.\S+/;
		return emailRegex.test(email);
	};

	const isValidPhone = (phoneNumber: string) => {
		const phoneRegex = /^\d{10}$/;
		return phoneRegex.test(phoneNumber);
	};

	const handlePotentialSubmit = async (e: any) => {
		e.preventDefault();
		const trimmedName = fullName.trim();
		const trimmedEmail = email.trim();
		const trimmedPhone = phoneNumber.trim();

		const hasEmptyFields =
			!trimmedName || !trimmedEmail || !trimmedPhone;

		const hasInvalidFields =
			!isValidEmail(trimmedEmail) ||
			!isValidPhone(trimmedPhone);

		if (hasEmptyFields) {
			notifyWarning('Please fill all details!');
		} else if (hasInvalidFields) {
			notifyError('Please enter valid details!');
		} else {
			try {
				const response =
					await services.referralService.potentialReferral(
						trimmedName,
						trimmedEmail,
						trimmedPhone
					);
				if (response && response?.errorMessage) {
					notifyError(response.errorMessage);
				} else if (response && response?.message) {
					notifySuccess(
						'Referral code sent successfully !!'
					);
				} else {
					notifyError(
						'Something went wrong! Please try again'
					);
				}
			} catch (error) {
				notifyError(
					(
						(error as AxiosError).response?.data as {
							errorMessage: string;
						}
					).errorMessage
				);
			}
		}
	};

	useEffect(() => {
		getURL();
		getData();
	}, []);

	const getCourseName = () => {
		if (user?.roles) {
			if (user.roles.length > 0) {
				if (user.roles[0] === 'data-maincourse-user') {
					return 'Data Analytics course';
				} else if (user.roles[0] === 'maincourse-user') {
					return 'Full Stack Development course';
				}
			}
		}
		return 'course';
	};

	const dataStepcard = [
		{
			color: 'red',
			iconSource: chatIcon,
			text1: 'Step 1',
			text2: `Refer your friend to our ${getCourseName()}`,
		},
		{
			color: 'purple',
			iconSource: verifiedIcon,
			text1: 'Step 2',
			text2:
				'Ask them to sign up using your referral link or code.',
		},
		{
			color: 'green',
			iconSource: giftIcon,
			text1: 'Step 3',
			text2:
				'Earn upto ₹3,000 in cash for each successful referral.',
		},
	];

	return (
		<div>
			<div
				style={{
					padding: '20px',
					height: 'calc(100vh - 50px)',
					overflow: 'auto',
				}}
			>
				<div>
					<h2>Refer & Earn upto ₹3000 per referral</h2>
				</div>
				<div className="referandearn-layout">
					<div className="re-stats-row">
						{referralData.map((stats, index) => (
							<ReferAndEarnReferalCard
								key={index}
								stats={stats}
								index={index}
								referralData={referralData}
							/>
						))}
					</div>

					<div className="re-referral-section">
						<div className="re-referral-section-title">
							Share With Your Friends
						</div>
						<div className="re-referral-link-row">
							<div className="re-referral-link-container">
								<div className="re-referral-link-display">
									<div className="re-referral-link-title">
										Referral Link:{' '}
									</div>
									<div className="re-referral-link">
										https://acciojob.com/?ref={referralCode}
									</div>
								</div>
								<div
									className="re-copy-link"
									onClick={() => {
										navigator.clipboard.writeText(
											`https://acciojob.com/?ref=${referralCode}`
										);
										notifySuccess('Copied to clipboard!');
									}}
									event-analytics={
										'd7336ff9-5c07-4202-a885-f42b05d2c0f9'
									}
								>
									COPY LINK
								</div>
							</div>
							<div className="re-link-share-container">
								<div className="re-share-via-title">
									Share via
								</div>
								<div className="re-share-icons-container">
									<img
										src={whatsAppicon}
										alt="Whatsapp Icon"
										className="re-whatsapp-icon"
										onClick={() => {
											window.open(
												`https://web.whatsapp.com/send?text=https://acciojob.com/?ref=${referralCode}`
											);
										}}
										event-analytics={
											'f186192a-6a1c-4bb6-be73-f3c16b7be091'
										}
									/>
									{/* <img
										src={linkedinicon}
										alt="Linkedin Icon"
										className="re-linkedin-icon"
										onClick={() => {
											window.open(
												`https://www.linkedin.com/shareArticle?mini=true&url=https://acciojob.com/?ref=${referralCode}&title=https://acciojob.com/?ref=${referralCode}&summary=https://acciojob.com/?ref=${referralCode}&source=LinkedIn`
											);
										}}
									/> */}
									<img
										src={shareIcon}
										alt="Share Icon"
										className="re-share-icon"
										onClick={() => {
											navigator.clipboard.writeText(
												`https://acciojob.com/?ref=${referralCode}`
											);
											notifySuccess('Copied to clipboard!');
										}}
									/>
								</div>
							</div>
						</div>
						<div className="re-user-details-container">
							<div className="re-user-details-title">
								Or fill in their details, our team will
								reach out to them
							</div>
							<form className="re-user-details-form">
								<div className="re-user-details-inputs">
									<div className="re-user-details-icon-input">
										<img
											src={userIcon}
											className="re-input-icon"
											alt="User Icon"
										/>
										<input
											type="text"
											placeholder="Full Name"
											className="re-user-details-input"
											value={fullName}
											onChange={(e) =>
												setFullName(e.target.value)
											}
										/>
									</div>
									<div className="re-user-details-icon-input">
										<img
											src={emailIcon}
											className="re-input-icon"
											alt="Email Icon"
										/>
										<input
											type="email"
											placeholder="Email"
											className="re-user-details-input"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>
									<div className="re-user-details-icon-input">
										<img
											src={mobIcon}
											className="re-input-icon"
											alt="User Icon"
										/>
										<input
											type="text"
											placeholder="Phone Number"
											className="re-user-details-input"
											value={phoneNumber}
											onChange={(e) => {
												if (e.target.value.length > 10)
													return;
												setPhoneNumber(e.target.value);
											}}
										/>
									</div>
								</div>
								<button
									className="re-user-details-button"
									onClick={(e) => handlePotentialSubmit(e)}
									event-analytics={
										'b76af1f4-d975-4fa2-88a7-3eca356d4bc1'
									}
								>
									<img
										src={require('../../assets/refer-and-earn/submit-icon.png')}
										alt="Submit Icon"
										className="re-submit-icon"
									/>
									Submit
								</button>
							</form>
						</div>
					</div>

					<div className="re-how-it-works-section">
						<div className="re-how-it-works-title">
							How it{' '}
							<span className="re-yellow-text">Works?</span>
						</div>
						<div className="re-how-it-works-subtitle">
							{`Invite your friends to sign up for our ${getCourseName()} and help them get`}
							<br />{' '}
							<span className="re-yellow-text">
								a ₹5000 scholarship on the course and get a
								cash reward upto ₹3000. Win-Win, No??
							</span>
						</div>
						<div className="re-how-it-works-steps-container">
							{dataStepcard.map((step, index) => (
								<ReferralStepCard
									color={step.color}
									iconSource={step.iconSource}
									text1={step.text1}
									text2={step.text2}
								/>
							))}
							<div className="re-how-t-works-line" />
						</div>
					</div>

					<div className="re-referral-benefits-section">
						<div className="re-referral-benefits-title">
							Referral{' '}
							<span className="re-blue-text">
								Benefits!
							</span>
						</div>
						<div className="re-referral-benefits-row">
							{/* <div className="re-referral-benefits-card re-orange-card">
								<div className="re-referral-benefits-card-title re-orange-title"></div>
								<div className="re-referral-benefits-card-subtitle re-orange-subtitle">
									<br />
									<span className="re-subtitle-highlight re-orange-highlight"></span>
									<img
										src={bagIcon}
										alt="₹ Bag"
										className="re-ruppee-bag"
									/>
									<br />
								</div>
							</div>
							<div className="re-referral-benefits-card re-blue-card">
								<div className="re-referral-benefits-card-title re-blue-title">
									What your friend will get
								</div>
								<div className="re-referral-benefits-card-subtitle re-blue-subtitle">
									Your friend gets <br />
									<span className="re-subtitle-highlight re-blue-highlight">
										₹5000
									</span>
									<br />
								</div>
							</div> */}

							<ReferralStyledCard
								color={'orange'}
								srcIcon={bagIcon}
								t1={'What will you get'}
								t2={'You get upto'}
								t3={'₹3000'}
								t4={
									"referral bonus after your friend's enrollment!"
								}
							/>
							<ReferralStyledCard
								color={'blue'}
								srcIcon={bag2Icon}
								t1={'What your friend will get'}
								t2={'Your friend gets'}
								t3={'₹5000'}
								t4={'scholarship on our course!'}
							/>
						</div>
					</div>

					<div className="re-referral-reasons-section">
						<div className="re-referral-reasons-title">
							Why you should{' '}
							<span className="re-yellow-title">
								refer a friend
							</span>
						</div>
						<div className="re-referral-reasons-row">
							<div className="re-referral-reasons-column">
								<ReferWhyCard
									srcIcon={medalIcon}
									color={'orange'}
									t1={'41 LPA'}
									t2={'Highest Salary'}
								/>
								<ReferWhyCard
									srcIcon={classroomIcon}
									color={'dark-green'}
									t1={'  100%'}
									t2={'Job Guarantee '}
								/>
							</div>
							<div className="re-referral-reasons-column">
								<img
									src={rocketIcon}
									alt="Rocket"
									className="re-rocket-image"
								/>
								<div className="title">
									<span
										className="yellow-title"
										style={{ fontSize: '80px' }}
									>
										1000+
									</span>
									<p style={{ marginTop: '10px' }}>
										students have earned through
									</p>{' '}
									<span className="yellow-title">
										Referrals!
									</span>
								</div>
								{/* <button className="refer-now-button">
                  <span>Refer Now</span>{" "}
                  <img
                    src={require("../../assets/refer-and-earn/rocket-icon.svg")}
                    alt="Rocket Icon"
                    className="rocket-icon"
                  />
                </button> */}
							</div>
							<div className="re-referral-reasons-column">
								<ReferWhyCard
									srcIcon={groupIcon}
									color={'green'}
									t1={'300+'}
									t2={'Partner Companies'}
								/>

								<ReferWhyCard
									srcIcon={salaryIcon}
									color={'blue'}
									t1={'8.3 LPA'}
									t2={'Average Salary'}
								/>
							</div>
						</div>
					</div>

					<div className="re-referral-faqs-section">
						<div className="re-referral-faqs-title">
							Frequently Asked Questions{' '}
							<img
								src={faqsIcon}
								alt="FAQs"
								className="faqs-image"
							/>
						</div>
						<div className="re-referral-faqs-subtitle">
							Everything you need to know about our Refer &
							Earn Programme
						</div>
						<div className="re-referral-faqs-content">
							{faqs.map((faq, index) => (
								<Faq
									faq={faq}
									index={index}
								/>
							))}
						</div>
						<div className="re-referral-faqs-more-content">
							<div className="title">
								Still have more questions?
							</div>
							<button
								className="contact-us-button"
								onClick={() =>
									window.open(
										'https://api.whatsapp.com/send?phone=918595563221&text=Hey%20Team%2c%20I%20want%20to%20know%20more%20about%20your%20referral%20programme.%0a%0aThanks&source=&data='
									)
								}
								event-analytics={
									'ff01ef01-4086-4f03-8c2b-78d0ca532471"'
								}
							>
								Contact Us
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ReferAndEarn;
