import Box from '@mui/joy/Box';
import Tooltip from '@mui/joy/Tooltip';

import { ClickAwayListener } from '@mui/material';

type AccioTooltipProps = {
	children: any;
	position: 'top' | 'bottom' | 'left' | 'right';
	tooltipDisplayItem: React.ReactNode;
	arrow: boolean;
	variant: 'outlined' | 'soft' | 'solid';
};

/**
 * Renders a tooltip component.
 *
 * @param {AccioTooltipProps} props - The component props.
 * @returns {React.ReactNode} - The rendered tooltip component.
 */
const AccioHoverTooltip = (
	props: AccioTooltipProps
): React.ReactNode => {
	const {
		children,
		position,
		tooltipDisplayItem,
		arrow,
		variant,
	} = props;

	return (
		<Tooltip
			placement={position}
			variant={variant}
			// leaveDelay={100000000}
			arrow={arrow}
			title={
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'column',
						maxWidth: 320,
						justifyContent: 'center',
						p: 1,
					}}
				>
					{tooltipDisplayItem}
				</Box>
			}
		>
			{children}
		</Tooltip>
	);
};

export default AccioHoverTooltip;
