import { type FC } from 'react';
import Dropdown, {
	SearchableDropdownOptionType,
} from '../shared/SearchableDropdown';

type GymDropdownPropsType = {
	shouldShowResetButton: boolean;
	value?: string | string[];
	placeholder: string;
	options: Array<SearchableDropdownOptionType>;
	isMulti?: boolean;
	onSelect: (value: string | string[] | null) => void;
	showSearch?: boolean;
	label?: string;
};

export const GymDropdown: FC<GymDropdownPropsType> = ({
	onSelect,
	options,
	placeholder,
	shouldShowResetButton,
	value,
	isMulti,
	showSearch,
	label,
}) => {
	return (
		<div>
			<div className="gym-filter-header">
				<label>{label}</label>
				{shouldShowResetButton ? (
					<div
						className="gym-filter-reset"
						onClick={() => onSelect(isMulti ? [] : null)}
					>
						Reset
					</div>
				) : null}
			</div>
			<Dropdown
				value={value}
				placeholder={placeholder}
				options={options}
				onSelect={onSelect}
				outerContainerStyle={{
					width: '200px',
				}}
				isMulti={isMulti}
				showSearch={showSearch}
			/>
		</div>
	);
};
