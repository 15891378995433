import { Loader } from '@acciojob/loader';
import {
	PieChart,
	pieArcLabelClasses,
} from '@mui/x-charts/PieChart';
import { useEffect, useState } from 'react';
import { BiPieChartAlt } from 'react-icons/bi';
import { useAppSelector } from '../../../../../redux/store';

export const QuestionAnalysisCard = () => {
	const [loading, setLoading] = useState(true);
	const { userQuestionSubmissionData } = useAppSelector(
		(state) => state.gym
	);

	const [data, setData] = useState([
		{ value: 0, label: 'Easy', color: '#34D399' },
		{ value: 0, label: 'Medium', color: '#F9C74F' },
		{ value: 0, label: 'Hard', color: '#F94144' },
	]);

	const size = {
		width: 400,
		height: 200,
	};

	const TOTAL = data
		.map((item) => item.value)
		.reduce((a, b) => a + b, 0);

	const getArcLabel = (params: any) => {
		const percent = params.value / TOTAL;
		return `${(percent * 100).toFixed(0)}%`;
	};

	useEffect(() => {
		if (userQuestionSubmissionData) {
			console.log(
				'userQuestionSubmissionData123>>>',
				userQuestionSubmissionData
			);
			if (userQuestionSubmissionData.questionAnalysis) {
				const myData = data;
				if (myData.length >= 3) {
					myData[0].value = parseInt(
						userQuestionSubmissionData.questionAnalysis[0]
							.solved
					);

					myData[1].value = parseInt(
						userQuestionSubmissionData.questionAnalysis[1]
							.solved
					);

					myData[2].value = parseInt(
						userQuestionSubmissionData.questionAnalysis[2]
							.solved
					);
					setData(myData);
					setLoading(false);
				}
			}
		}
	}, [userQuestionSubmissionData]);

	console.log({ QuestionAnalysisCard: data });

	if (!loading)
		return (
			<div
				className="transparent-div"
				style={{ marginLeft: '-20px' }}
			>
				<div className="heading-flex">
					<BiPieChartAlt
						style={{
							color: '#2F80ED',
							fontSize: '24px',
							margin: '20px 0px',
						}}
					/>
					<p className="heading">Question Analaysis</p>
				</div>
				<PieChart
					colors={['#34D399', '#F9C74F', '#F94144']}
					series={[
						{
							arcLabel: (item) => getArcLabel(item),
							arcLabelMinAngle: 45,
							data,
						},
					]}
					sx={{
						'& .MuiResponsiveChart-container': {
							position: 'relative !important',
						},
						[`& .${pieArcLabelClasses.root}`]: {
							fill: 'white',
							fontWeight: 'bold',
						},
						'& .MuiChartsLegend-series': {
							display: 'none',
							padding: '20px',
						},
					}}
					{...size}
				/>
				<div className="legend-wrapper">
					{data.map((item, i) => (
						<div
							key={i}
							className="legend-div"
						>
							<div
								className="bg-color-box"
								style={{ backgroundColor: item.color }}
							></div>
							<p className="heading">{item.value}</p>
							<p className="level">{item.label}</p>
						</div>
					))}
				</div>
				<p className="grey-2">
					Questions Solved{' '}
					<span
						style={{ color: '#2B2F34', fontWeight: 700 }}
					>
						{TOTAL}
					</span>
				</p>
			</div>
		);
	else {
		return (
			<div
				className="transparent-div"
				style={{ gap: '0px' }}
			>
				<Loader factVisibility={false} />
			</div>
		);
	}
};
