import { QuestionType } from './../apis/StudentLectureFeedbackService';
export interface UserResponseInterface {
	userResponse: string;
	metaData?: any;
	studentAssessmentTestId: string;
}

export enum QuestionTypeEnum {
	SUBJECTIVE = 'SUBJECTIVE',
	SINGLE_SELECT = 'MCQ',
	PSEUDO_CODE = 'PSEUDO-CODE',
}

export interface AiStudentAssessmentState {
	assessmentId: string | null;
	questionStatement: string;
	questionType: QuestionTypeEnum;
	testStarted: boolean;
	options?: string[];
	expectedTimeToSolve?: number;
	isTestCompleted: boolean;
	loading: boolean;
	count: number;
	report: Record<string, any> | null;
	totalQuestions: number;
}
