import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
	deleteWorkExperience,
	fetchAcademicDetails,
	fetchContestData,
	fetchExperienceDetails,
	fetchPerformanceUpdates,
	fetchPersonalDetails,
	updateAcademicDetails,
	updateExperienceDetails,
	updatePersonalDetails,
} from './profile.thunk';
import { ProfileDataInterface } from './profile.type';

const initialState: ProfileDataInterface = {
	loading: false,
	contestData: { loading: false, data: null },
	performanceData: { loading: false, data: null },
	personalDetails: { loading: false, data: null },
	academicDetails: { loading: false, data: null },
	experienceDetails: { loading: false, data: null },
};
export const ProfileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		updateProfilePhoto(state, action) {
			if (state.personalDetails.data) {
				state.personalDetails.data.profileImage =
					action.payload;
			}
		},
	},
	extraReducers: {
		// Contest Data
		[fetchContestData.pending.type]: (
			state,
			action: ReturnType<typeof fetchContestData.pending>
		) => {
			state.contestData.loading = true;
		},
		[fetchContestData.fulfilled.type]: (
			state,
			action: ReturnType<typeof fetchContestData.fulfilled>
		) => {
			state.contestData.data = action.payload;
			state.contestData.loading = false;
		},
		[fetchContestData.rejected.type]: (
			state,
			action: ReturnType<typeof fetchContestData.rejected>
		) => {
			state.contestData.loading = false;
		},
		// Performance Details
		[fetchPerformanceUpdates.pending.type]: (
			state,
			action: ReturnType<
				typeof fetchPerformanceUpdates.pending
			>
		) => {
			state.performanceData.loading = true;
		},
		[fetchPerformanceUpdates.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchPerformanceUpdates.fulfilled
			>
		) => {
			state.performanceData.data = action.payload;
			state.performanceData.loading = false;
		},
		[fetchPerformanceUpdates.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchPerformanceUpdates.rejected
			>
		) => {
			state.performanceData.loading = false;
		},
		// Personal Details
		[fetchPersonalDetails.pending.type]: (
			state,
			action: ReturnType<
				typeof fetchPersonalDetails.pending
			>
		) => {
			state.personalDetails.loading = true;
			state.personalDetails.data = null;
		},
		[fetchPersonalDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchPersonalDetails.fulfilled
			>
		) => {
			state.personalDetails.data = action.payload;
			state.personalDetails.loading = false;
		},
		[fetchPersonalDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchPersonalDetails.rejected
			>
		) => {
			state.personalDetails.loading = false;
			state.personalDetails.data = null;
		},
		// Update Personal Details
		[updatePersonalDetails.pending.type]: (
			state,
			action: ReturnType<
				typeof updatePersonalDetails.pending
			>
		) => {
			state.personalDetails.loading = true;
			state.personalDetails.data = null;
		},
		[updatePersonalDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof updatePersonalDetails.fulfilled
			>
		) => {
			toast.success('Personal Details Updated!');
			state.personalDetails.data = action.payload.data;
			state.personalDetails.loading = false;
		},
		[updatePersonalDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof updatePersonalDetails.rejected
			>
		) => {
			state.personalDetails.loading = false;
			state.personalDetails.data = null;
		},
		// Academic Details
		[fetchAcademicDetails.pending.type]: (
			state,
			action: ReturnType<
				typeof fetchAcademicDetails.pending
			>
		) => {
			state.academicDetails.loading = true;
			state.academicDetails.data = null;
		},
		[fetchAcademicDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchAcademicDetails.fulfilled
			>
		) => {
			state.academicDetails.data = action.payload;
			state.academicDetails.loading = false;
		},
		[fetchAcademicDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchAcademicDetails.rejected
			>
		) => {
			state.academicDetails.loading = false;
			state.academicDetails.data = null;
		},
		// Update Academic Details
		[updateAcademicDetails.pending.type]: (
			state,
			action: ReturnType<
				typeof updateAcademicDetails.pending
			>
		) => {
			state.academicDetails.loading = true;
			state.academicDetails.data = null;
		},
		[updateAcademicDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof updateAcademicDetails.fulfilled
			>
		) => {
			state.academicDetails.data = action.payload.data;
			state.academicDetails.loading = false;
		},
		[updateAcademicDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof updateAcademicDetails.rejected
			>
		) => {
			state.academicDetails.loading = false;
			state.academicDetails.data = null;
		},
		// Experience Details
		[fetchExperienceDetails.pending.type]: (
			state,
			action: ReturnType<
				typeof fetchExperienceDetails.pending
			>
		) => {
			state.experienceDetails.loading = true;
			state.experienceDetails.data = null;
		},
		[fetchExperienceDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchExperienceDetails.fulfilled
			>
		) => {
			state.experienceDetails.data = action.payload;
			state.experienceDetails.loading = false;
		},
		[fetchExperienceDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchExperienceDetails.rejected
			>
		) => {
			state.experienceDetails.loading = false;
			state.experienceDetails.data = null;
		},
		// Update Experience Details
		[updateExperienceDetails.pending.type]: (
			state,
			action: ReturnType<
				typeof updateExperienceDetails.pending
			>
		) => {
			state.experienceDetails.loading = true;
			state.experienceDetails.data = null;
		},
		[updateExperienceDetails.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof updateExperienceDetails.fulfilled
			>
		) => {
			toast.success('Experience Updated!');
			state.experienceDetails.data = action.payload;
			state.experienceDetails.loading = false;
		},
		[updateExperienceDetails.rejected.type]: (
			state,
			action: ReturnType<
				typeof updateExperienceDetails.rejected
			>
		) => {
			state.experienceDetails.loading = false;
			state.experienceDetails.data = null;
		},
		// Delete Experience
		[deleteWorkExperience.pending.type]: (
			state,
			action: ReturnType<
				typeof deleteWorkExperience.pending
			>
		) => {
			// state.experienceDetails.loading = true;
			// state.experienceDetails.data = null;
		},
		[deleteWorkExperience.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof deleteWorkExperience.fulfilled
			>
		) => {
			toast.success('Experience Deleted!');
			// state.experienceDetails.data = action.payload;
			// state.experienceDetails.loading = false;
		},
		[deleteWorkExperience.rejected.type]: (
			state,
			action: ReturnType<
				typeof deleteWorkExperience.rejected
			>
		) => {
			// state.experienceDetails.loading = false;
			// state.experienceDetails.data = null;
		},
	},
});

export const profileActions = ProfileSlice.actions;
export const profileReducer = ProfileSlice.reducer;
