import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';

interface EffortGuageProps {
	score: number;
}

const EffortGuage: React.FC<EffortGuageProps> = ({
	score,
}) => {
	const chartRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!chartRef.current) return;
		const myChart: echarts.ECharts = echarts.init(
			chartRef.current
		);
		const option = {
			title: {
				show: false,
			},
			series: [
				{
					type: 'gauge',
					startAngle: 180,
					endAngle: 0,
					progress: {
						show: false,
						width: 10,
					},
					axisLine: {
						roundCap: true,
						lineStyle: {
							width: 5,
							color: [
								[0.3, 'rgb(239,87,76)'],
								[0.6, '#F0B416'],
								[1, '#12B76A'],
							],
						},
					},
					axisTick: {
						show: false,
					},
					splitLine: {
						length: 15,
						lineStyle: {
							width: 0,
							color: '#999',
						},
					},
					axisLabel: {
						show: false,
						distance: 5,
						color: '#999',
						fontSize: 14,
					},
					pointer: {
						icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
						length: '85%',
						width: 6,
						offsetCenter: [0, '0'],
					},
					anchor: {
						show: true,
						showAbove: true,
						size: 10,
						itemStyle: {
							borderWidth: 3,
						},
					},
					detail: {
						show: false,
						valueAnimation: true,
						fontSize: 30,
						offsetCenter: [0, '20%'],
					},
					data: [
						{
							value: score,
						},
					],
				},
			],
		};

		if (option && typeof option === 'object') {
			myChart.setOption(option, true);
		}

		return () => {
			myChart.dispose();
		};
	}, [score]);

	return (
		<div className="container">
			<div
				id="chart"
				ref={chartRef}
			></div>
		</div>
	);
};

export default EffortGuage;
