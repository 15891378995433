export const setLocalStorageWithXDaysExpiry = (
	key: string,
	data: any,
	x: number
) => {
	const now = new Date();
	const item = {
		value: data,
		expiry: now.getTime() + x * 86400000,
	};
	localStorage.setItem(key, JSON.stringify(item));
};

export const getActiveDataFromLocalStorage = async (
	key: string
) => {
	const itemStr = await localStorage.getItem(key);
	// if the item doesn't exist, return null
	if (!itemStr) {
		return null;
	}
	const item = JSON.parse(itemStr);
	const now = new Date();
	// compare the expiry time of the item with the current time
	if (now.getTime() > item.expiry) {
		// If the item is expired, delete the item from storage
		// and return null
		localStorage.removeItem(key);
		return null;
	}
	return item.value;
};

export const localStorageKeyForCurrentCategory =
	'fimfare-current-category';
export const localStorageKeyForPreVotingCategory =
	'fimfare-current-category-in-url';
export const showedMobileThankYouLocalStorageKey =
	'showed-mobile-thankYou';
export const localStorageKeyForLandingPageVisit =
	'visited-filmfare-landed-page';
