import React, {
	ReactElement,
	useEffect,
	useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { RootState } from '../redux/store';

interface RouteInterface {
	path: string;
}

const ActiveRouter = ({ path }: RouteInterface) => {
	const FeatureController = useSelector(
		(state: RootState) => state.featureController
	);
	//

	//
	//
	//

	//
	// const [isRouteActive, setIsRouteActive] = useState(false);

	const { activeRoutes, userRole } = FeatureController;

	console.log(
		'activeRoutes[path]',
		activeRoutes,

		path,
		activeRoutes[path]
	);

	const checkActiveRoute = () => {
		if (activeRoutes[path] === undefined) {
			const pathArr = path.split('/').slice(1);
			console.log('pathArr', path, pathArr);
			if (pathArr.length >= 1) {
				const newPath = '/' + pathArr[0];
				if (activeRoutes[newPath]) {
					return activeRoutes[newPath].active;
				}
			} else return false;
		} else return activeRoutes[path].active;
	};

	return checkActiveRoute() ? (
		<Outlet />
	) : userRole === 'launchpad' ? (
		<Navigate to={'/locked'} />
	) : (
		<Navigate to={'*'} />
	);
};

export default ActiveRouter;
