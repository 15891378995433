import { BsArrowRight } from 'react-icons/bs';
import Button from '../../components/elements/Button';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchSessionType } from '../../redux/onevone/onevoneSlice';
import { encryptData } from '@acciojob/event-analytics';

interface SoftskillCardProps {
	imgSrc: string;
	title: string;
	description: string;
	btnText: string;
	btnVariant: any;
	btnCustomClass?: string;
	btnUrl?: string;
	cardBgColor: string;
	textColor: string;
	btnIcon?: any;
	setOpen: any;
}

const SoftskillCard = ({
	imgSrc,
	title,
	description,
	btnText,
	btnVariant = 'primary',
	btnCustomClass = '',
	btnUrl,
	cardBgColor,
	textColor,
	btnIcon = <BsArrowRight />,
	setOpen,
}: SoftskillCardProps) => {
	const [loading, setLoading] = useState(true);
	const user = useSelector(
		(state: any) => state.user?.user
	);
	const isFutureEligible = useSelector(
		(state: any) => state.onevone?.isfutureEligible
	);
	const dispatch = useDispatch();

	const getSessionData = async () => {
		if (!user) {
			setLoading(false);
			return;
		}

		const userType =
			user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
		const payload = { userType, userId: user.id };
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		// dispatch(fetchSessionType(payload));
		setLoading(false);
	};

	useEffect(() => {
		getSessionData();
	}, [user]);

	const handleButtonClick = () => {
		if (btnText === 'Book Now') {
			if (user && isFutureEligible) {
				window.open('https://course.acciojob.com/session');
			} else {
				setOpen(true);
			}
		} else if (btnUrl) {
			window.open(btnUrl, '_blank');
		}
	};

	const getEventId = () => {
		switch (btnCustomClass) {
			case 'ssPrepare-btn':
				return '00a8843d-2705-409f-9555-5e277ee20adb';

			case 'ssEtiquettes-btn':
				return 'f0b2f275-92b8-453c-9ba7-6073026bba84';

			case 'ssBeforeInterview-btn':
				return 'c4ac771e-5cf9-4a35-8767-b80824c42768';

			case 'ssEmails-btn':
				return '22a7a826-c647-46ab-b6b9-6ecdde7a5582';

			case 'ssPersonality-btn':
				return 'ea626d48-aa5b-40c8-b1f2-7b9872509c72';

			case 'ssResumeReview-btn':
				return '9ec85c08-bb0a-4758-9b63-6d3669b05d27';

			case 'ssAptitude-btn':
				return 'c922c71e-dd58-4a42-bc36-3cd302ca8561';

			case 'ssDiscord-btn':
				return '59c2aef8-3a6a-493d-82bc-070ff75e05c8';

			case 'ssLinkedin-btn':
				return '064bc955-a655-495e-9862-ad10671874af';

			case 'ssResume-btn':
				return 'b51e2d08-8675-411a-9386-379b9df86826';

			default:
				return '';
		}
	};

	return (
		<div
			className="softskill-card"
			style={{ backgroundColor: cardBgColor }}
		>
			<div className="softskill-card-content">
				<p
					className="softskill-card-title"
					style={{ color: textColor }}
					dangerouslySetInnerHTML={{ __html: title }}
				/>

				<p className="softskill-card-description">
					{description}
				</p>
				<div
					style={{
						width: 'fit-content',
						height: 'fit-content',
					}}
					event-analytics={
						btnText === 'Book Now'
							? user && isFutureEligible
								? '9ec85c08-bb0a-4758-9b63-6d3669b05d27'
								: getEventId()
							: ''
					}
				>
					<Button
						title={btnText}
						action={handleButtonClick}
						customClass={btnCustomClass}
						className="softskill-card-btn"
						variant={btnVariant}
						icon={btnIcon}
					/>
				</div>
			</div>
			<div className="softskill-card-img-container">
				<img
					src={imgSrc}
					alt="softskill-img"
				/>
			</div>
		</div>
	);
};

export default SoftskillCard;
