import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { ScheduleMeeting } from 'react-schedule-meeting';
import { services } from '../apis';
import Loader from './Loader';

// import '../styles/interviewBooking.css';

import { IoMdClose } from 'react-icons/io';
import { notifyError, notifySuccess } from './notification';

const InterviewBooking = ({
	open,
	handleClose,
	sessionId,
	reloadData,
	rescheduleId,
	isSessionMentorship,
}: {
	open: boolean;
	handleClose: any;
	sessionId: string;
	reloadData: any;
	rescheduleId: any;
	isSessionMentorship: boolean;
}) => {
	console.log(
		'HArsh',
		open,
		handleClose,
		sessionId,
		reloadData,
		rescheduleId,
		isSessionMentorship
	);
	// const date = moment();
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [showModal, setshowModal] = useState(true);
	const { user, userReportCard } = useSelector(
		(state: any) => state.user
	);

	const [availableSlots, setAvailableSlots] = useState([]);
	const [
		allAvailableSlotsState,
		setAllAvailableSlotsState,
	] = useState<
		{ id: number; startTime: Date; endTime: Date }[]
	>([]);

	const [sendingBookingReq, setSendingBookingReq] =
		useState(false);

	const [success, setSuccess] = useState(false);
	const [response, setResponse] = useState(false);
	const [message, setMessage] = useState('');
	const [prevMentors, setPrevMentors] = useState<
		null | any[]
	>(null);
	const [loadingSlots, setLoadingSlots] = useState(false);
	const [selectedPrevMentor, setSelectedPrevMentor] =
		useState<null | string>(null);
	// useEffect()
	useEffect(() => {
		(async () => {
			setAllAvailableSlotsState([]);
			if (isSessionMentorship)
				await getPastMentors(sessionId);
			else getMentorSlots(null);
		})();
	}, []);
	const getSlots = async (
		date: { toISOString: () => string },
		session: any,
		mentorId: null | string
	) => {
		console.log('GET SLOTS', date, session);
		setLoadingSlots(true);
		const data =
			await services.userService.getAvailableSlots(
				date.toISOString().split('T')[0],
				session,
				mentorId
			);
		return data;
	};

	const generateDatesArray = () => {
		const dates: string[] = [];
		const current = moment();

		let startDayIncrement =
			current.hour() < 23 ||
			(current.hour() === 23 && current.minute() <= 30)
				? 1
				: 2;

		for (let i = 0; i < 3; i++) {
			dates.push(
				current
					.add(startDayIncrement, 'days')
					.format('YYYY-MM-DD')
			);
			startDayIncrement = 1;
		}

		return dates;
	};

	const getMentorSlots = async (
		mentorId: null | string
	) => {
		setLoading(true);
		const datesArray = generateDatesArray();

		const availableSLots1 = await getSlots(
			new Date(datesArray[0]),
			sessionId,
			mentorId
		);
		const availableSLots2 = await getSlots(
			new Date(datesArray[1]),
			sessionId,
			mentorId
		);
		const availableSLots3 = await getSlots(
			new Date(datesArray[2]),
			sessionId,
			mentorId
		);

		console.log(
			'date slots',
			datesArray[0],
			datesArray[1],
			datesArray[2]
		);
		console.log(
			'date slots',
			availableSLots1,
			availableSLots2,
			availableSLots3
		);

		const allAvailableSlots: {
			id: number;
			startTime: Date;
			endTime: Date;
		}[] = [];

		let id = 0;
		const availableSlotsArray = [
			availableSLots1,
			availableSLots2,
			availableSLots3,
		];

		for (const availableSlots of availableSlotsArray) {
			for (let i = 0; i < availableSlots.length; i++) {
				let dt = moment(availableSlots[i], [
					'h:mm A',
				]).format('HH:mm');
				let dtArray = dt.split(':');
				let ed = moment(availableSlots[i], ['h:mm A'])
					.add(30, 'minutes')
					.format('HH:mm');
				let edArray = ed.split(':');
				console.log('times ----', dtArray, edArray);

				const temp = {
					id,
					startTime: new Date(
						new Date(new Date(datesArray[id])).setHours(
							parseInt(dtArray[0]),
							parseInt(dtArray[1]),
							0,
							0
						)
					),
					endTime: new Date(
						new Date(new Date(datesArray[id])).setHours(
							parseInt(edArray[0]),
							parseInt(edArray[1]),
							0,
							0
						)
					),
				};
				allAvailableSlots.push(temp);

				console.log('temp', temp);
			}

			id += 1;
		}

		setAllAvailableSlotsState(allAvailableSlots);

		console.log('all Available Slots', allAvailableSlots);
		setLoadingSlots(false);

		setLoading(false);
	};

	const getPastMentors = async (session: any) => {
		const data = await services.userService.getPrevMentors(
			session,
			user.id
		);
		if (data.length === 0) {
			getMentorSlots(null);
			return;
		}
		setPrevMentors(data);

		return data;
	};

	const availableTimeslots = [0, 1, 2].map((id) => {
		return {
			id,
			startTime: new Date(
				new Date(
					new Date().setDate(new Date().getDate() + id)
				).setHours(9, 0, 0, 0)
			),
			endTime: new Date(
				new Date(
					new Date().setDate(new Date().getDate() + id)
				).setHours(17, 0, 0, 0)
			),
		};
	});

	console.log('time slots', availableTimeslots);
	// console.log("all time slots",allAvailableSlots)

	const handleDayChange = async (e: any) => {
		const date = moment(e).add(2, 'd').toDate();

		console.log('Moment Date', date);

		console.log('date', new Date(e).toISOString());
		console.log('date', new Date(e + 1).toISOString());
		console.log('date', new Date(e + 2).toISOString());

		console.log(e);
	};

	const handleBookSlot = async (e: any) => {
		setSendingBookingReq(true);
		console.log('slot', e);
		let time = e.startTime.toTimeString();
		time = moment(time, ['HH:mm']).format('HH:mm:ss');
		console.log('TIME', time);
		// alert('Your Request Has Been Sent');
		if (rescheduleId !== null) {
			console.log(e.startTime);
			const data = services.courseService
				.rescheduleSession(
					e.startTime,
					rescheduleId,
					selectedPrevMentor
				)
				.then((data) => {
					// setSuccess(true);
					// setMessage('Session rescheduled successfully');
					// notifySuccess('Session rescheduled successfully');
					// window.location.reload();
					// setResponse(true);

					if (data.status === 200 || data.status === 201) {
						setSuccess(true);
						setMessage(data.message);
						window.location.reload();
						notifySuccess(
							'Session rescheduled successfully'
						);
					} else {
						console.log('notifyError', data.message);
						setSuccess(false);
						setMessage(data.message);
						notifyError('Error on rescheduling session');
					}
					setResponse(true);
				})
				.catch((err) => {
					console.log('session err', err);
					notifyError(
						err.response.data.message.message.message
					);
					setMessage(
						err.response.data.message.message.message
					);
					setResponse(true);
					// window.location.reload();
				});
		} else {
			const data = services.userService
				.bookSessionWithMentor(
					e.startTime,
					sessionId,
					time,
					selectedPrevMentor
				)
				.then((data) => {
					if (data.status === 200 || data.status === 201) {
						setSuccess(true);
						setMessage(data.message);
						window.location.reload();
					} else {
						setSuccess(false);
						setMessage(data.message);
					}
					setResponse(true);
				});
		}
	};

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			style={{ overflow: 'scroll' }}
		>
			<div className={'modal interviewbookingmodal'}>
				{sendingBookingReq ? (
					<div className={'request-msg'}>
						{response ? (
							<div className={'response-div'}>
								<h3>{message}</h3>
								<button
									className={'ok-btn'}
									onClick={() => {
										setSendingBookingReq(false);
										setshowModal(false);
										handleClose();
										reloadData();
									}}
								>
									OK
								</button>
							</div>
						) : (
							<div className={'process'}>
								<h3>Sending your booking request</h3>
								<Loader />
							</div>
						)}
					</div>
				) : allAvailableSlotsState.length !== 0 ? (
					<div className={'meeting-calender'}>
						<div className={'header'}>
							<h3>Pick your slot</h3>
							<button
								className={'close-btn'}
								onClick={() => {
									setSendingBookingReq(false);
									setshowModal(false);
									handleClose();
								}}
							>
								<IoMdClose />
							</button>
						</div>

						<ScheduleMeeting
							borderRadius={10}
							primaryColor="#2F80ED"
							eventDurationInMinutes={30}
							// scheduleMeetingStyles={{ backgroundColor: '#ebf3fe' }}
							availableTimeslots={allAvailableSlotsState}
							onSelectedDayChange={handleDayChange}
							onStartTimeSelect={(e: any) => {
								sendingBookingReq
									? console.log('first book')
									: handleBookSlot(e);
							}}
							//eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							emptyListContentEl={
								loading ? <Loader /> : null
							}
						/>
					</div>
				) : prevMentors ? (
					<div className="prevmentor_wrapper">
						<h3>
							Select a mentor to book your 1v1 session.
						</h3>
						<p
							className="prevmentor_name"
							onClick={() => getMentorSlots(null)}
						>
							Assign me the best available mentor.
						</p>
						<h3 style={{ margin: '20px' }}>
							Or, choose from the mentors you have a had a
							session with
						</h3>
						<div style={{ margin: '20px' }}>
							{prevMentors &&
								prevMentors.map((sn: any, ind: number) => (
									<p
										key={ind}
										onClick={() => {
											setSelectedPrevMentor(sn.id);
											getMentorSlots(sn.id);
										}}
										className="prevmentor_name"
									>
										{sn.firstName} {sn.lastName}
									</p>
								))}
						</div>
					</div>
				) : allAvailableSlotsState.length === 0 &&
				  loadingSlots ? (
					<div className={'no-slots'}>
						<Loader />
					</div>
				) : (
					<h4>
						No Slots Available for Today.
						<br /> Check Again Tomorrow!
					</h4>
				)}
			</div>
		</Modal>
	);
};

export default InterviewBooking;
