export interface MentorProfile {
	firstName: string | null;
	lastName: string | null;
	whatsappNumber: string | null;
	linkedInProfile: string | null;
	dateOfBirth: Date | null;
	gender: string | null;
	aboutMe: string | null;
	jobStatus: string | null;
	preferredSpeakingLanguages: [] | null;
	collegeDegree: string | null;
	collegeDepartment: string | null;
	graduationYear: string | null;
}

export interface MentorExperienceFormFields {
	companyName: any;
	position: any;
	startDate: any;
	endDate: any;
	description: any;
	isCurrent: any;
	jobType: any;
}

export interface MentorPreferences {
	preferenceOne: string;
	preferenceTwo: string;
	preferenceThree: string;
	preferenceFour: string;
	preferenceFive: string;
}

export interface MentorSkillsTypes {
	programmingLanguages: string[] | [];
	softwareDevelopmentFrameworks: string[] | [];
	mobileDevelopmentSkills: string[] | [];
	databases: string[] | [];
	cloudPlatforms: string[] | [];
	softwareDevelopmentTools: string[] | [];
	otherSkills: string[] | [];
}

export enum ProgrammingLanguage {
	C = 'C',
	CPlusPlus = 'C++',
	CSharp = 'C#',
	Dart = 'Dart',
	Go = 'Go',
	Java = 'Java',
	JavaScript = 'JavaScript',
	Kotlin = 'Kotlin',
	PHP = 'PHP',
	Python = 'Python',
	Ruby = 'Ruby',
	Rust = 'Rust',
	Scala = 'Scala',
	Swift = 'Swift',
	TypeScript = 'TypeScript',
}

export enum SoftwareDevelopmentFramework {
	Angular = 'Angular',
	Django = 'Django',
	Express = 'Express',
	Flask = 'Flask',
	Laravel = 'Laravel',
	Nest = 'Nest',
	Next = 'Next',
	NodeJS = 'NodeJS',
	React = 'React',
	Spring = 'Spring',
	Vue = 'Vue',
}

export enum MobileDevelopmentSkill {
	Android = 'Android',
	Flutter = 'Flutter',
	Ionic = 'Ionic',
	ReactNative = 'React Native',
	Xamarin = 'Xamarin',
	iOS = 'iOS',
}

export enum Database {
	MongoDB = 'MongoDB',
	MySQL = 'MySQL',
	Oracle = 'Oracle',
	PostgreSQL = 'PostgreSQL',
	Redis = 'Redis',
	SQLite = 'SQLite',
}

export enum CloudPlatform {
	AWS = 'AWS',
	Azure = 'Azure',
	GCP = 'GCP',
}

export enum SoftwareDevelopmentTool {
	Docker = 'Docker',
	Git = 'Git',
	IntelliJ = 'IntelliJ',
	Postman = 'Postman',
}

export enum OtherSkill {
	ArtificialIntelligence = 'Artificial Intelligence',
	Blockchain = 'Blockchain',
	CyberSecurity = 'Cyber Security',
	DataScience = 'Data Science',
	MachineLearning = 'Machine Learning',
}

export enum SessionTabs {
	UPCOMING_ONGOING = 'UPCOMING_ONGOING',
	PENDING = 'PENDING',
	DIDNT_HAPPEN = 'DIDNT_HAPPEN',
	COMPLETED = 'COMPLETED',
}

export enum Filters {
	INTERNAL_ZOOM = 'INTERNAL_ZOOM',
	EXTERNAL_GMEET = 'EXTERNAL_GMEET',
	FEEDBACK_PENDING = 'FEEDBACK_PENDING',
	RECORDING_PENDING = 'NOT_UPLOADED',
}
