import { Loader } from '@acciojob/loader';
import { useEffect, useState } from 'react';
import { BiMedal } from 'react-icons/bi';
import { BsArrowRightShort } from 'react-icons/bs';
import { useAppSelector } from '../../../redux/store';
import { AchievementModal } from '../Modals/AchievementModal';
import { VerticleAchievement } from './VerticleAchievement';

export const AchievementsCard = () => {
	const [open, setOpen] = useState(false);
	const [achievements, setAchievements] = useState<
		Array<any>
	>([]);

	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	const getTopPerformerLevel = (streak: number) => {
		let myObj = {
			level: 0,
			total: [1, 2, 3, 4, 5],
			message: [
				'Finished In Top-3 Once',
				'Finished In Top-3 Twice',
				'Finished In Top-3 Thrice',
				'Finished In Top-3 Four Times',
				'Finished In Top-3 Five Times',
			],
		};
		if (streak) {
			if (streak < 1) {
				return { ...myObj, level: 0 };
			} else if (streak == 1) {
				return { ...myObj, level: 1 };
			} else if (streak == 2) {
				return { ...myObj, level: 2 };
			} else if (streak == 3) {
				return { ...myObj, level: 3 };
			} else if (streak == 4) {
				return { ...myObj, level: 4 };
			} else if (streak > 4) {
				return { ...myObj, level: 5 };
			}
		} else {
			return { ...myObj, level: 0 };
		}
	};

	const getChampionLevel = (streak: number) => {
		let myObj = {
			level: 0,
			total: [1, 2, 3, 4, 5],
			message: [
				'Finished First Once',
				'Finished First Twice',
				'Finished First Thrice',
				'Finished First Four Times',
				'Finished First Five Times',
			],
		};
		if (streak) {
			if (streak < 1) {
				return { ...myObj, level: 0 };
			} else if (streak == 1) {
				return { ...myObj, level: 1 };
			} else if (streak == 2) {
				return { ...myObj, level: 2 };
			} else if (streak == 3) {
				return { ...myObj, level: 3 };
			} else if (streak == 4) {
				return { ...myObj, level: 4 };
			} else if (streak > 4) {
				return { ...myObj, level: 5 };
			}
		} else {
			return { ...myObj, level: 0 };
		}
	};

	const getMediumTaskMasterLevel = (streak: number) => {
		let myObj = {
			level: 0,
			total: [5, 25, 75, 150, 250],
			message: [
				'Needs 5 Medium Question Solving',
				'Needs 25 Medium Question Solving',
				'Needs 75 Medium Question Solving',
				'Needs 150 Medium Question Solving',
				'Needs 250 Medium Question Solving',
			],
		};
		if (streak) {
			if (streak <= 5) {
				return { ...myObj, level: 0 };
			} else if (streak <= 25) {
				return { ...myObj, level: 1 };
			} else if (streak <= 75) {
				return { ...myObj, level: 2 };
			} else if (streak <= 150) {
				return { ...myObj, level: 3 };
			} else if (streak <= 250) {
				return { ...myObj, level: 4 };
			} else return { ...myObj, level: 5 };
		} else {
			return { ...myObj, level: 0 };
		}
	};

	const getHardTaskMasterLevel = (streak: number) => {
		let myObj = {
			level: 0,
			total: [10, 50, 100, 200, 300],
			message: [
				'Needs 10 Hard Questions',
				'Needs 50 Hard Questions',
				'Needs 100 Hard Questions',
				'Needs 200 Hard Questions',
				'Needs 300 Hard Questions',
			],
		};

		if (streak) {
			if (streak <= 10) {
				return { ...myObj, level: 0 };
			} else if (streak <= 50) {
				return { ...myObj, level: 1 };
			} else if (streak <= 100) {
				return { ...myObj, level: 2 };
			} else if (streak <= 200) {
				return { ...myObj, level: 3 };
			} else return { ...myObj, level: 4 };
		} else {
			return myObj;
		}
	};

	const getStreakLevel = (streak: number) => {
		let myObj = {
			level: 0,
			total: [7, 15, 30, 50, 100],
			message: [
				'Needs 7 days of Solving',
				'Needs 15 days of Solving',
				'Needs 30 days of Solving',
				'Needs 50 days of Solving',
				'Needs 100 days of Solving',
			],
		};
		if (streak) {
			if (streak < 7) {
				return {
					...myObj,
					level: 0,
				};
			} else if (streak >= 7 && streak < 15) {
				return { ...myObj, level: 1 };
			} else if (streak >= 15 && streak < 30) {
				return { ...myObj, level: 2 };
			} else if (streak >= 30 && streak < 50) {
				return { ...myObj, level: 3 };
			} else if (streak >= 50 && streak < 100) {
				return { ...myObj, level: 4 };
			} else if (streak >= 100) {
				return { ...myObj, level: 5 };
			}
		} else {
			return myObj;
		}
	};

	useEffect(() => {
		if (performanceData.data) {
			const arr = [
				{
					heading: 'Question Streak Badges',
					desc: 'Earned From Continuous Question Solving',
					streak: performanceData.data?.streak,
					...getStreakLevel(performanceData.data?.streak),
				},
				{
					heading: 'Hard Task Master Badges',
					desc: 'Earned From Continuous Hard Question Solving',
					streak: performanceData.data?.hardQuestionsSolved,
					...getHardTaskMasterLevel(
						performanceData.data?.hardQuestionsSolved
					),
				},
				{
					heading: 'Medium Task Master Badges',
					desc: 'Earned From Continuous Medium Difficulty Question Solving',
					streak:
						performanceData.data?.mediumQuestionsSolved,
					...getMediumTaskMasterLevel(
						performanceData.data?.mediumQuestionsSolved
					),
				},
				{
					heading: 'Contest Champion Badges',
					desc: 'Earned By Finishing First in a Contest',
					streak:
						performanceData.data?.totalContestWithRank1,
					...getChampionLevel(
						performanceData.data?.totalContestWithRank1
					),
				},
				{
					heading: 'Top Performer Badges',
					desc: 'Earned By Finishing in the Top 3 of a Contest',
					streak:
						performanceData.data
							?.totalContestsWithTop3Ranks,
					...getTopPerformerLevel(
						performanceData.data?.totalContestsWithTop3Ranks
					),
				},
			];
			setAchievements(arr);
		}
	}, [performanceData]);

	if (achievements)
		return (
			<div className="profile-card achievements-card">
				<div className="heading-flex">
					<BiMedal
						style={{ color: '#2F80ED', fontSize: '1.4rem' }}
					/>
					<p className="heading">Earned Badges</p>
					<div
						className="icon-flex"
						style={{
							marginLeft: 'auto',
							cursor: 'pointer',
						}}
						onClick={() => setOpen(true)}
					>
						<p
							className="heading"
							style={{ color: '#2F80ED' }}
						>
							See All Badges
						</p>
						<BsArrowRightShort
							style={{
								color: '#2F80ED',
								fontSize: '1.4rem',
							}}
						/>
					</div>
				</div>

				<div className="achievements-flex">
					{achievements
						.sort((a, b) => b.level - a.level)
						.slice(0, 3)
						.map((item, i) => (
							<VerticleAchievement
								key={i}
								heading={item.heading}
								level={item.level}
								learnMore={item.desc}
								streak={item.streak}
								total={item.total}
							/>
						))}
				</div>
				<AchievementModal
					state={open}
					setState={setOpen}
					achievements={achievements}
				/>
			</div>
		);
	else {
		return (
			<div className="profile-card achievements-card">
				<Loader />
			</div>
		);
	}
};
