import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../apis';

export const fetchPopUpToDisplay = createAsyncThunk(
	'users/popUpToDisplay',
	async (page: string) =>
		await services.popupService.getPopUpToDisplay(page)
);

export const recordClicks = createAsyncThunk(
	'users/recordClicks',
	async (clickData: any) => {
		return await services.popupService.recordClick(
			clickData
		);
	}
);

export const recordView = createAsyncThunk(
	'users/recordViews',
	async (clickData: any) => {
		return await services.popupService.recordView(
			clickData
		);
	}
);
