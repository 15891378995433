import { useEffect, useState } from 'react';
// import './../../styles/aptitude.scss';
import { encryptData } from '@acciojob/event-analytics';
import queryString from 'query-string';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoIosArrowBack } from 'react-icons/io';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import {
	Link,
	useLocation,
	useNavigate,
} from 'react-router-dom';
import { services } from '../../apis';
import submitted from '../../assets/lottie/submitted.json';
import Loader from '../../components/Loader';
import {
	fetchAptitudeQuestions,
	submitAptitudeQuestions,
} from '../../redux/modules/moduleSlice';
import { isMainCourseUser } from '../../utils/events';
import IntegerType from './components/Integer';
import Mcq from './components/Mcq';
import MultiSelect from './components/MultiSelect';
import Subjective from './components/Subjective';
import { DifficultyTypeEnum } from '../../redux/gym/type';

const Aptitude = () => {
	const location = useLocation();
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const moduleId: string = queryString.parse(
		location.search
	).moduleId;
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const questionIdFromGym: string = queryString.parse(
		location.search
	).questionId;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const moduleState = useSelector(
		(state: any) => state.module
	);

	const {
		aptitudeQuestions,
		submitAptitudeLoading,
		aptitudeScore,
		alreadySubmitted,
		aptitudeLoading,
	} = moduleState;

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(fetchAptitudeQuestions(moduleId));
	}, []);

	const [questionNumber, setQuestionNumber] = useState(1);
	const [questionStatement, setQuestionStatement] =
		useState('');
	const [options, setOptions] = useState([]);
	const [questionType, setQuestionType] = useState('');
	const [answerId, setAnswerId] = useState(undefined);
	const [answer, setAnswer] = useState<any>([]);

	const [questionId, setQuestionId] = useState<any>(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [showSubmitModal, setShowSubmitModal] =
		useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [submitWarning, setSubmitWarning] = useState(true);
	const [explanation, setExplanation] = useState('');
	const [difficulty, setDifficulty] =
		useState<DifficultyTypeEnum>();

	const [correctAnswer, setCorrectAnswer] = useState([]);

	const [loadAptitudeQuestion, setLoadAptitudeQuestion] =
		useState(false);
	const [score, setScore] = useState(0);

	const getQuestionById = async (questionId: string) => {
		setLoadAptitudeQuestion(true);
		try {
			const res =
				await services.courseService.getAptitudeQuestionById(
					questionId
				);
			setAnswer([]);
			console.log(
				'Aptitude Question temp',
				res,
				res.answer
			);
			if (res.answer !== null) {
				setAnswerId(res.answer.id);
				setAnswer(res.answer.answer);
			} else {
				setAnswerId(undefined);
				setAnswer([]);
			}
			setCorrectAnswer(res.question.correctAnswer);
			setLoadAptitudeQuestion(false);
			setQuestionData(res.question);
			return res.question;
		} catch (e) {
			console.log(e);
		}
	};

	const saveQuestion = async (answerArray: string[]) => {
		const data = {
			conceptualQuestionId: questionId,
			answer: answerArray,
			id: answerId,
		};

		setAnswer(answerArray);

		try {
			const res =
				await services.courseService.saveAptitudeQuestions(
					data
				);
			console.log(
				'Save Aptitude Question temp',
				res,
				res.answer
			);
			return res.question;
		} catch (e) {
			console.log(e);
		}
	};

	console.log('aptitudeQuestions', aptitudeQuestions);

	const [questionData, setQuestionData] =
		useState<any>(null);

	useEffect(() => {
		if (aptitudeQuestions.length > 0) {
			console.log({ questionId });
			if (questionId) getQuestionById(questionId);
			else {
				getQuestionById(aptitudeQuestions[0].id);
			}
		} else {
			setQuestionId(null);
			setQuestionStatement('');
			setQuestionType('');
			setOptions([]);
			setScore(0);
			setIsSubmitted(false);
			setExplanation('');
		}
	}, [aptitudeQuestions, questionId]);

	useEffect(() => {
		if (questionData) {
			setQuestionStatement(questionData?.statement);
			setQuestionType(questionData?.questionType);
			setOptions(questionData?.answerOptions);
			setScore(questionData?.score);
			setIsSubmitted(alreadySubmitted);
			setExplanation(questionData?.explanation);
		}
	}, [questionData, aptitudeQuestions]);

	const submit = () => {
		setShowSubmitModal(true);
		setIsSubmitted(true);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(submitAptitudeQuestions(moduleId));
	};

	return (
		<div className={'aptitude'}>
			{showSubmitModal ? (
				<div className={'overlay'}>
					<div className={'celebration'}>
						{!submitLoading ? (
							<AiOutlineCloseCircle
								className={'pop-up-close'}
								onClick={() => {
									setShowSubmitModal(false);
								}}
							/>
						) : null}
						{submitWarning ? (
							<div className={'pop-up warn'}>
								<h4>
									You are about to submit all questions
								</h4>
								<p>
									Note: Make sure to answer all the
									questions before submitting the assigment
								</p>
								<h5>Press confirm to submit</h5>
								<div style={{ display: 'flex' }}>
									<button
										onClick={() => {
											setSubmitWarning(false);
											submit();
										}}
										style={{ marginRight: '20px' }}
									>
										Confirm
									</button>
									<button
										onClick={() => {
											setShowSubmitModal(false);
										}}
									>
										Let Me Solve All
									</button>
								</div>
							</div>
						) : submitAptitudeLoading ? (
							<div className={'pop-up'}>
								<h3>Submitting</h3>
								<Loader />
								<h4>Your answers are being submitted</h4>
								<p>Please wait...</p>
							</div>
						) : (
							<div className={'pop-up'}>
								<h3>Submitted</h3>
								<Lottie
									className={'congrats'}
									loop={false}
									animationData={submitted}
									play
								/>
								<h4>Your answers have been submitted</h4>
								<p>Your Total Score : {aptitudeScore}</p>
								<button
									onClick={() => {
										setShowSubmitModal(false);
										setIsSubmitted(true);
									}}
								>
									Checkout Correct Answers
								</button>
							</div>
						)}
					</div>
				</div>
			) : null}
			<div className={'column-1'}>
				<div className={'heading'}>
					<h1>
						<span>
							<Link to={`/modules`}>
								<button className="go-back-btn">
									<IoIosArrowBack /> Go back to modules
								</button>
							</Link>
						</span>
						<span>Conceptual Questions</span>
					</h1>
					<h1 className={'total-score'}>
						Your Score : {aptitudeScore}
					</h1>
				</div>
				<p className={'meta-info'}>
					Individual multiple select questions answers are
					auto-saved, press save to save answers for
					subjective and integer type questions. Submit all
					questions to get score
				</p>
				{aptitudeQuestions.length > 0 ? (
					<>
						{!loadAptitudeQuestion ? (
							<div className={'questionContainer'}>
								<div className={'question-bar'}>
									<h1>Question : {questionNumber}</h1>
									<h4>Marks : {score}</h4>
								</div>
								<div className={'question-panel'}>
									{questionType === 'SINGLEMCQ' &&
									!loadAptitudeQuestion ? (
										<Mcq
											statement={questionStatement}
											options={options}
											save={saveQuestion}
											response={answer}
											correct={correctAnswer}
											submitted={alreadySubmitted}
											explanation={explanation}
										/>
									) : null}
									{questionType === 'INT' &&
									!loadAptitudeQuestion ? (
										<IntegerType
											statement={questionStatement}
											options={options}
											save={saveQuestion}
											response={answer}
											correct={correctAnswer}
											submitted={alreadySubmitted}
											explanation={explanation}
										/>
									) : null}
									{questionType === 'TEXT' &&
									!loadAptitudeQuestion ? (
										<Subjective
											statement={questionStatement}
											options={options}
											save={saveQuestion}
											response={answer}
											correct={correctAnswer}
											submitted={alreadySubmitted}
											explanation={explanation}
										/>
									) : null}
									{questionType === 'MULTIMCQ' &&
									!loadAptitudeQuestion ? (
										<MultiSelect
											statement={questionStatement}
											options={options}
											save={saveQuestion}
											response={answer}
											correct={correctAnswer}
											submitted={alreadySubmitted}
											explanation={explanation}
										/>
									) : null}
								</div>
							</div>
						) : (
							<Loader />
						)}
						<button
							className={'submit-btn'}
							onClick={() => {
								setShowSubmitModal(true);
							}}
							disabled={alreadySubmitted}
						>
							Submit All
						</button>
					</>
				) : aptitudeLoading ? (
					<Loader />
				) : (
					<div className={'no-questions'}>
						<h1>No questions available for this module</h1>
						<button
							onClick={() => {
								navigate('/modules');
							}}
							className={'go-back-btn'}
						>
							Go Back
						</button>
					</div>
				)}
			</div>

			<div className={'column-2'}>
				<ul>
					{aptitudeQuestions.map(
						(el: any, index: number) => {
							return (
								<li
									key={el.id}
									className={`${
										el.id === questionId ? 'active' : ''
									}`}
									onClick={() => {
										setQuestionId(el.id);
										setQuestionNumber(index + 1);
									}}
									event-analytics={
										isMainCourseUser()
											? '2014650f-246f-47ae-bb1b-2f380401b97b'
											: ''
									}
									data-config={encryptData(
										JSON.stringify({
											name: questionId,
										})
									)}
								>
									{el.title}
								</li>
							);
						}
					)}
				</ul>
			</div>
		</div>
	);
};

export default Aptitude;
