import { Modal, ModalClose, Sheet } from '@mui/joy';
import React, { useState } from 'react';
import { FiUpload } from 'react-icons/fi';
import { MdPreview } from 'react-icons/md';
import { services } from '../../apis/index';
import AccioButton from '../elements/Button';
import { toast } from 'react-toastify';

const FileUploadButton = ({
	resumeLink,
	setResumeLink,
	btnType,
}: any) => {
	const [open, setOpen] = React.useState<boolean>(false);
	const [uploading, setUploading] = useState(false);

	const handleFileSelect: React.ChangeEventHandler<
		HTMLInputElement
	> = (event) => {
		setFile(event?.target.files?.[0]);
	};

	const handleViewDocument = () => {
		if (resumeLink && resumeLink.length > 0) {
			setOpen(true);
		}
	};

	const [file, setFile] = useState<File | null | undefined>(
		null
	);

	const uploadResume = async () => {
		if (!file) return;

		try {
			setUploading(true);
			const { signedUrl, publicUrl } =
				await services.userService.getResumeSignedUrl(
					file?.name
				);

			await services.fileUploadDownloadService.uploadFile(
				signedUrl,
				file,
				'application/pdf'
			);

			setResumeLink(publicUrl);

			await services.userService.saveResume(publicUrl);

			setUploading(false);
			toast.success('Resume uploaded successfully');
		} catch (error) {
			setUploading(false);
			console.log(error);
		}
	};

	return (
		<div className="file-upload-button">
			<div className="file-upload-button-top">
				<div className="file-upload-button-top-1">
					<input
						className="input-file-browser"
						type="file"
						accept="application/pdf"
						onChange={handleFileSelect}
					/>
					{uploading && (
						<div className="progress-indicator"></div>
					)}
				</div>
				<div className="file-upload-button-top-2">
					<AccioButton
						btnType={btnType}
						customClass="file-upload-button"
						className=""
						variant="outline"
						title={uploading ? 'Uploading...' : 'Upload'}
						icon={<FiUpload />}
						action={uploadResume}
						disabled={!file}
					/>

					{resumeLink && resumeLink.length > 0 && (
						<>
							<AccioButton
								btnType={btnType}
								customClass="file-view-button"
								className=""
								variant="primary"
								title={'View Document'}
								icon={<MdPreview />}
								action={handleViewDocument}
							/>
							<Modal
								aria-labelledby="modal-title"
								aria-describedby="modal-desc"
								open={open}
								onClose={() => setOpen(false)}
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
								}}
							>
								<Sheet
									variant="outlined"
									sx={{
										maxWidth: 800,
										borderRadius: 'md',
										p: 2,
										boxShadow: 'lg',
										zIndex: 2,
									}}
								>
									<ModalClose
										variant="outlined"
										sx={{
											top: 'calc(-1/2 * var(--IconButton-size))',
											right:
												'calc(-1/2 * var(--IconButton-size))',
											boxShadow:
												'0 2px 12px 0 rgba(0 0 0 / 0.2)',
											borderRadius: '50%',
											bgcolor: 'background.body',
										}}
									/>
									<div className="modal-body">
										<iframe
											src={resumeLink}
											width="700px"
											height="700px"
										/>
									</div>
								</Sheet>
							</Modal>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default FileUploadButton;
