import Cookies from 'js-cookie';

const OpenCodeEditor = () => {
	const questionId = '';
	const endpoint = encodeURIComponent(
		'https://acciojob-backend-eobnd7jx2q-el.a.run.app'
	);
	const platform = 'open';
	const type = 'course';
	const contestId = '';
	const source = encodeURIComponent(window.location.origin);
	const hostingPlatform = 'https://ide2.acciojob.com';
	const token = Cookies.get('acciojobs-token');

	return (
		<iframe
			className={'CodeEditor'}
			allow={'fullscreen'}
			src={`${hostingPlatform}/?question=${questionId}&endpoint=${endpoint}&platform=${platform}&type=${type}&contest=${contestId}&source=${source}#accessToken=${token}`}
		/>
	);
};

export default OpenCodeEditor;
