const month = [
	'Jan',
	'Feb',
	'March',
	'April',
	'May',
	'June',
	'July',
	'Aug',
	'Sep',
	'Oct',
	'Nov',
	'Dec',
];

export const formatTime = (date: string) => {
	const hour = new Date(date).getHours();
	// console.log(hour)
	const minutes =
		new Date(date).getMinutes() === 0
			? ''
			: `:${new Date(date).getMinutes()}`;
	const suffix = hour >= 12 ? 'PM' : 'AM';
	const hours = ((hour + 11) % 12) + 1;
	return hours + minutes + suffix;
};

export const formatDate = (date: string) => {
	const dt = new Date(date).getDate();
	const mnth = new Date(date).getMonth();
	return dt + ' ' + month[mnth];
};

export const timeformatter = (
	date: Date,
	locale: boolean
) => {
	const indian = new Date(date).toLocaleString('en-US');

	const dtstr = formatDate(indian);
	const timestr = formatTime(indian);

	return timestr + ' ' + dtstr;
};
