import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import { AiFillTrophy } from 'react-icons/ai';
import { fetchContestData } from '../../../redux/profile/profile.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../redux/store';

import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';

export const ContestRanking = () => {
	const dispatch = useAppDispatch();
	const { contestData } = useAppSelector(
		(state) => state.profile
	);

	console.log('contestData>>>', contestData);

	useEffect(() => {
		dispatch(fetchContestData());
	}, []);

	if (contestData.loading && !contestData.data)
		return (
			<div className="profile-card profile-contest-card">
				<Loader factVisibility={false} />
			</div>
		);
	else
		return (
			<div className="profile-card profile-contest-card">
				<div className="heading-flex">
					<AiFillTrophy
						style={{ color: '#2F80ED', fontSize: '1.4rem' }}
					/>
					<p className="heading">Contest Ranking</p>
				</div>
				<div className="numbers-flex">
					<div className="number-div">
						<p className="grey-text">AVG RANK</p>
						<p className="black-text">
							{contestData.data?.averageRank?.toFixed(0) ===
							'-1'
								? 'NA'
								: contestData.data?.averageRank?.toFixed(0)}
						</p>
					</div>
					<div className="number-div">
						<p className="grey-text">RECENT RANK</p>
						<p className="black-text">
							{contestData.data?.lastRank?.toFixed(0) ===
							'-1'
								? 'NA'
								: contestData.data?.lastRank?.toFixed(0)}
						</p>
					</div>
					<div className="number-div">
						<p className="grey-text">TOP RANK</p>
						<p className="black-text">
							{contestData.data?.maxScore?.toFixed(0) ===
							'-1'
								? 'NA'
								: contestData.data?.maxScore?.toFixed(0)}
						</p>
					</div>
				</div>
				<Chart
					id="my-chart"
					type="line"
					height={150}
					data={{
						labels: [1, 2, 3, 4],
						datasets: [
							{
								label: 'Dataset 1',
								data: [
									contestData.data?.fourthLastRank,
									contestData.data?.thirdLastRank,
									contestData.data?.secondLastRank,
									contestData.data?.lastRank,
								],
								borderColor: '#2F80ED',
								pointBorderWidth: 0,
								pointRadius: 4,
								pointBackgroundColor: '#13335F',
								fill: true,
								backgroundColor: 'rgba(224,234,255,0.4)',
							},
						],
					}}
					options={{
						responsive: true,
						plugins: {
							legend: {
								display: false,
							},
							title: {
								display: false,
							},
						},
						scales: {
							x: {
								display: false,
							},

							y: {
								display: false,
							},
						},
					}}
				/>
			</div>
		);
};
