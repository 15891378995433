import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { BsArrowRightCircle } from 'react-icons/bs';
import { FiArrowRight } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchSessionType } from '../../redux/onevone/onevoneSlice';
import ss1 from './ss1.svg';
import ss10 from './ss10.svg';
import ss2 from './ss2.svg';
import ss3 from './ss3.svg';
import ss4 from './ss4.svg';
import ss5 from './ss5.svg';
import ss6 from './ss6.svg';
import ss7 from './ss7.svg';
import ss8 from './ss8.svg';
import ss9 from './ss9.svg';
// import '../../styles/softskills.scss';
const style = {
	background: '#2F80ED',
	color: '#FFFFFF',
	borderRadius: '50%',
};

const style1 = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #2F80ED',
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',
};
const SoftSkillsDash = () => {
	const openWindow1 = () => {
		window.open(
			'https://acciojob.notion.site/Answering-the-most-common-interview-questions-968a9beb665a43b9b3a77c93753618d0',
			'_blank'
		);
	};
	const [typeUser, setUserType] = useState('USER');
	const [userId, setUserId] = useState('');
	const [loading, setLoading] = useState(true);
	const UserState = useSelector((state: any) => state.user);
	const [showCelebration, setShowCelebration] =
		useState(false);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const { user } = UserState;

	const SessionState = useSelector(
		(state: any) => state.onevone
	);
	const { isfutureEligible } = SessionState;

	const dispatch = useDispatch();
	const getSessionData = async () => {
		const userType =
			user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
		setUserType(userType);
		setUserId(user.id);
		const payload = { userType, userId: user.id };
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		// dispatch(fetchSessionType(payload));
		setLoading(false);
		console.log();
	};

	useEffect(() => {
		if (user !== null) {
			getSessionData();
		}
	}, [user]);
	const courseState = useSelector(
		(state: any) => state.course
	);
	const { batchManagerPhoneNumber } = courseState;

	return (
		<>
			{open ? (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style1}>
						<div
							className="class234cvf"
							onClick={() => setOpen(false)}
						>
							<GrClose
								style={{
									color: '#2666be',
									cursor: 'pointer',
								}}
							/>
						</div>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
						>
							You are not currently eligible for the HR
							Mock, please contact your batch manager.
						</Typography>
						<button
							id="popupButton"
							onClick={() => {
								window.open(
									`https://wa.me/+91${batchManagerPhoneNumber}`
								);
								// componentToggler(5;
							}}
						>
							Contact BM{' '}
							<BsArrowRightCircle style={style} />
						</button>
					</Box>
				</Modal>
			) : null}

			<div className="ssContainer">
				<div className="ssLeft">
					<h1>Welcome Back User</h1>

					<div className="ssPrepare">
						<div className="prepareTextContent">
							<h4>
								Prepare your answers for the most asked HR
								interview questions
							</h4>
							<button
								onClick={() => {
									window.open(
										'https://acciojob.notion.site/Answering-the-most-common-interview-questions-968a9beb665a43b9b3a77c93753618d0',
										'_blank'
									);
								}}
							>
								Start Practicing <FiArrowRight />
							</button>
						</div>
						<div className="svgPrepare">
							<img
								src={ss1}
								alt=""
							/>
						</div>
					</div>
					<div className="ssEtiquettes">
						<div className="textContent">
							<h4>Learn about interview etiquettes</h4>
							<p>
								Most common etiquettes to be followed during
								a job interview
							</p>
							<a
								className="ssLeftLinks--"
								href="https://acciojob.notion.site/Interview-etiquette-9817a776125044babf1ed12b2e50f8d3"
								target="_blank"
								rel="noopener noreferrer"
							>
								Check It Out &nbsp;{' '}
								<BsArrowRightCircle
									style={style}
									className={'sss1123icon'}
								/>
							</a>
						</div>
						<div className="svgContent">
							<img
								src={ss2}
								alt=""
							/>
						</div>
					</div>
					<div className="ssBeforeInterview">
						<div className="textContent">
							<h4>
								What should you do before an interview?
							</h4>
							<p>
								Things to keep in mind to be well prepared
								for an interview
							</p>
							<a
								className="ssLeftLinks--"
								href="https://acciojob.notion.site/Preparing-before-an-interview-919454e86d9b4763a216c52027d37d9f"
								target="_blank"
								rel="noopener noreferrer"
							>
								Check It Out &nbsp;{' '}
								<BsArrowRightCircle
									style={style}
									className={'sss1123icon'}
								/>
							</a>
						</div>
						<div className="svgContent">
							<img
								src={ss3}
								alt=""
							/>
						</div>
					</div>
					<div className="ssEmails">
						<div className="textContent">
							<h4>
								Learn how to write cold emails and cover
								letters!
							</h4>
							<p>
								Quick and comprehensive guide to cold email
								and cover letters
							</p>
							<a
								className="ssLeftLinks--"
								href="https://acciojob.notion.site/Writing-emails-and-cover-letters-c74443f55ee842b3a65b6644b19e2ebe"
								target="_blank"
								rel="noopener noreferrer"
							>
								Check It Out &nbsp;{' '}
								<BsArrowRightCircle
									style={style}
									className={'sss1123icon'}
								/>
							</a>
						</div>
						<div className="svgContent">
							<img
								src={ss4}
								alt=""
							/>
						</div>
					</div>
					<div className="ssPersonality">
						<div className="textContent">
							<h4>
								Lagging in personality skills? Check this!
							</h4>
							<p>
								Common life skills to help you in the longer
								run
							</p>
							<a
								className="ssLeftLinks--"
								href="https://acciojob.notion.site/Skills-to-help-you-in-the-longer-run-c2944c0f919049aaaddc71cc0ab92b62"
								target="_blank"
								rel="noopener noreferrer"
							>
								Check It Out &nbsp;{' '}
								<BsArrowRightCircle
									style={style}
									className={'sss1123icon'}
								/>
							</a>
						</div>
						<div className="svgContent">
							<img
								src={ss5}
								alt=""
							/>
						</div>
					</div>
				</div>
				<div className="ssRight">
					<div className="ssResumeReview">
						<div className="ssRightContent">
							<p>
								Get your <strong>Resume </strong> reviewed{' '}
							</p>
							<button
								onClick={() => {
									console.log(
										'session check',
										user,
										isfutureEligible
									);
									if (user !== null && isfutureEligible) {
										window.open(
											'https://course.acciojob.com/onevone'
										);
									} else {
										setOpen(true);
									}
								}}
							>
								Book Now <FiArrowRight />
							</button>

							{/* <div className="popup">
						<h4 className='popupText'>You are not currently eligible for the HR Mock, please contact your batch manager.</h4>

					</div> */}
						</div>
						<div className="ssRightImg">
							<img
								src={ss6}
								alt=""
							/>
						</div>
					</div>
					<div className="ssAptitude">
						<div className="ssRightContent">
							<p>
								{' '}
								<strong>Aptitude</strong> Handbook
							</p>
							<button
								onClick={() => {
									window.open(
										'https://acciojob.notion.site/Aptitude-Handbook-60b6506093ec4505b7ee3d8d3e4d6c24'
									);
								}}
							>
								Solve Now <FiArrowRight />
							</button>
						</div>
						<div className="ssRightImg">
							<img
								src={ss7}
								alt=""
							/>
						</div>
					</div>
					<div className="ssDiscord">
						<div className="ssRightContent">
							<p>
								Join our <strong>Discord</strong> Community
							</p>
							<button
								onClick={() => {
									window.open(
										'https://discord.com/invite/gVVR7baGY3'
									);
								}}
							>
								Join Now <FiArrowRight />
							</button>
						</div>
						<div className="ssRightImg">
							<img
								src={ss8}
								alt=""
							/>
						</div>
					</div>
					<div className="linkedIn-resume">
						<div className="ssIdealLinkedin">
							<div className="ssIdealLinkedinText">
								<p>
									Want to know, how an ideal Linkedin
									profile looks like?
								</p>
								<button
									onClick={() => {
										window.open(
											'https://acciojob.notion.site/Building-your-Linkedin-profile-7158108d1e5b43e8b30f7c65931d5f44'
										);
									}}
								>
									Check it out <FiArrowRight />
								</button>
							</div>
							<div className="ssIdealLinkedinImg">
								<img
									src={ss9}
									alt=""
								/>
							</div>
						</div>
						<div className="ssIdealResume">
							<div className="ssIdealResumeText">
								<p>
									No idea, about an Ideal Resume? Don’t
									worry
								</p>
								<button
									onClick={() => {
										window.open(
											'https://acciojob.notion.site/Resume-that-stands-out-dab703040ab846e681c856bec3767786'
										);
									}}
								>
									Check it out <FiArrowRight />
								</button>
							</div>
							<div className="ssIdealResumeImg">
								<img
									src={ss10}
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default SoftSkillsDash;
