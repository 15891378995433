import { Grid } from '@mui/material';
import { SiGoogleclassroom } from 'react-icons/si';
import Loader from '../Loader';
import AccioButton from '../elements/Button';
import { encryptData } from '@acciojob/event-analytics';
import { isMainCourseUser } from '../../utils/events';

interface upcomingClassDataProps {
	dataPresent: boolean;
	disable: boolean;
	action: Function;
}

interface ModuleTopBarProps {
	courseLoading: boolean;
	moduleLoading: boolean;
	courseName: string;
	moduleName: string;
	upcomingClassData: upcomingClassDataProps;
}

const ModuleTopBar = ({
	courseLoading,
	moduleLoading,
	courseName,
	moduleName,
	upcomingClassData,
}: ModuleTopBarProps) => {
	return (
		<Grid
			container
			className={'module-top-bar'}
		>
			<Grid
				item
				lg={9}
				md={8}
			>
				<div className={'topic-name'}>
					{!courseLoading ? (
						<>
							<p className={'course-name'}>{courseName}</p>
							{moduleLoading ? (
								<span>Loading</span>
							) : (
								<h3 className={'active-module-name'}>
									{moduleName.length > 0
										? moduleName
										: 'No Module Selected'}
								</h3>
							)}
						</>
					) : (
						<Loader />
					)}
				</div>
			</Grid>
			<Grid
				item
				lg={3}
				md={8}
			>
				<>
					{console.log(
						'upcomingClassData.dataPresent',
						upcomingClassData.dataPresent
					)}
				</>
				{upcomingClassData.dataPresent ? (
					<div
						event-analytics={
							isMainCourseUser()
								? '0326a3bb-e6ba-4b8b-9689-141850ff9242'
								: ''
						}
						data-config={encryptData(
							JSON.stringify({
								name: moduleName,
							})
						)}
					>
						<AccioButton
							className={'calendar-btn'}
							action={upcomingClassData.action}
							icon={<SiGoogleclassroom />}
							iconPosition={'left'}
							title="Join Now"
							variant={
								upcomingClassData.disable
									? 'disable'
									: 'primary'
							}
						/>
					</div>
				) : null}
			</Grid>
		</Grid>
	);
};

export default ModuleTopBar;
