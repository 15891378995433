import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class CodingQuestionService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getCodingQuestionsForModule(moduleId: string) {
		const url = `${this.endpoint}/user-course-enrollment/${moduleId}/codingQuestions`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchQuestion(body: any) {
		const url = `${this.endpoint}/coding-question/question`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async runCustomInput(input: any) {
		const reqInput = { ...input };
		const url = `${this.endpoint}/coding-question/runTestCase`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: reqInput,
				}
			);
			return data[0];
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getExpectedOutput(input: any) {
		const reqInput = { ...input };
		const url = `${this.endpoint}/coding-question/getExpectedOutput`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: reqInput,
				}
			);
			return data[0];
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitCode(input: any) {
		const url = `${this.endpoint}/judge0/submission`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: input,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getAllBookmark() {
		const url = `${this.endpoint}/bookmark/getbookmarks`;
		try {
			const { data } = await this.httpService.get<any>(url);
			// console.log(data);

			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addBookmark(input: any) {
		const url = `${this.endpoint}/bookmark/addbookmark`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						codingQuestionId: input,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async removeBookmark(input: any) {
		const url = `${this.endpoint}/bookmark/removebookmark`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: {
						codingQuestionId: input,
					},
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	// async getRevisionSheets() {
	// 	const url = `${this.endpoint}/coding-question/revision/sheets`;
	// 	try {
	// 		const { data } = await this.httpService.get<any>(url);
	// 		return data;
	// 	} catch (error) {
	// 		throw new Error(apiFailureErrorMessage);
	// 	}
	// }

	async fetchHeatMapData() {
		const url = `${this.endpoint}/user-score-card/heatmap`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchUserQuestionSubmissionData() {
		const url = `${this.endpoint}/user-score-card/questionAnalysis`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
