import React, { useState } from 'react';
import addIcon from '../../assets/refer-and-earn/subtract-circle-icon.svg';
import substractIcon from '../../assets/refer-and-earn/add-circle-icon.svg';

const Faq = ({ faq, index }: any) => {
	const [active, setActive] = useState<number | null>(0);
	return (
		<>
			<div
				key={index}
				className="re-faq-section"
			>
				<div
					className="re-faq-question-container"
					onClick={() => {
						active === index
							? setActive(null)
							: setActive(index);
					}}
				>
					<div className="re-faq-question">
						{faq.question}
					</div>
					<img
						src={active === index ? substractIcon : addIcon}
						alt={'Open Icon'}
						className="re-faq-question-icon"
					/>
				</div>
				{active === index ? (
					<div className="re-faq-answer">{faq.answer}</div>
				) : null}
			</div>
			{/* {
		index !== faqs.length - 1 ? (
			<div className="re-hr" />
		) : null
	} */}
		</>
	);
};

export default Faq;
