import {
	Checkbox,
	FormControlLabel,
	TextField,
} from '@mui/material';
import MyMultiSelectField from '../../formElements/MyMultiselectField';
import MySelectField from '../../formElements/MySelectField';
import MyTextField from '../../formElements/MyTextField';
import { MentorExperienceFormFields } from '../mentorTypes';

function Fields({
	field,
	idx,
	fieldId,
	updateFormData,
	formData,
	isProfileLocked,
	setFormData = '',
}: any) {
	console.log('checking formdata', formData);
	return (
		<>
			{field.type === 'date' ? (
				<TextField
					required={
						formData.isCurrent && field.label == 'End Date'
							? false
							: true
					}
					disabled={
						formData.isCurrent && field.label == 'End Date'
							? true
							: false
					}
					type={
						formData.isCurrent && field.label == 'End Date'
							? 'text'
							: field.type
					}
					label={field.label}
					variant="outlined"
					style={{ width: '100%' }}
					name={fieldId}
					InputProps={{
						inputProps: { ...field },
						style: {
							color: '#000',
							fontSize: '0.9rem',
							fontWeight: 400,
							fontFamily: "'Inter', sans-serif",
						},
					}}
					InputLabelProps={{
						style: {
							color: 'rgba(0, 0, 0, 0.75)',
							fontSize: '0.9rem',
							fontWeight: 400,
							fontFamily: "'Inter', sans-serif",
						},
					}}
					value={
						formData[
							fieldId as keyof MentorExperienceFormFields
						]
					}
					onChange={(e) =>
						updateFormData(e.target.name, e.target.value, e)
					}
				/>
			) : field.type === 'select' ? (
				<MySelectField
					key={idx}
					id={fieldId}
					{...field}
					updateFormData={updateFormData}
					formData={formData}
					isProfileLocked={isProfileLocked}
				/>
			) : field.type === 'multiselect' ? (
				<MyMultiSelectField
					key={idx}
					id={fieldId}
					{...field}
					updateFormData={updateFormData}
					formData={formData}
					isProfileLocked={isProfileLocked}
				/>
			) : field.type === 'checkbox' ? (
				<FormControlLabel
					control={<Checkbox />}
					label="Is Present?"
					labelPlacement="start"
					onClick={() => {
						const temp = formData.isCurrent;
						setFormData({
							...formData,
							isCurrent: !formData.isCurrent,
							endDate: !temp ? null : formData.endDate,
						});
					}}
				/>
			) : (
				<MyTextField
					key={idx}
					id={fieldId}
					{...field}
					updateFormData={updateFormData}
					formData={formData}
					isProfileLocked={isProfileLocked}
				/>
			)}
		</>
	);
}

export default Fields;
