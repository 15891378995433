import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	getMultiSelectValuesJobCard,
	offCampusCardsWithFilters,
} from '../../redux/jobportal/jobPortalSlice';
import { AppDispatch, RootState } from '../../redux/store';
import OffCampus from './offcampus';

const OffCampusWrapper = ({
	filters,
	feedbackOption,
	handleFilterChange,
	pageNumber,
	pageSize,
	handleChangePage,
	handleChangeRowsPerPage,
}: any) => {
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);

	const { offCampusCardsState } = jobPortalState;
	const dispatch = useDispatch<AppDispatch>();
	useEffect(() => {
		if (
			offCampusCardsState.offCampusCards &&
			offCampusCardsState.offCampusCards.length === 0
		) {
			dispatch(getMultiSelectValuesJobCard());
			dispatch(
				offCampusCardsWithFilters({
					pageNumber,

					pageSize,
					...filters,
				})
			);
			// dispatch(fetchOffCampusCards());
		}
	}, []);

	useEffect(() => {
		dispatch(
			offCampusCardsWithFilters({
				pageNumber,
				pageSize,
				...filters,
			})
		);
	}, [
		// filters.companyLocation,
		// filters.collegeDegree,
		// filters.maxYearOfGraduation,
		// filters.minYearOfGraduation,
		pageNumber,
		pageSize,
	]);

	// useEffect(() => {
	// 	const timer = setTimeout(() => {
	// 		dispatch(
	// 			offCampusCardsWithFilters({
	// 				pageNumber,
	// 				pageSize,
	// 				...filters,
	// 			})
	// 		);
	// 	}, 1000);

	// 	return () => clearTimeout(timer);
	// }, [filters.companyName, filters.rolesOffered]);

	return (
		<div className="offcampus-wrapper view-scroll-bar">
			{offCampusCardsState.loading === false ? (
				<OffCampus
					filters={filters}
					feedbackOption={feedbackOption}
					handleFilterChange={handleFilterChange}
					data={offCampusCardsState?.offCampusCards}
					totalLength={
						offCampusCardsState?.offCampusTotalJobLength
					}
					pageNumber={pageNumber}
					pageSize={pageSize}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
				/>
			) : (
				<div className="loader-container">
					<Loader
						factVisibility={true}
						width={'100%'}
						height={'100%'}
					/>
				</div>
			)}
		</div>
	);
};

export default OffCampusWrapper;
