import { services } from '../../../apis';
import { Action } from '../../../redux/popups/popup.type';

export const handleRecordClicks = async (data: any) => {
	await services.popupService.recordClick(data);
};

export const handleCTAClick = (actions: Array<Action>) => {
	for (const action of actions) {
		switch (action.actionType) {
			case 'RECORD_CLICK':
				handleRecordClicks(action.data);
				continue;
			case 'START_STUDENT_ASSESSMENT_TEST':
				window.open(
					`${window.location.origin}/student-assessment`,
					'_self',
					'rel=noopener noreferrer'
				);
				continue;
			case 'NAVIGATION':
				window.open(
					`${window.location.origin}${action.redirectionLink}`,
					'_blank',
					'rel=noopener noreferrer'
				);
				continue;
		}
	}
};
