import moment from 'moment';
import { useEffect, useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../apis';
import {
	setAllAvailableSlots,
	setStateForRescheduleEnableTab,
	setStateMentorId,
	setStateSelectedDateTimeForBooking,
} from '../../redux/session/sessionSlice';
import {
	getEligibleSessionsOfUser,
	getSessionsOfUserForAllSessionTypes,
	getUpcomingSessionsOfUser,
} from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import AccioButton from '../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../notification';
import PopUpBox from '../shared/PopUpBox';
import ScheduleCalendar from '../shared/ScheduleCalender';

interface SessionDetail {
	sessionTag: string;
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	totalScore: string | number;
	studentFeedbackLink: string;
	isAllowedToJoin: boolean;
	mentorFeedback: MentorFeedback | null;
	recordings: string[];
	sessionId: string;
	sessionTypeId: string;
	mentorName: string;
	mentorPhone: string;
}

interface MentorFeedback {
	createdAt: string;
	[key: string]: string | MentorQuestionFeedback | null;
}

interface MentorQuestionFeedback {
	[key: string]: string | MentorQuestionAnswer | null;
}

interface MentorQuestionAnswer {
	[key: string]: string;
}

export interface PrevMentor {
	id: string;
	name: string;
	isGrayedOut?: boolean;
}

export interface PrevMentors {
	[key: string]: PrevMentor;
}

interface MentorshipSessionBookingBoxInterface {
	bookSessionData: {
		allSessions: SessionDetail[];
		isEligibleToBookSession: boolean;
		prevMentors: PrevMentors;
		isMentorship: boolean;
		numberOfCancellationsAllowed: number;
		numberOfSessionsCancelledOrRescheduledTillNow: number;
		remainingBookingLimit?: number | null;
	};
	setOpen: (open: boolean) => void;
}

const MentorshipSessionBookingBox = ({
	bookSessionData,
	setOpen,
}: MentorshipSessionBookingBoxInterface) => {
	const [prevMentorsData, setPrevMentorsData] = useState<
		Array<PrevMentor>
	>([]);

	const [mentorId, setMentorId] = useState<string | null>(
		null
	);
	const [mentorName, setMentorName] = useState<
		string | null
	>(null);
	const [viewCalender, setViewCalender] =
		useState<boolean>(false);
	const [selectedDateTimeVal, setSelectedDateTimeVal] =
		useState<any>();

	console.log('prevMentorsData', prevMentorsData);
	useEffect(() => {
		// Update prevMentors whenever bookSessionData changes
		if (
			bookSessionData &&
			Object.keys(bookSessionData.prevMentors).length > 0
		) {
			const { prevMentors: bookSessionPrevMentors } =
				bookSessionData;
			const prevMentorsArray = Object.entries(
				bookSessionPrevMentors
			).map(([id, mentor]) => ({
				id,
				name: mentor.name,
				isGrayedOut: mentor.isGrayedOut || false,
			}));

			setPrevMentorsData(prevMentorsArray);
		} else {
			setPrevMentorsData([]);
		}
	}, [bookSessionData]);

	const utilFunction = (
		mentorIdVal: string | null,
		mentorNameVal: string | null
	) => {
		console.log(
			'mentorIdVal',
			mentorId,
			mentorIdVal,
			mentorNameVal
		);
		if (mentorId === mentorIdVal) {
			setMentorId(null);
			setViewCalender(true);
			setMentorName(null);
			dispatch(setStateMentorId(null));
			console.log(
				'mentorIdVal i am logging out',
				mentorIdVal
			);
		} else {
			setMentorId(mentorIdVal);
			setViewCalender(true);
			setMentorName(mentorNameVal);
			dispatch(setStateMentorId(mentorIdVal));
			console.log(
				'mentorIdVal i am logging in',
				mentorIdVal
			);
		}
	};

	const [dateTime, setDateTime] = useState<string>('');
	const [addAnimation, setAddAnimation] =
		useState<boolean>(false);

	useEffect(() => {
		console.log('dateTime', dateTime);
		if (dateTime.length > 0) {
			setAddAnimation(false);
		} else {
			setAddAnimation(true);
		}
	}, [dateTime]);

	const {
		stateMentorId,
		stateSessionTypeId,
		stateAllAvailableSlots,
		stateRescheduleId,
		stateSelectedDateTimeForBooking,
		stateForRescheduleEnableTab,
	} = useSelector((state: any) => state.session);

	const { user } = useSelector((state: any) => state.user);

	const { roles } = user;

	const isDataMainCourseUser =
		roles &&
		roles.length > 0 &&
		roles[0] === 'data-maincourse-user';

	const generateDatesArray = () => {
		const dates: string[] = [];
		const current = moment();

		let startDayIncrement =
			current.hour() < 23 ||
			(current.hour() === 23 && current.minute() <= 30)
				? 1
				: 2;

		for (let i = 0; i < 3; i++) {
			dates.push(
				current
					.add(startDayIncrement, 'days')
					.format('YYYY-MM-DD')
			);
			startDayIncrement = 1;
		}

		console.log('dates', dates);

		return dates;
	};

	const selectableDates: string[] = generateDatesArray();

	const dispatch = useDispatch<AppDispatch>();

	const handleTimeSelect = (value: string): void => {
		setDateTime(value);
		dispatch(setStateSelectedDateTimeForBooking(value));
	};

	const [slotLoading, setSlotLoading] =
		useState<boolean>(false);
	const handleDateSelect = async (
		selectedDate: string
	): Promise<any> => {
		try {
			setSlotLoading(true);
			const data =
				await services.sessionService.getAvailableSlots(
					selectedDate,
					stateSessionTypeId,
					stateMentorId
				);
			dispatch(setAllAvailableSlots(data));
			return data;
		} catch (error) {
			console.error(
				'Error fetching available slots:',
				error
			);
			return dispatch(setAllAvailableSlots([]));
		} finally {
			setSlotLoading(false);
		}
	};

	const [response, setResponse] = useState(false);
	const [message, setMessage] = useState('');

	const [bookingBtnDisabled, setBookingBtnDisabled] =
		useState(false);
	const handleBookSlot = async () => {
		setBookingBtnDisabled(true);
		dispatch(setStateForRescheduleEnableTab(false));

		try {
			const formattedDate = moment(
				stateSelectedDateTimeForBooking,
				'DD/MM/YYYY hh:mm a'
			).toDate();

			const formattedTime = moment(
				stateSelectedDateTimeForBooking,
				'DD/MM/YYYY hh:mm a'
			).format('HH:mm:ss');

			const formattedDateTime = moment(
				stateSelectedDateTimeForBooking,
				'DD/MM/YYYY hh:mm a'
			).format('M/D/YYYY, hh:mm:ss A');

			if (stateRescheduleId !== null) {
				const data =
					await services.sessionService.rescheduleSession(
						formattedDateTime,
						stateRescheduleId,
						stateMentorId
					);

				if (data.status === 200 || data.status === 201) {
					notifySuccess(data?.data?.message);
				} else {
					setOpen(false);
					notifyError(data?.data?.message);
				}
			} else {
				const data =
					await services.sessionService.bookSessionWithMentor(
						formattedDate,
						stateSessionTypeId,
						formattedTime,
						stateMentorId
					);

				if (data.status === 200 || data.status === 201) {
					notifySuccess(data?.data?.message);
				} else {
					notifyError(data?.data?.message);
				}
			}
		} catch (err: any) {
			notifyError(
				err.response.data.message.message.message
			);
		} finally {
			setResponse(true);
			setOpen(false);
			dispatch(getSessionsOfUserForAllSessionTypes());
			dispatch(getUpcomingSessionsOfUser());
			dispatch(getEligibleSessionsOfUser());
			setBookingBtnDisabled(false);
		}
	};

	const [openPopUp, setOpenPopUp] = useState(false);
	const handlePopUpClose = () => {
		setOpenPopUp(false);
	};

	return (
		<>
			<div className="mentorship-session-booking-box-outer">
				<div className="limit-text-wrapper">
					{bookSessionData?.remainingBookingLimit ? (
						<p
							className={`modal-box-text-limit ${
								bookSessionData?.remainingBookingLimit === 0
									? 'modal-box-text-limit-cross'
									: ''
							}`}
						>
							Available Bookings: : &nbsp;
							{bookSessionData?.remainingBookingLimit ==
							null
								? '-'
								: bookSessionData?.remainingBookingLimit}
						</p>
					) : null}
					{!isDataMainCourseUser ? (
						<p
							className={`modal-box-text-limit ${
								bookSessionData?.numberOfCancellationsAllowed -
									bookSessionData?.numberOfSessionsCancelledOrRescheduledTillNow <=
								0
									? 'modal-box-text-limit-cross'
									: ''
							}`}
						>
							Cancellations left : &nbsp;
							{bookSessionData?.numberOfCancellationsAllowed -
								bookSessionData?.numberOfSessionsCancelledOrRescheduledTillNow}
						</p>
					) : null}
				</div>
				{!viewCalender ? (
					<div
						className={`mentorship-session-booking-box-wrapper ${
							viewCalender ? 'fade-out' : ''
						} `}
					>
						<p className="box-title">
							Select a mentor for your session
						</p>
						<div className="box-mentors-wrapper">
							<div
								className={`box-mentor ${
									mentorId === 'null' ? 'card-selected' : ''
								}`}
								// onClick={() => utilFunction('null')}
							>
								<div className="mentor-details">
									<p className="mentor-name">
										Best available mentor
									</p>
								</div>
								<div className="selection-btn-wrapper">
									<button
										className="selection-btn"
										onClick={() => utilFunction(null, null)}
									>
										Select <FaAngleRight />
									</button>
								</div>
							</div>

							{prevMentorsData.length > 0
								? prevMentorsData.map((mentor) =>
										mentor.isGrayedOut ? null : (
											<div
												key={mentor.id}
												className={`box-mentor ${
													mentorId === mentor.id
														? 'card-selected'
														: ''
												}`}
												// onClick={() => utilFunction(mentor.id)}
											>
												<div className="mentor-details">
													<p className="mentor-name">
														{mentor.name}
													</p>
												</div>
												<div className="selection-btn-wrapper">
													<button
														className="selection-btn"
														onClick={() =>
															utilFunction(
																mentor.id,
																mentor.name
															)
														}
													>
														Select <FaAngleRight />
													</button>
												</div>
											</div>
										)
								  )
								: null}
						</div>
					</div>
				) : (
					<div
						className={`box-selected-mentor-with-calendar `}
					>
						{mentorId !== null ? (
							<div
								className={`selected-mentor-card ${
									viewCalender ? 'slide-in-top' : ''
								}`}
							>
								<p className="selected-mentor-header">
									Mentor has been selected 🎉
								</p>
								<div className="selected-mentor-details">
									<p className="selected-mentor-name">
										{mentorName}
									</p>
									<div className="selection-btn-wrapper">
										<AccioButton
											title="Change selection"
											action={() => {
												setViewCalender(false);
												setMentorId('');
												setMentorName('');
												setSelectedDateTimeVal('');
												dispatch(setStateMentorId(null));
											}}
											customClass="box-book-session-btn"
										/>
									</div>
								</div>
							</div>
						) : null}

						<div
							className={`box-calender-wrapper ${
								viewCalender ? 'slide-in-left' : ''
							}`}
						>
							<ScheduleCalendar
								selectableDates={selectableDates}
								// startTime={startTime}
								// endTime={endTime}
								// duration={duration}
								onTimeSelect={handleTimeSelect}
								timeSlots={stateAllAvailableSlots}
								onDateSelect={handleDateSelect}
							/>

							<div
								className={`box-book-session-btn-wrapper ${
									!addAnimation
										? 'slide-in-right'
										: 'slide-out-right'
								}`}
							>
								<div className="box-book-session-dateTime-wrapper">
									<p className="text">Session details :</p>
									<p className="box-book-session-dateTime">
										{moment(
											dateTime,
											'DD/MM/YYYY h:mm a'
										).format('h:mm a, DD/MM/YYYY')}
									</p>
								</div>

								<AccioButton
									disabled={bookingBtnDisabled}
									customClass={`box-book-session-btn ${
										bookingBtnDisabled
											? 'box-book-session-btn-disable'
											: ''
									}}`}
									title={
										!stateForRescheduleEnableTab
											? 'Book Session'
											: 'Reschedule Session'
									}
									action={() => setOpenPopUp(true)}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
			<PopUpBox
				handleClose={handlePopUpClose}
				openPopUp={openPopUp}
			>
				<div className="session-limit-pop-up-box-content">
					<p className="pop-up-box-title">
						{`Do you want to ${
							stateRescheduleId == null
								? 'book'
								: 'reschedule'
						}   this session ?`}
					</p>
					{stateSelectedDateTimeForBooking ? (
						<p className="pop-up-box-message">
							<span className="text-1">
								Session details :
							</span>
							<span className="text-2">
								{stateSelectedDateTimeForBooking}
							</span>
						</p>
					) : null}
					{!isDataMainCourseUser ? (
						<p
							className={`modal-box-text-limit ${
								bookSessionData?.numberOfCancellationsAllowed -
									bookSessionData?.numberOfSessionsCancelledOrRescheduledTillNow <=
								0
									? 'modal-box-text-limit-cross'
									: ''
							}`}
						>
							Cancel / Reschedule Limit : &nbsp;
							{bookSessionData?.numberOfCancellationsAllowed -
								bookSessionData?.numberOfSessionsCancelledOrRescheduledTillNow}
						</p>
					) : null}

					{bookSessionData?.remainingBookingLimit ? (
						<p
							className={`modal-box-text-limit ${
								bookSessionData?.remainingBookingLimit === 0
									? 'modal-box-text-limit-cross'
									: ''
							}`}
						>
							Available Bookings: : &nbsp;
							{bookSessionData?.remainingBookingLimit ==
							null
								? '-'
								: bookSessionData?.remainingBookingLimit}
						</p>
					) : null}
					<div className="pop-up-box-btn-wrapper">
						<AccioButton
							title="No"
							variant="outline"
							customClass="btn booking-session-btn-outline"
							action={() => {
								handlePopUpClose();
							}}
						/>
						<AccioButton
							title="Yes"
							variant="primary"
							customClass="btn booking-session-btn-primary"
							action={() => {
								handlePopUpClose();
								handleBookSlot();
							}}
						/>
					</div>
				</div>
			</PopUpBox>
		</>
	);
};

export default MentorshipSessionBookingBox;
