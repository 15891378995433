import {
	Button,
	Dialog,
	Input,
	TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { services } from '../../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';

function RecordingModal({
	sessionId,
	showRecordingModal,
	handleCloseRecording,
}: any) {
	const [videoURL, setVideoURL] = useState<string>('');
	const [showError, setShowError] =
		useState<boolean>(false);

	const handleUploadRecording = async () => {
		if (videoURL.length === 0) {
			setShowError(true);
			return;
		}
		const body = {
			sessionId: sessionId,
			videoLink: videoURL,
		};
		console.log('videoURL', body);
		const data =
			await services.mentorService.uploadRecording(body);
		if (data && data.statusCode) notifyError(data.message);
		else {
			notifySuccess(data.message);
			handleCloseRecording();
		}
	};

	return (
		<div>
			<Dialog
				open={showRecordingModal}
				onClose={handleCloseRecording}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={{ padding: '1em' }}>
					<h4 className="upload-url-title">
						Upload Recording URL
					</h4>
					<TextField
						className="videourl-input"
						error={showError}
						id="outlined-basic"
						label="Recording URL"
						variant="outlined"
						required
						onChange={(e) => {
							setShowError(false);
							setVideoURL(e.target.value);
						}}
						placeholder="https://recordingURL.com"
						type={'url'}
						fullWidth
						size="small"
						margin="normal"
					/>
					<button
						className="url-submit-btn"
						onClick={handleUploadRecording}
					>
						Upload
					</button>
				</Box>
			</Dialog>
		</div>
	);
}

export default RecordingModal;
