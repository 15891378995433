import React from 'react';
import { services } from '../../apis';
import { OffCampusCardData } from '../../apis/JobPortalService';
import mapPin from '../../../assets/images/icons/mapPin.svg';
import money from '../../../assets/images/icons/money.svg';
import time from '../../../assets/images/icons/time.svg';
import { PopupState } from '../../pages/jobportal/JobPortal';

interface JobCardProps {
	data: OffCampusCardData;
	setJobDetailsPopup: (newState: PopupState) => void;
}

const OffCampusCard = ({
	data,
	setJobDetailsPopup,
}: JobCardProps) => {
	const {
		companyName,
		rolesOffered,
		dateAdded,
		eligibility,
		applicationLink,
		jobCardId,
		applied,
	} = data;

	const formatDate = (date: string) => {
		const newDate = new Date(date);
		const day = newDate.getDate();
		const month = newDate
			.toLocaleString('default', { month: 'long' })
			.substring(0, 3);
		const time = newDate.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
		});

		return day + ' ' + month + ', ' + time;
	};

	const handleApply = async () => {
		const data =
			await services.jobPortalService.postApplicationOffCampus(
				jobCardId
			);
		window.open(applicationLink, '_blank');
	};

	return (
		<div className="off-campus-card">
			<div className="header-area">
				<div className="company-overview">
					<h3>{companyName}</h3>
				</div>
			</div>
			<div className="body-area">
				{rolesOffered && (
					<div
						className={
							'positions ' +
							(rolesOffered.length > 45
								? 'custom-tooltip'
								: '')
						}
						data-tooltip={rolesOffered}
					>
						<h4>OPEN POSITIONS</h4>
						<p>{rolesOffered}</p>
					</div>
				)}
				{dateAdded && (
					<div className="skills">
						<h4>Date Added</h4>
						<p>{formatDate(dateAdded)}</p>
					</div>
				)}
				{eligibility && (
					<div
						className={
							'process ' +
							(eligibility.length > 45
								? 'custom-tooltip'
								: '')
						}
						data-tooltip={eligibility}
					>
						<h4>Selection Process</h4>
						<p>{eligibility}</p>
					</div>
				)}
			</div>
			<div className="footer-area">
				<button
					className="apply"
					onClick={() => {
						if (!applied) {
							handleApply();
						}
					}}
				>
					{!applied ? 'Apply now' : 'Applied'}
				</button>
			</div>
		</div>
	);
};

export default OffCampusCard;
