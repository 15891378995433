import { Box } from '@mui/material';

interface PageHeadingProps {
	pageName: string;
	variant?: 'home';
	userName?: 'string';
}

const PageHeading = ({
	pageName,
	variant,
	userName,
}: PageHeadingProps) => {
	return (
		<Box className={'page-heading '}>
			<h2 className="heading">
				<strong>{pageName}</strong>&nbsp;
				<span>{userName ? userName : ''}</span>
			</h2>
		</Box>
	);
};

export default PageHeading;
