import { Button } from '@mui/material';
import {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import { useSelector } from 'react-redux';
import { services } from '../../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import { fetchMentorSkills } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import {
	CloudPlatform,
	Database,
	MobileDevelopmentSkill,
	OtherSkill,
	ProgrammingLanguage,
	SoftwareDevelopmentFramework,
	SoftwareDevelopmentTool,
} from '../mentorTypes';
import Fields from './Fields';

const enumToList = (data: any): string[] =>
	Object.values(data);

const FormFields = [
	{
		title: 'Skills',
		nonWorkingTitle: 'Skills',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			programmingLanguages: {
				label: 'Programming Languages',
				type: 'multiselect',
				items: enumToList(ProgrammingLanguage),
				disabled: false,
				required: false,
			},
			softwareDevelopmentFrameworks: {
				label: 'Software Development Frameworks',
				type: 'multiselect',
				items: enumToList(SoftwareDevelopmentFramework),
				disabled: false,
				required: false,
			},
			mobileDevelopmentSkills: {
				label: 'Mobile Development Skills',
				type: 'multiselect',
				items: enumToList(MobileDevelopmentSkill),
				disabled: false,
				required: false,
			},
			databases: {
				label: 'Databases',
				type: 'multiselect',
				items: enumToList(Database),
				disabled: false,
				required: false,
			},
			cloudPlatforms: {
				label: 'Cloud Platforms',
				type: 'multiselect',
				items: enumToList(CloudPlatform),
				disabled: false,
				required: false,
			},
			softwareDevelopmentTools: {
				label: 'Software Development Tools',
				type: 'multiselect',
				items: enumToList(SoftwareDevelopmentTool),
				disabled: false,
				required: false,
			},
			otherSkills: {
				label: 'Other Skills',
				type: 'multiselect',
				items: enumToList(OtherSkill),
				disabled: false,
				required: false,
			},
		},
	},
];

const MentorSkills = () => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, mentorSkillsdata } = MentorState;

	const initialFormData = {
		programmingLanguages: [],
		softwareDevelopmentFrameworks: [],
		mobileDevelopmentSkills: [],
		databases: [],
		cloudPlatforms: [],
		softwareDevelopmentTools: [],
		otherSkills: [],
	};

	const [isProfileLocked, setIsProfileLocked] =
		useState(false);

	const [formFields, setFormFields] = useState(FormFields);

	const [formData, setFormData] =
		useState<any>(initialFormData);

	useEffect(() => {
		if (mentor !== null) dispatch(fetchMentorSkills());
	}, [mentor]);

	useEffect(() => {
		if (
			mentorSkillsdata !== null &&
			Object.keys(mentorSkillsdata).length > 0
		) {
			setFormData({
				...mentorSkillsdata,
			});
		}
	}, [mentorSkillsdata]);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[],
		event?: any
	) => {
		const temp = { ...formData };
		// temp[fieldId] = fieldValue
		setFormData({
			...temp,
			[fieldId]: fieldValue,
		});
		console.log('changes', temp);
	};

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		try {
			const data =
				await services.mentorService.addMentorSkill(
					formData
				);
			if (data && data.statusCode)
				notifyError(data.message);
			else notifySuccess('Skills Updated');
		} catch (error) {
			console.log('updateMentorSkill', error);
		}
	};

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{formFields.map((fieldBatch, idx) => {
				return (
					<div
						key={idx}
						className="batch"
					>
						<h4>{fieldBatch.title}</h4>
						<>
							<div className="fields">
								{Object.entries(fieldBatch.items).map(
									([fieldId, field], idx) => (
										<Fields
											field={field}
											idx={idx}
											fieldId={fieldId}
											updateFormData={updateFormData}
											formData={formData}
											isProfileLocked={isProfileLocked}
										/>
									)
								)}
							</div>
							<Button
								className="profile-submit"
								type="submit"
								style={{ marginTop: '1.25em' }}
							>
								Submit
							</Button>
						</>
					</div>
				);
			})}
		</form>
	);
};

export default MentorSkills;
