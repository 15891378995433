import { encryptData } from '@acciojob/event-analytics';
import { Loader } from '@acciojob/loader';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect, useState } from 'react';
import 'react-alice-carousel/lib/alice-carousel.css';
import {
	AiOutlineClockCircle,
	AiOutlineClose,
} from 'react-icons/ai';
import { FiArrowRight, FiSearch } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AccioCarousel from '../../components/accioCarousels';
import AccioButton from '../../components/elements/Button';
import { InputWithDecorator } from '../../components/elements/Input';
import Portal from '../../components/portal';
import ProjectCard from '../../components/project/ProjectCard';
import ProjectResourceCard from '../../components/project/ProjectResourceCard';
import { fetchAllProjects } from '../../redux/projects/projectSlice';
import { useAppDispatch } from '../../redux/store';
import resources from './resource.json';

enum FilterOption {
	Beginner = 'Beginner',
	Intermediate = 'Intermediate',
	Advanced = 'Advanced',
	Completed = 'Completed',
	Ongoing = 'Ongoing',
	NotStarted = 'Not Started',
}

const Projects = () => {
	const dispatch = useAppDispatch();
	const { projects } = useSelector(
		(state: any) => state.projects
	);

	useEffect(() => {
		if (projects === null) {
			dispatch(fetchAllProjects());
		}
	}, []);

	const [selectedVideo, setSelectedVideo] =
		useState<any>(null);
	const [selectedProject, setSelectedProject] =
		useState<any>(null);
	const [selectedVideoUrl, setSelectedVideoUrl] =
		useState('');
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const navigate = useNavigate();

	const [filterOptions, setFilterOptions] = useState<
		FilterOption[]
	>([]);
	const [filteredProjectsState, setFilteredProjectsState] =
		useState<any[]>([]);
	const [activeFilterOptions, setActiveFilterOptions] =
		useState<FilterOption[]>([]);
	const [searchText, setSearchText] = useState('');

	const toggleFilterOption = (option: FilterOption) => {
		if (filterOptions.includes(option)) {
			setFilterOptions(
				filterOptions.filter((item) => item !== option)
			);
			setActiveFilterOptions(
				activeFilterOptions.filter(
					(item) => item !== option
				)
			);
		} else {
			setFilterOptions([...filterOptions, option]);
			setActiveFilterOptions([
				...activeFilterOptions,
				option,
			]);
		}
	};

	useEffect(() => {
		if (!projects) {
			setFilteredProjectsState([]);
			return;
		}

		const newFilteredProjects = projects.filter(
			(project: any) => {
				const projectDifficulty =
					project.project.difficulty;
				const projectTag = project.projectTag;

				if (filterOptions.length === 0) {
					return true;
				}

				const difficultyFilterOptions =
					activeFilterOptions.filter((option: any) => {
						return (
							option === FilterOption.Beginner ||
							option === FilterOption.Intermediate ||
							option === FilterOption.Advanced
						);
					});

				const tagFilterOptions = activeFilterOptions.filter(
					(option: any) => {
						return (
							option === FilterOption.Completed ||
							option === FilterOption.Ongoing ||
							option === FilterOption.NotStarted
						);
					}
				);

				let isDifficultyMatch = false;
				if (difficultyFilterOptions.length > 0) {
					isDifficultyMatch = difficultyFilterOptions.some(
						(option: any) => {
							return (
								(option === FilterOption.Beginner &&
									projectDifficulty === 'Beginner') ||
								(option === FilterOption.Intermediate &&
									projectDifficulty === 'Intermediate') ||
								(option === FilterOption.Advanced &&
									projectDifficulty === 'Advanced')
							);
						}
					);
				} else {
					isDifficultyMatch = true;
				}

				let isTagMatch = false;
				if (tagFilterOptions.length > 0) {
					isTagMatch = tagFilterOptions.some((option) => {
						return (
							(option === FilterOption.Completed &&
								projectTag === 'Completed') ||
							(option === FilterOption.Ongoing &&
								projectTag === 'Ongoing') ||
							(option === FilterOption.NotStarted &&
								projectTag === 'Not Started')
						);
					});
				} else {
					isTagMatch = true;
				}

				return isDifficultyMatch && isTagMatch;
			}
		);
		setFilteredProjectsState(newFilteredProjects);
		if (searchText.length > 0) {
			const newFilteredProjects2 =
				newFilteredProjects.filter((project: any) => {
					return project.project.name
						.toLowerCase()
						.includes(searchText.toLowerCase());
				});
			setFilteredProjectsState(newFilteredProjects2);
		}
	}, [projects, filterOptions, searchText]);

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		bgcolor: 'background.paper',
		p: 0,
		background:
			'linear-gradient(164.79deg, #FFFFFF 10.69%, #FFFFFF 99.64%)',
		boxShadow: '0px 4px 32px rgba(38, 102, 190, 0.15)',
		borderRadius: '16px',
	};

	const [resourceCards, setResourceCards] = useState<any[]>(
		[]
	);

	useEffect(() => {
		const temp = resources.resourceVideos.map((el: any) => (
			<ProjectResourceCard
				handleShow={handleShow}
				el={el}
				setSelectedVideo={setSelectedVideo}
			/>
		));
		setResourceCards(temp);
	}, [resources]);

	const options: any = {
		infinite: true,
		controlsStrategy: 'default',
		mouseTracking: true,
		autoPlay: true,
		autoPlayInterval: 3000,
		animationDuration: 1000,
		responsive: {
			0: { items: 1 },
			568: { items: 2 },
			1024: { items: 3 },
		},
	};

	return (
		<div className="projects">
			{selectedVideo !== null ? (
				<Portal>
					<div className={'video-modal'}>
						<header>
							<h2>{selectedVideo.title}</h2>
							<AiOutlineClose
								onClick={() => {
									setSelectedVideo(null);
									setSelectedVideoUrl('');
								}}
							/>
						</header>
						<div className={'video-main'}>
							<div className={'video-player-container'}>
								<ReactPlayer
									url={selectedVideoUrl}
									className={'video-player'}
								/>
							</div>
							<div className={'video-list'}>
								<h3>Videos({selectedVideo.totalVideo})</h3>
								<ul>
									{selectedVideo.videos.map((el: any) => (
										<li
											key={el.videoLink}
											onClick={() =>
												setSelectedVideoUrl(el.videoLink)
											}
											event-analytics={
												'c108a270-60e1-4ba8-a9d3-8f713be7da51'
											}
											data-config={encryptData(
												JSON.stringify({
													name: el.title,
												})
											)}
										>
											<p>{el.title}</p>
											<p>
												<AiOutlineClockCircle />{' '}
												<span>{el.duration}</span>
											</p>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</Portal>
			) : null}
			{selectedProject !== null ? (
				<Portal>
					<div className={'sample-modal'}>
						<h4>Sample Project</h4>
						<header>
							<h2>{selectedProject.name}</h2>
							<AiOutlineClose
								onClick={() => {
									setSelectedProject(null);
								}}
							/>
						</header>
						<div className={'sample-modal-data'}>
							<ul>
								<li className={'sample-list-header'}>
									<h3>Student name</h3>
									<h3>Project Score</h3>
									<h3>Link</h3>
								</li>
								{selectedProject.sampleProject.map(
									(el: any) => (
										<li
											className={'sample-items'}
											key={
												el.student_name +
												el.project_score +
												el.deployed_link
											}
										>
											<h3>{el.student_name}</h3>
											<h3>{el.project_score}</h3>
											<button
												onClick={() =>
													window.open(
														`${el.deployed_link}`,
														'_blank'
													)
												}
											>
												View Project <FiArrowRight />
											</button>
										</li>
									)
								)}
							</ul>
						</div>
					</div>
				</Portal>
			) : null}
			<h1 className={'page-head'}>Projects</h1>
			<div className="resources">
				<h3>RESOURCES TO HELP YOU GET STARTED QUICKLY</h3>
				<div className="resource-videos">
					<AccioCarousel
						options={options}
						items={resourceCards}
					/>
				</div>
			</div>
			<h3 className={'projects-for-you'}>
				PROJECTS FOR YOU
			</h3>
			<div className="all-projects">
				{projects && projects.length > 0 ? (
					<>
						<div className="project-cards-container">
							{filteredProjectsState.length > 0 ? (
								filteredProjectsState.map(
									(elem: any, index: number) => {
										return (
											<ProjectCard
												elem={elem}
												setSelectedProject={
													setSelectedProject
												}
											/>
										);
									}
								)
							) : (
								<p className="no-project-found-text">
									No Found !!
								</p>
							)}
						</div>
						<div className="project-cards-filter">
							<p className="project-filter-title">
								Filters
							</p>
							<div className="project-filter-0">
								<p className="project-filter-subtitle">
									Project
								</p>
								<InputWithDecorator
									placeholder="Search Project"
									value={searchText}
									setValue={setSearchText}
									startDecoration={<FiSearch />}
									endDecoration={
										searchText.length ? (
											<span
												className="end-decoration"
												onClick={() => {
													setSearchText('');
												}}
											>
												<IoMdClose />
											</span>
										) : null
									}
									customClass="search-module Joy-focused project-filter-input"
								/>
							</div>
							<div className="project-filter-1">
								<p className="project-filter-subtitle">
									Difficulty
								</p>
								<div className="project-filter-buttons">
									<AccioButton
										title={'Beginner'}
										action={() =>
											toggleFilterOption(
												FilterOption.Beginner
											)
										}
										customClass={`${
											filterOptions.includes(
												FilterOption.Beginner
											)
												? 'fliter-btn-active'
												: ''
										}`}
										className={'fliter-btn'}
										variant={'primary'}
										icon={''}
									/>

									<AccioButton
										title={'Intermediate'}
										action={() =>
											toggleFilterOption(
												FilterOption.Intermediate
											)
										}
										customClass={`${
											filterOptions.includes(
												FilterOption.Intermediate
											)
												? 'fliter-btn-active'
												: ''
										}`}
										className={'fliter-btn'}
										variant={'primary'}
										icon={''}
									/>

									<AccioButton
										title={'Advanced'}
										action={() =>
											toggleFilterOption(
												FilterOption.Advanced
											)
										}
										customClass={`${
											filterOptions.includes(
												FilterOption.Advanced
											)
												? 'fliter-btn-active'
												: ''
										}`}
										className={'fliter-btn'}
										variant={'primary'}
										icon={''}
									/>
								</div>
							</div>
							<div className="project-filter-2">
								<p className="project-filter-subtitle">
									Project Status
								</p>
								<div className="project-filter-buttons">
									<AccioButton
										title={'Completed'}
										action={() =>
											toggleFilterOption(
												FilterOption.Completed
											)
										}
										customClass={`${
											filterOptions.includes(
												FilterOption.Completed
											)
												? 'fliter-btn-active'
												: ''
										}`}
										className={'fliter-btn'}
										variant={'primary'}
										icon={''}
									/>

									<AccioButton
										title={'Ongoing'}
										action={() =>
											toggleFilterOption(
												FilterOption.Ongoing
											)
										}
										customClass={`${
											filterOptions.includes(
												FilterOption.Ongoing
											)
												? 'fliter-btn-active'
												: ''
										}`}
										className={'fliter-btn'}
										variant={'primary'}
										icon={''}
									/>

									<AccioButton
										title={'Not Started'}
										action={() =>
											toggleFilterOption(
												FilterOption.NotStarted
											)
										}
										customClass={`${
											filterOptions.includes(
												FilterOption.NotStarted
											)
												? 'fliter-btn-active'
												: ''
										}`}
										className={'fliter-btn'}
										variant={'primary'}
										icon={''}
									/>
								</div>
							</div>
						</div>
					</>
				) : (
					<div className="loader-container">
						<Loader
							factVisibility={true}
							width={'100%'}
							height={'100%'}
						/>
					</div>
				)}
			</div>
		</div>
	);
};

export default Projects;
