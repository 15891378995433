import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/joy';
import { useState } from 'react';
import {
	MdKeyboardArrowDown,
	MdKeyboardArrowUp,
} from 'react-icons/md';

const sectionInfo: any = {
	Resume:
		'Resume content, explanation, formatting and presentation',
	Project:
		'Project functionality, understanding, novelity and explanation',
	'Coding Skill':
		'Coding Logic, optimality of code, judgement of final code',
	Introduction:
		'Communication of technical skills/experiences to interviewer',
	'Theoretical Knowledge':
		'Understanding of theoretical concepts',
	'Explanation and Code Quality':
		'Code modularity, variable naming and code comments',
};

const ProgressBar = ({
	length,
	color,
}: {
	length: number;
	color: string;
}) => {
	return (
		<div className="progress-box-wrp">
			<div className="progressWrp">
				<div
					className="progress-inner"
					style={{
						background: color,
						width: `${length <= 0 ? 0.5 : length * 10}%`,
					}}
				></div>
			</div>
			<span className="progress-txt">
				{length <= 0 ? 0.5 : (length * 10).toFixed(2)}%
			</span>
		</div>
	);
};

const SessionFeedBackBox = ({ currentFeedback }: any) => {
	const numCodingLength = Object.keys(
		currentFeedback
	).filter((x: string) => x.includes('Coding')).length;
	const [activeTab, setActiveTab] = useState(0);

	console.log('currentFeedback', currentFeedback);
	return (
		<div className="feedbackmodal-wrp">
			{/* <div className="header">
				<div>
					{currentFeedback.isMentorShip ? (
						<p>Mentorship Session</p>
					) : (
						<p>Mock Interviews</p>
					)}
					<h5 style={{ margin: 0 }}>
					</h5>
				</div>
				<div>
					
				</div>
			</div> */}
			<div
				className="mainfeedback"
				style={{
					display: currentFeedback.isMentorShip
						? 'block'
						: 'grid',
				}}
			>
				<div
					className="scorecard-wrapper"
					// style={{
					// 	display: currentFeedback.isMentorShip
					// 		? 'none'
					// 		: 'initial',
					// }}
				>
					{!currentFeedback.isMentorShip ? (
						<div className="scorecard">
							<div className="totalscore">
								<h5>Total Score</h5>
								<h4>{currentFeedback.score}/10</h4>
							</div>

							<div className="sectionwise">
								<h5>Section Wise Analysis</h5>
								{currentFeedback['SS'] ? (
									<div style={{ width: '100%' }}>
										{Object.entries(
											currentFeedback['SS']
										).map((sn: any, index: number) => (
											<div
												className="sskills"
												key={index}
											>
												<div
													className="codingheader"
													// style={{ columnGap: '5px' }}
												>
													{sn[0]}{' '}
													<Tooltip
														placement="top"
														title={
															sectionInfo[sn[0].trim()]
														}
													>
														<InfoOutlinedIcon
															style={{
																fontSize: '13px',
																color: '#505862',
															}}
														/>
													</Tooltip>
												</div>
												<ProgressBar
													length={sn[1]}
													color={
														sn[1] < 4
															? '#EF574C'
															: sn[1] < 6
															? '#F0B416'
															: '#12B76A'
													}
												/>
											</div>
										))}
									</div>
								) : null}
							</div>
						</div>
					) : null}
				</div>
				<div className="detailedfeedback">
					<div className="headerfeedback">
						<p
							style={{
								color: '#526986',
								fontSize: '14px',
							}}
						>
							Let’s see what our Mentor has to say about the
							Interview !{' '}
						</p>
						<h6 style={{ marginTop: '10px' }}>
							{currentFeedback[
								'Feedback for the student'
							] || currentFeedback['Feedback for student']}
						</h6>
					</div>
					<div
						className="feedbackTable-wrp"
						style={{
							display: currentFeedback.isMentorShip
								? 'none'
								: 'block',
						}}
					>
						{currentFeedback['Technical Introduction'] && (
							<div>
								<table
									className={`${
										activeTab === 1
											? 'session-details-header-wrapper mentorship-header-table-active '
											: 'session-details-header-wrapper'
									}`}
								>
									<tr
										className={`accordion-header-wrapper  `}
										style={{ fontSize: '14px' }}
									>
										<th
											className="accordion-header"
											style={{
												marginLeft: '10px',
												color:
													currentFeedback[`SS`][
														'Introduction'
													] < 4
														? '#EF574C'
														: currentFeedback[`SS`][
																'Introduction'
														  ] < 6
														? '#F0B416'
														: '#12B76A',
											}}
										>
											Technical Introduction
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text topictagwrp"></span>
										</th>
										<th className="accordion-header">
											<span className="accordion-header-text score-header">
												<p>Score</p>
												<ProgressBar
													length={
														currentFeedback[`SS`][
															'Introduction'
														]
													}
													color={
														currentFeedback[`SS`][
															'Introduction'
														] < 4
															? '#EF574C'
															: currentFeedback[`SS`][
																	'Introduction'
															  ] < 6
															? '#F0B416'
															: '#12B76A'
													}
												/>
											</span>
										</th>
										<th
											className="accordion-header-dropdown-text feedback-show-icon"
											style={{
												paddingLeft: '5px',
												paddingRight: '5px',
											}}
											onClick={() =>
												setActiveTab(
													activeTab === 1 ? 0 : 1
												)
											}
										>
											{activeTab === 1 ? (
												<>
													Hide feedback
													<MdKeyboardArrowUp
														className={'icon'}
													/>
												</>
											) : (
												<>
													Show Feedback
													<MdKeyboardArrowDown
														className={'icon'}
													/>
												</>
											)}
										</th>
									</tr>
								</table>
								<table
									className={`${
										activeTab === 1
											? 'session-content-wrapper mentorship-content-table-active '
											: 'session-content-wrapper'
									}`}
								>
									<tr className="topic-header">
										<td className="topic-header-text mock-text topic-width-25">
											<div className="feedbackanswer">
												{
													currentFeedback[
														'Technical Introduction'
													]
												}
											</div>
										</td>
									</tr>
								</table>
							</div>
						)}
						{currentFeedback['Project'] && (
							<div>
								<table
									className={`${
										activeTab === 2
											? 'session-details-header-wrapper mentorship-header-table-active '
											: 'session-details-header-wrapper'
									}`}
								>
									<tr
										className={`accordion-header-wrapper  `}
										style={{ fontSize: '14px' }}
									>
										<th
											className="accordion-header"
											style={{
												marginLeft: '10px',
												color:
													currentFeedback[`SS`]['Project'] <
													4
														? '#EF574C'
														: currentFeedback[`SS`][
																'Project'
														  ] < 6
														? '#F0B416'
														: '#12B76A',
											}}
										>
											Project
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text"></span>
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text score-header">
												<p>Score</p>
												<ProgressBar
													length={
														currentFeedback[`SS`]['Project']
													}
													color={
														currentFeedback[`SS`][
															'Project'
														] < 4
															? '#EF574C'
															: currentFeedback[`SS`][
																	'Project'
															  ] < 6
															? '#F0B416'
															: '#12B76A'
													}
												/>
											</span>
										</th>

										<th
											className="accordion-header-dropdown-text feedback-show-icon"
											style={{
												paddingLeft: '5px',
												paddingRight: '5px',
											}}
											onClick={() =>
												setActiveTab(
													activeTab === 2 ? 0 : 2
												)
											}
										>
											{activeTab === 2 ? (
												<>
													Hide feedback
													<MdKeyboardArrowUp
														className={'icon'}
													/>
												</>
											) : (
												<>
													Show Feedback
													<MdKeyboardArrowDown
														className={'icon'}
													/>
												</>
											)}
										</th>
									</tr>
								</table>
								<table
									className={`${
										activeTab === 2
											? 'session-content-wrapper mentorship-content-table-active '
											: 'session-content-wrapper'
									}`}
								>
									{Object.entries(
										currentFeedback['Project']
									).map((sn: any, i: number) => (
										<tr
											className="topic-header"
											key={i}
										>
											{![
												'Score',
												'Difficulty',
												'Topics',
											].includes(sn[0].trim()) && (
												<td className="topic-header-text mock-text topic-width-25">
													<p
														style={{
															fontSize: '14px',
															marginBottom: '10px',
														}}
													>
														Question Asked:
													</p>
													<p
														style={{
															fontSize: '14px',
															color: '#2B2F34',
														}}
													>
														{sn[0]}
													</p>
													<div className="feedbackanswer">
														{sn[1]}
													</div>
												</td>
											)}
										</tr>
									))}
								</table>
							</div>
						)}
						{currentFeedback['Resume'] && (
							<div>
								<table
									className={`${
										activeTab === 3
											? 'session-details-header-wrapper mentorship-header-table-active '
											: 'session-details-header-wrapper'
									}`}
								>
									<tr
										className={`accordion-header-wrapper  `}
										style={{ fontSize: '14px' }}
									>
										<th
											className="accordion-header"
											style={{
												marginLeft: '10px',
												color:
													currentFeedback[`SS`]['Resume'] <
													4
														? '#EF574C'
														: currentFeedback[`SS`][
																'Resume'
														  ] < 6
														? '#F0B416'
														: '#12B76A',
											}}
										>
											Resume
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text"></span>
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text score-header">
												<p>Score</p>
												<ProgressBar
													length={
														currentFeedback[`SS`]['Resume']
													}
													color={
														currentFeedback[`SS`][
															'Resume'
														] < 4
															? '#EF574C'
															: currentFeedback[`SS`][
																	'Resume'
															  ] < 6
															? '#F0B416'
															: '#12B76A'
													}
												/>
											</span>
										</th>

										<th
											className="accordion-header-dropdown-text feedback-show-icon"
											style={{
												paddingLeft: '5px',
												paddingRight: '5px',
											}}
											onClick={() =>
												setActiveTab(
													activeTab === 3 ? 0 : 3
												)
											}
										>
											{activeTab === 3 ? (
												<>
													Hide feedback
													<MdKeyboardArrowUp
														className={'icon'}
													/>
												</>
											) : (
												<>
													Show Feedback
													<MdKeyboardArrowDown
														className={'icon'}
													/>
												</>
											)}
										</th>
									</tr>
								</table>
								<table
									className={`${
										activeTab === 3
											? 'session-content-wrapper mentorship-content-table-active '
											: 'session-content-wrapper'
									}`}
								>
									{Object.entries(
										currentFeedback['Resume']
									).map((sn: any, i: number) => (
										<tr
											className="topic-header"
											key={i}
										>
											{![
												'Score',
												'Difficulty',
												'Topics',
											].includes(sn[0].trim()) && (
												<td className="topic-header-text mock-text topic-width-25">
													<p
														style={{
															fontSize: '14px',
															marginBottom: '10px',
														}}
													>
														Feedback Parameter:
													</p>
													<p
														style={{
															fontSize: '14px',
															color: '#2B2F34',
														}}
													>
														{sn[0]}
													</p>
													<div className="feedbackanswer">
														{sn[1]}
													</div>
												</td>
											)}
										</tr>
									))}
								</table>
							</div>
						)}

						{Array.from(Array(numCodingLength).keys()).map(
							(codeques: any, i: number) => (
								<div key={i}>
									<table
										className={`${
											activeTab === 4 + i
												? 'session-details-header-wrapper mentorship-header-table-active '
												: 'session-details-header-wrapper'
										}`}
									>
										<tr
											className={`accordion-header-wrapper  `}
											style={{ fontSize: '14px' }}
										>
											<th
												className="accordion-header codingheader"
												style={{
													color:
														currentFeedback[
															`Coding Question ${i + 1}`
														]['Score'] < 4
															? '#EF574C'
															: currentFeedback[
																	`Coding Question ${i + 1}`
															  ]['Score'] < 6
															? '#F0B416'
															: '#12B76A',
												}}
											>
												Coding Q{i + 1}
												<div className="coding-difficulty">
													{
														currentFeedback[
															`Coding Question ${i + 1}`
														]['Difficulty']
													}
												</div>
											</th>

											<th className="accordion-header">
												<span className="accordion-header-text">
													<div className="topictags">
														{
															currentFeedback[
																`Coding Question ${i + 1}`
															]['Topics']
														}
													</div>
												</span>
											</th>
											<th className="accordion-header">
												<span className="accordion-header-text score-header">
													<p>Score</p>
													<ProgressBar
														length={
															currentFeedback[
																`Coding Question ${i + 1}`
															]['Score']
														}
														color={
															currentFeedback[
																`Coding Question ${i + 1}`
															]['Score'] < 4
																? '#EF574C'
																: currentFeedback[
																		`Coding Question ${
																			i + 1
																		}`
																  ]['Score'] < 6
																? '#F0B416'
																: '#12B76A'
														}
													/>
												</span>
											</th>

											<th
												className="accordion-header-dropdown-text feedback-show-icon"
												style={{
													paddingLeft: '5px',
													paddingRight: '5px',
												}}
												onClick={() =>
													setActiveTab(
														activeTab === 4 + i ? 0 : 4 + i
													)
												}
											>
												{activeTab === 4 + i ? (
													<>
														Hide feedback
														<MdKeyboardArrowUp
															className={'icon'}
														/>
													</>
												) : (
													<>
														Show Feedback
														<MdKeyboardArrowDown
															className={'icon'}
														/>
													</>
												)}
											</th>
										</tr>
									</table>
									<table
										className={`${
											activeTab === 4 + i
												? 'session-content-wrapper mentorship-content-table-active '
												: 'session-content-wrapper'
										}`}
									>
										{Object.entries(
											currentFeedback[
												`Coding Question ${i + 1}`
											]
										).map((sn: any, i: number) => (
											<tr
												className="topic-header"
												key={i}
											>
												{![
													'Score',
													'Difficulty',
													'Topics',
												].includes(sn[0].trim()) && (
													<td className="topic-header-text mock-text topic-width-25">
														<p
															style={{
																fontSize: '14px',
																marginBottom: '10px',
															}}
														>
															Feedback Parameter:
														</p>
														<p
															style={{
																fontSize: '14px',
																color: '#2B2F34',
															}}
														>
															{sn[0]}
														</p>
														<div className="feedbackanswer">
															{sn[1]}
														</div>
													</td>
												)}
											</tr>
										))}
									</table>
								</div>
							)
						)}
						{currentFeedback['Aptitude'] && (
							<div>
								<table
									className={`${
										activeTab === 4 + numCodingLength
											? 'session-details-header-wrapper mentorship-header-table-active '
											: 'session-details-header-wrapper'
									}`}
								>
									<tr
										className={`accordion-header-wrapper  `}
										style={{ fontSize: '14px' }}
									>
										<th
											className="accordion-header"
											style={{
												marginLeft: '10px',
												color:
													currentFeedback[`SS`][
														'Aptitude'
													] < 4
														? '#EF574C'
														: currentFeedback[`SS`][
																'Aptitude'
														  ] < 6
														? '#F0B416'
														: '#12B76A',
											}}
										>
											Aptitude
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text"></span>
										</th>

										<th className="accordion-header">
											<span className="accordion-header-text score-header">
												<p>Score</p>
												<ProgressBar
													length={
														currentFeedback[`SS`][
															'Aptitude'
														]
													}
													color={
														currentFeedback[`SS`][
															'Aptitude'
														] < 4
															? '#EF574C'
															: currentFeedback[`SS`][
																	'Aptitude'
															  ] < 6
															? '#F0B416'
															: '#12B76A'
													}
												/>
											</span>
										</th>

										<th
											className="accordion-header-dropdown-text feedback-show-icon"
											style={{
												paddingLeft: '5px',
												paddingRight: '5px',
											}}
											onClick={() =>
												setActiveTab(
													activeTab === 4 + numCodingLength
														? 0
														: 4 + numCodingLength
												)
											}
										>
											{activeTab === 4 + numCodingLength ? (
												<>
													Hide feedback
													<MdKeyboardArrowUp
														className={'icon'}
													/>
												</>
											) : (
												<>
													Show Feedback
													<MdKeyboardArrowDown
														className={'icon'}
													/>
												</>
											)}
										</th>
									</tr>
								</table>
								<table
									className={`${
										activeTab === 4 + numCodingLength
											? 'session-content-wrapper mentorship-content-table-active '
											: 'session-content-wrapper'
									}`}
								>
									{Object.entries(
										currentFeedback['Aptitude']
									).map((sn: any, i: number) => (
										<tr
											className="topic-header"
											key={i}
										>
											{![
												'Score',
												'Difficulty',
												'Topics',
											].includes(sn[0].trim()) && (
												<td className="topic-header-text mock-text topic-width-25">
													<p
														style={{
															fontSize: '14px',
															marginBottom: '10px',
														}}
													>
														Question Asked:
													</p>
													<p
														style={{
															fontSize: '14px',
															color: '#2B2F34',
														}}
													>
														{sn[0]}
													</p>
													<div className="feedbackanswer">
														{sn[1]}
													</div>
												</td>
											)}
										</tr>
									))}
								</table>
							</div>
						)}
						{currentFeedback['Theory'] && (
							<div>
								<table
									className={`${
										activeTab === 5 + numCodingLength
											? 'session-details-header-wrapper mentorship-header-table-active '
											: 'session-details-header-wrapper'
									}`}
								>
									<tr
										className={`accordion-header-wrapper  theory-`}
										style={{ fontSize: '14px' }}
									>
										<th
											className="accordion-header"
											style={{
												marginLeft: '10px',
												color:
													currentFeedback[`SS`][
														'Theoretical Knowledge'
													] < 4
														? '#EF574C'
														: currentFeedback[`SS`][
																'Theoretical Knowledge'
														  ] < 6
														? '#F0B416'
														: '#12B76A',
											}}
										>
											Theory
										</th>
										<th className="accordion-header">
											<span className="accordion-header-text topictagwrp">
												{currentFeedback['Theory'][
													'Topics'
												].map((tpc: string, i: number) => (
													<div
														className="topictags"
														key={i}
													>
														{tpc}
													</div>
												))}
											</span>
										</th>
										<th className="accordion-header">
											<span className="accordion-header-text score-header">
												<p>Score</p>
												<ProgressBar
													length={
														currentFeedback[`SS`][
															'Theoretical Knowledge'
														]
													}
													color={
														currentFeedback[`SS`][
															'Theoretical Knowledge'
														] < 4
															? '#EF574C'
															: currentFeedback[`SS`][
																	'Theoretical Knowledge'
															  ] < 6
															? '#F0B416'
															: '#12B76A'
													}
												/>
											</span>
										</th>
										<th
											className="accordion-header-dropdown-text feedback-show-icon"
											style={{
												paddingLeft: '5px',
												paddingRight: '5px',
											}}
											onClick={() =>
												setActiveTab(
													activeTab === 5 + numCodingLength
														? 0
														: 5 + numCodingLength
												)
											}
										>
											{activeTab === 5 + numCodingLength ? (
												<>
													Hide feedback
													<MdKeyboardArrowUp
														className={'icon'}
													/>
												</>
											) : (
												<>
													Show Feedback
													<MdKeyboardArrowDown
														className={'icon'}
													/>
												</>
											)}
										</th>
									</tr>
								</table>
								<table
									className={`${
										activeTab === 5 + numCodingLength
											? 'session-content-wrapper mentorship-content-table-active '
											: 'session-content-wrapper'
									}`}
								>
									{Object.entries(
										currentFeedback['Theory']
									).map((sn: any, i: number) => (
										<tr
											className="topic-header"
											key={i}
										>
											{![
												'Score',
												'Difficulty',
												'Topics',
											].includes(sn[0].trim()) && (
												<td className="topic-header-text mock-text topic-width-25">
													<p
														style={{
															fontSize: '14px',
															marginBottom: '10px',
														}}
													>
														Question Asked:
													</p>
													<p
														style={{
															fontSize: '14px',
															color: '#2B2F34',
														}}
													>
														{sn[0]}
													</p>
													<div className="feedbackanswer">
														{sn[1]}
													</div>
												</td>
											)}
										</tr>
									))}
								</table>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default SessionFeedBackBox;
