import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import {
	BsArrowRight,
	BsArrowRightCircle,
} from 'react-icons/bs';
import { FiArrowRight } from 'react-icons/fi';
import { GrClose } from 'react-icons/gr';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchSessionType } from '../../redux/onevone/onevoneSlice';
import ss1 from './ss1.svg';
import ss10 from './ss10.svg';
import ss2 from './ss2.svg';
import ss3 from './ss3.svg';
import ss4 from './ss4.svg';
import ss5 from './ss5.svg';
import ss6 from './ss6.svg';
import ss7 from './ss7.svg';
import ss8 from './ss8.svg';
import ss9 from './ss9.svg';
import { Grid } from '@mui/material';
import SoftskillCard from '../../components/softskill/SoftskillCard';
// import '../../styles/softskills.scss';
const style = {
	background: '#2F80ED',
	color: '#FFFFFF',
	borderRadius: '50%',
};

const style1 = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #2F80ED',
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',
};
const SoftSkillsDash = () => {
	const [showCelebration, setShowCelebration] =
		useState(false);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const courseState = useSelector(
		(state: any) => state.course
	);
	const { batchManagerPhoneNumber } = courseState;

	const contentArray = {
		left: [
			{
				btnIcon: <BsArrowRight />,
				textColor: '#fff',
				title:
					'Prepare your answers for the most asked HR interview questions',
				buttonText: 'Start Practicing',
				buttonLink:
					'https://acciojob.notion.site/Answering-the-most-common-interview-questions-968a9beb665a43b9b3a77c93753618d0',
				content: '',
				imageSrc: ss1,
				btnVarient: 'outline',
				customclass: 'ssPrepare-btn',
				cardBgColor: '#2B2F34',
			},
			{
				btnIcon: <BsArrowRightCircle />,
				textColor: '',
				title: 'Learn about interview etiquettes',
				content:
					'Most common etiquettes to be followed during a job interview',
				buttonText: 'Check It Out',
				buttonLink:
					'https://acciojob.notion.site/Interview-etiquette-9817a776125044babf1ed12b2e50f8d3',
				imageSrc: ss2,
				btnVarient: 'outline',
				customclass: 'ssEtiquettes-btn',
				cardBgColor: '#FAFAFA',
			},
			{
				btnIcon: <BsArrowRightCircle />,
				textColor: '',
				title: 'What should you do before an interview?',
				content:
					'Things to keep in mind to be well prepared for an interview',
				buttonText: 'Check It Out',
				buttonLink:
					'https://acciojob.notion.site/Preparing-before-an-interview-919454e86d9b4763a216c52027d37d9f',
				imageSrc: ss3,
				btnVarient: 'outline',
				customclass: 'ssBeforeInterview-btn',
				cardBgColor: '#FAFAFA',
			},
			{
				btnIcon: <BsArrowRightCircle />,
				textColor: '',
				title:
					'Learn how to write cold emails and cover letters!',
				content:
					'Quick and comprehensive guide to cold email and cover letters',
				buttonText: 'Check It Out',
				buttonLink:
					'https://acciojob.notion.site/Writing-emails-and-cover-letters-c74443f55ee842b3a65b6644b19e2ebe',
				imageSrc: ss4,
				btnVarient: 'outline',
				customclass: 'ssEmails-btn',
				cardBgColor: '#FAFAFA',
			},
			{
				btnIcon: <BsArrowRightCircle />,
				textColor: '',
				title: 'Lagging in personality skills? Check this!',
				content:
					'Common life skills to help you in the longer run',
				buttonText: 'Check It Out',
				buttonLink:
					'https://acciojob.notion.site/Skills-to-help-you-in-the-longer-run-c2944c0f919049aaaddc71cc0ab92b62',
				imageSrc: ss5,
				btnVarient: 'outline',
				customclass: 'ssPersonality-btn',
				cardBgColor: '#FAFAFA',
			},
		],
		right: [
			{
				setOpen: setOpen,
				btnIcon: <BsArrowRight />,
				textColor: '#2976df',
				title: 'Get your <b>Resume</b> reviewed',
				content: '',
				buttonText: 'Book Now',
				imageSrc: ss6,
				btnVarient: 'primary',
				customclass: 'ssResumeReview-btn',
				cardBgColor: '#FAFAFA',
			},
			{
				btnIcon: <BsArrowRight />,
				textColor: '#ff940a',
				title: '<b>Aptitude</b> Handbook',
				buttonText: 'Solve Now',
				content: '',
				buttonLink:
					'https://acciojob.notion.site/Aptitude-Handbook-60b6506093ec4505b7ee3d8d3e4d6c24',
				imageSrc: ss7,
				btnVarient: 'primary',
				customclass: 'ssAptitude-btn',
				cardBgColor: '#fff',
			},
			// {
			// 	btnIcon: <BsArrowRight />,
			// 	textColor: '#814ceb',
			// 	title: 'Join our <b>Discord</b> Community',
			// 	buttonText: 'Join Now',
			// 	content: '',
			// 	buttonLink: 'https://discord.com/invite/gVVR7baGY3',
			// 	imageSrc: ss8,
			// 	btnVarient: 'primary',
			// 	customclass: 'ssDiscord-btn',
			// 	cardBgColor: '#fff',
			// },
			{
				btnIcon: <BsArrowRight />,
				textColor: '',
				title:
					'Want to know, how an ideal Linkedin profile looks like?',
				buttonText: 'Check it out',
				content: '',
				buttonLink:
					'https://acciojob.notion.site/Building-your-Linkedin-profile-7158108d1e5b43e8b30f7c65931d5f44',
				imageSrc: ss9,
				btnVarient: 'primary',
				customclass: 'ssLinkedin-btn',
				cardBgColor: '#fff',
			},
			{
				btnIcon: <BsArrowRight />,
				textColor: '',
				title:
					'No idea, about an Ideal Resume? Don’t worry',
				buttonText: 'Check it out',
				content: '',
				buttonLink:
					'https://acciojob.notion.site/Resume-that-stands-out-dab703040ab846e681c856bec3767786',
				imageSrc: ss10,
				btnVarient: 'primary',
				customclass: 'ssResume-btn',
				cardBgColor: '#fff',
			},
		],
	};

	return (
		<>
			{open ? (
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style1}>
						<div
							className="class234cvf"
							onClick={() => setOpen(false)}
						>
							<GrClose
								style={{
									color: '#2666be',
									cursor: 'pointer',
								}}
							/>
						</div>
						<Typography
							id="modal-modal-title"
							variant="h6"
							component="h2"
						>
							You are not currently eligible for the HR
							Mock, please contact your batch manager.
						</Typography>
						<button
							id="popupButton"
							onClick={() => {
								window.open(
									`https://wa.me/+91${batchManagerPhoneNumber}`
								);
								// componentToggler(5;
							}}
						>
							Contact BM{' '}
							<BsArrowRightCircle style={style} />
						</button>
					</Box>
				</Modal>
			) : null}

			<Grid
				container
				spacing={2}
				className={'pg-softskill'}
				// sx={{ width: '100%', marginLeft: '0px !important' }}
			>
				<Grid
					item
					lg={8}
					md={8}
					sm={9}
					xs={7}
				>
					<h1>Welcome Back User</h1>
					<Box className={'pg-softskill--box'}>
						{contentArray.left.map(
							(content: any, index: number) => (
								<SoftskillCard
									key={index}
									setOpen={content.setOpen}
									btnIcon={content.btnIcon}
									textColor={content.textColor}
									imgSrc={content.imageSrc}
									title={content.title}
									description={content.content}
									btnText={content.buttonText}
									btnUrl={content.buttonLink}
									cardBgColor={content.cardBgColor}
									btnVariant={content.btnVarient}
									btnCustomClass={content.customclass}
								/>
							)
						)}
					</Box>
				</Grid>
				<Grid
					item
					lg={4}
					md={4}
					sm={3}
					xs={4}
					sx={{ background: '#F3F6F9' }}
				>
					<Box className={'pg-softskill--box'}>
						{contentArray.right.map(
							(content: any, index: number) => (
								<SoftskillCard
									key={index}
									setOpen={content.setOpen}
									btnIcon={content.btnIcon}
									textColor={content.textColor}
									imgSrc={content.imageSrc}
									title={content.title}
									description={content.content}
									btnText={content.buttonText}
									btnUrl={content.buttonLink}
									cardBgColor={content.cardBgColor}
									btnVariant={content.btnVarient}
									btnCustomClass={content.customclass}
								/>
							)
						)}
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

export default SoftSkillsDash;
