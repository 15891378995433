import React, { useState } from 'react';
import home from '../../logo.svg';
import leaderboardImg from './../../assets/images/leaderboard.svg';
import leaderboardBG from './../../assets/images/leader-bg.svg';
import clockLogo from './clockLogo.svg';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
	fetchContests,
	fetchQuestions,
	fetchTopContestsLeaderboard,
	fetchContestsLeaderboard,
	resetContestLeaderboard,
} from '../../redux/contest/contestSlice';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import { FiArrowRight } from 'react-icons/fi';
import { BiHomeAlt } from 'react-icons/bi';
import { BsChevronRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import crown from '../../assets/images/crown.svg';
import Loader from '../../components/Loader';
import { useTimer } from 'react-timer-hook';
import Timer from './timer';
import { Waypoint } from 'react-waypoint';
import queryString from 'query-string';
import PastContest from '../../components/contestComponents/PastContest';
import LiveContest from '../../components/contestComponents/LiveContest';
import UpcomingContest from '../../components/contestComponents/UpcomingContest';
import MissedContest from '../../components/contestComponents/MissedContest';
import OngoingContest from '../../components/contestComponents/OngoingContest';
import UpcomingGiven from '../../components/contestComponents/UpcomingGiven';
import OpenContest from '../../components/contest/OpenContest';
import { AppDispatch } from '../../redux/store';
import OpenGivenContest from '../../components/contest/OpenGiven';
// import 'react-circular-progressbar/dist/styles.css';

const ContestInfo = () => {
	const [contestTimer, setContestTimer] = useState(0);
	const [expired, setExpired] = useState(false);
	const [timeLeft, setTimeLeft] = useState(null);
	const [page, setPage] = useState(0);
	const [hasNextPage, setHasNextPage] = useState(true);
	// const [contestEnded, setContestEnded] = useState(true);
	const [noContestData, setNoContestData] = useState(null);

	const itemsPerPage = 10;

	const dispatch = useDispatch<AppDispatch>();

	const contestInfoState = useSelector(
		(state: any) => state.contest
	);

	const [toShow, setToShow] = useState(-1);

	const {
		contestName,
		contestEnded,
		instructions,
		codingQuestions,
		webDevQuestions,
		contestData,
		isAttempted,
		isContestLive,
		duration,
		startTime,
		endTime,
		currentUser,
		contestLeaderboard,
		flag,
	} = contestInfoState;
	const courseState = useSelector(
		(state: any) => state.course
	);
	const {
		latestAssignment,
		courseID,
		course,
		allCourses,
		courseName,
		leaderboard,
		latestAssignmentData,
		totalAssignmentQuestions,
		totalSolvedAssignmentQuestions,
		upcomingMeetings,
	} = courseState;

	const contestState = useSelector(
		(state: any) => state.contest
	);

	const { allContest, loading } = contestState;

	const navigate = useNavigate();

	const navigator2 = () => {
		const path2 = '/';
		navigate(path2);
	};

	const breadCrumbNavigation = (path: string) => {
		navigate(path);
	};

	const location = useLocation();
	// console.log("USELOCATION OUTPUT", contestLocation);

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const contestId: string = queryString.parse(
		location.search
	).contestId;

	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	//@ts-ignore
	const contestStatus: string = queryString.parse(
		location.search
	).type;

	console.log('CONTEST ID', contestId);

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		dispatch(fetchContests(contestId));
		dispatch(resetContestLeaderboard());
	}, []);
	useEffect(() => {
		if (
			flag === 'past-given' ||
			flag === 'past-not-given' ||
			flag === 'open-given'
		) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//   @ts-ignore
			dispatch(fetchQuestions(contestId));
			console.log('being called with page: ', page);
			const temp = {
				contestId: contestId,
			};
			// setPage(page + 1);
		}
	}, [contestData]);
	useEffect(() => {
		if (contestData) {
			console.log('being called with without page: ');
			if (
				flag === 'past-given' ||
				flag === 'past-not-given'
			) {
				dispatch(
					fetchContestsLeaderboard({
						contestId: contestId,
						pageNumber: page + 1,
					})
				);
				setPage(page + 1);
				dispatch(fetchTopContestsLeaderboard(contestId));
			}
		}
	}, [contestData]);
	const bringSomeMore = () => {
		const temp = {
			contestId: contestId,
			pageNumber: page + 1,
		};
		setPage(page + 1);

		dispatch(fetchContestsLeaderboard(temp));
	};

	const startContest = () => {
		const path = `https://contest2.acciojob.com/?contestID=${contestId}`;
		window.open(path);
	};

	const date = new Date();
	const currentTime = date.getTime();
	const percentage = 100;
	//   const startT = new Date(contestData.startTime);
	//   const endT = new Date(contestData.endTime);

	const expiryHandler = () => {
		setExpired(true);
	};

	useEffect(() => {
		if (contestData !== null) {
			const timeleft = new Date(contestData.endTime);
			const date = new Date();

			console.log(
				'TIMELEFT',
				timeleft.getTime() - date.getTime()
			);

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setTimeLeft(timeleft);
		}
	}, [contestData]);

	const getContestMarks = (questionArray: []) => {
		let totalMarks = 0;
		questionArray.map((question: any) => {
			if (question.isSolvedDuringContest) {
				question.scoreInContest
					? (totalMarks += Number(question.scoreInContest))
					: (totalMarks += Number(question.score));
			} else {
				totalMarks += 0;
			}
		});
		return totalMarks;
	};
	return (
		<div className="mainContainer">
			<div className={'column-1'}>
				<div className="contestInfoContainer">
					<div className="pathInfo">
						<span onClick={() => breadCrumbNavigation('/')}>
							<BiHomeAlt className={'bread-home-icon'} />
						</span>

						<BsChevronRight className={'bread-next-icon'} />
						<span
							onClick={() =>
								breadCrumbNavigation('/contests')
							}
						>
							All Contests
						</span>
						<BsChevronRight className={'bread-next-icon'} />
						<span className="nameInfo">
							{' '}
							{contestName}{' '}
						</span>
					</div>

					<div className="topPath">
						<div className="headings">
							<h1 className="contestNameHead">
								{contestName}{' '}
								{flag === 'running-contest' ||
								flag === 'live-not-given' ? (
									<p className={'live-contest'}>LIVE</p>
								) : null}
							</h1>

							{flag !== 'running-contest' &&
							(flag === 'live-not-given' ||
								flag === 'open-not-given') ? (
								<button
									onClick={startContest}
									className="contestButton"
								>
									Start Contest <FiArrowRight />
								</button>
							) : flag === 'running-contest' ? (
								<button
									onClick={startContest}
									className="ongoingContestButton"
								>
									Resume Contest <FiArrowRight />
								</button>
							) : null}
						</div>
					</div>
					<div className={'contest-type-container'}>
						{contestData !== null ? (
							<>
								{flag === 'past-given' ? (
									<PastContest contestData={contestData} />
								) : null}
								{flag === 'live-given' ||
								flag === 'live-not-given' ? (
									<LiveContest contestData={contestData} />
								) : null}
								{flag === 'upcoming' ? (
									<UpcomingContest
										contestData={contestData}
									/>
								) : null}
								{flag === 'past-not-given' ? (
									<MissedContest
										contestData={contestData}
									/>
								) : null}
								{flag === 'running-contest' ? (
									<OngoingContest
										contestData={contestData}
									/>
								) : null}
								{flag === 'upcomig-given' ? (
									<UpcomingGiven
										contestData={contestData}
									/>
								) : null}
								{flag === 'open-not-given' ? (
									<OpenContest contestData={contestData} />
								) : null}
								{flag === 'open-given' ? (
									<OpenGivenContest
										contestData={contestData}
									/>
								) : null}
							</>
						) : (
							<p>Contest Not Available</p>
						)}
					</div>
				</div>
			</div>
			<div className={'column-2'}>
				{flag === 'past-given' ||
				flag === 'past-not-given' ? (
					<div className={'contestLeaderboard'}>
						{
							contestLeaderboard.length >= 3 ? (
								<>
									<div
										className={'contestLeaderboard-head'}
									>
										<h1>Leaderboard</h1>
									</div>

									<div
										className={'contestLeaderboard-content'}
									>
										<div className={'leader-2'}>
											<p className={'leader-image-2'}>
												{contestLeaderboard[1].name[0]}
											</p>
											<h1>
												{contestLeaderboard[1].name !== ''
													? contestLeaderboard[1].name
													: 'A'}
											</h1>
											<div className={'leader-score'}>
												<span>2</span>
												{contestLeaderboard[1].total} pts
											</div>
											<img
												className={'stage'}
												src={`${window.location.origin}/defaults/leader2.svg`}
											/>
										</div>
										<div className={'leader-1'}>
											<img
												src={crown}
												alt={'crown'}
											/>

											<p className={'leader-image-2'}>
												{contestLeaderboard[0].name[0]}
											</p>
											<h1>
												{contestLeaderboard[0].name !== ''
													? contestLeaderboard[0].name
													: 'A'}
											</h1>
											<div className={'leader-score'}>
												<span>1</span>
												{contestLeaderboard[0].total} pts
											</div>
											<img
												className={'stage'}
												src={`${window.location.origin}/defaults/leader2.svg`}
											/>
										</div>
										<div className={'leader-3'}>
											<p className={'leader-image-2'}>
												{contestLeaderboard[2].name[0]}
											</p>
											<h1>
												{contestLeaderboard[2].name !== ''
													? contestLeaderboard[2].name
													: 'A'}
											</h1>
											<div className={'leader-score'}>
												<span>3</span>
												{contestLeaderboard[2].total} pts
											</div>
											<img
												className={'stage'}
												src={`${window.location.origin}/defaults/leader2.svg`}
											/>
										</div>
									</div>
									<ul className={'contestLeaderboard-list'}>
										<li className={'leader-info'}>
											<span className={'leader-rank'}>
												Rank
											</span>
											<span className={'leader-name'}>
												Name
											</span>
											<span className={'leader-score-list'}>
												Score
											</span>
										</li>
										{flag !== 'past-not-given' &&
										currentUser !== null ? (
											<li
												key={currentUser.userId}
												className={
													'leader-info  leader-info-current'
												}
											>
												<span className={'leader-rank'}>
													{currentUser.rank}
												</span>
												<span className={'leader-name'}>
													{currentUser.name} (YOU)
												</span>
												<span
													className={'leader-score-list'}
												>
													{currentUser.total}
												</span>
											</li>
										) : null}

										{contestLeaderboard.map(
											(el: any, index: number) => {
												if (index > 2) {
													console.log(
														'leaderboard element',
														contestLeaderboard
													);
													return (
														<>
															<li
																key={el.userId}
																className={'leader-info'}
															>
																<span
																	className={'leader-rank'}
																>
																	{el.rank}
																</span>
																<span
																	className={'leader-name'}
																>
																	{el.name}
																</span>
																<span
																	className={
																		'leader-score-list'
																	}
																>
																	{parseInt(
																		el.total
																	).toFixed(2)}
																</span>
															</li>
														</>
													);
												}
											}
										)}

										<li>
											<Waypoint
												onEnter={() => bringSomeMore()}
											/>
										</li>
									</ul>
								</>
							) : null
							// <div className="leaderboardImgDiv2">
							/* <img
									src={leaderboardImg}
									alt="leaderboard"
									className="leaderboardImgInfoPage2"
								/> */
							// </div>
						}
					</div>
				) : loading ? (
					<Loader />
				) : (
					<div className="leaderboardImgDiv">
						<img
							src={leaderboardBG}
							alt="leaderboard"
							className="leaderboardImgDiv-bg"
						/>
						<img
							src={leaderboardImg}
							alt="leaderboard"
							className="leaderboardImgInfoPage"
						/>
						<h3>Leaderboard</h3>
						<p>
							Scores will appear here
							<br />
							once Contest ends
						</p>
					</div>
				)}
			</div>
		</div>
	);
};

export default ContestInfo;
