import { useAppSelector } from '../../../../../redux/store';
import Heatmap from '../../../../gym/HeatMap';
import HeatmapCardShare from '../PreviewComponents/HeatmapComponent';

export const HeatMap = ({ vertical }: any) => {
	const { heatMapData, isLoading } = useAppSelector(
		(state) => state.gym
	);
	if (heatMapData)
		return (
			<div
				className="preview-card"
				style={{
					flexDirection: 'column',
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					height: vertical ? '320px' : '150px',
					marginBottom: 0,
					flex: 1,
					position: 'relative',
				}}
			>
				{vertical ? (
					<div
						style={{
							transform: 'scale(0.6)',
							position: 'absolute',
							left: '-0.5rem',
							top: '-6.2rem',
						}}
					>
						<HeatmapCardShare
							data={heatMapData}
							legend={false}
						/>
					</div>
				) : (
					<div
						style={{
							transform: 'scale(0.7)',
							marginTop: '-1rem',
							marginLeft: '-6rem',
						}}
					>
						<Heatmap
							data={heatMapData}
							legend={false}
						/>
					</div>
				)}
			</div>
		);
	else return null;
};
