import { Loader } from '@acciojob/loader';
import React, { useEffect, useState } from 'react';
// icons
import mapPin from '../../assets/images/icons/mapPin.svg';
import money from '../../assets/images/icons/money.svg';
import time from '../../assets/images/icons/time.svg';
// api
import {
	Box,
	Chip,
	Modal,
	ModalClose,
	ModalDialog,
	Typography,
} from '@mui/joy';
import { services } from '../../apis';
import { JobDetailsData } from '../../apis/JobPortalService';
import { PopupState } from '../../pages/jobportal/JobPortal';
import AccioButton from '../elements/Button';
import NotInterestedForm from './NotInterestedForm';
import PopupBody from './PopupBody';
import SlideInContent from './SlideInContent';

interface PopupProps {
	jobDetailsPopup: PopupState;
	setJobDetailsPopup: (newState: PopupState) => void;
	setApplyPopup: (newState: PopupState) => void;
	setBlackListModalOpen: (newState: boolean) => void;
	placementFlag: boolean;
	activeTab: number;
}

const JobDetailsPopup = ({
	jobDetailsPopup,
	placementFlag,
	setJobDetailsPopup,
	setApplyPopup,
	setBlackListModalOpen,
	activeTab,
}: PopupProps) => {
	const [loading, setLoading] = useState(false);
	const [jobDetailsData, setJobDetailsData] = useState(
		{} as JobDetailsData
	);
	const popupIdentifer =
		jobDetailsPopup.identifier.split('source:')[0];
	const popupSource =
		jobDetailsPopup.identifier.split('source:')[1];

	console.log('popupIdentifer', jobDetailsPopup);

	const [scroll, setScroll] = React.useState<boolean>(true);
	const [interestedPopup, setInterestedPopup] =
		React.useState<boolean>(
			jobDetailsPopup.notInterested ?? false
		);

	useEffect(() => {
		if (jobDetailsPopup.notInterested !== undefined) {
			setInterestedPopup(jobDetailsPopup.notInterested);
		}
		const fetchJobDetails = async () => {
			if (popupIdentifer !== '') {
				setLoading(true);
				const data =
					await services.jobPortalService.fetchJobDetails(
						popupIdentifer
					);
				console.log(data);
				if (typeof data?.data === 'string')
					setJobDetailsData({} as JobDetailsData);
				else setJobDetailsData(data.data);
				setTimeout(() => {
					setLoading(false);
				}, 300);
			}
		};

		fetchJobDetails();
	}, [jobDetailsPopup]);

	const handleClose = () => {
		setJobDetailsPopup({ isOpen: false, identifier: '' });
		setTimeout(() => {
			setJobDetailsData({} as JobDetailsData);
		}, 300);
		setPage(1);
		setItems([]);
		setViewTable(false);
	};

	const [swipeDirection, setSwipeDirection] = useState('');

	const handleSwipe = (direction: string) => {
		setSwipeDirection(direction);
	};

	const formatDate = (date: string) => {
		const newDate = new Date(date);
		const day = newDate.getDate();
		const month = newDate
			.toLocaleString('default', { month: 'long' })
			.substring(0, 3);
		const time = newDate.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
		});

		return day + ' ' + month + ', ' + time;
	};

	const [viewTable, setViewTable] = useState(false);

	const [items, setItems] = useState<any[]>([]);
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);

	const fetchMoreData = async () => {
		setIsLoading(true);

		const response =
			await services.jobPortalService.fetchAppliedStudentsStatus(
				popupIdentifer,
				page
			);

		const newItems: any[] = [...response];
		setPage(page + 1);
		setItems((prevItems) => [...prevItems, ...newItems]);
		setIsLoading(false);
	};

	useEffect(() => {
		if (popupIdentifer) fetchMoreData();
	}, [popupIdentifer]);

	const {
		blacklisted,
		id,
		minCtcOffered,
		maxCtcOffered,
		deadline,
		jd,
		location,
		rolesOffered,
		selectionProcess,
		companyName,
		eligibility,
		disqualificationReasons,
		resources,
		isRejected,
		rejectedReason,
		isHold,
		endAt,
		isHoldReason,
		stageNotes,
		isInterested = true,
	} = jobDetailsData || {};

	return (
		<Modal
			open={jobDetailsPopup.isOpen}
			onClose={handleClose}
		>
			<ModalDialog aria-labelledby="dialog-vertical-scroll-title">
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				{isHold ? (
					<div className="job-on-hold-tag">
						<p>This drive is currently on hold</p>
					</div>
				) : null}
				<Box
					sx={{
						overflowY: scroll ? 'scroll' : 'initial',
						mx: 'calc(-1 * var(--ModalDialog-padding))',
						px: 'var(--ModalDialog-padding)',
						maxHeight: 'calc(100% - 64px)',
						'&::-webkit-scrollbar': {
							width: '5px',
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#D0D5DD',
							borderRadius: '5px',
						},
						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#98A2B3',
						},
					}}
					className="thin-scrollbar"
				>
					{/* Here we check if jobDetailsData exists, if not we show the error message */}
					{!jobDetailsData ? (
						<>
							<Typography
								level="h4"
								endDecorator={
									<Chip
										size="sm"
										variant="soft"
										color="danger"
									>
										<Typography
											variant="soft"
											color="danger"
											startDecorator="🚨"
											sx={{
												alignItems: 'flex-start',
												maxWidth: 'max-content',
											}}
										>
											<b> Error </b>
										</Typography>
									</Chip>
								}
								justifyContent="center"
							>
								No data found something
							</Typography>
							<Typography
								level="h4"
								justifyContent="center"
							>
								occurred contact to your BM
							</Typography>
						</>
					) : (
						<div className="job-portal-job-details-popup-inner">
							<>{console.log('isHold', isHold)}</>

							{loading === true && (
								<div className="loader-container">
									<Loader
										factVisibility={false}
										width={'100%'}
										height={'100%'}
									/>
								</div>
							)}
							{loading === false &&
								Object.keys(jobDetailsData).length ===
									0 && (
									<p>
										Some error occurred, please try again
										later.
									</p>
								)}
							{loading === false &&
								Object.keys(jobDetailsData).length !==
									0 && (
									<>
										{interestedPopup ? (
											<div className="popup-header-wrapper">
												<div className="popup-header">
													<div style={{ width: '100%' }}>
														<div className="popup-header-flex">
															{companyName && (
																<h2>{companyName}</h2>
															)}
															{eligibility ? (
																<AccioButton
																	action={() => {
																		setInterestedPopup(
																			!interestedPopup
																		);
																	}}
																	title={'Go Back'}
																	variant="primary"
																	icon={''}
																	customClass="job-details-popup-btn"
																/>
															) : null}
														</div>
													</div>
												</div>
												<div className="meta">
													<NotInterestedForm
														jobId={id}
														setInterestedPopup={
															setInterestedPopup
														}
														setJobDetailsPopup={
															setJobDetailsPopup
														}
													/>
												</div>
											</div>
										) : (
											<>
												<div className="popup-header-wrapper">
													<div className="popup-header">
														<div style={{ width: '100%' }}>
															<div className="popup-header-flex">
																{companyName && (
																	<h2>{companyName}</h2>
																)}
																{eligibility ? (
																	<AccioButton
																		action={() => {
																			setInterestedPopup(
																				!interestedPopup
																			);
																		}}
																		title={
																			isInterested
																				? 'Mark Not Interested'
																				: 'Marked As Not Interested'
																		}
																		disabled={!isInterested}
																		variant={
																			isInterested
																				? 'primary'
																				: 'disable'
																		}
																		icon={''}
																		customClass="job-details-popup-btn"
																	/>
																) : null}
															</div>

															<p>
																{location && (
																	<span
																		className={
																			location.length > 1
																				? 'custom-tooltip'
																				: ''
																		}
																		data-tooltip={location.join(
																			', '
																		)}
																	>
																		<img src={mapPin} />{' '}
																		{location.length > 1
																			? 'Multiple Locations'
																			: location[0]}
																	</span>
																)}
																{minCtcOffered &&
																	maxCtcOffered && (
																		<span>
																			<img src={money} />{' '}
																			{minCtcOffered +
																				'LPA - ' +
																				maxCtcOffered +
																				'LPA'}
																		</span>
																	)}
															</p>
														</div>
													</div>
													<div className="meta">
														{rolesOffered && (
															<p>
																<span>
																	OPEN POSITIONS:{' '}
																</span>{' '}
																{rolesOffered.join(', ')}
															</p>
														)}
														{selectionProcess && (
															<p>
																<span>TIMELINE:</span>{' '}
																{selectionProcess.join(
																	' -> '
																)}
															</p>
														)}
													</div>
												</div>
												{popupSource === 'applications' &&
												!viewTable ? (
													<div className="check-status-wrapper">
														<p className="check-status-text">
															Want to know how other student
															performed ?
														</p>
														<AccioButton
															customClass="check-status-btn"
															variant="primary"
															action={() => {
																setViewTable(!viewTable);
																handleSwipe('left');
															}}
															icon={''}
															title="Check Status"
														/>
													</div>
												) : null}

												<div
													className={`swipeable-content ${swipeDirection}`}
												>
													{popupSource ===
													'applications' ? (
														<>
															{viewTable ? (
																<SlideInContent
																	items={items}
																	fetchMoreData={
																		fetchMoreData
																	}
																	isLoading={isLoading}
																	setViewTable={
																		setViewTable
																	}
																	handleSwipe={handleSwipe}
																	viewTable={viewTable}
																/>
															) : (
																<PopupBody
																	eligibility={eligibility}
																	placementFlag={
																		placementFlag
																	}
																	popupSource={popupSource}
																	jobDetailsPopup={
																		jobDetailsPopup
																	}
																	isHold={isHold}
																	isRejected={isRejected}
																	endAt={endAt}
																	jd={jd}
																	resources={resources}
																	disqualificationReasons={
																		disqualificationReasons
																	}
																	rejectedReason={
																		rejectedReason
																	}
																	isHoldReason={
																		isHoldReason
																	}
																	stageNotes={stageNotes}
																/>
															)}
														</>
													) : (
														<PopupBody
															eligibility={eligibility}
															placementFlag={placementFlag}
															popupSource={popupSource}
															jobDetailsPopup={
																jobDetailsPopup
															}
															isHold={isHold}
															isRejected={isRejected}
															endAt={endAt}
															jd={jd}
															resources={resources}
															disqualificationReasons={
																disqualificationReasons
															}
															rejectedReason={
																rejectedReason
															}
														/>
													)}
												</div>
												{isInterested ? (
													<>
														{popupSource !==
															'applications' &&
														jobDetailsPopup.identifier !==
															'' &&
														placementFlag ? (
															<div className="popup-footer">
																<AccioButton
																	action={() => {
																		if (eligibility) {
																			handleClose();
																			console.log(
																				'blacklist',
																				blacklisted
																			);

																			if (blacklisted)
																				setBlackListModalOpen(
																					true
																				);
																			else
																				setApplyPopup({
																					isOpen: true,
																					identifier: id,
																				});
																		}
																	}}
																	disabled={!eligibility}
																	title={
																		eligibility
																			? 'Apply now'
																			: 'Not Eligible'
																	}
																	variant="primary"
																	icon={''}
																	customClass="job-details-popup-btn"
																/>

																{deadline && (
																	<div className="deadline">
																		<img src={time} />
																		<span>
																			Apply Before:
																		</span>{' '}
																		{formatDate(deadline)}
																	</div>
																)}
															</div>
														) : null}
													</>
												) : null}
											</>
										)}
									</>
								)}
						</div>
					)}
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default JobDetailsPopup;
