import { useEffect, useState } from 'react';
import { services } from '../../../apis';
import DashboardCard from '../../../components/softSkillsDashboard/DashboardCard';
import { SoftSkillsCourseType } from '../../../redux/softskills/softskills.type';
import {
	RootState,
	useAppSelector,
} from '../../../redux/store';
import PageHeading from '../../../components/shared/PageHeading';

const SoftSkillsDashboard = () => {
	const { user } = useAppSelector(
		(state: RootState) => state.user
	);
	const [softSkillsCourses, setSoftSkillsCourses] =
		useState<Array<SoftSkillsCourseType>>([]);

	const getCourses = async () => {
		try {
			const res =
				await services.softskillsService.getSoftSkillsCourses();
			setSoftSkillsCourses(res);
		} catch (error) {
			console.log(error);
		}
	};
	useEffect(() => {
		if (user) {
			getCourses();
		}
	}, [user]);

	return (
		<main className="soft-skills-dashboard">
			<PageHeading pageName={'Soft Skills'} />
			<div className={`soft-skills-cards`}>
				{softSkillsCourses.map((course, index) => (
					<DashboardCard
						key={index}
						id={course.id}
						cardindex={index}
						title={course.title}
						solvedAssignmentsCount={
							course.solvedAssignmentsCount
						}
						totalAssignmentsCount={
							course.totalAssignmentsCount
						}
						videosCount={course.videosCount}
						videosDuration={course.videosDuration}
					/>
				))}
			</div>
		</main>
	);
};

export default SoftSkillsDashboard;
