import React, { useEffect, useRef, useState } from 'react';
import {
	Calendar,
	EventWrapperProps,
	ToolbarProps,
	momentLocalizer,
} from 'react-big-calendar';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../apis';

import {
	LiaAngleLeftSolid,
	LiaAngleRightSolid,
} from 'react-icons/lia';

import { View } from 'react-big-calendar';

import noTaskLottie from '../../assets/lottie/no_task.json';

import { Loader } from '@acciojob/loader';
import { nanoid } from 'nanoid';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsCalendar3Event } from 'react-icons/bs';
import { FiArrowUpRight } from 'react-icons/fi';
import { GoDotFill } from 'react-icons/go';
import { IoPricetagsOutline } from 'react-icons/io5';
import { RiCalendarLine } from 'react-icons/ri';
import Lottie from 'react-lottie-player';
import { Popper, Reference } from 'react-popper';
import { useNavigate } from 'react-router-dom';
import AccioButton from '../../components/elements/Button';
import { RootState } from '../../redux/store';
import {
	setSelectedDayEvents,
	setStateEventModalView,
	setToolTipPosition,
} from '../../redux/user/userSlice';
const localizer = momentLocalizer(moment);

const EventWrapper: React.FC<EventWrapperProps> = (
	props
) => {
	const event: any = props.event;

	const dispatch = useDispatch();
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	const handleTooltipClick = (e: React.MouseEvent) => {
		const clickedEvent = e.currentTarget;
		const rect = clickedEvent.getBoundingClientRect();
		const tooltipWidth =
			tooltipRef &&
			tooltipRef.current &&
			tooltipRef.current.offsetWidth;
		const tooltipHeight =
			tooltipRef &&
			tooltipRef.current &&
			tooltipRef.current.offsetHeight;

		let top =
			rect.top +
			window.scrollY +
			clickedEvent.clientHeight +
			10;
		let left = rect.left + window.scrollX;

		const ele = document.getElementById(
			'calender-container'
		);
		const viewportWidth = ele && ele.clientWidth;
		const viewportHeight = ele && ele.clientHeight;

		if (viewportWidth && tooltipWidth) {
			const spaceOnRight =
				viewportWidth - (left - window.scrollX);
			if (spaceOnRight < tooltipWidth + 50) {
				left = left - tooltipWidth - 50;
			} else {
				left += 50;
			}
		}

		if (left < 0) {
			left = rect.right + window.scrollX;
		}

		if (
			viewportHeight &&
			tooltipHeight &&
			top + tooltipHeight + 280 > viewportHeight
		) {
			top = viewportHeight - tooltipHeight - 420;
		} else {
			top = top - 50;
		}

		if (top < 0) {
			top = rect.bottom + window.scrollY;
		}

		dispatch(setToolTipPosition({ top, left }));
		dispatch(setStateEventModalView(true));
	};

	const handleTooltipClose = () => {
		dispatch(setStateEventModalView(false));
	};

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				tooltipRef.current &&
				!tooltipRef.current.contains(event.target as Node)
			) {
				handleTooltipClose();
			}
		};

		document.addEventListener(
			'mousedown',
			handleClickOutside
		);

		return () => {
			document.removeEventListener(
				'mousedown',
				handleClickOutside
			);
		};
	}, []);

	const [activeEvent, setActiveEvent] = useState(null);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				tooltipRef.current &&
				!tooltipRef.current.contains(event.target as Node)
			) {
				setActiveEvent(null); // Deselect active event
				handleTooltipClose();
			}
		};

		document.addEventListener(
			'mousedown',
			handleClickOutside
		);

		return () => {
			document.removeEventListener(
				'mousedown',
				handleClickOutside
			);
		};
	}, []);

	return (
		<Reference>
			{({ ref }) => (
				<div
					ref={(node) => {
						// @ts-ignore
						ref(node);
						tooltipRef.current = node;
					}}
					className={`calender-show-event ${
						activeEvent === event.id ? 'active' : ''
					}`}
					onClick={(e) => {
						if (activeEvent !== event.id) {
							setActiveEvent(event.id);
						}
						handleTooltipClick(e);
						dispatch(setSelectedDayEvents(event));
					}}
				>
					<span className={` icon icon-${event.type}`}>
						<GoDotFill />
					</span>
					<span className="text">
						{event.type.charAt(0).toUpperCase() +
							event.type.slice(1)}
					</span>
				</div>
			)}
		</Reference>
	);
};

interface ShowAllEventsModalProps {
	events: {
		title: string;
		type: string;
		tags: Array<null | string>;
		start: Date;
		end: Date;
	};
	info?: any;
}

const ShowAllEventsModal: React.FC<
	ShowAllEventsModalProps
> = ({ events }) => {
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	const { toolTipPosition, eventModalView } = useSelector(
		(state: any) => state.user
	);

	return (
		<Popper
			placement="top"
			modifiers={[
				{
					name: 'offset',
					options: {
						offset: [0, 10],
					},
				},
			]}
			referenceElement={tooltipRef.current ?? undefined}
			strategy="fixed"
			//@ts-ignore
			eventsEnabled={eventModalView}
		>
			{({ ref, style }) => (
				<div
					ref={ref}
					style={{ ...style, zIndex: 10 }}
					className={`tooltip-container-calender`}
				>
					{eventModalView && (
						<div
							className={`tooltip-container-child  active`}
							style={{
								top: toolTipPosition.top,
								left: toolTipPosition.left,
							}}
						>
							<div className="event-details-main-content">
								{events.title ? (
									<div className="event-section">
										<div className="event-icon">
											<BsCalendar3Event />
										</div>
										<div className="event-details">
											<div className="label">
												Event Name
											</div>
											<div className="value">
												<span
													className={` icon icon-${events.type}`}
												>
													<GoDotFill />
												</span>
												<span className="text">
													{events.title}
												</span>
											</div>
										</div>
									</div>
								) : null}
								{events.tags &&
								events.tags.length > 0 &&
								!events.tags.includes(null) &&
								!events.tags.some(
									(tag: any) => tag === null
								) ? (
									<div className="tags-section">
										<div className="tags-icon">
											<IoPricetagsOutline />
										</div>
										<div className="tags-details">
											<div className="label">Tags</div>
											<div className="list">
												{events.tags.map(
													(tag: any, index: number) => (
														<span
															key={index}
															className={`chip-basic chip-${events.type}`}
															role="tag"
														>
															{tag}
														</span>
													)
												)}
											</div>
										</div>
									</div>
								) : null}

								{events.start && events.end ? (
									<div className="datetime-section">
										<div className="calendar-icon">
											<RiCalendarLine />
										</div>
										<div className="datetime-details">
											<div className="label">Date</div>
											<div className="value">
												{moment(events.start).format(
													'DD MMM YYYY'
												)}
											</div>
										</div>
										<div className="time-icon">
											<AiOutlineClockCircle />
										</div>
										<div className="time-details">
											<div className="label">Time</div>
											<div className="value">
												{moment(events.start).format(
													'hh:mm A'
												)}{' '}
												-{' '}
												{moment(events.end).format(
													'hh:mm A'
												)}
											</div>
										</div>
									</div>
								) : null}
							</div>
						</div>
					)}
				</div>
			)}
		</Popper>
	);
};

const TopbarWrapper: React.FC<ToolbarProps> = (props) => {
	const defaultDate = props.date;
	const newDateprev = new Date(
		defaultDate.getFullYear(),
		defaultDate.getMonth() - 1,
		1
	);
	const newDatenext = new Date(
		defaultDate.getFullYear(),
		defaultDate.getMonth() + 1,
		1
	);

	return (
		<div className="calender-toolbar">
			<span
				className="calender-toolbar-left"
				onClick={() =>
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					props.onNavigate('prev', newDateprev)
				}
			>
				<LiaAngleLeftSolid />
			</span>
			<h3 className="calender-toolbar-title">
				{props.label}
			</h3>
			<span
				className="calender-toolbar-right"
				onClick={() =>
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					props.onNavigate('next', newDatenext)
				}
			>
				<LiaAngleRightSolid />
			</span>
		</div>
	);
};

interface Event {
	id: string;
	title: string;
	type: string;
	start: Date;
	end: Date;
}

const Calender2View: React.FC = () => {
	const { user, eventModalView, selectedDayEvent } =
		useSelector((state: RootState) => state.user);

	const [calenderEvents, setCalenderEvents] = useState<
		Event[]
	>([]);

	const [selectedDayInfo, setSelectedDayInfo] =
		useState<View>('month');
	const [open, setOpen] = useState<boolean>(false);

	const [defaultDate, setDefaultDate] = useState<Date>(
		new Date()
	);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		const getEvents = async () => {
			if (user) {
				const userType =
					user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
				const res =
					await services.courseService.getCalenderEvents(
						user.id,
						userType
					);
				setCalenderEvents(res.events);
			}
		};
		getEvents();
	}, [user]);

	if (calenderEvents.length === 0) {
		return <Loader factVisibility={true} />;
	}

	return (
		<div className="calender-container">
			<div className="calender-container">
				<div className="upcoming-events-container">
					<p className="upcoming-events-heading">
						Upcoming Events
					</p>
					<div className="display-today">
						<p className="date">
							{moment().format('dddd, DD MMM YYYY')}
						</p>
						<span className="text">Today</span>
					</div>
					<div className="upcoming-events-wrapper">
						{calenderEvents.length > 0 && (
							<React.Fragment>
								{calenderEvents.filter((event) =>
									moment(event?.start).isSame(
										moment(),
										'day'
									)
								).length <= 0 ? (
									<React.Fragment>
										<Lottie
											loop
											animationData={noTaskLottie}
											play
										/>
										<p className="no-event">
											No Events Found!
										</p>
									</React.Fragment>
								) : (
									calenderEvents
										.filter((event) =>
											moment(event?.start).isSame(
												moment(),
												'day'
											)
										)
										.map((event) => (
											<div
												key={nanoid()}
												className={`event-agenda event-${event.type}`}
											>
												<p className="title">
													{event.title}
												</p>
												<div className="event-details">
													<span
														className={`chip-basic chip-${event.type}`}
													>
														{event.type}
													</span>
													<span className="time">
														{moment(event.start).format(
															'hh:mm A'
														)}{' '}
														-{' '}
														{moment(event.end).format(
															'hh:mm A'
														)}
													</span>
												</div>
												<AccioButton
													className="go-to-page-btn"
													title="Go to page"
													variant="outline"
													icon={<FiArrowUpRight />}
													action={() => {
														if (event.type === 'class')
															navigate('/modules');
														else if (
															event.type === 'contest'
														)
															navigate(`/contests`);
														else if (
															event.type === 'session'
														)
															navigate(`/session`);
														else navigate(`/`);
													}}
												/>
											</div>
										))
								)}
							</React.Fragment>
						)}
					</div>
				</div>
				<div
					className="calender"
					id="calender-container"
				>
					<div className="calender-header">Calender</div>
					<ShowAllEventsModal
						events={selectedDayEvent}
						info={selectedDayInfo}
					/>

					<Calendar
						components={{
							//@ts-ignore
							eventWrapper: EventWrapper,
							toolbar: TopbarWrapper,
						}}
						localizer={localizer}
						defaultDate={defaultDate}
						defaultView={'month'}
						// dayPropGetter={customDayPropGetter}
						events={calenderEvents}
						showAllEvents={true}
						popup={true}
						selectable={true}
						onSelectSlot={(info) => {
							console.log('first');
							// const { start, end } = info;
							// const eventsForThisDay = calenderEvents.filter(
							// 	(event: any) =>
							// 		new Date(event.start) >= new Date(start) &&
							// 		new Date(event.start) < new Date(end)
							// );
							// setOpen(true);
							// setSelectedDayEvents(eventsForThisDay);
							// setSelectedDayInfo(info);
						}}
						// messages
					/>
				</div>
			</div>
		</div>
	);
};

export default Calender2View;
