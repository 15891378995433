import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { FiMonitor } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionsOfUserForAllSessionTypes } from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import TabPanel from '../shared/TabPanel';
import SessionTabAdditionData from './SessionTabAdditionData';
import SessionTabBox from './SessionTabBox';

const AllSessionBox = () => {
	const dispatch = useDispatch<AppDispatch>();
	const sessionState = useSelector(
		(state: any) => state.session
	);
	const { allSessionData } = sessionState;

	const [tabTitle, setTabTitle] = useState<string[]>([]);
	const [additionalData, setAdditionalData] = useState<
		string[][]
	>([]);
	const [selectedFilter, setSelectedFilter] =
		useState<string>('ALL');

	console.log('allSessionData');

	useEffect(() => {
		if (
			allSessionData &&
			allSessionData?.sessionTypeObjects
		) {
			setTabTitle(
				Object.keys(allSessionData?.sessionTypeObjects)
			);
		} else {
			setTabTitle(['Loading..', 'Loading..']);
		}
	}, [allSessionData]);

	console.log('allSessionData new ', allSessionData);

	useEffect(() => {
		if (allSessionData && tabTitle?.length > 0) {
			const additionalDataArr = tabTitle?.map(
				(title: string) => {
					return allSessionData?.sessionTypeObjects[title]
						? Object.keys(
								allSessionData?.sessionTypeObjects[title]
						  )
						: [];
				}
			);
			setAdditionalData(additionalDataArr);
		}
	}, [tabTitle, allSessionData]);

	useEffect(() => {
		if (allSessionData === null) {
			dispatch(getSessionsOfUserForAllSessionTypes());
		}
	}, [allSessionData, dispatch]);

	console.log(
		'selectedFilter',
		allSessionData?.sessionTypeObjects
	);

	const tabData = tabTitle?.map(
		(title: string, index: number) => ({
			keyId: nanoid(),
			title,
			element: (
				<SessionTabBox
					tabData={
						allSessionData?.sessionTypeObjects[title]
					}
					index={index}
					selectedFilter={selectedFilter}
					totalSessionsLimit={
						allSessionData?.totalSessionsLimit
					}
					restrictUserSessions={
						allSessionData?.restrictUserSessions
					}
				/>
			),
			show: true,
			// additionalData: additionalData[index],
		})
	);

	return (
		<div className="all-session-box-wrapper">
			<div className="box-top-container">
				<p className="box-title">
					<span className="icon">
						<FiMonitor />
					</span>
					<span className="text"> All Sessions</span>
				</p>
				<p className="box-subtitle-session-info">
					Manage all your sessions here
				</p>
			</div>
			<div className="box-bottom-container">
				<TabPanel
					tabData={tabData}
					tabBoxClass="all-session-box-tab-outer"
					onTabChange={() => setSelectedFilter('ALL')}
					rightComponent={(index: number) => (
						<SessionTabAdditionData
							selectedFilter={selectedFilter}
							TabAdditionalData={additionalData[index]}
							setSelectedFilter={setSelectedFilter}
						/>
					)}
				/>
			</div>
		</div>
	);
};

export default AllSessionBox;
