import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';
import { ENDPOINTS } from '../config/endpoints/stage';
import { UserResponseInterface } from '../types/aiStudentAssessment.types';

export class AiStudentAssessment {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async startAssessment(assessmentId?: string) {
		try {
			const { data } = await this.httpService.post(
				`${this.endpoint}/student-assessment-test/start-student-assessment-test`,
				{
					data: {
						assessmentId,
					},
				}
			);
			return data;
		} catch (error) {
			return apiFailureErrorMessage;
		}
	}
	async getNextQuestion(assessmentId: string) {
		try {
			const { data } = await this.httpService.get(
				`${this.endpoint}/student-assessment-test/next-assessment-question?studentAssessmentTestId=${assessmentId}`
			);
			return data;
		} catch (error) {
			return apiFailureErrorMessage;
		}
	}

	async sendUserResponse(
		userResponse: UserResponseInterface
	) {
		try {
			const { data } = await this.httpService.post(
				`${this.endpoint}/student-assessment-test/submit-assessment-question`,
				{
					data: userResponse,
				}
			);
			return data;
		} catch (error) {
			return apiFailureErrorMessage;
		}
	}
}
