import React, { useState, useEffect } from 'react';
import { addDoubt } from '../../redux/course/courseSlice';
// import '../../styles/livedoubts.scss';
import { useSelector, useDispatch } from 'react-redux';
import {
	FiSearch,
	FiClock,
	FiArrowRight,
} from 'react-icons/fi';
import Lottie from 'react-lottie-player';
import meditation from '../../assets/lottie/meditation.json';
import onlinelearning from '../../assets/lottie/onlinelearning.json';
import ripple from '../../assets/lottie/ripple.json';
import inactive_ripple from '../../assets/lottie/alert.json';
import zoom from '../../assets/images/zoom-logo.svg';
import moment from 'moment';
import {
	fetchAllTagsList,
	fetchLiveDoubtsSlots,
} from '../../redux/user/userSlice';
import { engagements } from '../../engagements';
import PageHeading from '../../components/shared/PageHeading';
import { isMainCourseUser } from '../../utils/events';
import { encryptData } from '@acciojob/event-analytics';

const LiveDoubtDash = () => {
	const dispatch = useDispatch();

	const userState = useSelector((state: any) => state.user);

	const { topicTags, liveDoubtsSlots } = userState;

	const [active, setActive] = useState<boolean>(false);
	const [doubtZoomLink, setDoubtZoomLink] = useState('');
	const [doubtSlots, setDoubtSlots] = useState([]);

	const doubtMSG = [
		'Write your doubts in the form below and we will reach you back with all the solutions.',
		'Fill the below form and click on the button to join the Live Doubt Class',
	];

	const [componentArray, setComponentArray] = useState<
		number[]
	>([0, 0, 0]);
	const [topicSelect, setTopicSelect] = useState('');
	const [relatedSelect, setRelatedSelect] = useState('');
	const [desc, setDesc] = useState('');

	const [submitActive, setSubmitActive] = useState(false);

	const componentToggler = (num: number) => {
		const temp = [...componentArray];
		for (let i = 0; i < 10; i++)
			temp[i] = num === i && temp[i] !== 1 ? 1 : 0;
		console.log(temp);
		setComponentArray([...temp]);
	};

	useEffect(() => {
		if (liveDoubtsSlots.length === 0) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignorej
			dispatch(fetchLiveDoubtsSlots());
		} else {
			setActive(liveDoubtsSlots.isActive);
			setDoubtZoomLink(liveDoubtsSlots.doubtZoomLink);
			const temp = liveDoubtsSlots.timeSlots.map(
				(el: any) => {
					return {
						endTime:
							el.endDate !== '' &&
							moment(el.endDate)
								.add('1', 'minutes')
								.format('hh A'),
						startTime:
							el.startDate !== '' &&
							moment(el.startDate).format('hh A'),
						isActive: el.isActive,
					};
				}
			);
			setDoubtSlots(temp);
		}
	}, [liveDoubtsSlots]);

	useEffect(() => {
		if (topicTags.length === 0) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(fetchAllTagsList());
		}
	}, [topicTags]);

	useEffect(() => {
		let slot = 0;
		for (let i = 0; i < 3; i++) {
			slot = slot ^ componentArray[i];
		}
		if (active) {
			if (
				topicSelect !== '' &&
				topicSelect !== 'Select Doubt Topic' &&
				relatedSelect !== '' &&
				relatedSelect !== 'Type of Doubt' &&
				desc !== '' &&
				slot !== 0
			)
				setSubmitActive(true);
			else setSubmitActive(false);
		} else {
			if (
				topicSelect !== '' &&
				topicSelect !== 'Select Doubt Topic' &&
				relatedSelect !== '' &&
				relatedSelect !== 'Type of Doubt'
			)
				setSubmitActive(true);
			else setSubmitActive(false);
		}
	}, [topicSelect, relatedSelect, desc, componentArray]);

	const sendDoubtQuery = async () => {
		// const slotArray = ['11 AM-1 PM', '6 PM-8 PM', '11 PM-12 midnight'];
		let num = 0;
		for (let i = 0; i < 3; i++) {
			if (componentArray[i] === 1) {
				num = i;
				break;
			}
		}
		const body = {
			topic: topicSelect,
			related: relatedSelect.toUpperCase(),
			description: desc,
			slot: doubtSlots[num],
		};
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(addDoubt(body));
		alert(
			'Your response has been recorded!. Please come to the session at the selected time!'
		);
		engagements.courseEngagement.bookLiveDoubtSession(
			body.topic,
			body.slot
		);
	};
	const sendDoubtQueryWhileActive = async () => {
		if (topicSelect === '' || relatedSelect === '')
			return 0;
		const body = {
			topic: topicSelect,
			related: relatedSelect.toUpperCase(),
			description: '',
			slot: 'ACTIVE',
		};
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(addDoubt(body));
		return 1;
	};

	return (
		<div className={'live-doubt-dash'}>
			<div className={'live-doubt-content'}>
				<PageHeading pageName="Live Doubts" />
				{active ? (
					<div className={'live-doubt-active'}>
						<Lottie
							className="live-doubt-lottie"
							loop
							animationData={ripple}
							play
						/>
						<h3>
							<span>Active</span> right now ! Ask your
							doubts away
						</h3>
					</div>
				) : (
					<>
						<div className={'live-doubt-inactive'}>
							<div className="live-doubt-inactive-top">
								<Lottie
									className="live-doubt-lottie"
									loop
									animationData={inactive_ripple}
									play
								/>
								<h3>
									<span>Not available</span> at the moment.
								</h3>
							</div>
						</div>
						<div className={'live-doubt-slots-wrapper'}>
							<div className="doubt-slot-wrapper">
								<p className="doubt-slot-heading">
									Our slots are :
								</p>
								<div className="doubt-time-slots">
									{doubtSlots.map((el: any) => {
										return el.startTime && el.endTime ? (
											<div className="doubt-time-slot">
												<span className="icon">
													<FiClock />
												</span>
												<span className="time">
													{el.startTime} - {el.endTime}
												</span>
											</div>
										) : null;
									})}
								</div>
							</div>
						</div>
					</>
				)}
				<hr />
				<div className={'doubt-section'}>
					<p className={'doubt-section-msg'}>
						{active ? doubtMSG[1] : doubtMSG[0]}
					</p>
					<div className="dropdowns">
						<select
							className="topic first"
							value={topicSelect}
							onChange={(event) =>
								setTopicSelect(event.target.value)
							}
							event-analytics={
								isMainCourseUser()
									? '9dfa75f2-fd8f-4b5a-95a2-8e1482ee6f75'
									: ''
							}
							data-config={encryptData(
								JSON.stringify({
									name: topicSelect,
								})
							)}
						>
							<option>Select Doubt Topic</option>
							{topicTags.map((el: any) => (
								<option key={el.id}>{el.name}</option>
							))}
						</select>
						<select
							className="related second"
							value={relatedSelect}
							onChange={(event) =>
								setRelatedSelect(event.target.value)
							}
							event-analytics={
								isMainCourseUser()
									? '94d989bd-81fb-48e9-b722-6854d41375af'
									: ''
							}
							data-config={encryptData(
								JSON.stringify({
									name: relatedSelect,
								})
							)}
						>
							<option>Type of Doubt</option>
							<option>Concept related doubt</option>
							<option>Question related doubt</option>
						</select>
					</div>
					<div className={'doubt-state'}>
						{active ? (
							<div className={'zoom-section'}>
								<img
									src={zoom}
									alt={'zoom logo'}
									style={{
										filter: `grayscale(${active ? 0 : 1})`,
									}}
								/>
								<button
									className={`${
										submitActive ? 'active' : 'disable'
									}`}
									onClick={() => {
										if (active) {
											sendDoubtQueryWhileActive().then(
												(res) => {
													if (res)
														engagements.courseEngagement.joinLiveDoubtSession(
															topicSelect
														);
													return window.open(
														doubtZoomLink,
														'_blank'
													);
												}
											);
										} else {
											alert(
												'No live doubts sessions are active at this time, please try again later! '
											);
										}
									}}
								>
									Open Zoom Meeting
									<FiArrowRight />
								</button>
							</div>
						) : (
							<div className={'doubt-input'}>
								<div className={'textarea-box'}>
									<h3>YOUR DOUBT</h3>
									<textarea
										placeholder={'Explain your doubt here'}
										onChange={(event) =>
											setDesc(event.target.value)
										}
										value={desc}
									/>
								</div>
								<div>
									<h3>SELECT TIME SLOT</h3>
									<div className={'slot-section'}>
										{doubtSlots.map((el: any, index) => {
											return (
												<button
													key={index}
													className={`slot-section-btn ${
														componentArray[index] === 1
															? 'active'
															: ''
													}`}
													onClick={() => {
														componentToggler(index);
													}}
													event-analytics={
														isMainCourseUser()
															? 'f11b7b21-c02c-4e6b-99d9-01a3a8f80bea'
															: ''
													}
													data-config={encryptData(
														JSON.stringify({
															name: `${el.startTime} - ${el.endTime}`,
														})
													)}
												>
													{el.startTime} -{el.endTime}
												</button>
											);
										})}

										<button
											className={`${
												submitActive ? 'active' : 'disable'
											}`}
											onClick={() => sendDoubtQuery()}
											event-analytics={
												isMainCourseUser()
													? 'de50f3ff-5f78-41bd-b3e0-844fbe18aeb3'
													: ''
											}
										>
											Send Doubt <FiArrowRight />
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className={'animation'}>
				<div className="lottie-animation">
					<Lottie
						className="lottie-animation-doubt-session"
						loop
						animationData={
							active ? onlinelearning : meditation
						}
						play
					/>
				</div>
			</div>
		</div>
	);
};

export default LiveDoubtDash;
