import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export const fetchAllProjects = createAsyncThunk(
	'projects/fetchAllProjects',
	async (arg, { getState }) => {
		const data =
			await services.courseService.getAllProjects();
		console.log(data, 'projects call data');
		return data;
	}
);

export const captureLike = createAsyncThunk(
	'question/captureLike',
	async ({
		projectId,
		likeStatus,
		previousLikeStatus,
	}: {
		projectId: string;
		likeStatus: ProjectReactionType;
		previousLikeStatus: ProjectReactionType;
	}) => {
		if (previousLikeStatus !== likeStatus) {
			return await services.courseService.captureLike({
				projectId,
				likeStatus,
			});
		}
	}
);

export type ProjectReactionType = 'LIKE' | 'DISLIKE' | null;

interface ProjectsState {
	// filters
	projects: any;
	projectsLoading: boolean;
}

const initialState: ProjectsState = {
	// filters
	projects: null,
	projectsLoading: true,
};

export const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		// set difficulty
		setProjects: (state, action: PayloadAction<any>) => {
			state.projects = action.payload;
			console.log('set prpjects hit', action.payload);
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllProjects.pending]: (
			state: ProjectsState,
			action: PayloadAction<any>
		) => {
			state.projectsLoading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllProjects.fulfilled]: (
			state: ProjectsState,
			action: PayloadAction<any>
		) => {
			state.projectsLoading = false;
			state.projects = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllProjects.rejected]: (
			state: ProjectsState,
			action: PayloadAction<any>
		) => {
			state.projectsLoading = false;
			console.log('error in projects');
		},
	},
});

//Exporting Actions
export const { setProjects } = projectsSlice.actions;
export default projectsSlice.reducer;
