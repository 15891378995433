import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { services } from '../../apis';
import {
	BiLock,
	BiCheckCircle,
	BiCircle,
	BiLockOpen,
} from 'react-icons/bi';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import {
	FiArrowLeft,
	FiArrowRight,
	FiThumbsDown,
	FiThumbsUp,
} from 'react-icons/fi';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { RxCross2 } from 'react-icons/rx';
import Portal from '../../components/portal';
import { AiOutlineClose } from 'react-icons/ai';
import moment from 'moment';
import {
	notifyError,
	notifySuccess,
} from '../../components/notification';
import RenderMarkDown from '../../components/shared/RenderMarkdown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { encryptData } from '@acciojob/event-analytics';
import { useAppDispatch } from '../../redux/store';
import {
	captureLike,
	ProjectReactionType,
} from '../../redux/projects/projectSlice';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: '500px',
	maxHeight: '90vh',
	overflow: 'auto',
	bgcolor: 'background.paper',
	border: '1px solid transparent',
	boxShadow: 24,
	p: 2,
	borderRadius: '8px',
};

const ProjectFeedback = (props: {
	text: string;
	reaction: ProjectReactionType;
	onReaction: (like: boolean) => void;
}) => {
	const { text, reaction, onReaction } = props;
	return (
		<div className="like-row">
			<div className="like-text">{text}</div>
			<div className="like-buttons">
				<div
					onClick={async () => {
						onReaction(true);
					}}
				>
					{reaction === 'LIKE' ? (
						<FiThumbsUp
							fill="#2F80ED"
							color="#2F80ED"
							size={18}
							cursor="pointer"
						/>
					) : (
						<FiThumbsUp
							size={18}
							className="grey-icon"
							cursor="pointer"
						/>
					)}
				</div>
				<div
					onClick={() => {
						onReaction(false);
					}}
				>
					{reaction === 'DISLIKE' ? (
						<FiThumbsDown
							fill="rgb(239, 68, 68)"
							color="rgb(239, 68, 68)"
							size={18}
							cursor="pointer"
						/>
					) : (
						<FiThumbsDown
							size={18}
							className="grey-icon"
							cursor="pointer"
						/>
					)}
				</div>
			</div>
		</div>
	);
};

const StartProject = () => {
	const location = useLocation();
	const projectId: Array<string | null> | null | string =
		queryString.parse(location.search).project;

	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState(true);
	const [projectSteps, setProjectSteps] =
		useState<any>(null);
	const [activeStep, setActiveStep] = useState<any>(1);
	const [projectName, setProjectName] =
		useState<string>('');
	const [userLastStep, setUserLastIndex] = useState(-1);
	const [stepMarkDown, setStepMarkdown] =
		useState<string>('');
	const [submissionUnlocked, setSubmissionUnlocked] =
		useState(false);
	const [submitAgain, setSubmitAgain] = useState(false);
	const [showSubmissionModal, setShowSubmissionModal] =
		useState(false);
	const [deployedLink, setDeployedLink] = useState('');
	const [githubLink, setGithubLink] = useState('');
	const [overWrite, setOverWrite] =
		useState<boolean>(false);
	const [showOverWriteOption, setShowOverWriteOption] =
		useState<boolean>(false);

	const [disableSubmit, setDisableSubmit] = useState(false);

	const [open, setOpen] = React.useState(false);
	const [reaction, setReaction] =
		useState<ProjectReactionType>(null);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const getProjectSteps = async (projectId: string) => {
		setLoading(true);
		console.log(projectId, 'projId');
		const steps =
			await services.courseService.getProjectSteps(
				projectId
			);
		setProjectSteps(steps);
		setUserLastIndex(
			steps.usersLatestCompletedStepIndex === -1
				? 0
				: steps.usersLatestCompletedStepIndex
		);

		if (!steps.steps[steps.steps.length - 1].isCompleted) {
			console.log(
				'last elem',
				steps,
				steps.steps.slice(-1)
			);
			setActiveStep(
				steps.usersLatestCompletedStepIndex === -1
					? steps.steps[0]
					: steps.steps[steps.usersLatestCompletedStepIndex]
			);
		} else {
			setActiveStep(steps.steps[steps.steps.length - 1]);
		}

		// setSubmitAgain(true)
		setSubmitAgain(
			steps.steps[steps.steps.length - 1]
				.userFinalSubmissionsForthisStep !== undefined
		);

		setStepMarkdown(
			steps.usersLatestCompletedStepIndex === -1
				? steps.steps[0].content
				: steps.usersLatestCompletedStepIndex ===
				  steps.totalSteps
				? steps.steps[
						steps.usersLatestCompletedStepIndex - 1
				  ].content
				: steps.steps[steps.usersLatestCompletedStepIndex]
						.content
		);
		console.log(steps, 'steps');
		setReaction(steps.reaction);
		setLoading(false);
		return steps;
	};

	const updateStep = (step: any) => {
		if (userLastStep + 1 >= step.index) {
			console.log('update to', step);
			setStepMarkdown(step.content);
			setActiveStep(step);
			setSubmitAgain(
				step.userFinalSubmissionsForthisStep !== undefined
			);
		}
	};

	const markAsDone = async () => {
		await services.courseService
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			.markStepComplete(projectId, activeStep.id)
			.then(() => {
				setUserLastIndex(userLastStep + 1);
				if (
					activeStep.index === projectSteps.totalSteps &&
					!activeStep.isSubmissionRequired
				) {
					setShowNextBtn(false);
				} else if (
					projectSteps.steps.length >
					userLastStep + 1
				) {
					const tempPro = { ...projectSteps };
					tempPro.steps[userLastStep].isCompleted = true;
					tempPro.usersLatestCompletedStepIndex =
						tempPro.usersLatestCompletedStepIndex + 1;
					const temp = tempPro.steps[userLastStep + 1];
					setStepMarkdown(temp.content);
					setActiveStep(temp);
					setSubmitAgain(
						temp.userFinalSubmissionsForthisStep !==
							undefined
					);
					setProjectSteps(tempPro);
					console.log('tempPro', tempPro, temp);
					if (
						tempPro.steps[tempPro.steps.length - 1]
							.isCompleted
					) {
						setSubmissionUnlocked(true);
					}
				} else {
					goToNext(activeStep.index);
				}
			});
	};

	const goToNext = (num: number) => {
		console.log('next', num);
		if (projectSteps.steps.length > num)
			updateStep(projectSteps.steps[num]);
	};
	const goToPrev = (num: number) => {
		console.log('prev', num);
		updateStep(projectSteps.steps[num - 2]);
	};

	const loadProjectSteps = () => {
		if (
			projectId !== undefined &&
			typeof projectId === 'string'
		)
			getProjectSteps(projectId)
				.then((el: any) => {
					const name =
						el.projectName !== undefined
							? el.projectName
							: 'Project';
					setProjectName(name);
					if (el.steps[el.steps.length - 1].isCompleted) {
						setSubmissionUnlocked(true);
					}
				})
				.catch((error) => console.log(error));
	};

	useEffect(() => {
		if (
			projectSteps === null &&
			projectId !== undefined &&
			typeof projectId === 'string'
		) {
			loadProjectSteps();
		}
	}, [projectId]);

	const submitProject = async () => {
		setShowSubmissionModal(false);
		setDisableSubmit(true);
		try {
			if (
				githubLink.trim() === '' ||
				deployedLink.trim() === ''
			) {
				notifyError('Please fill all the fields');
				return;
			}
			if (showOverWriteOption && !overWrite) {
				notifyError(
					'Please select override to submit your submission'
				);
				return;
			}
			const body = {
				overWritten: overWrite,
				projectId: projectId,
				stepId: activeStep.id,
				githubLink: githubLink.trim(),
				deployedLink: deployedLink.trim(),
			};
			const res =
				await services.courseService.submitProject(body);
			console.log(res);
			notifySuccess('Your Submission has been registered');
			setGithubLink('');
			setDeployedLink('');
			setOverWrite(false);
			markAsDone();
			loadProjectSteps();
			setDisableSubmit(false);
		} catch (e) {
			console.log('error', e);
		}
	};
	const [feedBackLIstItem, setFeedBackLIstItem] = useState<
		any[]
	>([]);
	useEffect(() => {
		if (
			activeStep.userFinalSubmissionsForthisStep !==
				undefined &&
			activeStep.userFinalSubmissionsForthisStep.length > 0
		) {
			const feedbackKeys = Object.keys(
				activeStep.userFinalSubmissionsForthisStep
			).filter(
				(key: any) =>
					activeStep.userFinalSubmissionsForthisStep[key]
						.isShown
			);
			setFeedBackLIstItem([...feedbackKeys]);
			console.log('feedBackLIstItem', feedBackLIstItem);
		}
	}, [activeStep]);

	const [showNextBtn, setShowNextBtn] = useState(true);
	const [showBlackList, setShowBlackList] = useState(false);

	const [submissionAllowed, setSubmissionAllowed] =
		useState(false);

	useEffect(() => {
		if (projectSteps !== null) {
			if (
				projectSteps.totalSteps === activeStep.index &&
				!activeStep.isSubmissionRequired
			) {
				setShowNextBtn(!activeStep.isCompleted);
			} else setShowNextBtn(true);

			if (
				activeStep.isSubmissionRequired &&
				activeStep.startTime &&
				activeStep.endTime
			) {
				const curr = moment();
				const start = moment(activeStep.startTime);
				const end = moment(activeStep.endTime);
				const check = moment(curr).isBetween(start, end);
				console.log('time', start, end, check);
				if (activeStep.hidden) {
					setSubmissionAllowed(check);
				} else setSubmissionAllowed(true);
			} else if (
				activeStep.isSubmissionRequired &&
				!activeStep.hidden
			) {
				setSubmissionAllowed(true);
			}

			if (
				projectSteps.blackListed &&
				activeStep.isSubmissionRequired
			) {
				setShowBlackList(true);
			} else setShowBlackList(false);

			if (
				activeStep.userFinalSubmissionsForthisStep !==
					undefined &&
				activeStep.userFinalSubmissionsForthisStep.length >
					0
			) {
				if (
					activeStep.userFinalSubmissionsForthisStep[0]
						.score === 'Under Evaluation'
				) {
					setShowOverWriteOption(true);
				} else setShowOverWriteOption(false);
			}
		}
	}, [activeStep]);

	const onReaction = (like: boolean) => {
		if (typeof projectId === 'string') {
			setReaction(like ? 'LIKE' : 'DISLIKE');
			void dispatch(
				captureLike({
					projectId: projectId,
					likeStatus: like ? 'LIKE' : 'DISLIKE',
					previousLikeStatus: reaction,
				})
			);
		}
	};

	return (
		<div className={'start-project'}>
			<div className={'head-bar'}>
				<h2>{projectName}</h2>
				<ProjectFeedback
					text={'Do you like this project ?'}
					reaction={reaction}
					onReaction={onReaction}
				/>
				{/*<button>Book a Session with Mentor</button>*/}
			</div>
			{showSubmissionModal ? (
				<div className={'portal-overlay'}>
					<div className={'submission-modal'}>
						<header className={'header-submission'}>
							<h3>Submit Project</h3>
							<AiOutlineClose
								onClick={() => {
									setShowSubmissionModal(false);
								}}
							/>
						</header>
						<div className={'submission-form'}>
							<div className={'modal-banner'}>
								{showOverWriteOption ? (
									<>
										<div className={'heading'}>
											<input
												type={'checkbox'}
												checked={overWrite}
												onChange={(e: any) => {
													console.log('check', e);
													setOverWrite(e.target.checked);
												}}
											/>
											<span>
												Yes, I want to override my
												submission
											</span>
										</div>
										<p>
											Your current submission will be used
											as the latest project and ongoing
											evaluation for the last submitted
											project will be stopped.
										</p>
									</>
								) : (
									<>
										<div className={'heading'}>
											<span>
												You are submitting another project!
											</span>
										</div>
										<p>
											We will evaluate your project and will
											update the score on the list. Check
											your score after some days. All the
											best!
										</p>
									</>
								)}
							</div>
							<div className={'form-fields'}>
								<p>Enter the Github repository link:</p>
								<span>
									(E.g.
									https://github.com/your_username/repo_name)
								</span>
								<input
									type={'text'}
									value={githubLink}
									onChange={(e: any) =>
										setGithubLink(e.target.value)
									}
								/>

								<p>
									Enter the deployed link for your project:
								</p>
								<input
									type={'text'}
									value={deployedLink}
									onChange={(e: any) =>
										setDeployedLink(e.target.value)
									}
								/>
							</div>
							<button onClick={submitProject}>
								Submit Project <FiArrowRight />
							</button>
						</div>
					</div>
				</div>
			) : null}

			{projectSteps !== null && !loading ? (
				<div className={'main-block'}>
					<div className={'steps-container'}>
						<h1>Steps to complete </h1>
						<ul>
							{activeStep !== null
								? projectSteps.steps.map((step: any) => (
										<li
											onClick={() => {
												updateStep(step);
											}}
											key={step.id}
											className={`${
												activeStep.index === step.index
													? 'active'
													: ''
											} ${
												step.isCompleted ||
												userLastStep + 1 >= step.index
													? 'step-not-disabled'
													: 'step-disabled'
											}`}
											event-analytics={
												'cb4bad8c-1b37-4e85-af15-b5d705e478c5'
											}
											data-config={encryptData(
												JSON.stringify({
													name: `${projectId} + ${activeStep.id}`,
												})
											)}
										>
											<span>
												{step.isCompleted ? (
													<BiCheckCircle />
												) : activeStep.index ===
												  step.index ? (
													<BiCircle />
												) : (
													<BiLock />
												)}
											</span>
											<p>{step.name}</p>
										</li>
								  ))
								: null}
						</ul>
					</div>
					{activeStep !== null ? (
						<div className={'steps-view-container'}>
							<div className={'view-head'}>
								<div>
									<p className={'step-count'}>
										STEP {activeStep.index}/
										{projectSteps.totalSteps}
									</p>
									<h3 className={'step-name'}>
										{activeStep.name}
									</h3>
								</div>
								{activeStep.isCompleted ? (
									<div className={'completed-status'}>
										<BiCheckCircle />
										Completed
									</div>
								) : null}
							</div>

							<div className={'markdown-container'}>
								{showBlackList ? (
									<div className={'blacklisted'}>
										<p>You are Blacklist</p>
									</div>
								) : (
									<>
										{activeStep.userFinalSubmissionsForthisStep !==
											undefined &&
										activeStep
											.userFinalSubmissionsForthisStep
											.length > 0 &&
										submitAgain ? (
											<>
												<div className={'submission-table'}>
													<table className="project-table">
														<tr>
															<th>Submission</th>
															<th>Github Link</th>
															<th>Deployed Link</th>
															<th>Score</th>
															<th>View Feedback</th>
														</tr>
														{activeStep.userFinalSubmissionsForthisStep.map(
															(submissionData: any) =>
																submissionData.overWritten ? null : (
																	<tr
																		key={submissionData.id}
																	>
																		<td className={'date'}>
																			{moment(
																				submissionData.createdAt
																			).format(
																				'DD MMM YYYY'
																			)}
																		</td>
																		<td className={'link'}>
																			<a
																				className="project-link-opener"
																				href={
																					submissionData.githubLink
																				}
																				target="_blank"
																			>
																				Click here
																			</a>
																		</td>
																		<td className={'link'}>
																			<a
																				className="project-link-opener"
																				href={
																					submissionData.deployedLink
																				}
																				target="_blank"
																			>
																				Click here
																			</a>
																		</td>
																		<td
																			className={'score'}
																			style={{
																				color: `${
																					submissionData.score ===
																					'Under Evaluation'
																						? 'rgba(80, 88, 98, 0.4)'
																						: '#505862'
																				}`,
																			}}
																		>
																			<span
																				style={{
																					width: '100%',
																					textAlign:
																						'center',
																				}}
																			>
																				{
																					submissionData.score
																				}
																			</span>
																		</td>
																		<td
																			className={'feedback'}
																		>
																			{!Array.isArray(
																				submissionData.mentorFeedback
																			) ? (
																				<>
																					<button
																						className="project-feedback-open-modal"
																						onClick={() =>
																							handleOpen()
																						}
																					>
																						View Feedback{' '}
																						<BsFillArrowRightCircleFill />
																					</button>
																					<Modal
																						open={open}
																						onClose={
																							handleClose
																						}
																						aria-labelledby="parent-modal-title"
																						aria-describedby="parent-modal-description"
																					>
																						<Box
																							sx={{
																								...style,
																							}}
																						>
																							<div className="feedback-modal-crossbtn">
																								<span
																									onClick={() =>
																										handleClose()
																									}
																								>
																									<RxCross2 />
																								</span>
																							</div>
																							{submissionData
																								.mentorFeedback[
																								'Subjective Feedback for the student'
																							] ? (
																								<div className="feedback-modal-top-feedback">
																									<div className="feedback-modal-top-feedback-left">
																										<p className="feedback-modal-top-feedback-question">
																											Feedback
																											from
																											the
																											mentor
																										</p>
																										<p className="feedback-modal-top-feedback-title">
																											{
																												submissionData
																													.mentorFeedback[
																													'Subjective Feedback for the student'
																												]
																													.answer
																											}
																										</p>
																									</div>
																									<div className="feedback-modal-top-feedback-right">
																										<span className="feedback-score-span">
																											Total
																											score
																										</span>

																										<span className="feedback-score-data-span">{`${submissionData.score}/100`}</span>
																									</div>
																								</div>
																							) : null}
																							<div
																								className={
																									'feedback-modal-bottom-feedback'
																								}
																							>
																								{Object.entries(
																									submissionData.mentorFeedback
																								).map(
																									([
																										key,
																										value,
																									]: any) => {
																										if (
																											value.isShown
																										) {
																											return (
																												<ul className="project-modal-feedback-holder">
																													{value
																														.answer
																														.length >
																													1 ? (
																														<li className="project-modal-feedback-holder-question">
																															{
																																key
																															}
																														</li>
																													) : null}
																													{value
																														.answer
																														.length >
																													1 ? (
																														<li className="project-modal-feedback-holder-answer">
																															{
																																value.answer
																															}
																														</li>
																													) : null}
																												</ul>
																											);
																										}
																									}
																								)}
																							</div>
																						</Box>
																					</Modal>
																				</>
																			) : (
																				<p>" "</p>
																			)}
																		</td>
																	</tr>
																)
														)}
													</table>
												</div>
											</>
										) : null}

										<RenderMarkDown>
											{stepMarkDown}
										</RenderMarkDown>

										{activeStep.isSubmissionRequired &&
										!submitAgain ? (
											<div className={'form-fields'}>
												<p>
													Enter the Github repository link:
												</p>
												<span>
													(E.g.
													https://github.com/your_username/repo_name)
												</span>
												<input
													type={'text'}
													value={githubLink}
													onChange={(e: any) =>
														setGithubLink(e.target.value)
													}
												/>

												<p>
													Enter the deployed link for your
													project:
												</p>
												<input
													type={'text'}
													value={deployedLink}
													onChange={(e: any) =>
														setDeployedLink(e.target.value)
													}
												/>
											</div>
										) : null}
									</>
								)}
							</div>
							<div
								className={`view-bottom-btns ${
									activeStep.index > 1
										? 'goback-disabled'
										: ''
								}`}
							>
								<button
									onClick={() => {
										if (activeStep.index > 1)
											goToPrev(activeStep.index);
									}}
									disabled={activeStep.index === 1}
									event-anakytics={
										'9c06740a-809d-4d5f-9549-bd0275be01b8'
									}
									data-config={encryptData(
										JSON.stringify({
											name: `${projectId} + ${activeStep.id}`,
										})
									)}
								>
									<FiArrowLeft />
									Go to Previous Step
								</button>
								{showNextBtn && !showBlackList ? (
									<button
										disabled={disableSubmit}
										onClick={() => {
											if (
												activeStep.isSubmissionRequired &&
												submissionAllowed
											) {
												if (submitAgain) {
													setShowSubmissionModal(true);
												} else {
													submitProject();
												}
											} else {
												if (
													userLastStep + 1 ==
													activeStep.index
												) {
													markAsDone();
												} else {
													goToNext(activeStep.index);
												}
											}
										}}
									>
										{!activeStep.isSubmissionRequired ? (
											<div
												event-analytics={
													userLastStep + 1 ==
													activeStep.index
														? 'e2583ee0-b224-42d1-83fa-7213b868af4c'
														: '1ded30ab-e604-410e-b337-39bda078b383'
												}
												data-config={encryptData(
													JSON.stringify({
														name: `${projectId} + ${activeStep.id}`,
													})
												)}
											>
												{userLastStep + 1 ==
												activeStep.index
													? 'Mark as Done'
													: 'Go to Next'}
												<FiArrowRight />
											</div>
										) : submissionAllowed ? (
											<>Submit Project</>
										) : (
											<div
												event-analytics={
													userLastStep + 1 ==
													activeStep.index
														? 'e2583ee0-b224-42d1-83fa-7213b868af4c'
														: '1ded30ab-e604-410e-b337-39bda078b383'
												}
												data-config={encryptData(
													JSON.stringify({
														name: `${projectId} + ${activeStep.id}`,
													})
												)}
											>
												{userLastStep + 1 ==
												activeStep.index
													? 'Mark as Done'
													: 'Go to Next'}
												<FiArrowRight />
											</div>
										)}
									</button>
								) : null}
							</div>
						</div>
					) : null}
				</div>
			) : (
				<Loader />
			)}
		</div>
	);
};

export default StartProject;
