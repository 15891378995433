import { Box } from '@mui/material';
import { type FC } from 'react';
import { BiBoltCircle, BiCode } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { fetchRandomQuestions } from '../../redux/gym/thunks/gym.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import { RightComponentPropsType } from '../shared/Tabs';

export const CodingQuestionHeader: FC<
	RightComponentPropsType
> = (props) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { latestCourseID } = useAppSelector(
		(state: any) => state.user
	);
	const onRandomQuestionClick = async () => {
		const questionId = (
			await dispatch(
				fetchRandomQuestions({
					courseId: latestCourseID as string,
					selectedTabId: props.tabData?.key as string,
				})
			)
		).payload;

		switch (props.tabData?.key) {
			case 'coding': {
				window.open(
					`${window.origin}/idle?question=${questionId}`,
					'_blank'
				);
				return;
			}
			case 'webdev': {
				window.open(
					`${window.origin}/web-idle?question=${questionId}`,
					'_blank'
				);
				return;
			}
			case 'conceptual': {
				window.open(
					`${window.origin}/gym-aptitude?questionId=${questionId}`,
					'_blank'
				);
			}
		}
	};

	const onOpenEditorClick = () => {
		navigate('/open-editor');
	};

	const shouldShowOpenEditorButton =
		props.tabData?.key === 'coding';

	return (
		<Box className="coding-question-header-container">
			<p>
				Quick Link{shouldShowOpenEditorButton ? 's' : ''}:
			</p>
			<Box
				className="link-button"
				onClick={onRandomQuestionClick}
			>
				<BiBoltCircle className="bolt" />
				<span>Pick a random question</span>
			</Box>
			{shouldShowOpenEditorButton ? (
				<Box
					className="link-button"
					onClick={onOpenEditorClick}
				>
					<BiCode className="code" />
					<span>Open Editor</span>
				</Box>
			) : null}
		</Box>
	);
};
