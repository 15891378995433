import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

interface AssignmentState {
	requestId: string | null;
	requestIdLoader: boolean;
	iframeLoader: boolean;
	iframeData: null | any;
	iframeSrc: string | null;
	attemptId: string | null;
	attemptIdLoader: boolean;
	testCaseData: null | any;
	testCaseLoader: boolean;
	attemptMessage: string | null;
	attemptStatus: boolean;
	questionData: null | any;
	loader: boolean;
}

const initialState: AssignmentState = {
	requestId: null,
	requestIdLoader: false,
	iframeLoader: false,
	iframeData: null,
	iframeSrc: null,
	attemptId: null,
	attemptIdLoader: false,
	testCaseData: null,
	testCaseLoader: false,
	attemptMessage: null,
	attemptStatus: false,
	questionData: null,
	loader: false,
};

export const fetchJupyterNoteBookQuestionRequestId =
	createAsyncThunk(
		'jupyterNoteBook/fetchJupyterNoteBookQuestionRequestId',
		async (questionId: string) => {
			const data =
				await services.jupyterNoteBookService.getJupyterNoteBookRequestId(
					questionId
				);

			return data;
		}
	);

export const fetchJupyterNoteBookQuestionIframe =
	createAsyncThunk(
		'jupyterNoteBook/fetchJupyterNoteBookQuestionIframe',
		async (requestId: string) => {
			let data = null;
			const timeStart = Date.now();
			const after5Min = timeStart + 300000;
			while (!data && Date.now() < after5Min) {
				await new Promise((resolve) =>
					setTimeout(resolve, 5000)
				);
				data =
					await services.jupyterNoteBookService.getJupyterNoteBookQuestionIframe(
						requestId
					);
			}
			return data;
		}
	);

export const fetchJupyterNoteBookAttemptId =
	createAsyncThunk(
		'jupyterNoteBook/fetchJupyterNoteBookAttemptId',
		async (questionId: string) => {
			const data =
				await services.jupyterNoteBookService.getJupyterNoteBookAttemptId(
					questionId
				);

			return data;
		}
	);

export const fetchJupyterNoteBookQuestionData =
	createAsyncThunk(
		'jupyterNoteBook/fetchJupyterNoteBookQuestionData',
		async (questionId: string) => {
			const data =
				await services.jupyterNoteBookService.getJupyterNoteBookQuestionData(
					questionId
				);

			return data;
		}
	);

export const fetchJupyterNoteBookQuestionResultData =
	createAsyncThunk(
		'jupyterNoteBook/fetchJupyterNoteBookQuestionResultData',
		async ({
			attemptId,
			questionId,
		}: {
			attemptId: string;
			questionId: string;
		}) => {
			let data = null;
			const timeStart = Date.now();
			let isAPICalled = false;
			const after5Min = timeStart + 300000;
			while (
				!(
					isAPICalled &&
					data.attemptEvaluationStatus &&
					data.attemptEvaluationStatus !== 'EVALUATING'
				) &&
				Date.now() < after5Min
			) {
				await new Promise((resolve) =>
					setTimeout(resolve, 5000)
				);
				data =
					await services.jupyterNoteBookService.getJupyterNoteBookQuestionResultData(
						attemptId,
						questionId
					);

				isAPICalled = true;
			}
			return data;
		}
	);

// export const fetchJupyterNoteBookQuestionResultData =
// 	createAsyncThunk(
// 		'jupyterNoteBook/fetchJupyterNoteBookQuestionResultData',
// 		async ({
// 			attemptId,
// 			questionId,
// 		}: {
// 			attemptId: string;
// 			questionId: string;
// 		}) => {
// 			let data = null;
// 			const timeStart = Date.now();
// 			const after5Min = timeStart + 300000;

// 			if (data === null) {
// 				while (Date.now() < after5Min) {
// 					data =
// 						await services.jupyterNoteBookService.getJupyterNoteBookQuestionResultData(
// 							attemptId,
// 							questionId
// 						);

// 					if (data !== null && typeof data !== 'string') {
// 						return data;
// 					}

// 					await new Promise((resolve) =>
// 						setTimeout(resolve, 5000)
// 					);
// 				}

// 				return null; // If conditions are not met within 5 minutes
// 			}
// 		}
// 	);

export const jupyterNoteBookSlice = createSlice({
	name: 'jupyterNoteBook',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchJupyterNoteBookQuestionRequestId.pending as any]:
			(
				state: AssignmentState,
				action: PayloadAction<any>
			) => {
				state.requestIdLoader = true;
				state.questionData = null;
			},

		[fetchJupyterNoteBookQuestionRequestId.fulfilled as any]:
			(
				state: AssignmentState,
				action: PayloadAction<any>
			) => {
				state.requestId = action.payload.requestId;
				state.requestIdLoader = false;
			},

		[fetchJupyterNoteBookQuestionRequestId.rejected as any]:
			(
				state: AssignmentState,
				action: PayloadAction<any>
			) => {
				state.requestIdLoader = false;
			},

		[fetchJupyterNoteBookQuestionIframe.pending as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.iframeLoader = true;
			state.iframeData = null;
		},

		[fetchJupyterNoteBookQuestionIframe.fulfilled as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.iframeData = action.payload;
			state.iframeLoader = false;
		},

		[fetchJupyterNoteBookQuestionIframe.rejected as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.iframeLoader = false;
		},

		[fetchJupyterNoteBookAttemptId.pending as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.attemptIdLoader = true;
			state.attemptId = null;
		},

		[fetchJupyterNoteBookAttemptId.fulfilled as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			console.log('action.attemptId', action.payload);
			state.attemptId = action.payload.attemptId ?? null;
			state.attemptMessage = action.payload.message;
			state.attemptStatus = action.payload.status;
			state.attemptIdLoader = false;
		},

		[fetchJupyterNoteBookAttemptId.rejected as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.attemptIdLoader = false;
		},

		[fetchJupyterNoteBookQuestionResultData.pending as any]:
			(
				state: AssignmentState,
				action: PayloadAction<any>
			) => {
				state.testCaseLoader = true;
				state.testCaseData = null;
			},

		[fetchJupyterNoteBookQuestionResultData.fulfilled as any]:
			(
				state: AssignmentState,
				action: PayloadAction<any>
			) => {
				state.testCaseData = action.payload;
				state.testCaseLoader = false;
			},

		[fetchJupyterNoteBookQuestionResultData.rejected as any]:
			(
				state: AssignmentState,
				action: PayloadAction<any>
			) => {
				state.testCaseLoader = false;
			},

		[fetchJupyterNoteBookQuestionData.pending as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loader = true;
			state.questionData = null;
		},

		[fetchJupyterNoteBookQuestionData.fulfilled as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.questionData = action.payload;
			state.loader = false;
		},

		[fetchJupyterNoteBookQuestionData.rejected as any]: (
			state: AssignmentState,
			action: PayloadAction<any>
		) => {
			state.loader = false;
		},
	},
});

//Exporting Actions
export const {} = jupyterNoteBookSlice.actions;
export default jupyterNoteBookSlice.reducer;
