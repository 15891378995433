import { Loader } from '@acciojob/loader';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMockScoreCards } from '../../redux/jobportal/jobPortalSlice';
import { AppDispatch, RootState } from '../../redux/store';
import JobCard from './JobCard';

const JobsForYouWrapper = ({
	placementFlag,
	setJobDetailsPopup,
	setApplyPopup,
	setBlackListModalOpen,
}: any) => {
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);

	const { mockScoreCardsState } = jobPortalState;

	const dispatch = useDispatch<AppDispatch>();

	useEffect(() => {
		if (
			mockScoreCardsState.mockScoreCards &&
			mockScoreCardsState.mockScoreCards.length === 0
		) {
			dispatch(fetchMockScoreCards());
		}
	}, []);

	return (
		<div className="job-for-you-wrapper">
			{mockScoreCardsState.loading === true ? (
				<div className="loader-container">
					<Loader
						factVisibility={true}
						width={'100%'}
						height={'100%'}
					/>
				</div>
			) : mockScoreCardsState.mockScoreCards &&
			  mockScoreCardsState.mockScoreCards.length !== 0 ? (
				<div className="job-card-wrapper view-scroll-bar">
					{mockScoreCardsState.mockScoreCards.map(
						(card: any, idx: number) => (
							<JobCard
								placementFlag={placementFlag}
								key={idx}
								data={card}
								setJobDetailsPopup={setJobDetailsPopup}
								setApplyPopup={setApplyPopup}
								setBlackListModalOpen={
									setBlackListModalOpen
								}
							/>
						)
					)}
				</div>
			) : (
				<p>
					No new jobs are available, please try again later
				</p>
			)}
		</div>
	);
};

export default JobsForYouWrapper;
