import StarIcon from '@mui/icons-material/Star';
import { Button, Checkbox, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Rating from '@mui/material/Rating';
import React, { useEffect, useState } from 'react';
// import { setQuestionSource } from '../../redux/gym/gymSlice';
import { useSelector } from 'react-redux';
import { services } from '../../apis/index';
import { notifyError } from '../notification';
export enum QuestionType {
	SUBJECTIVE = 'SUBJECTIVE',
	OBJECTIVE = 'OBJECTIVE',
	OTHER = 'OTHER',
}
export const questionsWhenOverallRatingis1 = [
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Fast Paced',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Not able to understand Concepts',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Topic was difficult',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Not able to understand Problems',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Doubts not cleared',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Instructor delivery was not good',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.SUBJECTIVE,
			text: 'Detailed Feedback',
		},
		required: true,
		answer: '',
	},
];
export const questionsWhenOverallRatingis2 = [
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Fast Paced',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Not able to understand Concepts',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Topic was difficult',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Not able to understand Problems',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Doubts not cleared',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Instructor delivery was not good',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.SUBJECTIVE,
			text: 'Detailed Feedback',
		},
		required: true,
		answer: '',
	},
];
export const questionsWhenOverallRatingis3 = [
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Fast Paced',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Not able to understand Concepts',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Topic was difficult',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Not able to understand Problems',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Doubts not cleared',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Instructor delivery was not good',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.SUBJECTIVE,
			text: 'Detailed Feedback',
		},
		required: true,
		answer: '',
	},
];
export const questionsWhenOverallRatingis4 = [
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Understood all the Concepts',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Understood all the problems solved',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Comfortable Pace',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Doubts were cleared',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Extraordinary delivery by Instructor',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.SUBJECTIVE,
			text: 'Detailed Feedback',
		},
		required: true,
		answer: '',
	},
];
export const questionsWhenOverallRatingis5 = [
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Understood all the Concepts',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Understood all the problems solved',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Comfortable Pace',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Doubts were cleared',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.OBJECTIVE,
			text: 'Extraordinary delivery by Instructor',
		},
		required: false,
		answer: false,
	},
	{
		question: {
			questionType: QuestionType.SUBJECTIVE,
			text: 'Detailed Feedback',
		},
		required: false,
		answer: '',
	},
];

const StudentLectureFeedbackPopUp = ({
	data,
	popupType,
}: any) => {
	const [value, setValue] = React.useState<number | null>(
		null
	);
	const [hover, setHover] = React.useState(-1);
	const [questions, setQuestions] =
		React.useState<any>(null);
	// const [open, setOpen] = useState(false);
	const [open, setOpen] = useState(true);
	const [videoId, setVideoId] = useState(null);
	const [videoTitle, setVideoTitle] = useState<any>(null);
	const [displayPopupType, setDisplayPopupType] =
		useState(null);
	const [notFilled, setNotFilled] = React.useState<
		Array<number>
	>([]);

	const UserState = useSelector((state: any) => state.user);
	const { user } = UserState;

	// const getLatestLectureDetails = async () => {
	// 	const LectureFeedbackPopUp =
	// 		await services.studentLectureFeedbackService.getLectureFeedbackPopUpStatus();
	// 	if (LectureFeedbackPopUp.LectureFeedbackPopUp) {
	// 		console.log('running');
	// 		setVideoId(LectureFeedbackPopUp.video.id);
	// 		setVideoTitle(LectureFeedbackPopUp.video.title);
	// 		setOpen(true);
	// 	}
	// };

	// const getLatestLectureDetails = async () => {
	// 	const LectureFeedbackPopUp =
	// 		await services.userService.getPopUpToDisplay();
	// 	if (LectureFeedbackPopUp?.showPopup) {
	// 		console.log('running');

	// 	}
	// };

	// useEffect(() => {
	// 	if (user !== null) {
	// 	}
	// }, [user]);

	useEffect(() => {
		if (value) setQuestions(labels[value]);
	}, [value]);

	useEffect(() => {
		if (data != null) {
			setDisplayPopupType(popupType);
			setVideoId(data?.video?.id);
			setVideoTitle(data?.video?.title);
			setOpen(true);
		}
	}, [data]);

	const closeDialog = () => {
		// Do nothing
	};

	const onSubmit = async (ev: React.MouseEvent) => {
		ev.preventDefault();
		const notFilledRequiredQuestions: number[] = [];
		setNotFilled([]);
		if (value == null) {
			return notifyError(
				'Please rate the last lecture by filling the form'
			);
		}
		questions.forEach((question: any, index: any) => {
			if (question.required && question.answer == '') {
				notFilledRequiredQuestions.push(index);
			}
		});

		if (notFilledRequiredQuestions.length > 0) {
			console.log(notFilledRequiredQuestions, 'not');
			setNotFilled(notFilledRequiredQuestions);
			return notifyError('Please fill detailed feedback');
		}
		console.log(questions, 'ques');
		const feedback: any = {
			response: questions.map((question: any) => ({
				question: question.question,
				answer: question.answer,
			})),
			overallRating: value,
			videoId: videoId,
			feedbackType: displayPopupType,
		};

		try {
			await services.studentLectureFeedbackService.addLectureFeedback(
				feedback
			);
		} catch (err: any) {
			notifyError(err.message);
		}
		setOpen(false);
	};
	const changeAnswer = (
		ev: any,
		index: number,
		check: boolean | null = null
	) => {
		console.log('check', check);
		let answer: any;
		if (
			questions[index].question.questionType ==
			QuestionType.OBJECTIVE
		) {
			console.log('andat');
			answer = check;
		} else answer = ev.target.value;
		console.log(questions, 'theses ater');
		setQuestions((oldQuestions: any) => {
			const oldQuestions_ = [...oldQuestions];
			oldQuestions[index].answer = answer;
			return oldQuestions_;
		});
	};

	const labels: { [index: string]: any } = {
		1: questionsWhenOverallRatingis1,
		2: questionsWhenOverallRatingis2,
		3: questionsWhenOverallRatingis3,
		4: questionsWhenOverallRatingis4,
		5: questionsWhenOverallRatingis5,
	};
	function getLabelText(value: number) {
		return `${value} Star${value !== 1 ? 's' : ''}`;
	}
	return (
		<Dialog
			open={open}
			onClose={closeDialog}
		>
			<DialogTitle>
				<b>
					Please fill the Feedback for {videoTitle} session
				</b>
			</DialogTitle>

			<div
				style={{
					// width: 500,
					// height :400,
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
					justifyContent: 'space-between',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<h4>Overall Rating &nbsp; </h4>
					<Rating
						name="hover-feedback"
						size="large"
						value={value}
						getLabelText={getLabelText}
						onChange={(event, newValue) => {
							setValue(newValue);
							if (newValue)
								setQuestions([...labels[newValue]]);
						}}
						// onChangeActive={(event, newHover) => {
						// 	setHover(newHover);
						// }}
						emptyIcon={
							<StarIcon
								style={{ opacity: 0.55 }}
								fontSize="inherit"
							/>
						}
					/>
				</div>

				{value !== null && (
					<Box sx={{ ml: 2 }}>
						{questions.map((question: any, index: any) => (
							<div
								key={value.toString() + index.toString()}
								style={{
									display: 'flex',
									flexDirection:
										question.question.questionType ==
										QuestionType.SUBJECTIVE
											? 'column'
											: 'row',
									alignItems:
										question.question.questionType ===
										QuestionType.OBJECTIVE
											? 'center'
											: 'left',
									justifyContent: 'space-between',
									paddingBottom:
										question.question.questionType ==
										QuestionType.OBJECTIVE
											? '0px'
											: '10px',
								}}
							>
								<div>{question.question.text}</div>

								<div>
									{question.question.questionType ===
									QuestionType.OBJECTIVE ? (
										<Checkbox
											checked={question.answer}
											onChange={(ev, newValue) =>
												changeAnswer(ev, index, newValue)
											}
											inputProps={{
												'aria-label': 'controlled',
											}}
										/>
									) : (
										<TextField
											//   style={{justifyContent:'flex-start'}}
											rows={3}
											multiline
											placeholder="Your answer"
											fullWidth
											value={question.answer}
											onChange={(ev) =>
												changeAnswer(ev, index)
											}
										/>
									)}
								</div>
							</div>
						))}
					</Box>
				)}
			</div>
			<div style={{ marginTop: '10px' }}>
				<Button
					style={{ width: '100%' }}
					variant="contained"
					onClick={onSubmit}
				>
					Submit
				</Button>
			</div>
		</Dialog>
	);
};

export default StudentLectureFeedbackPopUp;
