import {
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import { engagements } from '../../engagements';

import { encryptData } from '@acciojob/event-analytics';
import { RiLightbulbLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { services } from '../../apis';
import VideoPlayer from '../../components/VideoPlayer';
import { POLLING_INTERVAL_MS } from '../../config/recording-analytics.poll-interval';
import { isMainCourseUser } from '../../utils/events';
import RenderMarkDown from '../shared/RenderMarkdown';
import { VimeoVideoPlayer } from '../shared/VimeoVideoPlayer';

interface LectureRecordingPanelProps {
	type: 'lecture' | 'doubt';
}

const LectureRecordingPanel = ({
	type,
}: LectureRecordingPanelProps) => {
	const courseState = useSelector(
		(state: any) => state.course
	);
	const {
		courseName,
		recordingDataLecture,
		recordingDataDoubt,
	} = courseState;

	const [recordingData, setRecordingData] =
		useState<any>(null);

	useEffect(() => {
		if (recordingDataLecture || recordingDataDoubt) {
			if (type === 'lecture') {
				setRecordingData(recordingDataLecture);
			} else if (type === 'doubt') {
				setRecordingData(recordingDataLecture);
			}
		}
	}, [type, recordingDataLecture, recordingDataDoubt]);

	const [activeSessionId, setActiveSessionId] =
		useState('');
	const [currentInterval, setCurrentInterval] =
		useState<any>(null);

	const ModuleState = useSelector(
		(state: any) => state.module
	);
	const { moduleActive, currentModuleId } = ModuleState;

	const firstTime = useRef(true);

	const [videoURL, setVideoURL] = useState('');
	const [videoName, setVideoName] = useState('');
	const [videoId, setVideoId] = useState('');
	const playBackRate = useRef<number>(1);
	const vimeoVideoPlayerRef = useRef<any>();

	useEffect(() => {
		console.log('LECTURES RECORDING', recordingData);
		if (
			recordingData !== null &&
			recordingData.length > 0
		) {
			setVideoURL(recordingData[0].url);
			setVideoName(recordingData[0].name);
			setVideoId(recordingData[0].videoId);
		}

		return () => {
			localStorage.setItem('duration', '0');
			localStorage.setItem('buffering', 'false');
		};
	}, [recordingData]);

	useEffect(() => {
		firstTime.current = true;
	}, [videoURL]);

	const postNewPlayAnalytic = async () => {
		let timestamp = await vimeoVideoPlayerRef.current
			?.getPlayer()
			.getCurrentTime();
		if (!timestamp) timestamp = 0;
		localStorage.setItem('duration', '0');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const response =
			await services.courseService.postRecordingAnalytics({
				videoId: recordingData[0].video_id,
				starting_timestamp: Math.floor(timestamp),
			});
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const activeSessionId = response.data;
		setActiveSessionId(activeSessionId);
		console.log('posting with ID', activeSessionId);
	};

	const updatePlayAnalytic = async (timestamp: any) => {
		if (!activeSessionId) return;

		const effectiveTimeIncrement =
			POLLING_INTERVAL_MS / 1000;

		let currentSliceDuration =
			localStorage.getItem('duration');
		if (currentSliceDuration === null)
			currentSliceDuration = '0';
		const currentPlaybackRate = playBackRate.current;
		const newDuration =
			parseFloat(currentSliceDuration) +
			effectiveTimeIncrement * currentPlaybackRate;
		localStorage.setItem(
			'duration',
			newDuration.toString()
		);
		console.log(
			'newDuration, currentSliceDuration, effectiveTimeIncrement:',
			newDuration,
			currentSliceDuration,
			effectiveTimeIncrement
		);
		console.log('updating with ID', activeSessionId);

		await services.courseService.updateRecordingAnalytics({
			sessionId: activeSessionId,
			duration: Math.floor(newDuration),
			ending_timestamp: Math.floor(timestamp),
		});
	};

	// Store the interval id in a ref so that we can access its latest value in the cleanup function
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	// Clear interval when currentModuleId changes
	useEffect(() => {
		return () => {
			if (intervalRef.current !== null) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		};
	}, [currentModuleId]);

	// Set up interval when activeSessionId changes
	useEffect(() => {
		if (activeSessionId) {
			intervalRef.current = setInterval(async () => {
				const isBuffering =
					localStorage.getItem('buffering');

				if (vimeoVideoPlayerRef.current) {
					if (
						!(await vimeoVideoPlayerRef.current
							.getPlayer()
							.getPaused()) &&
						isBuffering === 'false'
					) {
						updatePlayAnalytic(
							await vimeoVideoPlayerRef.current
								.getPlayer()
								.getCurrentTime()
						);
					}
				}
			}, POLLING_INTERVAL_MS);
		}

		return () => {
			if (intervalRef.current !== null) {
				clearInterval(intervalRef.current);
				intervalRef.current = null;
			}
		};
	}, [activeSessionId]);

	const [suggestedReading, setSuggestedReading] =
		useState('');

	useEffect(() => {
		if (moduleActive !== null) {
			setSuggestedReading(moduleActive.suggestedReading);
		}
	}, [moduleActive]);

	const callbacks = useMemo(() => {
		return {
			onBufferStart: () => {
				localStorage.setItem('buffering', 'true');
			},
			onBufferEnd: () => {
				localStorage.setItem('buffering', 'false');
			},
			onPlay: () => {
				postNewPlayAnalytic();
				console.log({ firstTime: firstTime.current });
				if (firstTime.current) {
					engagements.courseEngagement.watchRecording(
						videoName,
						videoId,
						courseName,
						moduleActive.name
					);
					firstTime.current = false;
				}
			},
			onPlaybackRateChange: (e: any) => {
				playBackRate.current = e.playbackRate;
			},
		};
	}, [recordingData]);

	if (!recordingData?.length || !callbacks) return null;

	return (
		<div className={'lecture-and-notes'}>
			<h4 style={{ marginBottom: '20px' }}>
				{recordingData !== null && recordingData.length > 0
					? recordingData[0].name
					: null}
			</h4>
			{/* Custom Video Player */}
			{videoURL ? (
				videoURL.includes('https://us06web.zoom.us/rec') ? (
					<div
						event-analytics={
							isMainCourseUser()
								? '7ebb9a0b-ca90-4741-89bd-64bc42567064'
								: ''
						}
						data-config={encryptData(
							JSON.stringify({
								name: videoName,
							})
						)}
					>
						<VideoPlayer
							src={videoURL}
							id={videoId}
							name={videoName}
						/>
					</div>
				) : (
					<div
						event-analytics={
							isMainCourseUser()
								? '7ebb9a0b-ca90-4741-89bd-64bc42567064'
								: ''
						}
						data-config={encryptData(
							JSON.stringify({
								name: videoName,
							})
						)}
					>
						<VimeoVideoPlayer
							id={videoId}
							ref={vimeoVideoPlayerRef}
							options={{
								url: videoURL,
								maxwidth: 640,
								maxheight: 360,
								autoplay: true,
								controls: true,
								pip: true,
								transparent: true,
								title: false,
							}}
							style={{
								position: 'relative',
								display: 'flex',
								justifyContent: 'center',
								marginInline: 'auto',
								borderRadius: '5px',
								overflow: 'hidden',
								background: 'black',
								height: '360px',
								width: '640px',
							}}
							callbacks={callbacks}
						/>
					</div>
				)
			) : null}

			<div className={'video-carousel'}>
				{recordingData !== null
					? recordingData.map((el: any, index: number) => {
							return (
								<div
									key={el.url}
									className={'video-item'}
									onClick={() => {
										setVideoURL(el.url);
										console.log('URL-->', el.url);
									}}
									event-analytics={
										isMainCourseUser()
											? '7ebb9a0b-ca90-4741-89bd-64bc42567064'
											: ''
									}
									data-config={encryptData(
										JSON.stringify({
											name: videoName,
										})
									)}
								>
									<h3 className={'videoName'}>{el.name}</h3>
									{/*<img*/}
									{/*	style={{ height: '120px' }}*/}
									{/*	src={`${window.location.origin}/assets/images/${el.thumbnail}`}*/}
									{/*/>*/}
									<BsFillPlayCircleFill />
								</div>
							);
					  })
					: null}
			</div>
			{suggestedReading !== null &&
			suggestedReading !== undefined &&
			suggestedReading !== '' ? (
				<div className={'suggested-readings'}>
					<h3 style={{ marginBottom: '20px' }}>
						<span>
							<RiLightbulbLine />
						</span>{' '}
						Suggested Readings
					</h3>
					<RenderMarkDown>
						{suggestedReading}
					</RenderMarkDown>
				</div>
			) : null}
		</div>
	);
};

export default LectureRecordingPanel;
