import { Avatar } from '@mui/joy';
import { useEffect, useState } from 'react';
import { AiOutlineShareAlt } from 'react-icons/ai';
import { BiLogoLinkedin } from 'react-icons/bi';
import { FaGithub } from 'react-icons/fa';
import { SiLeetcode } from 'react-icons/si';
import AccioButton from '../elements/Button';
import { ShareModal } from './Modals/Share/ShareModal';
import { StudentProfileModal } from './Modals/StudentProfileModal';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import {
	fetchAcademicDetails,
	fetchExperienceDetails,
	fetchPersonalDetails,
} from '../../redux/profile/profile.thunk';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const TopProfile = () => {
	const [editProfile, setEditProfile] = useState(false);
	const [share, setShare] = useState(false);
	const dispatch = useAppDispatch();
	const {
		personalDetails,
		academicDetails,
		experienceDetails,
	} = useAppSelector((state) => state.profile);

	console.log('personalDetails>>>', personalDetails);
	console.log('academicDetails>>>', academicDetails);
	console.log('experienceDetails>>>', experienceDetails);

	return (
		<div className="top-profile">
			<StudentProfileModal
				type="edit-profile"
				state={editProfile}
				setState={setEditProfile}
			/>
			<ShareModal
				state={share}
				setState={setShare}
			/>
			<div className="top-profile-img-div">
				<Avatar
					sx={{ width: 62, height: 62 }}
					src={personalDetails?.data?.profileImage ?? ''}
				/>
				<div className="info">
					<p className="blue-heading">Profile Dashboard</p>
					<p className="white-name">
						{personalDetails.data?.firstName +
							' ' +
							personalDetails.data?.lastName}
					</p>
				</div>
			</div>
			<div className="profile-info">
				<div className="row">
					<div className="btn-flex">
						<AccioButton
							title="Edit Profile"
							customClass={'white-btn'}
							action={() => setEditProfile(true)}
						/>
						<AccioButton
							title="Share"
							iconPosition="right"
							customClass={'white-border-btn'}
							icon={<AiOutlineShareAlt />}
							action={() => setShare(true)}
						/>
					</div>
					<div className="info-div">
						<p className="text">Profiles</p>
						<div className="flex-end-div">
							<div
								className="icon-flex"
								onClick={() => {
									if (personalDetails.data?.linkedInUrl) {
										window.open(
											personalDetails.data?.linkedInUrl,
											'_blank'
										);
									} else {
										toast.error(
											'Please Add Your Linkedin Url!'
										);
									}
								}}
							>
								<p className="icon-text">Linkedin</p>
								<BiLogoLinkedin style={{ fontSize: 16 }} />
							</div>
							<div
								className="icon-flex"
								onClick={() => {
									if (
										personalDetails.data?.leetcodeUsername
									) {
										window.open(
											'https://leetcode.com/' +
												personalDetails.data
													?.leetcodeUsername,
											'_blank'
										);
									} else {
										toast.error(
											'Please Add Your Leetcode Username!'
										);
									}
								}}
							>
								<p className="icon-text">Leetcode</p>
								<SiLeetcode style={{ fontSize: 16 }} />
							</div>
							<div
								className="icon-flex"
								onClick={() => {
									if (
										personalDetails.data?.githubProfileLink
									) {
										window.open(
											personalDetails.data
												?.githubProfileLink,
											'_blank'
										);
									} else {
										toast.error(
											'Please Add Your Github Url!'
										);
									}
								}}
							>
								<p className="icon-text">Github</p>
								<FaGithub style={{ fontSize: 16 }} />
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="info-div">
						<p className="text">Email ID</p>
						<p className="email">
							{personalDetails.data?.email ?? 'No Email'}
						</p>
					</div>
					<div className="info-div">
						<p className="text">Skills</p>
						<div className="flex-end-div chips">
							{personalDetails.data?.otherCodingSkills ? (
								personalDetails.data?.otherCodingSkills.map(
									(item) => <p className="chip">{item}</p>
								)
							) : (
								<p className="chip">No Skills Added</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
