import React, { useEffect, useRef, useState } from 'react';

import {
	BsArrowLeftShort,
	BsArrowRightShort,
	BsCheck,
	BsCheckAll,
} from 'react-icons/bs';
import { IoClose } from 'react-icons/io5';
import ModuleMonthJourney from '../../components/ModuleJourneyComponent/ModuleMonthJourney';
import ModuleJourneyDetails from '../../components/ModuleJourneyComponent/ModuleJourneyDetails';
import ModuleJourneyStepper from '../../components/ModuleJourneyComponent/ModuleJourneyStepper';

type StatusType =
	| 'completed'
	| 'partially-completed'
	| 'ongoing'
	| 'failed'
	| 'upcoming';

interface DataItemType {
	name: string;
	status: StatusType;
	IsPlacement: boolean;
}

interface CoursedataVal {
	month: string;
	year: string;
	status: StatusType;
	course: string;
	monthDisplay: string;
}

const data: DataItemType[][] = [
	[{ name: 'S1', status: 'completed', IsPlacement: false }],
	[{ name: 'S1', status: 'completed', IsPlacement: false }],
	[
		{
			name: 'S1',
			status: 'partially-completed',
			IsPlacement: false,
		},
	],
	[{ name: 'S1', status: 'completed', IsPlacement: false }],
	[
		{ name: 'S1', status: 'completed', IsPlacement: false },
		{ name: 'S1', status: 'completed', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'completed', IsPlacement: true },
		{ name: 'S1', status: 'failed', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'completed', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'completed', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'ongoing', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
	[
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
		{ name: 'S1', status: 'upcoming', IsPlacement: false },
	],
];

const data2 = [
	{
		month: 'd',
		year: 'd',
		status: 'upcoming',
		course: 'd',
		monthDisplay: 'Course Starts',
	},
	{
		month: 'Jan',
		year: '2023',
		status: 'completed',
		course: 'S1',
		monthDisplay: 'M1',
	},
	{
		month: 'Jan',
		year: '2023',
		status: 'completed',
		course: 'S1',
		monthDisplay: 'M1',
	},
	{
		month: 'Jan',
		year: '2023',
		status: 'completed',
		course: 'S1',
		monthDisplay: 'M1',
	},
	{
		month: 'Feb',
		year: '2023',
		status: 'ongoing',
		course: 'S2',
		monthDisplay: 'M2',
	},
	{
		month: 'Mar',
		year: '2023',
		status: 'upcoming',
		course: 'S3',
		monthDisplay: 'M3',
	},
	{
		month: 'Apr',
		year: '2023',
		status: 'upcoming',
		course: 'S4',
		monthDisplay: 'M4',
	},
	{
		month: 'May',
		year: '2023',
		status: 'failed',
		course: 'S5',
		monthDisplay: 'M5',
	},
	{
		month: 'Jun',
		year: '2023',
		status: 'completed',
		course: 'S6',
		monthDisplay: 'M6',
	},
	{
		month: 'Jul',
		year: '2023',
		status: 'completed',
		course: 'S7',
		monthDisplay: 'M7',
	},
	{
		month: 'Aug',
		year: '2023',
		status: 'partially-completed',
		course: 'S8',
		monthDisplay: 'M8',
	},
	{
		month: 'Sep',
		year: '2023',
		status: 'upcoming',
		course: 'S9',
		monthDisplay: 'M9',
	},
	{
		month: 'Oct',
		year: '2023',
		status: 'upcoming',
		course: 'S10',
		monthDisplay: 'M10',
	},
	{
		month: 'Nov',
		year: '2023',
		status: 'upcoming',
		course: 'S11',
		monthDisplay: 'M11',
	},
	{
		month: 'Dec',
		year: '2023',
		status: 'upcoming',
		course: 'S12',
		monthDisplay: 'M12',
	},
	{
		month: 'd',
		year: 'd',
		status: 'upcoming',
		course: 'd',
		monthDisplay: 'Course Ends',
	},
];

//always two button getting disable make it in different way
const ModuleJourney = () => {
	const [isClicked, setIsClicked] = useState(true);
	const [offset, setOffset] = useState<number>(0);
	const [isPrevDisabled, setIsPrevDisabled] =
		useState<boolean>(true);
	const [isNextDisabled, setIsNextDisabled] =
		useState<boolean>(false);
	const sliderRef = useRef<HTMLDivElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const sliderWidth = sliderRef.current
			? sliderRef.current.offsetWidth
			: 0;
		const containerWidth = containerRef.current
			? containerRef.current.offsetWidth
			: 0;
		setIsPrevDisabled(offset === 0);
		setIsNextDisabled(
			sliderWidth - containerWidth <= Math.abs(offset)
		);
	}, [offset]);

	const handlePrevClick = () => {
		if (offset === 0) {
			return;
		}
		setOffset(offset + 80);
	};

	const handleNextClick = () => {
		const sliderWidth = sliderRef.current
			? sliderRef.current.offsetWidth
			: 0;
		const containerWidth = containerRef.current
			? containerRef.current.offsetWidth
			: 0;
		if (sliderWidth - containerWidth <= offset) {
			return;
		}
		setOffset(offset - 80);
	};

	return (
		<div className="module-journey-wrapper">
			<div className="module-journey-heading-legends">
				<p className="module-journey-heading">
					Module Section
				</p>
				<div className="module-journey-legends-wrapper">
					<div className="module-journey-legends">
						<div className="legend-box legend-Completed">
							<BsCheckAll />
						</div>
						<div className="legend-text">Completed</div>
					</div>
					<div className="module-journey-legends">
						<div className="legend-box legend-Partially-Completed">
							<BsCheck />
						</div>
						<div className="legend-text">
							Partially-Completed
						</div>
					</div>
					<div className="module-journey-legends">
						<div className="legend-box legend-Ongoing"></div>
						<div className="legend-text">Ongoing</div>
					</div>
					<div className="module-journey-legends">
						<div className="legend-box legend-Upcoming"></div>
						<div className="legend-text">Upcoming</div>
					</div>
					<div className="module-journey-legends">
						<div className="legend-box legend-Failed">
							<IoClose />
						</div>
						<div className="legend-text">Failed</div>
					</div>
				</div>
			</div>
			<div className="module-journey-title">
				Your Progress
				<span className="module-journey-title-span">
					(Click on modules for details)
				</span>
			</div>
			<div className="module-journey-bottom-wrapper">
				<div
					className={`container-module-journey ${
						isClicked
							? 'container-module-journey-width'
							: ''
					}`}
				>
					{isClicked ? (
						<button
							className={`prev-btn ${
								isPrevDisabled ? 'disabled' : ''
							}`}
							onClick={handlePrevClick}
							disabled={isPrevDisabled}
						>
							<BsArrowLeftShort />
						</button>
					) : null}

					<div
						className="slider"
						ref={containerRef}
					>
						<div
							ref={sliderRef}
							style={{
								transform: `translateX(${offset}px)`,
							}}
						>
							<ModuleJourneyStepper data={data} />
						</div>
					</div>

					{isClicked ? (
						<button
							className={`next-btn ${
								isNextDisabled ? 'disabled' : ''
							}`}
							onClick={handleNextClick}
							disabled={isNextDisabled}
						>
							<BsArrowRightShort />
						</button>
					) : null}
				</div>
				{isClicked ? (
					<div className="module-journey-card">
						<span className="module-journey-card-tag">
							upcoming
						</span>
					</div>
				) : null}
			</div>
			<div className="module-journey-title">
				Your Timeline
			</div>
			<div className="module-month-journey">
				<p className="module-month-journey-text">
					Your course duration is
					<b> 14 months</b>, complete your modules to get
					placement ready!!
				</p>
				<ModuleMonthJourney dataVal={data2} />
			</div>
			<div className="module-journey-title">
				Your Timeline
			</div>
			<div>
				<ModuleJourneyDetails />
			</div>
		</div>
	);
};

export default ModuleJourney;
