import { type FC } from 'react';
import { DifficultyColors } from '../../constants/colors';

import { DifficultyTypeEnum } from '../../redux/gym/type';

type DifficultyChipPropsType = {
	difficulty: DifficultyTypeEnum;
};

export const DifficultyChip: FC<
	DifficultyChipPropsType
> = ({ difficulty }) => {
	const colorObj = DifficultyColors[difficulty];
	return (
		<div
			className="chip"
			style={{
				color: colorObj.color,
				backgroundColor: colorObj.backgroundColor,
			}}
		>
			{difficulty.toLowerCase()}
		</div>
	);
};
