import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const WebDevEditor = () => {
	const location = useLocation();

	const question: Array<string | null> | null | string =
		queryString.parse(location.search).question;

	const endpoint =
		'https://acciojob-backend-eobnd7jx2q-el.a.run.app';
	const platform = 'unrestricted';
	const type = 'course';
	const contestId = '';
	const hostingPlatform = 'https://webeditor.acciojob.com';

	return (
		<iframe
			className={'CodeEditor'}
			allow={'fullscreen'}
			src={`${hostingPlatform}/?question=${question}&endpoint=${endpoint}&platform=${platform}&type=${type}&contest=${contestId}`}
		/>
	);
};

export default WebDevEditor;
