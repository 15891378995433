import React from 'react';
import Banner from './Banner';
import MarkDown from './MarkDown';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

interface ContestProps {
	contestData: any;
}

const OngoingContest = ({ contestData }: ContestProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const contestId = queryString.parse(
		location.search
	).contestId;

	const contestInfoState = useSelector(
		(state: any) => state.contest
	);

	const { codingQuestions, flag } = contestInfoState;
	return contestData !== null ? (
		<div>
			<Banner
				msg={{
					head: 'Time is running out!',
					para: 'Resume contest or your work will be auto submitted.',
				}}
				duration={contestData.duration}
				startTime={contestData.startTime}
				endTime={contestData.endTime}
				active={false}
			/>
			<div className="instructions">
				<MarkDown statement={contestData.instructions} />
			</div>
		</div>
	) : null;
};

export default OngoingContest;
