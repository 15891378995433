import React, { useEffect, useState } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';

import { services } from '../../apis';
import AccioButton from '../elements/Button';
import CustomDropdown from '../shared/Dropdown';
import { offCampusCardsWithFilters } from '../../redux/jobportal/jobPortalSlice';
interface Column {
	id:
		| 'companyName'
		| 'skillsRequired'
		| 'dateAdded'
		| 'guide'
		| 'remark'
		| 'eligibility'
		| 'gradYR'
		| 'apply';
	label: string;
	minWidth?: number;
	align?: 'left';
	format?: (value: any) => any;
}

type OptionType = {
	label: string;
	value: string;
};

const columns: readonly Column[] = [
	{
		id: 'companyName',
		label: 'Company Name',
		minWidth: 200,
	},
	{
		id: 'skillsRequired',
		label: 'Skills Required',
		minWidth: 190,
	},
	{
		id: 'dateAdded',
		label: 'Date Added',
		minWidth: 120,

		align: 'left',
	},
	{ id: 'guide', label: '', minWidth: 120 },
	{ id: 'remark', label: '', minWidth: 200 },
];

interface Data {
	companyName: string;
	rolesOffered: string;
	collegeDegree: Array<string>;
	companyLocation: Array<string>;
	minCtc: number;
	maxCtc: number;
}

interface OffCampusProps {
	pageNumber: number;
	pageSize: number;
	handleChangePage: any;
	handleChangeRowsPerPage: any;
	data: any;
	filters: any;
	handleFilterChange: any;
	feedbackOption: string[];
	totalLength: number;
}

const createData = (data: Data) => {
	return {
		companyName: data.companyName,
		rolesOffered: data.rolesOffered,
		collegeDegree: data.collegeDegree
			? data.collegeDegree
			: 'Not Mentioned',
		companyLocation: data.companyLocation
			? data.companyLocation
			: 'Not Mentioned',
		salary:
			data.minCtc && data.maxCtc
				? `${data.minCtc} - ${data.maxCtc}`
				: data.minCtc
				? data.minCtc
				: data.maxCtc
				? data.maxCtc
				: 'Not mentioned',
	};
};

export default function OffCampus(props: OffCampusProps) {
	console.log('props', props);
	const [showPopup, setShowpopup] =
		useState<boolean>(false);

	const {
		pageNumber,
		pageSize,
		handleChangePage,
		handleChangeRowsPerPage,
		data,
		handleFilterChange,
		filters,
	} = props;
	const [value, setValue] = React.useState<number[]>([
		2022, 2025,
	]);
	const [allData, setAllData] = useState(props.data);
	const [dropDownOptions, setDropdownOptions] = useState<
		string[]
	>([]);

	useEffect(() => {
		if (
			props.feedbackOption &&
			props.feedbackOption.length > 0
		) {
			setDropdownOptions([
				...props.feedbackOption,
				'Go to job',
			]);
		}
	}, [props.feedbackOption]);

	useEffect(() => {
		setAllData(props.data);
	}, [props.data]);
	const handleChange = (
		event: Event,
		newValue: number | number[]
	) => {
		handleFilterChange({
			minYearOfGraduation: (newValue as number[])[0],
			maxYearOfGraduation: (newValue as number[])[1],
		});
		// setValue(newValue as number[]);
	};
	console.log('AllData', allData);

	// const optionDataPost

	const handleChangeEvent = (event: any) => {
		const i = props.feedbackOption.indexOf(
			event.target.value
		);
		console.log(event.target.value);
		// setOptionDropDown(optionDropDown.map((e,idx) => idx===i ? 1:0))

		// const optionSelectPostRequest = async () => {};
		// optionSelectPostRequest();
	};

	const dispatch = useDispatch<AppDispatch>();

	// get global state
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);
	const { multiSelectState } = jobPortalState;

	const handleApply = async (
		jobCardId: string,
		applicationLink: string
	) => {
		const data =
			await services.jobPortalService.postApplicationOffCampus(
				jobCardId
			);
		window.open(applicationLink, '_blank');
	};
	console.log('allData', allData);

	return (
		<div className="offcampus-container">
			{/* <div className="offcampus-filters" >
        <div className="textfilters" >
          <div>
            <p>Company Name</p>
            <input value={filters.companyName} onChange={(e)=>handleFilterChange({"companyName":e.target.value})} placeholder='Search by company name' />
          </div>
          <div>
            <p>Roles Offered</p>
            <input value={filters.rolesOffered} onChange={(e)=>handleFilterChange({"rolesOffered":e.target.value})} placeholder='Search by job roles' />
          </div>
        </div>
        <div className="non-textfilters">
          <div>
            <p>Location</p>
            <MultipleSelectChip filterName={"companyLocation"} names={multiSelectState.companyLocation} personName={filters.companyLocation}  handleFilterChange={handleFilterChange} />
          </div>
          <div>
            <p>Degree</p>
            <MultipleSelectChip filterName={"collegeDegree"} names={multiSelectState.collegeDegree} personName={filters.collegeDegree}  handleFilterChange={handleFilterChange} />
          </div>
          <div>
            <p>Graduation Yr. {filters.minYearOfGraduation} - {filters.maxYearOfGraduation}</p>
            <Slider
              min={2000}
              max={2030}
              getAriaLabel={() => 'Graduation year'}
              value={[filters.minYearOfGraduation,filters.maxYearOfGraduation]}
              onChange={handleChange}
              valueLabelDisplay="auto"
              // getAriaValueText={value}
            />
          </div>
        </div>
        

      </div> */}
			<Paper
				sx={{
					width: '60vw',
					border: '1px solid #e9e9e9',
					borderRadius: '10px',
					marginTop: '10px',
					position: 'relative',
					minHeight: '75vh',
				}}
			>
				<TableContainer
					className="table-scrollbar view-scroll-bar"
					// style={{
					// 	maxHeight: '68vh',
					// 	overflow: 'auto',
					// 	'&::-webkit-scrollbar': {
					// 		width: '5px',
					// 	},
					// 	'&::-webkit-scrollbar-track': {
					// 		backgroundColor: 'transparent',
					// 	},
					// 	'&::-webkit-scrollbar-thumb': {
					// 		backgroundColor: '#d0d5dd',
					// 		borderRadius: '5px',
					// 	},
					// 	'&::-webkit-scrollbar-thumb:hover': {
					// 		backgroundColor: '#98a2b3',
					// 	},
					// }}
				>
					<Table aria-label="sticky table">
						<TableHead>
							<TableRow className="table-head">
								{columns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{
											minWidth: column.minWidth,
											fontSize: '12px',
											color: 'rgba(80, 88, 98, 0.7)',
											textTransform: 'uppercase',
											position: 'sticky',
											top: 0,
											backgroundColor: '#fff',
											zIndex: 1,
										}}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{allData.map((row: any, index: number) => {
								return (
									<TableRow
										hover
										role="checkbox"
										tabIndex={-1}
										key={index}
									>
										<TableCell
											style={{
												color: '#2B2F34',
												fontSize: '16px',
												fontWeight: '600',
											}}
										>
											{row.companyName}
											{/* <div style={{display:"flex", alignItems:"center"}} >
                              {row.companyLinkedin && <LinkedInIcon style={{color:"#2f80ec"}} onClick={()=> window.open(row.companyLinkedin)} />}
                              {row.companyWebsite && <LanguageIcon style={{color:"slategray"}} onClick={()=> window.open(row.companyWebsite)}/>}
                            </div> */}
										</TableCell>

										<TableCell>
											<div className="offcampus-skill-tag-wrapper">
												{row.skillsRequired &&
												row.skillsRequired.length > 0 ? (
													<>
														{row.skillsRequired.length >=
														1 ? (
															<span className="offcampus-skill-tag">
																{row.skillsRequired[0]}
															</span>
														) : null}
														{row.skillsRequired.length >=
														2 ? (
															<span className="offcampus-skill-tag">
																{row.skillsRequired[1]}
															</span>
														) : null}
														{row.skillsRequired.length >=
														3 ? (
															<span className="offcampus-skill-tag">
																{row.skillsRequired[2]}
															</span>
														) : null}
														{row.skillsRequired.length >=
														4 ? (
															<span className="offcampus-skill-tag">
																{row.skillsRequired[3]}
															</span>
														) : null}
														{row.skillsRequired.length >
														4 ? (
															<span
																className="offcampus-skill-tag"
																style={{
																	cursor: 'pointer',
																}}
																onMouseOver={() =>
																	setShowpopup(true)
																}
																onMouseLeave={() =>
																	setShowpopup(false)
																}
															>
																+
																{row.skillsRequired.length -
																	4}{' '}
																more
															</span>
														) : null}
														{showPopup &&
														row.skillsRequired.length >
															4 ? (
															<div className="offcampus-extra-skill-tag-wrapper">
																{row.skillsRequired
																	.slice(4)
																	.map(
																		(
																			tag: any,
																			ind: number
																		) => (
																			<span
																				key={ind}
																				className="offcampus-skill-tag"
																			>
																				{tag}
																			</span>
																		)
																	)}
															</div>
														) : null}
													</>
												) : (
													''
												)}
											</div>
										</TableCell>

										<TableCell>
											{row.dateAdded ? (
												<span className="offcampus-duration-wrapper">
													{' '}
													{moment(moment()).diff(
														row.dateAdded,
														'days'
													) == 0
														? 'Today'
														: moment(moment()).diff(
																row.dateAdded,
																'days'
														  ) +
														  ' ' +
														  'Days ago'}{' '}
												</span>
											) : null}
										</TableCell>

										<TableCell
											style={{ textAlign: 'center' }}
										>
											<span
												className="offcampus-guide"
												onClick={() =>
													window.open(
														row.sourceGuide
															? row.sourceGuide
															: '',
														'_blank'
													)
												}
											>
												{' '}
												{row.source ? row.source : ''}{' '}
												<span
													style={{
														marginLeft: '5px',
													}}
												></span>
											</span>
										</TableCell>

										<TableCell>
											{row.applied === null ? (
												<AccioButton
													variant="primary"
													action={() =>
														handleApply(
															row.id,
															row.applicationLink
														)
													}
													title="Apply"
													icon={''}
													customClass="offcampus-btn"
												/>
											) : (
												<CustomDropdown
													options={dropDownOptions.map(
														(ele) => ({
															value: ele,
															label: ele,
														})
													)}
													id="demo-simple-select"
													value={
														row.feedback === '{}'
															? ''
															: {
																	value:
																		row.feedback[
																			row.feedback.length -
																				1
																		],
																	label:
																		row.feedback[
																			row.feedback.length -
																				1
																		],
															  }
													}
													onDropdownChange={async (
														selectedOption: OptionType
													) => {
														if (selectedOption) {
															const value = (
																selectedOption as OptionType
															).value;
															if (value === 'Go to job') {
																window.open(
																	row.applicationLink,
																	'_blank'
																);
															} else {
																if (row.feedback == '{}') {
																	const res =
																		await services.jobPortalService.postOffCampusApplicationStatus(
																			row.id,
																			value
																		);
																} else {
																	const res =
																		await services.jobPortalService.postOffCampusApplicationStatus(
																			row.id,
																			value
																		);
																}
																dispatch(
																	offCampusCardsWithFilters(
																		{
																			pageNumber,

																			pageSize,
																			...filters,
																		}
																	)
																);
															}
														}
													}}
													placeholder="Status"
												/>
											)}
										</TableCell>

										{/* <TableCell >
                            {
                              row.companyLocation.length>0 ? row.companyLocation.join(",") : "Not mentioned"
                            }
                          </TableCell> */}

										{/* <TableCell >
                            {
                              (row.minCtc && row.maxCtc) ? `${row.minCtc} - ${row.maxCtc}` : row.minCtc ? row.minCtc : row.maxCtc ?row.maxCtc: "Not mentioned"
                            }
                          </TableCell> */}
										{/* <TableCell>
                            {(row.minYearOfGraduation && row.maxYearOfGraduation) ? `${row.minYearOfGraduation} - ${row.maxYearOfGraduation}` : row.minYearOfGraduation ? row.minYearOfGraduation : row.maxYearOfGraduation ?row.maxYearOfGraduation: "Not mentioned"}
                          </TableCell> */}

										{/* <TableCell>
                            <button className='suggestcompany-btn' onClick={()=> window.open(row.applicationLink)} >Apply</button>
                          </TableCell> */}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					component="div"
					count={props.totalLength}
					rowsPerPage={pageSize}
					page={pageNumber}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					style={{ position: 'sticky', bottom: '0' }}
				/>
			</Paper>
		</div>
	);
}
