import React from 'react';
import accioloader from '../assets/lottie/accioloader.json';
import Lottie from 'react-lottie-player';

const AccioLoader = () => {
	return (
		<div className={'accioloader'}>
			<Lottie
				className={'confetti'}
				loop
				animationData={accioloader}
				play
			/>
		</div>
	);
};

export default AccioLoader;
