import React, { useState } from 'react';

const ReferWhyCard = ({ srcIcon, color, t1, t2 }: any) => {
	return (
		<div
			className={`re-referral-reasons-card ${color}-card`}
		>
			<img
				src={srcIcon}
				className="icon"
				alt="Group Icon"
			/>
			<div className="title">{t1}</div>
			<div className="subtitle">{t2}</div>
		</div>
	);
};

export default ReferWhyCard;
