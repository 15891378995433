import React, { useRef } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { HiArrowLeft } from 'react-icons/hi2';
import AccioButton from '../elements/Button';
import ItemList from './Listitem';

const SlideInContent = ({
	items,
	fetchMoreData,
	isLoading,
	setViewTable,
	handleSwipe,
}: any) => {
	const containerRef = useRef(null);

	const scrollToTop = () => {
		if (containerRef.current) {
			//@ts-ignore
			containerRef.current.scrollTop = 0;
		}
	};

	return (
		<div className="table-wrapper">
			<div className="sub-div-1">
				<HiArrowLeft
					style={{
						color: '#667085',
						marginRight: '-10px',
						fontSize: '14px',
					}}
				/>
				<AccioButton
					customClass="check-status-tbl-btn"
					variant="primary"
					action={() => {
						setViewTable(false);
						handleSwipe('right');
					}}
					icon={''}
					title="Back to Job Description"
					iconPosition="left"
				/>
			</div>
			<div
				className="sub-div-2"
				ref={containerRef}
				id="job-data-applied-now"
				style={{
					overflow: 'scroll',
					height: '300px',
				}}
			>
				<ItemList
					items={items}
					fetchMoreData={fetchMoreData}
					isLoading={isLoading}
				/>
			</div>
			<div className="sub-div-3">
				<AccioButton
					customClass="check-status-tbl-btn-a"
					action={() => scrollToTop()}
					variant="outline"
					title="Go to top"
					icon={<AiOutlineArrowUp />}
				></AccioButton>
			</div>
		</div>
	);
};

export default SlideInContent;
