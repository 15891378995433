import { encryptData } from '@acciojob/event-analytics';
import moment from 'moment';
import { useState } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { FiChevronRight } from 'react-icons/fi';
import { MdLiveTv } from 'react-icons/md';
import { SiGoogleclassroom } from 'react-icons/si';
import { isMainCourseUser } from '../../utils/events';

const colorMap = (num: number): any => {
	const color = [
		{
			color: 'rgba(239,87,76,0.5)',
			bg: '#FDE6E4',
		},
		{
			color: '#FF940A',
			bg: 'rgba(255, 239, 218, 0.5)',
		},
		{
			color: '#12B76A',
			bg: 'rgba(227, 255, 237, 0.5)',
		},
	];
	if (num < 0.5) {
		return color[0];
	} else if (num >= 0.5 && num < 0.8) {
		return color[1];
	} else if (num >= 0.8) {
		return color[2];
	}
};

interface ModuleCardProps {
	active: boolean;
	id: string;
	startDate: string;
	moduleName: string;
	idUserModule: string;
	onModuleSelect: Function;
	totalQuestions: number;
	solved: number;
	liveAttended: boolean;
	recordingWatched: boolean;
	type: string;
}

const ModuleCard = ({
	active,
	id,
	startDate,
	moduleName,
	idUserModule,
	onModuleSelect,
	totalQuestions,
	solved,
	liveAttended,
	recordingWatched,
	type,
}: ModuleCardProps) => {
	const [displayClassStatus, setDisplayClasssStatus] =
		useState<any>('');
	const [
		displayClassStatusLive,
		setDisplayClasssStatusLive,
	] = useState<any>(false);
	const [hoveredModuleID, setHoveredModuleID] =
		useState<any>(null);
	const [displayClassStatusR, setDisplayClasssStatusR] =
		useState<any>(false);

	return (
		<li
			className={`${active ? 'active-module' : ''}`}
			key={id}
			onClick={() => {
				onModuleSelect(id, type, moduleName);
			}}
			style={{
				backgroundImage: `linear-gradient( to right, ${
					!active
						? colorMap(
								totalQuestions !== 0
									? solved / totalQuestions
									: 1
						  ).bg
						: '#ebf3fe'
				} ${
					(totalQuestions !== 0
						? solved / totalQuestions
						: 1) * 100
				}% , #fff 
                    	${1}%
                      )`,
				// backgroundSize: `${(solved / totalQuestions) * 100}%`,
			}}
			event-analytics={
				isMainCourseUser() && active
					? '17da8415-15da-4740-a5a3-c86086c64ad8'
					: ''
			}
			data-config={
				active
					? encryptData(
							JSON.stringify({
								name: moduleName,
								label: moduleName,
							})
					  )
					: ''
			}
		>
			<div className={'module-info'}>
				<p className={'date'}>
					{moment(startDate).format('DD MMMM')}
				</p>
				<h3 className={'module-name'}>{moduleName}</h3>
			</div>

			<div className="data_module-0">
				<div
					className={'completed'}
					style={{
						color: `${
							!active
								? colorMap(
										totalQuestions !== 0
											? solved / totalQuestions
											: 1
								  ).color
								: '#2666BE'
						}`,
					}}
				>
					{solved}/{totalQuestions} Completed
					{active ? <FiChevronRight /> : null}
				</div>
				<div className="recodring-icon-span-holder">
					{hoveredModuleID === id &&
						(displayClassStatusLive ||
							displayClassStatusR) && (
							<span className="recodring-icon-span-tag">
								{displayClassStatus}
							</span>
						)}

					{liveAttended ? (
						<span
							className="recodring-icon-span-live"
							id="recodring-icon-span-live"
							onMouseEnter={() => {
								setDisplayClasssStatus(
									'Live Class Attended'
								);
								setHoveredModuleID(id);
								setDisplayClasssStatusLive(true);
							}}
							onMouseLeave={() => {
								setDisplayClasssStatus('');
								setHoveredModuleID(null);
								setDisplayClasssStatusLive(false);
							}}
						>
							<SiGoogleclassroom />
						</span>
					) : null}

					{recordingWatched ? (
						<span
							onMouseEnter={() => {
								setDisplayClasssStatus(
									' Recording Watched'
								);
								setHoveredModuleID(id);
								setDisplayClasssStatusR(true);
							}}
							onMouseLeave={() => {
								setDisplayClasssStatus('');
								setHoveredModuleID(null);
								setDisplayClasssStatusR(false);
							}}
							className="recodring-icon-span-recorded"
							id="recodring-icon-span-recorded"
						>
							<MdLiveTv />
						</span>
					) : null}
				</div>
			</div>

			{idUserModule ? (
				<div
					className={'user-module-banner'}
					title={
						'These are the special modules that are specific to you'
					}
				>
					<AiFillStar />
				</div>
			) : null}
		</li>
	);
};

export default ModuleCard;
