import { debounce } from 'lodash';
import {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
	type FC,
} from 'react';
import { BsSearch } from 'react-icons/bs';
import { DiJsBadge, DiPython } from 'react-icons/di';
import {
	FiArrowLeft,
	FiArrowRight,
	FiBookmark,
} from 'react-icons/fi';

import { Button } from '@mui/material';
import { MdOutlineClose } from 'react-icons/md';
import {
	QuestionDifficultyOptions,
	QuestionStatusOptions,
	QuestionTypeOptions,
} from '../../pages/gym/constants';
import { GymActions } from '../../redux/gym/gym.slice';
import {
	fetchQuestions,
	fetchRevisionSheetByTab,
	fetchTopicFilters,
} from '../../redux/gym/thunks/gym.thunk';
import {
	AttemptStatusEnum,
	DifficultyTypeEnum,
	IdNameCountRecordType,
	QuestionTypeEnum,
	RevisionSheetDataType,
} from '../../redux/gym/type';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import AccioButton from '../elements/Button';
import { InputBox } from '../shared/InputBox';
import Dropdown from '../shared/SearchableDropdown';
import { GymDropdown } from './GymDropdown';

type GymFilterPropsType = {
	tabId: string;
};

export const GymFilter: FC<GymFilterPropsType> = (
	props
) => {
	const dispatch = useAppDispatch();

	const {
		topicsByTabId,
		selectedTabId,
		appliedFiltersForTabId,
		questionsByTabId,
		companyTagsByTabId,
		revisionSheetsByTabId,
	} = useAppSelector((state) => state.gym);

	const isCurrentTab = props.tabId === selectedTabId;

	const topicsData = topicsByTabId?.[props.tabId];
	const appliedFiltersData =
		appliedFiltersForTabId?.[props.tabId];
	const companyTagsData = companyTagsByTabId?.[props.tabId];
	const questionObjData = questionsByTabId?.[props.tabId];
	const revisionSheetData =
		revisionSheetsByTabId?.[props.tabId];

	const selectedUnitId =
		appliedFiltersData?.selectedUnit?.unitId;
	const selectedTopics = appliedFiltersData?.topics ?? [];
	const selectedDifficulty =
		appliedFiltersData?.difficulties?.[0];
	const selectedStatus =
		appliedFiltersData?.questionAttemptStatus;
	const selectedQuestionType =
		appliedFiltersData?.questionType;

	const showQuestionTypeFilter = selectedTabId === 'coding';

	useEffect(() => {
		if (appliedFiltersData?.selectedUnit && isCurrentTab) {
			dispatch(
				fetchTopicFilters({ selectedTabId: props.tabId })
			).then(() => {
				dispatch(
					fetchQuestions({ selectedTabId: props.tabId })
				);
			});
		}
	}, [appliedFiltersData?.selectedUnit]);

	useEffect(() => {
		if (!topicsData && isCurrentTab)
			dispatch(
				fetchTopicFilters({ selectedTabId: props.tabId })
			);

		if (!questionObjData && isCurrentTab)
			dispatch(
				fetchQuestions({ selectedTabId: props.tabId })
			);

		if (!revisionSheetData && isCurrentTab)
			dispatch(
				fetchRevisionSheetByTab({
					selectedTabId: props.tabId,
				})
			);
	}, [selectedTabId]);

	const topics = useMemo(() => {
		const tempTopics = topicsByTabId[props.tabId];
		return (
			tempTopics?.map((topic) => {
				return {
					label: topic.name,
					value: topic.name,
				};
			}) ?? []
		);
	}, [topicsByTabId, props.tabId]);

	const onTopicSelect = useCallback(
		(selectedTopics: unknown) => {
			dispatch(
				GymActions.applyFilter({
					topics: selectedTopics as Array<string>,
				})
			);
			dispatch(
				fetchQuestions({ selectedTabId: props.tabId })
			);
		},
		[]
	);

	const onDifficultySelect = useCallback(
		(selectedDifficulty: unknown) => {
			dispatch(
				GymActions.applyFilter({
					difficulties: selectedDifficulty
						? [selectedDifficulty as DifficultyTypeEnum]
						: [],
				})
			);
			dispatch(
				fetchQuestions({ selectedTabId: props.tabId })
			);
		},
		[]
	);

	const onStatusSelect = useCallback(
		(selectedStatus: unknown) => {
			dispatch(
				GymActions.applyFilter({
					questionAttemptStatus: selectedStatus
						? (selectedStatus as AttemptStatusEnum)
						: undefined,
				})
			);
			dispatch(
				fetchQuestions({ selectedTabId: props.tabId })
			);
		},
		[]
	);

	const onQuestionTypeSelect = useCallback(
		(selectedQuestionType: unknown) => {
			dispatch(
				GymActions.applyFilter({
					questionType: selectedQuestionType
						? [selectedQuestionType as QuestionTypeEnum]
						: [],
				})
			);
			dispatch(
				fetchQuestions({ selectedTabId: props.tabId })
			);
		},
		[]
	);

	const onBookmarked = () => {
		dispatch(
			GymActions.applyFilter({
				shouldShowBookmarkedQuestions:
					!appliedFiltersData?.shouldShowBookmarkedQuestions,
			})
		);
		dispatch(
			fetchQuestions({ selectedTabId: props.tabId })
		);
	};

	const onJSOnly = () => {
		dispatch(
			GymActions.applyFilter({
				shouldShowOnlyJsQuestions:
					!appliedFiltersData?.shouldShowOnlyJsQuestions,
			})
		);
		dispatch(
			fetchQuestions({ selectedTabId: props.tabId })
		);
	};

	const onPythonOnly = () => {
		dispatch(
			GymActions.applyFilter({
				shouldShowOnlyPythonQuestions:
					!appliedFiltersData?.shouldShowOnlyPythonQuestions,
			})
		);
		dispatch(
			fetchQuestions({ selectedTabId: props.tabId })
		);
	};

	const resetAllFilters = () => {
		dispatch(GymActions.resetFilters());
		dispatch(
			fetchTopicFilters({ selectedTabId: props.tabId })
		);
		dispatch(
			fetchQuestions({ selectedTabId: props.tabId })
		);
	};

	return (
		<div className="gym-filters-container">
			<div className="gym-filters">
				<div className="gym-filter-head">
					<div className="gym-filter-heading">
						<h4>Filters</h4>
						<Button
							variant="text"
							className="gym-filters-reset"
							onClick={resetAllFilters}
						>
							Reset All
						</Button>
					</div>
				</div>
				<div
					className={`gym-filter-visibility gym-filter-visible'`}
				>
					{companyTagsData?.length ? (
						<div className="gym-filters-row">
							<GymCompanyTags
								companyTags={companyTagsData ?? []}
								selectedCompanyIds={
									appliedFiltersData?.companyIds ?? []
								}
								tabId={props.tabId}
							/>
						</div>
					) : null}
					<div className="gym-filters-row">
						<GymModuleFilter
							selectedUnitId={selectedUnitId}
						/>
						<GymDropdown
							label="Topics"
							shouldShowResetButton={
								!!selectedTopics.length
							}
							onSelect={onTopicSelect}
							showSearch
							isMulti
							value={selectedTopics}
							placeholder="Select topics"
							options={topics}
						/>
						<GymDropdown
							label="Difficulty"
							shouldShowResetButton={!!selectedDifficulty}
							onSelect={onDifficultySelect}
							value={selectedDifficulty as string}
							placeholder="Select difficulty"
							options={QuestionDifficultyOptions}
						/>
						<GymDropdown
							label="Problem status"
							shouldShowResetButton={!!selectedStatus}
							onSelect={onStatusSelect}
							value={selectedStatus as string}
							placeholder="Select status"
							options={QuestionStatusOptions}
						/>
						<GymDropdown
							label="Question type"
							shouldShowResetButton={
								!!selectedQuestionType?.length
							}
							onSelect={onQuestionTypeSelect}
							value={selectedQuestionType?.[0] as string}
							placeholder="Select type"
							options={QuestionTypeOptions}
						/>
					</div>
					<div
						className="gym-filters-row"
						style={{ marginBottom: '8px' }}
					>
						<GymSearchBar tabId={props.tabId} />
						{showQuestionTypeFilter ? (
							<>
								<AccioButton
									title="JS problems only"
									variant={
										appliedFiltersData?.shouldShowOnlyJsQuestions
											? 'primary'
											: 'outline'
									}
									icon={<DiJsBadge />}
									iconPosition="left"
									className="filter-button"
									action={onJSOnly}
								/>
								<AccioButton
									title="Python problems only"
									variant={
										appliedFiltersData?.shouldShowOnlyPythonQuestions
											? 'primary'
											: 'outline'
									}
									icon={<DiPython />}
									iconPosition="left"
									className="filter-button python-button"
									action={onPythonOnly}
								/>
							</>
						) : null}
						<AccioButton
							title="Bookmarked problems"
							variant={
								appliedFiltersData?.shouldShowBookmarkedQuestions
									? 'primary'
									: 'outline'
							}
							icon={<FiBookmark />}
							iconPosition="left"
							className="filter-button"
							action={onBookmarked}
						/>
					</div>
				</div>
				<GymRevisionSheet
					revisionSheetData={revisionSheetData ?? []}
					selectedRevisionSheetIds={
						appliedFiltersData?.moduleIds ?? []
					}
					tabId={props.tabId}
				/>
			</div>
		</div>
	);
};

const GymSearchBar: FC<{ tabId: string }> = ({ tabId }) => {
	const dispatch = useAppDispatch();

	const onSearch = useRef(
		debounce((searchText: string) => {
			dispatch(
				GymActions.applyFilter({
					query: searchText,
				})
			);
			dispatch(fetchQuestions({ selectedTabId: tabId }));
		}, 500)
	);

	return (
		<InputBox
			prefixIcon={<BsSearch />}
			placeholder="Search problems"
			onChange={(e) => {
				onSearch.current(e.target.value);
			}}
		/>
	);
};

const GymCompanyTags = ({
	companyTags,
	selectedCompanyIds,
	tabId,
}: {
	companyTags: Array<IdNameCountRecordType>;
	selectedCompanyIds: Array<string>;
	tabId: string;
}) => {
	const dispatch = useAppDispatch();

	const selectedCompaniesSet = useMemo(() => {
		return new Set(selectedCompanyIds);
	}, [selectedCompanyIds]);

	const optionsForDropdown = useMemo(() => {
		return companyTags.map((company) => {
			return {
				label: company.name,
				value: company.id,
			};
		});
	}, [companyTags]);

	const onCompanySelectFormList = (
		selectedCompanyId: string
	) => {
		const companyIds = selectedCompaniesSet.has(
			selectedCompanyId
		)
			? selectedCompanyIds.filter(
					(id) => id !== selectedCompanyId
			  )
			: [...selectedCompanyIds, selectedCompanyId];

		dispatch(
			GymActions.applyFilter({
				companyIds,
			})
		);
		dispatch(fetchQuestions({ selectedTabId: tabId }));
	};

	const onCompanyDeselect = (selectedCompanyId: string) => {
		dispatch(
			GymActions.applyFilter({
				companyIds: selectedCompanyIds.filter(
					(id) => id !== selectedCompanyId
				),
			})
		);
		dispatch(fetchQuestions({ selectedTabId: tabId }));
	};

	const onCompanySelectFromDropdown = (
		selectedCompanies: unknown
	) => {
		dispatch(
			GymActions.applyFilter({
				companyIds: selectedCompanies as Array<string>,
			})
		);
		dispatch(fetchQuestions({ selectedTabId: tabId }));
	};
	return (
		<div className="gym-company-filter">
			<div className="gym-company-tags-container">
				<div className="gym-row-label">
					Select companies:
				</div>
				<div className="gym-company-tag-list-container">
					<div className="gym-company-tag-list">
						{companyTags.map((company) => {
							return (
								<div
									onClick={() => {
										onCompanySelectFormList(company.id);
									}}
									className={`${
										selectedCompaniesSet.has(company.id)
											? 'gym-company-tag-list-item-selected'
											: ''
									} gym-company-tag-list-item`}
								>
									{company.name}
								</div>
							);
						})}
					</div>
				</div>
				<Dropdown
					value={selectedCompanyIds}
					showSearch
					placeholder="View list"
					options={optionsForDropdown}
					onSelect={onCompanySelectFromDropdown}
					outerContainerStyle={{
						width: '150px',
					}}
					isMulti
				/>
			</div>
			<div className="gym-company-tag-selected-list">
				{companyTags.map((company) => {
					const isSelected = selectedCompaniesSet.has(
						company.id
					);
					if (!isSelected) return null;
					return (
						<div
							onClick={() => {
								onCompanyDeselect(company.id);
							}}
							className="gym-company-tag-list-item"
						>
							{company.name}
							<div>{company.count}</div>
							<MdOutlineClose />
						</div>
					);
				})}
			</div>
		</div>
	);
};

const GymRevisionSheet = ({
	revisionSheetData,
	selectedRevisionSheetIds,
	tabId,
}: {
	revisionSheetData: Array<RevisionSheetDataType>;
	selectedRevisionSheetIds: Array<string>;
	tabId: string;
}) => {
	const dispatch = useAppDispatch();
	const scrollRef = useRef<HTMLDivElement>(null);

	const selectedRevisionSheetSet = useMemo(() => {
		return new Set(selectedRevisionSheetIds);
	}, [selectedRevisionSheetIds]);

	const onClick = (selectedSheetId?: string) => {
		dispatch(
			GymActions.applyFilter({
				moduleIds: selectedSheetId ? [selectedSheetId] : [],
			})
		);
		dispatch(fetchQuestions({ selectedTabId: tabId }));
	};

	const onLeft = () => {
		scrollRef.current?.scroll({
			left: 0,
			behavior: 'smooth',
		});
	};

	const onRight = () => {
		scrollRef.current?.scroll({
			left: scrollRef.current.scrollWidth,
			behavior: 'smooth',
		});
	};

	useEffect(() => {
		if (scrollRef.current) {
			if (
				scrollRef.current.scrollWidth !==
				scrollRef.current.clientWidth
			) {
				const leftArrow = document.querySelector(
					'.arrow-left'
				) as HTMLDivElement;
				const rightArrow = document.querySelector(
					'.arrow-right'
				) as HTMLDivElement;

				if (leftArrow) {
					leftArrow.style.display = 'block';
				}
				if (rightArrow) {
					rightArrow.style.display = 'block';
				}
			}
		}
	}, [scrollRef.current?.scrollWidth]);

	if (!revisionSheetData.length) return null;

	return (
		<div className="gym-revision-sheet-container">
			<div className="gym-row-label">
				Select revision sheets:
			</div>
			<div className="gym-revision-sheet">
				<div
					className="arrow arrow-left"
					onClick={onLeft}
					style={{ display: 'none' }}
				>
					<FiArrowLeft />
				</div>
				<div
					className="gym-revision-sheet-item-list"
					ref={scrollRef}
				>
					{revisionSheetData.map((sheet) => {
						const isSelected = selectedRevisionSheetSet.has(
							sheet.id
						);
						return (
							<div
								className={`gym-revision-sheet-item ${
									isSelected
										? 'gym-revision-sheet-item-selected'
										: ''
								}`}
								onClick={() => {
									onClick(
										isSelected ? undefined : sheet.id
									);
								}}
							>
								{sheet.name}
							</div>
						);
					})}
				</div>
				<div
					className="arrow arrow-right"
					onClick={onRight}
					style={{ display: 'none' }}
				>
					<FiArrowRight />
				</div>
			</div>
		</div>
	);
};

const GymModuleFilter: FC<{ selectedUnitId?: string }> = ({
	selectedUnitId,
}) => {
	const dispatch = useAppDispatch();

	const { courseList } = useAppSelector(
		(state) => state.user
	);

	const [selectedModuleIndex, setSelectedModuleIndex] =
		useState<string | undefined>();

	const modules = useMemo(() => {
		return courseList.map((course, index) => {
			return {
				value: index.toString(),
				label: course.description,
				id: course.id,
				unitId: course.unitId,
			};
		});
	}, [courseList]);

	useEffect(() => {
		if (!selectedUnitId) setSelectedModuleIndex(undefined);
	}, [selectedUnitId]);

	const onModuleSelect = (selectedModuleIndex: unknown) => {
		if (!selectedModuleIndex) {
			dispatch(
				GymActions.applyFilter({
					selectedUnit: {
						unitId: undefined,
						courseId: undefined,
					},
				})
			);
			setSelectedModuleIndex(undefined);
			return;
		}
		const index = Number(selectedModuleIndex);
		const module = modules[index];
		setSelectedModuleIndex(selectedModuleIndex as string);
		dispatch(
			GymActions.applyFilter({
				selectedUnit: {
					unitId: module.unitId,
					courseId: module.id,
				},
			})
		);
	};

	return (
		<GymDropdown
			label="Module"
			shouldShowResetButton={
				selectedModuleIndex !== undefined
			}
			onSelect={onModuleSelect}
			value={selectedModuleIndex}
			placeholder="Select module"
			options={modules}
			showSearch
		/>
	);
};
