import { Box, Input, type InputProps } from '@mui/joy';
import {
	useState,
	type ChangeEvent,
	type FC,
	type ReactNode,
} from 'react';
import { MdClose, MdDone } from 'react-icons/md';

type InputBoxPropsType = InputProps & {
	validation?: (
		text: string
	) => string | null | false | undefined;
	label?: string;
	prefixIcon?: ReactNode;
	showError?: boolean;
};

export const InputBox: FC<InputBoxPropsType> = (props) => {
	const [error, setError] = useState<string | undefined>();

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (props.showError) {
			const text = e.target.value;
			const validationError = props.validation?.(text);
			if (validationError) {
				setError(validationError);
			} else {
				setError('');
			}
		}
		props.onChange?.(e);
	};

	return (
		<Box className="input-field">
			{props.label ? (
				<label
					className={`input-label${error ? '-error' : ''}`}
				>
					{props.label}
				</label>
			) : null}
			<Input
				{...props}
				value={props.value}
				onChange={onChange}
				startDecorator={props.prefixIcon}
				endDecorator={
					typeof error === 'string' &&
					(error ? (
						<MdClose style={{ color: 'red' }} />
					) : (
						<MdDone style={{ color: 'green' }} />
					))
				}
			/>
			<p className="input-error">{error}</p>
		</Box>
	);
};
