import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchMentorAvailability } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';

function SessionTable() {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorAvailability } = MentorState;

	const [mentorAvlData, setMentorAvlData] =
		useState<any>(null);
	const [rowsData, setRowsData] = useState<any>(null);

	useEffect(() => {
		if (mentorAvailability === null) {
			dispatch(fetchMentorAvailability());
		} else {
			setMentorAvlData(mentorAvailability);
		}
	}, [mentorAvailability]);

	// useEffect(() => {
	//     if(mentorAvailability !== null) {
	//         setMentorAvlData(mentorAvailability)
	//     }
	// },[mentorAvailability])

	useEffect(() => {
		if (mentorAvlData !== null) {
			let rows: any = [];
			mentorAvlData.forEach((dayData: any) => {
				const { day, startTime, endTime } = dayData;
				rows = [...rows, { day, startTime, endTime }];
			});
			setRowsData([...rows]);
		}
	}, [mentorAvlData]);

	return (
		<div>
			<TableContainer component={Paper}>
				<Table
					sx={{ minWidth: 650 }}
					aria-label="simple table"
				>
					<TableHead>
						<TableRow>
							<TableCell align="left">Day</TableCell>
							<TableCell align="left">Start Time</TableCell>
							<TableCell align="left">End Time</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rowsData &&
							rowsData.map((row: any) => (
								<TableRow
									key={row.startTime}
									sx={{
										'&:last-child td, &:last-child th': {
											border: 0,
										},
									}}
								>
									<TableCell align="left">
										{row.day}
									</TableCell>
									<TableCell align="left">
										{row.startTime}
									</TableCell>
									<TableCell align="left">
										{row.endTime}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</div>
	);
}

export default SessionTable;
