import { Switch } from '@mui/joy';
import {
	FormControl,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { updateAcademicDetails } from '../../../../../redux/profile/profile.thunk';
import { AcademicDetailsInterface } from '../../../../../redux/profile/profile.type';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../redux/store';
import AccioButton from '../../../../elements/Button';
import CollegeAutoComplete from './CollegeAutoComplete';

interface InputProps {
	required: boolean;
	value: string | number | string[];
	name: string;
	label: string;
	placeholder: string;
	type:
		| 'text'
		| 'select'
		| 'combined'
		| 'multiSelect'
		| 'switch'
		| 'college';
	options?: Array<{
		value: string | number;
		label: string;
	}>;
}

export interface SectionProps {
	title: string;
	inputs: InputProps[];
}

export const AcademicForm = ({
	sections,
	type,
}: {
	sections: SectionProps[];
	type: string;
}) => {
	const {
		control,
		setValue,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<AcademicDetailsInterface>();

	const dispatch = useAppDispatch();
	const formRef = useRef<HTMLFormElement>(null);

	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);

	const isProfileLocked = () => {
		return personalDetails.data?.isProfileLocked ?? false;
	};

	const onSubmit = (data: AcademicDetailsInterface) => {
		console.log('AcademicDetailsData>>>', data);
		let newData = {
			...data,
			...{
				college: { id: data?.collegeId ?? '' },
				pgCollege: {
					id: data?.postGraduationCollegeId ?? '',
				},
			},
		};
		// newData.school = null;
		delete newData.postGraduationCollegeId;
		delete newData.collegeId;
		delete newData.graduationCollege;
		delete newData.postGraduationCollege;
		// REMOVE!!!
		delete newData.diploma;
		console.log('New Dataa>>>', newData);
		dispatch(updateAcademicDetails({ data: newData }));
	};

	const handleCollegeSelection = (
		event: any,
		newValue: any,
		type: 'UG' | 'PG'
	) => {
		console.log('in selection hiiii');
		if (type == 'UG') {
			setValue('collegeId', newValue?.id || '');
			console.log(type, newValue?.id);
		} else {
			setValue(
				'postGraduationCollegeId',
				newValue?.id || ''
			);
			console.log(type, newValue?.id);
		}
	};

	const renderInput = (input: InputProps) => {
		switch (input.type) {
			case 'text':
				return (
					<Controller
						disabled={isProfileLocked()}
						defaultValue={input.value.toString()}
						name={
							input.name as keyof AcademicDetailsInterface
						}
						control={control}
						render={({ field }) => (
							<>
								<p className="mui-custom-label">
									{input.label}
									{input.required ? (
										<span style={{ color: '#EF4444' }}>
											{' '}
											*
										</span>
									) : (
										''
									)}
								</p>

								<TextField
									required={input.required}
									{...field}
									placeholder={input.placeholder}
									error={
										!!errors[
											input.name as keyof AcademicDetailsInterface
										]
									}
									helperText={
										errors[
											input.name as keyof AcademicDetailsInterface
										]?.message
									}
									fullWidth
									sx={{
										flex: 1,
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
								/>
							</>
						)}
					/>
				);
			case 'select':
				return (
					<FormControl fullWidth>
						<p className="mui-custom-label">
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>
						<Controller
							disabled={isProfileLocked()}
							defaultValue={input.value.toString()}
							name={
								input.name as keyof AcademicDetailsInterface
							}
							control={control}
							render={({ field }) => (
								<Select
									displayEmpty
									placeholder={input.placeholder}
									{...field}
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
									renderValue={(selected) => {
										if (!selected) {
											return input.placeholder;
										}
										return input.options?.find(
											(item) => item.value === selected
										)?.label;
									}}
								>
									<MenuItem
										value=""
										disabled
									>
										{input.placeholder}
									</MenuItem>
									{input.options?.map((option) => (
										<MenuItem
											key={option.value}
											value={option.value}
										>
											{option.label}
										</MenuItem>
									))}
								</Select>
							)}
						/>
					</FormControl>
				);
			case 'switch':
				return (
					<div
						style={{
							width: '100%',
							display: 'flex',
							gap: '16px',
							justifyContent: 'flex-start',
							alignItems: 'center',
						}}
					>
						<p
							className="mui-custom-label"
							style={{ width: 'fit-content' }}
						>
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>
						<Controller
							disabled={isProfileLocked()}
							name={
								input.name as keyof AcademicDetailsInterface
							}
							control={control}
							defaultValue={''}
							render={({ field }) => (
								<Switch
									{...field}
									sx={{
										cursor: isProfileLocked()
											? 'not-allowed'
											: 'default',
									}}
								/>
							)}
						/>
					</div>
				);

			case 'college':
				return (
					<FormControl fullWidth>
						<p className="mui-custom-label">
							{input.label}
							{input.required ? (
								<span style={{ color: '#EF4444' }}> *</span>
							) : (
								''
							)}
						</p>
						<Controller
							disabled={isProfileLocked()}
							defaultValue={input.value.toString()}
							name={
								input.name as keyof AcademicDetailsInterface
							}
							control={control}
							render={({ field }) => (
								<CollegeAutoComplete
									{...field}
									defaultValue={input.value.toString()}
									typeCollege={
										input.name == 'graduationCollege'
											? 'UG'
											: 'PG'
									}
									placeHolder={
										'Type here your college name'
									}
									onSelection={handleCollegeSelection}
									disabled={isProfileLocked()}
								/>
							)}
						/>
					</FormControl>
				);
			default:
				return null;
		}
	};

	return (
		<form
			ref={formRef}
			onSubmit={handleSubmit(onSubmit)}
			className="main-form"
		>
			{sections.map(
				(section: SectionProps, sectionIndex: number) => (
					<div
						key={sectionIndex}
						className="section-wrapper"
					>
						{sectionIndex !== 0 ? (
							<h3 className="section-title">
								{section.title}
							</h3>
						) : null}
						<div
							className="input-flex-wrap"
							style={{ justifyContent: 'flex-start' }}
						>
							{section.inputs.map(
								(input: InputProps, inputIndex: number) => (
									<div
										className="input-wrapper"
										key={inputIndex}
										style={{
											width:
												input.name == 'diploma'
													? '100%'
													: '32%',
											marginTop:
												input.name == 'diploma'
													? '6px'
													: '',
										}}
									>
										{renderInput(input)}
									</div>
								)
							)}
						</div>
					</div>
				)
			)}

			<div className="save-form">
				<p className="text">
					Do you want to save changes ?
				</p>
				<AccioButton
					variant={
						isProfileLocked() ? 'disable' : 'primary'
					}
					disabled={isProfileLocked()}
					btnType="submit"
					title={'Save'}
				/>
			</div>
		</form>
	);
};
