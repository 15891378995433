import React from 'react';
import PropTypes from 'prop-types';
import TreeNode from './TreeNode';

const Tree = ({
	onSelect,
	selectedFolder,
	selectedFile,
	highlightFolder,
	handleNextFile,
	setMainNodes,
	handlePrevFile,
	nodes,
	rootNodes,
}) => {
	const getChildNodes = (node) => {
		if (!node.children) return [];
		return node.children.map((id) => nodes[id]);
	};

	const onToggle = (node) => {
		const s = { ...nodes };
		s[node.id].isOpen = !node.isOpen;
		setMainNodes(s);
	};

	const onNodeSelect = (node) => {
		console.log(node);
		onSelect(node);
		if (node.type === 'file') {
			handleNextFile(nodes[node.next]);
			handlePrevFile(nodes[node.prev]);
		}
	};
	console.log(rootNodes);
	return (
		<div style={{ padding: '5px', fontSize: 'initial' }}>
			{rootNodes.map((node, i) => (
				<TreeNode
					key={i}
					node={node}
					getChildNodes={getChildNodes}
					onToggle={onToggle}
					onNodeSelect={onNodeSelect}
					level={0}
					selectedFolder={selectedFolder}
					selectedFile={selectedFile}
					highlightFolder={highlightFolder}
				/>
			))}
		</div>
	);
};

Tree.propTypes = {
	onSelect: PropTypes.func.isRequired,
	selectedFolder: PropTypes.object,
	selectedFile: PropTypes.object,
	highlightFolder: PropTypes.string,
	handleNextFile: PropTypes.func.isRequired,
	setMainNodes: PropTypes.func.isRequired,
	handlePrevFile: PropTypes.func.isRequired,
	nodes: PropTypes.object.isRequired,
	rootNodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Tree;
