import * as React from 'react';
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import { Loader } from '@acciojob/loader';
import { useNavigate } from 'react-router-dom';
import { resetStates } from '../../redux/aiStudentAssessment/aiStudentAssessment.slice';
import { Box } from '@mui/material';

const TestCompletedModal: React.FC = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { isTestCompleted, report } = useAppSelector(
		(state) => state.aiStudentAssessment
	);
	return (
		<React.Fragment>
			<Modal
				aria-labelledby="modal-title"
				aria-describedby="modal-desc"
				open={isTestCompleted}
				onClose={() => {
					navigate('/');
					dispatch(resetStates());
				}}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<Sheet
					variant="outlined"
					sx={{
						maxWidth: 500,
						minWidth: 400,
						borderRadius: 'md',
						p: 3,
						boxShadow: 'lg',
					}}
				>
					<ModalClose
						variant="plain"
						sx={{ m: 1 }}
					/>
					<Typography
						component="h2"
						id="modal-title"
						level="h4"
						textColor="inherit"
						fontWeight="lg"
						mb={1}
					>
						Assessment Completed !!
					</Typography>
					<Typography
						id="modal-desc"
						textColor="text.tertiary"
					>
						Thanks for taking Revision Assessment
					</Typography>
					{report ? (
						<Box className="student-assessment-test-report">
							<Typography>
								Your Score : {report.scorePercentage}%
							</Typography>
							<Typography>
								Total Questions Asked :{' '}
								{report.totalQuestionsAsked}
							</Typography>
							<Typography>
								Total Correct Answers :{' '}
								{report.totalCorrectAnswers}
							</Typography>
							<Typography>
								Total Wrong Answers :{' '}
								{report.totalWrongAnswers}
							</Typography>
							<Typography>
								Total Unattempted Questions :{' '}
								{report.totalUnattemptedQuestions}
							</Typography>
							<Typography>Weak Areas :</Typography>
							<ul>
								{report.weakAreas.map(
									(area: string, index: number) => (
										<li key={area + index}>{area}</li>
									)
								)}
							</ul>
							<Typography>Strong Areas :</Typography>
							<ul>
								{report.strongAreas.map(
									(area: string, index: number) => (
										<li key={area + index}>{area}</li>
									)
								)}
							</ul>
						</Box>
					) : null}
					<Button
						sx={{ marginTop: '20px' }}
						onClick={() => navigate('/')}
						variant="outlined"
						color="primary"
					>
						Go to Home
					</Button>
				</Sheet>
			</Modal>
		</React.Fragment>
	);
};

export default TestCompletedModal;
