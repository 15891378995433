import {
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { number } from 'echarts';
import { services } from '../../apis';
import {
	ApplicationCardData,
	JobCardData,
	JobPortalDashboardData,
	MockScoreCardData,
	MockSessionDetailsData,
	OffCampusCardData,
} from '../../apis/JobPortalService';

export const fetchJobCards = createAsyncThunk(
	'jobPortal/fetchJobCards',
	async () => {
		const data =
			await services.jobPortalService.fetchJobCards();
		const jobCards = data.data;
		return jobCards;
	}
);

export const checkBlacklistStatus = createAsyncThunk(
	'jobPortal/checkBlackListStatus',
	async () => {
		const data =
			await services.jobPortalService.checkBlacklistStatus();
		console.log('blacklisted', data);
		return data;
	}
);

export const fetchApplicationCards = createAsyncThunk(
	'jobPortal/fetchApplicationCards',
	async () => {
		const data =
			await services.jobPortalService.fetchApplicationCards();
		const applicationCards = data.data;
		return applicationCards;
	}
);

export const fetchMockScoreCards = createAsyncThunk(
	'jobPortal/fetchMockScoreCards',
	async () => {
		const data =
			await services.jobPortalService.fetchMockScoreCards();
		const mockScoreCards = data.data;
		return mockScoreCards;
	}
);

export const fetchOffCampusCards = createAsyncThunk(
	'jobPortal/fetchOffCampusCards',
	async () => {
		const data =
			await services.jobPortalService.fetchOffCampusCards();
		const offCampusCards = data.data;
		return offCampusCards;
	}
);

export const offCampusCardsWithFilters = createAsyncThunk(
	'jobPortal/offCampusCardsWithFilters',
	async (input: any, { getState }) => {
		const data: any =
			await services.jobPortalService.getOffCampusCardsWithFilters(
				input
			);
		const offCampusCards = data.data;
		return offCampusCards;
	}
);

export const fetchJobPortalDashboard = createAsyncThunk(
	'jobPortal/fetchJobCardDashboard',
	async () => {
		const data =
			await services.jobPortalService.fetchJobPortalDashboard();
		const jobPortalDashboard = data.data;
		return jobPortalDashboard;
	}
);

export const fetchMockSessionDetails = createAsyncThunk(
	'jobPortal/fetchMockSessionDetails',
	async () => {
		const data =
			await services.jobPortalService.fetchMockSessionDetails();
		const mockSessionDetails = data.data;
		return mockSessionDetails;
	}
);

export const fetchDataWeeklyHighlited = createAsyncThunk(
	'jobPortal/fetchGetPlacedStudentsThisMonth',
	async () => {
		const data =
			await services.jobPortalService.fetchGetPlacedStudentsThisMonth();
		const weeklyData = data.data;
		return weeklyData;
	}
);

export const getMultiSelectValuesJobCard = createAsyncThunk(
	'jobPortal/getMultiSelectValuesJobCard',
	async () => {
		const data =
			await services.jobPortalService.getMultiSelectValuesJobCard();
		const multiselectvalues = data.data;

		console.log('multiselectvalues', multiselectvalues);
		return multiselectvalues;
	}
);

export interface StateProperties {
	loading: boolean;
	error: {
		state: boolean;
		message: string;
	};
}

export interface JobPortalState {
	jobCardsState: StateProperties & {
		jobCards: JobCardData[];
	};
	applicationCardsState: StateProperties & {
		applicationCards: ApplicationCardData[];
	};
	offCampusCardsState: StateProperties & {
		offCampusCards: OffCampusCardData[];
		offCampusTotalJobLength: number;
	};
	mockScoreCardsState: StateProperties & {
		mockScoreCards: MockScoreCardData[];
	};
	jobPortalDashboardState: StateProperties & {
		jobPortalDashboard: null | JobPortalDashboardData;
	};
	jobPortalMockState: StateProperties & {
		jobPortalMock: null | MockSessionDetailsData;
	};

	multiSelectState: {
		companyLocation: null | Array<string>;
		collegeDegree: null | Array<string>;
		skillRequired: null | Array<string>;
	};
	weeklyPlacedDataState: {
		placementdata: any;
		loading: boolean;
	};
	blackListStatus: any;
}

const initialState: JobPortalState = {
	jobCardsState: {
		loading: false,
		error: {
			state: false,
			message: '',
		},
		jobCards: [],
	},
	mockScoreCardsState: {
		loading: false,
		error: {
			state: false,
			message: '',
		},
		mockScoreCards: [],
	},
	applicationCardsState: {
		loading: false,
		error: {
			state: false,
			message: '',
		},
		applicationCards: [],
	},
	offCampusCardsState: {
		loading: false,
		error: {
			state: false,
			message: '',
		},
		offCampusCards: [],
		offCampusTotalJobLength: 0,
	},
	jobPortalDashboardState: {
		loading: false,
		error: {
			state: false,
			message: '',
		},
		jobPortalDashboard: null,
	},
	jobPortalMockState: {
		loading: false,
		error: {
			state: false,
			message: '',
		},
		jobPortalMock: null,
	},
	multiSelectState: {
		companyLocation: null,
		collegeDegree: null,
		skillRequired: null,
	},
	blackListStatus: null,
	weeklyPlacedDataState: {
		placementdata: null,
		loading: false,
	},
};

export const jobPortalSlice = createSlice({
	name: 'jobPortal',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchJobCards.pending, (state) => {
				state.jobCardsState.loading = true;
			})
			.addCase(fetchJobCards.fulfilled, (state, action) => {
				state.jobCardsState.loading = false;
				state.jobCardsState.jobCards = action.payload;
				console.log('state.job', action.payload);
				state.jobCardsState.error = {
					state: false,
					message: '',
				};
			})
			.addCase(fetchJobCards.rejected, (state) => {
				state.jobCardsState.loading = false;
				state.jobCardsState.error = {
					state: true,
					message: 'Error, Please try again later.',
				};
			});

		builder.addCase(
			checkBlacklistStatus.fulfilled,
			(state, action) => {
				state.blackListStatus = action.payload;
			}
		);

		builder
			.addCase(fetchMockScoreCards.pending, (state) => {
				state.mockScoreCardsState.loading = true;
			})
			.addCase(
				fetchMockScoreCards.fulfilled,
				(state, action) => {
					state.mockScoreCardsState.loading = false;
					state.mockScoreCardsState.mockScoreCards =
						action.payload;
					state.mockScoreCardsState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(fetchMockScoreCards.rejected, (state) => {
				state.mockScoreCardsState.loading = false;
				state.mockScoreCardsState.error = {
					state: true,
					message: 'Error, Please try again later.',
				};
			});

		builder
			.addCase(fetchApplicationCards.pending, (state) => {
				state.applicationCardsState.loading = true;
			})
			.addCase(
				fetchApplicationCards.fulfilled,
				(state, action) => {
					state.applicationCardsState.loading = false;
					state.applicationCardsState.applicationCards =
						action.payload;
					state.applicationCardsState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(fetchApplicationCards.rejected, (state) => {
				state.applicationCardsState.loading = false;
				state.applicationCardsState.error = {
					state: true,
					message: 'Error, Please try again later.',
				};
			});

		builder
			.addCase(fetchOffCampusCards.pending, (state) => {
				state.offCampusCardsState.loading = true;
			})
			.addCase(
				fetchOffCampusCards.fulfilled,
				(state, action) => {
					state.offCampusCardsState.loading = false;
					state.offCampusCardsState.offCampusCards =
						action.payload;
					state.offCampusCardsState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(fetchOffCampusCards.rejected, (state) => {
				state.offCampusCardsState.loading = false;
				state.offCampusCardsState.error = {
					state: true,
					message: 'Error, Please try again later.',
				};
			});

		builder
			.addCase(
				offCampusCardsWithFilters.pending,
				(state) => {
					state.offCampusCardsState.loading = true;
				}
			)
			.addCase(
				offCampusCardsWithFilters.fulfilled,
				(state, action) => {
					state.offCampusCardsState.loading = false;
					state.offCampusCardsState.offCampusCards =
						action.payload.jobs;
					state.offCampusCardsState.offCampusTotalJobLength =
						action.payload.length;
					state.offCampusCardsState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(
				offCampusCardsWithFilters.rejected,
				(state) => {
					state.offCampusCardsState.loading = false;
					state.offCampusCardsState.error = {
						state: true,
						message: 'Error, Please try again later.',
					};
				}
			);

		builder
			.addCase(
				getMultiSelectValuesJobCard.pending,
				(state) => {
					state.offCampusCardsState.loading = true;
				}
			)
			.addCase(
				getMultiSelectValuesJobCard.fulfilled,
				(state, action) => {
					state.offCampusCardsState.loading = false;
					state.multiSelectState = action.payload;
					state.offCampusCardsState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(
				getMultiSelectValuesJobCard.rejected,
				(state) => {
					state.offCampusCardsState.loading = false;
					state.offCampusCardsState.error = {
						state: true,
						message: 'Error, Please try again later.',
					};
				}
			);

		builder
			.addCase(fetchJobPortalDashboard.pending, (state) => {
				state.jobPortalDashboardState.loading = true;
			})
			.addCase(
				fetchJobPortalDashboard.fulfilled,
				(state, action) => {
					state.jobPortalDashboardState.loading = false;
					state.jobPortalDashboardState.jobPortalDashboard =
						action.payload;
					state.jobPortalDashboardState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(
				fetchJobPortalDashboard.rejected,
				(state) => {
					state.jobPortalDashboardState.loading = false;
					state.jobPortalDashboardState.error = {
						state: true,
						message: 'Error, Please try again later.',
					};
				}
			);

		builder
			.addCase(fetchMockSessionDetails.pending, (state) => {
				state.jobPortalMockState.loading = true;
			})
			.addCase(
				fetchMockSessionDetails.fulfilled,
				(state, action) => {
					state.jobPortalMockState.loading = false;
					state.jobPortalMockState.jobPortalMock =
						action.payload;
					state.jobPortalMockState.error = {
						state: false,
						message: '',
					};
				}
			)
			.addCase(
				fetchMockSessionDetails.rejected,
				(state) => {
					state.jobPortalMockState.loading = false;
					state.jobPortalMockState.error = {
						state: true,
						message: 'Error, Please try again later.',
					};
				}
			);

		builder
			.addCase(
				fetchDataWeeklyHighlited.pending,
				(state) => {
					state.weeklyPlacedDataState.loading = true;
				}
			)
			.addCase(
				fetchDataWeeklyHighlited.fulfilled,
				(state, action) => {
					state.weeklyPlacedDataState.loading = false;
					console.log('action.payload', action.payload);
					state.weeklyPlacedDataState.placementdata =
						action.payload;
				}
			)
			.addCase(
				fetchDataWeeklyHighlited.rejected,
				(state) => {
					state.weeklyPlacedDataState.loading = false;
				}
			);
	},
});

//Exporting Actions
// export const {} = jobPortalSlice.actions;

export default jobPortalSlice.reducer;
