export const truncateText = (
	str: string,
	length: number,
	showDots: boolean
) =>
	str?.length > length
		? `${str.substr(0, length - 1)} ...`
		: str;

export const splitNameIntoFirstNameAndLastName = (
	name: string
) => {
	const namesArray = name.split(' ');
	if (namesArray.length === 1) {
		return {
			firstName: namesArray[0],
			lastName: null,
		};
	}
	if (namesArray.length === 2) {
		return {
			firstName: namesArray[0],
			lastName: namesArray[1],
		};
	}
	return {
		firstName: namesArray[0],
		lastName: `${namesArray[1]} ${namesArray[2]}`,
	};
};

export const getInitialsForNamePlaceHolder = (
	name: string
) => {
	const nameSplitted = name.split(' ');
	let returnInitials = '';
	nameSplitted.map((nameIndex) => {
		returnInitials += nameIndex[0];
	});
	return returnInitials;
};

export const convertStringArrayToPascalCaseReadableFormat =
	(roles: string[]): string => {
		let resultString = '';
		roles.map((role, index) => {
			resultString +=
				role.charAt(0).toUpperCase() + role.slice(1);
			if (index !== roles.length - 1) {
				resultString += ', ';
			}
		});
		return resultString;
	};
