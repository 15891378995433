import React from 'react';
import {
	AcademicForm,
	SectionProps,
} from './Forms/AcademicForm';
import { useAppSelector } from '../../../../redux/store';

export const AcademicDetails: React.FC = () => {
	const { academicDetails } = useAppSelector(
		(state) => state.profile
	);
	const currentYear = new Date().getFullYear();
	const startYear = currentYear - 20;
	const endYear = currentYear + 5;

	const years = Array.from(
		{ length: endYear - startYear + 1 },
		(_, index) => {
			const year = startYear + index;
			return {
				value: year.toString(),
				label: year.toString(),
			};
		}
	);

	const infoArr: SectionProps[] = [
		{
			title: 'General Information',
			inputs: [
				{
					value: academicDetails?.data?.jobStatus ?? '',
					name: 'jobStatus',
					label: 'Employment Status',
					type: 'select',
					options: [
						{
							value: 'Working_Professional',
							label: 'Working Professional',
						},
						{ value: 'Unemployed', label: 'Unemployed' },
						{ value: 'Student', label: 'Student' },
					],
					placeholder: 'Enter Employment Status',
					required: true,
				},
			],
		},
		{
			title: 'School',
			inputs: [
				{
					value: academicDetails?.data?.schoolName ?? '',
					name: 'schoolName',
					label: 'School',
					type: 'text',
					placeholder: 'Enter School',
					required: true,
				},
				{
					value:
						academicDetails?.data?.class10Percentage ?? '',
					name: 'class10Percentage',
					label: 'Tenth Percentage',
					type: 'text',
					placeholder: 'Enter Tenth Percentage',
					required: true,
				},
			],
		},
		{
			title: 'Diploma/12th Class',
			inputs: [
				{
					value: '',
					name: 'diploma',
					label: 'Are you a diploma student ?',
					type: 'switch',
					placeholder: 'Enter Are you a diploma student ?',
					required: false,
				},
				{
					value:
						academicDetails?.data?.class12Percentage ?? '',
					name: 'class12Percentage',
					label: 'Diploma/Twelth Percentage',
					type: 'text',
					placeholder: 'Enter Diploma/Twelth Percentage',
					required: true,
				},
				{
					value:
						academicDetails?.data?.graduationYear ?? '',
					name: 'twelthPassingYear',
					label: 'Twelth Passing Year',
					type: 'select',
					options: years,
					placeholder: 'Enter Twelth Passing Year',
					required: true,
				},
			],
		},
		{
			title: 'Graduation',
			inputs: [
				{
					value:
						academicDetails?.data?.college?.fullName ?? '',
					name: 'graduationCollege',
					label: 'College/School',
					type: 'college',
					placeholder: 'Enter College/School',
					required: true,
				},
				{
					value:
						academicDetails?.data?.collegeDepartment ?? '',
					name: 'collegeDepartment',
					label: 'Department',
					type: 'select',
					options: [
						{
							value:
								'Computer Science/CSE/Other CS related branch',
							label:
								'Computer Science/CSE/Other CS related branch',
						},
						{
							value:
								'Electrical/Other electrical related branches',
							label:
								'Electrical/Other electrical related branches',
						},
						{
							value: 'Chemical Engineering',
							label: 'Chemical Engineering',
						},
						{ value: 'Civil', label: 'Civil' },
						{
							value:
								'Mechanical/Other mechanics related branch',
							label:
								'Mechanical/Other mechanics related branch',
						},
						{
							value: 'Aeronautical/Aerospace',
							label: 'Aeronautical/Aerospace',
						},
						{
							value: 'Biotech/Biomedical',
							label: 'Biotech/Biomedical',
						},
						{ value: 'Ceramic', label: 'Ceramic' },
						{
							value: 'Communications',
							label: 'Communications',
						},
						{ value: 'Physics', label: 'Physics' },
						{ value: 'Chemistry', label: 'Chemistry' },
						{ value: 'Mathematics', label: 'Mathematics' },
						{
							value: 'English/Literature',
							label: 'English/Literature',
						},
						{
							value: 'Metallurgical/Mining',
							label: 'Metallurgical/Mining',
						},
						{ value: 'Textile', label: 'Textile' },
						{ value: 'Others', label: 'Others' },
						{ value: 'Agriculture', label: 'Agriculture' },
						{ value: 'IT', label: 'IT' },
					],
					placeholder: 'Enter Department',
					required: true,
				},
				{
					value: academicDetails?.data?.collegeDegree ?? '',
					name: 'collegeDegree',
					label: 'Degree',
					type: 'select',
					options: [
						{ value: 'BTech./BE', label: 'BTech./BE' },
						{ value: 'BCA', label: 'BCA' },
						{ value: 'BBA', label: 'BBA' },
						{ value: 'BSc.', label: 'BSc.' },
						{ value: 'BCom', label: 'BCom' },
						{ value: 'BA', label: 'BA' },
						{ value: 'BE,d.', label: 'BE.d.' },
						{ value: 'LLB', label: 'LLB' },
						{ value: 'BArch.', label: 'BArch.' },
						{ value: 'BDes.', label: 'BDes.' },
						{ value: 'CA', label: 'CA' },
						{ value: 'BJMC', label: 'BJMC' },
						{ value: 'BHM', label: 'BHM' },
						{ value: 'Other', label: 'Other' },
					],
					placeholder: 'Enter Degree',
					required: true,
				},
				{
					value:
						academicDetails?.data?.graduationYear ?? '',
					name: 'graduationYear',
					label: 'Graduation Year',
					type: 'select',
					options: years,
					placeholder: 'Enter Graduation Year',
					required: true,
				},
				{
					value:
						academicDetails?.data?.graduationPercentage ??
						'',
					name: 'graduationPercentage',
					label: 'Graduation Percentage',
					type: 'text',
					placeholder: 'Enter Graduation Percentage',
					required: true,
				},
				{
					value:
						academicDetails?.data?.collegeBacklogs ?? '',
					name: 'collegeBacklogs',
					label: 'Backlogs',
					type: 'text',
					placeholder: 'Enter Backlogs',
					required: true,
				},
			],
		},
		{
			title: 'Post Graduation',
			inputs: [
				{
					value:
						academicDetails?.data?.pgCollege?.fullName ??
						'',
					name: 'pgCollege',
					label: 'Post Graduation College',
					type: 'college',
					options: [
						{ value: 'IIITD', label: 'IIITD' },
						{ value: 'IITD', label: 'IITD' },
					],
					placeholder: 'Enter College/School',
					required: true,
				},
				{
					value:
						academicDetails?.data?.pgCollegeDepartment ??
						'',
					name: 'pgCollegeDepartment',
					label: 'Department',
					type: 'select',
					options: [
						{
							value:
								'Computer Science/CSE/Other CS related branch',
							label:
								'Computer Science/CSE/Other CS related branch',
						},
						{
							value:
								'Electrical/Other electrical related branches',
							label:
								'Electrical/Other electrical related branches',
						},
						{
							value: 'Chemical Engineering',
							label: 'Chemical Engineering',
						},
						{ value: 'Civil', label: 'Civil' },
						{
							value:
								'Mechanical/Other mechanics related branch',
							label:
								'Mechanical/Other mechanics related branch',
						},
						{
							value: 'Aeronautical/Aerospace',
							label: 'Aeronautical/Aerospace',
						},
						{
							value: 'Biotech/Biomedical',
							label: 'Biotech/Biomedical',
						},
						{ value: 'Ceramic', label: 'Ceramic' },
						{
							value: 'Communications',
							label: 'Communications',
						},
						{ value: 'Physics', label: 'Physics' },
						{ value: 'Chemistry', label: 'Chemistry' },
						{ value: 'Mathematics', label: 'Mathematics' },
						{
							value: 'English/Literature',
							label: 'English/Literature',
						},
						{
							value: 'Metallurgical/Mining',
							label: 'Metallurgical/Mining',
						},
						{ value: 'Textile', label: 'Textile' },
						{ value: 'Others', label: 'Others' },
						{ value: 'Agriculture', label: 'Agriculture' },
						{ value: 'IT', label: 'IT' },
					],
					placeholder: 'Enter Department',
					required: true,
				},
				{
					value:
						academicDetails?.data?.pgCollegeDegree ?? '',
					name: 'pgCollegeDegree',
					label: 'Degree',
					type: 'select',
					options: [
						{ value: 'MCA', label: 'MCA' },
						{ value: 'MTech./ME', label: 'MTech./ME' },
						{ value: 'MBA', label: 'MBA' },
						{ value: 'MSc', label: 'MSc' },
						{ value: 'Other', label: 'Other' },
					],
					placeholder: 'Enter Degree',
					required: true,
				},
				{
					value:
						academicDetails?.data?.postGraduationYear ?? '',
					name: 'postGraduationYear',
					label: 'Post Graduation Year',
					type: 'select',
					options: [
						{ value: '2023', label: '2023' },
						{ value: '2024', label: '2024' },
					],
					placeholder: 'Enter Post Graduation Year',
					required: true,
				},
				{
					value:
						academicDetails?.data
							?.postGraduationPercentage ?? '',
					name: 'postGraduationPercentage',
					label: 'Post Graduation Percentage',
					type: 'text',
					placeholder: 'Enter Post Graduation Percentage',
					required: true,
				},
				// {
				// 	value:
				// 		academicDetails?.data?.collegeBacklogs ?? '',
				// 	name: 'postGraduationBacklogs',
				// 	label: 'Backlogs',
				// 	type: 'text',
				// 	placeholder: 'Enter Backlogs',
				// 	required: true,

				// },
			],
		},
	];

	return (
		<div className="personal-details-wrapper">
			<AcademicForm
				sections={infoArr}
				type={'academic'}
			/>
		</div>
	);
};
