import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineCodeSandbox } from 'react-icons/ai';
import {
	BiChevronDown,
	BiChevronUp,
	BiLinkAlt,
} from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../redux/store';

export const ProjectsCard = () => {
	const navigate = useNavigate();
	const convertDate = (input: string) => {
		return moment(input).format('DD MMMM YYYY');
	};

	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	const [projects, setProjects] = useState(
		performanceData.data?.projects ?? []
	);

	const [visibleIndex, setVisibleIndex] = useState(0);
	const listRef = useRef<HTMLDivElement>(null);

	const handleChevronDownClick = () => {
		if (visibleIndex < projects.length - 1) {
			setVisibleIndex((prevIndex) => prevIndex + 1);
		}
	};

	const handleChevronUpClick = () => {
		if (visibleIndex > 0) {
			setVisibleIndex((prevIndex) => prevIndex - 1);
		}
	};

	useEffect(() => {
		if (listRef.current) {
			const targetScroll = visibleIndex * 50; // Assuming each list item has a height of 50px
			listRef.current.scrollTo({
				top: targetScroll,
				behavior: 'smooth',
			});
		}
	}, [visibleIndex]);

	return (
		<div className="profile-card projects-card">
			<div className="top-flex">
				<div className="headings-flex">
					<AiOutlineCodeSandbox
						style={{ color: '#2F80ED', fontSize: '1.5rem' }}
					/>
					<div className="heading-vertical">
						<h3 className="heading">Projects</h3>
						<p className="random-text">
							Projects, Contests and Module tests you've
							worked on.
						</p>
					</div>
				</div>
				{projects.length > 0 ? (
					<div className="btn-flex">
						{visibleIndex > 0 && (
							<button
								onClick={handleChevronUpClick}
								className="btn-chevron"
							>
								<BiChevronUp
									style={{
										color: '#2F80ED',
										fontSize: '2rem',
									}}
								/>
							</button>
						)}
						<button
							onClick={handleChevronDownClick}
							className="btn-chevron"
						>
							<BiChevronDown
								style={{
									color: '#2F80ED',
									fontSize: '2rem',
								}}
							/>
						</button>
					</div>
				) : null}
			</div>

			<div
				ref={listRef}
				className="projects-div"
			>
				{projects.length > 0 ? (
					projects.map((item, index) => (
						<div
							key={index}
							className="project-main-card"
							onClick={() =>
								navigate(
									`/start-project?project=${item.project.id}`
								)
							}
						>
							<div className="icon-div">
								<BiLinkAlt
									style={{
										color: '#101828',
										fontSize: '1.25rem',
									}}
								/>
							</div>
							<div className="info-div">
								<p className="heading clip-text">
									{item.project.name}
								</p>
								<p className="desc clip-text">
									{item.project.description ?? ''}
								</p>
							</div>
							<div className="chip">
								{item.project.difficulty}
							</div>
							<div className="date-info">
								<p className="date-text">Completed On:</p>
								<p className="date">
									{convertDate(item.updatedAt)}
								</p>
							</div>
						</div>
					))
				) : (
					<div
						className="project-main-card"
						style={{
							justifyContent: 'flex-start',
							gap: 16,
						}}
					>
						<div className="icon-div">
							<BiLinkAlt
								style={{
									color: '#101828',
									fontSize: '1.25rem',
								}}
							/>
						</div>
						<div
							className="info-div"
							style={{ maxWidth: '100%' }}
						>
							<p className="heading clip-text">
								You Have No Projects
							</p>
							<p className="desc clip-text">
								Please Complete More Projects For Them To
								Appear Here.
							</p>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
