import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class ContestService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchAllContest() {
		// const url = ` https://accio-release-3-dot-acciojob-prod.el.r.appspot.com/contests/all`;
		const url = `${this.endpoint}/contests/all`;
		// console.log('FETCH CONTEST', body);
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchContest(body: any) {
		const url = `${this.endpoint}/contests/one`;
		console.log('FETCH CONTEST', body);
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchQuestions(body: any) {
		const url = `${this.endpoint}/contests/questions-after-contest`;
		// const url = `${this.endpoint}/contests/questions/all`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchProctingInformation(body: any) {
		const url = `${this.endpoint}/contests/proctor`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async submitContest(body: any) {
		const url = `${this.endpoint}/contests/submit`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchContestLeaderboard(contestDetails: any) {
		const url = `${this.endpoint}/contests/leaderboard/next`;
		// console.log('FETCH CONTEST', body);
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: contestDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchTopContestLeaderboard(contestDetails: any) {
		const url = `${this.endpoint}/contests/leaderboard/all`;
		// console.log('FETCH CONTEST', body);
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: contestDetails,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
