import moment from 'moment';
import { services } from '../../../apis';
import {
	notifyError,
	notifySuccess,
} from '../../../components/notification';
import store from '../../../redux/store';

export enum SessionPlatform {
	INTERNAL_ZOOM = 'INTERNAL_ZOOM',
	EXTERNAL_GMEET = 'EXTERNAL_GMEET',
}

export const getParams = (
	startDate: string,
	endDate: string,
	sessionType: string,
	params: string
): string => {
	const start = new Date(startDate);
	const end = new Date(endDate);
	const starting = `${start.getFullYear()}-${
		start.getMonth() + 1
	}-${start.getDate()} 00:00:00.000`;
	const ending = `${end.getFullYear()}-${
		end.getMonth() + 1
	}-${end.getDate()} 00:00:00.000`;

	if (
		sessionType !== null &&
		startDate !== null &&
		endDate !== null
	) {
		params += `&sessionTypeId=${sessionType}`;
		params += `&startDate=${starting}&endDate=${ending}`;
	} else if (
		sessionType !== null &&
		sessionType.length > 0 &&
		(startDate === null || endDate === null)
	) {
		params += `&sessionTypeId=${sessionType}`;
	} else if (
		sessionType === null &&
		startDate !== null &&
		endDate !== null
	) {
		params += `&startDate=${starting}&endDate=${ending}`;
	}
	const state: any = store.getState();
	params += `&page=${state.mentor.sessionsPage}`;
	return params;
};

interface timeLeft {
	hours: number;
	minutes: number;
	seconds: number;
}

export const calculateTimeLeft = (
	sessionStartTime: string
): timeLeft => {
	//@ts-ignore
	const difference =
		//@ts-ignore
		new Date(sessionStartTime) -
		//@ts-ignore
		new Date(Date.now());

	let timeLeft = {
		hours: 0,
		minutes: 0,
		seconds: 0,
	};

	if (difference > 0) {
		timeLeft = {
			hours: Math.floor(
				(difference / (1000 * 60 * 60)) % 24
			),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60),
		};
	}
	return timeLeft;
};

export const joinNow = async (
	latestSession: any,
	user: any
) => {
	const state = store.getState();
	const typeUser =
		user.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
	const userType = typeUser === 'MENTOR' ? 1 : 11;
	// console.log(room, 'sessionkey', typeof room);
	// const role = userType==1?1:0;
	const role = 1;
	const userId = user.id;
	const room = latestSession.roomId ?? latestSession.room;
	const data =
		await services.sessionService.fetchZoomVideoLink(
			room,
			user.id,
			userType,
			{
				sessionName: room,
				role: role,
				sessionKey: room,
				userIdentity: user.email,
			}
		);

	if (data.joinLink) {
		window.open(data.joinLink, '_blank');
	} else {
		window.open(
			`https://meeting.acciojob.com/video?topic=${room}&role=${role}&sessionKey=${room}&userIdentity=${user.email}&signature=${data}&name=${user.email}`,
			'_blank'
		);
	}
};

export const handleBtnsDisplay = (
	setShowJoinNow: any,
	setShowGmeet: any,
	setShowDidntHappen: any,
	latestSession: any,
	tab: string
) => {
	const isDuringSession = checkIsDuringSession(
		tab === 'UPCOMING_ONGOING'
			? latestSession.sessionDate
			: latestSession.sessionStartTime,
		latestSession.sessionDuration
	);

	if (latestSession && latestSession.platform) {
		if (
			latestSession.platform ===
			SessionPlatform.EXTERNAL_GMEET
		) {
			setShowJoinNow(false);
			setShowGmeet(false);
			setShowDidntHappen(true);
		} else {
			console.log('isDuringSession', isDuringSession);
			setShowGmeet(isDuringSession);
			setShowDidntHappen(isDuringSession);
			const isSessionTime = checkJoiningTime(
				tab === 'UPCOMING_ONGOING'
					? latestSession.sessionDate
					: latestSession.sessionStartTime,
				latestSession.sessionDuration
			);
			setShowJoinNow(isSessionTime);
		}

		const hasNoSessionReason =
			latestSession.noSessionReason &&
			latestSession.noSessionReason !== null;
		if (hasNoSessionReason) {
			setShowGmeet(false);
			setShowDidntHappen(false);
			setShowJoinNow(false);
		}
		const isNoShowByMentorOrStudent =
			checkNoShowByMentorOrStudent(latestSession, tab);
		if (isDuringSession && isNoShowByMentorOrStudent) {
			setShowGmeet(false);
			setShowDidntHappen(false);
			setShowJoinNow(false);
		}
	}
};

export const checkNoShowByMentorOrStudent = (
	latestSession: any,
	tab: string
): boolean => {
	if (
		latestSession.platform === SessionPlatform.INTERNAL_ZOOM
	) {
		const is20MinIntoSession: boolean =
			new Date(Date.now()) >=
			moment(
				tab === 'UPCOMING_ONGOING'
					? latestSession.sessionDate
					: latestSession.sessionStartTime
			)
				.add(20, 'm')
				.toDate();
		if (
			is20MinIntoSession &&
			(!latestSession.mentorJoinedWithinThresholdTime ||
				!latestSession.studentJoinedWithinThresholdTime)
		) {
			return true;
		}
	}
	return false;
};

export const checkJoiningTime = (
	sessionDate: Date,
	sessionDuration: string
) => {
	const lessThanTenminToStart = moment(sessionDate)
		.subtract(10, 'm')
		.toDate();
	const newSessionDuration = (
		+sessionDuration + 10
	).toString();
	const isDuringSession = checkIsDuringSession(
		lessThanTenminToStart,
		newSessionDuration
	);
	return isDuringSession;
};

export const checkIsDuringSession = (
	sessionDate: Date,
	sessionDuration: string
) => {
	const startingTime = new Date(sessionDate);
	const endingTime = moment(sessionDate)
		.add(sessionDuration, 'm')
		.toDate();
	return (
		endingTime >= new Date(Date.now()) &&
		startingTime <= new Date(Date.now())
	);
};

export const handleBtnsDisplayBasedOnTimer = (
	timeLeft: timeLeft,
	setShowJoinNow: any,
	setRefresh: any,
	setSessionStarted: any
) => {
	const { hours, minutes, seconds } = timeLeft;

	if (hours === 0 && minutes === 10 && seconds === 0) {
		setShowJoinNow(true);
		setRefresh(true);
	}

	if (hours === 0 && minutes === 0 && seconds === 1) {
		setRefresh(true);
	}

	if (hours === 0 && minutes === 0 && seconds === 0) {
		setSessionStarted(true);
	}

	return timeLeft;
};

export const handleSessionDidntHappen = async (
	sessionId: string,
	reason: string,
	setShowDidntHappen: any,
	setShowJoinNow: any,
	setShowGmeet: any,
	setOpen: any
) => {
	const body = { noSessionReason: reason };
	const data = await services.mentorService.markDidntShow(
		sessionId,
		body
	);
	if (data && data.statusCode) notifyError(data.message);
	else {
		notifySuccess(data.message);
		setShowDidntHappen(false);
		setShowJoinNow(false);
		setShowGmeet(false);
	}
	setOpen(false);
};

export const checkShowFeedback = (
	sessionData: any,
	tab: any
): boolean => {
	return (
		checkIsDuringSession(
			tab === 'UPCOMING_ONGOING'
				? sessionData.sessionDate
				: sessionData.sessionStartTime,
			sessionData.sessionDuration
		) &&
		sessionData.noSessionReason === null &&
		!(
			sessionData.mentorFeedback['createdAt'] ||
			sessionData.mentorFeedback['created_at']
		)
	);
};

export const getRecommendedQns = async (
	sessionId: string,
	setHasRecommendedQns: any
) => {
	const response =
		await services.mentorService.fetchRecommendedQuestions(
			sessionId
		);
	if (response && response.statusCode)
		notifyError(response.message);
	else {
		if (response.length > 0) {
			setHasRecommendedQns(true);
		} else setHasRecommendedQns(false);
	}
};

export const handleConfirmGmeet = async (
	sessionId: string,
	setOpenGmeet: any,
	setShowJoinNow: any,
	setShowGmeet: any
) => {
	const data =
		await services.mentorService.markShiftedToGmeet(
			sessionId
		);
	if (data && data.statusCode) notifyError(data.massage);
	else {
		notifySuccess(data.message);
		setOpenGmeet(false);
		setShowJoinNow(false);
		setShowGmeet(false);
	}
};

export const calculateTimeDifference = (
	endingTime: any,
	feedbackTime: any
): any => {
	let startingYear = new Date(endingTime).getFullYear();
	let startingMonth = new Date(endingTime).getMonth() + 1;
	let startingDay = new Date(endingTime).getDate() + 2;

	let endingYear = new Date(feedbackTime).getFullYear();
	let endingMonth = new Date(feedbackTime).getMonth() + 1;
	let endingDay = new Date(feedbackTime).getDate() + 2;

	const difference =
		+new Date(`${endingYear}-${endingMonth}-${endingDay}`) -
		+new Date(
			`${startingYear}-${startingMonth}-${startingDay}`
		);

	let timeLeft = {};

	if (difference > 0) {
		timeLeft = {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor(
				(difference / (1000 * 60 * 60)) % 24
			),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60),
		};
	} else {
		return 'IN_TIME';
	}

	return timeLeft;
};

export const checkIntime = (sessionData: any): any => {
	if (sessionData.mentorFeedbackFilled) {
		const feedbackTime =
			Object.keys(sessionData.mentorFeedback).indexOf(
				'createdAt'
			) > -1
				? sessionData.mentorFeedback.createdAt
				: sessionData.mentorFeedback.created_at;
		const startingTime = sessionData.sessionDate;
		const sessionDuration = sessionData.sessionDuration;
		const endingTime = moment(startingTime)
			.add(sessionDuration, 'minutes')
			.format('LLL');
		const timeDiff = calculateTimeDifference(
			new Date(endingTime),
			new Date(feedbackTime)
		);

		if (timeDiff === 'IN_TIME') return 'IN_TIME';

		const { days, hours, minutes } = timeDiff;

		let timeToDisplay = '';
		if (days > 0) timeToDisplay += days + 'D ';
		if (hours > 0) timeToDisplay += hours + 'H ';
		if (minutes > 0) timeToDisplay += minutes + 'M ';
		return timeToDisplay;
	}
};

export const removeDuplicatesById = (arr: any[]) => {
	const seenIds = new Set();
	const uniqueArr = [];

	for (let i = 0; i < arr.length; i++) {
		const element = arr[i];
		if (!seenIds.has(element.sessionId)) {
			seenIds.add(element.sessionId);
			uniqueArr.push(element);
		}
	}

	return uniqueArr;
};

export const onPropsChange = (
	sessionType: string,
	startDate: string,
	endDate: string,
	currentTab: string
) => {
	const state: any = store.getState();
	console.log('sessionType', sessionType);
	if (currentTab !== state.mentor.prevTab) {
		return 'TAB_CHANGE';
	} else if (
		sessionType !== null &&
		sessionType !== state.mentor.prevSessionId
	) {
		return 'SESSION_CHANGE';
	} else if (
		startDate !== null &&
		endDate !== null &&
		(startDate !== state.mentor.prevStartTime ||
			endDate !== state.mentor.prevEndTime)
	) {
		return 'DATE_CHANGE';
	}
	return 'NO_CHANGE';
};
