import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { FiArrowRight } from 'react-icons/fi';
import AccioButton from '../elements/Button';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { isMainCourseUser } from '../../utils/events';

interface LatestModuleProps {
	latestModuleData: any;
	moduleName: string;
}

const LatestModule = ({
	latestModuleData,
	moduleName,
}: LatestModuleProps) => {
	const navigate = useNavigate();

	const UserState = useSelector((state: any) => state.user);
	const { userRole } = UserState;

	return (
		<Box className={'latest-module'}>
			<div className={'module-marking'}>
				<strong>Your Latest Class :</strong> {moduleName}
			</div>
			<div className={'latest-module-content-container'}>
				{latestModuleData !== null ? (
					<>
						<div className={'latest-module-content'}>
							<div className={'module-data'}>
								<div className={'date'}>
									<p>
										{moment(latestModuleData.time).format(
											'MMM'
										)}
									</p>
									<h5>
										{moment(latestModuleData.time).format(
											'DD'
										)}
									</h5>
								</div>
								<div className={'module-info'}>
									<h3>{latestModuleData.module.name}</h3>
									<p>
										{latestModuleData.soleved} /{' '}
										{latestModuleData.totalQuestions} Solved
									</p>
								</div>
							</div>
							<div
								event-analytics={
									isMainCourseUser() &&
									!latestModuleData.completed
										? '0402f6f0-a010-4bfe-955f-867a59f5ba43'
										: ''
								}
							>
								<AccioButton
									title={`${
										latestModuleData.completed
											? 'Solved'
											: 'Start Solving'
									}`}
									icon={
										latestModuleData.completed ? undefined : (
											<FiArrowRight />
										)
									}
									action={() => navigate('/modules')}
									variant={
										latestModuleData.completed
											? 'solved'
											: 'primary'
									}
								/>
							</div>
						</div>
						<div className={'progress-bar'}>
							<span
								style={{
									width: `${
										(latestModuleData.soleved /
											latestModuleData.totalQuestions) *
										100
									}%`,
								}}
							></span>
						</div>
					</>
				) : null}
			</div>
		</Box>
	);
};

export default LatestModule;
