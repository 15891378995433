import { Box } from '@mui/material';
import React, {
	FC,
	useMemo,
	useRef,
	useState,
} from 'react';
import { BsSearch } from 'react-icons/bs';
import {
	IoIosArrowDown,
	IoIosArrowUp,
	IoMdCheckmark,
} from 'react-icons/io';
import { RxCross2 } from 'react-icons/rx';
import { useOnClickOutside } from '../../customHooks/useOnClickOutside';

export type SearchableDropdownOptionType = Record<
	string,
	any
> & {
	value: string;
	label: string;
};

interface DropdownPropsInterface {
	options: Array<SearchableDropdownOptionType>;
	isMulti?: boolean;
	notFoundText?: string;
	placeholder?: string;
	outerContainerStyle?: React.CSSProperties;
	isDisabled?: boolean;
	showSearch?: boolean;
	OptionComponent?: FC<
		SearchableDropdownOptionType & { isSelected: boolean }
	>;
	onSelect: (values: string | Array<string> | null) => void;
	value?: string | Array<string>;
	shouldDeselectOnReClick?: boolean;
}

const Dropdown: FC<DropdownPropsInterface> = ({
	options,
	value,
	isMulti,
	placeholder,
	notFoundText = 'No results found',
	outerContainerStyle,
	isDisabled,
	showSearch,
	OptionComponent,
	shouldDeselectOnReClick = true,
	onSelect,
}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [searchQuery, setSearchQuery] = useState('');

	const containerRef = useRef<HTMLDivElement>(null);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const selectedOptionsMap = useMemo(() => {
		if (!options.length || value === undefined) {
			return {};
		}
		if (typeof value === 'string') {
			return {
				[value]: true,
			};
		} else {
			const selectedOption: Record<string, true> = {};
			if (value?.length) {
				value.forEach((value) => {
					selectedOption[value] = true;
				});
			}
			return selectedOption;
		}
	}, [value]);

	const handleOptionSelect = (
		option: SearchableDropdownOptionType
	) => {
		if (!isMulti) {
			if (selectedOptionsMap[option.value]) {
				shouldDeselectOnReClick && onSelect(null);
			} else {
				onSelect(option.value);
			}
			return;
		}
		let options = [...(value as Array<string>)];
		if (selectedOptionsMap[option.value]) {
			options = options.filter(
				(entry) => entry !== option.value
			);
		} else {
			options.push(option.value);
		}
		onSelect(options);
	};

	const handleSearchInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setSearchQuery(event.target.value);
	};

	const filteredOptions = useMemo(() => {
		return options.filter((option) =>
			option.label
				?.toLowerCase()
				.includes(searchQuery?.toLowerCase())
		);
	}, [options, searchQuery]);

	useOnClickOutside(containerRef, () => setIsOpen(false));

	return (
		<Box
			className="dropdown-container"
			ref={containerRef}
			style={outerContainerStyle}
		>
			<Box
				className={`dropdown-button-container ${
					isDisabled ? 'dropdown-button-disabled' : ''
				}`}
				onClick={isDisabled ? undefined : toggleDropdown}
			>
				<Box className="dropdown-button-label">
					{value?.length ? (
						<>
							{filteredOptions.map((option, index) => {
								if (!selectedOptionsMap[option.value]) {
									return undefined;
								}
								if (OptionComponent) {
									return (
										<div style={{ marginRight: '5px' }}>
											<OptionComponent
												{...option}
												isSelected={true}
												key={option.value}
											/>
										</div>
									);
								}
								return (
									<Box
										className={`dropdown-button-text dropdown-button-selected-label ${
											isMulti ? 'truncate' : ''
										}`}
										key={option.value}
										style={{
											marginRight: '5px',
										}}
									>
										{option.label}
									</Box>
								);
							})}
						</>
					) : (
						<Box
							className={`dropdown-button-text ${
								isMulti ? 'truncate' : ''
							}`}
						>
							{placeholder}
						</Box>
					)}
				</Box>
				<Box className="dropdown-button-icon">
					{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
				</Box>
			</Box>
			{isOpen && (
				<Box className="dropdown-options-container">
					{showSearch ? (
						<Box className="dropdown-option-search-bar-container">
							<Box className="dropdown-options-search-bar">
								<span className="dropdown-options-search-icon">
									<BsSearch />
								</span>
								<input
									type="text"
									placeholder="Search..."
									value={searchQuery}
									onChange={handleSearchInputChange}
								/>
								{searchQuery.length > 0 && (
									<span
										className="dropdown-options-search-reset-button"
										onClick={() => {
											setSearchQuery('');
										}}
									>
										<RxCross2 />
									</span>
								)}
							</Box>
						</Box>
					) : null}
					{filteredOptions.length ? (
						<ul
							className={`dropdown-option-container ${
								isMulti
									? 'dropdown-option-multiselect-container'
									: ''
							}`}
						>
							{filteredOptions.map((option, ind) => {
								const isSelected =
									selectedOptionsMap[option.value];
								return (
									<li
										key={ind}
										className={`
											dropdown-option ${
												isSelected
													? 'dropdown-option-selected'
													: ''
											}
										`}
										onClick={() => {
											handleOptionSelect(option);
										}}
									>
										{OptionComponent ? (
											<OptionComponent
												{...option}
												isSelected={isSelected}
												key={ind}
											/>
										) : (
											<Box
												className={'dropdown-option-label'}
											>
												{option.label}
											</Box>
										)}
										{isSelected ? (
											<Box className="dropdown-option-selected-arrow">
												<IoMdCheckmark />
											</Box>
										) : null}
									</li>
								);
							})}
						</ul>
					) : (
						<p className="dropdown-search-not-found">
							{notFoundText}
						</p>
					)}
				</Box>
			)}
		</Box>
	);
};

export default Dropdown;
