import {
	PayloadAction,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { services } from '../../apis';
import { notifySuccess } from '../../components/notification';
import {
	GeneralProfileFormFields,
	PlacementProfileFormFields,
} from '../../pages/profile/student_personal_details';

export const fetchUserReport = createAsyncThunk(
	'users/getReport',
	async () => {
		try {
			return await services.userService.getReport();
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchUserTrack = createAsyncThunk(
	'users/getTrack',
	async () => {
		try {
			return await services.userService.getTrackDetails();
		} catch (error) {
			console.log(error);
		}
	}
);

export const addCallbackRequest = createAsyncThunk(
	'users/callbackRequest',
	async (data: any) => {
		return await services.userService.callbackRequest(
			data.callbackRequest,
			data.callbackRequestTime
		);
	}
);

export const userPauseStatusThunk = createAsyncThunk(
	'users/userPauseStatusThunk',
	async () => {
		return await services.userService.getProfilePauseStatus();
	}
);

export type PauseType = 'CURRENT' | 'NEXT';

export const raiseCoursePauseThunk = createAsyncThunk(
	'users/raiseCoursePause',
	async (type: PauseType, dispatch) => {
		console.log('pause Month', type);
		const body = {
			coursePauseMonth: type,
		};
		const res =
			await services.userService.raiseCoursePauseRequest(
				body
			);
		const newPauseStatus =
			await services.userService.getProfilePauseStatus();
		return { ...res, ...newPauseStatus };
	}
);

const checkRepeatition = (data: any[]) => {
	console.log('This is the data ', data);
	data = data.filter(
		(value, index, self) =>
			index === self.findIndex((t) => t.id === value.id)
	);

	console.log('From Set', data);
	return data;
};

export const fetchCourseList = createAsyncThunk(
	'users/enrollment',
	async () => {
		try {
			const res: any = {};
			let { data } =
				await services.courseService.getAllCourseData();
			data = checkRepeatition(data);
			const latestCourseID = data[0].id;
			const latestCourseName = data[0].title;
			localStorage.setItem(
				'selected-course-token',
				latestCourseID
			);
			res.latestCourseID = latestCourseID;
			res.latestCourseName = latestCourseName;
			res.courseList = data;
			console.log('USER STATE RESPONSE -> ', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchAllTagsList = createAsyncThunk(
	'users/topic-tags',
	async () => {
		try {
			const res = await services.courseService.getAllTags();
			console.log('USER STATE RESPONSE -> ', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchLiveDoubtsSlots = createAsyncThunk(
	'users/live-doubt-slots',
	async () => {
		try {
			const res =
				await services.courseService.getLiveDoubtsSlots();
			console.log(
				'USER STATE LIVE DOUBTS RESPONSE -> ',
				res
			);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const checkIfCommunityEnabled = createAsyncThunk(
	'users/check-if-community-enabled',
	async () => {
		try {
			const res =
				await services.userService.checkIfCommunityEnabled();
			console.log('COMMUNITY CHECK RESPONSE -> ', res);
			return res;
		} catch (err) {
			console.log(err);
		}
	}
);

export const fetchChartData = createAsyncThunk(
	'users/monthy-progress',
	async () => {
		try {
			const res =
				await services.userService.getMonthyChartData();
			console.log('USER CHART RESPONSE -> ', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchAnnouncements = createAsyncThunk(
	'users/announcements',
	async () => {
		try {
			const res =
				await services.courseService.getAnnouncements();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchGeneralProfile = createAsyncThunk(
	'users/generalProfile',
	async () => {
		try {
			const res = await services.userService.getProfile();
			console.log('USER Profile G RESPONSE -> ', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchPlacementProfile = createAsyncThunk(
	'users/placementProfile',
	async () => {
		try {
			const res =
				await services.userService.getPlacementProfile();
			console.log('USER Profile P RESPONSE -> ', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchLeetcodeUserName = createAsyncThunk(
	'users/leetcodeName',
	async () => {
		try {
			const res =
				await services.userService.getLeetcodeUserName();
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const checkStudentPlacementReadiness =
	createAsyncThunk(
		'users/checkStudentPlacementReadiness',
		async () => {
			try {
				const res =
					await services.userService.getStudentPlacementReadiness();
				return res;
			} catch (error) {
				console.log(
					'getStudentPlacementReadinessError',
					error
				);
			}
		}
	);

export interface UserState {
	user: any;
	userReportCard: any;
	courseList: any[];
	userDataStatus: boolean;
	userCourseStatus: boolean;
	latestCourseID: any;
	latestCourseName: any;
	interviewCleared: boolean;
	topicTags: any[];
	chartData: any[];
	communityEnabled: boolean;
	liveDoubtsSlots: unknown;
	announcement: any;
	profile: GeneralProfileFormFields | null;
	placementProfile: PlacementProfileFormFields | null;
	leetcodeUserName: any;
	leetcodeLoading: boolean;
	pauseStatus: {
		showPauseOption: boolean;
		userPauseStatus: boolean;
		availablePauses: number;
		takenPauses: number;
	};
	isCrispConfigured: boolean;
	userRole: null | string;

	eventModalView: boolean;
	selectedDayEvent: any;
	toolTipPosition: {
		top: number;
		left: number;
	};
	userTrackDetails: any;
	isPlacementReady: boolean;
}

const initialState: UserState = {
	user: null,
	userReportCard: null,
	courseList: [],
	communityEnabled: false,
	userDataStatus: false,
	userCourseStatus: false,
	latestCourseID: null,
	latestCourseName: null,
	interviewCleared: false,
	topicTags: [],
	chartData: [],
	liveDoubtsSlots: [],
	announcement: null,
	profile: null,
	placementProfile: null,
	leetcodeUserName: null,
	leetcodeLoading: false,
	pauseStatus: {
		showPauseOption: true,
		userPauseStatus: false,
		availablePauses: 0,
		takenPauses: 0,
	},
	isCrispConfigured: false,
	userRole: null,

	eventModalView: false,
	selectedDayEvent: null,
	toolTipPosition: {
		top: 0,
		left: 0,
	},
	userTrackDetails: null,
	isPlacementReady: false,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserData: (state, action: PayloadAction<any>) => {
			state.user = action.payload;
		},
		setUserReportCardData: (
			state,
			action: PayloadAction<any>
		) => {
			state.userReportCard = action.payload;
		},
		setUserFullCourseEnrollmentData: (state) => {
			console.log(state);
		},
		updateCourseList: (
			state,
			action: PayloadAction<any[]>
		) => {
			state.courseList = action.payload;
		},
		updateProfile: (state, action: PayloadAction<any>) => {
			state.profile = action.payload;
		},
		updateLeetcodeUserName: (
			state,
			action: PayloadAction<any>
		) => {
			state.leetcodeUserName = action.payload;
		},
		updatePlacementProfile: (
			state,
			action: PayloadAction<any>
		) => {
			state.placementProfile = action.payload;
		},
		markIsCrispConfigured(state, action) {
			state.isCrispConfigured = action.payload;
		},

		setStateEventModalView: (state, action) => {
			state.eventModalView = action.payload;
		},
		setSelectedDayEvents: (state, action) => {
			state.selectedDayEvent = action.payload;
		},
		setToolTipPosition: (state, action) => {
			state.toolTipPosition = action.payload;
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchLiveDoubtsSlots.pending]: (
			state: UserState,
			action: PayloadAction<any[]>
		) => {
			return;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchLiveDoubtsSlots.fulfilled]: (
			state: UserState,
			action: PayloadAction<any[]>
		) => {
			state.liveDoubtsSlots = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchLiveDoubtsSlots.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchGeneralProfile.pending]: (
			state: UserState,
			action: PayloadAction<any[]>
		) => {
			return;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchGeneralProfile.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.profile = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchGeneralProfile.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchPlacementProfile.pending]: (
			state: UserState,
			action: PayloadAction<any[]>
		) => {
			return;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchPlacementProfile.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.placementProfile = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchPlacementProfile.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchLeetcodeUserName.pending]: (
			state: UserState,
			action: PayloadAction<any[]>
		) => {
			state.leetcodeUserName = null;
			state.leetcodeLoading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchLeetcodeUserName.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.leetcodeUserName =
				action.payload.leetcodeUsername;
			console.log('SLICE', state.leetcodeUserName);
			state.leetcodeLoading = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchLeetcodeUserName.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
			state.leetcodeLoading = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUserReport.pending]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userDataStatus = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUserReport.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			console.log('USER REPORT', action.payload);
			state.userDataStatus = true;
			state.userReportCard = action.payload.reportCard;
			state.user = action.payload.user;
			state.userRole = action.payload.user.roles[0];
			state.interviewCleared =
				action.payload.reportCard.interviewCleared;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUserReport.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userDataStatus = false;
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchCourseList.pending]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userCourseStatus = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchCourseList.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			console.log('Action', action.payload);
			state.courseList = action.payload.courseList;
			state.latestCourseID = action.payload.latestCourseID;
			state.latestCourseName =
				action.payload.latestCourseName;
			state.userCourseStatus = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchCourseList.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userDataStatus = false;
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllTagsList.pending]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userCourseStatus = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllTagsList.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			console.log('Action', action.payload);
			state.topicTags = action.payload;
			state.userCourseStatus = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAllTagsList.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userDataStatus = false;
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAnnouncements.pending]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userCourseStatus = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAnnouncements.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			console.log('Announcements', action.payload);
			state.announcement = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchAnnouncements.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userDataStatus = false;
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[addCallbackRequest.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.user = action.payload.user;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[checkIfCommunityEnabled.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.communityEnabled = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[checkIfCommunityEnabled.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.communityEnabled = action.payload.status;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[checkIfCommunityEnabled.panding]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.communityEnabled = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchChartData.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userDataStatus = false;
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchChartData.pending]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userCourseStatus = false;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchChartData.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.chartData = [
				action.payload.questionsSolvedByUser,
				action.payload.maxQuestionsSolvedByAnyUser,
			];
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUserTrack.pending]: (
			state: UserState,
			action: PayloadAction<any[]>
		) => {
			return;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUserTrack.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.userTrackDetails =
				action.payload.status && action.payload.id
					? action.payload
					: null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchUserTrack.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[checkStudentPlacementReadiness.fulfilled]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			state.isPlacementReady = action.payload;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[checkStudentPlacementReadiness.rejected]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			// state.authError = action.payload
			state.isPlacementReady = false;
		},

		[userPauseStatusThunk.pending as any]: (
			state: UserState,
			action: PayloadAction<any>
		) => {},
		[userPauseStatusThunk.fulfilled as any]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			const temp = {
				showPauseOption:
					action.payload.shouldShowCoursePauseButton,
				userPauseStatus: action.payload.userPauseStatus,
				availablePauses: action.payload.pausesRemaining,
				takenPauses: action.payload.pausesTaken,
			};
			state.pauseStatus = { ...temp };
		},
		[userPauseStatusThunk.rejected as any]: (
			state: UserState,
			action: PayloadAction<any>
		) => {},

		[raiseCoursePauseThunk.pending as any]: (
			state: UserState,
			action: PayloadAction<any>
		) => {},
		[raiseCoursePauseThunk.fulfilled as any]: (
			state: UserState,
			action: PayloadAction<any>
		) => {
			const temp = {
				showPauseOption:
					action.payload.shouldShowCoursePauseButton,
				userPauseStatus: action.payload.userPauseStatus,
				availablePauses: action.payload.pausesRemaining,
				takenPauses: action.payload.pausesTaken,
			};
			state.pauseStatus = { ...temp };
			notifySuccess('Course Paused');
		},
		[raiseCoursePauseThunk.rejected as any]: (
			state: UserState,
			action: PayloadAction<any>
		) => {},
	},
});

//Exporting Actions
export const {
	setStateEventModalView,
	setUserData,
	setUserReportCardData,
	setUserFullCourseEnrollmentData,
	updateProfile,
	updateLeetcodeUserName,
	updatePlacementProfile,
	markIsCrispConfigured,
	setSelectedDayEvents,
	setToolTipPosition,
} = userSlice.actions;

export default userSlice.reducer;
