import {
	PayloadAction,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export interface WebDevQuestionState {
	isTokenValid: boolean;
	githubLoginLink: string;
	githubUserName: string;
	questionData: any;
	loading: boolean;
	isThemeDark: boolean;
	solutionRepo: string;
	acceptedInvitation: boolean;
	startingAssignment: boolean;
	error: {
		state: boolean;
		message: string;
		githubAuthLink: string;
	};
	// submitLoading:boolean
}

export enum Theme {
	'light' = 'light',
	'vsDark' = 'dark',
}

export const fetchWebDevQuestionData = createAsyncThunk(
	'webDevQuestion/question',
	async (questionId: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		const data = await services.webDevQuestionService.fetch(
			questionId
		);
		return data;
	}
);

export const startAssignment = createAsyncThunk(
	'webDevQuestion/startAssignment',
	async ({
		questionId,
		code,
	}: {
		questionId: string;
		code?: string;
	}) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		const data =
			await services.webDevQuestionService.startAssignment(
				questionId,
				code || ''
			);
		return data;
	}
);

const initialState: WebDevQuestionState = {
	isTokenValid: true,
	githubLoginLink: '',
	githubUserName: '',
	isThemeDark: false,
	loading: false,
	questionData: null,
	solutionRepo: '', // Default: the student has not started the assignment yet
	acceptedInvitation: false,
	startingAssignment: false,
	error: {
		state: false,
		message: '',
		githubAuthLink: '',
	},
	// submitLoading:false,
};

export const webDevQuestionSlice = createSlice({
	name: 'editor',
	initialState,
	reducers: {
		changeTheme: (state, action: PayloadAction<string>) => {
			// console.log('heer')
			state.isThemeDark = !state.isThemeDark;
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchWebDevQuestionData.pending]: (
			state: WebDevQuestionState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchWebDevQuestionData.fulfilled]: (
			state: WebDevQuestionState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.questionData = action.payload;
			//eslint
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			state.solutionRepo = action.payload.solutionRepo;
			state.acceptedInvitation =
				action.payload.acceptedInvitation;
			state.error.state = false;
			state.githubUserName = action.payload.githubUsername;
			state.githubLoginLink = action.payload.githubAuthUrl;
			state.isTokenValid = action.payload.isTokenValid;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchWebDevQuestionData.rejected]: (
			state: WebDevQuestionState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error.state = true;
			state.error.message = 'Error, please try again later';
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[startAssignment.pending]: (
			state: WebDevQuestionState,
			action: PayloadAction<any>
		) => {
			state.startingAssignment = true;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[startAssignment.fulfilled]: (
			state: WebDevQuestionState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.startingAssignment = false;

			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			//@ts-ignore
			if (action.payload.githubAuthUrl) {
				state.error.githubAuthLink =
					action.payload.githubAuthUrl;
				state.error.message = action.payload.message;
				state.error.state = true;
				console.log('githubUserName here1');
			} else {
				console.log('githubUserName here2');
				state.solutionRepo = action.payload.newRepo;

				state.acceptedInvitation =
					action.payload.acceptedInvitation;
				state.error.state = false;
			}
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[startAssignment.rejected]: (
			state: WebDevQuestionState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.startingAssignment = false;
			state.error.state = true;
			state.error.message =
				"Error, couldn't start the assignment";
		},
	},
});

//Exporting Actions
export const { changeTheme } = webDevQuestionSlice.actions;

export default webDevQuestionSlice.reducer;
