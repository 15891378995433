import moment from 'moment';
import { useEffect, useState } from 'react';

import {
	FiArrowRight,
	FiAward,
	FiTarget,
	FiZap,
} from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import { selectActiveModule } from '../../redux/modules/moduleSlice';

import { encryptData } from '@acciojob/event-analytics';

const ModuleQuestionPanel = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const userState = useSelector((state: any) => state.user);
	const { user } = userState;

	const instructor =
		user !== null
			? user.roles.find((role: any) => role == 'instructor')
			: undefined;
	const role = instructor !== undefined ? 1 : 0;

	const moduleState = useSelector(
		(state: any) => state.module
	);
	const {
		allModulesData,
		modulesData,
		allModules,
		moduleListloading,
		moduleloading,
		moduleActive,
		currentModuleId,
	} = moduleState;
	const [displayClassStatus, setDisplayClasssStatus] =
		useState('');
	const [
		displayClassStatusLive,
		setDisplayClasssStatusLive,
	] = useState(false);
	const [hoveredModuleID, setHoveredModuleID] =
		useState(null);
	const [displayClassStatusR, setDisplayClasssStatusR] =
		useState(false);
	const courseState = useSelector(
		(state: any) => state.course
	);
	const {
		latestAssignment,
		courseID,
		course,
		allCourses,
		courseName,
		recordingDataLecture,
		recordingDataDoubt,
		totalAssignmentQuestions,
		totalSolvedAssignmentQuestions,
		upcomingMeetings,
		latestAssignmentData,
		upcomingClass,
	} = courseState;

	const [modulesList, setModulesList] = useState<any[]>([]);
	const [questionsList, setQuestionList] = useState<any[]>(
		[]
	);
	// const [devQuestionsList, setDevQuestionList] = useState<any[]>([]);
	const [webDevQuestionList, setWebDevQuestionList] =
		useState<any[]>([]);
	const [sqlQuestionList, setSqlQuestionList] = useState<
		any[]
	>([]);
	const [
		fileUploadDownloadQuestionList,
		setFileUploadDownloadQuestionList,
	] = useState<any[]>([]);

	const [
		jupyterNoteBookQuestions,
		setJupyterNoteBookQuestionList,
	] = useState<any[]>([]);
	const [deadLine, setDeadLine] = useState(new Date());
	const [showAptitudeCard, setShowAptitudeCard] = useState<
		any[]
	>([]);
	const [moduleName, setModuleName] = useState('');

	const [isWebModule, setIsWebModule] = useState(false);
	const [isAptitudeModule, setIsAptitudeModule] =
		useState(false);

	useEffect(() => {
		if (
			currentModuleId !== '' &&
			modulesData[currentModuleId] !== undefined
		) {
			console.log(
				'moduledata 🤟',
				modulesData[currentModuleId]
			);
			setQuestionList(
				modulesData[currentModuleId].codingQuestions
			);
			setWebDevQuestionList(
				modulesData[currentModuleId].webdevQuestions
			);
			setSqlQuestionList(
				modulesData[currentModuleId].sqlQuestions
			);
			setIsAptitudeModule(
				modulesData[currentModuleId].conceptualQuestions
					.length > 0
			);
			setFileUploadDownloadQuestionList(
				modulesData[currentModuleId].fileQuestions
			);
			setJupyterNoteBookQuestionList(
				modulesData[currentModuleId].dataScienceQuestions
			);
			setDeadLine(modulesData[currentModuleId].deadLine);
			setIsWebModule(
				modulesData[currentModuleId].module.type ===
					'WEB_ASSIGNMENT'
			);
			setModuleName(
				modulesData[currentModuleId].module.name
			);
			console.log(
				'currenasdft',
				modulesData[currentModuleId].module
			);
			dispatch(
				selectActiveModule({
					...modulesData[currentModuleId].module,
				})
			);
		}
	}, [modulesData, currentModuleId]);

	const colorDIFFMap = (diff: string): any => {
		const color = [
			{
				color: '#EF574C',
				bg: '#FDE6E4',
			},
			{
				color: '#F0B416',
				bg: 'rgba(253, 242, 214, 0.5)',
			},
			{
				color: '#12B76A',
				bg: '#E3FFED',
			},
		];
		if (diff === 'HARD') {
			return color[0];
		} else if (diff === 'MEDIUM') {
			return color[1];
		} else if (diff === 'EASY') {
			return color[2];
		}
	};

	return (
		<div className={'module-content'}>
			<div
				className={
					'module-content-container view-scroll-bar'
				}
			>
				{questionsList.length > 0 ||
				webDevQuestionList.length > 0 ||
				sqlQuestionList.length > 0 ||
				fileUploadDownloadQuestionList.length > 0 ||
				jupyterNoteBookQuestions.length > 0 ||
				isAptitudeModule ? (
					<ul className={'assignments view-scroll-bar'}>
						{modulesData[currentModuleId]
							?.conceptualQuestions.length > 0 ? (
							<li
								className={'question aptitude-card'}
								key={'aptitude-questions'}
							>
								<div className={'question-info'}>
									<div className={'question-details'}>
										<h4>Solve Conceptual Questions</h4>
									</div>
									<button
										className={'question-solve-btn'}
										onClick={() =>
											navigate(
												`/aptitude?moduleId=${currentModuleId}`
											)
										}
									>
										Start Solving
										<FiArrowRight />
									</button>
								</div>
							</li>
						) : null}
						{!moduleloading ? (
							questionsList.map((el) => (
								<li
									key={el.id}
									className={'question'}
								>
									<div className={'question-info'}>
										<div className={'question-details'}>
											<h4>{el.title}</h4>
											<ul>
												<li>
													<FiZap />
													<span
														className={'difficulty'}
														style={{
															color: colorDIFFMap(
																el.difficulty
															).color,
															backgroundColor: colorDIFFMap(
																el.difficulty
															).bg,
														}}
													>
														{el.difficulty}
													</span>
												</li>
												<li>
													<FiTarget /> Max Score: {el.score}{' '}
													Points
												</li>
												<li>
													<FiAward />
													Your Score: {el.userScore} Points
												</li>
											</ul>
										</div>
										<button
											className={`${
												el.solved
													? 'question-solved-btn'
													: 'question-solve-btn'
											}`}
											onClick={() =>
												window.open(
													`${window.origin}/idle?question=${el.id}`,
													'_blank'
												)
											}
											event-analytics={
												!el.solved
													? 'af8d76d5-ac39-4d27-9514-c8c37cfdab97'
													: ''
											}
											data-config={
												!el.solved
													? encryptData(
															JSON.stringify({
																name: el.id,
																label: 'Start Solving',
															})
													  )
													: ''
											}
										>
											{' '}
											{el.solved ? (
												<>Solved</>
											) : (
												<>
													{el.attemptStatus === 'ATTEMPTED'
														? 'Continue Solving'
														: 'Start Solving'}
													<FiArrowRight />
												</>
											)}
										</button>
									</div>
								</li>
							))
						) : (
							<Loader />
						)}
						{!moduleloading ? (
							fileUploadDownloadQuestionList.map((el) => (
								<li
									key={el.id}
									className={'question'}
								>
									<div className={'question-info'}>
										<div className={'question-details'}>
											<div className="title-container">
												<span className="file-uploadDownload-title">
													{moment(new Date()).format(
														'MMMM Do YYYY, h:mm a'
													) <
													moment(deadLine).format(
														'MMMM Do YYYY, h:mm a'
													) ? (
														el.title
													) : (
														<>
															{el.title}
															<span className="deadline-text">
																Deadline Passed
															</span>
														</>
													)}
												</span>
												{deadLine ? (
													moment(new Date()).format(
														'MMMM Do YYYY, h:mm a'
													) <
													moment(deadLine).format(
														'MMMM Do YYYY, h:mm a'
													) ? (
														<span className="file-uploadDownload-text">
															Deadline:
															<span className="file-uploadDownload-date-time">
																&nbsp;{' '}
																{moment(deadLine).format(
																	'MMMM Do YYYY, h:mm a'
																)}
															</span>
														</span>
													) : null
												) : null}
											</div>
											<ul>
												<li>
													<FiZap />
													<span
														className={'difficulty'}
														style={{
															color: colorDIFFMap(
																el.difficulty
															).color,
															backgroundColor: colorDIFFMap(
																el.difficulty
															).bg,
														}}
													>
														{el.difficulty}
													</span>
												</li>
												<li>
													<FiTarget /> Max Score: {el.score}{' '}
													Points
												</li>
												<li>
													<FiAward />
													Your Score: {el.userScore} Points
												</li>
											</ul>
										</div>
										<button
											className={`${
												el.solved
													? 'question-solved-btn'
													: 'question-solve-btn'
											}`}
											onClick={() =>
												window.open(
													`${window.origin}/file?question=${el.id}&moduleId=${currentModuleId}`,
													'_blank'
												)
											}
										>
											{' '}
											{el.solved ? (
												<>Solved</>
											) : (
												<>
													{el.attemptStatus === 'ATTEMPTED'
														? 'Continue Solving'
														: 'Start Solving'}
													<FiArrowRight />
												</>
											)}
										</button>
									</div>
								</li>
							))
						) : (
							<Loader />
						)}
						{!moduleloading
							? webDevQuestionList.map((el) => (
									<li
										key={el.id}
										className={'question'}
									>
										<div className={'question-info'}>
											<div className={'question-details'}>
												<h4>{el.title}</h4>
												<ul>
													<li>
														<FiZap />
														<span
															className={'difficulty'}
															style={{
																color: colorDIFFMap(
																	el.difficulty
																).color,
																backgroundColor:
																	colorDIFFMap(
																		el.difficulty
																	).bg,
															}}
														>
															{el.difficulty}
														</span>
													</li>
													<li>
														<FiTarget /> Max Score:{' '}
														{el.score} Points
													</li>
													<li>
														<FiAward />
														Your Score: {el.userScore}{' '}
														Points
													</li>
												</ul>
											</div>
											<button
												className={`${
													el.solved
														? 'question-solved-btn'
														: 'question-solve-btn'
												}`}
												onClick={() =>
													window.open(
														`${window.origin}/web-idle?question=${el.id}`,
														'_blank'
													)
												}
												event-analytics={
													'b43820cf-0589-40b2-b493-4fb584abb160'
												}
												data-config={encryptData(
													JSON.stringify({
														name: el.id,
													})
												)}
											>
												{' '}
												{el.solved ? (
													<>Solved</>
												) : (
													<>
														{el.attemptStatus ===
														'ATTEMPTED'
															? 'Continue Solving'
															: 'Start Solving'}
														<FiArrowRight />
													</>
												)}
											</button>
										</div>
									</li>
							  ))
							: null}
						{!moduleloading
							? sqlQuestionList.map((el) => (
									<li
										key={el.id}
										className={'question'}
									>
										<div className={'question-info'}>
											<div className={'question-details'}>
												<h4>{el.title}</h4>
												<ul>
													<li>
														<FiZap />
														<span
															className={'difficulty'}
															style={{
																color: colorDIFFMap(
																	el.difficulty
																).color,
																backgroundColor:
																	colorDIFFMap(
																		el.difficulty
																	).bg,
															}}
														>
															{el.difficulty}
														</span>
													</li>
													<li>
														<FiTarget /> Max Score:{' '}
														{el.score} Points
													</li>
													<li>
														<FiAward />
														Your Score: {el.userScore}{' '}
														Points
													</li>
												</ul>
											</div>
											<button
												className={`${
													el.solved
														? 'question-solved-btn'
														: 'question-solve-btn'
												}`}
												onClick={() =>
													window.open(
														`${window.origin}/sqlEditor?question=${el.id}`,
														'_blank'
													)
												}
											>
												{' '}
												{el.solved ? (
													<>Solved</>
												) : (
													<>
														{el.attemptStatus ===
														'ATTEMPTED'
															? 'Continue Solving'
															: 'Start Solving'}
														<FiArrowRight />
													</>
												)}
											</button>
										</div>
									</li>
							  ))
							: null}

						{!moduleloading
							? jupyterNoteBookQuestions.map((el) => (
									<li
										key={el.id}
										className={'question'}
									>
										<div className={'question-info'}>
											<div className={'question-details'}>
												<h4>{el.title}</h4>
												<ul>
													<li>
														<FiZap />
														<span
															className={'difficulty'}
															style={{
																color: colorDIFFMap(
																	el.difficulty
																).color,
																backgroundColor:
																	colorDIFFMap(
																		el.difficulty
																	).bg,
															}}
														>
															{el.difficulty}
														</span>
													</li>
													<li>
														<FiTarget /> Max Score:{' '}
														{el.score} Points
													</li>
													<li>
														<FiAward />
														Your Score: {el.userScore}{' '}
														Points
													</li>
												</ul>
											</div>
											<button
												className={`${
													el.solved
														? 'question-solved-btn'
														: 'question-solve-btn'
												}`}
												onClick={() =>
													window.open(
														`${window.origin}/jupyter-notebook?question=${el.id}`,
														'_blank'
													)
												}
											>
												{' '}
												{el.solved ? (
													<>Solved</>
												) : (
													<>
														{el.attemptStatus ===
														'ATTEMPTED'
															? 'Continue Solving'
															: 'Start Solving'}
														<FiArrowRight />
													</>
												)}
											</button>
										</div>
									</li>
							  ))
							: null}
					</ul>
				) : isAptitudeModule ? null : (
					<p>No Questions Available For Today</p>
				)}
			</div>
		</div>
	);
};

export default ModuleQuestionPanel;
