import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../apis';

export const getSessionDetailsOfUserFromSessionType =
	createAsyncThunk(
		'session/getSessionDetailsOfUserFromSessionType',
		async (sessioId: string) => {
			const data =
				await services.sessionService.fetchSessionDetailsOfUserFromSessionType(
					sessioId
				);
			return data;
		}
	);

export const getEligibleSessionsOfUser = createAsyncThunk(
	'session/getEligibleSessionsOfUser',
	async () => {
		const data =
			await services.sessionService.fetchEligibleSessionsOfUser();
		return data;
	}
);

export const getSessionsOfUserForAllSessionTypes =
	createAsyncThunk(
		'session/getSessionsOfUserForAllSessionTypes',
		async () => {
			const data =
				await services.sessionService.fetchSessionsOfUserForAllSessionTypes();
			return data;
		}
	);

export const getUpcomingSessionsOfUser = createAsyncThunk(
	'session/getUpcomingSessionsOfUser',
	async () => {
		const data =
			await services.sessionService.fetchUpcomingSessionsOfUser();
		return data;
	}
);

// export const getUpcomingSessions = createAsyncThunk(
// 	'session/getUpcomingSessions',
// 	async (body: any) => {
// 		const data =
// 			await services.sessionService.fetchUpcomingSessions(
// 				body.userId,
// 				body.userType
// 			);
// 		console.log('session data onevone slice', data);
// 		// const temp = data.filter((el: any) => el.upcomingInterviews.length > 0);
// 		// console.log('session data', temp, data);

// 		return data;
// 	}
// );

export const getMeetingLink = createAsyncThunk(
	'session/getMeetingLink',
	async (body: any) => {
		const data =
			await services.sessionService.fetchMeetingLink(
				body.roomId,
				body.userId,
				body.userType
			);
		return data;
	}
);

export const getSessionWithoutFeedback = createAsyncThunk(
	'session/getSessionWithoutFeedback',
	async (userId: string) => {
		const data =
			await services.sessionService.getSessionWithoutFeedback(
				userId
			);
		return data;
	}
);

export const checkStudentPlacementReadinessFinder =
	createAsyncThunk(
		'session/checkStudentPlacementReadiness',
		async () => {
			const data =
				await services.sessionService.checkStudentPlacementReadiness();
			return data;
		}
	);

export const postFeedback = createAsyncThunk(
	'session/postFeedback',
	async (body: any) => {
		const data = await services.sessionService.postFeedback(
			body.sessionId,
			body.userType,
			body.formData
		);
		return data;
	}
);

export const joinClassZoomLink = createAsyncThunk(
	'session/joinClassZoomLink',
	async (body: any) => {
		const data = await services.sessionService.joinZoomLink(
			body
		);
		return data;
	}
);

export const getRecommendedSessions = createAsyncThunk(
	'session/getRecommendedSessions',
	async (body: any) => {
		const data =
			await services.sessionService.fetchUpcomingAllSessions(
				body.userId,
				body.userType
			);
		return data;
	}
);

export const getRemainingBookings = createAsyncThunk(
	'session/getRemainingBookings',
	async () => {
		const data =
			await services.sessionService.fetchRemainingBookings();
		return data;
	}
);

export const getSessionEligibility = createAsyncThunk(
	'session/fetchSessionEligibility',
	async () => {
		const data =
			await services.sessionService.fetchSessionEligibility();
		return data;
	}
);
