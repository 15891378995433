import { useEffect, useRef, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { FiFilter, FiSearch } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAllTagsList } from '../../redux/user/userSlice';

import { Grid } from '@mui/material';
import { nanoid } from 'nanoid';
import { IoMdClose } from 'react-icons/io';
import ContestPanel from '../../components/contest/contestPanel';
import { InputWithDecorator } from '../../components/elements/Input';
import PageHeading from '../../components/shared/PageHeading';
import TabPanel from '../../components/shared/TabPanel';
import { fetchAllContests } from '../../redux/contest/contestSlice';
import { AppDispatch } from '../../redux/store';

const ContestDash = () => {
	const dispatch = useDispatch<AppDispatch>();

	const contestState = useSelector(
		(state: any) => state.contest
	);
	const userState = useSelector((state: any) => state.user);
	const { courseList, topicTags } = userState;

	const [contestTabs, setContestTabs] = useState<any[]>([]);
	const [moduleValue, setModuleValue] =
		useState<string>('reset');
	const [searchText, setSearchText] = useState<string>('');

	const [{ live, upcoming, past, practice }, setContests] =
		useState<{
			live: any[];
			upcoming: any[];
			past: any[];
			practice: any[];
		}>({
			live: [],
			upcoming: [],
			past: [],
			practice: [],
		});

	const timeoutRef = useRef<any>(null);

	useEffect(() => {
		if (topicTags.length === 0) {
			dispatch(fetchAllTagsList());
		}
	}, [topicTags]);

	useEffect(() => {
		dispatch(fetchAllContests());
	}, []);

	useEffect(() => {
		setContests({
			live: [...contestState.live, ...contestState.ongoing],
			upcoming: [...contestState.upcoming],
			past: [...contestState.past],
			practice: [...contestState.openContest],
		});
	}, [contestState]);

	useEffect(() => {
		const temp = [
			{
				keyId: nanoid(),
				title: 'Live',
				element: (
					<ContestPanel
						key={nanoid()}
						contestList={live}
						panelType="live"
					/>
				),
				show: true,
			},
			{
				keyId: nanoid(),
				title: 'Upcoming',
				element: (
					<ContestPanel
						key={nanoid()}
						contestList={upcoming}
						panelType="upcoming"
					/>
				),
				show: true,
			},
			{
				keyId: nanoid(),
				title: 'Past',
				element: (
					<ContestPanel
						key={nanoid()}
						contestList={past}
						panelType="past"
					/>
				),
				show: true,
			},
			{
				keyId: nanoid(),
				title: 'Practise',
				element: (
					<ContestPanel
						key={nanoid()}
						contestList={practice}
						panelType="open"
					/>
				),
				show: true,
			},
		];

		setContestTabs([...temp]);
	}, [live, past, upcoming, practice]);

	useEffect(() => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			setContestList(moduleValue, searchText);
		}, 500);
	}, [searchText]);

	useEffect(() => {
		setContestList(moduleValue, '');
		setSearchText('');
	}, [moduleValue]);

	const setContestList = (
		moduleName: string,
		searchText: string
	) => {
		const filteredContestFilter = (contest: any) => {
			if (
				moduleName === 'reset' ||
				(moduleName === 'Project Contest' &&
					(!contest.courseId || !contest.courseName))
			) {
				return (
					!searchText ||
					(contest.name as string)
						.toLowerCase()
						.includes(searchText.toLowerCase())
				);
			} else if (contest.courseName) {
				return (
					(contest.courseName as string)
						.toLowerCase()
						.includes(moduleName.toLowerCase()) &&
					(!searchText ||
						(contest.name as string)
							.toLowerCase()
							.includes(searchText.toLowerCase()))
				);
			} else if (contest.name) {
				return (
					!searchText ||
					(contest.name as string)
						.toLowerCase()
						.includes(searchText.toLowerCase())
				);
			} else {
				return false;
			}
		};

		const filteredLive = [
			...contestState.live,
			...contestState.ongoing,
		].filter(filteredContestFilter);

		const filteredUpcoming = [
			...contestState.upcoming,
		].filter(filteredContestFilter);

		const filteredPast = [...contestState.past].filter(
			filteredContestFilter
		);

		const filteredPractice = [
			...contestState.openContest,
		].filter(filteredContestFilter);

		setContests({
			live: filteredLive,
			upcoming: filteredUpcoming,
			past: filteredPast,
			practice: filteredPractice,
		});
	};

	return (
		<div className="pg-contest">
			<Grid container>
				<Grid
					item
					lg={8}
					sm={8}
					className="contest-column-1"
				>
					<PageHeading pageName="All Contests" />

					{contestTabs.length > 0 ? (
						<TabPanel
							tabData={contestTabs}
							defaultValue={0}
							panelBoxClass="contest-panel-container"
						/>
					) : null}
				</Grid>
				<Grid
					item
					lg={4}
					sm={4}
				>
					<div className="contest-filter">
						<div className={'filter-options'}>
							<div className={'filter-head'}>
								<span>
									<FiFilter />
								</span>
								<p>Filter Contests</p>
							</div>
							<div className={'filter-dropdown'}>
								<p>module</p>
								{courseList.length !== 0 ? (
									<Select
										value={moduleValue ?? 'reset'}
										className={
											'tag-dropdown contest-tag-filter'
										}
										onChange={(e: any) => {
											setModuleValue(e.target.value);
										}}
										name="Course"
										style={{
											borderRadius: 10,
										}}
									>
										<MenuItem
											key={'reset'}
											value={'reset'}
										>
											Select Module
										</MenuItem>
										{courseList.map(
											(el: any, index: number) => (
												<MenuItem
													key={el.id}
													value={el.title}
												>
													{el.title}
												</MenuItem>
											)
										)}
										<MenuItem
											key={'project_contest'}
											value={'Project Contest'}
										>
											Project Contest
										</MenuItem>
									</Select>
								) : null}
							</div>
							<div className={'filter-dropdown'}>
								<p>Search By Name</p>
								<InputWithDecorator
									placeholder=""
									value={searchText ?? ''}
									setValue={setSearchText}
									startDecoration={<FiSearch />}
									endDecoration={
										searchText?.length ? (
											<span
												className="end-decoration"
												onClick={() => {
													setSearchText('');
												}}
											>
												<IoMdClose />
											</span>
										) : null
									}
									customClass="search-module tag-dropdown contest-tag-filter"
								/>
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default ContestDash;
