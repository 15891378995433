import AccioButton from '../elements/Button';
import angle_right_blue from '../../assets/softSkillsNew/angle_right_blue.svg';
import clock_history from '../../assets/softSkillsNew/clock_history.svg';
import back_arrow from '../../assets/softSkillsNew/back_arrow.svg';
import { FC, useState } from 'react';
import { FeedbackType } from '../../redux/softskills/softskills.type';
import moment from 'moment';
import MarkDown from '../contestComponents/MarkDown';
import {
	notifyError,
	notifyInfo,
	notifySuccess,
} from '../notification';
import { services } from '../../apis';
import PopUpBox from '../shared/PopUpBox';
import Input from '@mui/joy/Input';

type FeedbackSectionProps = {
	feedback: Array<FeedbackType>;
	selectedModuleId: string;
};

const FeedbackSection: FC<FeedbackSectionProps> = ({
	feedback,
	selectedModuleId,
}) => {
	const [showFeedbackHistory, setShowFeedbackHistory] =
		useState(false);
	const [showBackToFeedback, setShowBackToFeedback] =
		useState(false);
	const [selectedFeedback, setSelectedFeedback] =
		useState<string>();
	const [videoURL, setVideoURL] = useState<string>('');

	const handleViewFeedbackHistory = () => {
		setShowFeedbackHistory(true);
		setShowBackToFeedback(true);
	};

	const [showUploadVideoModal, setShowUploadVideoModal] =
		useState(false);

	const handlePopUpClose = () => {
		setShowUploadVideoModal(false);
	};

	const handleUploadVideo = async () => {
		if (!videoURL.length) {
			notifyError('Please enter video URL');
		} else {
			try {
				const res =
					await services.softskillsService.uploadVideo(
						selectedModuleId,
						videoURL
					);
				if (res.statusCode && res.statusCode === 500) {
					notifyError('Failed to upload');
				} else if (res.message) {
					notifySuccess('URL uploaded successfully');
				}
			} catch (error) {
				console.log(error);
			} finally {
				setShowUploadVideoModal(false);
			}
		}
	};

	return (
		<div className="feedback-section">
			<PopUpBox
				handleClose={handlePopUpClose}
				openPopUp={showUploadVideoModal}
			>
				<div className="session-limit-pop-up-box-content">
					<p className="pop-up-box-title">
						{feedback.length > 0 &&
						feedback[0].feedback === null
							? 'Update Latest Video URL'
							: 'Upload Video URL'}
					</p>
					<Input
						value={videoURL}
						onChange={(e) => setVideoURL(e.target.value)}
						placeholder="Enter Video URL"
						fullWidth
						variant="outlined"
						sx={{
							marginTop: '15px',
						}}
					/>
					<div className="pop-up-box-btn-wrapper">
						<AccioButton
							title="CANCEL"
							variant="outline"
							customClass="btn booking-session-btn-outline"
							action={() => {
								handlePopUpClose();
							}}
						/>
						<AccioButton
							title="SUBMIT"
							variant="primary"
							customClass="btn booking-session-btn-primary"
							action={handleUploadVideo}
						/>
					</div>
				</div>
			</PopUpBox>

			{showBackToFeedback ? (
				<div
					className="back-to-feedback-container"
					onClick={() => {
						setShowBackToFeedback(false);
						setShowFeedbackHistory(false);
					}}
				>
					<img src={back_arrow} />
					<p>Back to Feedback</p>
				</div>
			) : null}

			{!showFeedbackHistory ? (
				<div>
					<div className="get-feedback-container">
						<div className="get-feedback-left">
							<h2 className="get-feedback-title">
								Get Feedback on your skills
							</h2>
							<p className="get-feedback-description"></p>
							<div className="upload-video-container">
								<h3 className="upload-video-title">
									Upload your video
								</h3>
								<div className="upload-video-block">
									{/* <img src={play_circle} /> */}
									{/* <p className="video-title">Video Title</p> */}
									<AccioButton
										title="Upload Video"
										className="upload-video-btn"
										action={() => {
											console.log('Clicked Upload Video');
											setShowUploadVideoModal(true);
										}}
									/>
									<AccioButton
										title="View Feedback History"
										icon={<img src={clock_history} />}
										action={handleViewFeedbackHistory}
										className="view-feedback-history-btn"
										iconPosition={'left'}
									/>
								</div>
							</div>
						</div>
						<div className="get-feedback-right">
							<img src="https://storage.googleapis.com/acciojob-open-file-collections/be88779c-924a-479a-b738-73d56e9b7b32_feedback.gif" />
						</div>
					</div>
					{selectedFeedback ? (
						<div className="feedback-section-bottom">
							<div className="feedback-contianer">
								<h3 className="feedback-bottom-title">
									Feedback
								</h3>
								<ul className="feedback-list">
									<MarkDown statement={selectedFeedback} />
								</ul>
							</div>
						</div>
					) : null}
				</div>
			) : (
				<div className="feedback-history">
					<h3 className="feedback-history-title">
						Feedback History
					</h3>
					<p className="feedback-history-description"></p>
					{feedback.length === 0 ? (
						<p className="no-feedback-history">
							No Feedback History
						</p>
					) : (
						<table className="feedback-history-table">
							<thead>
								<tr>
									<th></th>
									<th>Upload Date</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{feedback.map(
									(
										{ updatedAt, feedback, videoLink },
										index
									) => {
										return (
											<tr key={index}>
												<td
													onClick={() => {
														window.open(
															videoLink,
															'_blank'
														);
													}}
													style={{ cursor: 'pointer' }}
												>
													Video Link
												</td>
												<td>
													{moment(updatedAt).format(
														'DD MMM YYYY'
													)}
												</td>
												{feedback !== null ? (
													<td
														className="view-details"
														onClick={() => {
															setShowBackToFeedback(false);
															setSelectedFeedback(feedback);
															setShowFeedbackHistory(false);
														}}
													>
														View Details
														<img src={angle_right_blue} />
													</td>
												) : (
													<td>Feedback Pending</td>
												)}
											</tr>
										);
									}
								)}
							</tbody>
						</table>
					)}
				</div>
			)}
		</div>
	);
};

export default FeedbackSection;
