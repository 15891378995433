import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import { useEffect, useState } from 'react';

import defaultToken from './../utils/token/testingToken.json';
import { notifyError } from '../components/notification';

interface useAuthProps {
	allowedRoles?: string[];
	callBack: Function;
	setRole: Function;
}

const useAuthentication = ({
	allowedRoles,
	callBack,
	setRole,
}: useAuthProps) => {
	const [authenticated, setAuthenticated] = useState(false);

	const logout = () => {
		localStorage.clear();
		Cookies.remove('acciojobs-token', {
			domain: `.acciojob.com`,
		});
		window.open(
			'https://authentication.acciojob.com',
			'_self'
		);
		console.log('logout');
	};

	const isAccessAllowed = (
		roles: string[],
		userRole: string
	) => {
		if (roles.length > 0) {
			return roles.includes(userRole);
		} else return true;
	};

	const performActionOnTokenPresent = (token: string) => {
		localStorage.setItem('acciojobs-token', token);
		const decodedObject: any = jwt_decode(token);
		const userRole = decodedObject.roles[0];
		const isUserAllowed = isAccessAllowed(
			allowedRoles ?? [],
			userRole
		);
		if (isUserAllowed) {
			setRole(userRole);
			setAuthenticated(true);
			if (callBack) callBack();
		} else logout();
	};

	const authenticationCheck = async () => {
		const url = window.location.origin;
		const domainArr = new URL(url).hostname.split('.');
		domainArr.shift();
		const domain = domainArr.join('.');

		// Local Development
		if (process.env.REACT_APP_TOKEN) {
			const localToken = process.env.REACT_APP_TOKEN;
			try {
				if (localToken) {
					console.log('local token present', localToken);
					performActionOnTokenPresent(localToken);
				} else {
					alert('localToken is undefined');
				}
			} catch (err) {
				console.log('TOKEN ERROR', err);
				alert(
					'There is some error with the Local Development token please check console for => TOKEN ERROR'
				);
			}
		} else if (domain === 'web.app') {
			const alreadyPresentedToken = localStorage.getItem(
				'acciojobs-token'
			);
			const testingToken =
				alreadyPresentedToken ?? defaultToken.token;
			try {
				if (testingToken) {
					performActionOnTokenPresent(testingToken);
				} else {
					alert('testingToken is undefined');
					logout();
				}
			} catch (err) {
				console.log('TOKEN ERROR', err);
				notifyError(
					'You have been logged, please login again'
				);
				logout();
			}
		} else {
			const UserAccessToken = Cookies.get(
				'acciojobs-token'
			);
			try {
				if (UserAccessToken) {
					performActionOnTokenPresent(UserAccessToken);
				} else {
					notifyError(
						'You have been logged, please login again'
					);
					logout();
				}
			} catch (err) {
				console.log('TOKEN ERROR', err);
				notifyError(
					'You have been logged, please login again'
				);
				logout();
			}
		}
	};

	useEffect(() => {
		authenticationCheck();
	}, []);

	return authenticated;
};

export default useAuthentication;
