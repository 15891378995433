import { Dialog, DialogContent } from '@mui/material';
import moment from 'moment';
import React from 'react';

const ReferandearnTableModal = ({
	openModal,
	setOpenModal,
	activeModal,
	referralData,
}: any) => {
	return (
		<div>
			<Dialog
				open={openModal}
				onClose={() => {
					setOpenModal(false);
				}}
				scroll="paper"
				maxWidth="lg"
			>
				<DialogContent>
					<h4 className="re-modal-title">
						{activeModal !== null ? (
							<img
								src={require(`../../${referralData[
									activeModal
								].image.replace('icon', 'icon-full')}`)}
								alt={`${referralData[activeModal].title} Icon`}
								className="re-title-icon"
							/>
						) : null}
						<span
							className={`${
								activeModal !== null
									? referralData[activeModal].statsClass
									: ''
							}`}
						>
							{activeModal !== null
								? referralData[activeModal].title
								: ''}
						</span>
					</h4>

					<table className="re-modal-table">
						<thead>
							<tr className="re-table-row re-table-head">
								{activeModal !== null
									? referralData[
											activeModal
									  ].table.columns.map((col: string) => {
											console.log(col);
									  })
									: null}
							</tr>
						</thead>
						<tbody>
							{activeModal !== null
								? referralData[activeModal].table.data
										.length
									? referralData[
											activeModal
									  ].table.data.map(
											(d: any, i: number) => (
												<tr key={i}>
													<td className="re-table-cell">
														{i + 1}
													</td>
													<td className="re-table-cell">
														{d.name}
													</td>
													{activeModal === 1 && (
														<td className="re-table-cell">
															{moment(d.regDate).format(
																'DD-MM-YYYY'
															)}
														</td>
													)}
													<td className="re-table-cell">
														{activeModal === 1
															? d.status
															: d.email}
													</td>
													<td className="re-table-cell">
														{d.cashEarned}
													</td>
												</tr>
											)
									  )
									: null
								: null}
						</tbody>
					</table>
				</DialogContent>
			</Dialog>
		</div>
	);
};

export default ReferandearnTableModal;
