import moment from 'moment';
import { useEffect, useState } from 'react';
import { BsFillFilterSquareFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {
	fetchMentorSessions,
	setPrevEndTime,
	setPrevSessionId,
	setPrevStartTime,
	setPrevTab,
	setSessionsPage,
} from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import {
	getParams,
	onPropsChange,
	removeDuplicatesById,
} from '../mentorUtils';
import FormModal from './FormModal';
import RecordingModal from './RecordingModal';
import SessionsTable from './SessionsTable';

const PENDING = 'PENDING';

enum Filters {
	INTERNAL_ZOOM = 'INTERNAL_ZOOM',
	EXTERNAL_GMEET = 'EXTERNAL_GMEET',
	FEEDBACK_PENDING = 'FEEDBACK_PENDING',
	RECORDING_PENDING = 'NOT_UPLOADED',
}

const PendingSessions = ({
	sessionType,
	startDate,
	endDate,
}: any) => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorSessions, sessionsPage } = MentorState;

	const [sessionsData, setSessionsData] = useState<any[]>(
		[]
	);
	const [displayFilters, setDisplayFilters] =
		useState<boolean>(false);
	const [selectedFilter, setSelectedFilter] =
		useState<string>('');
	const [feedbackFormUrl, setFeedbackFormUrl] =
		useState<string>('');
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [selectedQns, setSelectedQns] = useState<any>(null);
	const [showRecordingModal, setShowRecordingModal] =
		useState<boolean>(false);
	const [targetSessionId, setTargetSessionId] =
		useState<string>('');

	useEffect(() => {
		dispatch(setSessionsPage(1));
	}, []);

	useEffect(() => {
		if (mentorSessions !== null) {
			const temp = removeDuplicatesById([
				...sessionsData,
				...mentorSessions,
			]);
			setSessionsData([...temp]);
		}
	}, [mentorSessions]);

	useEffect(() => {
		const changed = onPropsChange(
			sessionType,
			startDate,
			endDate,
			PENDING
		);
		setSessionsData([]);
		switch (changed) {
			case 'SESSION_CHANGE': {
				dispatch(setSessionsPage(1));
				dispatch(setPrevSessionId(sessionType));
				getSessionsData();
				// dispatch(setSessionsPage(2));
				break;
			}
			case 'DATE_CHANGE': {
				dispatch(setSessionsPage(1));
				dispatch(setPrevStartTime(startDate));
				dispatch(setPrevEndTime(endDate));
				getSessionsData();
				// dispatch(setSessionsPage(2));
				break;
			}
			case 'TAB_CHANGE': {
				getSessionsData();
			}
		}
		dispatch(setPrevTab(PENDING));
	}, [sessionType, startDate, endDate, selectedFilter]);

	const paramsWithFilter = (
		params: string,
		filter?: string
	): string => {
		const filterTemp = filter ?? selectedFilter;
		if (filterTemp.length > 0) {
			const isPlatform =
				filterTemp === Filters.INTERNAL_ZOOM ||
				filterTemp === Filters.EXTERNAL_GMEET;
			const isRecordingStatus =
				filterTemp === Filters.RECORDING_PENDING;
			const isFeedbackStatus =
				filterTemp === Filters.FEEDBACK_PENDING;
			if (isPlatform) params += `&platform=${filterTemp}`;
			if (isRecordingStatus)
				params += `&platform=${Filters.EXTERNAL_GMEET}&recordingStatus=${filterTemp}`;
			if (isFeedbackStatus)
				params += `&feedbackStatus=${filterTemp}`;
		}
		return params;
	};

	const getSessionsData = (filter?: string) => {
		let params = getParams(
			startDate,
			endDate,
			sessionType,
			PENDING
		);
		params = paramsWithFilter(params, filter);
		setSessionsData([]);
		dispatch(fetchMentorSessions(params));
	};

	const handleFilterSelect = (filter: string) => {
		setDisplayFilters(false);
		dispatch(setSessionsPage(1));
		setSelectedFilter(filter);
		getSessionsData(filter);
	};

	const handleFillFeedback = (sessionData: any) => {
		const selectedQns = sessionData.selectedQuestions;
		setFeedbackFormUrl(sessionData.mentorFeedbackLink);
		setOpenForm(true);
		setSelectedQns(selectedQns);
	};

	const handleFormClose = () => setOpenForm(false);

	const handleRecording = (sessionId: string) => {
		setShowRecordingModal(true);
		setTargetSessionId(sessionId);
	};
	const handleCloseRecording = () =>
		setShowRecordingModal(false);

	const fetchMoreDataCallback = () => {
		let params = getParams(
			startDate,
			endDate,
			sessionType,
			PENDING
		);
		params = paramsWithFilter(params);
		dispatch(fetchMentorSessions(params));
		dispatch(setSessionsPage(sessionsPage + 1));
	};

	return (
		<div>
			{openForm && (
				<FormModal
					openForm={openForm}
					url={feedbackFormUrl}
					handleFormClose={handleFormClose}
					selectedQns={selectedQns}
				/>
			)}

			{showRecordingModal && (
				<RecordingModal
					sessionId={targetSessionId}
					showRecordingModal={showRecordingModal}
					handleCloseRecording={handleCloseRecording}
				/>
			)}

			<SessionsTable
				sessionsData={sessionsData}
				fetchMoreDataCallback={fetchMoreDataCallback}
				tab={PENDING}
				obj={{
					handleFillFeedback,
					handleRecording,
					displayFilters,
					handleFilterSelect,
					selectedFilter,
					setDisplayFilters,
				}}
			/>
		</div>
	);
};

export default PendingSessions;
