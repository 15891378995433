import { Box, Dialog } from '@mui/material';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { services } from '../../../apis';
import EnterCollegeName from '../../../pages/profile/formElements/EnterCollegeName';
import { PopupTypes } from '../../../redux/popups/popup.type';

const VerifyCollege = ({ data, type }: any) => {
	const [open, setOpen] = useState(true);
	const [getCollegeName, setGetCollegeName] =
		useState(false);
	const closeDialog = () => setOpen(false);

	const notifySuccess = (msg: string) => {
		toast.success(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const confimCollege = async () => {
		const data = await services.userService.confirmCollege(
			type === PopupTypes.VERIFY_UG_COLLEGE ? 'UG' : 'PG'
		);
		notifySuccess(data.message);
		setOpen(false);
	};
	return (
		<Dialog
			open={open}
			onClose={closeDialog}
		>
			<Box sx={{ padding: '1em' }}>
				{!getCollegeName && (
					<>
						<h4 className="verify-title center">
							You studied / are studying{' '}
							{`${
								type === PopupTypes.VERIFY_UG_COLLEGE
									? 'UG'
									: 'PG'
							}`}{' '}
							in{' '}
						</h4>
						<div className="center">
							<p className="college-name">
								{data.collegeName}
							</p>
							<p className="college-location">
								{data.collegeCity}, {data.collegeState}
							</p>
						</div>
						<div className="confirm-btns-container">
							<button
								className="confirm-btns yes"
								onClick={confimCollege}
							>
								YES
							</button>
							<button
								className="confirm-btns no"
								onClick={() => setGetCollegeName(true)}
							>
								NO
							</button>
						</div>
					</>
				)}

				{getCollegeName && (
					<EnterCollegeName
						open={open}
						setOpen={setOpen}
						type={type}
					/>
				)}
			</Box>
		</Dialog>
	);
};

export default VerifyCollege;
