import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	setAllFeedbackOpenModal,
	setNextPopupId,
	setSelectedRatingVal,
} from '../../redux/allFeedbackModal/allFeedbackModal.slice';
import { AppDispatch, RootState } from '../../redux/store';
import { notifySuccess } from '../notification';
import { services } from '../../apis';

interface RatingProps {
	popUpData: any;
}

const Rating: React.FC<RatingProps> = ({
	popUpData,
}: RatingProps): JSX.Element => {
	const modalData = useSelector(
		(state: RootState) => state.allFeedbackModal
	);

	const dispatch = useDispatch<AppDispatch>();
	// const handleRatingHover = (
	// 	rating: number | null,
	// 	nextPopupId: string | null
	// ) => {
	// 	dispatch(setSelectedRatingVal(rating));
	// 	dispatch(setNextPopupId(nextPopupId));
	// };

	const handleRatingClick = async (
		rating: number | null,
		nextPopupId: string | null
	) => {
		dispatch(setSelectedRatingVal(rating));
		dispatch(setNextPopupId(nextPopupId));
		dispatch(setAllFeedbackOpenModal(true));
		try {
			const res = await services.popupService.recordRating({
				feedbackType: 'PLATFORM_FEEDBACK',
				page: '/home',
				rating: rating,
			});
			// notifySuccess(res?.message);
		} catch (error) {
			console.error(error);
		}
		// TODO: Have to perform an API call to save the rating
	};

	return (
		<div className="bottom-bar-wrapper">
			<p className="display-text">
				{popUpData?.popUpHeaderText}
			</p>
			<div className="rating-holder">
				{popUpData?.ratings?.length > 0
					? popUpData?.ratings?.map(
							(rating: any, i: number) => (
								<span
									className={`rating-component ${
										rating?.rating <=
										(modalData?.selectedRatingVal || 0)
											? 'selected'
											: ''
									}`}
									// onMouseEnter={() =>
									// 	handleRatingHover(
									// 		rating?.rating || null,
									// 		rating?.nextPopupId || null
									// 	)
									// }
									// onMouseLeave={() => handleRatingHover(null, null)}
									onClick={() =>
										handleRatingClick(
											rating?.rating || null,
											rating?.nextPopupId || null
										)
									}
								>
									{i + 1}
								</span>
							)
					  )
					: null}
			</div>
		</div>
	);
};

export default Rating;
