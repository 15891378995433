import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import orange_1 from '../../assets/images/Gym_Img/1_orange.svg';
import green_2 from '../../assets/images/Gym_Img/2_green.svg';
import red_3 from '../../assets/images/Gym_Img/3_red.svg';
import blue_4 from '../../assets/images/Gym_Img/4_blue.svg';
import yellow_5 from '../../assets/images/Gym_Img/5_yellow.svg';
import darkBlue_6 from '../../assets/images/Gym_Img/6_darkBlue.svg';
// import { fetchAllTagsByTab } from '../../redux/gym/gymSlice';
interface RevisionSheetCard {
	data: any;
	ind: number;
	selectRevisionSheet: (arg: any) => void;
	active: boolean;
	handleCardClick: (arg: any, a: number) => void;
	selectedOptions: any;
	sheetName: string;
	index: number;
}

const palletColors = [
	'#FDF2D6',
	'#E3FFED',
	'#FDE6E4',
	'#DCC9FF',
	'#FFC9F6',
	'#EBF3FE',
];

const RevisionSheetCard = (props: RevisionSheetCard) => {
	const {
		data,
		ind,
		selectRevisionSheet,
		active,
		handleCardClick,
		selectedOptions,
		sheetName,
		index,
	} = props;
	const gymState = useSelector((state: any) => state.gym);

	const dispatch = useDispatch();

	const {
		filteredData,
		solvedStatus,
		difficulty,
		name,
		tags,
		page,
		bookmarked,
		gymloading,
		courseId,
		questionSource,
		filterCourseName,
		revisionSheets,
		revisionSheetId,
		gymFilterObject,
	} = gymState;

	const cardStyle = (
		index: number,
		id: string
	): React.CSSProperties => {
		let color = '';
		let background = '';

		if (id === 'all_ques') {
			color = '#FF940A';
			background = `rgba(255, 148, 10, 0.4) url(${orange_1})`;
		} else {
			switch (index % 6) {
				case 0:
					color = '#FF940A';
					background = `rgba(255, 148, 10, 0.4) url(${orange_1})`;
					break;
				case 1:
					color = '#12B76A';
					background = `#E3FFED url(${green_2})`;
					break;
				case 2:
					color = '#2F80ED';
					background = `#EBF3FE url(${blue_4})`;
					break;
				case 3:
					color = '#EF574C';
					background = `#FFDFDC url(${red_3})`;
					break;

				case 4:
					color = '#F4C23E';
					background = `#FFE8AD url(${yellow_5})`;
					break;
				case 5:
					color = '#6E99D4';
					background = `#fff url(${darkBlue_6})`;
					break;
			}
		}
		// console.log('selectedOptions', selectedOptions);
		// console.log('selectedOptions', id);
		const border = selectedOptions.includes(id)
			? '2px solid rgb(50, 106, 151)'
			: '1px solid transparent';

		return {
			color,
			background,
			border,
			padding: '10px',
			margin: '10px',
			borderRadius: '10px',
			backgroundSize: '90%',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'right ',
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'space-between',
			zIndex: 100,
			boxSizing: 'border-box',
		};
	};

	return (
		<div className="item">
			<div
				className="revision-sheet-card-wrapper"
				style={cardStyle(ind, data.id)}
				onClick={() => handleCardClick(data.id, index)}
			>
				<p
					className="revision-sheet-heading"
					style={{
						fontSize: '16px',
						color: '#000',
						fontWeight: 700,
					}}
				>
					{sheetName}
				</p>

				{data.id !== 'all_ques' && (
					<p className="revision-sheet-text">
						{data.solvedCount}/{data.totalCount}
					</p>
				)}
			</div>
		</div>
	);
};

export default RevisionSheetCard;
