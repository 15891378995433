import React from 'react';
import Banner from './Banner';
import MarkDown from './MarkDown';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FiArrowRight } from 'react-icons/fi';
import queryString from 'query-string';
import { Flag } from '@mui/icons-material';
import moment from 'moment';
import { FiCalendar } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

interface ContestProps {
	contestData: any;
}

const OpenContest = ({ contestData }: ContestProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const contestId = queryString.parse(
		location.search
	).contestId;

	const contestInfoState = useSelector(
		(state: any) => state.contest
	);

	const { flag } = contestInfoState;

	return contestData !== null ? (
		<div>
			<Banner
				msg={
					flag === 'open-given'
						? {
								head: 'Your attempt has been recorded!',
								para: 'Wait for your score and the leaderboard to be updated.',
						  }
						: {
								head: 'Contest is LIVE!',
								para: 'All the best, attempt the questions now! ',
						  }
				}
				duration={contestData.duration}
				startTime={contestData.startTime}
				endTime={contestData.endTime}
				active={false}
				showProgress={false}
			/>
			<div className="contestTime">
				<FiCalendar />
				<p>
					<h5>Always Available</h5>
				</p>
			</div>
			<div className="instructions">
				<h2>Instructions</h2>
				<MarkDown statement={contestData.instructions} />
			</div>
		</div>
	) : null;
};

export default OpenContest;
