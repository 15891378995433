import React from 'react';
import Slider, {
	ResponsiveObject,
	Settings,
} from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import BookSessionCard from './BookSessionCard';

interface BookSessionSliderProps {
	data: BookSessionCardProps[];
}

interface BookSessionCardProps {
	isMentorship: boolean;
	lastAttemptDate: string;
	lastScore: string;
	sessionType: string;
	sessionTypeId: string;
	studentFeedbackLink: string;
	feedbackStatus: string;
	category: string;
}

const BooksessionSlider: React.FC<
	BookSessionSliderProps
> = ({ data }) => {
	const responsive: ResponsiveObject[] = [
		{
			breakpoint: 3000,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
			},
		},
		{
			breakpoint: 1440,
			settings: {
				slidesToShow: 2.5,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2,
			},
		},
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 1.5,
				slidesToScroll: 1,
			},
		},
		{
			breakpoint: 464,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
			},
		},
	];

	const settings: Settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		swipeToSlide: true,
		responsive: responsive,
	};

	if (data?.length > 0) {
		return (
			<div className="book-session-slider-wrapper">
				<Slider {...settings}>
					{data.map(
						(
							sessionData: BookSessionCardProps,
							index: number
						) => (
							<div key={index}>
								<BookSessionCard {...sessionData} />
							</div>
						)
					)}
				</Slider>
			</div>
		);
	} else {
		return null;
	}
};

export default BooksessionSlider;
