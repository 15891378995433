import React from 'react';
import { MdOutlineNavigateNext } from 'react-icons/md';
import shortlisted from '../../assets/images/icons/shortlisted.svg';
import notApplied from '../../assets/images/icons/notApplied.svg';
import applied from '../../assets/images/icons/applied.svg';
import onlineTest from '../../assets/images/icons/onlineTest.svg';
import technicalInterview from '../../assets/images/icons/technicalInterview.svg';
import hrRound from '../../assets/images/icons/hrRound.svg';
import { PopupState } from '../../pages/jobportal/JobPortal';
import Loader from '../Loader';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { JobPortalDashboardData } from '../../apis/JobPortalService';

const StudentPlacementOverview = ({
	setDrivesPopup,
}: {
	setDrivesPopup: (newState: PopupState) => void;
}) => {
	const jobPortalState = useSelector(
		(state: RootState) => state.jobPortal
	);
	const { jobPortalDashboardState } = jobPortalState;
	const { loading, jobPortalDashboard } =
		jobPortalDashboardState;

	const data = [
		{
			icon: shortlisted,
			title: 'Eligible',
		},
		{
			icon: notApplied,
			title: 'Not Applied',
		},
		{
			icon: applied,
			title: 'Applied',
		},
		{
			icon: onlineTest,
			title: 'Ongoing',
		},
		{
			icon: technicalInterview,
			title: 'Rejected',
		},
	];
	return (
		<div className="student-placement-overview">
			{loading && (
				<div
					style={{
						margin: '2rem 0',
						transform: 'scale(0.8)',
					}}
				>
					<Loader />
				</div>
			)}
			{!loading && jobPortalDashboard && (
				<>
					<h3>Dashboard</h3>
					<div className="placement-stages">
						{data.map((item, idx) => {
							if (item.title in jobPortalDashboard)
								return (
									<button
										onClick={() => {
											setDrivesPopup({
												isOpen: true,
												identifier: item.title,
											});
										}}
										key={idx}
										event-analytics={
											item.title === 'Eligible'
												? '80102971-69fd-4aa3-a01e-7c15607e6cd0'
												: item.title === 'Not Applied'
												? 'b4c7d52d-7c52-4402-b403-4c027739fc56'
												: item.title === 'Applied'
												? '4c9573bf-f3aa-42d4-babc-218a7ae21a03'
												: item.title === 'Ongoing'
												? 'e8afd3a4-890d-4cd6-bb52-8038549c8fb8'
												: item.title === 'Rejected'
												? 'bee8b31c-1597-4a6b-bf64-9a9bd1b1535b'
												: ''
										}
									>
										<img src={item.icon} />
										<p className="title">{item.title}</p>
										<p className="value">
											{
												jobPortalDashboard[
													item.title as keyof JobPortalDashboardData
												]
											}
										</p>
										<MdOutlineNavigateNext />
									</button>
								);
							return <></>;
						})}
					</div>
				</>
			)}
			{!loading && !jobPortalDashboard && (
				<p>Some error occured, please try again later.</p>
			)}
		</div>
	);
};

export default StudentPlacementOverview;
