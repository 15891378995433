import { Delete } from '@mui/icons-material';
import { Switch } from '@mui/joy';
import {
	IconButton,
	MenuItem,
	Select,
	TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { IoAddCircleOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import {
	deleteWorkExperience,
	updateExperienceDetails,
} from '../../../../../redux/profile/profile.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../../redux/store';
import AccioButton from '../../../../elements/Button';

import profileShareImg from '../../../../../assets/images/profileShareImg.svg';

export interface Experience {
	id?: string;
	companyName: string;
	position: string;
	startDate: string;
	endDate: string | null;
	description: string;
	isCurrent: boolean;
	jobType: string;
	experienceType: string;
	createdAt?: string;
	updatedAt?: string;
}

const emptyState = {
	companyName: '',
	position: '',
	startDate: '',
	endDate: '',
	description: '',
	isCurrent: false,
	jobType: 'FULL_TIME',
	experienceType: 'Coding Related',
};

export const WorkExperience: React.FC = () => {
	const inputRef = useRef<HTMLInputElement>(null);
	const myDivRef = useRef<HTMLDivElement>(null);
	const { experienceDetails } = useAppSelector(
		(state) => state.profile
	);

	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);

	const isProfileLocked = () => {
		return personalDetails.data?.isProfileLocked ?? false;
	};

	const dispatch = useAppDispatch();
	const [error, setError] = useState<string>('');
	const [experiences, setExperiences] = useState<
		Experience[]
	>([emptyState]);

	const [currentCtc, setCurrentCtc] = useState<number>(0);

	const handleAddExperience = () => {
		setExperiences([...experiences, emptyState]);
		const wrapperDiv = document.querySelector(
			'.profile-modal-wrapper'
		);
		setTimeout(() => {
			if (wrapperDiv) {
				const scrollHeight = wrapperDiv.scrollHeight;
				const height = wrapperDiv.clientHeight;
				const maxScrollTop = scrollHeight - height;
				wrapperDiv.scrollTop =
					maxScrollTop > 0 ? maxScrollTop : 0;
			}
		}, 200);
	};

	useEffect(() => {
		if (experienceDetails.data) {
			if (experienceDetails.data?.workExp) {
				const newArr = [...experienceDetails.data?.workExp];
				setExperiences(newArr);
			}
			if (experienceDetails.data?.currentCTC) {
				setCurrentCtc(experienceDetails.data?.currentCTC);
			}
		}
	}, [experienceDetails.data]);

	const handleInputChange = (
		index: number,
		field: keyof Experience,
		value: string | boolean
	) => {
		// Create a deep copy of the experiences array

		let newExperiences = experiences.map((exp, idx) =>
			idx !== index ? exp : { ...exp, [field]: value }
		);

		const currentExp = newExperiences[index];

		if (field === 'startDate' || field === 'endDate') {
			// If both dates are filled in, and the endDate is before the startDate
			if (
				currentExp.startDate &&
				currentExp.endDate &&
				currentExp.endDate < currentExp.startDate
			) {
				setError(
					'End date cannot be earlier than the start date.'
				);
				return;
			}

			if (
				hasOverlappingDates(
					newExperiences,
					index,
					currentExp.startDate,
					currentExp.endDate ?? ''
				)
			) {
				setError(
					'The dates overlap with another experience. Please choose different dates.'
				);
				return;
			}
		} else if (field === 'isCurrent') {
			newExperiences = newExperiences.map((job, i) => {
				if (job.isCurrent && i !== index) {
					return {
						...job,
						isCurrent: false,
					};
				}
				return job;
			});
		}

		setExperiences(newExperiences);
		setError('');
	};

	function isExperienceEmpty(exp: Experience): boolean {
		return (
			!exp.position &&
			!exp.companyName &&
			!exp.startDate &&
			!exp.endDate &&
			!exp.description
			// &&
			// !exp.ctc
		);
	}

	function isExperienceIncomplete(
		exp: Experience
	): boolean {
		return (
			!exp.position ||
			!exp.companyName ||
			!exp.startDate ||
			(exp.isCurrent ? false : !exp.endDate) ||
			!exp.description
		);
		// ||
		// !exp.ctc
	}

	function hasOverlappingDates(
		experiences: Experience[],
		currentIdx: number,
		start: string,
		end: string
	): boolean {
		for (let i = 0; i < experiences.length; i++) {
			// Skip the current experience being edited
			if (i === currentIdx) continue;

			const exp = experiences[i];

			// Check if either startDate or endDate is empty, and if so, skip this experience
			if (!exp.startDate || !exp.endDate) continue;

			if (
				(start >= exp.startDate && start <= exp.endDate) ||
				(end >= exp.startDate && end <= exp.endDate) ||
				(start <= exp.startDate && end >= exp.endDate)
			) {
				return true;
			}
		}
		return false;
	}

	const handleDeleteExperience = (index: number) => {
		const newExperiences = [...experiences];
		newExperiences.splice(index, 1);
		setExperiences(newExperiences);
		setError('');
		dispatch(
			deleteWorkExperience({
				id: experiences[index].id ?? '',
			})
		);
	};

	const handleSave = () => {
		const filteredExperiences = experiences.filter(
			(exp) => !isExperienceEmpty(exp)
		);

		// Check for incomplete experiences
		const incompleteExperiences =
			filteredExperiences.filter(isExperienceIncomplete);

		if (incompleteExperiences.length > 0) {
			setError(
				'All fields are mandatory. Please fill in the missing fields.'
			);
			toast.error('All fields are mandatory!');
			return;
		}

		// If everything is fine, clear the error and proceed
		setError('');
		console.log(
			'filteredExperiences>>>',
			filteredExperiences
		);

		const data = filteredExperiences.map((job) => {
			if (job.isCurrent) {
				return {
					...job,
					endDate: null,
				};
			}
			return job;
		});

		dispatch(
			updateExperienceDetails({
				workExp: data,
				currentCtc: Number(currentCtc),
			})
		);
	};

	useEffect(() => {
		if (error.length > 0) {
			const wrapperDiv = document.querySelector(
				'.profile-modal-wrapper'
			);
			setTimeout(() => {
				if (wrapperDiv) {
					wrapperDiv.scrollTop = 0;
				}
			}, 200);
		}
	}, [error]);

	return (
		<div
			className="work-exp-form"
			ref={myDivRef}
		>
			<div
				style={{
					display: 'flex',
					width: '100%',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<p style={{ color: '#EF4444', fontWeight: 500 }}>
					{error ? error : ''}
				</p>
				{experiences.length > 0 ? (
					<AccioButton
						variant={
							isProfileLocked() ? 'disable' : undefined
						}
						disabled={isProfileLocked()}
						title="Add Experience"
						icon={
							<IoAddCircleOutline
								style={{ fontSize: '18px' }}
							/>
						}
						iconPosition="right"
						action={handleAddExperience}
					/>
				) : null}
			</div>

			{experiences.length > 0 ? (
				experiences.map((experience, index) => (
					<div
						key={index}
						className="experience"
					>
						<div className="experience-heading-flex">
							<h1 className="experience-heading">
								Experience {index + 1}
							</h1>
							<div className="coding-experience-flex">
								<p className="coding-experience">
									Is this a Current Experience?
								</p>
								<Switch
									disabled={isProfileLocked()}
									checked={experience.isCurrent}
									onChange={(e) => {
										setCurrentCtc(0);
										handleInputChange(
											index,
											'isCurrent',
											e.target.checked
										);
									}}
								/>
								<IconButton
									disabled={isProfileLocked()}
									onClick={() =>
										handleDeleteExperience(index)
									}
								>
									<Delete />
								</IconButton>
							</div>
						</div>
						<div className="input-flex">
							<div style={{ width: '100%' }}>
								<p className="mui-custom-label">
									Job Title
									<span style={{ color: '#EF4444' }}>
										{' '}
										*
									</span>
								</p>
								<TextField
									disabled={isProfileLocked()}
									placeholder="Add Your Job Title"
									fullWidth
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
									value={experience.position}
									onChange={(e) =>
										handleInputChange(
											index,
											'position',
											e.target.value
										)
									}
								/>
							</div>
							<div style={{ width: '100%' }}>
								<p className="mui-custom-label">
									Company{' '}
									<span style={{ color: '#EF4444' }}>
										{' '}
										*
									</span>
								</p>
								<TextField
									disabled={isProfileLocked()}
									fullWidth
									placeholder="Add Your Company"
									value={experience.companyName}
									onChange={(e) =>
										handleInputChange(
											index,
											'companyName',
											e.target.value
										)
									}
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
								/>
							</div>
							<div style={{ width: '100%' }}>
								<p className="mui-custom-label">
									Start Date{' '}
									<span style={{ color: '#EF4444' }}>
										{' '}
										*
									</span>
								</p>
								<TextField
									disabled={isProfileLocked()}
									fullWidth
									type="date"
									onClick={(e) => {
										const elem =
											e.target as HTMLInputElement;
										elem?.showPicker();
									}}
									value={experience.startDate.split('T')[0]}
									onChange={(e) => {
										let date = new Date(e.target.value);
										date.setUTCHours(0, 0, 0, 0);
										let isoString = date.toISOString();
										handleInputChange(
											index,
											'startDate',
											isoString
										);
									}}
									InputLabelProps={{ shrink: true }}
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
								/>
							</div>
							<div style={{ width: '100%' }}>
								<p className="mui-custom-label">
									End Date{' '}
									{!experience.isCurrent ? (
										<span style={{ color: '#EF4444' }}>
											{' '}
											*
										</span>
									) : (
										''
									)}
								</p>
								{
									<TextField
										fullWidth
										type="date"
										onClick={(e) => {
											const elem =
												e.target as HTMLInputElement;
											elem?.showPicker();
										}}
										disabled={
											isProfileLocked() ??
											experience.isCurrent
										}
										value={
											experience.isCurrent
												? ''
												: experience.endDate?.split('T')[0]
										}
										onChange={(e) => {
											let date = new Date(e.target.value);
											date.setUTCHours(0, 0, 0, 0);
											let isoString = date.toISOString();
											handleInputChange(
												index,
												'endDate',
												isoString
											);
										}}
										sx={{
											margin: '6px 0px',
											'& .MuiInputBase-input': {
												padding: '10px 12px',
												fontSize: '14px',
												color: '#8C9AAC',
												cursor: isProfileLocked()
													? 'not-allowed'
													: 'default',
											},
										}}
										InputLabelProps={{ shrink: true }}
									/>
								}
							</div>
						</div>

						{/* Job Type */}
						<div
							className="half-input"
							style={{ marginBottom: '16px' }}
						>
							<div style={{ width: '24%' }}>
								<p className="mui-custom-label">
									Job Type{' '}
									<span style={{ color: '#EF4444' }}>
										{' '}
										*
									</span>
								</p>
								<Select
									disabled={isProfileLocked()}
									fullWidth
									value={experience.jobType}
									onChange={(e) =>
										handleInputChange(
											index,
											'jobType',
											e.target.value
										)
									}
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
								>
									<MenuItem value="FULL_TIME">
										Full Time
									</MenuItem>
									<MenuItem value="PART_TIME">
										Part Time
									</MenuItem>
									<MenuItem value="INTERNSHIP">
										Internship
									</MenuItem>
									<MenuItem value="CONTRACT">
										Contract
									</MenuItem>
								</Select>
							</div>
							<div style={{ width: '24%' }}>
								<p className="mui-custom-label">
									Experience Type{' '}
									<span style={{ color: '#EF4444' }}>
										{' '}
										*
									</span>
								</p>
								<Select
									disabled={isProfileLocked()}
									fullWidth
									value={experience.experienceType}
									onChange={(e) =>
										handleInputChange(
											index,
											'experienceType',
											e.target.value
										)
									}
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '10px 12px',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
								>
									<MenuItem value="Coding Related">
										Coding Related
									</MenuItem>
									<MenuItem value="Not Coding Related">
										Not Coding Related
									</MenuItem>
								</Select>
							</div>
							{experience.isCurrent ? (
								<div style={{ width: '24%' }}>
									<p className="mui-custom-label">
										Current CTC{' '}
										<span style={{ color: '#EF4444' }}>
											{' '}
											*
										</span>
									</p>
									<TextField
										disabled={isProfileLocked()}
										type="number"
										fullWidth
										value={currentCtc}
										onChange={(e) =>
											setCurrentCtc(Number(e.target.value))
										}
										sx={{
											margin: '6px 0px',
											'& .MuiInputBase-input': {
												padding: '10px 12px',
												fontSize: '14px',
												color: '#8C9AAC',
												cursor: isProfileLocked()
													? 'not-allowed'
													: 'default',
											},
										}}
									/>
								</div>
							) : null}
						</div>
						<div className="full-input">
							<div style={{ width: '100%' }}>
								<p className="mui-custom-label">
									Job Description
								</p>
								<TextField
									disabled={isProfileLocked()}
									placeholder="Add Your Job Description"
									sx={{
										margin: '6px 0px',
										'& .MuiInputBase-input': {
											padding: '2x',
											fontSize: '14px',
											color: '#8C9AAC',
											cursor: isProfileLocked()
												? 'not-allowed'
												: 'default',
										},
									}}
									fullWidth
									multiline
									rows={2}
									value={experience.description}
									onChange={(e) =>
										handleInputChange(
											index,
											'description',
											e.target.value
										)
									}
								/>
							</div>
						</div>
					</div>
				))
			) : (
				<div className="no-experience">
					<div className="img-div">
						<img
							src={profileShareImg}
							className="boy-img"
						/>
						<p className="heading">
							No Work Experience Found
						</p>
						<p className="text">
							Please Add Your Work Experiences
						</p>
						<AccioButton
							disabled={isProfileLocked()}
							variant={
								isProfileLocked() ? 'disable' : undefined
							}
							title="Add Experience"
							icon={
								<IoAddCircleOutline
									style={{ fontSize: '18px' }}
								/>
							}
							iconPosition="right"
							action={handleAddExperience}
						/>
					</div>
				</div>
			)}

			<div className="save-form">
				<p className="text">
					Do you want to save changes ?
				</p>
				<AccioButton
					variant={
						isProfileLocked() ? 'disable' : 'primary'
					}
					disabled={isProfileLocked()}
					title={'Save'}
					action={handleSave}
				/>
			</div>
		</div>
	);
};
