import { Box, Button, Dialog } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { services } from '../../../apis';
import { PopupTypes } from '../../../redux/popups/popup.type';

function EnterCollegeName({
	open,
	setOpen,
	type,
	selectedCollege = '',
}: any) {
	const [results, setResults] = useState<any[]>([]);
	const [collegeName, setCollegeName] = useState('');
	const [collegeId, setCollegeId] = useState('');
	const [
		displaySelectedCollege,
		setDisplaySelectedCollege,
	] = useState(true);
	const [updatingClg, setUpdatingClg] = useState(false);
	const [updatingClgId, setUpdatingClgId] = useState('');
	const [updatingClgName, setUpdatingClgName] =
		useState('');

	const notifySuccess = (msg: string) => {
		toast.success(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const notifyFailure = (msg: string) => {
		toast.error(msg, {
			position: toast.POSITION.TOP_RIGHT,
			autoClose: 2000,
		});
	};

	const popupType =
		type === PopupTypes.VERIFY_UG_COLLEGE ? 'UG' : 'PG';

	const submitCollegeData = async () => {
		let response: any;
		if (!updatingClg) {
			// response = uploadClgData(collegeId, collegeName)
			if (collegeId.length === 0) {
				response =
					await services.userService.updateCollegeName(
						collegeName,
						null,
						popupType
					);
			} else {
				response =
					await services.userService.updateCollegeName(
						collegeName,
						collegeId,
						popupType
					);
			}
		} else {
			// response = uploadClgData(updatingClgId, updatingClgName)
			if (updatingClgId.length === 0) {
				response =
					await services.userService.updateCollegeName(
						updatingClgName,
						null,
						popupType
					);
			} else {
				response =
					await services.userService.updateCollegeName(
						updatingClgName,
						updatingClgId,
						popupType
					);
			}
		}
		console.log('response', response);
		if (response && response.statusCode)
			notifyFailure(response.message);
		else {
			setOpen(false);
			notifySuccess(response.message);
		}
	};

	const searchColleges = async (e: any) => {
		const input: string = e.target.value;
		if (updatingClg) setUpdatingClgName(input);
		else setCollegeName(input);

		if (input.length > 2) {
			const results =
				await services.userService.getCollegeNames(input);
			setResults(results);
		} else {
			setResults([]);
		}
	};

	useEffect(() => {
		if (selectedCollege)
			setUpdatingClg(selectedCollege.length > 0);
	}, []);

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
		>
			<Box sx={{ padding: '1em' }}>
				<div className="get-college-name-container">
					<p>
						<b>
							Selected College :{' '}
							<span style={{ color: '#2f80ed' }}>
								{!updatingClg &&
									`${
										displaySelectedCollege
											? selectedCollege
											: collegeId
											? collegeName
											: 'None'
									}`}
								{updatingClg &&
									`${
										displaySelectedCollege
											? selectedCollege
											: updatingClgId
											? updatingClgName
											: 'None'
									}`}
							</span>
						</b>
					</p>
					<div className="college-search-top">
						<input
							autoFocus
							id="outlined-basic"
							placeholder="Enter Your College Name"
							className="search-input"
							onChange={(e) => {
								searchColleges(e);
								updatingClg
									? setUpdatingClgId('')
									: setCollegeId('');
							}}
							value={
								updatingClg ? updatingClgName : collegeName
							}
						/>
					</div>
					<div className="search-results-container">
						{results &&
							results.map((result: any) => (
								<div
									key={result.id}
									className="search-result"
									onClick={() => {
										setDisplaySelectedCollege(false);
										// updateClg(result)
										if (updatingClg) {
											setUpdatingClgId(result.id);
											setUpdatingClgName(
												result.college_name
											);
										} else {
											setCollegeId(result.id);
											setCollegeName(result.college_name);
										}
									}}
									// onClick={(result) => handleCollegeSelect(result)}
									style={{
										backgroundColor: `${
											result.id === collegeId
												? '#ebf3fe'
												: ''
										}`,
										color: `${
											result.id === collegeId
												? '#2f80ed'
												: ''
										}`,
									}}
								>
									<p className="college-name">
										{result.college_name}
									</p>
									<p className="college-location">
										{result.college_city}
									</p>
								</div>
							))}
					</div>

					<div
						style={{
							position: 'absolute',
							bottom: '0',
							width: '100%',
							margin: 'auto',
						}}
					>
						{((!updatingClg &&
							collegeName !== '' &&
							collegeName.length > 2) ||
							(updatingClg &&
								updatingClgName !== '' &&
								updatingClgName.length > 2)) && (
							<p className="not-found-instruction">
								Could not find your college name in search
								results ? <br />
								<span className="enter-full-name">
									Enter college full name and click on
									submit.
								</span>
							</p>
						)}

						<Button
							style={{ width: '100%', margin: 'auto' }}
							variant="contained"
							onClick={submitCollegeData}
						>
							Submit
						</Button>
					</div>
				</div>
			</Box>
		</Dialog>
	);
}

export default EnterCollegeName;
