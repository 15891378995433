import {
	Box,
	ClickAwayListener,
	Tooltip,
} from '@mui/material';

import React, { ReactNode } from 'react';

type AccioClickTooltipProps = {
	handleTooltipClose: () => void;
	open: boolean;
	children: any;
	tooltipDisplayItem: ReactNode;
};

/**
 * Renders a ClickAwayListener component with a Tooltip.
 *
 * @param handleTooltipClose - Function to handle tooltip close event.
 * @param open - Boolean indicating whether the tooltip is open.
 * @param children - ReactNode representing the content inside the tooltip.
 * @param tooltipDisplayItem - ReactNode representing the content of the tooltip display item.
 */
export const AccioClickTooltip: React.FC<
	AccioClickTooltipProps
> = ({
	handleTooltipClose,
	open,
	children,
	tooltipDisplayItem,
}) => (
	<ClickAwayListener onClickAway={handleTooltipClose}>
		<div>
			<Tooltip
				PopperProps={{
					disablePortal: true,
				}}
				onClose={handleTooltipClose}
				open={open}
				disableFocusListener
				disableHoverListener
				disableTouchListener
				title={
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							maxWidth: 320,
							justifyContent: 'center',
							p: 1,
						}}
					>
						{tooltipDisplayItem}
					</Box>
				}
			>
				{children}
			</Tooltip>
		</div>
	</ClickAwayListener>
);
