// import unlocked from '../../../../assets/images/unlocked.svg';
import { useState } from 'react';
import level1 from '../../../../assets/images/acheivments/level1.svg';
import level2 from '../../../../assets/images/acheivments/level2.svg';
import level3 from '../../../../assets/images/acheivments/level3.svg';
import level4 from '../../../../assets/images/acheivments/level4.svg';
import level5 from '../../../../assets/images/acheivments/level5.svg';

export const AchievementHorizontalCard = ({
	level,
	myLevel,
	streak,
	total,
	message,
}: {
	level: number;
	myLevel: number;
	streak: number;
	total: Array<number>;
	message: Array<string>;
}) => {
	const filledStyle = {
		width: `${(streak / total[level - 1]) * 100}%`,
		backgroundImage:
			'linear-gradient(90deg, #9B2FED 0%, #3187F7 100%)',
	};

	const naming = [
		'Noobie',
		'Apprentice',
		'Capable',
		'Proficient',
		'Master',
	];
	console.log('MyLevel>>>', myLevel);
	const [locked, setLocked] = useState(level > myLevel);

	const getImage = () => {
		if (level == 1) {
			return level1;
		} else if (level == 2) {
			return level2;
		} else if (level == 3) {
			return level3;
		} else if (level == 4) {
			return level4;
		} else if (level == 5) {
			return level5;
		}
	};

	return (
		<div
			className="achievement-horizontal-card"
			style={{
				filter: locked ? 'grayscale(0.9)' : 'grayscale(0)',
			}}
		>
			<div className="achievement-img-div">
				<img
					src={getImage()}
					className="achievement-img"
				/>
				<p className="achievement-p">
					{locked ? 'Locked' : 'Unlocked'}
				</p>
			</div>
			<div className="right-div">
				<p className="heading">{naming[level - 1]}</p>
				<p className="sub-heading">Level {level}</p>
				<p className="desc">{message[level - 1]}</p>
				<div className="progress-bar-container">
					<div
						className="progress-bar-filled"
						style={filledStyle}
					></div>
				</div>
				<p className="xp-text">
					{streak}/{total[level - 1]}
				</p>
			</div>
		</div>
	);
};
