import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService/HttpService';

export class SoftskillsService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getSoftSkillsCourses() {
		const url = `${this.endpoint}/soft-skills/fetch-courses`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getSoftSkillsModules(courseId: string) {
		const url = `${this.endpoint}/soft-skills/fetch-modules/${courseId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getSoftSkillsModuleDetails(moduleId: string) {
		const url = `${this.endpoint}/soft-skills/fetch-module-details/${moduleId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async uploadVideo(moduleId: string, link: string) {
		const url = `${this.endpoint}/soft-skills/upload-video`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { moduleId, link },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getLiveClassJoinLink(moduleId: string) {
		const url = `${this.endpoint}/soft-skills/join-link/${moduleId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async registerForSession({
		moduleId,
		videoId,
	}: {
		moduleId: string;
		videoId: string;
	}) {
		const url = `${this.endpoint}/soft-skills/register-breakout-session`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { moduleId, videoId },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getBreakoutClassJoinLink({
		moduleId,
		videoId,
	}: {
		moduleId: string;
		videoId: string;
	}) {
		const url = `${this.endpoint}/soft-skills/link-breakout-session`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					data: { moduleId, videoId },
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
