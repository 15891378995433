import React, { useEffect, useState } from 'react';
import {
	AiFillPieChart,
	AiTwotoneFire,
} from 'react-icons/ai';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import EffortGuage from './EffortGuage';
import { FiArrowRight } from 'react-icons/fi';
import { CgGym } from 'react-icons/cg';
import {
	IoIosArrowDown,
	IoIosArrowUp,
} from 'react-icons/io';

import EffortBox from './../assets/images/effort-box.svg';
import {
	BsFillPersonFill,
	BsInfoCircle,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';

import { services } from '../apis';
import Carousel from 'react-bootstrap/Carousel';
import Loader from './Loader';
import { isMainCourseUser } from '../utils/events';
import { BiInfoCircle } from 'react-icons/bi';

export interface EffortScoreDataInterface {
	openEffortModal: any;
	showGauge?: boolean;
}

export const streakNumber = (
	streak: number,
	restoreStreakNumber: number
): { streak: number; message: string | null } => {
	let returnObj: {
		streak: number;
		message: string | null;
	} = {
		streak: streak,
		message: null,
	};
	switch (restoreStreakNumber) {
		case 0:
			returnObj.streak = streak;
			returnObj.message = `Your streak is ${streak}. Keep Solving!`;
			break;
		case -1:
			returnObj.streak = 0;
			returnObj.message = `Your streak was broken, and please keep solving questions consistently`;
			break;
		case 3:
		case 6:
		case 9:
			returnObj.streak = 0;
			if (
				restoreStreakNumber === 3 ||
				restoreStreakNumber === 6 ||
				restoreStreakNumber === 9
			) {
				returnObj.message = `Your streak was ${streak}. Solve ${restoreStreakNumber} questions to restore streak.`;
			}
			break;
	}
	console.log({ streak, restoreStreakNumber, returnObj });
	return returnObj;
};

const EffortScore = ({
	openEffortModal,
	showGauge,
}: EffortScoreDataInterface) => {
	const userState = useSelector((state: any) => state.user);

	const { courseList, user } = userState;

	const [effortData, setEffortData] = useState<any>(null);

	const [showGaugeCheck, setShowGaugeCheck] =
		useState(false);

	useEffect(() => {
		if (showGauge === undefined || showGauge === true) {
			setShowGaugeCheck(true);
		} else {
			setShowGaugeCheck(false);
		}
	}, [showGauge]);

	const [isAnalysisOpen, setIsAnalysisOpen] =
		useState(true);
	const [loading, setLoading] = useState(false);
	const [streakData, setStreakData] = useState<{
		streak: number;
		message: string | null;
	} | null>(null);

	const getData = async (courseId: string) => {
		try {
			setLoading(true);
			const res =
				await services.courseService.getEffortScoreData(
					courseId
				);
			console.log('effort res', res);
			setEffortData(res);
			setStreakData(
				streakNumber(res.streak, res.restoreStreakNumber)
			);
			setLoading(false);
		} catch (e) {
			console.log(e);
		}
	};

	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (courseList.length > 0) {
			getData(courseList[index].id);
		}
	}, [courseList, index]);

	const handleSelect = (selectedIndex: number) => {
		setIndex(selectedIndex);
		console.log('bootstrap', selectedIndex);
	};

	// const isDataCourseUser = () => {
	// 	if(user && user.roles && user.roles[0] === 'data-maincourse-user')
	// 		return true;
	// 	return false;
	// }

	const [isDataCourseUser, setIsDataCourseUser] =
		useState<boolean>(false);

	useEffect(() => {
		if (
			user &&
			user.roles &&
			user.roles[0] === 'data-maincourse-user'
		)
			setIsDataCourseUser(true);
	}, [user]);

	return effortData !== null ? (
		<div className={'effort-score'}>
			<div className={'section-heading'}>Your Module</div>
			<div className={'module-content'}>
				<div className={'module-carousel'}>
					<div className={'carousel-container'}>
						{courseList.length > 0 ? (
							<Carousel
								activeIndex={index}
								onSelect={handleSelect}
								interval={null}
							>
								{courseList.map(
									(el: any, index: number) => {
										return (
											<Carousel.Item key={el.id}>
												<h3
													className={
														'module-carousel-title'
													}
												>
													{el.title}
												</h3>
											</Carousel.Item>
										);
									}
								)}
							</Carousel>
						) : null}
					</div>
					{/* <div className={'module-whats-new'}>
						{isDataCourseUser ? (
							<>
								<div>
									<h3>
										Know your <br />
										course performance
									</h3>
								</div>
							</>
						) : (
							<>
								<div>
									<h3>
										Why is it <br />
										important ?
									</h3>
									<button
										onClick={() => openEffortModal(true)}
										event-analytics={
											isMainCourseUser()
												? '63887991-d4e6-4a12-a47f-390c522183a6'
												: ''
										}
									>
										Know more
									</button>
								</div>
							</>
						)}
						<img
							src={EffortBox}
							alt={'box'}
						/>
					</div> */}
				</div>
				{isAnalysisOpen && !loading ? (
					<>
						<div className={'module-efforts'}>
							{showGaugeCheck &&
							user &&
							user.roles &&
							user.roles[0] !== 'data-maincourse-user' &&
							effortData.jobSincerity !== null ? (
								<div className={'module-effort-score'}>
									<EffortGuage
										score={
											parseInt(
												effortData.jobSincerity.toFixed(1)
											) * 10
										}
									/>
									<p>
										{effortData.jobSincerity.toFixed(1)}
										<span>/10</span>
									</p>
									<h4>Your Module</h4>
								</div>
							) : null}
							<div
								className={`module-activeness ${
									isDataCourseUser
										? 'data-course-module-activeness'
										: ''
								}`}
							>
								<div className={'item'}>
									<AiFillPieChart />
									<p>
										<h3>
											{
												effortData.studentSolvedAssignmentQuestions
											}
											/
											{
												effortData.studentAssignmentQuestions
											}
										</h3>
										<p>Assignment Score</p>
									</p>
								</div>
								<div className={'item attendance'}>
									<BsFillPersonFill />
									<p>
										<h3>{effortData.studentAttendance}</h3>
										<p>Attendance</p>
									</p>
								</div>
								{!isDataCourseUser && (
									<>
										<div className={'item streak'}>
											<AiTwotoneFire />
											<p>
												<h3>{streakData?.streak}</h3>
												<p>Streak</p>
											</p>
											<p className={'info'}>
												<BiInfoCircle />
												<p className="streak-message">
													{streakData?.message}
												</p>
											</p>
										</div>
										<div className={'item gym'}>
											<CgGym />
											<p>
												<h3>
													{
														effortData.studentSolvedGymQuestions
													}
													/{effortData.studentGymQuestions}
												</h3>
												<p>Gym Score</p>
											</p>
										</div>
									</>
								)}
							</div>
						</div>
						<div
							className={'weak-topic-analysis'}
							style={{
								height: isAnalysisOpen
									? 'max-content'
									: 'Opx',
								display: 'none',
							}}
						>
							<h2>Weak Topic Analysis</h2>
							<ul>
								<li className={'title-bar'}>
									<p className={'col1'}>Topic Name</p>
									<p className={'col2'}>Question Solved</p>
									<p className={'col3'}>Recommendation</p>
								</li>
								<li className={'table-item'}>
									<p className={'col1'}>Foundation</p>
									<p className={'col2'}>8/30</p>
									<p className={'col3'}>
										<button>
											Solve Question <FiArrowRight />
										</button>
									</p>
								</li>
								<li className={'table-item'}>
									<p className={'col1'}>Foundation</p>
									<p className={'col2'}>8/30</p>
									<p className={'col3'}>
										<button>
											Solve Question <FiArrowRight />
										</button>
									</p>
								</li>
							</ul>
						</div>
					</>
				) : loading ? (
					<Loader />
				) : null}

				<div
					className={'close-analysis'}
					onClick={() => {
						setIsAnalysisOpen(!isAnalysisOpen);
					}}
				>
					{isAnalysisOpen ? (
						<span>
							Close Analysis <IoIosArrowUp />
						</span>
					) : (
						<span>
							Open Analysis <IoIosArrowDown />{' '}
						</span>
					)}
				</div>
			</div>
		</div>
	) : null;
};

export default EffortScore;
