import CircularProgress from '@mui/joy/CircularProgress';
import LinearProgress from '@mui/joy/LinearProgress';
import { QuestionAnalaysisDataInterface } from './QuestionsCard';

interface QuestionsPropInterface {
	value: number;
	data?: QuestionAnalaysisDataInterface | null;
}

export const QuestionsCardData = ({
	value,
	data,
}: QuestionsPropInterface) => {
	if (data)
		return (
			<div className="questions-card-data">
				<div className="circular-div">
					<CircularProgress
						determinate
						size="lg"
						value={
							(data.totalSolved / data.totalQuestions) * 100
						}
						variant="solid"
						sx={{
							'--CircularProgress-size': '130px',
							'--CircularProgress-trackThickness': '6px',
							'--CircularProgress-trackColor': '#D5E6FB',
							'--CircularProgress-progressThickness': '6px',
							'--CircularProgress-progressColor': '#2F80ED',
						}}
					>
						<div className="inside-circle">
							<p className="normal-text">TOTAL QUESTIONS</p>
							<p className="heavy-text">
								{data.totalQuestions}
							</p>
						</div>
					</CircularProgress>
				</div>
				{data.data ? (
					<div className="progress-div">
						<div className="question-progress">
							<p className="difficulty">Easy</p>
							<div className="progress-flex">
								<LinearProgress
									determinate
									size="lg"
									value={parseInt(data.data[0]?.percentage)}
									variant="solid"
									sx={{
										backgroundColor: '#D5E6FB',
										color: '#2F80ED',
									}}
								/>
								<div className="progress-text">
									{data.data[0]?.percentage}%
									<span className="sub-text">
										{data.data[0]?.solved}/
										{data.data[0]?.total}
									</span>
								</div>
							</div>
						</div>
						<div className="question-progress">
							<p
								className="difficulty"
								style={{ color: '#FAC515' }}
							>
								Medium
							</p>
							<div className="progress-flex">
								<LinearProgress
									determinate
									size="lg"
									value={parseInt(data.data[1]?.percentage)}
									variant="solid"
									sx={{
										backgroundColor: '#D5E6FB',
										color: '#2F80ED',
									}}
								/>
								<div className="progress-text">
									{data.data[1].percentage}%
									<span className="sub-text">
										{data.data[1]?.solved}/
										{data.data[1]?.total}
									</span>
								</div>
							</div>
						</div>
						<div className="question-progress">
							<p
								className="difficulty"
								style={{ color: '#EF4444' }}
							>
								Hard
							</p>
							<div className="progress-flex">
								<LinearProgress
									determinate
									size="lg"
									value={parseInt(data.data[2]?.percentage)}
									variant="solid"
									sx={{
										backgroundColor: '#D5E6FB',
										color: '#2F80ED',
									}}
								/>
								<div className="progress-text">
									{data.data[2]?.percentage}%
									<span className="sub-text">
										{data.data[2]?.solved}/
										{data.data[2]?.total}
									</span>
								</div>
							</div>
						</div>
					</div>
				) : null}
			</div>
		);
	else return null;
};
