import React from 'react';
import JourneyCustomCard from './JourneyCustomCard';

const ModuleJourneyCard = ({ cardsData }: any) => {
	return (
		<div>
			{cardsData.units.map((cardData: any) => (
				<JourneyCustomCard
					cardData={cardData}
					cardCatgory={cardsData.category}
					key={cardData.id}
				/>
			))}
		</div>
	);
};

export default ModuleJourneyCard;
