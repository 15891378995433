import React from 'react';
import Input from '@mui/joy/Input';

const InputField = ({
	inputParentClass,
	inputCustomClass,
	placeHolder,
	id,
	type,
	value,
	onChange,
	showError,
	errorMessage,
	disabled,
}: any) => {
	const inputStyle = {
		'& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
			{
				WebkitAppearance: 'none',
				margin: 0,
			},
		'& input[type=number]': {
			MozAppearance: 'textfield',
			width: '100%',
		},
	};

	return (
		<div className={`${inputParentClass} input-container`}>
			{showError ? (
				<span className="error-message">
					{errorMessage}
				</span>
			) : null}
			<Input
				disabled={disabled}
				sx={inputStyle}
				id={id}
				placeholder={placeHolder}
				className={inputCustomClass}
				type={type}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

export default InputField;
