import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { services } from '../../apis';
import {
	fetchJobCards,
	fetchMockScoreCards,
} from '../../redux/jobportal/jobPortalSlice';
import { useAppDispatch } from '../../redux/store';
import AccioButton from '../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../notification';

const NotInterestedForm = ({
	jobId,
	setInterestedPopup,
	setJobDetailsPopup,
}: any) => {
	const dispatch = useAppDispatch();
	const [selectedReasons, setSelectedReasons] =
		useState<string>();
	const [otherReason, setOtherReason] =
		useState<string>('');

	const handleChange = (
		event: SelectChangeEvent<string>
	) => {
		setSelectedReasons(event.target.value as string);
	};

	const handleSubmit = async () => {
		if (
			selectedReasons === 'Others' &&
			otherReason === ''
		) {
			notifyError("Please provide a reason for 'Others'");
			return;
		}
		// Process the request here
		let reason = selectedReasons;
		if (reason === 'Others') {
			console.log('Other Reason:', otherReason);
			reason = otherReason;
		}

		if (reason) {
			const status =
				await services.jobPortalService.markNotInterested(
					jobId,
					reason
				);
			if (status === 201) {
				notifySuccess(
					'Company Successfully Marked As Not Interested!'
				);
				// refetching the cards again
				dispatch(fetchJobCards());
				dispatch(fetchMockScoreCards());
				setInterestedPopup(false);
				setJobDetailsPopup({
					isOpen: false,
					identifier: '',
				});
			} else {
				notifyError('Something Went Wrong!');
			}
		} else {
			notifyError('Please select a reason!');
		}
	};

	const reasonArr = [
		'Not comfortable with Relocation',
		'Not Comfortable with the Bond Offered',
		'Not Comfortable with the CTC',
		'Not Comfortable with the Internship',
		'Others',
	];

	return (
		<div>
			<Typography
				color="error"
				sx={{ fontSize: '20px' }}
			>
				You will be letting go of this opportunity and it
				will not be possible for you to apply for this job
				again!
			</Typography>
			<FormControl
				fullWidth
				variant="outlined"
				margin="normal"
			>
				<InputLabel id="reason-label">
					Please select a reason
				</InputLabel>
				<Select
					labelId="reason-label"
					value={selectedReasons}
					onChange={handleChange}
					label="Please select a reason"
					renderValue={(selected) => selected as string}
				>
					{reasonArr.map((item, i) => (
						<MenuItem
							value={item}
							key={i}
						>
							{item}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{selectedReasons === 'Others' && (
				<TextField
					fullWidth
					variant="outlined"
					margin="normal"
					required
					label="Other reason"
					value={otherReason}
					onChange={(e) => setOtherReason(e.target.value)}
				/>
			)}
			<div style={{ marginTop: '16px' }}>
				<AccioButton
					title="Mark Job As Not Interested"
					action={handleSubmit}
				/>
			</div>
		</div>
	);
};

export default NotInterestedForm;
