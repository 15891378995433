import { useEffect } from 'react';
import { BiUser } from 'react-icons/bi';
import { useDispatch, useSelector } from 'react-redux';
import {
	getEligibleSessionsOfUser,
	getRecommendedSessions,
	getRemainingBookings,
} from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import BookSessionCard from './BookSessionCard';
import BookSessionSlider from './BookSessionSlider';
import SessionRecommended from './SessionRecommended';
import RemainingSessionBooking from './RemainingSessionBooking';

interface BookSessionCardProps {
	isMentorship: boolean;
	lastAttemptDate: string;
	lastScore: string;
	sessionType: string;
	sessionTypeId: string;
	studentFeedbackLink: string;
	feedbackStatus: string;
	showCard: boolean;
	category: string;
}

const BookSessionBox = () => {
	const dispatch = useDispatch<AppDispatch>();

	const SessionState = useSelector(
		(state: any) => state.session
	);

	const {
		allBookingSessions,
		allRecommendedSession_Suggested_Upcoming_Sessions,
		allSessionData,
		remainingBookings,
	} = SessionState;

	const userState = useSelector((state: any) => state.user);

	const { user } = userState;

	useEffect(() => {
		console.log('All good Out!!');
		if (allBookingSessions === null) {
			console.log('All good');
			dispatch(getEligibleSessionsOfUser());
		}
	}, [allBookingSessions]);

	console.log('allSessionData', allSessionData);

	useEffect(() => {
		console.log('All good Out!!');
		if (
			user &&
			allRecommendedSession_Suggested_Upcoming_Sessions ===
				null
		) {
			const userType =
				user?.roles[0] === 'mentor' ? 'MENTOR' : 'USER';
			const payload = { userType, userId: user?.id };
			dispatch(getRecommendedSessions(payload));
		}
	}, [
		allRecommendedSession_Suggested_Upcoming_Sessions,
		user,
	]);

	useEffect(() => {
		if (user) {
			dispatch(getRemainingBookings());
		}
	}, [user]);

	console.log(
		'allRecommendedSession_Suggested_Upcoming_Sessions',
		allRecommendedSession_Suggested_Upcoming_Sessions
	);

	const allBookingSessionDataAfterFilterNotToShowCard: BookSessionCardProps[] =
		allBookingSessions && allBookingSessions?.length > 0
			? allBookingSessions?.filter(
					(Obj: BookSessionCardProps) => Obj.showCard
			  )
			: [];

	return (
		<div className="session-book-box-wrapper">
			<div className="slider-container">
				<p className="slider-title">
					<span className="text">Book sessions</span>
				</p>
				<p className="slider-subtitle-session-info ">
					All your eligible sessions that you can book are
					shown here
				</p>
				{allSessionData?.totalSessionsLimit ===
				null ? null : (
					<p
						className={`session-left-display ${
							allSessionData?.totalSessionsLimit -
								allSessionData?.sessionsTaken ===
							0
								? 'limited'
								: ''
						}`}
					>
						<BiUser
							style={{ color: '#505862', fontSize: '18px' }}
						/>
						<span className="text">
							Session Limit :&nbsp;
							{allSessionData?.totalSessionsLimit -
								allSessionData?.sessionsTaken <
							10
								? `0${
										allSessionData?.totalSessionsLimit -
										allSessionData?.sessionsTaken
								  }`
								: allSessionData?.totalSessionsLimit -
								  allSessionData?.sessionsTaken}{' '}
							Left
						</span>
					</p>
				)}

				{allBookingSessions &&
				!allSessionData?.restrictUserSessions &&
				allBookingSessionDataAfterFilterNotToShowCard.length >
					0 ? (
					allBookingSessionDataAfterFilterNotToShowCard.length >=
					3 ? (
						<BookSessionSlider data={allBookingSessions} />
					) : (
						<div className="book-session-slider-wrapper display">
							{allBookingSessionDataAfterFilterNotToShowCard.map(
								(
									sessionData: BookSessionCardProps,
									index: number
								) => (
									<div key={`${index}`}>
										<BookSessionCard {...sessionData} />
									</div>
								)
							)}
						</div>
					)
				) : (
					<p className="no-data">
						You don't have any eligible sessions
					</p>
				)}
			</div>

			{remainingBookings ? (
				<RemainingSessionBooking />
			) : allRecommendedSession_Suggested_Upcoming_Sessions ? (
				<SessionRecommended
					sessionRecommended={
						allRecommendedSession_Suggested_Upcoming_Sessions?.recommendedSessions
					}
				/>
			) : null}
		</div>
	);
};

export default BookSessionBox;
