import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

export const fetchCourseData = createAsyncThunk(
	'course/fetchCourseData',
	async () => {
		try {
			const res =
				await services.courseService.getCourseData();
			// const dataApi =
			// 	await services.courseService.getFullCourseQuestionsForModule(
			// 		res.latestAssignment.module.id
			// 	);
			// res.latestAssignment = dataApi;
			console.log('RES->', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const addDoubt = createAsyncThunk(
	'course/addDoubt',
	async (data: any) => {
		try {
			const res = await services.courseService.addLiveDoubt(
				data
			);
			console.log('RES->', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchRecordings = createAsyncThunk(
	'module/recordings',
	async (data: any) => {
		try {
			const res =
				await services.courseService.getRecordings(data);
			console.log('RECORDINGS->', res);
			if (res[0].videos.length === 0) return null;
			else return res[0].videos;
		} catch (error) {
			console.log(error);
		}
	}
);

export const fetchMeetingIDs = createAsyncThunk(
	'course/fetchMeetingIDs',
	async (courseId: string) => {
		try {
			const res =
				await services.courseService.getUpcomingMeetingIds(
					courseId
				);
			console.log(res, res.length === 0);
			if (res.length === 0) return null;
			const lecture = res.filter(
				(e: any) => e.type === 'LECTURE'
			);
			const doubt = res.filter(
				(e: any) => e.type === 'DOUBT'
			);
			// console.log('extraction', lecture, doubt);

			// console.log('MEETINGS->', res, courseId);
			return { lecture: lecture, doubt: doubt };
		} catch (error) {
			console.log(error);
		}
	}
);

export const getLeaderBoard = createAsyncThunk(
	'course/leaderboard',
	async (courseID: any) => {
		try {
			const res =
				await services.courseService.getCourseLeaderboard();
			console.log('RES->', res);
			const data = {
				id: courseID,
				leaderboard: res,
			};
			return data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const getTotalAssignmentSolved = createAsyncThunk(
	'course/totalAssignmentSolved',
	async () => {
		try {
			const res =
				await services.courseService.getTotalSolvedAssignmentCounts();
			console.log('RES->', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

export const getEffortScoreData = createAsyncThunk(
	'course/effortScoreData',
	async () => {
		try {
			// const res =
			// 	await services.courseService.getEffortScoreData();
			// console.log('RES->', res);
			// return res;
		} catch (error) {
			console.log(error);
		}
	}
);

interface CourseState {
	//state
	allCourses: any;
	assignmentsCompleted: number;
	assignmentsCompletedLastWeek: number;
	courseID: string | null;
	course: any;
	courseName: any;
	enrollment: any;
	latestAssignment: any;
	latestAssignmentData: any;
	totalAssignmentModules: number;
	totalAssignmentModulesLastWeek: number;
	totalAssignmentQuestions: number;
	totalSolvedAssignmentQuestions: number;
	upcomingVideoClass: any[];
	leaderboard: any[];
	leaderboardLoading: boolean;
	upcomingMeetings: any;
	meetingId: string | null;
	recordingDataLecture: any;
	recordingDataDoubt: any;
	batchManagerPhoneNumber: any;
	totalAssignmentQuestionOverall: number;
	totalSolvedAssignmentQuestionOverall: number;
	upcomingClass: any;
	announcement: any;
	effortScoreData: any[];

	// extra
	courseloading: boolean;
	error: {
		state: boolean;
		message: string;
	};
}

const initialState: CourseState = {
	//state
	allCourses: {},
	assignmentsCompleted: 0,
	assignmentsCompletedLastWeek: 0,
	courseID: null,
	course: null,
	courseName: null,
	enrollment: null,
	latestAssignment: null,
	latestAssignmentData: null,
	totalAssignmentModules: 0,
	totalAssignmentModulesLastWeek: 0,
	totalAssignmentQuestions: 0,
	totalSolvedAssignmentQuestions: 0,
	upcomingVideoClass: [],
	leaderboard: [],
	leaderboardLoading: true,
	upcomingMeetings: null,
	meetingId: '',
	recordingDataLecture: null,
	recordingDataDoubt: null,
	batchManagerPhoneNumber: null,
	totalAssignmentQuestionOverall: 0,
	totalSolvedAssignmentQuestionOverall: 0,
	upcomingClass: null,
	announcement: null,
	effortScoreData: [],

	// extra
	courseloading: true,
	error: {
		state: false,
		message: '',
	},
};

export const courseSlice = createSlice({
	name: 'course',
	initialState,
	reducers: {
		setCourseID(state, action) {
			console.log('selected course is :', action.payload);
			state.courseID = action.payload;
		},
		setCourse(state, action) {
			state.course = action.payload;
		},
		setMeetingId(state, action) {
			state.meetingId = action.payload;
		},
		updateCourse(state, action) {
			state.assignmentsCompleted =
				state.allCourses[
					action.payload
				].assignmentsCompleted;
			state.assignmentsCompletedLastWeek =
				state.allCourses[
					action.payload
				].assignmentsCompletedLastWeek;
			state.course =
				state.allCourses[action.payload].course;
			state.courseName =
				state.allCourses[action.payload].course.title;
			state.batchManagerPhoneNumber =
				state.allCourses[
					action.payload
				].batchManagerPhoneNumber;
			state.enrollment =
				state.allCourses[action.payload].enrollment;
			state.latestAssignment =
				state.allCourses[action.payload].latestAssignment;
			state.latestAssignmentData =
				state.allCourses[
					action.payload
				].latestAssignmentData;
			state.totalAssignmentModules =
				state.allCourses[
					action.payload
				].totalAssignmentModules;
			state.totalAssignmentModulesLastWeek =
				state.allCourses[
					action.payload
				].totalAssignmentModulesLastWeek;
			state.totalAssignmentQuestions =
				state.allCourses[
					action.payload
				].totalAssignmentQuestions;
			state.totalSolvedAssignmentQuestions =
				state.allCourses[
					action.payload
				].totalSolvedAssignmentQuestions;
			state.upcomingVideoClass =
				state.allCourses[action.payload].upcomingVideoClass;
			state.courseloading = false;
			console.log(
				'COURSE UPDATE',
				state.allCourses[action.payload]
			);
		},
	},
	extraReducers: {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchCourseData.pending]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.courseloading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchCourseData.fulfilled]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.assignmentsCompleted =
				action.payload.assignmentsCompleted;
			state.assignmentsCompletedLastWeek =
				action.payload.assignmentsCompletedLastWeek;
			state.course = action.payload.course;
			state.courseName = action.payload.course.title;
			state.batchManagerPhoneNumber =
				action.payload.batchManagerPhoneNumber;
			state.enrollment = action.payload.enrollment;
			state.courseID = action.payload.course.id;
			state.latestAssignment =
				action.payload.latestAssignment?.module?.id;
			state.latestAssignmentData =
				action.payload.latestAssignment;
			state.totalAssignmentModules =
				action.payload.totalAssignmentModules;
			state.totalAssignmentModulesLastWeek =
				action.payload.totalAssignmentModulesLastWeek;
			state.totalAssignmentQuestions =
				action.payload.totalAssignmentQuestions;
			state.totalSolvedAssignmentQuestions =
				action.payload.totalSolvedAssignmentQuestions;
			state.upcomingVideoClass =
				action.payload.upcomingVideoClass;
			state.courseloading = false;
			console.log(
				'State from course slice fetch course data',
				state
			);
			const temp = {
				assignmentsCompleted:
					action.payload.assignmentsCompleted,
				assignmentsCompletedLastWeek:
					action.payload.assignmentsCompletedLastWeek,
				course: action.payload.course,
				courseName: action.payload.course.title,
				enrollment: action.payload.enrollment,
				courseID: action.payload.course.id,
				latestAssignment:
					action.payload.latestAssignment?.module.id,
				latestAssignmentData:
					action.payload.latestAssignment,
				totalAssignmentModules:
					action.payload.totalAssignmentModules,
				totalAssignmentModulesLastWeek:
					action.payload.totalAssignmentModulesLastWeek,
				totalAssignmentQuestions:
					action.payload.totalAssignmentQuestions,
				totalSolvedAssignmentQuestions:
					action.payload.totalSolvedAssignmentQuestions,
				upcomingVideoClass:
					action.payload.upcomingVideoClass,
			};
			if (
				state.allCourses[action.payload.course.id] ===
				undefined
			) {
				state.allCourses[action.payload.course.id] = temp;
			}
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchCourseData.rejected]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.courseloading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getLeaderBoard.pending]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.leaderboardLoading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getLeaderBoard.fulfilled]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.leaderboard = action.payload.leaderboard;
			state.leaderboardLoading = false;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getLeaderBoard.rejected]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.leaderboardLoading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getEffortScoreData.pending]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.effortScoreData = [];
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getEffortScoreData.fulfilled]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.effortScoreData = action.payload.leaderboard;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getEffortScoreData.rejected]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.effortScoreData = [];
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMeetingIDs.pending]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			// state.leaderboardLoading = true;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMeetingIDs.fulfilled]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			console.log('MEETINGS', action.payload);
			state.upcomingMeetings = action.payload;
			const temp = {
				room: '',
				url: '',
				activeAt: new Date(),
				name: '',
			};
			if (
				action.payload !== null &&
				(action.payload.lecture.length !== 0 ||
					action.payload.doubt.length !== 0)
			) {
				if (
					action.payload.lecture.length > 0 &&
					action.payload.doubt.length === 0
				) {
					temp.room = action.payload.lecture[0].roomId;
					temp.url = action.payload.lecture[0].start_url;
					temp.activeAt =
						action.payload.lecture[0].start_time;
					temp.name = action.payload.lecture[0].title;
				} else if (
					action.payload.lecture.length === 0 &&
					action.payload.doubt.length > 0
				) {
					temp.room = action.payload.doubt[0].roomId;
					temp.url = action.payload.doubt[0].start_url;
					temp.activeAt =
						action.payload.doubt[0].start_time;
					temp.name = action.payload.lecture[0].title;
				} else {
					const curr = new Date();
					if (
						new Date(action.payload.doubt[0].start_time) >
						new Date(action.payload.lecture[0].start_time)
					) {
						temp.room = action.payload.lecture[0].roomId;
						temp.url = action.payload.lecture[0].start_url;
						temp.activeAt =
							action.payload.lecture[0].start_time;
						temp.name = action.payload.lecture[0].title;
					} else {
						temp.room = action.payload.doubt[0].roomId;
						temp.url = action.payload.doubt[0].start_url;
						temp.activeAt =
							action.payload.doubt[0].start_time;
						temp.name = action.payload.lecture[0].title;
					}
				}
				const endDate = new Date(temp.activeAt);

				const startDate = new Date(endDate);

				const durationInMinutes = 5;

				startDate.setMinutes(
					endDate.getMinutes() - durationInMinutes
				);
				temp.activeAt = startDate;
				state.upcomingClass = temp;
				console.log(
					'Upcoming Class',
					temp,
					new Date() > temp.activeAt
				);
			}
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchMeetingIDs.rejected]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			// state.leaderboardLoading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchRecordings.pending]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			// state.leaderboardLoading = true;
			state.recordingDataLecture = null;
			state.recordingDataDoubt = null;
		},
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchRecordings.fulfilled]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			console.log('RECORDING', action.payload);
			if (action.payload !== null) {
				const doubt = action.payload.find(
					(el: any) => el.type === 'DOUBT'
				);
				const lecture = action.payload.find(
					(el: any) => el.type === 'LECTURE'
				);
				console.log('lecture', lecture);
				console.log('doubts', doubt);
				if (
					lecture !== undefined &&
					lecture.playbackUrls.length !== 0
				) {
					const temp1 = lecture.playbackUrls.map(
						(el: any) => ({
							name: lecture.title,
							thumbnail: 'recordingThumbnail.jpg',
							url: el,
							video_id: lecture.id,
						})
					);
					state.recordingDataLecture = temp1;
					console.log('recording lecture temp1', temp1);
				} else {
					state.recordingDataLecture = null;
				}
				console.log(
					'recording lecture',
					state.recordingDataLecture
				);
				if (
					doubt !== undefined &&
					doubt.playbackUrls.length !== 0
				) {
					const temp2 = doubt.playbackUrls.map(
						(el: any) => {
							return {
								name: doubt.title,
								thumbnail: 'recordingThumbnail.jpg',
								url: el,
								video_id: doubt.id,
							};
						}
					);
					state.recordingDataDoubt = temp2;
					console.log('recording lecture temp2', temp2);
				} else {
					state.recordingDataDoubt = null;
				}
				console.log(
					'recording doubt',
					state.recordingDataDoubt
				);
			} else {
				state.recordingDataLecture = null;
				state.recordingDataDoubt = null;
			}
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[fetchRecordings.rejected]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			// state.leaderboardLoading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getTotalAssignmentSolved.pending]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			console.log(
				'state.totalAssignmentQuestionOverall',
				state.totalAssignmentQuestionOverall
			);
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getTotalAssignmentSolved.fulfilled]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			state.totalAssignmentQuestionOverall =
				action.payload.totalAssignmentQuestions;
			state.totalSolvedAssignmentQuestionOverall =
				action.payload.totalSolvedAssignmentQuestions;
		},

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		//@ts-ignore
		[getTotalAssignmentSolved.rejected]: (
			state: CourseState,
			action: PayloadAction<any>
		) => {
			// state.leaderboardLoading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},
	},
});

//Exporting Actions
export const { setMeetingId, setCourseID, updateCourse } =
	courseSlice.actions;
export default courseSlice.reducer;
