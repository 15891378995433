import { Loader } from '@acciojob/loader';
import {
	Badge,
	Box,
	Modal,
	ModalClose,
	ModalDialog,
} from '@mui/joy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { services } from '../../apis';
import { PopupState } from '../../pages/jobportal/JobPortal';

interface PopupProps {
	mockScorePopup: PopupState;
	setMockScorePopup: (newState: PopupState) => void;
}

const MockScorePopup = ({
	mockScorePopup,
	setMockScorePopup,
}: PopupProps) => {
	const [loading, setLoading] = useState(false);
	const [mockScores, setMockScores] = useState([] as any[]);

	useEffect(() => {
		const fetchDrive = async () => {
			if (mockScorePopup.identifier !== '') {
				setLoading(true);
				const data =
					await services.jobPortalService.fetchUserMockScores();
				console.log('mhfcjgfcfhc', data);
				setMockScores(data.data);
				setTimeout(() => {
					setLoading(false);
				}, 300);
			}
		};

		fetchDrive();
	}, [mockScorePopup]);

	const [scroll, setScroll] = React.useState<boolean>(true);

	const handleClose = () => {
		setMockScorePopup({
			isOpen: false,
			identifier: '',
		});
	};

	return (
		<Modal
			open={mockScorePopup.isOpen}
			onClose={handleClose}
			className="thin-scrollbar"
		>
			<ModalDialog aria-labelledby="dialog-vertical-scroll-title">
				<ModalClose
					variant="outlined"
					sx={{
						top: 'calc(-1/4 * var(--IconButton-size))',
						right: 'calc(-1/4 * var(--IconButton-size))',
						boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
						borderRadius: '50%',
						bgcolor: 'background.body',
					}}
				/>
				<Box
					sx={{
						overflowY: scroll ? 'scroll' : 'initial',
						mx: 'calc(-1 * var(--ModalDialog-padding))',
						px: 'var(--ModalDialog-padding)',
						maxHeight: 'calc(100% - 64px)',

						'&::-webkit-scrollbar': {
							width: '5px',
						},
						'&::-webkit-scrollbar-track': {
							backgroundColor: 'transparent',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: '#D0D5DD',
							borderRadius: '5px',
						},
						'&::-webkit-scrollbar-thumb:hover': {
							backgroundColor: '#98A2B3',
						},
					}}
					className="thin-scrollbar"
				>
					<div className="job-portal-drives-popup-inner">
						{loading && (
							<div className="loader-container">
								<Loader
									factVisibility={false}
									width={'100%'}
									height={'100%'}
								/>
							</div>
						)}
						{!loading && (
							<>
								<div className="popup-header">
									<Badge
										badgeContent={mockScores.length}
										sx={{ color: '#2E80EC' }}
									>
										<h2>{mockScorePopup.identifier}</h2>
									</Badge>
								</div>
								{mockScores.length > 0 ? (
									<div className="popup-body popup-body-2">
										<table>
											<thead>
												<tr>
													<th> S.No. </th>
													<th> Session Name </th>
													<th> Mock Score </th>
													<th> Date </th>
												</tr>
											</thead>
											<tbody>
												{mockScores.map(
													(item: any, idx) => (
														<tr key={idx}>
															<td>{idx + 1}</td>
															<td>{item.sessionName}</td>
															<td>{item.mockScore}</td>

															<td>
																{moment(item.date).format(
																	'Do MMM’YY hh:mma'
																)}
															</td>
														</tr>
													)
												)}
											</tbody>
										</table>
									</div>
								) : (
									<p>No data found.</p>
								)}
							</>
						)}
					</div>
				</Box>
			</ModalDialog>
		</Modal>
	);
};

export default MockScorePopup;
