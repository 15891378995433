type ActionType =
	| 'RECORD_CLICK'
	| 'CUSTOM'
	| 'RECORD_INPUT_CLICK'
	| 'NAVIGATION'
	| 'RENDER_SECONDARY_CTAs'
	| 'START_STUDENT_ASSESSMENT_TEST';

export type ActionData = {
	page: string;
	section: string;
	entityType: string;
	others?: {
		[key: string]: any;
	};
};

export type Action = {
	actionType: ActionType;
	data: ActionData;
	redirectionLink?: string;
};

export interface BaseCTA {
	ctaType: ActionType;
	ctaId: string;
	ctaText: string;
	actions: Array<Action>;
}

type PopFooter = {
	footerText: string;
	ctas: Array<BaseCTA>;
};

export type ReferralClickData = {
	page: string;
	section: string;
	others: { [key: string]: any };
};

export enum PopupTypes {
	VERIFY_UG_COLLEGE = 'VERIFY_UG_COLLEGE',
	VERIFY_PG_COLLEGE = 'VERIFY_PG_COLLEGE',
	// LEETCODE_USERNAME = 'LEETCODE_USERNAME',
	// ENTER_COLLEGE = 'ENTER_COLLEGE',
	// CHAT_FEEDBACK = 'CHAT_FEEDBACK',
	// PLACEMENT_ELIGIBILITY = 'PLACEMENT_ELIGIBILITY',
	REFERRAL = 'REFERRAL',
	PLATFORM_FEEDBACK = 'PLATFORM_FEEDBACK',
	// LECTURE_FEEDBACK = 'LECTURE_FEEDBACK',
	STUDENT_ASSESSMENT_TEST = 'STUDENT_ASSESSMENT_TEST', //modules 1
	LIVE_LECTURE_FEEDBACK = 'LIVE_LECTURE_FEEDBACK', // modules 2
	RECORDED_LECTURE_FEEDBACK = 'RECORDED_LECTURE_FEEDBACK', // modules 3
	CONTEST_SECTION = 'CONTEST_SECTION',
}

export enum FeedbackTypes {
	PLATFORM_FEEDBACK = 'PLATFORM_FEEDBACK',
}

export type ReferralData = {
	referralCode: string;
	backgroundImageUrl: string;
	primaryCTAs: Array<BaseCTA>;
	secondaryCTAs: Array<BaseCTA>;
	popFooter: PopFooter;
	viewRecordData: {
		page: string;
		entityType: string;
	};
};

enum PopupDataPageEnum {
	maincourse_home = 'maincourse_home',
}

export type StudentAssesmentData = {
	backgroundImageUrl: string;
	shouldRecordView: boolean;
	viewRecordData: {
		page: PopupDataPageEnum;
		entityType: string;
	};
	ctas: Array<BaseCTA>;
};

export type PopupData = {
	isLoading: boolean;
	showPopup: boolean;
	popupType: string;
	popupToDisplay: any;
	data?: ReferralData | StudentAssesmentData;
};
