import moment from 'moment';
import { nanoid } from 'nanoid';
import React, { useEffect, useState } from 'react';
import { AiOutlineMessage } from 'react-icons/ai';
import { BiInfoCircle } from 'react-icons/bi';
import { FaUser } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import { MdOutlineArrowForwardIos } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../../apis';
import {
	setAllAvailableSlots,
	setStateForRescheduleEnableTab,
	setStateMentorId,
	setStateRescheduleId,
	setStateSelectedDateTimeForBooking,
	setStateSessionCategory,
	setStateSessionTypeId,
} from '../../redux/session/sessionSlice';
import {
	getEligibleSessionsOfUser,
	getSessionDetailsOfUserFromSessionType,
	getSessionsOfUserForAllSessionTypes,
	getUpcomingSessionsOfUser,
} from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import AccioButton from '../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../notification';
import PopUpBox from '../shared/PopUpBox';
import RightAlignModal from '../shared/RightAlignModal';
import { PrevMentors } from './MentorshipSessionBookingBox';
import SessionModalBox from './SessionModalBox';

interface Session {
	category: string;
	sessionTypeId: string;
	bookSessionTag: string;
	bookSessionAvailability: string;
	lastAttemptDate: string;
	feedbackStatus: string;
	lastScore: number | string;
	studentFeedbackLink: string;
	sessionType: string;
	remainingBookingLimit?: number | null;
}

interface SessionDetail {
	sessionTag: string;
	IsRescheduleAllowed: boolean;
	feedbackStatus: string;
	totalScore: string | number;
	studentFeedbackLink: string;
	isAllowedToJoin: boolean;
	mentorFeedback: MentorFeedback | null;
	recordings: string[];
	sessionId: string;
	sessionTypeId: string;
	mentorName: string;
	mentorPhone: string;
}

interface MentorFeedback {
	createdAt: string;
	[key: string]: string | MentorQuestionFeedback | null;
}

interface MentorQuestionFeedback {
	[key: string]: string | MentorQuestionAnswer | null;
}

interface MentorQuestionAnswer {
	[key: string]: string;
}

interface SessionData {
	allSessions: SessionDetail[];
	isEligibleToBookSession: boolean;
	prevMentors: PrevMentors;
	isMentorship: boolean;
}

interface ModalData {}

interface TabData {
	[key: string]: Session[];
}

interface SessionTabBoxProps {
	tabData?: TabData;
	index: number;
	selectedFilter: string;
	totalSessionsLimit: number;
	restrictUserSessions: boolean;
}

const SessionTabBox = ({
	tabData = {},
	index,
	selectedFilter,
	totalSessionsLimit,
	restrictUserSessions,
}: SessionTabBoxProps) => {
	console.log('tabData', tabData, index);

	const dispatch = useDispatch<AppDispatch>();

	const SessionState = useSelector(
		(state: any) => state.session
	);

	const { allSessionDetails, allSessionData } =
		SessionState;
	console.log(
		'🚀 ~ file: SessionTabBox.tsx:116 ~ allSessionDetails:',
		allSessionData
	);

	const { user } = useSelector((state: any) => state.user);

	console.log('user', user);

	const [typeBtn, setTypeBtn] = useState('');

	const [open, setOpen] = useState<boolean>(false);
	const [isOpened, setIsOpened] = useState<boolean>(false);
	const [requestSessionTypeId, setRequestSessionTypeId] =
		useState('');
	const [
		openRaisedRequestModal,
		setOpenRaisedRequestModal,
	] = useState<boolean>(false);
	const [selectedSessionType, setSelectedSessionType] =
		useState<string>('');

	const [
		showAvailAbleBookingColumn,
		setShowAvailAbleBookingColumn,
	] = useState(false);

	const handelRaisedRequestModalClose = () => {
		setOpenRaisedRequestModal(false);
	};

	useEffect(() => {
		if (
			allSessionData &&
			allSessionData?.shouldShowRemainingBookingsColumn
		) {
			setShowAvailAbleBookingColumn(true);
		} else {
			setShowAvailAbleBookingColumn(false);
		}
	}, [allSessionData]);

	const raiseBookingRequest = async (sessionId: any) => {
		try {
			const req =
				await services.sessionService.raiseARequest(
					user.id,
					sessionId
				);
			notifySuccess(req.message);
		} catch (error) {
			notifyError(
				'Something went wrong while raising a request. Contact to your Batch Manager.'
			);
			console.log('Error:', error);
		} finally {
			dispatch(getSessionsOfUserForAllSessionTypes());
			dispatch(getUpcomingSessionsOfUser());
			dispatch(getEligibleSessionsOfUser());
		}
	};

	const ModalDataGenerator = (
		sessionTypeId: string,
		category: string
	) => {
		handleOpen();

		dispatch(
			getSessionDetailsOfUserFromSessionType(sessionTypeId)
		);

		dispatch(setStateSessionTypeId(sessionTypeId));
		dispatch(setStateSessionCategory(category));
	};

	const handleOpen = () => {
		setIsOpened(true);
		setOpen(true);
	};

	const getColor = (score: number) => {
		if (score < 6) {
			return {
				color: '#F63D68',
				backgroundColor: '#FFE4E8',
			};
		} else if (score < 8) {
			return {
				color: '#EAAA08',
				backgroundColor: '#FEFBE8',
			};
		} else {
			return {
				color: '#12B76A',
				backgroundColor: '#D3F8DF',
			};
		}
	};

	const getColor2 = (sessionType: string) => {
		switch (sessionType) {
			case 'DSA':
				return '#12b76a';
			case 'Others':
				return '#f79009';
			case 'Backend':
				return '#f04438';
			case 'Fundamentals':
				return '#667085';
			case 'Excel':
				return '#66c61c';
			case 'Frontend':
				return '#15b79e';
			case 'Problem Solving':
				return '#ef6820';
			case 'Capstone':
				return '#f63d68';
			case 'SQL':
				return '#2e90fa';
			case 'Power BI':
				return '#06aed4';
			case 'Learning Mentorship':
				return '#6172f3';
			case 'Project Mentorship':
				return '#875bf7';
			case 'Learning Mocks':
				return '#b550e7';
			case 'Placement Mocks':
				return '#ee46bc';
			case 'HR Expert Sessions':
				return '#16b364';
			case 'BM Session':
				return '#0BA5EC';
			default:
				return '#808080';
		}
	};

	const getSessionConvention = (sessionType: string) => {
		switch (sessionType) {
			case 'DSA':
				return 'DSA';
			case 'Others':
				return 'OTH';
			case 'Backend':
				return 'BE';
			case 'Fundamentals':
				return 'FND';
			case 'Excel':
				return 'XLS';
			case 'Frontend':
				return 'FE';
			case 'Problem Solving':
				return 'PSV';
			case 'Capstone':
				return 'CAP';
			case 'SQL':
				return 'SQL';
			case 'Power BI':
				return 'PBI';
			case 'Learning Mentorship':
				return 'LM';
			case 'Project Mentorship':
				return 'PM';
			case 'Learning Mocks':
				return 'LM';
			case 'Placement Mocks':
				return 'PM';
			case 'HR Expert Sessions':
				return 'HR';
			case 'BM Session':
				return 'BM';
			default:
				return 'N/A';
		}
	};

	const handleClose = () => {
		setIsOpened(false);
		setTimeout(() => {
			dispatch(setStateMentorId(null));
			dispatch(setAllAvailableSlots([]));
			dispatch(setStateSessionTypeId(null));
			dispatch(setStateForRescheduleEnableTab(false));
			dispatch(setStateSelectedDateTimeForBooking(null));
			dispatch(setStateRescheduleId(null));
			dispatch(setStateForRescheduleEnableTab(null));
			setOpen(false);
		}, 1000);
	};

	const filteredSessions =
		selectedFilter !== 'ALL'
			? tabData[selectedFilter] || []
			: Object.values(tabData)
					.flat()
					.sort((a: any, b: any) => {
						const aScore = a['lastScore'];
						const bScore = b['lastScore'];
						if (aScore > bScore) {
							return -1;
						}
						if (aScore < bScore) {
							return 1;
						}
						return 0;
					});

	console.log('filteredSessions', filteredSessions);
	if (!tabData || !selectedFilter) {
		return null;
	} else {
		return (
			<div className="session-tab-box-wrapper">
				<ul className="session-tab-box-list-header">
					<li
						className={`session-tab-box-item ${
							showAvailAbleBookingColumn ? 'add-column' : ''
						}`}
						key={nanoid()}
					>
						<p className="col col-1">Session Name</p>

						{showAvailAbleBookingColumn ? (
							<p className="col col-6">
								Available Bookings
							</p>
						) : null}
						{index === 0 ? (
							<p className="col col-2">Score</p>
						) : (
							<p className="col col-2"></p>
						)}

						<p className="col col-3">Last session</p>
						<p className="col col-4"></p>
						<p className="col col-5"></p>
					</li>
				</ul>

				<ul
					className="session-tab-box-list"
					key={nanoid()}
				>
					{filteredSessions.length > 0 &&
						filteredSessions.map((sessionItem: Session) => (
							<React.Fragment key={nanoid()}>
								<li
									className={`session-tab-box-item ${
										showAvailAbleBookingColumn
											? 'add-column'
											: ''
									}`}
								>
									<div className="col col-1">
										<div
											className="session-type-round"
											style={{
												backgroundColor: getColor2(
													sessionItem?.category
												),
												outline: `1.5px solid ${getColor2(
													sessionItem?.category
												)}`,
											}}
										>
											{getSessionConvention(
												sessionItem?.category
											)}
										</div>
										<p className="col-session-type">
											{sessionItem.sessionType}
										</p>
									</div>
									{showAvailAbleBookingColumn ? (
										<p className="col col-6">
											{sessionItem?.remainingBookingLimit ==
											null
												? '-'
												: `${sessionItem?.remainingBookingLimit} Left`}
										</p>
									) : null}

									{index === 0 ? (
										<div className="col col-2">
											{sessionItem?.lastAttemptDate !==
											'No Sessions Taken' ? (
												sessionItem?.feedbackStatus ===
												'Filled' ? (
													sessionItem?.lastScore != -1 ? (
														<span
															className="last-score-text"
															style={{
																...getColor(
																	Number(
																		sessionItem?.lastScore
																	)
																),
															}}
														>
															{sessionItem.lastScore}
														</span>
													) : null
												) : sessionItem?.feedbackStatus ===
												  'Awaiting Feedback' ? (
													<span className="awaiting-text">
														Awaiting Feedback
													</span>
												) : (
													<AccioButton
														title="Fill Feedback"
														variant="outline"
														action={() =>
															window.open(
																sessionItem?.studentFeedbackLink,
																'_blank'
															)
														}
														icon={
															<AiOutlineMessage
																style={{
																	fontSize: '14px',
																	marginTop: '-2px',
																}}
															/>
														}
														customClass="btn all-session-btn-outline-fill-feedback"
													/>
												)
											) : (
												<span className="no-feedback-text">
													No Feedback Available
												</span>
											)}
										</div>
									) : (
										<div className="col col-2"></div>
									)}
									<p className="col col-3">
										{sessionItem?.lastAttemptDate !==
										'No Sessions Taken'
											? moment(
													sessionItem?.lastAttemptDate
											  ).format('D MMM YY')
											: sessionItem?.lastAttemptDate}
									</p>
									<div className="col col-4">
										{!restrictUserSessions ||
										totalSessionsLimit !== null ? (
											sessionItem?.bookSessionTag ===
											'UPCOMING' ? (
												<span className="upcoming-session-text">
													Upcoming session
												</span>
											) : sessionItem?.bookSessionTag ===
											  'NO SESSIONS LEFT' ? (
												<span className="request-pending-text">
													No Session Left
												</span>
											) : sessionItem?.bookSessionTag ===
											  'RAISE A REQUEST' ? (
												<AccioButton
													title="Raise request"
													variant="outline"
													action={() => {
														setOpenRaisedRequestModal(true);
														setSelectedSessionType(
															sessionItem?.sessionType
														);
														setRequestSessionTypeId(
															sessionItem?.sessionTypeId
														);
													}}
													icon={
														<BiInfoCircle
															style={{
																fontSize: '14px',
																marginTop: '-2px',
															}}
														/>
													}
													customClass="btn all-session-btn-outline-info"
												/>
											) : sessionItem?.bookSessionTag ===
											  'BOOK SESSION' ? (
												<AccioButton
													title="Book session"
													variant="outline"
													action={() => {
														ModalDataGenerator(
															sessionItem?.sessionTypeId,
															sessionItem?.category
														);
														setTypeBtn('BOOK SESSION');
													}}
													icon={''}
													customClass="btn all-session-btn-outline-book-session"
												/>
											) : sessionItem?.bookSessionTag ===
											  'REQUEST ALREADY RAISED' ? (
												<span className="request-pending-text">
													<FaUser
														style={{
															fontSize: '12px',
															marginTop: '-2px',
															marginRight: '5px',
														}}
													/>{' '}
													Request pending
												</span>
											) : (
												<p className="next-available-text">
													<span className="icon">
														<FiClock />
													</span>
													<span className="text">
														{
															sessionItem?.bookSessionAvailability
														}
													</span>
												</p>
											)
										) : (
											<span className="request-pending-text">
												No Session Left
											</span>
										)}
									</div>
									<div className="col col-5">
										<AccioButton
											title="View details"
											variant="outline"
											action={() => {
												ModalDataGenerator(
													sessionItem?.sessionTypeId,
													sessionItem?.category
												);
												setTypeBtn('VIEW DETAILS');
											}}
											icon={<MdOutlineArrowForwardIos />}
											iconPosition="right"
											customClass="btn all-session-btn-outline-view-details"
										/>
									</div>
								</li>
							</React.Fragment>
						))}
				</ul>

				<PopUpBox
					handleClose={handelRaisedRequestModalClose}
					openPopUp={openRaisedRequestModal}
				>
					<div className="popup-modal-box-wrapper">
						<p className="modal-box-title">
							Do you want to raise a request ?
						</p>
						<p className="modal-box-text">
							Are you sure you want to{' '}
							<b>Raise a request </b>
							to book your <b>{selectedSessionType}</b>{' '}
							Sessions?
						</p>

						<div className="modal-box-btn-wrapper">
							<AccioButton
								title="No"
								action={() =>
									setOpenRaisedRequestModal(false)
								}
								variant="outline"
								customClass="no"
							/>
							<AccioButton
								title="Yes"
								customClass="yes"
								variant="primary"
								action={() => {
									setOpenRaisedRequestModal(false);
									raiseBookingRequest(requestSessionTypeId);
								}}
							/>
						</div>
					</div>
				</PopUpBox>
				<RightAlignModal
					children={
						<SessionModalBox
							modalData={allSessionDetails}
							typeBtn={typeBtn}
							setOpen={setOpen}
						/>
					}
					handleClose={handleClose}
					handleOpen={handleOpen}
					isOpened={isOpened}
					setIsOpened={setIsOpened}
					open={open}
					setOpen={setOpen}
				/>
			</div>
		);
	}
};

export default SessionTabBox;
