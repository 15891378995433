import dashboard_card_img from '../../assets/softSkillsNew/dashboard_card_img.svg';
import clock from '../../assets/softSkillsNew/clock.svg';
import clock_mat from '../../assets/softSkillsNew/clock_mat.svg';
import clock_mar from '../../assets/softSkillsNew/clock_mar.svg';
import clock_cyan from '../../assets/softSkillsNew/clock_cyan.svg';
import right_arrow from '../../assets/softSkillsNew/right_arrow.svg';
import AccioButton from '../elements/Button';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SoftskillsActions } from '../../redux/softskills/softskills.slice';
import { useAppDispatch } from '../../redux/store';
import { SoftSkillsCourseType } from '../../redux/softskills/softskills.type';

type DashboardCardPropsType = SoftSkillsCourseType & {
	cardindex: number;
};

const clocks = [clock, clock_mar, clock_cyan, clock_mat];

const DashboardCard: FC<DashboardCardPropsType> = ({
	cardindex,
	title,
	id,
	solvedAssignmentsCount,
	totalAssignmentsCount,
	videosCount,
	videosDuration,
}) => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const handleGoToCourse = (id: string) => {
		dispatch(
			SoftskillsActions.setSelectedModuleDetails({
				selectedModuleId: null,
				activeTabIndex: null,
			})
		);
		dispatch(
			SoftskillsActions.setSelectedCourseTitle(title)
		);
		navigate(`/softskills/${id}`);
	};

	return (
		<div
			className={`soft-skills-card card-${cardindex % 4}`}
		>
			<img
				src={dashboard_card_img}
				className="dashboard-card-img"
				alt="dashboard card"
			/>
			{/* <p className="course-text">COURSE</p> */}
			<h2 className="course-title">{title}</h2>
			<div className="videos-info">
				<p>{videosCount} VIDEOS</p>
				<div className="watch-duration">
					<img
						src={clocks[cardindex % 4]}
						alt="clock"
					/>
					<p>{videosDuration} MIN</p>
				</div>
			</div>
			<div className="current-progress">
				<h3 className="progress-heading">
					CURRENT PROGRESS
				</h3>
				<p className="current-progress-text">
					Assignments Solved ({solvedAssignmentsCount}/
					{totalAssignmentsCount})
				</p>
				<div className="current-progress-bar">
					<div className="entire-line"></div>
					<div
						className="completed-line"
						style={{
							width: `${
								totalAssignmentsCount > 0
									? (solvedAssignmentsCount /
											totalAssignmentsCount) *
									  100
									: 0
							}%`,
						}}
					></div>
				</div>
			</div>

			<AccioButton
				title="Go to course"
				className="go-to-course-btn"
				action={() => handleGoToCourse(id)}
				icon={
					<img
						src={right_arrow}
						alt="right arrow"
					/>
				}
			/>
		</div>
	);
};

export default DashboardCard;
