import Tab, { tabClasses } from '@mui/joy/Tab';
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Tabs from '@mui/joy/Tabs';
import { useEffect, useState } from 'react';
import { QuestionAnalysisType } from '../../../redux/gym/type';
import { useAppSelector } from '../../../redux/store';
import { QuestionsCardData } from './QuestionsCardData';

export interface QuestionAnalaysisDataInterface {
	data: Array<QuestionAnalysisType> | null;
	totalQuestions: number;
	totalSolved: number;
}
export const QuestionsCard = () => {
	const { userQuestionSubmissionData } = useAppSelector(
		(state) => state.gym
	);

	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	const [allData, setAllData] =
		useState<QuestionAnalaysisDataInterface | null>(null);

	useEffect(() => {
		if (userQuestionSubmissionData) {
			if (userQuestionSubmissionData.questionAnalysis) {
				const myObj = {
					data: userQuestionSubmissionData.questionAnalysis,
					totalQuestions:
						userQuestionSubmissionData.questionAnalysis?.reduce(
							(acc, item: any) => {
								acc += parseInt(item.total);
								return acc;
							},
							0
						),
					totalSolved:
						userQuestionSubmissionData.questionAnalysis?.reduce(
							(acc, item: any) => {
								acc += parseInt(item.solved);
								return acc;
							},
							0
						),
				};
				setAllData(myObj);
			}
		}
	}, [userQuestionSubmissionData]);

	if (performanceData.data) {
		const {
			codingQuestionStats,
			webDevQuestionStats,
			conceptualQuestionStats,
		} = performanceData.data;

		if (
			codingQuestionStats &&
			webDevQuestionStats &&
			conceptualQuestionStats
		) {
			const transformCodingStats = () => {
				const total =
					codingQuestionStats.totalEasyCodingQuestions +
					codingQuestionStats.totalMediumCodingQuestions +
					codingQuestionStats.totalHardCodingQuestions;

				const totalSolved =
					codingQuestionStats.easy +
					codingQuestionStats.medium +
					codingQuestionStats.hard;
				const data = [
					{
						difficulty: 'EASY',
						total:
							codingQuestionStats.totalEasyCodingQuestions.toString(),
						solved: codingQuestionStats.easy.toString(),
						percentage: (
							(codingQuestionStats.easy /
								codingQuestionStats.totalEasyCodingQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
					{
						difficulty: 'MEDIUM',
						total:
							codingQuestionStats.totalMediumCodingQuestions.toString(),
						solved: codingQuestionStats.medium.toString(),
						percentage: (
							(codingQuestionStats.medium /
								codingQuestionStats.totalMediumCodingQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
					{
						difficulty: 'HARD',
						total:
							codingQuestionStats.totalHardCodingQuestions.toString(),
						solved: codingQuestionStats.hard.toString(),
						percentage: (
							(codingQuestionStats.hard /
								codingQuestionStats.totalHardCodingQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
				];
				return {
					data: data,
					totalQuestions: total,
					totalSolved: totalSolved,
				};
			};

			const transformWebDevStats = () => {
				const total =
					webDevQuestionStats.totalEasyWebdevQuestions +
					webDevQuestionStats.totalMediumWebdevQuestions +
					webDevQuestionStats.totalHardWebdevQuestions;

				const totalSolved =
					webDevQuestionStats.easy +
					webDevQuestionStats.medium +
					webDevQuestionStats.hard;

				const data = [
					{
						difficulty: 'EASY',
						total:
							webDevQuestionStats.totalEasyWebdevQuestions.toString(),
						solved: webDevQuestionStats.easy.toString(),
						percentage: (
							(webDevQuestionStats.easy /
								webDevQuestionStats.totalEasyWebdevQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
					{
						difficulty: 'MEDIUM',
						total:
							webDevQuestionStats.totalMediumWebdevQuestions.toString(),
						solved: webDevQuestionStats.medium.toString(),
						percentage: (
							(webDevQuestionStats.medium /
								webDevQuestionStats.totalMediumWebdevQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
					{
						difficulty: 'HARD',
						total:
							webDevQuestionStats.totalHardWebdevQuestions.toString(),
						solved: webDevQuestionStats.hard.toString(),
						percentage: (
							(webDevQuestionStats.hard /
								webDevQuestionStats.totalHardWebdevQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
				];
				return {
					data: data,
					totalQuestions: total,
					totalSolved: totalSolved,
				};
			};

			const transformConceptualStats = () => {
				const total =
					conceptualQuestionStats.totalEasyConceptualQuestions +
					conceptualQuestionStats.totalMediumConceptualQuestions +
					conceptualQuestionStats.totalHardConceptualQuestions;

				const totalSolved =
					conceptualQuestionStats.easy +
					conceptualQuestionStats.medium +
					conceptualQuestionStats.hard;

				const data = [
					{
						difficulty: 'EASY',
						total:
							conceptualQuestionStats.totalEasyConceptualQuestions.toString(),
						solved: conceptualQuestionStats.easy.toString(),
						percentage: (
							(conceptualQuestionStats.easy /
								conceptualQuestionStats.totalEasyConceptualQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
					{
						difficulty: 'MEDIUM',
						total:
							conceptualQuestionStats.totalMediumConceptualQuestions.toString(),
						solved:
							conceptualQuestionStats.medium.toString(),
						percentage: (
							(conceptualQuestionStats.medium /
								conceptualQuestionStats.totalMediumConceptualQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
					{
						difficulty: 'HARD',
						total:
							conceptualQuestionStats.totalHardConceptualQuestions.toString(),
						solved: conceptualQuestionStats.hard.toString(),
						percentage: (
							(conceptualQuestionStats.hard /
								conceptualQuestionStats.totalHardConceptualQuestions) *
							100
						)
							.toFixed(2)
							.toString(),
					},
				];
				return {
					data: data,
					totalQuestions: total,
					totalSolved: totalSolved,
				};
			};

			return (
				<div className="profile-card questions-card">
					<Tabs
						size="md"
						aria-label="Basic tabs"
						defaultValue={0}
						sx={{
							backgroundColor: '#fff',
						}}
					>
						<TabList
							disableUnderline
							sx={{
								[`&& .${tabClasses.root}`]: {
									flex: 'initial',
									bgcolor: 'transparent',
									color: '#8C9AAC',
									fontFamily: 'Inter',
									fontWeight: 600,
									'&:hover': {
										bgcolor: 'transparent',
									},
									[`&.${tabClasses.selected}`]: {
										color: '#2F80ED',
									},
								},
							}}
						>
							<Tab>All</Tab>
							<Tab>Coding</Tab>
							<Tab>WebDev</Tab>
							<Tab>Conceptual</Tab>
						</TabList>
						<div className="tab-panel-div">
							<TabPanel value={0}>
								<QuestionsCardData
									value={0}
									data={allData}
								/>
							</TabPanel>
							<TabPanel value={1}>
								<QuestionsCardData
									value={1}
									data={transformCodingStats()}
								/>
							</TabPanel>
							<TabPanel value={2}>
								<QuestionsCardData
									value={2}
									data={transformWebDevStats()}
								/>
							</TabPanel>

							<TabPanel value={3}>
								<QuestionsCardData
									value={3}
									data={transformConceptualStats()}
								/>
							</TabPanel>
						</div>
					</Tabs>
				</div>
			);
		} else {
			return null;
		}
	} else {
		return null;
	}
};
