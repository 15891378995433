import {
	createAsyncThunk,
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { services } from '../../apis';

let listOfAllCourses: any[] = [];

export const fetchCourseData = createAsyncThunk(
	'home/course',
	async () => {
		try {
			const res =
				await services.courseService.getCourseData();
			// console.log("HITT", res)
			const dataApi =
				await services.courseService.getFullCourseQuestionsForModule(
					res.latestAssignment.module.id
				);
			res.selectedCourse = localStorage.getItem(
				'selected-course-token'
			);
			res.courseList = listOfAllCourses;
			res.latestAssignment = dataApi;
			// console.log("RES ------- ", res)
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

const test = async () => {
	try {
		const res =
			await services.courseService.getCourseData();

		const dataApi =
			await services.courseService.getFullCourseQuestionsForModule(
				res.latestAssignment.module.id
			);

		res.latestAssignment = dataApi;
		return res;
	} catch (error) {
		console.log(error);
	}
};

const checkRepeatition = (data: any[]) => {
	console.log('This is the data ', data);
	data = data.filter(
		(value, index, self) =>
			index === self.findIndex((t) => t.id === value.id)
	);

	console.log('From Set', data);
	return data;
};

export const fetchAllCourseData = createAsyncThunk(
	'home/course',
	async () => {
		try {
			let res: any = {};
			let { data } =
				await services.courseService.getAllCourseData();
			data = checkRepeatition(data);
			// console.log("Data NR", dataNR)
			let latestCourseID = data[0].id;
			const ctoken = localStorage.getItem(
				'selected-course-token'
			);
			console.log('ctoken', ctoken);
			if (ctoken) {
				latestCourseID = ctoken;
			} else
				await localStorage.setItem(
					'selected-course-token',
					latestCourseID
				);
			res.courseList = data;
			listOfAllCourses = data;
			res.selectedCourse = latestCourseID;
			res.course = data.filter(
				(el: any): any => el.id == latestCourseID
			);
			const response = await test();
			// console.log("Response of fetchCourseData", response)
			res = { ...response, ...res };
			console.log('New RES -> ', res);
			return res;
		} catch (error) {
			console.log(error);
		}
	}
);

interface HomeScreenState {
	//state
	assignmentsCompleted: number;
	assignmentsCompletedLastWeek: number;
	courseList: any[];
	selectedCourse: string;
	course: any;
	enrollment: any;
	latestAssignment: any;
	totalAssignmentModules: number;
	totalAssignmentModulesLastWeek: number;
	totalAssignmentQuestions: number;
	totalSolvedAssignmentQuestions: number;
	upcomingVideoClass: any[];

	// extra
	loading: boolean;
	error: {
		state: boolean;
		message: string;
	};
}

const initialState: HomeScreenState = {
	//state
	assignmentsCompleted: 0,
	assignmentsCompletedLastWeek: 0,
	// adding course List
	courseList: [],
	selectedCourse: '',

	course: null,

	enrollment: null,
	latestAssignment: null,
	totalAssignmentModules: 0,
	totalAssignmentModulesLastWeek: 0,
	totalAssignmentQuestions: 0,
	totalSolvedAssignmentQuestions: 0,
	upcomingVideoClass: [],
	// extra
	loading: false,
	error: {
		state: false,
		message: '',
	},
};

export const homeScreenSlice = createSlice({
	name: 'homescreen',
	initialState,
	reducers: {
		showThisCourse(state, action) {
			console.log('selected course is :', action.payload);
			state.selectedCourse = action.payload;
			// localStorage.setItem('selected-course-token', state.selectedCourse)
			state.course = state.courseList.filter(
				(el: any): any => el.id == state.selectedCourse
			);
			console.log('Now see this course', state.course);
			console.log('from redux', state.selectedCourse);
		},
	},
	extraReducers: {
		// [fetchAllCourseData.fulfilled] : (
		//     state: HomeScreenState,
		//     action: PayloadAction<any>,
		// ) =>{
		//     console.log("fetchAllCourseData, homescreenSlice",action.payload)
		// },

		[fetchCourseData.pending as any]: (
			state: HomeScreenState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		[fetchCourseData.fulfilled as any]: (
			state: HomeScreenState,
			action: PayloadAction<any>
		) => {
			console.log(
				'HomeScreenSlice',
				action.payload.course,
				action.payload.enrollment
			);
			state.assignmentsCompleted =
				action.payload.assignmentsCompleted;
			state.assignmentsCompletedLastWeek =
				action.payload.assignmentsCompletedLastWeek;
			state.course = action.payload.course;
			state.courseList = action.payload.courseList;
			state.selectedCourse = action.payload.selectedCourse;
			state.enrollment = action.payload.enrollment;
			state.latestAssignment =
				action.payload.latestAssignment;
			state.totalAssignmentModules =
				action.payload.totalAssignmentModules;
			state.totalAssignmentModulesLastWeek =
				action.payload.totalAssignmentModulesLastWeek;
			state.totalAssignmentQuestions =
				action.payload.totalAssignmentQuestions;
			state.totalSolvedAssignmentQuestions =
				action.payload.totalSolvedAssignmentQuestions;
			state.upcomingVideoClass =
				action.payload.upcomingVideoClass;
			state.loading = false;
		},

		[fetchCourseData.rejected as any]: (
			state: HomeScreenState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},

		[fetchAllCourseData.pending as any]: (
			state: HomeScreenState,
			action: PayloadAction<any>
		) => {
			state.loading = true;
		},

		[fetchAllCourseData.fulfilled as any]: (
			state: HomeScreenState,
			action: PayloadAction<any>
		) => {
			console.log(
				'HomeScreenSlice',
				action.payload.course,
				action.payload.enrollment
			);
			state.assignmentsCompleted =
				action.payload.assignmentsCompleted;
			state.assignmentsCompletedLastWeek =
				action.payload.assignmentsCompletedLastWeek;
			state.courseList = action.payload.courseList;
			state.selectedCourse = action.payload.selectedCourse;
			state.course = action.payload.course;
			state.enrollment = action.payload.enrollment;
			state.latestAssignment =
				action.payload.latestAssignment;
			state.totalAssignmentModules =
				action.payload.totalAssignmentModules;
			state.totalAssignmentModulesLastWeek =
				action.payload.totalAssignmentModulesLastWeek;
			state.totalAssignmentQuestions =
				action.payload.totalAssignmentQuestions;
			state.totalSolvedAssignmentQuestions =
				action.payload.totalSolvedAssignmentQuestions;
			state.upcomingVideoClass =
				action.payload.upcomingVideoClass;
			state.loading = false;

			console.log(
				'State from home slice fetch course data',
				state
			);
		},

		[fetchAllCourseData.rejected as any]: (
			state: HomeScreenState,
			action: PayloadAction<any>
		) => {
			state.loading = false;
			state.error = {
				state: true,
				message: 'Something Went wrong',
			};
			// state.authError = action.payload
		},
	},
});

//Exporting Actions
export const { showThisCourse } = homeScreenSlice.actions;
export default homeScreenSlice.reducer;
