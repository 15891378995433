import ReactDOM from 'react-dom/client';

// Importing styles
import './index.css';
import './styles/designSystem.scss';
import './styles/global.scss';
import './styles/styles.scss';

import reportWebVitals from './reportWebVitals';

// REACT ROUTER
import { BrowserRouter } from 'react-router-dom';

// REDUX TOOLKIT STORE
import { Provider } from 'react-redux';
import store from './redux/store';

// CUSTOM COMPONENTS
import React from 'react';
import AuthenticationCheck from './AuthenticationCheck';

if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.error = () => {};
	console.debug = () => {};
}

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Provider store={store}>
				<AuthenticationCheck />
			</Provider>
		</BrowserRouter>
	</React.StrictMode>
);

reportWebVitals();
