import {
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import {
	addBookmark,
	fetchAvailableTabsThunk,
	fetchHeatMapData,
	fetchQuestions,
	fetchRandomQuestions,
	fetchRevisionSheetByTab,
	fetchTopicFilters,
	fetchUserQuestionSubmissionData,
	removeBookmark,
} from './thunks/gym.thunk';
import { GymFilterType, GymStateType } from './type';

const initialState: GymStateType = {
	appliedFiltersForTabId: {},
	selectedTabId: null,
	availableTabs: null,
	heatMapData: null,
	isLoading: false,
	questionsByTabId: {},
	revisionSheetsByTabId: {},
	topicsByTabId: {},
	userQuestionSubmissionData: {
		questionAnalysis: null,
		streak: 0,
		totalSolvedQuestions: 0,
		restoreStreakNumber: 0,
	},
	companyTagsByTabId: {},
	error: null,
};

export const gymSlice = createSlice({
	name: 'gym',
	initialState,
	reducers: {
		setSelectedTabId: (
			state,
			action: PayloadAction<string>
		) => {
			state.selectedTabId = action.payload;
		},
		applyFilter: (
			state,
			action: PayloadAction<Partial<GymFilterType>>
		) => {
			const tabId =
				state.selectedTabId ?? state.availableTabs![0].id;
			state.appliedFiltersForTabId[tabId] = {
				...state.appliedFiltersForTabId[tabId],
				...action.payload,
			};
			state.questionsByTabId[tabId] = {
				page: 1,
				questions: [],
				totalPages: 0,
			};
		},
		resetFilters: (state) => {
			const tabId =
				state.selectedTabId ?? state.availableTabs![0].id;
			state.appliedFiltersForTabId[tabId] = {};
			state.questionsByTabId[tabId] = {
				page: 1,
				questions: [],
				totalPages: 0,
			};
		},
	},
	extraReducers: {
		[fetchQuestions.pending.type]: (state) => {
			state.isLoading = true;
		},
		[fetchQuestions.fulfilled.type]: (
			state,
			action: ReturnType<typeof fetchQuestions.fulfilled>
		) => {
			state.isLoading = false;
			const tabId = action.meta.arg.selectedTabId;
			const existingQuestion =
				state.questionsByTabId[tabId];
			if (existingQuestion) {
				state.questionsByTabId[tabId] = {
					page: existingQuestion.page + 1,
					totalPages: action.payload.totalPages,
					questions: [
						...existingQuestion.questions,
						...action.payload.questions,
					],
				};
			} else {
				state.questionsByTabId[tabId] = {
					page: 2,
					totalPages: action.payload.totalPages,
					questions: action.payload.questions,
				};
			}
			if (action.payload.companyTags) {
				state.companyTagsByTabId[tabId] =
					action.payload.companyTags;
			}
		},
		[fetchQuestions.rejected.type]: (
			state,
			action: ReturnType<typeof fetchQuestions.rejected>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},

		[fetchTopicFilters.pending.type]: (state) => {
			state.isLoading = true;
		},
		[fetchTopicFilters.fulfilled.type]: (
			state,
			action: ReturnType<typeof fetchTopicFilters.fulfilled>
		) => {
			const tabId = action.meta.arg.selectedTabId;
			state.isLoading = false;
			if (state.appliedFiltersForTabId[tabId]) {
				state.appliedFiltersForTabId[tabId].topics = [];
			}
			state.topicsByTabId[tabId] = action.payload;
		},
		[fetchTopicFilters.rejected.type]: (
			state,
			action: ReturnType<typeof fetchTopicFilters.rejected>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},

		[addBookmark.pending.type]: (
			state,
			action: ReturnType<typeof addBookmark.pending>
		) => {
			const tabId = action.meta.arg.selectedTabId;

			state.questionsByTabId[tabId].questions[
				action.meta.arg.index
			].bookmarked = true;
		},
		[addBookmark.rejected.type]: (
			state,
			action: ReturnType<typeof addBookmark.rejected>
		) => {
			const tabId = action.meta.arg.selectedTabId;

			state.questionsByTabId[tabId].questions[
				action.meta.arg.index
			].bookmarked = false;
		},

		[removeBookmark.pending.type]: (
			state,
			action: ReturnType<typeof removeBookmark.pending>
		) => {
			const tabId = action.meta.arg.selectedTabId;

			state.questionsByTabId[tabId].questions[
				action.meta.arg.index
			].bookmarked = false;
		},
		[removeBookmark.rejected.type]: (
			state,
			action: ReturnType<typeof removeBookmark.rejected>
		) => {
			const tabId = action.meta.arg.selectedTabId;
			state.questionsByTabId[tabId].questions[
				action.meta.arg.index
			].bookmarked = true;
		},

		[fetchHeatMapData.pending.type]: (state) => {
			state.isLoading = true;
		},
		[fetchHeatMapData.fulfilled.type]: (
			state,
			action: ReturnType<typeof fetchHeatMapData.fulfilled>
		) => {
			state.isLoading = false;
			state.heatMapData = action.payload;
		},
		[fetchHeatMapData.rejected.type]: (
			state,
			action: ReturnType<typeof fetchHeatMapData.rejected>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},

		[fetchRevisionSheetByTab.pending.type]: (
			state,
			action: ReturnType<
				typeof fetchRevisionSheetByTab.pending
			>
		) => {
			const tabId = action.meta.arg.selectedTabId;

			state.isLoading = true;
			state.revisionSheetsByTabId[tabId] = null;
		},
		[fetchRevisionSheetByTab.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchRevisionSheetByTab.fulfilled
			>
		) => {
			const tabId = action.meta.arg.selectedTabId;
			state.isLoading = false;
			state.revisionSheetsByTabId[tabId] = action.payload;
		},
		[fetchRevisionSheetByTab.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchRevisionSheetByTab.rejected
			>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},

		[fetchRandomQuestions.pending.type]: (state) => {
			state.isLoading = true;
		},
		[fetchRandomQuestions.fulfilled.type]: (state) => {
			state.isLoading = false;
		},
		[fetchRandomQuestions.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchRandomQuestions.rejected
			>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},

		[fetchUserQuestionSubmissionData.pending.type]: (
			state
		) => {
			state.isLoading = true;
		},
		[fetchUserQuestionSubmissionData.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchUserQuestionSubmissionData.fulfilled
			>
		) => {
			state.isLoading = false;
			state.userQuestionSubmissionData = action.payload;
			if (state.userQuestionSubmissionData) {
				state.userQuestionSubmissionData.totalSolvedQuestions =
					state.userQuestionSubmissionData.questionAnalysis!.reduce(
						(sum, obj) => sum + parseInt(obj.solved),
						0
					);
			}
		},
		[fetchUserQuestionSubmissionData.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchUserQuestionSubmissionData.rejected
			>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},

		[fetchAvailableTabsThunk.pending as any]: (state) => {
			state.isLoading = true;
		},
		[fetchAvailableTabsThunk.fulfilled as any]: (
			state,
			action: ReturnType<
				typeof fetchAvailableTabsThunk.fulfilled
			>
		) => {
			state.isLoading = false;
			state.availableTabs = action.payload.tabs;
			state.selectedTabId = action.payload.tabs[0].id;
		},
		[fetchAvailableTabsThunk.rejected as any]: (
			state,
			action: ReturnType<
				typeof fetchAvailableTabsThunk.rejected
			>
		) => {
			state.isLoading = false;
			state.error = action.error;
		},
	},
});

export const GymActions = gymSlice.actions;
export const GymReducers = gymSlice.reducer;
