import { Box } from '@mui/joy';
import Modal from '@mui/joy/Modal';
import Sheet from '@mui/joy/Sheet';
import { Typography } from '@mui/material';
import { useState } from 'react';
import { FiPause } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../redux/store';
import {
	UserState,
	raiseCoursePauseThunk,
} from '../../redux/user/userSlice';
import AccioButton from '../elements/Button';

type PauseType = 'CURRENT' | 'NEXT';

const PauseProfileComponent = () => {
	const { pauseStatus }: UserState = useSelector(
		(state: RootState) => state.user as UserState
	);
	const [open, setOpen] = useState<boolean>(false);
	const [type, setType] = useState<string | null>(null);
	const [pauseOpen, setPauseOpen] =
		useState<boolean>(false);

	const pauseMonthFun = (type: string) => {
		setOpen(false);
		if (Boolean(type)) {
			setType(type);
			setPauseOpen(true);
		}
	};

	return pauseStatus.showPauseOption ? (
		<Box className="pause-profile">
			<h1>Pause Profile</h1>
			<Box className="pause-details">
				<Typography className="available-pauses">
					Pauses Available : {pauseStatus.availablePauses}
				</Typography>
				<Typography className="taken-pauses">
					Pauses Taken : {pauseStatus.takenPauses}
				</Typography>
			</Box>
			<AccioButton
				title="Pause Course"
				className="pause-btn"
				action={() => setOpen(true)}
				icon={<FiPause />}
				iconPosition="left"
			/>
			<PauseProfileDetailsModal
				open={open}
				setOpen={setOpen}
				pause={pauseMonthFun}
				data={{ ...pauseStatus }}
			/>
			<PauseProfileConfirmModal
				open={pauseOpen}
				setOpen={setPauseOpen}
				type={type}
				data={{ ...pauseStatus }}
			/>
		</Box>
	) : null;
};

const PauseProfileDetailsModal = ({
	open,
	setOpen,
	pause,
	data,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	pause: (type: string) => void;
	data: any;
}) => {
	return (
		<Modal
			className="pause-modal"
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Sheet
				variant="outlined"
				sx={{
					maxWidth: 700,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}
			>
				<Typography className="modal-title">
					Pause Course
				</Typography>
				<Typography className="short-head">
					You are requesting to pause the course
				</Typography>

				<Box className="note">
					<Typography className="short-text">
						Please Note :
					</Typography>
					<ol>
						<li>
							You can request course pause for 1 module at a
							time, you can request your current ongoing
							module or the next upcoming module.
						</li>
						<li>
							Access to all live classes will be paused for
							your selected duration, all other components
							of the platform including class recording,
							live doubt, one on one session will remain
							unaffected
						</li>
						<li>
							After pause, your live course will resume from
							the module that was paused.
						</li>
					</ol>
				</Box>

				<Typography className="short-highlight">
					Your Batch Manager will have to approve your
					request to pause the course.
				</Typography>

				<Box className="pause-details">
					<Typography className="available-pauses">
						Pauses Available : {data.availablePauses}
					</Typography>
					<Typography className="taken-pauses">
						Pauses Taken : {data.takenPauses}
					</Typography>
				</Box>

				<Box className="pause-btns">
					<AccioButton
						title="Pause Current Month"
						className="pause-btn"
						action={() => pause('current')}
						variant="outline"
					/>
					<AccioButton
						title="Pause Upcoming Month"
						className="pause-btn"
						action={() => pause('upcoming')}
						variant="outline"
					/>
				</Box>
			</Sheet>
		</Modal>
	);
};

const PauseProfileConfirmModal = ({
	open,
	setOpen,
	type,
	data,
}: {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	type: string | null;
	data: any;
}) => {
	const dispatch = useDispatch<AppDispatch>();

	// useEffect(,[])

	const confirmPause = () => {
		let reqFor: PauseType;
		if (type === 'current') {
			reqFor = 'CURRENT';
		} else {
			reqFor = 'NEXT';
		}
		console.log(reqFor);
		dispatch(raiseCoursePauseThunk(reqFor)).then(() => {
			setOpen(false);
		});
	};

	return (
		<Modal
			className="confirm-modal"
			aria-labelledby="modal-title"
			aria-describedby="modal-desc"
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Sheet
				variant="outlined"
				sx={{
					maxWidth: 750,
					borderRadius: 'md',
					p: 3,
					boxShadow: 'lg',
				}}
				className="confirm-sheet"
			>
				<Typography className="modal-title">
					Are you sure you want to pause {type} month ?
				</Typography>
				<Typography className="short-head">
					If you change your mind, you can ask your Batch
					Manager to reject the request
				</Typography>
				<Box className="confirm-btns">
					<AccioButton
						title="Yes"
						className="confirm-btn"
						action={confirmPause}
						variant="outline"
					/>
					<AccioButton
						title="No"
						className="confirm-btn"
						action={() => setOpen(false)}
						variant="outline"
					/>
				</Box>
			</Sheet>
		</Modal>
	);
};

export const PauseProfileIndicator = () => {
	return (
		<Box className="pause-indicator">
			Your course is paused for May 2023
		</Box>
	);
};

export default PauseProfileComponent;
