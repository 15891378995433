import { FC, useEffect, useState } from 'react';
import { recordView } from '../../../redux/popups/popup.thunk';
import { StudentAssesmentData } from '../../../redux/popups/popup.type';
import { useAppDispatch } from '../../../redux/store';
import AccioButton from '../../elements/Button';
import PopUpBox from '../../shared/PopUpBox';
import { handleCTAClick } from './popuputils';
import studentTest from './../../../assets/images/Boy_Giving_Online_Test.png';

const StudentAssesmentModal: FC<{
	data: StudentAssesmentData;
}> = ({ data }) => {
	const { ctas, shouldRecordView, viewRecordData } = data;
	const [openPopUp, setOpenPopUp] = useState(true);

	const handlePopUpClose = () => {
		setOpenPopUp(false);
	};

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (shouldRecordView) {
			dispatch(recordView(viewRecordData));
		}
	}, [shouldRecordView]);

	return (
		<PopUpBox
			handleClose={handlePopUpClose}
			openPopUp={openPopUp}
		>
			<div className="session-limit-pop-up-box-content">
				<p className="pop-up-box-title">
					Student Revision Assessment
				</p>
				{/* <p className="pop-up-box-message">
					Description of test
				</p> */}
				<img
					src={studentTest}
					alt="student test"
					className="student-test-img"
					style={{ width: '100%', height: 'auto' }}
				/>

				<div className="pop-up-box-btn-wrapper">
					{ctas.map((cta, index) => (
						<AccioButton
							key={index}
							title={'Take Test'}
							customClass="btn booking-session-btn-outline"
							action={() => handleCTAClick(cta.actions)}
						/>
					))}
				</div>
			</div>
		</PopUpBox>
	);
};

export default StudentAssesmentModal;
