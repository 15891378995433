import React, { useEffect, useState } from 'react';
import { services } from '../../../../apis';
import {
	Dialog,
	DialogTitle,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { DayPicker } from 'react-day-picker';
import moment from 'moment';
import 'react-day-picker/dist/style.css';
import { useAppDispatch } from '../../../../redux/store';
import { useSelector } from 'react-redux';
import { fetchMentorInvoice } from '../../../../redux/mentorSlice/mentorSlice';

const PaymentDetails = () => {
	const [open, setOpen] = useState(false);
	const [paymentData, setPaymentData] = useState<any>(null);
	const [paymentModal, setPaymentModal] = useState(false);
	const [startDate, setStartDate] = useState<any>(null);
	const [endDate, setEndDate] = useState<any>(null);
	const [defaultMonth, setDefaultMonth] = useState<any>();
	const [defaultYear, setDefaultYear] = useState<any>();
	const [finalAmount, setFinalAmount] = useState<any>(0);

	//mentorInvoice
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorInvoice } = MentorState;

	const handlePaymentClose = () => {
		setPaymentModal(false);
		setOpen(false);
	};

	const handlePaymentModal = () => {
		setOpen(true);
	};

	useEffect(() => {
		if (!open) {
			setStartDate(null);
		} else {
			handleDefaultMonth();
		}
	}, [open]);

	useEffect(() => {
		if (open && startDate !== null)
			handleSelectedMonthPayment();
	}, [open, startDate]);

	useEffect(() => {
		if (startDate === null && endDate === null)
			handleDefaultMonth();
		if (
			mentorInvoice === null &&
			startDate !== null &&
			endDate !== null
		) {
			dispatch(
				fetchMentorInvoice({
					startDate: startDate,
					endDate: endDate,
				})
			);
		}
	}, [startDate, endDate]);

	useEffect(() => {
		if (mentorInvoice !== null && finalAmount === 0)
			setFinalAmount(mentorInvoice.finalAmount);
	}, [mentorInvoice]);

	const handleDefaultMonth = () => {
		const date = new Date(Date.now());
		const day = date.getDate();

		let defaultMonth;
		let defaultYear;

		if (day >= 28) {
			defaultMonth = date.getMonth();
			defaultYear = date.getFullYear();
		} else {
			defaultMonth =
				date.getMonth() - 1 === -1
					? 11
					: date.getMonth() - 1;
			defaultYear =
				date.getMonth() - 1 === -1
					? date.getFullYear() - 1
					: date.getFullYear();
		}
		handleDate(new Date(defaultYear, defaultMonth));
		setDefaultMonth(defaultMonth);
		setDefaultYear(defaultYear);
	};

	const handleDate = (date: Date) => {
		const startingMonth = String(
			date.getMonth() + 1
		).padStart(2, '0');
		const endingMonth = String(
			startingMonth === '12' ? 1 : +startingMonth + 1
		).padStart(2, '0');
		const year = date.getFullYear();
		const startDate = `${year}-${startingMonth}-28 00:00:00.000`;
		setStartDate(startDate);
		const endDate = `${
			startingMonth === '12' ? year + 1 : year
		}-${endingMonth}-27 23:59:59.999`;
		setEndDate(endDate);
		return { startDate, endDate };
	};

	const handleSelectedMonthPayment = async () => {
		const data =
			await services.mentorService.fetchMentorInvoice(
				startDate,
				endDate
			);
		if (data && !data.statusCode) {
			const rows = [
				{
					name: 'Scheduled Session',
					count: data.totalSessions,
					amount: '',
				},
				{
					name: 'Rescheduled by student',
					count: data.rescheduledByStudent,
					amount: '',
				},
				{
					name: 'Cancelled by student',
					count: data.cancelledByStudent,
					amount: '',
				},
				{
					name: 'Mentor No-show',
					count: data.noShowMarkedByStudent,
					amount: data.mentorNoShowPenalty * -1,
				},
				{
					name: 'Student No-show',
					count: data.noShowMarkedByMentor,
					amount: data.studentNoShowCompensation,
				},
				{
					name: 'Completed Sessions',
					count: data.activeSessions,
					amount: '',
				},
				{
					name: 'Total Feedback Delay Time(min)',
					count: data.totalFeedbackDelayTime.toFixed(2),
					amount:
						data.delayedFeedbackPenalty.toFixed(2) * -1,
				},
				{
					name: 'Feedback Filled',
					count: data.feedbackFilledAndRecordingUploaded,
					amount: data.sessionCompletedAmount,
				},
				{
					name: 'Total Amount',
					count: '',
					amount: data.finalAmount,
				},
			];
			setPaymentData(rows);
			setPaymentModal(true);
		}
	};

	return (
		<>
			<Dialog
				open={open && paymentModal}
				onClose={handlePaymentClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div style={{ minWidth: '600px' }}>
					<DialogTitle className="payment-details-title">
						PAYMENT DETAILS
					</DialogTitle>
					<div className="select-month-container">
						<div className="month-picker">
							<DayPicker
								hideHead={true}
								hidden
								defaultMonth={
									new Date(defaultYear, defaultMonth)
								}
								onMonthChange={(date: Date) =>
									handleDate(date)
								}
								numberOfMonths={2}
							/>
						</div>
						<button
							className="custom-months-payment-details-btn"
							onClick={handleSelectedMonthPayment}
						>
							Get Details
						</button>
					</div>
					<p className="to-from-dates">
						<>
							{moment(new Date(startDate)).format(
								'DD/MM/YYYY'
							)}{' '}
							-{' '}
							{moment(new Date(endDate)).format(
								'DD/MM/YYYY'
							)}
						</>
					</p>
					{paymentData && (
						<TableContainer
							component={Paper}
							style={{
								maxWidth: '550px',
								tableLayout: 'fixed',
								margin: '1em',
							}}
						>
							<Table
								size="small"
								aria-label="a dense table"
							>
								<TableHead>
									<TableRow>
										<TableCell align="center"></TableCell>
										<TableCell align="center">
											Sessions
										</TableCell>
										<TableCell align="center">
											Amount
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{paymentData.map((row: any) => (
										<TableRow
											key={row.name}
											// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell
												component="th"
												scope="row"
												style={{
													fontSize: '16px',
													fontWeight: '500',
												}}
											>
												{row.name}
											</TableCell>
											<TableCell
												align="center"
												className="payment-details-cell"
											>
												{row.count}{' '}
											</TableCell>
											<TableCell
												align="center"
												className="payment-details-cell"
											>
												{row.amount}{' '}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</div>
			</Dialog>

			<div className="payment-block">
				<div className="payment-left">
					<h3 className="payment-heading">
						Payment Section: Cycle renews on 28th of every
						month
					</h3>
					<p className="payment-text">
						Net Amount: {finalAmount}
					</p>
					<button
						className="btn-blue payment-btn"
						onClick={handlePaymentModal}
					>
						View Details
					</button>
				</div>
				<div className="payment-right"></div>
			</div>
		</>
	);
};

export default PaymentDetails;
