import crown from '../../assets/images/crown.svg';

type LeaderboardProps = {
	leaderboard: any[];
	source?: string;
};

export const Leaderboard: React.FC<LeaderboardProps> = ({
	leaderboard,
	source,
}) => {
	return (
		<div className={'leaderboard'}>
			<div className={'leaderboard-head'}>
				<h1>Leaderboard</h1>
				<div className={'user-score'}>
					<h3>{leaderboard[10].rank}</h3>
					<span>{leaderboard[10].total_score} pt</span>
					<p className={'user-first-letter'}>
						{leaderboard[10].name[0]}
					</p>
				</div>
			</div>

			<div className={'leaderboard-content'}>
				<div className={'leader-2'}>
					<p className={'leader-image-2'}>
						{leaderboard[1].name[0]}
					</p>
					<h1>
						{leaderboard[1].name !== ''
							? leaderboard[1].name
							: 'A'}
					</h1>
					<div className={'leader-score'}>
						<span>2</span>
						<p>{leaderboard[1].score} pts</p>
					</div>
					<img
						className={'stage'}
						src={`${window.location.origin}/defaults/leader2.svg`}
						alt={'stage'}
					/>
				</div>
				<div className={'leader-1'}>
					<img
						src={crown}
						alt={'crown'}
					/>
					<p className={'leader-image-2'}>
						{leaderboard[0].name[0]}
					</p>
					<h1>
						{leaderboard[0].name !== ''
							? leaderboard[0].name
							: 'A'}
					</h1>
					<div className={'leader-score'}>
						<span>1</span>
						<p>{leaderboard[0].score} pts</p>
					</div>
					<img
						className={'stage'}
						src={`${window.location.origin}/defaults/leader2.svg`}
						alt={'stage'}
					/>
				</div>
				<div className={'leader-3'}>
					<p className={'leader-image-2'}>
						{leaderboard[2].name[0]}
					</p>
					<h1>
						{leaderboard[2].name !== ''
							? leaderboard[2].name
							: 'A'}
					</h1>
					<div className={'leader-score'}>
						<span>3</span>
						<p>{leaderboard[2].score} pts</p>
					</div>
					<img
						className={'stage'}
						src={`${window.location.origin}/defaults/leader2.svg`}
						alt={'stage'}
					/>
				</div>
			</div>
			<ul className={'leaderboard-list'}>
				{leaderboard.map((el: any, index: number) => {
					if (index < 10 && index > 2)
						return (
							<li
								key={index}
								className={'leader-info'}
							>
								<span className={'leader-rank'}>
									{index + 1}
								</span>
								<span className={'leader-name'}>
									{el.name}
								</span>
								<span className={'leader-score-list'}>
									{el.score}
								</span>
							</li>
						);
					return null;
				})}
				<li
					key={leaderboard[10].rank}
					className={'leader-info user-leaderboard-profile'}
				>
					<span className={'leader-rank'}>
						{leaderboard[10].rank}
					</span>
					<span className={'leader-name'}>
						{leaderboard[10].name} (YOU)
					</span>
					<span className={'leader-score-list'}>
						{leaderboard[10].total_score}
					</span>
				</li>
			</ul>
		</div>
	);
};
