import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import TabPanel from '../shared/TabPanel';
import LectureRecordingPanel from './lectureRecordingPanel';
import ModuleQuestionPanel from './moduleQuestionPanel';

const ModuleData = () => {
	const CourseState = useSelector(
		(state: RootState) => state.course
	);

	const { recordingDataLecture, recordingDataDoubt } =
		CourseState;

	const [moduleTabs, setModuleTabs] = useState<any[]>([
		{
			keyId: nanoid(),
			title: 'Assignment',
			element: <ModuleQuestionPanel />,
			show: true,
		},
		{
			keyId: nanoid(),
			title: 'Lecture',
			element: <LectureRecordingPanel type={'lecture'} />,
			show: false,
		},
		{
			keyId: nanoid(),
			title: 'HomeWork Discussion',
			element: <LectureRecordingPanel type={'doubt'} />,
			show: false,
		},
	]);

	useEffect(() => {
		const temp = moduleTabs;
		temp[1].show = recordingDataLecture !== null;
		temp[2].show = recordingDataDoubt !== null;
		setModuleTabs([...temp]);
	}, [recordingDataLecture, recordingDataDoubt]);

	return (
		<div className={'module-content'}>
			<TabPanel tabData={moduleTabs} />
		</div>
	);
};

export default ModuleData;
