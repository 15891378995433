import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from '../../apis';
import { UserResponseInterface } from '../../types/aiStudentAssessment.types';

export const startAssessment = createAsyncThunk(
	'ai-student-assessment/startAssessment',
	async (testId: string | undefined) => {
		const data =
			await services.aiStudentAssessmentService.startAssessment(
				testId
			);
		return data;
	}
);

export const getNextQuestion = createAsyncThunk(
	'ai-student-assessment/getNextQuestion',
	async (assessmentId: string) => {
		const data =
			await services.aiStudentAssessmentService.getNextQuestion(
				assessmentId
			);
		return data;
	}
);

export const submitUserResponse = createAsyncThunk(
	'ai-student-assessment/submitUserResponse',
	async (body: UserResponseInterface) => {
		const data =
			await services.aiStudentAssessmentService.sendUserResponse(
				body
			);
		return data;
	}
);
