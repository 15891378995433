import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';

export class FileUploadDownloadService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchFileUploadDownload(
		questionId: string,
		moduleId: string
	) {
		const url = `${this.endpoint}/file-question/questions/${questionId}/${moduleId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fileAssignmentUploader(body: FormData) {
		const file = body.get('file') as File;
		const fileExtension = file.name.split('.').pop();

		try {
			const url = `${this.endpoint}/file-question/getSignedUrlForFileQuestionAnswerUpload`;
			// Get signed url from backend
			const signedUploadUrlResponse =
				await this.httpService.post<any>(url, {
					data: {
						fileQuestionId: body.get('fileQuestionId'),
						fileExtension,
					},
				});

			// Upload file to signed url
			await this.httpService.put<any>(
				signedUploadUrlResponse.data.url,
				{
					headers: { 'Content-Type': 'text/plain' },
					data: file,
				}
			);

			const saveResponseUrl = `${this.endpoint}/file-question/save-file-question-answer-url`;

			// Save file url to backend
			const saveUrlResponse =
				await this.httpService.post<any>(saveResponseUrl, {
					headers: { 'Content-Type': 'application/json' },
					data: {
						fileQuestionId: body.get('fileQuestionId'),
						uploadedFileUrl: `${signedUploadUrlResponse.data.publicUrl}`,
					},
				});

			return saveUrlResponse.data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchUserDataFileUploadDownload(
		questionId: string
	) {
		const url = `${this.endpoint}/file-question/questions/${questionId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async uploadFile(
		resumeLink: string,
		file: File,
		contentType = 'application/pdf'
	) {
		try {
			const { data } = await this.httpService.put<any>(
				resumeLink,
				{
					headers: {
						'Content-Type': contentType,
					},
					data: file,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
