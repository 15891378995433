import React, { useEffect, useState } from 'react';
import {
	FormGroup,
	SelectChangeEvent,
	Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import SlotRows from './SlotRows';
import {
	notifyError,
	notifySuccess,
} from '../../../../components/notification';
import { services } from '../../../../apis';

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15,
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)',
		},
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor:
					theme.palette.mode === 'dark'
						? '#177ddc'
						: '#1890ff',
			},
		},
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200,
		}),
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor:
			theme.palette.mode === 'dark'
				? 'rgba(255,255,255,.35)'
				: 'rgba(0,0,0,.25)',
		boxSizing: 'border-box',
	},
}));

function UpdateSessions() {
	const weekDays = [
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
		'Sunday',
	];

	const [showStatus, setShowStatus] = useState([
		false,
		false,
		false,
		false,
		false,
		false,
		false,
	]);

	const dayData = {
		shouldUpdate: false,
		slots: [],
	};

	const initialState = {
		Monday: { ...dayData },
		Tuesday: { ...dayData },
		Wednesday: { ...dayData },
		Thursday: { ...dayData },
		Friday: { ...dayData },
		Saturday: { ...dayData },
		Sunday: { ...dayData },
	};

	const [data, setData] = useState<any>(initialState);

	const handleChange = (
		event: SelectChangeEvent,
		weekday: string,
		index: number,
		type: string
	) => {
		console.log('kuch ', event, weekday, index, type);
		let temp = { ...data };
		let newSlots: any = [];
		let existingSlot = [...data[weekday].slots];
		console.log('existing', existingSlot);
		const val =
			event.target.value === 'Not available'
				? ''
				: event.target.value;
		temp[weekday].shouldUpdate = true;
		temp[weekday].slots[index] = {
			startTime:
				type === 'START'
					? val
					: temp[weekday].slots[index].startTime,
			endTime:
				type === 'END'
					? val
					: temp[weekday].slots[index].endTime,
		};

		setData({ ...temp });
	};

	useEffect(() => {
		let temp = { ...data };
		Object.keys(data).forEach(
			(day: string, idx: number) => {
				if (showStatus[idx]) {
					temp = { ...temp };
				} else {
					temp = { ...temp };
					temp[day] = {
						shouldUpdate: false,
						slots: [
							{
								startTime: '',
								endTime: '',
							},
						],
					};
				}
			}
		);
		setData({ ...temp });
	}, [showStatus]);

	const addSlot = (day: string) => {
		let temp = { ...data };
		temp[day] = {
			shouldUpdate: true,
			slots: [
				...temp[day].slots,
				{
					startTime: '',
					endTime: '',
				},
			],
		};
		setData({ ...temp });
	};

	const validateEntries = () => {
		// let temp = {...data}
		let validEntries = true;

		for (let i = 0; i < weekDays.length; i++) {
			const day = weekDays[i];
			if (showStatus[i] && data[day].shouldUpdate) {
				const startOne = data[day].slots[0].startTime;
				const endOne = data[day].slots[0].endTime;
				const startTwo = data[day].slots[1].startTime;
				const endTwo = data[day].slots[1].endTime;

				const validEntriesOne =
					(startOne === '' && endOne === '') ||
					(startOne !== '' && endOne !== '');
				const validEntriesTwo =
					(startTwo === '' && endTwo === '') ||
					(startTwo !== '' && endTwo !== '');

				if (!validEntriesOne || !validEntriesTwo) {
					notifyError(
						'Both starting and ending time must be selectd'
					);
					validEntries = false;
					break;
				}

				const notAvlOne = startOne === '' && endOne === '';
				const notAvlTwo = startTwo === '' && endTwo === '';

				if (!notAvlOne) {
					const validSessionOne =
						new Date(startOne) < new Date(endOne);
					if (!validSessionOne) {
						notifyError(
							'Ending time must greater than Starting time'
						);
						validEntries = false;
						break;
					}
				}

				if (!notAvlTwo) {
					const validSessionTwo =
						new Date(startTwo) < new Date(endTwo);
					if (!validSessionTwo) {
						notifyError(
							'Ending time must greater than Starting time'
						);
						validEntries = false;
						break;
					}
				}
			}
		}
		return validEntries;
	};

	const submitAvailability = async () => {
		// const validEntries = validateEntries();
		console.log('finalData', data);
		// if (validEntries) {
		const response =
			await services.mentorService.updateAvailability({
				days: data,
			});
		if (response && response.statusCode)
			notifyError(response.message);
		else notifySuccess(response.message);
		// }
	};

	console.log('slots data', data);

	return (
		<FormGroup>
			{weekDays.map((weekday: any, index) => (
				<div
					style={{ minHeight: '150px' }}
					key={weekday}
				>
					<Stack
						direction="row"
						spacing={1}
						alignItems="center"
					>
						<p>Update {weekday} Availability</p>
						<AntSwitch
							inputProps={{ 'aria-label': 'ant design' }}
							onClick={() => {
								const temp = showStatus;
								temp[index] = !temp[index];
								setShowStatus([...temp]);
							}}
						/>
					</Stack>

					{showStatus[index] ? (
						<>
							{data[weekday].slots.map(
								(elm: any, ind: any) => (
									<SlotRows
										weekday={weekday}
										i={ind}
										handleChange={handleChange}
									/>
								)
							)}
							<button onClick={() => addSlot(weekday)}>
								Add Row
							</button>
						</>
					) : null}
				</div>
			))}
			<button
				className="avl-submit-btn"
				onClick={submitAvailability}
			>
				Update Availability
			</button>
		</FormGroup>
	);
}

export default UpdateSessions;
