import React from 'react';

const InfoBox = ({
	eligibility,
	disqualificationReasons,
}: any) => {
	return (
		<div
			className={`info-box ${
				eligibility ? 'isEligible' : 'isNotEligible'
			}`}
		>
			<h3>
				{eligibility
					? 'Congrats, You are eligible'
					: 'You are not eligible.'}
			</h3>
			{eligibility === false && (
				<p
					dangerouslySetInnerHTML={{
						__html: disqualificationReasons.join('<br />'),
					}}
				></p>
			)}
		</div>
	);
};

export default InfoBox;
