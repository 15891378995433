import React, { useEffect } from 'react';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import lottie from '../../assets/page/session/Flow 3.json';
import liveIcon from '../../assets/page/session/live-icon.svg';
import { getUpcomingSessionsOfUser } from '../../redux/session/sessionThunk';
import { AppDispatch } from '../../redux/store';
import SessionInfo from './SessionInfo';
import UpcomingSessionCard from './UpcomingSessionCard';
import UpcomingSessionSlider from './UpcomingSessionSlider';

interface UpcomingSessionCardProps {
	sessionTypeId: string;
	sessionType: string;
	isAllowedToJoin: boolean;
	sessionDate: string;
	sessionDuration: number;
	sessionId: string;
	room: string;
	category: string;
}

const SessionTopBox: React.FC = () => {
	const dispatch = useDispatch<AppDispatch>();
	const { allUpcomingSessionData } = useSelector(
		(state: any) => state.session
	);

	useEffect(() => {
		if (allUpcomingSessionData === null) {
			dispatch(getUpcomingSessionsOfUser());
		}
	}, [allUpcomingSessionData]);

	return (
		<div className="session-top-box-wrapper">
			{allUpcomingSessionData &&
			allUpcomingSessionData?.length > 0 ? (
				<Lottie
					loop
					animationData={lottie}
					play
					className="slider-lottie"
				/>
			) : null}
			<div className="slider-container">
				<p className="slider-title">
					<img
						className="icon"
						src={liveIcon}
						alt="Live Icon"
					/>
					<span className="text">Join now</span>
				</p>
				<p className="slider-subtitle-session-info">
					{`You have upcoming ${allUpcomingSessionData?.length} sessions.`}
				</p>

				{allUpcomingSessionData &&
				allUpcomingSessionData.length > 0 ? (
					allUpcomingSessionData.length >= 3 ? (
						<UpcomingSessionSlider
							data={allUpcomingSessionData}
						/>
					) : (
						<div className="upcoming-session-slider-wrapper display">
							{allUpcomingSessionData.map(
								(
									sessionData: UpcomingSessionCardProps,
									index: number
								) => (
									<div key={sessionData.sessionTypeId}>
										<UpcomingSessionCard
											{...sessionData}
											index={index}
										/>
									</div>
								)
							)}
						</div>
					)
				) : (
					<p className="no-data">
						You don't have any upcoming sessions
					</p>
				)}
			</div>
			<SessionInfo />
		</div>
	);
};

export default SessionTopBox;
