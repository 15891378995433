// import React from 'react';
// import ReactMarkdown from 'react-markdown';
// import remarkGfm from 'remark-gfm';
// // import remarkHTML from 'remark-html';
// import rehypeHighlight from 'rehype-highlight';
// import rehypeRaw from 'rehype-raw';

// interface MarkDownProps {
// 	statement: string;
// }

// const MarkDown = ({ statement }: MarkDownProps) => {
// 	return (
// 		<ReactMarkdown
// 			className={`markdown-body`}
// 			rehypePlugins={[[rehypeHighlight], [rehypeRaw]]}
// 			remarkPlugins={[remarkGfm, remarkHTML]}
// 		>
// 			{statement}
// 		</ReactMarkdown>
// 	);
// };

// export default MarkDown;

import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';

interface MarkDownProps {
	statement: string;
}

const MarkDown = ({ statement }: MarkDownProps) => {
	return (
		<ReactMarkdown
			className={`markdown-body`}
			rehypePlugins={[[rehypeHighlight], [rehypeRaw]]}
			remarkPlugins={[remarkGfm]}
		>
			{statement}
		</ReactMarkdown>
	);
};

export default MarkDown;
