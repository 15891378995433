const Tabs = ({ activeTab, setActiveTab }: any) => {
	return (
		<div className="tabs">
			<button
				className={activeTab === 1 ? 'active' : ''}
				onClick={() => setActiveTab(1)}
				style={{ width: '20%', display: 'inline' }}
			>
				Upcoming / Ongoing
			</button>

			<button
				className={activeTab === 2 ? 'active' : ''}
				onClick={() => setActiveTab(2)}
				style={{ width: '20%', display: 'inline' }}
			>
				Pending
			</button>

			<button
				className={activeTab === 3 ? 'active' : ''}
				onClick={() => setActiveTab(3)}
				style={{ width: '20%', display: 'inline' }}
			>
				Didn't Happen
			</button>

			<button
				className={activeTab === 4 ? 'active' : ''}
				onClick={() => setActiveTab(4)}
				style={{ width: '20%', display: 'inline' }}
			>
				Completed
			</button>
		</div>
	);
};

export default Tabs;
