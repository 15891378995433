import Cookies from 'js-cookie';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const SqlEditor = () => {
	const location = useLocation();

	const questionId: Array<string | null> | null | string =
		queryString.parse(location.search).question;

	const endpoint = encodeURIComponent(
		'https://acciojob-backend-eobnd7jx2q-el.a.run.app'
	);
	const platform = 'unrestricted';
	const type = 'course';
	const contestId = '';
	const source = encodeURIComponent(window.location.origin);
	const hostingPlatform = 'https://ide2.acciojob.com';
	const token = Cookies.get('acciojobs-token');

	return (
		<iframe
			className={'CodeEditor'}
			allow={'fullscreen'}
			src={`${hostingPlatform}/sql/?question=${questionId}&endpoint=${endpoint}&platform=${platform}&type=${type}&contest=${contestId}&source=${source}#accessToken=${token}`}
		/>
	);
};

export default SqlEditor;
