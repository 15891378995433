import { createSlice } from '@reduxjs/toolkit';
import community from './courseLevel/community.json';
import datacourse from './courseLevel/datacourse.json';
import dsacourse from './courseLevel/dsacourse.json';
import fsdcourse from './courseLevel/fsdcourse.json';
import launchpad from './courseLevel/launchpad.json';
import maincourse from './courseLevel/maincourse.json';
import mentor from './courseLevel/mentor.json';
import communityRoutes from './routeLevel/community_routes.json';
import daLaunchpadRoutes from './routeLevel/da_launchpad_routes.json';
import datacourseRoutes from './routeLevel/datacourse_routes.json';
import dsacourseRoutes from './routeLevel/dsa_routes.json';
import fsdcourseRoutes from './routeLevel/fsd_routes.json';
import launchpadRoutes from './routeLevel/launchpad_routes.json';
import maincourseRoutes from './routeLevel/maincourse_routes.json';
import mentorRoutes from './routeLevel/mentor_routes.json';

// Interfaces
interface RouteInterface {
	[key: string]: {
		[key: string]: string | boolean;
	};
}

interface NavigationInterface {
	[key: string]: boolean;
}

interface ComponentDataInterface {
	[key: string]: {
		[key: string]: string | string[];
	};
}

interface PageDataInterface {
	[key: string]: ComponentDataInterface;
}

// Thunk and Functions
const buildNav = (
	activeRoutes?: RouteInterface
): NavigationInterface => {
	const temp =
		activeRoutes === undefined
			? { ...maincourseRoutes.routes }
			: { ...activeRoutes };

	console.log('activeRoutes', activeRoutes);

	const nav = {};
	Object.keys(temp).forEach((el: string) => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		nav[temp[el]] = true;
	});
	return nav;
};

// Slice Interface
interface FeatureControllerSlice {
	userRole:
		| 'maincourse'
		| 'datacourse'
		| 'launchpad'
		| 'mentor'
		| 'dsacourse'
		| 'fsdcourse'
		| 'community'
		| 'dataprecourse'
		| 'dalaunchpad'
		| 'precourse';

	activeRoutes: RouteInterface;
	currRoute: string | null;
	navigationOptions: NavigationInterface;
	// home: PageDataInterface;
	controllerJson: any;
	loaded: boolean;
}

const initialState: FeatureControllerSlice = {
	userRole: 'maincourse',
	activeRoutes: { ...maincourseRoutes.routes },
	currRoute: null,
	navigationOptions: buildNav(),
	controllerJson: { ...maincourse },
	loaded: false,
};

const allTypesOfRoles: Record<
	string,
	FeatureControllerSlice['userRole']
> = {
	'data-precourse-user': 'dataprecourse',
	'playground-user': 'launchpad',
	'data-maincourse-user': 'datacourse',
	mentor: 'mentor',
	instructor: 'maincourse',
	'precourse-user': 'precourse',
	'maincourse-user': 'maincourse',
	'community-user': 'community',
	'dsa-maincourse-user': 'dsacourse',
	'fsd-maincourse-user': 'fsdcourse',
	'da-playground-user': 'dalaunchpad',
};

export const featureControllerSlice = createSlice({
	name: 'features',
	initialState,
	reducers: {
		setUserRole(state, action) {
			const userRole =
				allTypesOfRoles[action.payload as string];
			state.userRole = userRole;
			console.log('role', action.payload, state.userRole);
			if (userRole === 'datacourse') {
				state.activeRoutes = { ...datacourseRoutes.routes };
				state.controllerJson = { ...datacourse };
			} else if (userRole === 'dsacourse') {
				state.activeRoutes = { ...dsacourseRoutes.routes };
				state.controllerJson = { ...dsacourse };
			} else if (userRole === 'fsdcourse') {
				state.activeRoutes = { ...fsdcourseRoutes.routes };
				state.controllerJson = { ...fsdcourse };
			} else if (userRole === 'launchpad') {
				state.activeRoutes = { ...launchpadRoutes.routes };
				state.controllerJson = { ...launchpad };
			} else if (userRole === 'dalaunchpad') {
				state.activeRoutes = {
					...daLaunchpadRoutes.routes,
				};
				state.controllerJson = { ...launchpad };
			} else if (userRole === 'community') {
				state.activeRoutes = { ...communityRoutes.routes };
				state.controllerJson = { ...community };
			} else if (userRole === 'mentor') {
				state.activeRoutes = { ...mentorRoutes.routes };
				state.controllerJson = { ...mentor };
				console.log(
					'state.activeRoutes',
					state.activeRoutes
				);
				console.log('state.activeRoutes', mentor);
			} else {
				state.activeRoutes = { ...maincourseRoutes.routes };
				state.controllerJson = { ...maincourse };
			}
			state.navigationOptions = buildNav(
				state.activeRoutes
			);
			console.log(
				'activeRoutes nav',
				state.navigationOptions
			);
			state.loaded = true;
		},
		setActiveRoutes(state, action) {
			state.activeRoutes = action.payload;
		},
	},
	extraReducers: {},
});

//Exporting Actions
export const { setUserRole, setActiveRoutes } =
	featureControllerSlice.actions;
export default featureControllerSlice.reducer;
