import { FC, useEffect, useState } from 'react';
import queryString from 'query-string';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import Lottie from 'react-lottie-player';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { services } from '../../apis';
import submitted from '../../assets/lottie/submitted.json';
import Loader from '../../components/Loader';
import {
	fetchAptitudeQuestions,
	submitAptitudeQuestions,
} from '../../redux/modules/moduleSlice';
import {
	BiExitFullscreen,
	BiFullscreen,
	BiLeftArrowAlt,
} from 'react-icons/bi';
import { QuestionPanel } from './components/QuestionPanel';
import { DifficultyTypeEnum } from '../../redux/gym/type';
import {
	ReflexContainer,
	ReflexElement,
	ReflexSplitter,
} from 'react-reflex';
import TabPanel from '../../components/shared/TabPanel';
import { nanoid } from 'nanoid';
import RenderOptions from './components/RenderOptions';
import AccioButton from '../../components/elements/Button';
import { SubmissionTab } from '../gym-aptitute/GymAptitudeNew';
import PopUpBox from '../../components/shared/PopUpBox';
import { QnsNavigation } from '../../components/shared/QuestionsNavigation';

const Aptitude = () => {
	const location = useLocation();

	const moduleId = queryString.parse(location.search)
		.moduleId as string;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const moduleState = useSelector(
		(state: any) => state.module
	);

	const {
		aptitudeQuestions,
		submitAptitudeLoading,
		aptitudeScore,
		alreadySubmitted,
		aptitudeLoading,
	} = moduleState;

	useEffect(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(fetchAptitudeQuestions(moduleId));
	}, [moduleId]);

	const [questionNumber, setQuestionNumber] = useState(1);
	const [questionStatement, setQuestionStatement] =
		useState('');
	const [options, setOptions] = useState([]);
	const [questionType, setQuestionType] = useState('');
	const [answerId, setAnswerId] = useState(undefined);
	const [answer, setAnswer] = useState<any>([]);

	const [questionId, setQuestionId] = useState<any>(null);
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [showSubmitModal, setShowSubmitModal] =
		useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const [submitWarning, setSubmitWarning] = useState(true);
	const [explanation, setExplanation] = useState('');
	const [difficulty, setDifficulty] =
		useState<DifficultyTypeEnum>(DifficultyTypeEnum.EASY);

	const [correctAnswer, setCorrectAnswer] = useState([]);

	const [loadAptitudeQuestion, setLoadAptitudeQuestion] =
		useState(false);
	const [score, setScore] = useState(0);

	const getQuestionById = async (questionId: string) => {
		setLoadAptitudeQuestion(true);
		try {
			const res =
				await services.courseService.getAptitudeQuestionById(
					questionId
				);
			setAnswer([]);
			if (res.answer !== null) {
				setAnswerId(res.answer.id);
				setAnswer(res.answer.answer);
			} else {
				setAnswerId(undefined);
				setAnswer([]);
			}
			setCorrectAnswer(res.question.correctAnswer);
			setLoadAptitudeQuestion(false);
			setQuestionData(res.question);
			return res.question;
		} catch (e) {
			console.log(e);
		}
	};

	const saveQuestion = async (answerArray: string[]) => {
		const data = {
			conceptualQuestionId: questionId,
			answer: answerArray,
			id: answerId,
		};

		setAnswer(answerArray);

		try {
			const res =
				await services.courseService.saveAptitudeQuestions(
					data
				);
			console.log(
				'Save Aptitude Question temp',
				res,
				res.answer
			);
			return res.question;
		} catch (e) {
			console.log(e);
		}
	};

	const [questionData, setQuestionData] =
		useState<any>(null);

	useEffect(() => {
		if (aptitudeQuestions?.length > 0) {
			if (questionId) getQuestionById(questionId);
			else {
				getQuestionById(aptitudeQuestions[0].id);
				setQuestionId(aptitudeQuestions[0].id);
			}
		} else {
			setQuestionId(null);
			setQuestionStatement('');
			setQuestionType('');
			setOptions([]);
			setScore(0);
			setIsSubmitted(false);
			setExplanation('');
		}
	}, [aptitudeQuestions, questionId]);

	useEffect(() => {
		if (questionData) {
			setQuestionStatement(questionData?.statement);
			setQuestionType(questionData?.questionType);
			setOptions(questionData?.answerOptions);
			setScore(questionData?.score);
			setIsSubmitted(alreadySubmitted);
			setExplanation(questionData?.explanation);
			setDifficulty(questionData?.difficulty);
		}
	}, [questionData, aptitudeQuestions]);

	const submit = () => {
		setShowSubmitModal(true);
		setIsSubmitted(true);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		dispatch(submitAptitudeQuestions(moduleId));
	};

	const [tabs, setTabs] = useState<
		{
			key: string;
			title: string;
			element: JSX.Element;
			show: boolean;
		}[]
	>([]);

	useEffect(() => {
		const temp = [
			{
				key: nanoid(),
				title: 'Question',
				element: (
					<QuestionPanel
						questionStatement={questionStatement}
						difficulty={difficulty}
						score={score}
						questionNumber={questionNumber}
					/>
				),
				show: true,
			},
			{
				key: nanoid(),
				title: 'Submission',
				element: (
					<SubmissionTab isSubmitted={isSubmitted} />
				),
				show: true,
			},
		];
		setTabs(temp);
	}, [questionStatement, difficulty, score]);

	const [isFullScreen, setIsFullScreen] = useState(false);

	const toggleFullScreen = async () => {
		setIsFullScreen(!isFullScreen);

		if (isFullScreen) await document.exitFullscreen();
		else await document.documentElement.requestFullscreen();
	};

	return (
		<div className={'aptitude-new'}>
			<PopUpBox
				handleClose={() => setShowSubmitModal(false)}
				openPopUp={showSubmitModal}
			>
				<div className="submit-all-popup">
					{submitWarning ? (
						<div className={'pop-up warn'}>
							<p className="pop-up-box-title">
								You are about to submit all questions
							</p>

							<p className="pop-up-box-message">
								<span className="text-1">
									Note: Make sure to answer all the
									questions before submitting the assigment
								</span>
							</p>
							{/* <h5 className='confirm-text'>Press confirm to submit</h5> */}
							<div className="pop-up-box-btn-wrapper">
								<AccioButton
									title="Submit All"
									variant="outline"
									customClass="btn booking-session-btn-outline"
									action={() => {
										setSubmitWarning(false);
										submit();
									}}
								/>
								<AccioButton
									title="Let Me Solve All"
									variant="primary"
									customClass="btn booking-session-btn-primary"
									action={() => {
										setShowSubmitModal(false);
									}}
								/>
							</div>
						</div>
					) : submitAptitudeLoading ? (
						<div className={'pop-up'}>
							<h3 className="pop-up-box-title">
								Submitting
							</h3>
							<Loader />
							<p className="pop-up-box-message">
								Your answers are being submitted
							</p>
							<p>Please wait...</p>
						</div>
					) : (
						<div className={'submitted-container'}>
							<h3 className="submitted-title">Submitted</h3>
							<Lottie
								className={'congrats'}
								loop={false}
								animationData={submitted}
								play
								style={{
									height: '150px',
								}}
							/>
							<h4 className="submitted-text">
								Your answers have been submitted
							</h4>
							<p className="score">
								Your Total Score : {aptitudeScore}
							</p>
							<AccioButton
								title="Checkout Correct Answers"
								className="checkout-btn"
								variant="primary"
								customClass="btn booking-session-btn-primary"
								action={() => {
									setShowSubmitModal(false);
									setIsSubmitted(true);
								}}
							/>
						</div>
					)}
				</div>
			</PopUpBox>

			<div className="back-containter">
				<div
					className="left"
					onClick={() => {
						navigate('/modules');
					}}
				>
					<BiLeftArrowAlt
						size={22}
						className="icon"
					/>
					<p className="text">Back</p>
				</div>
				<div className="right">
					<div onClick={toggleFullScreen}>
						{isFullScreen ? (
							<BiExitFullscreen
								cursor={'pointer'}
								className="grey-icon"
							/>
						) : (
							<BiFullscreen
								cursor={'pointer'}
								className="grey-icon"
							/>
						)}
					</div>
				</div>
			</div>

			<p className={'meta-info'}>
				Individual multiple select questions answers are
				auto-saved, press save to save answers for
				subjective and integer type questions. Submit all
				questions to get score.
			</p>

			{aptitudeQuestions?.length > 0 ? (
				<div className={'aptitude-container'}>
					<QnsNavigation
						qnsArray={aptitudeQuestions}
						questionId={questionId}
						setQuestionId={setQuestionId}
						setQuestionNumber={setQuestionNumber}
					/>

					{!loadAptitudeQuestion ? (
						<ReflexContainer
							orientation="vertical"
							className={
								'aptitude-question-panel-web-container'
							}
							windowResizeAware
						>
							<ReflexElement
								className={'vertical-reflex-element'}
								minSize={350}
							>
								<TabPanel tabData={tabs} />
							</ReflexElement>

							<ReflexSplitter style={{ width: '20px' }}>
								<img
									alt="3 dots"
									style={{
										width: '20px',
										height: '100%',
									}}
									src={
										'https://www.svgrepo.com/show/345223/three-dots-vertical.svg'
									}
								/>
							</ReflexSplitter>

							<ReflexElement minSize={350}>
								<div className={'options-panel'}>
									<div className={'header-container'}>
										<div>
											<label></label>
											<div className={'bottom-line'} />
										</div>
									</div>
									<RenderOptions
										options={options}
										saveQuestion={saveQuestion}
										correctAnswer={correctAnswer}
										alreadySubmitted={alreadySubmitted}
										explanation={explanation}
										answer={answer}
										questionType={questionType}
									/>

									<div className="btn-container">
										<AccioButton
											title={'Submit All'}
											className={'submit-btn'}
											action={() => {
												setShowSubmitModal(true);
											}}
											variant={
												alreadySubmitted
													? 'disable'
													: 'primary'
											}
											disabled={alreadySubmitted}
										/>
									</div>
								</div>
							</ReflexElement>
						</ReflexContainer>
					) : (
						<Loader />
					)}
				</div>
			) : aptitudeLoading ? (
				<Loader />
			) : (
				<div className={'no-questions'}>
					<h1>No questions available for this module</h1>
					<button
						onClick={() => {
							navigate('/modules');
						}}
						className={'go-back-btn'}
					>
						Go Back
					</button>
				</div>
			)}
		</div>
	);
};

export default Aptitude;
