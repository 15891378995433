import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class MentorService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchAllMentors() {
		const url = `${this.endpoint}/mentors/`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data.list;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async loginMentor(body: any) {
		const url = `${this.endpoint}/mentors/login`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/** MENTOR PROFILE **/
	async fetchMentorProfileStatus() {
		const url = `${this.endpoint}/mentor-dashboard/is-profile-completed`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorProfile() {
		const url = `${this.endpoint}/mentor-dashboard/profile`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('mentorProfile', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateMentorProfile(body: any) {
		const url = `${this.endpoint}/mentor-dashboard/profile`;
		try {
			const { data } = await this.httpService.patch<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async uploadMentorPhoto(body: any) {
		const url = `${this.endpoint}/student-personal-details/upload-profile-picture`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{
					headers: {
						'content-type': 'multipart/form-data',
					},
					data: body,
				}
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/** MENTOR EXPERIENCE **/
	async fetchMentorExperiences() {
		const url = `${this.endpoint}/professional-experience`;
		try {
			const { data } = await this.httpService.get<any>(url);
			console.log('ewdsesfecdse', data);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	//For creating and updating mentor experience
	async updateMentorExperience(body: any) {
		const url = `${this.endpoint}/professional-experience`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async deleteMentorExperience(id: string) {
		const url = `${this.endpoint}/professional-experience/${id}`;
		try {
			const { data } = await this.httpService.delete<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/** MENTOR SKILLS **/
	async addMentorSkill(body: any) {
		const url = `${this.endpoint}/user-skills/`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorSkills() {
		const url = `${this.endpoint}/user-skills/`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/** MENTOR AVAILABILITY **/
	async getMentorAvailability() {
		const url = `${this.endpoint}/mentor-dashboard/mentor-availability`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async updateAvailability(body: any) {
		const url = `${this.endpoint}/mentor-dashboard/mentor-availability`;
		try {
			const { data } = await this.httpService.patch<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/** MENTOR SESSIONS **/

	async fetchMentorSessions(params: string) {
		const url = `${this.endpoint}/mentor-dashboard/sessions?tab=${params}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorSessionTypes() {
		const url = `${this.endpoint}/session/mentor-session-types?type=TAKEN`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorPreferencesByRole() {
		const url = `${this.endpoint}/session/mentor-session-types?type=BY_ROLE`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchEligibleSessionTypes() {
		const url = `${this.endpoint}/session/mentor-session-types?type=ELIGIBLE`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchUpcominOngoingSessions() {
		const url = `${this.endpoint}/mentor-dashboard/sessions/ongoing-upcoming`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorAnnouncements() {
		const url = `${this.endpoint}/mentor-dashboard/announcements`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markShiftedToGmeet(sessionId: string) {
		const url = `${this.endpoint}/session/mark-shifted-to-gmeet/${sessionId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async cancelSession(sessionId: string, body: any) {
		const url = `${this.endpoint}/session/cancel-session-from-mentor/${sessionId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorInvoice(
		startDate: string,
		endDate: string
	) {
		const url = `${this.endpoint}/mentor-dashboard/payment-invoice?startDate=${startDate}&endDate=${endDate}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorQCScore() {
		const url = `${this.endpoint}/mentor-dashboard/qc-score`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchTodaysSessions() {
		const url = `${this.endpoint}/session/today/upcoming`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async fetchMentorPreferences() {
		const url = `${this.endpoint}/mentor-session-preference/`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async addMentorPreferences(body: any) {
		const url = `${this.endpoint}/mentor-session-preference/`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async markDidntShow(sessionId: string, body: any) {
		const url = `${this.endpoint}/session/mark-didnt-happen/${sessionId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/* MENTOR DOCS */
	async fetchMentorDocs() {
		const url = `${this.endpoint}/mentor-dashboard/important-docs`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/*** Questions ***/
	async fetchRecommendedQuestions(sessionId: string) {
		const url = `${this.endpoint}/session/recommended-questions/${sessionId}`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async finaliseQuestions(sessionId: string, body: any) {
		const url = `${this.endpoint}/session/finalize-questions/${sessionId}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	async getNewQuestion(
		section: string,
		sessionId: string,
		body: any
	) {
		const url = `${this.endpoint}/session/get-recommended-questions/${sessionId}/${section}`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}

	/**** SESSION RECORDING ****/
	async uploadRecording(body: any) {
		const url = `${this.endpoint}/mentor-dashboard/session-recording`;
		try {
			const { data } = await this.httpService.post<any>(
				url,
				{ data: body }
			);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
