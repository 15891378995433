import { Fragment, useEffect, useState } from 'react';
import './App.scss';
import './styles/styles.scss';
// Importing Providers
// Importing Routes
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AppRouter from './Router/AppRouter';
import NavigationDrawer from './components/navigation/navigationDrawer';
import { setActiveRoutes } from './featureController/featureControllerSlice';
import {
	fetchCourseData,
	fetchMeetingIDs,
	getLeaderBoard,
	getTotalAssignmentSolved,
} from './redux/course/courseSlice';

import { EventAnalytics } from '@acciojob/event-analytics';
import { Loader } from '@acciojob/loader';
import Hotjar from '@hotjar/browser';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import {
	fetchMentorProfile,
	fetchMentorProfileStatus,
} from './redux/mentorSlice/mentorSlice';
import { AppDispatch, RootState } from './redux/store';
import {
	fetchAnnouncements,
	userPauseStatusThunk,
} from './redux/user/userSlice';
import eventConfig from './utils/events.json';
import { SoftskillsActions } from './redux/softskills/softskills.slice';
import { getSectionalFeedbackPopupThunk } from './redux/allFeedbackModal/allFeedbackModal.thunk';
import SectionFeedbackPopUp from './components/home/Popups/SectionFeedbackPopUp';

function App() {
	const dispatch = useDispatch<AppDispatch>();
	const location = useLocation();

	// <!-- Hotjar Tracking Code for my site -->
	const siteId = 3537341;
	const hotjarVersion = 6;

	Hotjar.init(siteId, hotjarVersion);

	const allowedRoles = ['maincourse-user'];

	const path = location.pathname;

	const courseState = useSelector(
		(state: any) => state.course
	);
	const { courseID } = courseState;

	const userState = useSelector((state: any) => state.user);
	const {
		latestCourseID,
		user,
		courseList,
		isCrispConfigured,
		userTrackDetails,
	} = userState;
	const mentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, mentorProfileStatus } = mentorState;

	const featureController = useSelector(
		(state: RootState) => state.featureController
	);

	const { userRole, activeRoutes, loaded } =
		featureController;

	useEffect(() => {
		if (!isCrispConfigured) {
			return;
		}
		if (window.$crisp) {
			if (path === '/doubt-support')
				window.$crisp.push(['do', 'chat:show']);
			else window.$crisp.push(['do', 'chat:hide']);
		}
	}, [path, isCrispConfigured]);

	useEffect(() => {
		dispatch(userPauseStatusThunk());
	}, []);

	// const isJobPortalAccessible = (trackId: string) => {
	// 	const notAccessibleTracks = [
	// 		'f5d65706-db02-4947-9d2f-faaa59ab99be',
	// 		'fa955b87-3a02-43a5-ab8e-6dab0f6b67e1',
	// 	];
	// 	return !notAccessibleTracks.includes(trackId);
	// };

	const [userTrackAllowed, setUserTrackAllowed] =
		useState(false);

	// useEffect(() => {
	// 	if (userTrackDetails) {
	// 		if (!isJobPortalAccessible(userTrackDetails?.id)) {
	// 			const ar = { ...activeRoutes };
	// 			ar['/job-portal'] = {
	// 				active: false,
	// 				name: 'job portal',
	// 			};
	// 			dispatch(setActiveRoutes({ ...ar }))
	// 		}
	// 		console.log(
	// 			'isJobPortalAccessible',
	// 			isJobPortalAccessible(userTrackDetails?.trackId)
	// 		);
	// 	}
	// }, [userTrackDetails]);

	useEffect(() => {
		if (
			courseList.length > 0 &&
			(path === '/' || path === '/modules')
		) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(fetchMeetingIDs(courseList[0].id));
		}
	}, [courseList]);

	useEffect(() => {
		if (courseID === null && latestCourseID !== null) {
			console.log('location', location.pathname);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(fetchCourseData());
			if (path === '/') {
				dispatch(getTotalAssignmentSolved());
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(fetchAnnouncements());
			}
		}
	}, [latestCourseID, path]);

	useEffect(() => {
		if (courseID !== null && path === '/') {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			dispatch(getLeaderBoard());
		}
	}, [courseID, path]);

	useEffect(() => {
		if (user && user.roles[0] === 'mentor') {
			if (mentor === null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(fetchMentorProfile());
				console.log('apptsx1');
			} else if (mentorProfileStatus === null) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				dispatch(fetchMentorProfileStatus());
			}
		}
	}, [mentor, mentorProfileStatus, userRole, path]);

	useEffect(() => {
		const nonLiveDoubt = [
			'7da58c7a-75d2-4918-a61a-79698d66fdf8',
			'5a6a2159-0dc8-4790-a261-6e35e49d1703',
			'8ea576d4-e5bf-4fda-a8e0-221ef99ba6fd',
			'aaa341c0-e341-4914-9368-547c94dadff6',
			'c55cd845-166c-4f76-be2f-2bef95cc18d3',
			'7f2b48d1-49fd-4855-a01f-3996d8e7d76f',
			'548a11a8-7742-4a83-9f38-b506924e0d2b',
			'4ce9bf82-494a-4ae9-8a47-cdf9d26a0d31',
		];

		if (courseList.length > 0) {
			let temp = false;
			temp = nonLiveDoubt.includes(courseList[0].unitId);
			if (temp) {
				const ar = { ...activeRoutes };
				ar['/live-doubts'] = {
					active: false,
					name: 'live doubts',
				};
				dispatch(setActiveRoutes({ ...ar }));
			}
		}
	}, [courseList, latestCourseID]);

	useEffect(() => {
		const nonDoubtSupport = [
			'fe4af567-b464-45a1-bf2b-8b84cb3dd123',
		];

		if (courseList.length > 0) {
			let temp = false;
			temp = nonDoubtSupport.includes(courseList[0].unitId);
			console.log(
				'temp',
				temp,
				nonDoubtSupport,
				courseList[0].unitId
			);

			if (temp) {
				const ar = { ...activeRoutes };
				ar['/doubt-support'] = {
					active: false,
					name: 'doubt-support',
				};
				dispatch(setActiveRoutes({ ...ar }));
			}
		}
	}, [courseList, latestCourseID]);

	useEffect(() => {
		if (
			user &&
			user.roles[0] === 'data-maincourse-user' &&
			user.id === 'b027eeab-ec43-4fbc-9c68-f47fd66e3039'
		) {
			const temp = { ...activeRoutes };
			temp['/job-portal'] = {
				active: true,
				name: 'job portal',
			};
			temp['/refer-and-earn'] = {
				active: false,
				name: 'refer & earn',
			};

			dispatch(setActiveRoutes({ ...temp }));
		}
	}, [user]);

	const userTrackCheck = () => {
		if (user) {
			console.log(userTrackDetails);
			const byPassArray = [
				'data-precourse-user',
				'playground-user',
				'mentor',
				'instructor',
				'precourse-user',
				'data-precourse-user',
				'da-playground-user',
			];
			if (userTrackDetails) return true;
			else if (byPassArray.includes(user.roles[0])) {
				return true;
			} else {
				return false;
			}
		}
		return false;
	};

	useEffect(() => {
		if (user) {
			const val = userTrackCheck();
			console.log('val', val);
			setUserTrackAllowed(Boolean(val));
		}
	}, [user, userTrackDetails]);

	useEffect(() => {
		if (user) {
			// persisting softskills details
			const selectedModuleDetails = localStorage.getItem(
				'selected-module-details'
			);
			if (selectedModuleDetails) {
				const selectedModuleDetailsObj = JSON.parse(
					selectedModuleDetails
				);
				dispatch(
					SoftskillsActions.setSelectedModuleDetails(
						selectedModuleDetailsObj
					)
				);
			}

			const selectedCourseTitle = localStorage.getItem(
				'selected-course-title'
			);
			if (selectedCourseTitle) {
				dispatch(
					SoftskillsActions.setSelectedCourseTitle(
						selectedCourseTitle
					)
				);
			}
		}
	}, [user]);

	const { sectionalFeedbackData } = useSelector(
		(state: any) => state.allFeedbackModal
	);

	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		let section;
		if (location.pathname === '/modules') {
			section = 'MODULE';
		} else if (location.pathname === '/contests') {
			section = 'CONTEST';
		}
		if (section) {
			try {
				setShowLoader(true);
				dispatch(getSectionalFeedbackPopupThunk(section));
			} catch (error) {
				console.log(error);
			}
		}
		setShowLoader(false);
	}, [location.pathname]);

	return (
		<NavigationDrawer>
			<>
				{loaded &&
				user &&
				userTrackAllowed &&
				!showLoader ? (
					<SectionFeedbackPopUp
						sectionalFeedbackData={sectionalFeedbackData}
					>
						<AppRouter />
					</SectionFeedbackPopUp>
				) : (
					//<DashboardPopUps
					// 	popUpData={popUpData}
					// 	displayPopUp={displayPopUp}
					///>
					<Loader />
				)}
				{user ? (
					<EventAnalytics
						locationProp={location}
						userInfo={{
							userId: user ? user.id : uuidv4(),
							userName: user
								? user.firstName + ' ' + user.lastName
								: 'Anonymous User',
							isAnonymous: user ? false : true,
							phoneNumber: user ? user.phoneNumber : '',
						}}
						eventConfig={eventConfig}
					/>
				) : null}
			</>
		</NavigationDrawer>
	);
}

export default App;
