import React, { useEffect, useState } from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import {
	fetchMentorSessions,
	setPrevEndTime,
	setPrevSessionId,
	setPrevStartTime,
	setPrevTab,
	setSessionsPage,
} from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import {
	getParams,
	onPropsChange,
	removeDuplicatesById,
} from '../mentorUtils';
import SessionsTable from './SessionsTable';

const DIDNT_HAPPEN = 'DIDNT_HAPPEN';

const DidntHappen = ({
	sessionType,
	startDate,
	endDate,
}: any) => {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorSessions, sessionsPage } = MentorState;

	const [sessionsData, setSessionsData] = useState<any[]>(
		[]
	);
	const [selectedReason, setSelectedReason] =
		useState<any>(null);

	const reasons = {
		RESCHEDULED_CANCELLED_BY_STUDENT:
			'Cancelled By Student',
		CANCELLED_BY_MENTOR: 'Cancelled By Mentor',
		MARKED_DIDNT_HAPPEN: "Marked Didn't Happen",
		MENTOR_NO_SHOW: 'Mentor No Show',
		STUDENT_NO_SHOW: 'Student No Show',
	};

	useEffect(() => {
		dispatch(setSessionsPage(1));
	}, []);

	useEffect(() => {
		if (mentorSessions !== null) {
			const temp = removeDuplicatesById([
				...sessionsData,
				...mentorSessions,
			]);
			setSessionsData([...temp]);
		}
	}, [mentorSessions]);

	const [reasonChanged, setReasonChanged] =
		useState<boolean>(false);

	useEffect(() => {
		if (reasonChanged) {
			dispatch(setSessionsPage(1));
			setReasonChanged(false);
			setSessionsData([]);
			getSessionsData();
			dispatch(setSessionsPage(2));
		} else {
			const changed = onPropsChange(
				sessionType,
				startDate,
				endDate,
				DIDNT_HAPPEN
			);
			setSessionsData([]);
			switch (changed) {
				case 'SESSION_CHANGE': {
					dispatch(setSessionsPage(1));
					dispatch(setPrevSessionId(sessionType));
					getSessionsData();
					// dispatch(setSessionsPage(2));
					break;
				}
				case 'DATE_CHANGE': {
					dispatch(setSessionsPage(1));
					dispatch(setPrevStartTime(startDate));
					dispatch(setPrevEndTime(endDate));
					getSessionsData();
					// dispatch(setSessionsPage(2));
					break;
				}
				case 'TAB_CHANGE': {
					getSessionsData();
				}
			}
		}
		dispatch(setPrevTab(DIDNT_HAPPEN));
	}, [
		sessionType,
		startDate,
		endDate,
		selectedReason,
		reasonChanged,
	]);

	const handleReasonChange = (event: SelectChangeEvent) => {
		const currentReason = event.target.value;
		setSelectedReason(currentReason);
		// if(currentReason.length > 0) {
		setReasonChanged(true);
		// }
	};

	const getSessionsData = () => {
		let params = getParams(
			startDate,
			endDate,
			sessionType,
			DIDNT_HAPPEN
		);
		if (
			selectedReason !== null &&
			selectedReason.length > 0
		)
			params += `&didntHappenFilterStatus=${selectedReason}`;
		dispatch(fetchMentorSessions(params));
	};

	const fetchMoreDataCallback = () => {
		getSessionsData();
		dispatch(setSessionsPage(sessionsPage + 1));
	};

	return (
		<React.Fragment>
			<div className="reason-filter">
				<FormControl sx={{ width: '300px' }}>
					<InputLabel>Status</InputLabel>
					<Select
						value={selectedReason}
						label="Status"
						onChange={handleReasonChange}
					>
						<MenuItem value={''}>None</MenuItem>
						{/* {reasons.map((reason:any) => {
                      return <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                    })} */}
						{Object.keys(reasons).map((reason: any) => {
							//@ts-ignore
							return (
								<MenuItem
									key={reason}
									value={reason}
								>
									{
										//@ts-ignore
										reasons[reason]
									}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
			<SessionsTable
				sessionsData={sessionsData}
				fetchMoreDataCallback={fetchMoreDataCallback}
				tab={DIDNT_HAPPEN}
			/>
		</React.Fragment>
	);
};

export default DidntHappen;
