import { encryptData } from '@acciojob/event-analytics';

const ResourceBox = ({ resources }: any) => {
	const linkifyResources = (resources: string[]) => {
		let response = '';
		resources.forEach((resource) => {
			response += `<a 
				href=${resource} 
				target="_blank"
				event-analytics-{'3daf1121-0be8-4767-92a5-b5fa4586eecf'}
				data-config=${encryptData(
					JSON.stringify({
						name: resource,
					})
				)}
				>${resource}</a><br />`;
		});
		return response;
	};

	return resources && resources.length !== 0 ? (
		<div className="info-box info">
			<h3>Resources for preparation</h3>
			<p
				dangerouslySetInnerHTML={{
					__html: linkifyResources(resources),
				}}
			></p>
		</div>
	) : null;
};

export default ResourceBox;
