import React from 'react';
import {
	FormControl,
	MenuItem,
	Select,
	Stack,
	Typography,
} from '@mui/material';

const timings = [
	{
		label: 'Not available',
		value: 'Not available',
	},
	{
		label: '08:00',
		value: '2022-07-23 08:00:00.000',
	},
	{
		label: '08:30',
		value: '2022-07-23 08:30:00.000',
	},
	{
		label: '09:00',
		value: '2022-07-23 09:00:00.000',
	},
	{
		label: '09:30',
		value: '2022-07-23 09:30:00.000',
	},
	{
		label: '10:00',
		value: '2022-07-23 10:00:00.000',
	},
	{
		label: '10:30',
		value: '2022-07-23 10:30:00.000',
	},
	{
		label: '11:00',
		value: '2022-07-23 11:00:00.000',
	},
	{
		label: '11:30',
		value: '2022-07-23 11:30:00.000',
	},
	{
		label: '12:00',
		value: '2022-07-23 12:00:00.000',
	},
	{
		label: '12:30',
		value: '2022-07-23 12:30:00.000',
	},
	{
		label: '13:00',
		value: '2022-07-23 13:00:00.000',
	},
	{
		label: '13:30',
		value: '2022-07-23 13:30:00.000',
	},
	{
		label: '14:00',
		value: '2022-07-23 14:00:00.000',
	},
	{
		label: '14:30',
		value: '2022-07-23 14:30:00.000',
	},
	{
		label: '15:00',
		value: '2022-07-23 15:00:00.000',
	},
	{
		label: '15:30',
		value: '2022-07-23 15:30:00.000',
	},
	{
		label: '16:00',
		value: '2022-07-23 16:00:00.000',
	},
	{
		label: '16:30',
		value: '2022-07-23 16:30:00.000',
	},
	{
		label: '17:00',
		value: '2022-07-23 17:00:00.000',
	},
	{
		label: '17:30',
		value: '2022-07-23 17:30:00.000',
	},
	{
		label: '18:00',
		value: '2022-07-23 18:00:00.000',
	},
	{
		label: '18:30',
		value: '2022-07-23 18:30:00.000',
	},
	{
		label: '19:00',
		value: '2022-07-23 19:00:00.000',
	},
	{
		label: '19:30',
		value: '2022-07-23 19:30:00.000',
	},
	{
		label: '20:00',
		value: '2022-07-23 20:00:00.000',
	},
	{
		label: '20:30',
		value: '2022-07-23 20:30:00.000',
	},
	{
		label: '21:00',
		value: '2022-07-23 21:00:00.000',
	},
	{
		label: '21:30',
		value: '2022-07-23 21:30:00.000',
	},
	{
		label: '22:00:00',
		value: '2022-07-23 22:00:00',
	},
	{
		label: '22:30',
		value: '2022-07-23 22:30:00.000',
	},
	{
		label: '23:00',
		value: '2022-07-23 23:00:00.000',
	},
	{
		label: '23:30',
		value: '2022-07-23 23:30:00.000',
	},
];

function SlotRows({ weekday, i, handleChange }: any) {
	return (
		<div className="slot-1">
			<Stack
				display={'flex'}
				flexDirection={'row'}
				justifyContent={'space-between'}
				alignItems={'center'}
			>
				<p>
					<b>
						{weekday} Slot {i + 1}
					</b>
				</p>
				<div>
					<FormControl sx={{ m: 1, minWidth: 120 }}>
						<Select
							onChange={(e) =>
								handleChange(e, weekday, i, 'START')
							}
							sx={{ height: '40px' }}
						>
							{timings.map((timing) => {
								return (
									<MenuItem
										key={timing.label}
										value={timing.value}
									>
										{timing.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
					<FormControl sx={{ m: 1, minWidth: 120 }}>
						<Select
							onChange={(e) =>
								handleChange(e, weekday, i, 'END')
							}
							sx={{ height: '40px' }}
						>
							{timings.map((timing) => {
								return (
									<MenuItem
										key={timing.label}
										value={timing.value}
									>
										{timing.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
			</Stack>
		</div>
	);
}

export default SlotRows;
