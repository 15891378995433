import { AiOutlineArrowRight } from 'react-icons/ai';
import { BiLock } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

const MentorLock = () => {
	const navigate = useNavigate();
	return (
		<div className={'locked-screen'}>
			<div></div>
			<div className={'lock-card'}>
				<BiLock />
				<p>
					Please complete your profile to access all pages
				</p>
				<button onClick={() => navigate('/profile')}>
					Go to Profile Page <AiOutlineArrowRight />
				</button>
			</div>
		</div>
	);
};

export default MentorLock;
