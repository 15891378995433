import { Loader } from '@acciojob/loader';

import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { BiFullscreen } from 'react-icons/bi';
import { RxDotFilled } from 'react-icons/rx';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AccioButton from '../../components/elements/Button';
import {
	fetchJupyterNoteBookAttemptId,
	fetchJupyterNoteBookQuestionData,
	fetchJupyterNoteBookQuestionIframe,
	fetchJupyterNoteBookQuestionRequestId,
	fetchJupyterNoteBookQuestionResultData,
} from '../../redux/jupyterNoteBookSlice/jupyterNoteBookSlice';
import { AppDispatch, RootState } from '../../redux/store';
import PopupModal from './PopupModal';

const JupyterNoteBook: React.FC = () => {
	const location = useLocation();
	const dispatch = useDispatch<AppDispatch>();

	const questionId = queryString.parse(location.search)
		.question as string;

	// Fetch data from Redux store
	const {
		requestId,
		iframeData,
		iframeLoader,
		attemptId,
		attemptIdLoader,
		testCaseData,
		testCaseLoader,
		attemptMessage,
		attemptStatus,
		questionData,
	} = useSelector(
		(state: RootState) => state.jupyterNoteBook
	);

	//TODO: Evolution state not getting
	//TODO:Cookie not getting from jupyterhub.acciojob.com
	//TODO:

	const [openPopUp, setOpenPopUp] = useState(false);

	useEffect(() => {
		if (questionId) {
			dispatch(
				fetchJupyterNoteBookQuestionData(questionId)
			);
			dispatch(
				fetchJupyterNoteBookQuestionRequestId(questionId)
			);
		}
	}, [questionId]);

	useEffect(() => {
		if (requestId && iframeData == null) {
			dispatch(
				fetchJupyterNoteBookQuestionIframe(requestId)
			);
		}
	}, [requestId]);

	useEffect(() => {
		if (questionId && attemptId !== null) {
			dispatch(
				fetchJupyterNoteBookQuestionResultData({
					attemptId,
					questionId,
				})
			);
		}
	}, [attemptId, questionId]);

	const handelSubmitAction = () => {
		setOpenPopUp(true);

		dispatch(fetchJupyterNoteBookAttemptId(questionId));
	};

	const [fullScreen, setFullScreen] = useState(false);

	const goInFullscreen = (element: any) => {
		const requestFullscreen =
			element.requestFullscreen ||
			element.mozRequestFullScreen ||
			element.webkitRequestFullscreen ||
			element.msRequestFullscreen;

		if (requestFullscreen) {
			requestFullscreen.call(element);
			setFullScreen(true);
		}
	};

	document.addEventListener('fullscreenchange', () => {
		setFullScreen(!!document.fullscreenElement);
	});

	const { user } = useSelector((state: any) => state.user);

	const [isSubmitBtnEnable, setIsSubmitBtnEnable] =
		useState(true);

	useEffect(() => {
		if (attemptId === null) {
			setIsSubmitBtnEnable(true);
		} else {
			if (
				attemptId &&
				testCaseData &&
				testCaseData?.shouldShowSubmitButton
			) {
				setIsSubmitBtnEnable(true);
			} else {
				setIsSubmitBtnEnable(false);
			}
		}
	}, [attemptId, testCaseData]);

	useEffect(() => {
		if (
			iframeData &&
			questionData &&
			questionData?.lastAttemptId
		) {
			dispatch(
				fetchJupyterNoteBookQuestionResultData({
					attemptId: questionData?.lastAttemptId,
					questionId,
				})
			);
			setOpenPopUp(true);
		}
	}, [questionData, iframeData]);

	const [submitButtonShow, setSubmitButtonShow] =
		useState(true);

	return (
		<div className="jupyter-notebook-wrapper">
			<div className="jupyter-notebook-wrapper">
				<div className="jupyter-notebook-question-data">
					<p className="question">
						{questionData?.title ?? ''}
					</p>
					<div className="data">
						<div className="data-container">
							<p className="text">
								<span>Difficulty :</span>{' '}
								<span
									className={`text-difficulty ${
										questionData?.difficulty ?? 'EASY'
									}`}
								>
									<RxDotFilled
										style={{ marginRight: '1px' }}
									/>{' '}
									{questionData?.difficulty ?? 'EASY'}
								</span>
							</p>
							<p className="text">
								<span>Max score :</span>

								<span className="text-max-score">
									{' '}
									{questionData?.maxScore ?? 0}
								</span>
							</p>
							<p className="text">
								<span>Your score : </span>
								<span> {questionData?.userScore ?? 0}</span>
							</p>
						</div>

						<AccioButton
							title="Full screen"
							variant="outline"
							customClass="full-screen-btn"
							action={() =>
								goInFullscreen(
									$('#iframe-container').get(0)
								)
							}
							icon={<BiFullscreen size={18} />}
						/>
					</div>
				</div>
				<div
					className={`iframe-container ${
						fullScreen ? 'full-screen-container' : ''
					}`}
					id="iframe-container"
				>
					{iframeLoader ? (
						<Loader factVisibility={true} />
					) : iframeData !== null &&
					  iframeData.length > 0 ? (
						<iframe
							id="iframeId"
							src={iframeData}
							style={{
								width: '100%',
								height: '100%',
								border: 'none',
								overflow: 'hidden',
							}}
							sandbox="allow-scripts allow-forms allow-same-origin"
							title="Jupyter Notebook"
						/>
					) : (
						<div className="no-iframe">
							<span>No iframe available</span>
						</div>
					)}
				</div>
				<div className="submit-btn-wrapper">
					{submitButtonShow ? (
						<AccioButton
							customClass={`submit-btn ${
								!isSubmitBtnEnable ? 'disable' : ''
							}`}
							title="Submit"
							action={handelSubmitAction}
							disabled={!isSubmitBtnEnable}
						/>
					) : null}
				</div>

				<PopupModal
					setOpen={setOpenPopUp}
					selectedValue={openPopUp}
					open={openPopUp}
					loader1={attemptIdLoader}
					loader2={testCaseLoader}
					data={testCaseData}
					attemptStatus={attemptStatus}
					attemptMessage={attemptMessage}
					attemptId={
						(attemptId || questionData?.lastAttemptId) ??
						null
					}
					lastAttemptMsg={!attemptId}
					questionId={questionId}
				/>
			</div>
		</div>
	);
};

export default JupyterNoteBook;
