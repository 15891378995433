import {
	Button,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import React, {
	useState,
	useEffect,
	FormEventHandler,
} from 'react';
import MyMultiSelectField from './formElements/MyMultiselectField';
import MySelectField from './formElements/MySelectField';
import MyTextField from './formElements/MyTextField';
// import { PlacementFormFields } from './types';
import {
	PlacementProfileFormFields,
	preferredCodingLanguageOptions,
	otherCodingSkillsOptions,
	homeStateOptions,
} from './student_personal_details';
import { city } from './cities';
import {
	fetchPlacementProfile,
	updatePlacementProfile,
	updateProfile,
} from '../../redux/user/userSlice';
import { useAppDispatch } from '../../redux/store';
import { useSelector } from 'react-redux';
import { services } from '../../apis';
import { Typography } from '@mui/material';

import { storage } from '../../config/firebase';
import {
	ref,
	getDownloadURL,
	uploadBytesResumable,
} from 'firebase/storage';

import moment from 'moment';
import {
	notifyError,
	notifySuccess,
} from '../../components/notification';

const enumToList = (data: any): string[] =>
	Object.values(data);

const FormFields = [
	{
		title: 'General Details',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			collegeBacklogs: {
				label: 'Active College Backlogs',
				type: 'number',
				disabled: false,
			},
			preferredCodingLanguage: {
				label: 'Preferred Coding Language',
				type: 'select',
				disabled: false,
				items: enumToList(preferredCodingLanguageOptions),
			},
			otherCodingSkills: {
				label: 'Coding Skills',
				type: 'multiselect',
				disabled: false,
				items: enumToList(otherCodingSkillsOptions),
			},
			githubProfileLink: {
				label: 'Github Profile Link',
				type: 'text',
				disabled: false,
				required: false,
			},
			currentState: {
				label: 'Current State',
				type: 'select',
				disabled: false,
				items: Object.keys(homeStateOptions),
			},
		},
	},
	{
		title:
			'Since you are a working professional, please enter details regarding your work experience(compulsory)',
		nonWorkingTitle:
			'If you have professional working experience, please enter the info below. Or leave it blank.(Dont include internships)',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			workExperienceMonths: {
				label: 'Work Experience (Months)',
				type: 'number',
				disabled: false,
			},
			currentCompany: {
				label: 'Current Company',
				type: 'text',
				disabled: false,
			},
			currentCtc: {
				label: 'Current CTC',
				type: 'number',
				disabled: false,
			},
		},
	},
	{
		title: 'Since we are you working professional',
		show: true,
		type: 'batch',
		controls: -1,
		items: {
			noticePeriod: {
				label: 'Notice Period (Months)',
				type: 'number',
				disabled: false,
			},
		},
	},
];

const PlacementProfile = () => {
	const dispatch = useAppDispatch();

	const UserState = useSelector((state: any) => state.user);
	const { placementProfile, user, profile } = UserState;

	const [isProfileLocked, setIsProfileLocked] =
		useState(false);
	const [isResumeLocked, setIsResumeLocked] =
		useState(false);

	const initialFormData: PlacementProfileFormFields = {
		userId: user !== null ? user.id : '',
		resume: '',
		collegeBacklogs: null,
		preferredCodingLanguage: null,
		otherCodingSkills: [],
		currentState: '',
		currentCity: '',
		githubProfileLink: '',
		workExperienceMonths: null,
		currentCompany: '',
		currentCtc: '',
		noticePeriod: null,
	};

	useEffect(() => {
		dispatch(fetchPlacementProfile());
	}, []);

	const [formFields, setFormFields] = useState(FormFields);

	const [formData, setFormData] =
		useState<PlacementProfileFormFields>(initialFormData);

	const [showCities, setShowCities] = useState(false);
	const [
		showPreviousJobSection,
		setShowPreviousJobSection,
	] = useState(false);
	const [intialFillingDone, setIntialFillingDone] =
		useState(false);

	// Update Form Data on Input Change
	const updateFormData = (
		fieldId: string,
		fieldValue: string | string[],
		event?: any
	) => {
		if (fieldId === 'currentState' && fieldValue !== '') {
			setShowCities(true);
		}
		setFormData({
			...formData,
			[fieldId]: fieldValue,
		});
	};

	const handleSubmit: FormEventHandler<
		HTMLFormElement
	> = async (e) => {
		e.preventDefault();
		console.log(formData);
		// uploadResume();
		try {
			await uploadResume();
			if (!formData['resume']) {
				notifyError('Please upload your resume');
				return;
			}
			const data =
				await services.userService.savePlacementProfile(
					formData
				);
			notifySuccess('Professional Info saved!');
			console.log('data', data);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		if (profile !== null) {
			console.log('profile', profile);
			setShowPreviousJobSection(
				true
				// profile.jobStatus === 'Working_Professional' &&
				// 	profile.jobType === 'Coding Related'
			);
			// if(profile.jobStatus !== 'Working_Professional'){
			// 	formData['workExperienceMonths'] = '0';
			// }
		}
	}, []);

	useEffect(() => {
		if (user != null) {
			setIsProfileLocked(user.isProfileLocked);
			setIsResumeLocked(user.isResumeLocked);
		}
	}, [user]);

	useEffect(() => {
		if (placementProfile !== null && !intialFillingDone) {
			console.log('placement profile', placementProfile);
			let newFormData = { ...placementProfile };
			if (profile.jobStatus !== 'Working_Professional') {
				newFormData = {
					...newFormData,
					workExperienceMonths: null,
					currentCompany: null,
					currentCtc: null,
					noticePeriod: null,
				};
			}
			console.log('newFormData placement', newFormData);
			setFormData({
				...newFormData,
			});
			console.log(
				'moment',
				moment(placementProfile.dateOfBirth).format(
					'yyyy-mm-dd'
				)
			);

			if (placementProfile.currentState !== 'null') {
				setShowCities(true);
			}
			setIntialFillingDone(true);
		}
	}, [placementProfile, profile]);

	useEffect(() => {
		if (intialFillingDone) {
			console.log(formData);
			dispatch(updatePlacementProfile(formData));
		}
	}, [formData]);

	const [file, setFile] = useState<any>();
	const [resumeUrl, setResumeUrl] = useState('');

	const [progresspercent, setProgresspercent] = useState(0);

	const uploadResume = async () => {
		console.log('Resume', file);
		if (!file) return;
		console.log('Resume After', file);

		const storageRef = ref(
			storage,
			`resumes/${file.name + ' ' + user.id}`
		);
		const uploadTask = uploadBytesResumable(
			storageRef,
			file
		);

		await uploadTask.on(
			'state_changed',
			(snapshot) => {
				const progress = Math.round(
					(snapshot.bytesTransferred /
						snapshot.totalBytes) *
						100
				);
				setProgresspercent(progress);
			},
			(error) => {
				alert(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then(
					(downloadURL) => {
						setResumeUrl(downloadURL);
						setFormData({
							...formData,
							resume: downloadURL,
						});
						console.log('resume download', downloadURL);
					}
				);
			}
		);
	};

	return (
		<form
			className="profile-form"
			onSubmit={handleSubmit}
		>
			{formFields.map((fieldBatch, idx) => {
				if (!showPreviousJobSection && idx > 0) {
					return null;
				}

				if (fieldBatch.show && fieldBatch.type === 'batch')
					return (
						<div
							key={idx}
							className="batch"
						>
							{idx > 0 ? (
								<h4>
									{profile.jobStatus ===
									'Working_Professional'
										? fieldBatch.title
										: fieldBatch.nonWorkingTitle}
								</h4>
							) : (
								<h4>{fieldBatch.title}</h4>
							)}
							<div className="fields">
								{Object.entries(fieldBatch.items).map(
									([fieldId, field], idx) => {
										if (field.type === 'select')
											return (
												<MySelectField
													key={idx}
													id={fieldId}
													{...field}
													updateFormData={updateFormData}
													formData={formData}
													isProfileLocked={isProfileLocked}
												/>
											);
										else if (field.type === 'multiselect')
											return (
												<MyMultiSelectField
													key={idx}
													id={fieldId}
													{...field}
													updateFormData={updateFormData}
													formData={formData}
													isProfileLocked={isProfileLocked}
												/>
											);
										return (
											<MyTextField
												key={idx}
												id={fieldId}
												{...field}
												updateFormData={updateFormData}
												formData={formData}
												isProfileLocked={isProfileLocked}
											/>
										);
									}
								)}
								{fieldBatch.title === 'General Details' &&
								showCities &&
								formData.currentState !== '' ? (
									<MySelectField
										label={'Current District'}
										type={'select'}
										disabled={false}
										key={idx}
										id={'currentCity'}
										// eslint-disable-next-line @typescript-eslint/ban-ts-comment
										//@ts-ignore
										items={city[formData.currentState]}
										updateFormData={updateFormData}
										formData={formData}
										isProfileLocked={isProfileLocked}
									/>
								) : null}
								{fieldBatch.title === 'General Details' ? (
									<div className={'upload-resume'}>
										{user && !user.isResumeLocked ? (
											<label>Upload Resume</label>
										) : null}
										{formData.resume !== null ? (
											<a
												href={formData.resume}
												target={'_blank'}
												rel="noreferrer"
											>
												view resume
											</a>
										) : null}
										{user && !user.isResumeLocked ? (
											<>
												<input
													type={'file'}
													className={'resumeUpload'}
													onChange={(event: any) =>
														setFile(event.target.files[0])
													}
													disabled={isResumeLocked}
												/>

												<p onClick={uploadResume}>
													upload resume
												</p>
											</>
										) : null}
										{resumeUrl !== '' &&
										user &&
										!isResumeLocked ? (
											<div className="outerbar">
												<div
													className="innerbar"
													style={{
														width: `${progresspercent}%`,
													}}
												>
													<span>{progresspercent}%</span>
												</div>
											</div>
										) : null}
									</div>
								) : null}
							</div>
						</div>
					);
				return <></>;
			})}
			{user && (!isProfileLocked || !isResumeLocked) ? (
				<Button
					className="profile-submit"
					type="submit"
				>
					Submit
				</Button>
			) : null}
		</form>
	);
};

export default PlacementProfile;
