import Modal from '@mui/joy/Modal';
import { EditProfileModal } from './EditProfile/EditProfileModal';
import { GiPauseButton } from 'react-icons/gi';
import AccioButton from '../../elements/Button';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../redux/store';
import {
	PauseType,
	raiseCoursePauseThunk,
} from '../../../redux/user/userSlice';
import { toast } from 'react-toastify';

export const PauseModal = ({ state, setState }: any) => {
	const { pauseStatus } = useAppSelector(
		(state) => state.user
	);

	const dispatch = useAppDispatch();

	const pauseModule = (type: PauseType) => {
		dispatch(raiseCoursePauseThunk(type));
	};

	return (
		<div>
			<Modal
				open={state}
				onClose={() => setState(false)}
				className="achievement-modal-container"
			>
				<div className="achievement-modal-wrapper">
					<div className="header-wrapper">
						<div className="heading-flex">
							<GiPauseButton
								style={{ color: '#000', fontSize: '18px' }}
							/>
							<p className="heading">Pause Courses</p>
						</div>
						{/* <p className="desc">
							Lorem ipsum dolor sit amet consectetur.
						</p> */}
					</div>
					<div className="pause-wrapper">
						<div className="pause-wrapper-notes">
							<p
								className="notes-p"
								style={{ fontWeight: 600 }}
							>
								Please note :
							</p>
							<ol>
								<li className="notes-p">
									You can request course pause for 1 module
									at a time, you can request your current
									ongoing module or the next upcoming
									module.
								</li>
								<li className="notes-p">
									After pause, your live course will resume
									from the module that was paused.
								</li>
								<li
									className="notes-p"
									style={{ marginBottom: 0 }}
								>
									Access to all live classes will be paused
									for your selected duration, all other
									components of the platform including class
									recording, live doubt, one on one session
									will remain unaffected
								</li>
							</ol>
						</div>
						<p className="bm-text">
							Your Batch Manager will have to approve your
							request to pause the course.
						</p>
						<div className="grey-pause-flex">
							<div className="grey-box-div">
								<p className="text">Pauses Available</p>
								<p
									className="text"
									style={{ fontSize: '18px' }}
								>
									{pauseStatus.availablePauses ?? '0'}
								</p>
							</div>
							<div className="grey-box-div">
								<p className="text">Pauses Taken</p>
								<p
									className="text"
									style={{ fontSize: '18px' }}
								>
									{pauseStatus.takenPauses ?? '0'}
								</p>
							</div>
						</div>

						<div className="btns-div-flex">
							<AccioButton
								title="Pause Current Month"
								customClass="blue-border-btn"
								action={() => pauseModule('CURRENT')}
							/>
							<AccioButton
								title="Pause Upcoming Month"
								customClass="blue-border-btn"
								action={() => pauseModule('NEXT')}
							/>
						</div>
					</div>
				</div>
			</Modal>
		</div>
	);
};
