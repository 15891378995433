import { Loader } from '@acciojob/loader';
import moment from 'moment';
import { FC, Fragment, useState } from 'react';
import { services } from '../../apis';
import {
	BaseClassesType,
	LiveClassesType,
	PastLiveClassesType,
	UpcomingClassesType,
} from '../../redux/softskills/softskills.type';
import AccioButton from '../elements/Button';
import Recordings1v1 from '../Recordings1v1';
import PastSessionsCard from './PastSessionsCard';

interface LiveClassSectionProps {
	ongoingLiveClasses: Array<BaseClassesType>;
	upcomingLiveClasses: Array<UpcomingClassesType>;
}

const LiveClassSection: FC<LiveClassesType> = ({
	ongoingLiveClasses,
	pastLiveClasses,
	upcomingLiveClasses,
}) => {
	const [selectedVideo, setSelectedVideo] =
		useState<PastLiveClassesType>();

	const [openRecordingModal, setOpenRecordingModal] =
		useState(false);
	const handelCloseRecordingModal = () => {
		setOpenRecordingModal(false);
	};

	const handleSelectedVideo = (
		video: PastLiveClassesType
	) => {
		setSelectedVideo(video);
		setOpenRecordingModal(true);
	};

	return (
		<Fragment>
			<div className="live-class-section">
				{ongoingLiveClasses.length > 0 ? (
					<LiveClassCard
						ongoingLiveClasses={ongoingLiveClasses}
						upcomingLiveClasses={upcomingLiveClasses}
					/>
				) : null}

				{pastLiveClasses.length > 0 ? (
					<div className="past-sessions">
						<h2 className="past-sessions-heading">
							Past Sessions
						</h2>
						<div className="past-sessions-cards">
							{pastLiveClasses.map(
								(item: PastLiveClassesType, index) => (
									<PastSessionsCard
										key={index}
										videoDetails={item}
										setSelectedVideo={handleSelectedVideo}
									/>
								)
							)}
							{selectedVideo !== null &&
							selectedVideo?.recordingLink &&
							selectedVideo.recordingLink.length > 0 ? (
								<Recordings1v1
									currentRecordings={
										selectedVideo?.recordingLink
									}
									open={openRecordingModal}
									handleClose={handelCloseRecordingModal}
								/>
							) : null}
						</div>
					</div>
				) : null}
			</div>
		</Fragment>
	);
};

const LiveClassCard: FC<LiveClassSectionProps> = ({
	ongoingLiveClasses,
	upcomingLiveClasses,
}) => {
	const [showLoader, setShowLoader] = useState(false);
	const handleJoinClass = async (id: string) => {
		setShowLoader(true);
		try {
			const res =
				await services.softskillsService.getLiveClassJoinLink(
					id
				);
			if (res.joinLink) {
				window.open(res.joinLink, '_blank');
			}
		} catch (error) {
			console.log(error);
		} finally {
			setShowLoader(false);
		}
	};

	console.log('upcomingLiveClasses', upcomingLiveClasses);

	return (
		<div>
			{showLoader ? (
				<Loader />
			) : ongoingLiveClasses.length > 0 ? (
				ongoingLiveClasses.map(
					(item: BaseClassesType, index: number) => (
						<div
							className="live-class-card"
							key={index}
						>
							<div className="live-class-card-left">
								<h2 className="live-class-heading">
									Live session is being conducted right now
								</h2>
								<p className="live-class-description"></p>
								<AccioButton
									title="Join Session"
									className="join-session-btn"
									action={() => handleJoinClass(item.id)}
								/>
							</div>
							<div className="live-class-card-right">
								<img
									src="https://storage.googleapis.com/acciojob-open-file-collections/ed01c735-05f6-47c0-bf3d-df51a3ecb9dc_join.gif"
									className="live-class-img"
								/>
							</div>
						</div>
					)
				)
			) : null}

			{upcomingLiveClasses.length > 0 ? (
				<div className="live-class-card">
					<div className="live-class-card-left">
						<h2 className="live-class-heading">
							Upcoming Class
						</h2>
						<p className="live-class-title">
							{upcomingLiveClasses[0].title}
						</p>
						<p className="live-class-timing">
							{moment(
								upcomingLiveClasses[0].start_time
							).format('hh:mm A')}
							,{' '}
							{moment(
								upcomingLiveClasses[0].start_time
							).format('Do MMM YY')}
						</p>
					</div>
					<div className="live-class-card-right">
						<img
							src="https://storage.googleapis.com/acciojob-open-file-collections/ed01c735-05f6-47c0-bf3d-df51a3ecb9dc_join.gif"
							className="live-class-img"
						/>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default LiveClassSection;
