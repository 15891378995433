import { Loader } from '@acciojob/loader';
import { Avatar } from '@mui/material';
import { useEffect } from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { BsTelephone } from 'react-icons/bs';
import { FiMail } from 'react-icons/fi';
import { HiOutlineChatBubbleOvalLeftEllipsis } from 'react-icons/hi2';
import { IoLogoWhatsapp } from 'react-icons/io';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import building from '../../assets/images/building.svg';
import TimeSlotsTable from '../../components/contactBM/TimeSlotTable';
import AccioButton from '../../components/elements/Button';
import { fetchBMData } from '../../redux/contactBm/contactBM.thunk';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';

export const ContactBMPage = () => {
	const dispatch = useAppDispatch();
	const userState = useSelector((state: any) => state.user);
	const { user } = userState;
	const courseState = useSelector(
		(state: any) => state.course
	);
	const { batchManagerPhoneNumber } = courseState;

	const { loading, data } = useAppSelector(
		(state) => state.contactBM
	);

	const navigate = useNavigate();

	useEffect(() => {
		dispatch(fetchBMData());
	}, []);

	if (loading || !data) {
		return (
			<Loader
				width={'100%'}
				height={'100vh'}
			/>
		);
	} else if (
		user !== null &&
		user.roles[0] !== 'mentor' &&
		batchManagerPhoneNumber !== null &&
		batchManagerPhoneNumber !== ''
	) {
		const getName = () => {
			return data.firstName && data.lastName
				? data.firstName + ' ' + data.lastName
				: data.firstName
				? data.firstName
				: 'Name Of Batch Manager';
		};

		return (
			<div className="contact-bm-wrapper">
				<div className="blue-wrapper">
					<h1>Contact Batch Manager</h1>
					<div className="bm-flex">
						<Avatar
							sx={{ width: 72, height: 72 }}
							src={
								data.profilePicture
									? data.profilePicture
									: 'PhotoUrl'
							}
						/>
						<h3 className="heading">{getName()}</h3>
						<div className="icon-flex">
							<BsTelephone
								style={{
									color: '#fff',
									fontSize: '24px',
								}}
							/>
							<h3 className="heading">
								+91{' '}
								{data.phoneNumber
									? data.phoneNumber
									: '9999999999'}
							</h3>
						</div>
						<div className="icon-flex">
							<FiMail
								style={{
									color: '#fff',
									fontSize: '24px',
								}}
							/>
							<h3 className="heading">
								{data.email
									? data.email
									: 'batchmanager@acciojob.com'}
							</h3>
						</div>
					</div>
				</div>
				<div className="main-wrapper">
					<div className="card-flex">
						<div
							className="card"
							style={{ width: '50%' }}
						>
							<div className="heading-flex">
								<HiOutlineChatBubbleOvalLeftEllipsis
									style={{
										color: '#2666be',
										fontSize: '24px',
									}}
								/>
								<h1 className="heading">
									Contact Doubt Support
								</h1>
							</div>
							<p>
								Chat/Video Doubt Support. The timings for
								the coding related doubts is 11AM-1AM. The
								timings for general doubts is 11AM-11PM.
							</p>
							<AccioButton
								title="Go To Doubt Support"
								variant="primary"
								action={() => navigate('/doubt-support')}
							/>
						</div>
						<div
							className="card"
							style={{ width: '50%' }}
						>
							<div className="heading-flex">
								<IoLogoWhatsapp
									style={{
										color: '#25D366',
										fontSize: '24px',
									}}
								/>
								<h1 className="heading">
									Directly Contact Batch Manager
								</h1>
							</div>
							<p>
								Please make sure that you're only directly
								contacting the BM when urgent. If not
								contact doubt support.
							</p>
							<AccioButton
								title="Contact BM"
								variant="primary"
								action={() => {
									window.open(
										`https://wa.me/+91${data.phoneNumber}`
									);
								}}
							/>
						</div>
					</div>
					<div className="card-flex">
						<div
							className="card"
							style={{ width: '100%' }}
						>
							<div className="heading-flex">
								<BiTimeFive
									style={{
										color: '#2666be',
										fontSize: '24px',
									}}
								/>
								<h1 className="heading">
									Call Back Request
								</h1>
							</div>
							<p>
								Below are the time slots you can select for
								your Batch manager to contact you. Also
								state a reason as well accordingly.
							</p>
							<TimeSlotsTable bmId={data.id} />
						</div>
					</div>
					<img
						alt="building-background"
						src={building}
						className="background-building-image"
					/>
				</div>
			</div>
		);
	} else {
		return <Navigate to={'/'} />;
	}
};
