import {
	createSlice,
	PayloadAction,
} from '@reduxjs/toolkit';
import { fetchPopUpToDisplay } from './popup.thunk';
import { PopupData } from './popup.type';

const initialState: PopupData = {
	popupToDisplay: null,
	isLoading: false,
	showPopup: false,
	popupType: '',
};

export const popupSlice = createSlice({
	name: 'popup',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchPopUpToDisplay.pending.type]: (state) => {
			state.isLoading = true;
		},
		[fetchPopUpToDisplay.fulfilled.type]: (
			state,
			action: ReturnType<
				typeof fetchPopUpToDisplay.fulfilled
			>
		) => {
			console.log('actionpop', action.payload);
			state.popupToDisplay = action.payload;
		},
		[fetchPopUpToDisplay.rejected.type]: (
			state,
			action: ReturnType<
				typeof fetchPopUpToDisplay.rejected
			>
		) => {
			state.isLoading = false;
		},
	},
});

export const PopupActions = popupSlice.actions;
export const PopupReducers = popupSlice.reducer;
