import { Dialog } from '@mui/material';

function FormModal({
	openForm,
	url,
	handleFormClose,
	selectedQns,
}: any) {
	return (
		<div className="feedbackform-container">
			<Dialog
				open={openForm}
				onClose={handleFormClose}
				fullWidth
				maxWidth="md"
			>
				{selectedQns !== null &&
					Object.keys(selectedQns).length > 0 && (
						<div
							style={{
								maxHeight: '400px',
								overflowY: 'auto',
								padding: '1em',
							}}
						>
							<h4 className="finalised-qns-title">
								Finalised Questions
							</h4>
							<div style={{ padding: '1em' }}>
								{Object.keys(selectedQns).map(
									(key: any) => (
										<div className="question-container">
											<div className="question-section">
												<b>{key}</b>
											</div>
											<div className="question-text">
												{selectedQns[key]}
											</div>
										</div>
									)
								)}
							</div>
						</div>
					)}
				<iframe
					src={url}
					width={1000}
					height={600}
				/>
			</Dialog>
		</div>
	);
}

export default FormModal;
