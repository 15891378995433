import {
	apiFailureErrorMessage,
	HttpService,
} from '../utils/httpService';
import { ENDPOINTS } from '../config/endpoints/stage';

export class VariablesService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async fetchAllVariables() {
		const url = `${this.endpoint}/variables/get`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
