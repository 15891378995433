import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { Input } from '@mui/material';
import { Button } from '@mui/material';
import { services } from '../apis';
import { updateLeetcodeUserName } from '../redux/user/userSlice';
import { notifyError, notifyInfo } from './notification';

function StudentLeetcodeProfileNamePopUp() {
	const [open, setOpen] = useState(true);
	const [leetcodeUserName, setLeetcodeUserName] =
		useState('');
	const [showMessage, setShowMessage] = useState(false);

	const handleClose = () => setOpen(false);

	const handleSubmit = async () => {
		if (leetcodeUserName.length) {
			try {
				const data =
					await services.userService.saveLeetcodeUserName({
						leetcodeUsername: leetcodeUserName,
					});
				notifyInfo(data.message);
				if (data.statusCode !== 400) {
					updateLeetcodeUserName(leetcodeUserName);
					setOpen(false);
				}
			} catch (error) {
				console.log(error);
			}
		} else {
			notifyError('Please enter a valid input');
		}
	};
	return (
		<Dialog
			open={open}
			onClose={handleClose}
		>
			<DialogTitle>
				<b>Please Submit Your Leetcode Username</b>
			</DialogTitle>

			<div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'space-between',
					}}
				>
					<h4>Leetcode Username &nbsp; </h4>
					<Input
						onChange={(e) =>
							setLeetcodeUserName(e.target.value)
						}
						style={{ textAlign: 'center' }}
					/>
				</div>
			</div>
			<div style={{ marginTop: '20px' }}>
				<Button
					style={{ width: '100%' }}
					variant="contained"
					onClick={handleSubmit}
				>
					Submit
				</Button>
			</div>
		</Dialog>
	);
}

export default StudentLeetcodeProfileNamePopUp;
