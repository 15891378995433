import { FC, useEffect, useState } from 'react';
import AliceCarousel, {
	EventObject,
} from 'react-alice-carousel';
import PastSessionsCard from './PastSessionsCard';
import chevron_right from '../../assets/softSkillsNew/chevron_right.svg';
import chevron_left from '../../assets/softSkillsNew/chevron_left.svg';
import arrow_down from '../../assets/softSkillsNew/arrow_down.svg';
import { VideoContentType } from '../../redux/softskills/softskills.type';

const responsive = {
	0: { items: 1 },
	568: { items: 3 },
	1024: { items: 4 },
};

const VideoGallery: FC<{
	videoContent: Array<VideoContentType>;
	setSelectedVideo: any;
}> = ({ videoContent, setSelectedVideo }) => {
	const [slides, setSlides] = useState<JSX.Element[]>([]);

	useEffect(() => {
		const items = videoContent.map(
			(videoDetails, index) => (
				<PastSessionsCard
					key={index}
					videoDetails={videoDetails}
					setSelectedVideo={setSelectedVideo}
				/>
			)
		);
		for (
			let i = items.length;
			i < responsive[1024].items;
			i++
		) {
			items.push(items[i]);
		}
		setSlides(items);
		if (videoContent.length > 1) {
			setShowVideosSlider(true);
		}
	}, [videoContent]);

	const [isNextSlideDisabled, setIsNextSlideDisabled] =
		useState(false);
	const [isPrevSlideDisabled, setIsPrevSlideDisabled] =
		useState(false);
	const [showVideosSlider, setShowVideosSlider] =
		useState(false);

	const handleSlideChanged = (event: EventObject) => {
		if (event.isNextSlideDisabled)
			setIsNextSlideDisabled(true);
		else if (event.isPrevSlideDisabled)
			setIsPrevSlideDisabled(true);
		else {
			setIsNextSlideDisabled(false);
			setIsPrevSlideDisabled(false);
		}
	};

	return (
		<div className="video-gallery-container">
			<div className="video-gallery-header">
				<p className="video-gallery-text">VIDEO GALLERY</p>
				<img
					src={arrow_down}
					className="arrowdown-icon"
					onClick={() => {
						setShowVideosSlider(!showVideosSlider);
					}}
					style={{
						rotate: showVideosSlider ? '180deg' : '0deg',
					}}
				/>
			</div>
			{showVideosSlider ? (
				<div className="video-gallery-body">
					<div className="carousel-container">
						<div className="past-sessions">
							<div className="past-sessions-cards">
								<AliceCarousel
									// mouseTracking={true}
									items={slides}
									responsive={responsive}
									autoPlay={false}
									infinite={false}
									disableDotsControls={true}
									onSlideChanged={handleSlideChanged}
									renderPrevButton={() =>
										isPrevSlideDisabled ? null : (
											<div
												className={`alice-left-button ${
													isPrevSlideDisabled
														? 'disabled'
														: ''
												}`}
											>
												<img src={chevron_left} />
											</div>
										)
									}
									renderNextButton={() =>
										isNextSlideDisabled ? null : (
											<div
												className={`alice-right-button ${
													isNextSlideDisabled
														? 'disabled'
														: ''
												}`}
											>
												<img src={chevron_right} />
											</div>
										)
									}
								/>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default VideoGallery;
