import { Loader } from '@acciojob/loader';
import { Crisp } from 'crisp-sdk-web';
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { services } from '../apis';
import { AppDispatch } from '../redux/store';
import { markIsCrispConfigured } from '../redux/user/userSlice';
import AccioButton from './elements/Button';
import ChatFeedbackPopUp from './home/Popups/ChatFeedbackModal';

const FullScreenChat = () => {
	const dispatch = useDispatch<AppDispatch>();

	const userState = useSelector((state: any) => state.user);
	const { user, isCrispConfigured } = userState;

	const courseState = useSelector(
		(state: any) => state.course
	);
	const { course } = courseState;

	const [isChatStarted, setIsChatStarted] = useState(false);

	const [showChatFeedback, setShowChatFeedback] =
		useState<boolean>(false);
	const [lastCrispSessionId, setLastCrispSessionId] =
		useState<string>('');

	const handleCloseChatFeedback = () =>
		setShowChatFeedback(false);

	const crispApplicableUnitsMainCourse: string[] = [
		'8be25059-88a9-4c8f-ae30-d16b0118c58f',
		'53ee8838-8559-4225-a8d8-0372347cdd72',
		'13ba8c23-d78f-4976-8839-a9a67bff9a39',
		'40fbe173-edfb-4d33-aaf3-8c916d3cba91',
		'5a6a2159-0dc8-4790-a261-6e35e49d1703',
		'c55cd845-166c-4f76-be2f-2bef95cc18d3',
		'0e1e7302-f2c0-43c3-8703-f5c65d935aff',
		'83c7d1e1-4329-43bc-ae32-0462c1ddae6a',
		'797d813d-1114-45b0-8014-e5c5761daf09',
		'5a938cfb-ce7f-4bb5-8ab2-43064260218a',
		'7f2b48d1-49fd-4855-a01f-3996d8e7d76f',
		'ab198d3d-bf49-4a41-a1d3-c6e17b43b1c0',
		'548a11a8-7742-4a83-9f38-b506924e0d2b',
		'8ea576d4-e5bf-4fda-a8e0-221ef99ba6fd',
		'2055403b-0d25-4424-bbaa-778c32f69127',
		'aaa341c0-e341-4914-9368-547c94dadff6',
		'7eab616f-2742-41a3-8c99-3e4617cd16f9',
		'4ce9bf82-494a-4ae9-8a47-cdf9d26a0d31',
		'7da58c7a-75d2-4918-a61a-79698d66fdf8',
		'9b802b3e-8200-402c-8245-903b8f3cc02c',
		'94227276-c508-4fef-b19c-e2445b7d51e0',
		'1217c0f8-84a0-466e-a7af-cc1809f07549',
		'3c5b4e0e-a9c1-4d67-9b00-aad71a6c5468',
		'b3f05b26-ff55-4152-bb96-6e60e941cba4',
		'e22dde31-17ca-416d-ba53-d4155b367c96',
		'7eab616f-2742-41a3-8c99-3e4617cd16f9',
		'79c16445-f8d6-4403-91cf-e5f5b7d76c3c',
		'59218712-6f84-4e6c-86e4-7f09e9058751',
		'1749f778-eb54-4fdf-9112-1fa4340ce3e4',
		'529ff028-bdb7-47a4-a84a-6d531a7e3ab0',
		'8c86648a-1a38-446f-9137-7db0e2e102ff',
		'8ca8dcdc-5955-4cf2-b083-2bbf404db6bc',
		'ca36bcdf-8caf-4208-9af5-e5b43a3b72ba',
	];

	const crispApplicableUnitsDataCourse: string[] = [
		'387026ef-5cf8-47c9-b576-1b43293a3ab0',
		'8be25059-88a9-4c8f-ae30-d16b0118c58f',
		'bd928ee4-3223-48bd-8dbd-07bbb7649cfe',
		'45f84549-69cd-4688-9692-ee977c1fe76b',
		'0e23e0f1-8ed5-4ce4-b007-12e20e3f807d',
		'53ee8838-8559-4225-a8d8-0372347cdd72',
		'13ba8c23-d78f-4976-8839-a9a67bff9a39',
		'8e720076-ddb9-4a8f-ae38-7b6f92723c5b',
		'40fbe173-edfb-4d33-aaf3-8c916d3cba91',
		'5a6a2159-0dc8-4790-a261-6e35e49d1703',
		'c55cd845-166c-4f76-be2f-2bef95cc18d3',
		'0e1e7302-f2c0-43c3-8703-f5c65d935aff',
		'83c7d1e1-4329-43bc-ae32-0462c1ddae6a',
		'797d813d-1114-45b0-8014-e5c5761daf09',
		'5a938cfb-ce7f-4bb5-8ab2-43064260218a',
		'7f2b48d1-49fd-4855-a01f-3996d8e7d76f',
		'ab198d3d-bf49-4a41-a1d3-c6e17b43b1c0',
		'548a11a8-7742-4a83-9f38-b506924e0d2b',
		'8ea576d4-e5bf-4fda-a8e0-221ef99ba6fd',
		'2055403b-0d25-4424-bbaa-778c32f69127',
		'aaa341c0-e341-4914-9368-547c94dadff6',
		'7eab616f-2742-41a3-8c99-3e4617cd16f9',
		'4ce9bf82-494a-4ae9-8a47-cdf9d26a0d31',
		'7da58c7a-75d2-4918-a61a-79698d66fdf8',
		'9b802b3e-8200-402c-8245-903b8f3cc02c',
		'94227276-c508-4fef-b19c-e2445b7d51e0',
		'1217c0f8-84a0-466e-a7af-cc1809f07549',
		'3c5b4e0e-a9c1-4d67-9b00-aad71a6c5468',
		'b3f05b26-ff55-4152-bb96-6e60e941cba4',
		'e22dde31-17ca-416d-ba53-d4155b367c96',
		'7eab616f-2742-41a3-8c99-3e4617cd16f9',
		'79c16445-f8d6-4403-91cf-e5f5b7d76c3c',
		'59218712-6f84-4e6c-86e4-7f09e9058751',
		'1749f778-eb54-4fdf-9112-1fa4340ce3e4',
		'529ff028-bdb7-47a4-a84a-6d531a7e3ab0',
		'8c86648a-1a38-446f-9137-7db0e2e102ff',
	];

	const liveDoubtInapplicableUnits: string[] = [
		'7da58c7a-75d2-4918-a61a-79698d66fdf8',
		'5a6a2159-0dc8-4790-a261-6e35e49d1703',
		'aaa341c0-e341-4914-9368-547c94dadff6',
		'c55cd845-166c-4f76-be2f-2bef95cc18d3',
		'7f2b48d1-49fd-4855-a01f-3996d8e7d76f',
	];

	// const timedAccess = (unitId: string) => {
	// 	const available = [
	// 		'ca36bcdf-8caf-4208-9af5-e5b43a3b72ba',
	// 		'8ca8dcdc-5955-4cf2-b083-2bbf404db6bc',
	// 	].includes(unitId);
	// 	const curr = moment();
	// 	if (available) {
	// 		if (curr.day() === 0) return false;
	// 		else {
	// 			const ch = curr.hour();
	// 			if (ch === 23) return true;
	// 			else if (ch >= 17 && ch < 19) return true;
	// 			else return false;
	// 		}
	// 	} else return true;
	// };

	const resetChatIfSessionExpired = () => {
		const lastCrispSessionStartTime = localStorage.getItem(
			'lastCrispSessionStartTime'
		);
		const lastCrispTimeoutId = localStorage.getItem(
			'lastCrispTimeoutId'
		);

		const currentTime = new Date().getTime();
		const sessionTimeout = 45 * 60 * 1000;

		if (
			lastCrispSessionStartTime &&
			lastCrispTimeoutId &&
			currentTime >
				parseInt(lastCrispSessionStartTime) + sessionTimeout
		) {
			resetCrispSession();
			localStorage.setItem(
				'lastCrispSessionStartTime',
				currentTime.toString()
			);
			localStorage.setItem('lastCrispTimeoutId', '');
		}
	};

	const updateCrispSessionStartTime = () => {
		const lastCrispTimeoutId = localStorage.getItem(
			'lastCrispTimeoutId'
		);
		const currentTime = new Date().getTime();
		const sessionTimeout = 45 * 60 * 1000;

		// Set lastCrispSessionStartTime to current time to extend chat duration for 45 more minutes
		localStorage.setItem(
			'lastCrispSessionStartTime',
			currentTime.toString()
		);

		if (lastCrispTimeoutId) {
			clearTimeout(Number(lastCrispTimeoutId));
			localStorage.setItem('lastCrispTimeoutId', '');
		}

		const newTimeoutId = setTimeout(() => {
			resetCrispSession();
		}, sessionTimeout);

		localStorage.setItem(
			'lastCrispTimeoutId',
			String(newTimeoutId)
		);
	};

	const updateSessionTimeOut = () => {
		resetChatIfSessionExpired();
		updateCrispSessionStartTime();
	};

	const setSessionDataForCrispMainCourse = () => {
		window.$crisp.push(['set', 'user:email', user.email]);

		window.$crisp.push([
			'set',
			'user:nickname',
			[user.firstName + ' ' + user.lastName],
		]);
		window.$crisp.push([
			'set',
			'session:data',
			[
				[
					['course_name', course.title],
					['unit_name', course.unit.unitname],
					['unit_id', course.unit.id],
					['course_id', course.id],
					['user_id', user.id],
					['user_role', user.roles[0]],
				],
			],
		]);

		$crisp.push([
			'on',
			'message:sent',
			async (e: any) => {
				if (e.from === 'user') {
					updateSessionTimeOut();
					setLastCrispSessionId(
						$crisp.get('session:identifier')
					);
				}
			},
		]);
		window.$crisp.push(['do', 'chat:show']);
		window.$crisp.push([
			'on',
			'message:received',
			(e: any) => {
				if (
					e.from === 'operator' &&
					e.origin === 'network' &&
					e.content ===
						"This conversation is marked as 'resolved' by the AccioJob mentor"
				) {
					setTimeout(() => {
						setShowChatFeedback(true);
						resetCrispSession();
					}, 1000 * 5);
				}
			},
		]);
		window.$crisp.push([
			'on',
			'session:loaded',
			async () => {
				const sessionIdentifier = $crisp.get(
					'session:identifier'
				);
				if (sessionIdentifier) {
					await services.userService.crispChatData(
						sessionIdentifier,
						user.id
					);
				} else {
					console.error(
						'Error retrieving session identifier'
					);
				}
			},
		]);
	};

	const resetCrispSession = () => {
		$crisp.push(['do', 'session:ends']);
		setSessionDataForCrispMainCourse();
	};

	const configureMainCourseChat = async () => {
		Crisp.configure('02399c2f-1baa-4a49-a969-fd2094009d34');
		setSessionDataForCrispMainCourse();
	};

	const configureDataCourseChat = () => {
		Crisp.configure('2b3da53c-1822-472f-aa66-bf429d4d32cd');
		window.$crisp.push(['set', 'user:email', user.email]);
		window.$crisp.push([
			'set',
			'user:nickname',
			[user.firstName + ' ' + user.lastName],
		]);
		window.$crisp.push([
			'set',
			'session:data',
			[
				[
					['course_name', course.title],
					['unit_name', course.unit.unitname],
					['unit_id', course.unit.id],
					['course_id', course.id],
					['user_id', user.id],
					['user_role', user.roles[0]],
				],
			],
		]);
		window.$crisp.push(['do', 'chat:show']);
	};

	const fullScreenChat = () => {
		window.CRISP_RUNTIME_CONFIG = {
			lock_maximized: true,
			lock_full_view: true,
			cross_origin_cookies: true,
		};
		dispatch(markIsCrispConfigured(true));
	};

	useEffect(() => {
		if (isCrispConfigured) {
			resetChatIfSessionExpired();
		}
	}, [isCrispConfigured]);

	useEffect(() => {
		try {
			if (
				course != null &&
				course.title != null &&
				user != null &&
				course.unit != null &&
				isChatStarted
			) {
				if (user.roles[0] === 'data-maincourse-user') {
					configureDataCourseChat();
					fullScreenChat();
				} else {
					configureMainCourseChat();
					fullScreenChat();
				}
			}
		} catch (err) {
			console.error('crisping err', err);
		}
	}, [course, user, isChatStarted]);

	const clearLocalStorageAndCookies = (): void => {
		const token =
			localStorage.getItem('acciojobs-token') ??
			Cookies.get('acciojobs-token') ??
			'';
		localStorage.clear();
		localStorage.setItem('acciojobs-token', token);

		const cookies = document.cookie.split('; ');
		for (const cookie of cookies) {
			const [name] = cookie.split('=');

			if (name !== 'acciojobs-token') {
				document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
			}
		}

		window.location.reload();
	};

	return (
		<React.Fragment>
			<div className="full-screen-chat-loader">
				{isChatStarted ? (
					<>
						<Loader />
						<p className="loading-message">
							Please wait for a while.
							<p>If this page keeps loading</p>
						</p>
						<AccioButton
							title="Click Here"
							action={clearLocalStorageAndCookies}
						/>
					</>
				) : (
					<>
						<p
							style={{ width: '70%', textAlign: 'center' }}
						>
							👋 Welcome to our chat!
							<br />
							🚀 Just click the button below to start your
							conversation
						</p>
						<AccioButton
							title="Start Chat"
							action={() => setIsChatStarted(true)}
						/>
					</>
				)}
			</div>
			<ChatFeedbackPopUp
				open={showChatFeedback}
				sessionId={lastCrispSessionId}
				handleCloseChatFeedback={handleCloseChatFeedback}
			/>
		</React.Fragment>
	);
};

export default FullScreenChat;
