import EffortGuage from '../EffortGuage';

const ScoreBlock = ({
	qcScore,
	setDisplayScoreModal,
}: any) => {
	return (
		<div className="score-block">
			<div className="score-left">
				<p className="score-title">
					<strong>Student Satisfaction Score</strong>
				</p>

				{qcScore && (
					<>
						<EffortGuage
							score={
								parseInt(
									qcScore.currenCycleResult.averageQC
								) * 10
							}
						/>
						<p className="score-secured">
							{qcScore.currenCycleResult.averageQC}
							<span>/10</span>
						</p>
					</>
				)}
				<div className="view-score-details">
					<button
						className="btn-blue score-btn"
						onClick={() => setDisplayScoreModal(true)}
					>
						View Details
					</button>
				</div>
			</div>
		</div>
	);
};

export default ScoreBlock;
