import {
	ChevronLeft,
	ChevronRight,
} from '@mui/icons-material';
import { useRef } from 'react';
import { AchievementHorizontalCard } from './AchievementHorizontalCard';

interface AchievementDataInterface {
	data: {
		heading: string;
		desc: string;
		level: number;
		streak: number;
		total: Array<number>;
		message: Array<string>;
	};
}
export const AchievementsRow = ({
	data,
}: AchievementDataInterface) => {
	const scrollContainerRef = useRef<HTMLDivElement>(null);

	const handleScrollLeft = () => {
		const container = scrollContainerRef.current;
		if (container) {
			container.scrollLeft -= 330; // Scrolls by the width of one card
		}
	};

	const handleScrollRight = () => {
		const container = scrollContainerRef.current;
		if (container) {
			container.scrollLeft += 330; // Scrolls by the width of one card
		}
	};
	return (
		<div className="section">
			<p className="heading">{data.heading}</p>
			<p className="desc">{data.desc ?? ''}</p>
			<div className="achievement-hr"></div>
			<div className="scroll-wrapper">
				<button
					onClick={handleScrollLeft}
					className="scroll-button left"
				>
					<ChevronLeft
						style={{
							fontSize: '28px',
							color: '#2F80ED',
						}}
					/>
				</button>
				<div
					className="achievements-flex"
					ref={scrollContainerRef}
				>
					{[1, 2, 3, 4, 5].map((item, i) => (
						<AchievementHorizontalCard
							level={item}
							myLevel={data.level}
							streak={data.streak}
							total={data.total}
							message={data.message}
							key={i}
						/>
					))}
				</div>
				<button
					onClick={handleScrollRight}
					className="scroll-button right"
				>
					<ChevronRight
						style={{
							fontSize: '28px',
							color: '#2F80ED',
						}}
					/>
				</button>
			</div>
		</div>
	);
};
