import React from 'react';
import pagenotfound from '../../assets/lottie/pagenotfound.json';
import Lottie from 'react-lottie-player';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
	const navigate = useNavigate();
	return (
		<div className={'page-not-found'}>
			<h1>OOPS, looks like you picked some wrong route</h1>
			<Lottie
				className={'page-not-found-lottie'}
				loop
				animationData={pagenotfound}
				play
			/>
			<h1>Let&apos;s go back to home together</h1>
			<button
				onClick={() => {
					navigate('/');
				}}
			>
				Home
			</button>
		</div>
	);
};

export default PageNotFound;
