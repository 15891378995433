import { FC } from 'react';
import arrow_inclined from '../../assets/softSkillsNew/arrow_inclined.svg';
import { ReadingContentType } from '../../redux/softskills/softskills.type';

const ResourseLinkItems: FC<{
	resourceLinks: Array<ReadingContentType>;
}> = ({ resourceLinks }) => {
	return (
		<div className="resourse-links-container">
			{resourceLinks.map(({ title, link }, index) => (
				<div
					className="resourse-link-item"
					key={index}
				>
					<div className="resourse-link-item-left">
						RESOURCE LINK {index + 1}
					</div>
					<div className="resourse-link-item-right">
						<div className="resource-topic">{title}</div>
						<div
							className="resource-link"
							onClick={() => {
								window.open(link, '_blank');
							}}
						>
							Go to link
							<img src={arrow_inclined} />
						</div>
					</div>
				</div>
			))}
		</div>
	);
};

export default ResourseLinkItems;
