import React from 'react';
import Banner from './Banner';
import MarkDown from './MarkDown';
import { useState, useEffect } from 'react';
import { fetchQuestions } from '../../redux/contest/contestSlice';
import { useSelector, useDispatch } from 'react-redux';
import { FiArrowRight, FiCalendar } from 'react-icons/fi';
import queryString from 'query-string';
import moment from 'moment';
import { useLocation } from 'react-router-dom';

interface ContestProps {
	contestData: any;
}

const UpcomingContest = ({ contestData }: ContestProps) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const contestId = queryString.parse(
		location.search
	).contestId;

	const contestInfoState = useSelector(
		(state: any) => state.contest
	);

	const { codingQuestions } = contestInfoState;

	return contestData !== null ? (
		<div>
			<Banner
				msg={{
					head: 'Contest Coming Soon!',
					para: 'Start preparing and get ready to solve the questions.',
				}}
				duration={contestData.duration}
				startTime={contestData.startTime}
				endTime={contestData.endTime}
				active={false}
			/>
			<div className="contestTime">
				<FiCalendar />
				<p>
					<h5>Begin : </h5>

					<span>
						{' '}
						{moment(contestData.startTime).format(
							'DD MMM hh:mm A'
						)}
					</span>
				</p>
				<p>
					<h5>End : </h5>
					<span>
						{' '}
						{moment(contestData.endTime).format(
							'DD MMM hh:mm A'
						)}
					</span>
				</p>
			</div>
			<div className="instructions">
				<h2>Instructions</h2>
				<MarkDown statement={contestData.instructions} />
			</div>
		</div>
	) : null;
};

export default UpcomingContest;
