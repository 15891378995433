import { useEffect, useState } from 'react';

interface SessionTabAdditionDataProps {
	TabAdditionalData: string[];
	setSelectedFilter: (filter: string) => void;
	selectedFilter: string;
}

const classNames: Record<string, string> = {
	DSA: 'DSA',
	Others: 'Others',
	Backend: 'Backend',
	Fundamentals: 'Fundamentals',
	Excel: 'Excel',
	Frontend: 'Frontend',
	'Problem Solving': 'Problem-Solving',
	Capstone: 'Capstone',
	SQL: 'SQL',
	'Power BI': 'Power-BI',
	'Learning Mentorship': 'Learning-Mentorship',
	'Project Mentorship': 'Project-Mentorship',
	'Learning Mocks': 'Learning-Mocks',
	'Placement Mocks': 'Placement-Mocks',
	'HR Expert Sessions': 'HR-Expert-Sessions',
	'BM Session': 'BM-Session',
};

const SessionTabAdditionData = ({
	TabAdditionalData,
	setSelectedFilter,
	selectedFilter,
}: SessionTabAdditionDataProps): JSX.Element | null => {
	const [selectedFilterState, setSelectedFilterState] =
		useState('');

	const handleFilterClick = (data: string) => {
		const newFilter =
			selectedFilterState === data ? 'ALL' : data;
		setSelectedFilterState(newFilter);
		setSelectedFilter(newFilter);
	};

	useEffect(() => {
		setSelectedFilterState(selectedFilter);
	}, [selectedFilter]);

	console.log('TabAdditionalData', TabAdditionalData);

	return TabAdditionalData ? (
		<div className="session-tab-addition-data-wrapper">
			{TabAdditionalData.map(
				(data: string, index: number) => (
					<span
						key={index}
						className={`filter-tab session-tab-addition-data-${
							classNames[data]
						} ${
							selectedFilterState === data ? 'selected' : ''
						}`}
						onClick={() => handleFilterClick(data)}
					>
						{data}
					</span>
				)
			)}
		</div>
	) : null;
};

export default SessionTabAdditionData;
