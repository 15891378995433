import { useEffect, useState } from 'react';
import { TextField as MaterialTextField } from '@mui/material';
import { FormFieldProps } from '../types';
import {
	GeneralProfileFormFields,
	PlacementProfileFormFields,
} from '../student_personal_details';
import { useSelector } from 'react-redux';
import {
	MentorExperienceFormFields,
	MentorProfile,
} from '../mentor/mentorTypes';
import CollegeField from './CollegeField';
import { PopupTypes } from '../../../redux/popups/popup.type';

const MyTextField = ({
	id,
	formData,
	updateFormData,
	isProfileLocked,
	...args
}: FormFieldProps) => {
	// console.log('Props', id, args.label, args.type, args);
	args = {
		...args,
		disabled: args.disabled || isProfileLocked,
	};

	const { profile } = useSelector(
		(state: any) => state.user
	);
	const [expNotRequired, setExpNotRequired] =
		useState(false);

	console.log('testing123', formData);
	console.log('testing123 aa', updateFormData);

	// useEffect(() => {
	// 	if (profile.jobStatus !== 'Working_Professional') {
	// 		if (
	// 			id === 'workExperienceMonths' ||
	// 			id === 'currentCompany' ||
	// 			id === 'currentCtc' ||
	// 			id === 'noticePeriod'
	// 		) {
	// 			setExpNotRequired(true);
	// 		}
	// 	}
	// }, [profile]);

	return (
		<div className="form-item">
			{id === 'collegeName' ? (
				<>
					<CollegeField
						formData={formData}
						id={id}
						type={PopupTypes.VERIFY_UG_COLLEGE}
					/>
				</>
			) : id === 'pgCollegeName' ? (
				<>
					<CollegeField
						formData={formData}
						id={id}
						type={PopupTypes.VERIFY_PG_COLLEGE}
					/>
				</>
			) : (
				<MaterialTextField
					required={
						expNotRequired
							? false
							: args.required === false
							? false
							: true
					}
					disabled={args.disabled}
					type={args.type}
					id="outlined-basic"
					label={args.label}
					variant="outlined"
					style={{ width: '100%' }}
					name={id}
					InputProps={{
						inputProps: { ...args },
						style: {
							color: '#000',
							fontSize: '0.9rem',
							fontWeight: 400,
							fontFamily: "'Inter', sans-serif",
						},
					}}
					InputLabelProps={{
						style: {
							color: 'rgba(0, 0, 0, 0.75)',
							fontSize: '0.9rem',
							fontWeight: 400,
							fontFamily: "'Inter', sans-serif",
						},
					}}
					value={
						formData[
							id as keyof (
								| GeneralProfileFormFields
								| PlacementProfileFormFields
								| MentorExperienceFormFields
								| MentorProfile
							)
						]
					}
					onChange={(e) =>
						updateFormData(e.target.name, e.target.value, e)
					}
				/>
			)}
		</div>
	);
};

export default MyTextField;
