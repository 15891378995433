import { Avatar, Badge } from '@mui/joy';
import React, { ChangeEvent, useState } from 'react';
import { AiFillEdit } from 'react-icons/ai';
import { toast } from 'react-toastify';
import { services } from '../../../../apis';
import { profileActions } from '../../../../redux/profile/profile.slice';
import {
	useAppDispatch,
	useAppSelector,
} from '../../../../redux/store';
import {
	PersonalInfoForm,
	SectionProps,
} from './Forms/PersonalInfoForm';

export const PersonalDetails: React.FC = () => {
	const dispatch = useAppDispatch();
	const [imageSrc, setImageSrc] = useState<string>('');
	const [uploading, setUploading] =
		useState<boolean>(false);
	const { personalDetails } = useAppSelector(
		(state) => state.profile
	);
	const isProfileLocked = () => {
		return personalDetails.data?.isProfileLocked ?? false;
	};

	const handleImageChange = (
		e: ChangeEvent<HTMLInputElement>
	) => {
		const file = e.target.files?.length
			? e.target.files[0]
			: null;
		console.log({ file });
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (e.target) {
					setImageSrc(e.target.result as string);
				}
			};
			reader.readAsDataURL(file);
			uploadImage(file);
		}
	};

	const getNumber = (number: string) => {
		if (number.length > 10) {
			return number;
		} else {
			// return '+91' + number.toString();
			return number.toString();
		}
	};

	const uploadImage = async (file: File) => {
		try {
			setUploading(true);
			const data =
				await services.userService.uploadProfilePicture(
					file
				);
			dispatch(
				profileActions.updateProfilePhoto(data.publicUrl)
			);
			setUploading(false);
			toast.success('Image uploaded successfully');
		} catch (error) {
			setUploading(false);
			console.log(error);
			toast.error('Image Could Not Be Uploaded!');
		}
	};

	const infoArr: SectionProps[] = [
		{
			title: 'Personal Info',
			inputs: [
				{
					value: personalDetails.data?.firstName ?? '',
					name: 'firstName',
					label: 'First Name',
					type: 'text',
					placeholder: 'Enter First Name',
					required: true,
				},
				{
					value: personalDetails.data?.lastName ?? '',
					name: 'lastName',
					label: 'Last Name',
					type: 'text',
					placeholder: 'Enter Last Name',
					required: true,
				},
				{
					value: personalDetails.data?.currentState ?? '',
					name: 'currentState',
					label: 'Current State',
					type: 'select',
					options: [
						{
							value: 'Andhra Pradesh',
							label: 'Andhra Pradesh',
						},
						{
							value: 'Arunachal Pradesh',
							label: 'Arunachal Pradesh',
						},
						{ value: 'Assam', label: 'Assam' },
						{ value: 'Bihar', label: 'Bihar' },
						{
							value: 'Chhattisgarh',
							label: 'Chhattisgarh',
						},
						{ value: 'Goa', label: 'Goa' },
						{ value: 'Gujarat', label: 'Gujarat' },
						{ value: 'Haryana', label: 'Haryana' },
						{
							value: 'Himachal Pradesh',
							label: 'Himachal Pradesh',
						},
						{ value: 'Jharkhand', label: 'Jharkhand' },
						{ value: 'Karnataka', label: 'Karnataka' },
						{ value: 'Kerala', label: 'Kerala' },
						{
							value: 'Madhya Pradesh',
							label: 'Madhya Pradesh',
						},
						{ value: 'Maharashtra', label: 'Maharashtra' },
						{ value: 'Manipur', label: 'Manipur' },
						{ value: 'Meghalaya', label: 'Meghalaya' },
						{ value: 'Mizoram', label: 'Mizoram' },
						{ value: 'Nagaland', label: 'Nagaland' },
						{ value: 'Odisha', label: 'Odisha' },
						{ value: 'Punjab', label: 'Punjab' },
						{ value: 'Rajasthan', label: 'Rajasthan' },
						{ value: 'Sikkim', label: 'Sikkim' },
						{ value: 'Tamil Nadu', label: 'Tamil Nadu' },
						{ value: 'Telangana', label: 'Telangana' },
						{ value: 'Tripura', label: 'Tripura' },
						{
							value: 'Uttar Pradesh',
							label: 'Uttar Pradesh',
						},
						{ value: 'Uttarakhand', label: 'Uttarakhand' },
						{ value: 'West Bengal', label: 'West Bengal' },
						{
							value: 'Andaman and Nicobar Islands',
							label: 'Andaman and Nicobar Islands',
						},
						{ value: 'Chandigarh', label: 'Chandigarh' },
						{
							value: 'Dadra & Nagar Haveli and Daman & Diu',
							label: 'Dadra & Nagar Haveli and Daman & Diu',
						},
						{ value: 'Delhi', label: 'Delhi' },
						{
							value: 'Jammu and Kashmir',
							label: 'Jammu and Kashmir',
						},
						{ value: 'Lakshadweep', label: 'Lakshadweep' },
						{ value: 'Puducherry', label: 'Puducherry' },
						{ value: 'Ladakh', label: 'Ladakh' },
						{ value: 'Other', label: 'Other' },
					],
					placeholder: 'Select State',
					required: true,
				},
				{
					value: personalDetails.data?.currentCity ?? '',
					name: 'currentCity',
					label: 'Current District',
					type: 'hometown',
					placeholder: 'Select District',
					required: true,
					disabled: false,
				},
				{
					value: personalDetails.data?.phoneNumber
						? getNumber(personalDetails.data?.phoneNumber)
						: '',
					name: 'phoneNumber',
					label: 'Phone Number',
					type: 'text',
					placeholder: 'Enter Phone Number',
					required: true,
					disabled: true,
				},
				{
					value: personalDetails.data?.email ?? '',
					name: 'emailID',
					label: 'Email ID',
					type: 'email',
					placeholder: 'Enter Email ID',
					required: true,
					disabled: true,
				},
				{
					value: personalDetails.data?.whatsappNumber
						? getNumber(
								personalDetails.data?.whatsappNumber
						  )
						: '',
					name: 'whatsappNumber',
					label: 'Whatsapp Number',
					type: 'text',
					placeholder: 'Enter Whatsapp Number',
					required: true,
					maxLength: 10,
				},
				{
					value: personalDetails.data?.gender ?? '',
					name: 'gender',
					label: 'Gender',
					type: 'select',
					options: [
						{ value: 'Male', label: 'Male' },
						{ value: 'Female', label: 'Female' },
						{ value: 'Others', label: 'Others' },
					],
					placeholder: 'Select Gender',
					required: true,
				},
				{
					value:
						personalDetails.data
							?.preferredSpeakingLanguage ?? [],
					name: 'preferredSpeakingLanguage',
					label: 'Languages Spoken',
					type: 'multiSelect',
					options: [
						{ value: 'English', label: 'English' },
						{ value: 'Hindi', label: 'Hindi' },
						{ value: 'Bengali', label: 'Bengali' },
						{ value: 'Gujarati', label: 'Gujarati' },
						{ value: 'Kannada', label: 'Kannada' },
						{ value: 'Malayalam', label: 'Malayalam' },
						{ value: 'Marathi', label: 'Marathi' },
						{ value: 'Tamil', label: 'Tamil' },
						{ value: 'Telugu', label: 'Telugu' },
					],
					placeholder: 'Select Languages Spoken',
					required: true,
				},
				{
					value:
						personalDetails.data?.preferredCodingLanguage ??
						'',
					name: 'preferredCodingLanguage',
					label: 'Preferred Coding Language',
					type: 'select',
					options: [
						{ value: 'Java', label: 'Java' },
						{ value: 'Javascript', label: 'Javascript' },
						{ value: 'Python', label: 'Python' },
						{ value: 'C/C++', label: 'C/C++' },
					],
					placeholder: 'Select Preferred Coding',
					required: true,
				},
			],
		},
		{
			title: 'Parents Info',
			inputs: [
				{
					value: personalDetails.data?.fatherName ?? '',
					name: 'fatherName',
					label: 'Father Name',
					type: 'text',
					placeholder: 'Enter Father Name',
					required: true,
				},
				{
					value: personalDetails.data?.parentsContact
						? getNumber(
								personalDetails.data?.parentsContact
						  )
						: '',
					name: 'parentsContact',
					label: 'Parent Contact',
					type: 'text',
					placeholder: 'Enter Parent Contact',
					required: true,
					maxLength: 10,
				},
			],
		},
		{
			title: 'Skills',
			inputs: [
				{
					value:
						personalDetails.data?.otherCodingSkills ?? [],

					name: 'otherCodingSkills',
					label: 'You can select upto 5',
					type: 'multiSelect',
					options: [
						{ value: 'Spring', label: 'Spring' },
						{ value: 'Angular', label: 'Angular' },
						{
							value: 'Ruby on rails',
							label: 'Ruby on rails',
						},
						{ value: 'PHP', label: 'PHP' },
						{ value: '.NET', label: '.NET' },
						{ value: 'Django', label: 'Django' },
						{ value: 'Flask', label: 'Flask' },
						{ value: 'Springboot', label: 'Springboot' },
						{ value: 'Hibernate', label: 'Hibernate' },
						{
							value: 'Selenium/Testing',
							label: 'Selenium/Testing',
						},
						{ value: 'Salesforce', label: 'Salesforce' },
						{ value: 'Service Now', label: 'Service Now' },
						{ value: 'Node', label: 'Node' },
						{ value: 'React', label: 'React' },
						{ value: 'Vue', label: 'Vue' },
						{ value: 'Express', label: 'Express' },
						{ value: 'Mongo DB', label: 'Mongo DB' },
						{ value: 'SQL', label: 'SQL' },
						{ value: 'AWS', label: 'AWS' },
						{ value: 'HTML', label: 'HTML' },
						{ value: 'CSS', label: 'CSS' },
						{ value: 'Wordpress', label: 'Wordpress' },
						{ value: 'Basic DSA', label: 'Basic DSA' },
						{
							value: 'Intermediate DSA',
							label: 'Intermediate DSA',
						},
						{
							value: 'Advanced DSA',
							label: 'Advanced DSA',
						},
						{ value: 'OOPS', label: 'OOPS' },
						{ value: 'OS', label: 'OS' },
						{ value: 'Networking', label: 'Networking' },
						{ value: 'Java', label: 'Java' },
						{ value: 'C++', label: 'C++' },
						{ value: 'Python', label: 'Python' },
						{ value: 'Javascript', label: 'Javascript' },
					],
					placeholder: 'Select Skills',
					required: true,
				},
			],
		},
		{
			title: 'Profile Links',
			inputs: [
				{
					value: personalDetails.data?.linkedInUrl ?? '',
					name: 'linkedInUrl',
					label: 'Linkedin Link',
					type: 'text',
					placeholder:
						'https://www.linkedin.com/in/acciojob',
					required: true,
				},
				{
					value:
						personalDetails.data?.leetcodeUsername ?? '',
					name: 'leetcodeUsername',
					label: 'Leetcode Username',
					type: 'text',
					placeholder: 'acciojobCodes',
					required: true,
				},
				{
					value:
						personalDetails.data?.githubProfileLink ?? '',
					name: 'githubProfileLink',
					label: 'Github Link',
					type: 'text',
					placeholder: 'https://www.github.com/acciojob',
					required: true,
				},
			],
		},
	];

	return (
		<div className="personal-details-wrapper">
			<input
				type="file"
				accept="image/*"
				style={{ display: 'none' }}
				id="imageUpload"
				onChange={handleImageChange}
			/>
			<Badge
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				style={{ border: '0px solid #2F80ED' }}
				variant="solid"
				badgeContent={
					<label
						htmlFor={isProfileLocked() ? '' : 'imageUpload'}
					>
						<AiFillEdit
							style={{
								fontSize: '36px',
								padding: '0.5rem',
								borderRadius: '50%',
								backgroundColor: '#2F80ED',
								color: '#fff',
								border: '0px solid #2F80ED',
								cursor: 'pointer',
							}}
						/>
					</label>
				}
				badgeInset="14%"
				sx={{ '--Badge-paddingX': '0px' }}
			>
				<Avatar
					src={
						imageSrc.length > 0
							? imageSrc
							: personalDetails?.data?.profileImage
							? personalDetails?.data?.profileImage
							: ''
					}
					style={{ height: '128px', width: '128px' }}
				/>
			</Badge>
			<PersonalInfoForm
				sections={infoArr}
				type="personal"
			/>
		</div>
	);
};
