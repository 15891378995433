import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ContestListCard from './contestListCard';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Lottie from 'react-lottie-player';
import meditation from '../../assets/lottie/meditation.json';

interface ContestPanelProps {
	panelType: 'live' | 'upcoming' | 'past' | 'open';
	contestList: any[];
}

const ContestPanel = ({
	panelType,
	contestList,
}: ContestPanelProps) => {
	return (
		<Box
			className={'contest-panel'}
			key={panelType}
		>
			{contestList.map((el) => (
				<ContestListCard
					data={el}
					key={el.contestId}
					type={panelType}
				/>
			))}
			{contestList.length === 0 ? (
				<div className={'no-contest'}>
					<div className={'animation'}>
						<div className="lottie-animation">
							<Lottie
								loop
								animationData={meditation}
								play
							/>
						</div>
					</div>
					<h3>
						No
						<span
							style={{
								textTransform: 'capitalize',
							}}
						>
							{' '}
							{panelType}{' '}
						</span>
						Contest Available
					</h3>
				</div>
			) : null}
		</Box>
	);
};

export default ContestPanel;
