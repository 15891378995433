import { Button, ToggleButtonGroup } from '@mui/joy';
import {
	Radio,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import 'moment-timezone';
import { useState } from 'react';
import { services } from '../../apis';
import AccioButton from '../elements/Button';
import {
	notifyError,
	notifySuccess,
} from '../notification';

interface TimeSlot {
	label: string;
	startTime: string;
	endTime: string;
	dayLabel: string;
}

const TimeSlotsTable = ({ bmId }: { bmId: string }) => {
	const [selectedDay, setSelectedDay] = useState<
		string | null
	>('today');
	const [reason, setReason] = useState<string>('');
	const [open, setOpen] = useState<boolean>(false);
	const [selectedSlot, setSelectedSlot] =
		useState<TimeSlot | null>(null);

	const handleDayChange = (
		event: React.MouseEvent<HTMLElement>,
		newDay: string
	) => {
		if (newDay !== null) {
			setSelectedDay(newDay);
			setSelectedSlot(null); // Reset selected slot when day changes
		}
	};

	const createTimeSlots = (
		dayOffset: number
	): TimeSlot[] => {
		const slots: TimeSlot[] = [];
		const dateFormat = 'YYYY-MM-DD HH:mm:ss.SSS';
		const timezone = 'Asia/Kolkata'; // Set the timezone to IST

		const date = moment.tz(timezone).add(dayOffset, 'days');

		for (let hour = 11; hour < 20; hour++) {
			const start = date.clone().set({
				hour,
				minute: 0,
				second: 0,
				millisecond: 0,
			});
			const end = start.clone().add(1, 'hour');

			slots.push({
				label: `${start.format('hh:mm A')} - ${end.format(
					'hh:mm A'
				)}`,
				startTime: start.format(dateFormat),
				endTime: end.format(dateFormat),
				dayLabel: date.format('DD/MM/YYYY'),
			});
		}

		return slots;
	};

	const today = moment
		.tz('Asia/Kolkata')
		.format('DD/MM/YYYY');
	const tomorrow = moment
		.tz('Asia/Kolkata')
		.add(1, 'days')
		.format('DD/MM/YYYY');

	const timeSlots = createTimeSlots(
		selectedDay === 'today' ? 0 : 1
	);

	const handleSelectSlot = (slot: TimeSlot): void => {
		if (isSlotInPast(slot.startTime)) {
			notifyError(
				'Please select a slot which is in the future.'
			);
		} else {
			if (selectedSlot?.startTime === slot.startTime) {
				setSelectedSlot(null);
			} else {
				setSelectedSlot(slot);
			}
		}
	};

	const handleConfirmSlot = (): void => {
		if (selectedSlot) {
			setOpen(true);
		}
	};

	const submitForm = async () => {
		if (reason.trim().length > 0) {
			if (selectedSlot) {
				if (
					selectedSlot.endTime &&
					selectedSlot.startTime
				) {
					const data =
						await services.contactBMService.bookSlot({
							startTime: selectedSlot.startTime,
							endTime: selectedSlot.endTime,
							reason,
							bmId,
						});
					if (data.message) {
						notifySuccess('BM Session Booked!');
						setOpen(false);
						setSelectedSlot(null);
						setReason('');
					}
				} else {
					notifyError('Please select a slot!');
				}
			}
		} else {
			notifyError('Please state a reason!');
		}
	};

	const isSlotInPast = (startTime: string): boolean => {
		const now = moment.tz('Asia/Kolkata');
		const slotStartTime = moment(
			startTime,
			'YYYY-MM-DD HH:mm:ss.SSS'
		).tz('Asia/Kolkata');
		return slotStartTime.isBefore(now);
	};

	return (
		<>
			<ToggleButtonGroup
				value={selectedDay}
				onChange={(event, newValue) => {
					setSelectedDay(newValue);
					setSelectedSlot(null);
				}}
				sx={{ marginTop: '8px', marginLeft: '8px' }}
			>
				<Button value="today">Today</Button>
				<Button value="tomorrow">Tomorrow</Button>
			</ToggleButtonGroup>
			<TableContainer>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={{ width: '80px' }}>
								Select
							</TableCell>
							<TableCell>
								Time Slots For{' '}
								{selectedDay === 'today'
									? `Today (${today})`
									: `Tomorrow (${tomorrow})`}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{timeSlots.map((slot, index) => (
							<TableRow
								onClick={() => handleSelectSlot(slot)}
								key={index}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
									pointerEvents: isSlotInPast(
										slot.startTime
									)
										? 'none'
										: 'auto',
								}}
								selected={
									selectedSlot?.startTime === slot.startTime
								}
								hover
								style={{
									backgroundColor:
										selectedSlot?.startTime ===
										slot.startTime
											? '#f0f0f0'
											: '',
									cursor: 'pointer',
								}}
							>
								<TableCell
									sx={{ width: '80px' }}
									padding="checkbox"
								>
									<Radio
										checked={
											selectedSlot?.startTime ===
											slot.startTime
										}
										disabled={isSlotInPast(slot.startTime)}
										onChange={() => handleSelectSlot(slot)}
										value={slot.startTime}
									/>
								</TableCell>
								<TableCell
									sx={{
										color: isSlotInPast(slot.startTime)
											? 'text.disabled'
											: '',
									}}
									component="th"
									scope="row"
								>
									{slot.label}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell
								colSpan={2}
								style={{ textAlign: 'right' }}
							>
								<AccioButton
									variant={
										selectedSlot ? 'primary' : 'disable'
									}
									title="Request Callback"
									action={handleConfirmSlot}
									disabled={!selectedSlot}
								/>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			<Dialog
				open={open}
				onClose={() => setOpen(!open)}
				aria-labelledby="form-dialog-title"
				maxWidth="sm"
				fullWidth={true}
			>
				<DialogContent>
					<Typography
						id="form-dialog-title"
						variant="h5"
					>
						Why do you want to request a callback from your
						batch manager?
					</Typography>
					<TextField
						sx={{ margin: '16px 0px' }}
						placeholder="Please state your reason"
						autoFocus
						margin="dense"
						id="reason"
						type="text"
						fullWidth
						variant="outlined"
						value={reason}
						onChange={(event) =>
							setReason(event.target.value)
						}
					/>
					<AccioButton
						action={submitForm}
						variant="primary"
						title="Submit"
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

export default TimeSlotsTable;
