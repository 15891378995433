import React, { useEffect, useState } from 'react';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import remarkHTML from 'remark-html';
import ReactMarkdown from 'react-markdown';
import RenderMarkdown from './DisplayAsMarkDown';
import {
	IoIosRadioButtonOff,
	IoIosRadioButtonOn,
} from 'react-icons/io';

interface Props {
	statement: string;
	options: string[];
	save: any;
	response: string[];
	correct: string[];
	submitted: boolean;
	explanation: string;
}

const Mcq = (props: Props) => {
	const rm =
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ";
	const option =
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ";

	const [answer, setAnswer] = useState<string[]>([]);

	const saveAnswer = (option: string) => {
		const temp = [option];
		console.log('mcq temp', temp);
		setAnswer(temp);
		props.save(temp);
	};

	useEffect(() => {
		console.log('props response', props.response);
		setAnswer(props.response);
	}, [props.response]);

	console.log('answer array', answer);

	return (
		<div className={'mcq conceptual-question'}>
			<div className={'question'}>
				{RenderMarkdown(props.statement)}
			</div>
			<div className={'options'}>
				<ul>
					{props.options.map((el, index) => {
						return (
							<li
								key={index + el}
								onClick={() => {
									if (!props.submitted) {
										saveAnswer(el);
									}
								}}
								className={`${
									props.submitted
										? props.correct.includes(el)
											? 'correct'
											: answer.includes(el)
											? 'incorrect'
											: ''
										: ''
								}`}
							>
								<span>
									{answer.includes(el) ? (
										<IoIosRadioButtonOn />
									) : (
										<IoIosRadioButtonOff />
									)}
								</span>
								<div className={'mcq-option'}>
									{RenderMarkdown(el)}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			{props.submitted ? (
				<div className={'correct-answer'}>
					<h1>Correct Answer</h1>
					<ul>
						{props.correct.map((el: any) => {
							return <li key={el}>{el}</li>;
						})}
					</ul>
					{props.explanation &&
					props.explanation.length > 1 ? (
						<h1>Explanation</h1>
					) : null}
					{props.explanation &&
					props.explanation.length > 1 ? (
						<p>{RenderMarkdown(props.explanation)}</p>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default Mcq;
