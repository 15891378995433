import { IoInformationCircleSharp } from 'react-icons/io5';
import { JobCardData } from '../../apis/JobPortalService';
import mapPin from '../../assets/images/icons/mapPin.svg';
import money from '../../assets/images/icons/money.svg';
import time from '../../assets/images/icons/time.svg';
import { PopupState } from '../../pages/jobportal/JobPortal';
import AccioButton from '../elements/Button';

interface JobCardProps {
	data: JobCardData;
	setJobDetailsPopup: (newState: PopupState) => void;
	setApplyPopup: (newState: PopupState) => void;
	setBlackListModalOpen: (newState: boolean) => void;
	placementFlag: boolean;
}

const JobCard = ({
	data,
	setJobDetailsPopup,
	setApplyPopup,
	setBlackListModalOpen,
	placementFlag,
}: JobCardProps) => {
	const {
		blacklisted,
		companyName,
		location,
		otherCodingSkills,
		ctcOffered,
		deadline,
		rolesOffered,
		selectionProcess,
		jobCardId,
		eligibility,
		isBondOffering,
		isInterested = true,
	} = data;

	const rolesOfferedString = rolesOffered.join(', ');
	const selectionProcessString =
		selectionProcess.join(' > ');
	const locationString = location.join(', ');

	const formatDate = (date: string) => {
		const newDate = new Date(date);
		const day = newDate.getDate();
		const month = newDate
			.toLocaleString('default', { month: 'long' })
			.substring(0, 3);
		const time = newDate.toLocaleTimeString([], {
			hour: '2-digit',
			minute: '2-digit',
		});

		return day + ' ' + month + ', ' + time;
	};

	return (
		<div className="job-card">
			<div className="bond-wrapper">
				{isBondOffering ? (
					<p className="bond-job-border">
						Bond required for this job. Apply if
						comfortable.
					</p>
				) : null}
			</div>
			<div className="header-area">
				<div className="company-overview">
					<div className="btns-flex">
						<h3>{companyName}</h3>
						<AccioButton
							action={() => {
								setJobDetailsPopup({
									isOpen: true,
									identifier: jobCardId + 'source:jobs',
									notInterested: false,
								});
							}}
							title="More details"
							variant={'primary'}
							icon={
								<IoInformationCircleSharp
									style={{ fontSize: '15px' }}
								/>
							}
							customClass={`job-card-btn details`}
							iconPosition={'left'}
						/>
					</div>
					{locationString && (
						<div>
							<p
								className={
									location.length > 1
										? 'custom-tooltip'
										: ''
								}
								data-tooltip={locationString}
							>
								<img src={mapPin} />
								{location.length > 1
									? location.length + ' Locations'
									: location[0]}
							</p>
							{ctcOffered && (
								<p className="ctc-offered">
									<img src={money} />
									{ctcOffered.toUpperCase()}
								</p>
							)}
						</div>
					)}
				</div>
			</div>

			<div className="body-area">
				{rolesOffered && (
					<div
						className={
							'positions ' +
							(rolesOfferedString.length > 45
								? 'custom-tooltip'
								: '')
						}
						data-tooltip={rolesOfferedString}
					>
						<h4>OPEN POSITIONS</h4>
						<p>{rolesOfferedString}</p>
					</div>
				)}
				{otherCodingSkills && (
					<div className="skills">
						<h4>Preferred skills(Good to have)</h4>
						<p>
							{otherCodingSkills.map((skill, idx) => (
								<span key={idx}>{skill}</span>
							))}
						</p>
					</div>
				)}
				{selectionProcessString && (
					<div
						className={
							'process ' +
							(selectionProcessString.length > 45
								? 'custom-tooltip'
								: '')
						}
						data-tooltip={selectionProcessString}
					>
						<h4>Selection Process</h4>
						<p>{selectionProcessString}</p>
					</div>
				)}
			</div>
			{placementFlag && deadline && (
				<div className="deadline">
					<p>
						<img src={time} />
						Apply Before: {formatDate(deadline)}
					</p>
				</div>
			)}

			<div className="footer-area">
				{isInterested ? (
					<>
						{placementFlag ? (
							<AccioButton
								action={() => {
									if (eligibility)
										if (blacklisted)
											setBlackListModalOpen(true);
										else
											setApplyPopup({
												isOpen: true,
												identifier: jobCardId,
											});
								}}
								disabled={!eligibility}
								title={
									eligibility
										? 'Apply now'
										: new Date(deadline) < new Date()
										? 'Deadline Passed'
										: 'Not Eligible'
								}
								variant={
									eligibility ? 'outline' : 'disable'
								}
								customClass={`job-card-btn ${
									!eligibility ? 'disabled' : ''
								}`}
							/>
						) : null}
					</>
				) : (
					<AccioButton
						disabled={isInterested}
						title={'Marked As Not Interested'}
						variant="disable"
						customClass="job-card-btn disabled full-width"
					/>
				)}
				{eligibility &&
				isInterested &&
				new Date(deadline) > new Date() ? (
					<AccioButton
						action={() => {
							setJobDetailsPopup({
								isOpen: true,
								identifier: jobCardId + 'source:jobs',
								notInterested: true,
							});
						}}
						title="Mark As Not Interested"
						variant={'primary'}
						customClass={`job-card-btn details`}
					/>
				) : null}
			</div>
		</div>
	);
};

export default JobCard;
