import { Loader } from '@acciojob/loader';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Typography,
} from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import React, {
	FormEvent,
	ReactElement,
	Ref,
	forwardRef,
	useEffect,
	useState,
} from 'react';

import { GrClose } from 'react-icons/gr';
import CheckboxQuestion from '../components/questiontypes/CheckboxQuestion';
import RadioQuestion from '../components/questiontypes/RadioQuestion';
import TextQuestion from '../components/questiontypes/TextQuestion';
// api
import { encryptData } from '@acciojob/event-analytics';
import {
	Box,
	Modal,
	ModalClose,
	ModalDialog,
} from '@mui/joy';
import { services } from '../../apis';
import {
	CustomQuestionSubmission,
	CustomQuestionsData,
} from '../../apis/JobPortalService';
import { PopupState } from '../../pages/jobportal/JobPortal';
import {
	fetchApplicationCards,
	fetchJobCards,
	fetchJobPortalDashboard,
	fetchMockScoreCards,
} from '../../redux/jobportal/jobPortalSlice';
import { useAppDispatch } from '../../redux/store';

export interface CustomQuestion {
	id: string;
	type: string;
	statement: string;
	options: string[];
}

export interface FormDataType {
	[index: string]: string | string[];
}

interface FinalFormDataType {
	jobCardQuestion: string;
	answer: string[];
}

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: ReactElement;
	},
	ref: Ref<unknown>
) {
	return (
		<Slide
			direction="up"
			ref={ref}
			{...props}
		/>
	);
});

interface PopupProps {
	applyPopup: PopupState;
	setApplyPopup: (open: PopupState) => void;
}

const ApplyPopup = ({
	applyPopup,
	setApplyPopup,
}: PopupProps) => {
	// loading state

	const [loading, setLoading] = useState(false);
	const [customQuestions, setCustomQuestions] = useState(
		[] as CustomQuestionsData[]
	);
	const [formData, setFormData] = useState(
		{} as FormDataType
	);
	const [id, setId] = useState('');
	const [companyName, setCompanyName] = useState('');
	const [emptyFieldError, setEmptyFieldError] =
		useState(false);
	const [checked, setChecked] = React.useState(false);
	const [confirmOpen, setConfirmOpen] =
		React.useState<boolean>(false);

	const handleConfirmOpen = (
		e: FormEvent<HTMLFormElement>
	) => {
		e.preventDefault();
		setConfirmOpen(true);
		setId(applyPopup.identifier);
		// setApplyPopup({ isOpen: false, identifier: '' });
	};

	const handleConfirmClose = () => {
		setConfirmOpen(false);
		// setApplyPopup({ isOpen: true, identifier: id });
	};

	const handleConfirmSubmit = () => {
		setConfirmOpen(false);
		handleSubmit();
	};

	useEffect(() => {
		const fetchCustomQuestions = async () => {
			if (applyPopup.identifier !== '') {
				setId(applyPopup.identifier);
				setLoading(true);
				const companyNameResponse =
					await services.jobPortalService.fetchCompanyFromJobCardId(
						applyPopup.identifier
					);
				setCompanyName(companyNameResponse);

				const data =
					await services.jobPortalService.fetchCustomQuestions(
						applyPopup.identifier
					);
				const customQuestions = data.data;
				setFormData((initialFormData) => {
					let newFormData = { ...initialFormData };
					for (
						let i = 0;
						i < customQuestions.length;
						i += 1
					) {
						if (
							customQuestions[i].questionType === 'MULTIMCQ'
						)
							newFormData = {
								...newFormData,
								[customQuestions[i].id]: [] as string[],
							};
						else
							newFormData = {
								...newFormData,
								[customQuestions[i].id]: '',
							};
					}
					return newFormData;
				});
				setCustomQuestions(data.data);

				setTimeout(() => {
					setLoading(false);
				}, 300);
			}
		};

		fetchCustomQuestions();
	}, [applyPopup]);

	const handleClose = () => {
		setApplyPopup({
			isOpen: false,
			identifier: '',
		});
		setTimeout(() => {
			setFormData({} as FormDataType);
			setCustomQuestions([] as CustomQuestionsData[]);
		}, 300);
	};

	const updateFormData = (id: string, value: string) => {
		setFormData({ ...formData, [id]: value });
	};

	const updateCheckboxFormData = (
		id: string,
		checked: boolean,
		value: string
	) => {
		setFormData((oldFormData) => {
			const newFormData = { ...oldFormData };
			if (checked)
				(newFormData[id] as string[]).push(value);
			else
				(newFormData[id] as string[]).splice(
					newFormData[id].indexOf(value),
					1
				);
			return newFormData;
		});
	};

	const dispatch = useAppDispatch();

	const handleSubmit = async () => {
		const finalFormData: FinalFormDataType[] = [];
		let emptyField = false;

		for (
			let i = 0;
			i < Object.entries(formData).length;
			i += 1
		) {
			const [key, value] = Object.entries(formData)[i];
			finalFormData.push({
				jobCardQuestion: key,
				answer: typeof value === 'string' ? [value] : value,
			});
			if (
				finalFormData[finalFormData.length - 1].answer
					.length === 0
			) {
				emptyField = true;
				break;
			}
		}

		if (emptyField === true) {
			setEmptyFieldError(true);
			setTimeout(() => {
				setEmptyFieldError(false);
			}, 4500);
			return;
		}

		const submissionData: CustomQuestionSubmission = {
			jobCardId: applyPopup.identifier,
			customQuestionAnswers: finalFormData,
		};
		const postCustomQuestions =
			await services.jobPortalService.postCustomQuestions(
				submissionData
			);
		console.log(postCustomQuestions);
		dispatch(fetchMockScoreCards());
		dispatch(fetchJobCards());
		dispatch(fetchApplicationCards());
		dispatch(fetchJobPortalDashboard());
		handleClose();
	};
	const [scroll, setScroll] = React.useState<boolean>(true);

	return (
		<>
			<Modal
				open={applyPopup.isOpen}
				onClose={handleClose}
			>
				<ModalDialog aria-labelledby="dialog-vertical-scroll-title">
					<ModalClose
						variant="outlined"
						sx={{
							top: 'calc(-1/4 * var(--IconButton-size))',
							right: 'calc(-1/4 * var(--IconButton-size))',
							boxShadow: '0 2px 12px 0 rgba(0 0 0 / 0.2)',
							borderRadius: '50%',
							bgcolor: 'background.body',
						}}
					/>
					<Box
						sx={{
							overflowY: scroll ? 'scroll' : 'initial',
							mx: 'calc(-1 * var(--ModalDialog-padding))',
							px: 'var(--ModalDialog-padding)',
							maxHeight: 'calc(100% - 64px)',

							'&::-webkit-scrollbar': {
								width: '5px',
							},
							'&::-webkit-scrollbar-track': {
								backgroundColor: 'transparent',
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: '#D0D5DD',
								borderRadius: '5px',
							},
							'&::-webkit-scrollbar-thumb:hover': {
								backgroundColor: '#98A2B3',
							},
						}}
						className="thin-scrollbar"
					>
						<div className="job-portal-custom-questions-popup-inner">
							{loading === true && (
								<div className="loader-container">
									<Loader
										factVisibility={false}
										width={'100%'}
										height={'100%'}
									/>
								</div>
							)}
							{loading === false &&
								customQuestions.length === 0 && (
									<>
										<div className="popup-header">
											<div>
												{companyName && (
													<h3>{companyName}</h3>
												)}
												<p>
													Are you sure you want to apply to
													this job?
												</p>
											</div>
											<GrClose
												onClick={() => {
													handleClose();
												}}
											/>
										</div>
										<div className="confirmation-buttons">
											<form
												onSubmit={() => {
													handleSubmit();
												}}
												className="confirmation-buttons"
											>
												<Button
													type="submit"
													className="apply-now"
												>
													Yes, Apply now!
												</Button>
												<Button
													className="cancel"
													onClick={() => {
														handleClose();
													}}
												>
													No, Maybe later!
												</Button>
											</form>
										</div>
									</>
								)}
							{loading === false &&
								customQuestions.length !== 0 && (
									<>
										<div className="popup-header">
											<div>
												{companyName && (
													<h3>{companyName}</h3>
												)}
												<p>
													Please answer the following
													questions as a part of the
													application process:
												</p>
											</div>
										</div>

										<div className="popup-body">
											{/* Form Begin */}
											<form
												onSubmit={(e) => {
													handleConfirmOpen(e);
												}}
											>
												{customQuestions.map(
													(question, idx) => {
														if (
															question.questionType ===
															'TEXT'
														)
															return (
																<TextQuestion
																	key={idx}
																	sno={idx + 1}
																	formData={formData}
																	updateFormData={
																		updateFormData
																	}
																	{...question}
																/>
															);
														else if (
															question.questionType ===
															'MCQ'
														)
															return (
																<RadioQuestion
																	key={idx}
																	sno={idx + 1}
																	formData={formData}
																	updateFormData={
																		updateFormData
																	}
																	{...question}
																/>
															);
														else
															return (
																<CheckboxQuestion
																	key={idx}
																	sno={idx + 1}
																	formData={formData}
																	updateCheckboxFormData={
																		updateCheckboxFormData
																	}
																	{...question}
																/>
															);
													}
												)}
												<div className="check-box-wrapper">
													<label>
														<input
															type="checkbox"
															defaultChecked={checked}
															onChange={() =>
																setChecked(!checked)
															}
														/>
														<span className="check-box-title">
															I acknowledge that I have read
															all the terms and details
															mentioned in the Job
															Description. All the data in
															my profile and filled in this
															application is 100% accurate
															to the best of my knowledge. I
															declare that I will complete
															all the steps of the selection
															process of the company with
															diligence.
														</span>
													</label>
												</div>
												<div className="popup-footer">
													<Button
														type="submit"
														className={`custom-questions-submit ${
															checked
																? ''
																: 'apply-now-disenable'
														}`}
														event-analytics={
															'6a038779-ad84-465f-b946-dfdca975daa0'
														}
														data-config={encryptData(
															JSON.stringify({
																name: applyPopup.identifier,
															})
														)}
													>
														Apply Now
													</Button>
													{emptyFieldError && (
														<span>
															All fields are required,
															please fill all fields to
															continue.
														</span>
													)}
												</div>
											</form>
											{/* Form End */}
										</div>
									</>
								)}
						</div>
					</Box>
				</ModalDialog>
			</Modal>
			<Dialog
				open={confirmOpen}
				onClose={handleConfirmClose}
				fullWidth
				maxWidth={'sm'}
			>
				<DialogTitle>
					Are you sure you want to submit?
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<Typography color={'error'}>
							Please Note that If any information filled is
							found to be incorrect your portal will be
							blocked for 30 Days.
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleConfirmClose}
						color="primary"
					>
						No
					</Button>
					<Button
						onClick={handleConfirmSubmit}
						color="primary"
						autoFocus
					>
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ApplyPopup;
