import { Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchMentorDocs } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Box } from '@mui/system';
import ReactPlayer from 'react-player';
import {
	AiOutlineArrowLeft,
	AiOutlineArrowRight,
} from 'react-icons/ai';

function MentorTutorials() {
	const dispatch = useAppDispatch();
	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentorDocs } = MentorState;

	const [open, setOpen] = useState<boolean>(false);
	const [docs, setDocs] = useState<any>({});
	const [slides, setSlides] = useState();
	const [videoURL, setVideoURL] = useState('');

	const playerRef = useRef(null);

	useEffect(() => {
		if (mentorDocs === null) dispatch(fetchMentorDocs());
	}, [mentorDocs]);

	useEffect(() => {
		if (mentorDocs !== null) {
			console.log('mentorDocs', mentorDocs);
			setDocs({ ...mentorDocs });
			const items = mentorDocs.videos.map((obj: any) => (
				<div className="carouselItem">
					<img
						style={{
							width: '390px',
							height: '250px',
							margin: '1em',
							borderRadius: '10px',
						}}
						className="thumbnail"
						src={`${obj.thumbnail}`}
						onClick={() => {
							setOpen(true);
							setVideoURL(obj.url);
						}}
					/>
				</div>
			));
			setSlides(items);
		}
	}, [mentorDocs]);

	const responsive = {
		0: { items: 1 },
		568: { items: 3 },
		1024: { items: 3 },
	};

	const style = {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: 650,
		height: 450,
		bgcolor: 'background.paper',
		boxShadow: 24,
		p: 1,
	};

	const handleClose = () => setOpen(false);

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<ReactPlayer
						width="100%"
						muted={true}
						height={'100%'}
						style={{ borderRadius: '10px' }}
						url={videoURL}
						controls={true}
						ref={playerRef}
					/>
				</Box>
			</Modal>
			<div className="main-container">
				<h1 className="main-heading">Important Docs</h1>
				<div className="videos-container">
					<h2 className="section-heading">Videos</h2>
					<div className="carousel-container">
						<AliceCarousel
							mouseTracking={true}
							items={slides}
							responsive={responsive}
							autoPlay={false}
							infinite={false}
							disableDotsControls={true}
							renderPrevButton={() => (
								<div className="alice-left-btn">
									<AiOutlineArrowLeft />
								</div>
							)}
							renderNextButton={() => (
								<div className="alice-right-btn">
									<AiOutlineArrowRight />
								</div>
							)}
						/>
					</div>
				</div>
				<div className="docs-container">
					<h2 className="section-heading">Docs</h2>
					{docs.documents &&
						docs.documents.length > 0 &&
						docs.documents.map((doc: any) => (
							<div className="doc">
								<div className="doc-left"> {doc.title}</div>
								<div className="doc-right">
									<button
										className="open-doc"
										onClick={() => {
											window.open(doc.url, '_blank');
										}}
									>
										Open Doc
									</button>
								</div>
							</div>
						))}
				</div>
			</div>
		</>
	);
}

export default MentorTutorials;
