import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const CustomDropdown = ({
	options,
	isMulti,
	id,
	onDropdownChange,
	value,
	placeholder,
	showError,
	errorMessage,
}: any) => {
	if (id === ' graduationYear')
		console.log('graduation year', options);

	const handleOptionChange = (selectedOption: any) => {
		if (isMulti) {
			onDropdownChange(
				selectedOption.map((option: any) => option)
			);
		} else {
			console.log('selectedOption abc', selectedOption);
			onDropdownChange(selectedOption);
		}
	};

	const handleInputChange = (inputValue: string) => {
		console.log(inputValue);
	};

	const animatedComponents = makeAnimated();

	return (
		<div className="select-container">
			{showError ? (
				<span className="error-message">
					{errorMessage}
				</span>
			) : null}
			<Select
				id={id}
				options={options}
				value={value}
				components={animatedComponents}
				onChange={handleOptionChange}
				onInputChange={handleInputChange}
				isMulti={isMulti}
				placeholder={placeholder}
			/>
		</div>
	);
};

export default CustomDropdown;
