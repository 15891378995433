import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import arrow_right_blue from '../../assets/softSkillsNew/arrow_right_blue.svg';
import { AssignmentType } from '../../redux/softskills/softskills.type';

const AssignmentCard: FC<AssignmentType> = ({
	id,
	name,
	score,
	userScore,
	isSolved,
	difficulty,
}) => {
	const navigate = useNavigate();

	const handleStartSolving = (id: string) => {
		navigate(
			`/gym-aptitude?questionId=${id}&isFromSoftSkills=true`
		);
	};

	return (
		<div className="assignment-card">
			<p
				className={`difficulty ${difficulty.toLowerCase()}-difficulty`}
			>
				<span className="difficulty-icon"></span>
				<span className="difficulty-text">
					{difficulty}
				</span>
			</p>
			<p className="assignment-name">{name}</p>
			<p className="score">
				<span className="max-score">Max Score:</span>
				<span className="score-achieved">{score}</span>
			</p>
			{userScore ? (
				<p className="score">
					<span className="max-score">Your Score:</span>
					<span className="score-achieved">
						{userScore}
					</span>
				</p>
			) : null}

			<a
				className="assignment-link"
				onClick={() => {
					handleStartSolving(id);
				}}
			>
				{isSolved ? 'Solved' : 'Start Solving'}
				<img src={arrow_right_blue} />
			</a>
		</div>
	);
};
const AssignmentCards: FC<{
	assignments: Array<AssignmentType>;
}> = ({ assignments }) => {
	return (
		<div className="assignment-cards-section">
			<div className="assignment-cards">
				{assignments.map(
					({
						id,
						name,
						score,
						userScore,
						isSolved,
						difficulty,
					}) => (
						<AssignmentCard
							key={id}
							id={id}
							name={name}
							score={score}
							userScore={userScore}
							isSolved={isSolved}
							difficulty={difficulty}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default AssignmentCards;
