import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
// import remarkHTML from 'remark-html';
import Button from '@mui/material/Button';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import BoltIcon from '@mui/icons-material/Bolt';
import './QuestionPanel.css';
import { FormatColorReset } from '@mui/icons-material';

interface QuestionPanelProps {
	questionData: any;
}

const Diff = {
	EASY: '#66C61C',
	MEDIUM: '#EAAA08',
	HARD: '#FF4D4F',
};

//<img src={require("../../assets/codingquestile/maxscore.png")} />
//<img src={require("../../assets/codingquestile/yourscore.png")} />
//<img src={require("../../assets/codingquestile/difficulty.png")} />
const QuestionPanel: React.FC<QuestionPanelProps> = ({
	questionData,
}) => {
	const [ShowHints, SetShowHints] = useState(false);
	const [Hint, SetHint] = useState(0);
	console.log('question Data', questionData);
	return (
		<div
			style={{
				margin: '20px 20px 80px 20px',
				position: 'relative',
			}}
		>
			<div
				className="questions-all-stats"
				style={{ marginBottom: '20px' }}
			>
				<div className="question-stat">
					<BoltIcon />
					<p>Difficulty Level: </p>
					<span style={{ color: Diff['MEDIUM'] }}>
						{questionData.difficulty}
					</span>
				</div>
				<div className="question-stat">
					<TrackChangesIcon />
					<p> Max Score:</p>
					<span style={{ color: Diff.EASY }}>
						{questionData.score} Points
					</span>
				</div>
				{!ShowHints ? (
					<div className="question-stat">
						{/* <WorkspacePremiumIcon />
						<p>Hint:</p>
						<span
							style={{ color: Diff.HARD, cursor: 'pointer' }}
							onClick={(e) => SetShowHints(true)}
						>
							Show Hints
						</span> */}
					</div>
				) : (
					<div className="question-stat">
						{/* <WorkspacePremiumIcon />
						<p>Hint:</p>
						<span
							style={{ color: Diff.HARD, cursor: 'pointer' }}
							onClick={(e) => SetShowHints(false)}
						>
							Hide Hints
						</span> */}
					</div>
				)}
			</div>
			{/* {ShowHints ? (
				<div className="hints-div">
					<div className="hints-buttons">
						<span
							className={Hint == 0 ? 'selected' : 'notselected'}
							onClick={(e) => SetHint(0)}
						>
							Hint 1
						</span>
						<span
							className={Hint == 1 ? 'selected' : 'notselected'}
							onClick={(e) => SetHint(1)}
						>
							Hint 2
						</span>
						<span
							className={Hint == 2 ? 'selected' : 'notselected'}
							onClick={(e) => SetHint(2)}
						>
							Hint 3
						</span>
						{questionData.hints[3] != '' ? (
							<span
								className={
									Hint == 3 ? 'selected' : 'notselected'
								}
								onClick={(e) => SetHint(3)}
							>
								Hint 4
							</span>
						) : null}
					</div>
					<div className="hints-data">
						{Hint != 3 && questionData.hints[Hint] != '' ? (
							<p>{questionData.hints[Hint]}</p>
						) : (
							<Button
								variant="contained"
								color="error"
								onClick={() =>
									window.open(questionData.hints[Hint])
								}
							>
								<YouTubeIcon /> Watch Video
							</Button>
						)}
					</div>
				</div>
			) : null} */}
			<ReactMarkdown
				className={`markdown-body`}
				remarkPlugins={[remarkGfm]}
				rehypePlugins={[rehypeHighlight, rehypeRaw]}
			>
				{questionData.statement}
			</ReactMarkdown>
		</div>
	);
};

export default QuestionPanel;
