import moment, { min } from 'moment';
import { useEffect, useState } from 'react';
import { BiLink } from 'react-icons/bi';
import { BsPeopleFill } from 'react-icons/bs';
import { IoDocumentText } from 'react-icons/io5';
import { MdOutlineSpeakerNotesOff } from 'react-icons/md';
import { SiGooglemeet } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import graduate from '../../../../assets/images/graduate.png';
import AccioButton from '../../../../components/elements/Button';
import { fetchUpcomingOngoingSessions } from '../../../../redux/mentorSlice/mentorSlice';
import { useAppDispatch } from '../../../../redux/store';
import FormModal from '../mentorSessions/FormModal';
import {
	calculateTimeLeft,
	checkNoShowByMentorOrStudent,
	checkShowFeedback,
	getRecommendedQns,
	handleBtnsDisplay,
	handleBtnsDisplayBasedOnTimer,
	handleConfirmGmeet,
	handleSessionDidntHappen,
	joinNow,
} from '../mentorUtils';
import ConfirmGmeetModal from './ConfirmGmeetModal';
import DidntHappenModal from './DidntHappenModal';
import FinaliseQnsModal from './FinaliseQnsModal';

export enum SessionPlatform {
	INTERNAL_ZOOM = 'INTERNAL_ZOOM',
	EXTERNAL_GMEET = 'EXTERNAL_GMEET',
}

const LatestSession2 = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();

	const UserState = useSelector((state: any) => state.user);
	const { user } = UserState;

	const MentorState = useSelector(
		(state: any) => state.mentor
	);
	const { mentor, upcomingOngoingSessions } = MentorState;

	interface Student {
		id: string;
		firstName: string;
		lastName: string;
		phoneNumber: string;
		email: string;
	}

	const studentInitialData = {
		id: '',
		firstName: '',
		lastName: '',
		phoneNumber: '',
		email: '',
	};

	const [open, setOpen] = useState(false);
	const [sessionsData, setSessionsData] =
		useState<any>(null);
	const [latestSession, setLatestSession] =
		useState<any>(null);
	const [showGmeet, setShowGmeet] = useState(false);
	const [showDidntHappen, setShowDidntHappen] =
		useState(false);
	// const [signature, setSignature] = useState(null);
	const [student, setStudent] = useState<Student>(
		studentInitialData
	);
	const [upcomingSessions, setUpcomingSessions] =
		useState<any>(null);
	const [confirmGmeet, setConfirmGmeet] = useState(false);
	const [showJoinNow, setShowJoinNow] = useState(false);
	const [reason, setReason] = useState<string>('');

	const [timeLeft, setTimeLeft] = useState<any>();
	const [startTimer, setStartTimer] =
		useState<boolean>(false);
	const [typeUser, setUserType] = useState('USER');
	const [openRandomQns, setOpenRandomQns] =
		useState<boolean>(false);
	const [newQns, setNewQns] = useState<any>(null);
	const [feedbackFormUrl, setFeedbackFormUrl] =
		useState<string>('');
	const [openForm, setOpenForm] = useState<boolean>(false);
	const [selectedQns, setSelectedQns] = useState<any>(null);
	const [qnsFinalised, setQnsFinalised] =
		useState<boolean>(false);

	const [refresh, setRefresh] = useState<boolean>(false);
	const [sessionStarted, setSessionStarted] =
		useState<boolean>(false);

	useEffect(() => {
		if (location.pathname === '/') {
			dispatch(fetchUpcomingOngoingSessions());
		}
	}, [location]);

	useEffect(() => {
		if (upcomingOngoingSessions !== null) {
			setSessionsData(upcomingOngoingSessions);
		}
	}, [mentor, upcomingOngoingSessions]);

	useEffect(() => {
		if (qnsFinalised || refresh)
			dispatch(fetchUpcomingOngoingSessions());
		if (refresh) setRefresh(false);
	}, [qnsFinalised, refresh]);

	useEffect(() => {
		const hasOngoingSessions =
			sessionsData &&
			Object.keys(sessionsData.ongoingSession).length > 0;
		const hasUpcomingSessions =
			sessionsData &&
			Object.keys(sessionsData.upcomingSessions).length > 0;

		if (hasOngoingSessions) {
			const user = sessionsData.ongoingSession.user;
			setStudent({ ...user });
			setLatestSession({ ...sessionsData.ongoingSession });
			if (hasUpcomingSessions) {
				if (sessionsData.upcomingSessions.length > 2)
					setUpcomingSessions([
						...sessionsData.upcomingSessions.slice(0, 2),
					]);
				else
					setUpcomingSessions([
						...sessionsData.upcomingSessions,
					]);
			}
			setStartTimer(true);
		} else if (hasUpcomingSessions) {
			const user = sessionsData.upcomingSessions[0].user;
			setStudent({ ...user });
			setLatestSession({
				...sessionsData.upcomingSessions[0],
			});
			if (sessionsData.upcomingSessions.length > 2)
				setUpcomingSessions([
					...sessionsData.upcomingSessions.slice(1, 3),
				]);
			else
				setUpcomingSessions([
					...sessionsData.upcomingSessions.slice(1),
				]);
			setStartTimer(true);
		} else {
			setLatestSession(null);
			setUpcomingSessions(null);
		}
	}, [sessionsData]);

	const [checkNoShow, setCheckNoShow] =
		useState<boolean>(false);
	const [showNoShowMsg, setShowNoShowMsg] =
		useState<boolean>(false);

	useEffect(() => {
		if (latestSession !== null) {
			handleBtnsDisplay(
				setShowJoinNow,
				setShowGmeet,
				setShowDidntHappen,
				latestSession,
				'HOME'
			);
			const isNoShowByMentorOrStudent =
				checkNoShowByMentorOrStudent(latestSession, 'HOME');
			if (isNoShowByMentorOrStudent) {
				setShowGmeet(false);
				setShowDidntHappen(false);
				setShowJoinNow(false);
				setCheckNoShow(true);
				setShowNoShowMsg(true);
			}
		}
	}, [latestSession]);

	const timerBtnCtrl = () => {
		const timeLeft = calculateTimeLeft(
			latestSession.sessionStartTime
		);
		handleBtnsDisplayBasedOnTimer(
			timeLeft,
			setShowJoinNow,
			setRefresh,
			setSessionStarted
		);
		return timeLeft;
	};

	useEffect(() => {
		if (
			latestSession !== null &&
			Object.keys(latestSession).length > 0 &&
			!sessionStarted
		)
			setTimeout(() => setTimeLeft(timerBtnCtrl()), 1000);
	}, [startTimer, timeLeft]);

	const [noShowTimeLeft, setNoShowTimeLeft] =
		useState<any>();

	const calculateNoShowTime = () => {
		const timeLeft = calculateTimeLeft(
			moment(latestSession.sessionStartTime)
				.add(1201, 's')
				.toDate()
				.toString()
		);
		setNoShowTimeLeft({ ...timeLeft });
		const { hours, minutes, seconds } = timeLeft;
		if (hours === 0 && minutes === 0 && seconds === 1) {
			setRefresh(true);
		}
	};

	useEffect(() => {
		if (
			latestSession !== null &&
			Object.keys(latestSession).length > 0 &&
			sessionStarted &&
			!checkNoShow
		)
			setTimeout(
				() => setTimeLeft(calculateNoShowTime()),
				1000
			);
	}, [latestSession, checkNoShow, noShowTimeLeft]);

	const openSessionDidntHappen = () => setOpen(true);

	const handleSessionDidntHappenCallback = (
		sessionId: string
	) => {
		handleSessionDidntHappen(
			sessionId,
			reason,
			setShowDidntHappen,
			setShowJoinNow,
			setShowGmeet,
			setOpen
		);
		setRefresh(true);
	};

	const handleClose = () => setOpen(false);

	const joinNowCallback = () => {
		joinNow(latestSession, user);
	};

	const handleJoinNow = () => {
		const qnsFinalised =
			latestSession.selectedQuestions !== null;
		if (qnsFinalised) joinNowCallback();
		else {
			setOpenRandomQns(true);
		}
	};

	const handleShiftedToGmeet = () => setConfirmGmeet(true);

	const handleConfirmGmeetCallback = () => {
		handleConfirmGmeet(
			latestSession.sessionId,
			setConfirmGmeet,
			setShowJoinNow,
			setShowGmeet
		);
	};

	const handleCloseGmeet = () => setConfirmGmeet(false);

	const [hasRecommendedQns, setHasRecommendedQns] =
		useState<boolean>(false);

	useEffect(() => {
		if (latestSession !== null) {
			getRecommendedQns(
				latestSession.sessionId,
				setHasRecommendedQns
			);
		}
	}, [latestSession]);

	const handleCloseRandomQns = () =>
		setOpenRandomQns(false);

	const handleFillFeedback = (url: string) => {
		const selectedQns = latestSession.selectedQuestions;
		if (
			(selectedQns !== null &&
				Object.keys(selectedQns).length > 0) ||
			!hasRecommendedQns
		) {
			setFeedbackFormUrl(url);
			setOpenForm(true);
			setSelectedQns(selectedQns);
		} else {
			setOpenRandomQns(true);
		}
	};

	const handleFormClose = () => setOpenForm(false);

	return (
		<>
			{open && (
				<DidntHappenModal
					open={open}
					handleClose={handleClose}
					session={latestSession}
					setReason={setReason}
					handleSessionDidntHappen={
						handleSessionDidntHappenCallback
					}
				/>
			)}

			{confirmGmeet && (
				<ConfirmGmeetModal
					confirmGmeet={confirmGmeet}
					handleCloseGmeet={handleCloseGmeet}
					handleConfirmGmeet={handleConfirmGmeetCallback}
				/>
			)}

			{openRandomQns && (
				<FinaliseQnsModal
					setQnsFinalised={setQnsFinalised}
					openRandomQns={openRandomQns}
					handleCloseRandomQns={handleCloseRandomQns}
					joinNow={joinNowCallback}
					sessionId={latestSession.sessionId}
				/>
			)}

			{openForm && (
				<FormModal
					openForm={openForm}
					url={feedbackFormUrl}
					handleFormClose={handleFormClose}
					selectedQns={selectedQns}
				/>
			)}

			{latestSession ? (
				<div className="mentor-session-details-wrapper">
					<div className="mentor-session-details-container">
						<div className="mentor-session-name-outer">
							<span className="text-1">
								{timeLeft &&
								timeLeft.hours === 0 &&
								timeLeft.minutes === 0 &&
								timeLeft.seconds === 0
									? 'ONGOING SESSION :'
									: 'UPCOMING SESSION :'}
							</span>
							<span className="text-2">
								{latestSession.sessionType}
							</span>
						</div>

						<div className="mentor-session-data">
							<div className="mentor-session-data-1">
								<div className="session-participant-image-wrapper">
									<img
										src={graduate}
										className="student-icon"
									/>
								</div>
								<p className="session-participant-name">
									{student.firstName +
										' ' +
										student.lastName}
								</p>
								<p className="session-participant-mobile">
									{student.phoneNumber}
								</p>
							</div>
							<div className="mentor-session-data-2">
								<div className="mentor-session-data-2-top">
									{sessionStarted ? (
										<p className="session-status">
											<span className="text">
												Session is live
											</span>{' '}
											<span className="blink"></span>
										</p>
									) : (
										<p
											className={`session-timer ${
												timeLeft &&
												timeLeft.hours === 0 &&
												timeLeft.minutes <= 5
													? 'session-timer-5-min-remain'
													: ''
											}`}
										>
											<span className="time">
												{timeLeft &&
													String(timeLeft.hours).padStart(
														2,
														'0'
													)}
											</span>
											<span className="text">
												{timeLeft &&
												Number(timeLeft.hours) > 1
													? 'Hrs'
													: 'Hr'}
											</span>

											<span className="blink">:</span>

											<span className="time">
												{timeLeft &&
													String(timeLeft.minutes).padStart(
														2,
														'0'
													)}
											</span>
											<span className="text">
												{timeLeft &&
												Number(timeLeft.minutes) > 1
													? 'Mins'
													: 'Min'}
											</span>

											<span className="blink">:</span>

											<span className="time">
												{timeLeft &&
													String(timeLeft.seconds).padStart(
														2,
														'0'
													)}
											</span>
											<span className="text">
												{timeLeft &&
												Number(timeLeft.seconds) > 1
													? 'Secs'
													: 'Sec'}
											</span>
										</p>
									)}
								</div>
								<div className="mentor-session-data-wrapper">
									<div className="mentor-session-data-2-inside mentor-session-data-2-mid-1">
										<p className="session-time">
											<span className="text-1">
												Session Start Time :
											</span>
											<span className="text-2">
												{moment(
													latestSession.sessionStartTime
												).format('hh:mm A')}
											</span>
										</p>
										<p className="session-duration">
											<span className="text-1">
												Session Duration :
											</span>
											<span className="text-2">
												{latestSession.sessionDuration} Mins
											</span>
										</p>
									</div>
									<div className="mentor-session-data-2-inside mentor-session-data-2-mid-2">
										<AccioButton
											variant="outline"
											title="Session Guidelines"
											icon={<IoDocumentText />}
											action={() =>
												window.open(
													'https://docs.google.com/document/d/1dRt00ZZftxIyKPfxsBvH0ywgMEQyLU-FbjTc6uaNhi0/edit',
													'_blank'
												)
											}
											customClass="btn-1"
										/>
										{checkShowFeedback(
											latestSession,
											'HOME'
										) && showDidntHappen ? (
											<AccioButton
												variant="outline"
												title="Get feedback form link"
												icon={<BiLink />}
												action={() =>
													handleFillFeedback(
														latestSession.mentorFeedbackFormLink
													)
												}
												customClass="btn-2"
											/>
										) : null}
									</div>
									<div className="mentor-session-data-2-inside mentor-session-data-2-bottom">
										{showJoinNow ? (
											<AccioButton
												variant="primary"
												title="Join Now"
												icon={<BsPeopleFill />}
												action={() => handleJoinNow()}
												customClass="btn"
											/>
										) : null}
										{showGmeet ? (
											<AccioButton
												variant="primary"
												title="Shift to G-meet"
												icon={<SiGooglemeet />}
												action={() =>
													handleShiftedToGmeet()
												}
												customClass="btn"
											/>
										) : null}
										{showDidntHappen ? (
											<AccioButton
												variant="primary"
												title="Session didn't happen"
												icon={<MdOutlineSpeakerNotesOff />}
												action={() =>
													openSessionDidntHappen()
												}
												customClass="btn"
											/>
										) : null}

										{showNoShowMsg ? (
											!latestSession.mentorJoinedWithinThresholdTime ? (
												<b>
													This session has been marked no
													show for Mentor
												</b>
											) : (
												<b>
													This session has been marked no
													show for Student
												</b>
											)
										) : null}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="boder-add"></div>
				</div>
			) : (
				<p className="mentor-session-details-wrapper-text">
					NO UPCOMING SESSIONS
				</p>
			)}

			{upcomingSessions && (
				<div>
					{upcomingSessions.map((session: any) => (
						<div className="mentor-session-details-wrapper-upcoming">
							<div className="mentor-session-details-container">
								<div className="mentor-session-name-outer">
									<span className="text-1">
										UPCOMING SESSION :
									</span>
									<span className="text-2">
										{session.sessionType}
									</span>
								</div>

								<div className="mentor-session-data-wrapper">
									<div className="session-participant-image-wrapper ">
										<img
											src={graduate}
											className="student-icon"
										/>
									</div>
									<div className="mentor-session-text-wrapper">
										<div className="session-participant-wrapper">
											<p className="session-participant-name ">
												{session.user.firstName +
													' ' +
													session.user.lastName}
											</p>

											<p className="session-participant-mobile">
												{session.user.phoneNumber}
											</p>
										</div>
										<div className="session__">
											<p className="session-time">
												<span className="text-1">
													Session Date :
												</span>
												<span className="text-2">
													{moment(
														session.sessionStartTime
													).format('DD:MM:YYYY')}
												</span>
											</p>

											<p className="session-duration">
												<span className="text-1">
													Session Time :
												</span>
												<span className="text-2">
													{moment(
														session.sessionStartTime
													).format('hh:mm A')}
												</span>
											</p>

											<p className="session-duration">
												<span className="text-1">
													Duration :
												</span>
												<span className="text-2">
													{session.sessionDuration} Mins
												</span>
											</p>
										</div>
									</div>
								</div>
							</div>
							<div className="boder-add"></div>
						</div>
					))}
				</div>
			)}
		</>
	);
};

export default LatestSession2;
