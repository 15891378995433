import { Box } from '@mui/material';
import RenderMarkDown from '../shared/RenderMarkdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import Lottie from 'react-lottie-player';
import announcementLottie from '../../assets/lottie/announcement.json';
import congratulationSuccessLottie from '../../assets/lottie/congratulation-success.json';
import { useEffect, useState } from 'react';

interface AnnouncementProps {
	noticeType: boolean;
	noticeData: string;
}

const Announcement = ({
	noticeType,
	noticeData,
}: AnnouncementProps) => {
	const UserState: any = useSelector(
		(state: RootState) => state.user
	);

	return (
		<Box className={'announcement'}>
			<div className={'notice-comp'}>
				{noticeType ? (
					<div className={'notice-green'}>
						<div className={'container-1'}>
							<RenderMarkDown>{noticeData}</RenderMarkDown>
						</div>

						<Lottie
							loop={false}
							animationData={announcementLottie}
							play
						/>
					</div>
				) : (
					<div className={'notice-green notice-yellow'}>
						<div className={'container-1'}>
							<RenderMarkDown>{noticeData}</RenderMarkDown>
						</div>

						<Lottie
							loop={false}
							animationData={congratulationSuccessLottie}
							play
						/>
					</div>
				)}
			</div>
		</Box>
	);
};

export default Announcement;
