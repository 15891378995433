import moment from 'moment';
import { useEffect, useState } from 'react';

import { BsInfoCircle } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';

import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import { fetchRecordings } from '../../redux/course/courseSlice';
import {
	changeCurrentModule,
	fetchModuleQuestions,
} from '../../redux/modules/moduleSlice';

import { IoMdClose } from 'react-icons/io';
import { InputWithDecorator } from '../elements/Input';
import ModuleCard from './moduleCard';

interface MarksBifurcationProps {
	data: any;
	yourScore: number;
	totalScore: number;
}

const MarksBifurcation = ({
	data,
	yourScore,
	totalScore,
}: MarksBifurcationProps) => {
	return (
		<div className={'module-mid-head'}>
			<>
				<div
					className={'course-assignment-status'}
					id={'module-mid-head'}
				>
					Total Assignments Score : {yourScore}/{totalScore}
					<BsInfoCircle />
				</div>
				<div
					className={'bifurcation'}
					id={'bifurcation'}
				>
					<ul>
						<li>
							Coding Score :
							<span>
								{data.solvedCodingQuestionsScore}/
								{data.totalCodingQuestionsScore}
							</span>
						</li>
						<li>
							WebDev Score :
							<span>
								{data.solvedWebDevQuestionsScore}/
								{data.totalWebDevQuestionsScore}
							</span>
						</li>
						<li>
							Conceptual Score :
							<span>
								{data.solvedConceptualQuestionsScore}/
								{data.totalConceptualQuestionsScore}
							</span>
						</li>
					</ul>
				</div>
			</>
		</div>
	);
};

const ModuleNavigator = () => {
	const dispatch = useDispatch();
	const userState = useSelector((state: any) => state.user);
	const { user } = userState;

	const moduleState = useSelector(
		(state: any) => state.module
	);
	const {
		allModulesData,
		modulesData,
		moduleListloading,
		currentModuleId,
	} = moduleState;

	const courseState = useSelector(
		(state: any) => state.course
	);
	const {
		latestAssignment,
		courseID,
		latestAssignmentData,
	} = courseState;

	const [modulesList, setModulesList] = useState<any[]>([]);
	const [totalScore, setTotalScore] = useState(0);
	const [totalEarnedScore, setTotalEarnedScore] =
		useState(0);

	const [searchText, setSearchText] = useState('');

	const instructor =
		user !== null
			? user.roles.find((role: any) => role == 'instructor')
			: undefined;
	const role = instructor !== undefined ? 1 : 0;

	const searchInModules = (e: any) => {
		const temp = e.toLowerCase();
		// if(allModulesData.assignmentModules )
		const filteredData =
			allModulesData.assignmentModules &&
			allModulesData.assignmentModules.filter((el: any) =>
				el.name.toLowerCase().includes(temp)
			);
		setModulesList(filteredData);
		console.log('FILTER', filteredData);
	};

	const selectModule = async (
		moduleID: string,
		type: string,
		name: string
	) => {
		dispatch(changeCurrentModule(moduleID));
		if (modulesData[moduleID] === undefined) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			await dispatch(fetchModuleQuestions(moduleID));
		}
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		await dispatch(fetchRecordings(moduleID));
	};

	useEffect(() => {
		if (allModulesData !== null) {
			const temp = [...allModulesData.assignmentModules];
			temp.sort(function (a: any, b: any) {
				return moment(a.startDate) > moment(b.startDate)
					? -1
					: 1;
			});
			setModulesList(temp);
			const ts =
				allModulesData.totalCodingQuestionsScore +
				allModulesData.totalWebDevQuestionsScore +
				allModulesData.totalConceptualQuestionsScore +
				allModulesData.totalFileQuestionsScore;
			const tes =
				allModulesData.solvedCodingQuestionsScore +
				allModulesData.solvedWebDevQuestionsScore +
				allModulesData.solvedConceptualQuestionsScore +
				allModulesData.solvedFileQuestionsScore;

			setTotalScore(ts);
			setTotalEarnedScore(tes);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const elem = document.getElementById(
				'module-mid-head'
			);

			if (elem !== null) {
				elem.addEventListener('mouseover', () => {
					console.log('mouseover hit');
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const childElem =
						document.getElementById('bifurcation');
					console.log('childElem', childElem);
					if (childElem !== null) {
						childElem.style.opacity = '1';
						childElem.style.zIndex = '50';
					}
				});
				elem.addEventListener('mouseleave', () => {
					console.log('mouseover hit');
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					const childElem =
						document.getElementById('bifurcation');
					console.log('childElem', childElem);
					if (childElem !== null) {
						childElem.style.opacity = '0';
						childElem.style.zIndex = '-1';
					}
				});
			}
		}
	}, [allModulesData]);

	useEffect(() => {
		if (
			latestAssignment !== null &&
			latestAssignmentData !== null
		) {
			selectModule(
				latestAssignment,
				latestAssignmentData.module.type,
				latestAssignmentData.module.name
			);
		}
	}, [latestAssignment, courseID]);

	useEffect(() => {
		if (allModulesData) searchInModules(searchText);
	}, [searchText]);

	return (
		<div
			className={'module-navigator'}
			id="module-navigator"
		>
			<div className="head">
				<InputWithDecorator
					placeholder="Search any topic"
					value={searchText}
					setValue={setSearchText}
					startDecoration={<FiSearch />}
					endDecoration={
						searchText.length ? (
							<span
								className="end-decoration"
								onClick={() => {
									setSearchText('');
								}}
							>
								<IoMdClose />
							</span>
						) : null
					}
					customClass="search-module Joy-focused"
				/>
				{allModulesData !== null ? (
					<MarksBifurcation
						data={allModulesData}
						totalScore={totalScore}
						yourScore={totalEarnedScore}
					/>
				) : null}
			</div>
			<div className={'module-list-container'}>
				<ul>
					{!moduleListloading ? (
						modulesList.length === 0 ? (
							<p className={'no-module'}>
								No Module Available
							</p>
						) : (
							modulesList.map((el: any, ind: number) => {
								return (
									<ModuleCard
										key={ind}
										active={el.id === currentModuleId}
										id={el.id}
										startDate={el.startDate}
										moduleName={el.name}
										idUserModule={el.idUserModule}
										onModuleSelect={selectModule}
										totalQuestions={el.totalQuestions}
										solved={el.solved}
										liveAttended={el.liveAttended}
										recordingWatched={el.recordingWatched}
										type={el.type}
									/>
								);
							})
						)
					) : (
						<Loader />
					)}
				</ul>
			</div>
		</div>
	);
};

export default ModuleNavigator;
