import {
	Autocomplete,
	Dialog,
	DialogActions,
	DialogTitle,
	TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { services } from '../../../../../apis';
import AccioButton from '../../../../elements/Button';

interface College {
	id: string;
	college_name: string;
}

interface InputSearchBoxProps {
	disabled: boolean;
	defaultValue: string;
	typeCollege: 'UG' | 'PG';
	placeHolder: string;
	onChange: (value: string) => void;
	value:
		| string
		| number
		| { [key: string]: string | null }
		| null
		| undefined;
	onSelection: (
		event: any,
		newValue: any,
		type: 'UG' | 'PG'
	) => void;
}

const InputSearchBox: React.FC<InputSearchBoxProps> = ({
	typeCollege,
	placeHolder,
	onChange,
	value,
	onSelection,
	defaultValue,
	disabled,
}) => {
	console.log('Default Valueee>>>>', defaultValue);
	const [autocompleteKey, setAutocompleteKey] = useState(0);
	const [newCollege, setNewCollege] = useState('');
	const [inputValue, setInputValue] = useState<string>('');
	const [options, setOptions] = useState<College[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [isDialogOpen, setIsDialogOpen] =
		useState<boolean>(false);

	const fetchColleges = async (searchText: string) => {
		setLoading(true);
		try {
			const results =
				await services.userService.getCollegeNames(
					searchText
				);
			setOptions(results);
		} catch (error) {
			console.error('Error fetching college names:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (inputValue.length > 2) {
			fetchColleges(inputValue);
		} else {
			setOptions([]);
		}
	}, [inputValue]);

	const getFirstOption = async () => {
		try {
			console.log('1>>>');
			const results =
				await services.userService.getCollegeNames(
					defaultValue
				);

			handleSelection(null, results[0], true);
		} catch (error) {
			console.error('Error fetching college names:', error);
		}
	};

	useEffect(() => {
		getFirstOption();
	}, []);

	const handleSelection = async (
		event: any,
		newValue: any,
		firstTime: boolean
	) => {
		console.log('2>>>');
		console.log('Selection>>>', newValue);
		if (newValue && newValue.id === 'add_new') {
			setIsDialogOpen(true);
		} else if (newValue) {
			setInputValue(newValue.college_name);
			onChange(newValue.college_name);
			onSelection(event, newValue, typeCollege);
			if (!firstTime) {
				const response =
					await services.userService.updateCollegeName(
						newValue.college_name,
						newValue.id,
						typeCollege
					);

				if (
					response &&
					response.statusCode !== 400 &&
					response.statusCode !== 500
				) {
					toast.success(response.message);
				} else {
					toast.error(response.message);
				}
			}
		}
	};

	const handleAddNewCollege = async () => {
		if (newCollege.trim().length > 0) {
			try {
				setLoading(true);
				const response =
					await services.userService.updateCollegeName(
						newCollege,
						null,
						typeCollege
					);
				if (
					response &&
					response.statusCode !== 400 &&
					response.statusCode !== 500
				) {
					toast.success(response.message);
				} else {
					toast.error(response.message);
				}
			} catch (error) {
				console.error(
					'Error updating college name:',
					error
				);
			} finally {
				setLoading(false);
				setIsDialogOpen(false);
				setNewCollege('');
				setInputValue('');
				setAutocompleteKey((prev) => prev + 1);
			}
		} else {
			toast.warning('Your College name cannot be empty!');
		}
	};

	return (
		<>
			<Autocomplete
				disabled={disabled}
				key={autocompleteKey}
				options={[
					...options,
					{
						id: 'add_new',
						college_name: 'Add New College',
					},
				]}
				getOptionLabel={(option) => option.college_name}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					console.log(
						'Event Changing 123>>>',
						newInputValue,
						typeof newInputValue,
						newInputValue.length
					);
					if (event) {
						setInputValue(newInputValue);
					}
				}}
				onChange={(e, newValue) =>
					handleSelection(e, newValue, false)
				}
				loading={loading}
				sx={{
					'& .MuiAutocomplete-root': {
						padding: '6px 0px !important',
					},
					cursor: disabled ? 'not-allowed' : 'default',
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						sx={{
							flex: 1,
							margin: '6px 0px',
							'& .MuiInputBase-input': {
								padding: '10px 12px',
								fontSize: '14px',
								color: '#8C9AAC',
								cursor: disabled
									? 'not-allowed'
									: 'default',
							},
							cursor: disabled ? 'not-allowed' : 'default',
						}}
						variant="outlined"
					/>
				)}
			/>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				open={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
			>
				<div style={{ width: '100%', padding: '1rem' }}>
					<DialogTitle sx={{ paddingLeft: 0 }}>
						Please Add A College
					</DialogTitle>

					<TextField
						label="College Name"
						sx={{ width: '100%' }}
						value={newCollege}
						onChange={(e) => setNewCollege(e.target.value)}
					/>

					<DialogActions>
						<AccioButton
							action={() => setIsDialogOpen(false)}
							variant="primary"
							title="Cancel"
						/>
						<AccioButton
							action={handleAddNewCollege}
							variant="primary"
							title="Add"
						/>
					</DialogActions>
				</div>
			</Dialog>
		</>
	);
};

export default InputSearchBox;
