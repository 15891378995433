import { useEffect, useState } from 'react';
import AccioButton from '../../../../components/elements/Button';
import { MarkDown } from '../../../../components/shared/MarkDown';

interface PropsInterface {
	onSaveQuestion: (answer: Array<string>) => void;
	onSubmitQuestion?: (answer: Array<string>) => void;
	response?: Array<string>;
	correct?: Array<string>;
	submitted: boolean;
	explanation?: string;
	isLoading?: boolean;
}

const SubjectiveQuestion = (props: PropsInterface) => {
	const [inputValue, setInputValue] = useState('');

	const submitAnswer = () => {
		const temp = [inputValue];
		if (props.onSubmitQuestion)
			props.onSubmitQuestion(temp);
	};

	useEffect(() => {
		if (props.response) setInputValue(props.response[0]);
	}, [props.response]);

	return (
		<div className={'options-content'}>
			<div className={'options'}>
				<textarea
					disabled={props.submitted}
					value={inputValue}
					onChange={(event) => {
						setInputValue(event.target.value);
					}}
					placeholder={'Type your answer'}
				/>
			</div>

			{!props.submitted ? (
				<AccioButton
					title={'Submit'}
					disabled={inputValue.length === 0}
					action={submitAnswer}
					className={'save-btn'}
					// isLoading={props.isLoading}
				/>
			) : null}

			{props.submitted ? (
				<div className={'correct-answer'}>
					<h1>Correct Answer</h1>
					<ul>
						{props.correct?.map((el) => (
							<li key={el}>{el}</li>
						))}
					</ul>
					{props.explanation &&
					props.explanation.length > 1 ? (
						<h1>Explanation</h1>
					) : null}
					{props.explanation &&
					props.explanation.length > 1 ? (
						<p>
							<MarkDown>{props.explanation}</MarkDown>
						</p>
					) : null}
				</div>
			) : null}
		</div>
	);
};

export default SubjectiveQuestion;
