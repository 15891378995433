import { Box } from '@mui/material';
import {
	useEffect,
	useRef,
	useState,
	type FC,
} from 'react';
import { FiArrowUp } from 'react-icons/fi';
import { GymFilter } from './Filter';
import { GymQuestions } from './Questions';

type GymPanelPropsType = {
	tabId: string;
};

export const GymPanel: FC<GymPanelPropsType> = ({
	tabId,
}) => {
	const [showBackToTop, setShowBackToTop] = useState(false);
	const scrollRef = useRef<HTMLDivElement>(null);

	const handleScroll = () => {
		if (scrollRef.current) {
			const top = scrollRef.current.scrollTop;
			console.log({ top });
			if (top > 100) {
				setShowBackToTop(true);
			} else {
				setShowBackToTop(false);
			}
		}
	};

	useEffect(() => {
		scrollRef.current?.addEventListener(
			'scroll',
			handleScroll
		);
		return () => {
			scrollRef.current?.removeEventListener(
				'scroll',
				handleScroll
			);
		};
	}, []);

	const onBackToTop = () => {
		scrollRef.current?.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<Box
			className="gym-panel"
			id={`gym-${tabId}-panel-scroll`}
			ref={scrollRef}
		>
			<GymFilter tabId={tabId} />
			<GymQuestions tabId={tabId} />

			<Box
				className="back-to-top"
				onClick={onBackToTop}
				style={{
					opacity: showBackToTop ? 1 : 0,
				}}
			>
				<FiArrowUp size={20} />
				Back to top
			</Box>
		</Box>
	);
};
