import Modal from '@mui/joy/Modal';
import React, { useRef, useState } from 'react';
import { AiOutlineReload } from 'react-icons/ai';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import AccioButton from '../../elements/Button';
import { services } from '../../../apis';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../../redux/store';
export const ResumeFeedbackModal = ({
	state,
	setState,
}: any) => {
	const { performanceData } = useAppSelector(
		(state) => state.profile
	);

	const [resumeLink, setResumeLink] =
		React.useState<string>(
			performanceData.data?.resumeDetails?.resumeLink ?? ''
		);
	const [uploading, setUploading] = useState(false);
	const [fileName, setFileName] = useState<string | null>(
		null
	);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const handleButtonClick = () => {
		setFileName('');
		fileInputRef.current?.click(); // Trigger the hidden file input
	};

	const handleFileChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = event.target.files;
		if (files && files.length > 0) {
			setFileName(files[0].name);
			uploadResume(files[0], files[0].name);
		}
	};

	const uploadResume = async (file: File, name: string) => {
		try {
			setUploading(true);
			const { signedUrl, publicUrl } =
				await services.userService.getResumeSignedUrl(name);

			await services.fileUploadDownloadService.uploadFile(
				signedUrl,
				file,
				'application/pdf'
			);

			setResumeLink(publicUrl);

			await services.userService.saveResume(publicUrl);

			setUploading(false);
			toast.success('Resume uploaded successfully');
		} catch (error) {
			setUploading(false);
			console.log(error);
			toast.error('Resume Could Not Be Uploaded!');
		}
	};

	if (performanceData.data) {
		const { resumeDetails } = performanceData.data;
		if (resumeDetails) {
			return (
				<div>
					<Modal
						open={state}
						onClose={() => setState(false)}
						className="resume-modal-container"
					>
						<div className="resume-modal-wrapper">
							<div className="header-wrapper">
								<div className="heading-flex">
									<HiOutlineDocumentReport
										style={{ fontSize: '24px' }}
									/>
									<p className="heading">Resume Feedback</p>
								</div>
								{/* <p className="desc">
								Lorem ipsum dolor sit amet consectetur.
							</p> */}
							</div>
							<div className="grey-wrapper">
								<div className="white-div">
									<p className="heading">Upload Resume</p>
									<div className="file-container">
										<HiOutlineDocumentReport
											className="file-icon"
											style={{
												color: '#2666BE',
												fontSize: '24px',
											}}
										/>

										{fileName ? (
											<span className="file-name">
												{fileName}
											</span>
										) : resumeDetails.resumeLink ? (
											<span
												className="file-name click-able"
												onClick={() => {
													window.open(
														resumeDetails.resumeLink ?? '',
														'_blank'
													);
												}}
											>
												{resumeDetails.resumeLink.slice(
													0,
													79
												) + '...'}
											</span>
										) : (
											<span className="file-name">
												Choose a file
											</span>
										)}

										<AccioButton
											action={handleButtonClick}
											title={
												uploading
													? 'Uploading...'
													: 'Upload Resume File'
											}
											disabled={uploading}
										/>
										<input
											type="file"
											ref={fileInputRef}
											accept="application/pdf"
											onChange={handleFileChange}
											style={{ display: 'none' }}
										/>
									</div>
									<p className="heading">
										Verification Status
									</p>
									{resumeDetails.isResumeVerified ? (
										<div className="chip">
											<AiOutlineReload
												style={{ color: '#10b981' }}
											/>
											<p className="text">
												Successfully Verified
											</p>
										</div>
									) : (
										<div className="chip chip-yellow">
											<AiOutlineReload
												style={{ color: '#fac515' }}
											/>
											<p className="text">
												Verification Pending
											</p>
										</div>
									)}
									<p className="heading">Feedback</p>
									{resumeDetails.feedback ? (
										<p className="list-item">
											{resumeDetails.feedback}
										</p>
									) : (
										<p className="list-item">No Feedback</p>
									)}
									{/* <ul className="list">
									{[1, 2, 3, 4].map((item, i) => (
										<li
											key={i}
											className="list-item"
										>
											Lorem ipsum dolor sit amet
											consectetur.{' '}
										</li>
									))}
								</ul> */}
								</div>
							</div>
						</div>
					</Modal>
				</div>
			);
		} else return null;
	} else return <></>;
};
