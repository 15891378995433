import React, { useEffect, useState } from 'react';
import TopBar from '../../components/aiStudentAssessment/TopBar';
import CodeMirror from '@uiw/react-codemirror';
import { sublime } from '@uiw/codemirror-theme-sublime';
import { Grid } from '@mui/material';
import {
	useAppDispatch,
	useAppSelector,
} from '../../redux/store';
import {
	getNextQuestion,
	startAssessment,
	submitUserResponse,
} from '../../redux/aiStudentAssessment/aiStudentAssessment.thunk';
import StartAssessmentModal from '../../components/aiStudentAssessment/StartAssessmentModal';
import {
	QuestionTypeEnum,
	UserResponseInterface,
} from '../../types/aiStudentAssessment.types';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import AccioButton from '../../components/elements/Button';
import { useTimer } from 'react-timer-hook';
import { Loader } from '@acciojob/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import TestCompletedModal from '../../components/aiStudentAssessment/TestCompletedModal';
import queryString from 'query-string';

const timeExpireMsg =
	'User has crossed the time limit for this question , please proceed to next question assuming user was not able to solve it on time';
const skipMsg =
	'User has skipped this question , please proceed to next question assuming user was not able to solve it mark this question as unattempted';

interface OptionInterface {
	optionStatement: string;
}

const AiStudentAssessment = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const queryParams = queryString.parse(location.search);

	useEffect(() => {
		if (typeof queryParams.assessmentId === 'string') {
			handleStartAssessment(queryParams.assessmentId);
		}
		console.log({ assessmentId: queryParams.assessmentId });
	}, []);

	const {
		assessmentId,
		testStarted,
		questionStatement,
		questionType,
		options,
		expectedTimeToSolve,
		loading,
		isTestCompleted,
	} = useAppSelector((state) => state.aiStudentAssessment);

	const [userResponse, setUserResponse] = useState('');

	const [isStartModal, setIsStartModal] = useState(true);

	const [questionAllowedTime, setQuestionAllowedTime] =
		useState<Date | null>(null);

	const [recordTime, setRecordTime] = useState<number>(0);

	const recordTimeTaken = (time: number) => {
		if (expectedTimeToSolve)
			setRecordTime(expectedTimeToSolve - time);
		else setRecordTime(0);
	};

	const exitTest = () => {
		navigate('/');
		setIsStartModal(false);
	};

	const handleStartAssessment = async (testId?: string) => {
		await dispatch(startAssessment(testId));
	};

	useEffect(() => {
		if (assessmentId && !testStarted) {
			dispatch(getNextQuestion(assessmentId as string));
		}
	}, [assessmentId]);

	useEffect(() => {
		if (questionStatement && expectedTimeToSolve) {
			setUserResponse('');
			const time = new Date();
			time.setSeconds(
				time.getSeconds() + expectedTimeToSolve
			);
			setQuestionAllowedTime(time);
			setRecordTime(0);
		}
	}, [questionStatement]);

	const submitUserResponseAndFetchNextQuestion = async (
		customResponse: string | undefined = undefined
	) => {
		const userResponseBody: UserResponseInterface = {
			userResponse: userResponse.length
				? userResponse
				: customResponse ?? skipMsg,
			metaData: {
				assessmentId: assessmentId,
				timeTakenByStudentToSolveThisQuestionInSeconds:
					recordTime,
			},
			studentAssessmentTestId: assessmentId as string,
		};
		await dispatch(submitUserResponse(userResponseBody));
		dispatch(getNextQuestion(assessmentId as string));
	};

	return (
		<div className="ai-student-assessment">
			<TestCompletedModal />
			<StartAssessmentModal
				open={!testStarted && isStartModal}
				handleClose={exitTest}
				startTest={() => handleStartAssessment()}
			/>
			<TopBar
				onExpire={() =>
					submitUserResponseAndFetchNextQuestion(
						timeExpireMsg
					)
				}
				submitTest={() =>
					submitUserResponseAndFetchNextQuestion('SUBMIT')
				}
				expiryTimestamp={questionAllowedTime as Date}
				timeTaken={recordTimeTaken}
			/>
			{!loading ? (
				<Grid
					container
					className="assessment-container"
				>
					<Grid
						item
						xs={12}
						sm={8}
						md={8}
						lg={8}
						className="question"
					>
						<h3>
							Question{' '}
							<span className="question-type-chip">
								{questionType}
							</span>
						</h3>
						<p className="question-text">
							{questionStatement}
						</p>
					</Grid>
					<Grid
						item
						xs={12}
						sm={4}
						md={4}
						lg={4}
						className="response"
					>
						<h4>Mark/Write Your Response</h4>

						<div className="user-response-options">
							{questionType ===
							QuestionTypeEnum.SUBJECTIVE ? (
								<TextareaAutosize
									className="response-textarea"
									minRows={10}
									value={userResponse}
									onChange={(e) =>
										setUserResponse(e.target.value)
									}
									onPaste={(e) => {
										e.preventDefault();
										return false;
									}}
								/>
							) : null}
							{questionType ===
								QuestionTypeEnum.SINGLE_SELECT &&
							options ? (
								<ul className="response-options">
									{options.map((option: any) => (
										<li
											className={`${
												userResponse ===
												option.optionStatement
													? 'selected-res'
													: ''
											}`}
											key={option.optionStatement}
											onClick={() =>
												setUserResponse(
													option.optionStatement
												)
											}
										>
											{option.optionStatement}
										</li>
									))}
								</ul>
							) : null}
							{questionType ===
							QuestionTypeEnum.PSEUDO_CODE ? (
								<CodeMirror
									className="response-code"
									value={userResponse}
									height="100%"
									onChange={setUserResponse}
									theme={sublime}
									onPasteCapture={(e) => {
										e.preventDefault();
										return false;
									}}
								/>
							) : null}
						</div>
						<AccioButton
							action={
								submitUserResponseAndFetchNextQuestion
							}
							title="Confirm and Continue"
						/>
					</Grid>
				</Grid>
			) : (
				<Loader />
			)}
		</div>
	);
};

export default AiStudentAssessment;
