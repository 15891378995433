import React, { useEffect, useState } from 'react';
import {
	BiExitFullscreen,
	BiFullscreen,
} from 'react-icons/bi';
import {
	BsFillPlayFill,
	BsFillVolumeMuteFill,
	BsFillVolumeUpFill,
	BsPauseFill,
	BsVolumeDownFill,
} from 'react-icons/bs';
import { engagements } from '../engagements';
import { useSelector } from 'react-redux';

interface Props {
	src: string;
	id: string;
	name: string;
}

const VideoPlayer = ({ src, id, name }: Props) => {
	const CourseState = useSelector(
		(state: any) => state.course
	);
	const { courseName } = CourseState;

	const ModuleState = useSelector(
		(state: any) => state.module
	);
	const { moduleActive } = ModuleState;

	const [startWatching, setStartWatching] = useState(false);

	useEffect(() => {
		const playPauseBtn = document.querySelector(
			'.play-pause-btn'
		);
		const fullScreenBtn = document.querySelector(
			'.full-screen-btn'
		);
		const miniPlayerBtn = document.querySelector(
			'.mini-player-btn'
		);
		const muteBtn = document.querySelector('.mute-btn');
		const speedBtn = document.querySelector('.speed-btn');
		const currentTimeElem =
			document.querySelector('.current-time');
		const totalTimeElem =
			document.querySelector('.total-time');
		const volumeSlider: HTMLInputElement | null =
			document.querySelector('.volume-slider');
		const videoContainer: HTMLDivElement | null =
			document.querySelector('.video-container');
		const timelineContainer: HTMLDivElement | null =
			document.querySelector('.timeline-container');
		const video = document.querySelector('video');
		const loaderContainer =
			document.querySelector('.video-loader');
		const replay5 = document.querySelector('.replay');
		const forward5 = document.querySelector('.forward');
		const videoStatusPlay = document.querySelector(
			'.video-status-play'
		);
		const videoStatusPause = document.querySelector(
			'.video-status-pause'
		);

		if (
			!playPauseBtn ||
			!fullScreenBtn ||
			!miniPlayerBtn ||
			!muteBtn ||
			!speedBtn ||
			!currentTimeElem ||
			!totalTimeElem ||
			!volumeSlider ||
			!videoContainer ||
			!timelineContainer ||
			!video ||
			!replay5 ||
			!forward5
		)
			return;

		function skip(duration: number) {
			if (!video) return;
			video.currentTime += duration;
		}

		function replay() {
			skip(-5);
		}

		function forward() {
			skip(5);
		}

		replay5.addEventListener('click', replay);
		forward5.addEventListener('click', forward);

		const onKeyDown = (e: KeyboardEvent) => {
			if (document.activeElement) {
				const tagName =
					document.activeElement.tagName.toLowerCase();
				if (tagName === 'input') return;
			}

			switch (e.key.toLowerCase()) {
				case ' ':
					// if (tagName === 'button') return;
					e.preventDefault();
					togglePlay();
					break;
				case 'f':
					toggleFullScreenMode();
					break;
				case 'i':
					toggleMiniPlayerMode();
					break;
				case 'm':
					toggleMute();
					break;
				case 'arrowleft':
					skip(-5);
					break;
				case 'arrowright':
					skip(5);
					break;
				default:
					break;
			}
		};

		document.addEventListener('keydown', onKeyDown);

		// Timeline
		let isScrubbing = false;
		let wasPaused: boolean;

		const toggleScrubbing = (e: MouseEvent) => {
			if (!timelineContainer || !video || !videoContainer)
				return;
			const rect =
				timelineContainer.getBoundingClientRect();
			const percent =
				Math.min(Math.max(0, e.x - rect.x), rect.width) /
				rect.width;
			isScrubbing = (e.buttons & 1) === 1; // only left mouse click
			videoContainer.classList.toggle(
				'scrubbing',
				isScrubbing
			);

			handleTimelineUpdate(e);

			if (isScrubbing) {
				wasPaused = video.paused;
				video.pause();
			} else {
				video.currentTime = percent * video.duration;
				if (!wasPaused) video.play();
			}
		};

		const handleTimelineUpdate = (e: MouseEvent) => {
			if (!timelineContainer) return;
			const rect =
				timelineContainer.getBoundingClientRect();
			const percent =
				Math.min(Math.max(0, e.x - rect.x), rect.width) /
				rect.width;

			timelineContainer.style.setProperty(
				'--preview-position',
				percent.toString()
			);

			if (isScrubbing) {
				e.preventDefault(); // no highlighting
				timelineContainer.style.setProperty(
					'--progress-position',
					percent.toString()
				);
			}
		};

		const onDocumentMouseUp = (e: MouseEvent) => {
			if (isScrubbing) toggleScrubbing(e);
		};

		const onDocumentMouseMove = (e: MouseEvent) => {
			if (isScrubbing) handleTimelineUpdate(e);
		};

		timelineContainer.addEventListener(
			'mousemove',
			handleTimelineUpdate
		);
		timelineContainer.addEventListener(
			'mousedown',
			toggleScrubbing
		);

		document.addEventListener('mouseup', onDocumentMouseUp);
		document.addEventListener(
			'mousemove',
			onDocumentMouseMove
		);

		// Playback Speed
		speedBtn.addEventListener('click', changePlaybackSpeed);

		function changePlaybackSpeed() {
			if (!video || !speedBtn) return;

			let newPlaybackRate = video.playbackRate + 0.25;
			if (newPlaybackRate > 2) newPlaybackRate = 0.25;
			video.playbackRate = newPlaybackRate;
			speedBtn.textContent = `${newPlaybackRate}x`;
		}

		// Duration
		video.addEventListener('loadeddata', onVideoLoad);
		video.addEventListener('timeupdate', onVideoTimeUpdate);

		function onVideoLoad() {
			if (!totalTimeElem || !loaderContainer || !video)
				return;
			loaderContainer.classList.add('hide');
			totalTimeElem.textContent = formatDuration(
				video.duration
			);
		}

		function onVideoTimeUpdate() {
			if (!currentTimeElem || !video || !timelineContainer)
				return;
			currentTimeElem.textContent = formatDuration(
				video.currentTime
			);
			const percent = video.currentTime / video.duration;
			timelineContainer.style.setProperty(
				'--progress-position',
				percent.toString()
			);
		}

		const leadingZeroFormatter = new Intl.NumberFormat(
			undefined,
			{
				minimumIntegerDigits: 2,
			}
		);

		// 4 -> 04

		function formatDuration(time: number) {
			const seconds = Math.floor(time % 60);
			const minutes = Math.floor(time / 60) % 60;
			const hours = Math.floor(time / 3600);
			if (hours === 0) {
				return `${minutes}:${leadingZeroFormatter.format(
					seconds
				)}`;
			} else {
				return `${hours}:${leadingZeroFormatter.format(
					minutes
				)}:${leadingZeroFormatter.format(seconds)}`;
			}
		}

		// Volume
		muteBtn.addEventListener('click', toggleMute);
		volumeSlider.addEventListener(
			'input',
			handleVolumeSliderChange
		);

		function handleVolumeSliderChange() {
			if (!video || !volumeSlider || !videoContainer)
				return;

			video.volume = +volumeSlider.value;
			video.muted = volumeSlider.value === '0';

			volumeSlider.value = video.volume.toString();
			let volumeLevel;
			if (video.muted || video.volume === 0) {
				volumeSlider.value = '0';
				volumeLevel = 'muted';
			} else if (video.volume >= 0.5) {
				volumeLevel = 'high';
			} else {
				volumeLevel = 'low';
			}

			videoContainer.dataset.volumeLevel = volumeLevel;
		}

		function toggleMute() {
			if (!video || !volumeSlider || !videoContainer)
				return;
			video.muted = !video.muted;
			if (video.muted) {
				volumeSlider.value = '0';
				videoContainer.dataset.volumeLevel = 'muted';
			} else {
				video.volume = 0.6;
				volumeSlider.value = '0.6';
				videoContainer.dataset.volumeLevel = 'high';
			}
		}

		// VIEW MODES
		fullScreenBtn.addEventListener(
			'click',
			toggleFullScreenMode
		);
		miniPlayerBtn.addEventListener(
			'click',
			toggleMiniPlayerMode
		);

		// full screen
		document.addEventListener(
			'fullscreenchange',
			onFullScreenChange
		);
		videoContainer.addEventListener(
			'dblclick',
			toggleFullScreenMode
		);

		function toggleFullScreenMode() {
			if (!videoContainer) return;

			if (document.fullscreenElement == null) {
				videoContainer.requestFullscreen();
			} else {
				document.exitFullscreen();
			}
		}

		function onFullScreenChange() {
			if (!videoContainer) return;

			videoContainer.classList.toggle(
				'full-screen',
				document.fullscreenElement !== null
			);
		}

		// full screen end

		// mini player
		video.addEventListener(
			'enterpictureinpicture',
			onEnterPicInPic
		);
		video.addEventListener(
			'leavepictureinpicture',
			onLeavePicInPic
		);

		function toggleMiniPlayerMode() {
			if (!video || !videoContainer) return;

			if (
				videoContainer.classList.contains('mini-player')
			) {
				document.exitPictureInPicture();
			} else {
				video.requestPictureInPicture();
			}
		}

		function onEnterPicInPic() {
			if (!videoContainer) return;
			videoContainer.classList.add('mini-player');
		}

		function onLeavePicInPic() {
			if (!videoContainer) return;
			videoContainer.classList.remove('mini-player');
		}

		// mini player end

		// PLAY/PAUSE
		playPauseBtn.addEventListener('click', togglePlay);
		video.addEventListener('click', togglePlay);
		video.addEventListener('play', onVideoPlay);
		video.addEventListener('pause', onVideoPause);

		const playVideo = () => {
			video.play();
			if (!startWatching) {
				setStartWatching(true);
				console.log(
					name,
					id,
					courseName,
					moduleActive.name
				);
				engagements.courseEngagement.watchRecording(
					name,
					id,
					courseName,
					moduleActive.name
				);
			}
		};

		function togglePlay() {
			if (!video) return;
			video.paused ? playVideo() : video.pause();
		}

		function displayVideoStatus(status: string) {
			if (!status) return;

			// video status
			const el =
				status === 'play'
					? videoStatusPlay
					: videoStatusPause;
			if (!el) return;

			el.classList.remove('hide');
			setTimeout(() => {
				el.classList.add('enlarged');
			}, 30);
			setTimeout(() => {
				el.classList.remove('enlarged');
				el.classList.add('hide');
			}, 450);
		}

		function onVideoPlay() {
			if (!videoContainer) return;
			videoContainer.classList.remove('paused');
			displayVideoStatus('play');
			// engagements.courseEngagement.watchRecording();
		}

		function onVideoPause() {
			if (!videoContainer) return;
			videoContainer.classList.add('paused');
			displayVideoStatus('pause');
		}

		return () => {
			// remove all event listeners
			replay5.removeEventListener('click', replay);
			forward5.removeEventListener('click', forward);
			document.removeEventListener('keydown', onKeyDown);

			// Timeline
			timelineContainer.removeEventListener(
				'mousemove',
				handleTimelineUpdate
			);
			timelineContainer.removeEventListener(
				'mousedown',
				toggleScrubbing
			);

			document.removeEventListener(
				'mouseup',
				onDocumentMouseUp
			);
			document.removeEventListener(
				'mousemove',
				onDocumentMouseMove
			);

			// Playback Speed
			speedBtn.removeEventListener(
				'click',
				changePlaybackSpeed
			);

			// Duration
			video.removeEventListener('loadeddata', onVideoLoad);
			video.removeEventListener(
				'timeupdate',
				onVideoTimeUpdate
			);

			// Volume
			muteBtn.removeEventListener('click', toggleMute);
			volumeSlider.removeEventListener(
				'input',
				handleVolumeSliderChange
			);

			// VIEW MODES
			fullScreenBtn.removeEventListener(
				'click',
				toggleFullScreenMode
			);
			miniPlayerBtn.removeEventListener(
				'click',
				toggleMiniPlayerMode
			);

			// full screen
			videoContainer.removeEventListener(
				'dblclick',
				toggleFullScreenMode
			);
			document.removeEventListener(
				'fullscreenchange',
				onFullScreenChange
			);

			// mini player
			video.removeEventListener(
				'enterpictureinpicture',
				onEnterPicInPic
			);
			video.removeEventListener(
				'leavepictureinpicture',
				onLeavePicInPic
			);

			// PLAY/PAUSE
			playPauseBtn.removeEventListener('click', togglePlay);
			video.removeEventListener('click', togglePlay);
			video.removeEventListener('play', onVideoPlay);
			video.removeEventListener('pause', onVideoPause);
		};
	}, [src]);

	return (
		<div
			className="video-container paused"
			data-volume-level="high"
			key={src}
		>
			<div className="video-loader">
				<div className="loading"></div>
			</div>

			<div className="video-status video-status-play hide">
				<BsFillPlayFill
					className="play-status-icon"
					style={{ height: 35, width: 35 }}
				/>
			</div>

			<div className="video-status video-status-pause hide">
				<BsPauseFill
					className="pause-status-icon"
					style={{ height: 35, width: 35 }}
				/>
			</div>

			<div className="video-controls-container">
				<div className="timeline-container">
					<div className="timeline">
						<div className="thumb-indicator"></div>
					</div>
				</div>
				<div className="controls">
					<button className="play-pause-btn">
						<BsFillPlayFill
							className="play-icon"
							style={{ height: 30, width: 30 }}
						/>
						<BsPauseFill
							className="pause-icon"
							style={{ height: 30, width: 30 }}
						/>
					</button>

					<div className="volume-container">
						<button className="mute-btn">
							<BsFillVolumeUpFill
								className="volume-high-icon"
								style={{ height: 30, width: 27 }}
							/>
							<BsVolumeDownFill
								className="volume-low-icon"
								style={{ height: 30, width: 30 }}
							/>
							<BsFillVolumeMuteFill
								className="volume-muted-icon"
								style={{ height: 30, width: 30 }}
							/>
						</button>
						<input
							className="volume-slider"
							type="range"
							min="0"
							max="1"
							step="any"
							value="1"
						/>
					</div>

					<div className="skip-icons-container">
						<button className="replay">
							<svg
								width="24"
								height="26"
								viewBox="0 0 24 29"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M1.99999 17C1.99999 18.9778 2.58648 20.9112 3.6853 22.5557C4.78411 24.2002 6.3459 25.4819 8.17316 26.2388C10.0004 26.9957 12.0111 27.1937 13.9509 26.8079C15.8907 26.422 17.6725 25.4696 19.0711 24.0711C20.4696 22.6725 21.422 20.8907 21.8078 18.9509C22.1937 17.0111 21.9957 15.0004 21.2388 13.1732C20.4819 11.3459 19.2002 9.78412 17.5557 8.6853C15.9112 7.58649 13.9778 7 12 7H7.99999V12L1.99999 6L7.99999 0V5H12C14.3734 5 16.6934 5.70379 18.6668 7.02236C20.6402 8.34094 22.1783 10.2151 23.0865 12.4078C23.9948 14.6005 24.2324 17.0133 23.7694 19.3411C23.3064 21.6689 22.1635 23.8071 20.4853 25.4853C18.807 27.1635 16.6688 28.3064 14.3411 28.7694C12.0133 29.2324 9.60051 28.9948 7.40779 28.0866C5.21508 27.1783 3.34094 25.6402 2.02236 23.6668C0.703783 21.6935 -5.72205e-06 19.3734 -5.72205e-06 17H1.99999Z"
									fill="white"
								/>
								<path
									d="M14.5802 14.58H11.1302L11.0002 17.15C11.0788 16.9954 11.1656 16.8452 11.2602 16.7C11.3518 16.5673 11.4631 16.4493 11.5902 16.35C11.7234 16.2499 11.872 16.1722 12.0302 16.12C12.225 16.063 12.4273 16.0361 12.6302 16.04C12.9442 16.036 13.2561 16.0903 13.5502 16.2C13.8287 16.3047 14.0811 16.4684 14.2902 16.68C14.5074 16.9001 14.6775 17.1621 14.7902 17.45C14.9123 17.7691 14.9733 18.1083 14.9702 18.45C14.9756 18.8155 14.9111 19.1787 14.7802 19.52C14.6593 19.8362 14.4717 20.1227 14.2302 20.36C13.9768 20.6055 13.6732 20.7932 13.3402 20.91C12.9534 21.0533 12.5426 21.1212 12.1302 21.11C11.8135 21.1128 11.4977 21.0758 11.1902 21C10.9303 20.9271 10.6814 20.8195 10.4502 20.68C10.246 20.5575 10.0607 20.4059 9.90023 20.23C9.74855 20.0584 9.61141 19.8744 9.49023 19.68L10.5502 18.87L10.8202 19.28C10.918 19.4079 11.0324 19.5222 11.1602 19.62C11.2915 19.7148 11.4365 19.789 11.5902 19.84C11.7671 19.9002 11.9535 19.9274 12.1402 19.92C12.3229 19.9345 12.5066 19.9098 12.679 19.8478C12.8515 19.7857 13.0087 19.6876 13.1402 19.56C13.3753 19.2818 13.4935 18.9234 13.4702 18.56V18.5C13.484 18.3289 13.4602 18.1568 13.4006 17.9958C13.341 17.8348 13.247 17.6887 13.1251 17.5678C13.0033 17.4468 12.8565 17.354 12.695 17.2956C12.5335 17.2373 12.3613 17.2149 12.1902 17.23C11.9218 17.217 11.6551 17.2793 11.4202 17.41C11.2404 17.5135 11.0783 17.6452 10.9402 17.8L9.75023 17.63L10.0402 13.32H14.5602L14.5802 14.58Z"
									fill="white"
								/>
							</svg>
						</button>
						<button className="forward">
							<svg
								width="24"
								height="26"
								viewBox="0 0 24 29"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M22 17C22 18.9778 21.4135 20.9112 20.3147 22.5557C19.2159 24.2002 17.6541 25.4819 15.8268 26.2388C13.9996 26.9957 11.9889 27.1937 10.0491 26.8079C8.10929 26.422 6.32746 25.4696 4.92894 24.0711C3.53041 22.6725 2.578 20.8907 2.19215 18.9509C1.8063 17.0111 2.00433 15.0004 2.76121 13.1732C3.51809 11.3459 4.79981 9.78412 6.4443 8.6853C8.0888 7.58649 10.0222 7 12 7H16V12L22 6L16 0V5H12C9.62663 5 7.30655 5.70379 5.33316 7.02236C3.35977 8.34094 1.8217 10.2151 0.913451 12.4078C0.00519943 14.6005 -0.232441 17.0133 0.230582 19.3411C0.693605 21.6689 1.83649 23.8071 3.51472 25.4853C5.19295 27.1635 7.33115 28.3064 9.65892 28.7694C11.9867 29.2324 14.3995 28.9948 16.5922 28.0866C18.7849 27.1783 20.6591 25.6402 21.9776 23.6668C23.2962 21.6935 24 19.3734 24 17H22Z"
									fill="white"
								/>
								<path
									d="M14.5802 14.58H11.1302L11.0002 17.15C11.0788 16.9954 11.1656 16.8452 11.2602 16.7C11.3518 16.5673 11.4631 16.4493 11.5902 16.35C11.7234 16.2499 11.872 16.1722 12.0302 16.12C12.225 16.063 12.4273 16.0361 12.6302 16.04C12.9442 16.036 13.2561 16.0903 13.5502 16.2C13.8287 16.3047 14.0811 16.4684 14.2902 16.68C14.5074 16.9001 14.6775 17.1621 14.7902 17.45C14.9123 17.7691 14.9733 18.1083 14.9702 18.45C14.9756 18.8155 14.9111 19.1787 14.7802 19.52C14.6593 19.8362 14.4717 20.1227 14.2302 20.36C13.9768 20.6055 13.6732 20.7932 13.3402 20.91C12.9534 21.0533 12.5426 21.1212 12.1302 21.11C11.8135 21.1128 11.4977 21.0758 11.1902 21C10.9303 20.9271 10.6814 20.8195 10.4502 20.68C10.246 20.5575 10.0607 20.4059 9.90023 20.23C9.74855 20.0584 9.61141 19.8744 9.49023 19.68L10.5502 18.87L10.8202 19.28C10.918 19.4079 11.0324 19.5222 11.1602 19.62C11.2915 19.7148 11.4365 19.789 11.5902 19.84C11.7671 19.9002 11.9535 19.9274 12.1402 19.92C12.3229 19.9345 12.5066 19.9098 12.679 19.8478C12.8515 19.7857 13.0087 19.6876 13.1402 19.56C13.3753 19.2818 13.4935 18.9234 13.4702 18.56V18.5C13.484 18.3289 13.4602 18.1568 13.4006 17.9958C13.341 17.8348 13.247 17.6887 13.1251 17.5678C13.0033 17.4468 12.8565 17.354 12.695 17.2956C12.5335 17.2373 12.3613 17.2149 12.1902 17.23C11.9218 17.217 11.6551 17.2793 11.4202 17.41C11.2404 17.5135 11.0783 17.6452 10.9402 17.8L9.75023 17.63L10.0402 13.32H14.5602L14.5802 14.58Z"
									fill="white"
								/>
							</svg>
						</button>
					</div>

					<div className="duration-container">
						<div className="current-time">0:00</div>/
						<div className="total-time">0:00</div>
					</div>

					<button className="speed-btn wide-btn">1x</button>

					<button className="mini-player-btn">
						<svg viewBox="0 0 24 24">
							<path
								fill="currentColor"
								d="M21 3H3c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h18c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H3V5h18v14zm-10-7h9v6h-9z"
							/>
						</svg>
					</button>
					<button className="full-screen-btn">
						<BiFullscreen
							className="open-full"
							style={{ height: 30, width: 30 }}
						/>
						<BiExitFullscreen
							className="close-full"
							style={{ height: 30, width: 30 }}
						/>
					</button>
				</div>
			</div>
			<video>
				<source
					src={src}
					type="video/mp4"
				/>
			</video>
		</div>
	);
};

export default VideoPlayer;
