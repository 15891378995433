import {
	HttpService,
	apiFailureErrorMessage,
} from '../utils';

export class DAPlaygroundService {
	constructor(
		private httpService: HttpService,
		private endpoint: string
	) {}

	async getDAPlaygroundModuleCompletionStats() {
		const url = `${this.endpoint}/module/da-playground/module-completion-stats`;
		try {
			const { data } = await this.httpService.get<any>(url);
			return data;
		} catch (error) {
			throw new Error(apiFailureErrorMessage);
		}
	}
}
