import { createSlice } from '@reduxjs/toolkit';
import { fetchBMData } from './contactBM.thunk';
import { ContactBMInterface } from './contactBM.type';

const initialState: ContactBMInterface = {
	loading: false,
	data: null,
};
export const ContactBMSlice = createSlice({
	name: 'contactBM',
	initialState,
	reducers: {},
	extraReducers: {
		[fetchBMData.pending.type]: (
			state,
			action: ReturnType<typeof fetchBMData.pending>
		) => {
			state.loading = true;
		},
		[fetchBMData.fulfilled.type]: (
			state,
			action: ReturnType<typeof fetchBMData.fulfilled>
		) => {
			state.data = action.payload;
			state.loading = false;
		},
		[fetchBMData.rejected.type]: (
			state,
			action: ReturnType<typeof fetchBMData.rejected>
		) => {
			state.loading = false;
		},
	},
});

export const contactBMActions = ContactBMSlice.actions;
export const contactBMReducer = ContactBMSlice.reducer;
