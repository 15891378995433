import { Box, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { services } from '../../../../apis';
import { notifyError } from '../../../../components/notification';

function FinaliseQnsModal({
	setQnsFinalised,
	openRandomQns,
	handleCloseRandomQns,
	joinNow,
	sessionId,
}: any) {
	const [newQns, setNewQns] = useState<any>(null);

	const getRecommendedQns = async () => {
		// const response = await services.mentorService.fetchRecommendedQuestions('1391a267-4cea-4cb3-a0ad-1442e20d617d')
		const response =
			await services.mentorService.fetchRecommendedQuestions(
				sessionId
			);
		if (response && response.statusCode)
			notifyError(response.message);
		else {
			if (response.length === 0) {
				joinNow();
				handleCloseRandomQns();
			} else setNewQns(response);
		}
	};

	useEffect(() => {
		getRecommendedQns();
	}, []);

	const finaliseQns = async () => {
		let body = {};
		newQns.map((obj: any) => {
			let temp: string[] = [];
			Object.keys(obj).forEach((key: any) => {
				temp.push(obj[key]);
			});
			body = { ...body, ...{ [temp[0]]: temp[1] } };
		});
		const response =
			await services.mentorService.finaliseQuestions(
				sessionId,
				body
			);
		if (response && response.statusCode)
			notifyError(response.massage);
		else {
			joinNow();
			handleCloseRandomQns();
			setQnsFinalised(true);
		}
	};

	const getNewQuestion = async (section: string) => {
		let body: any = [];
		newQns.map((obj: any) => {
			body.push(obj.question);
		});
		const data =
			await services.mentorService.getNewQuestion(
				section,
				sessionId,
				{ currentSelectedQuestions: body }
			);
		// const data = await services.mentorService.getNewQuestion(section, '1391a267-4cea-4cb3-a0ad-1442e20d617d', {"currentSelectedQuestions": body})
		if (data && data.statusCode) {
			notifyError(data.message);
		} else {
			const recommendedQuestion = data.recommendedQuestion;
			const temp: any = [];
			newQns.map((obj: any) => {
				if (obj.section === section) {
					temp.push({
						section: section,
						question: recommendedQuestion,
					});
				} else temp.push(obj);
			});
			setNewQns([...temp]);
		}
	};

	return (
		<Dialog
			open={openRandomQns}
			// open={true}
			onClose={handleCloseRandomQns}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			sx={{}}
		>
			<Box sx={{ minWidth: '500px', padding: '1em' }}>
				<h3 className="recommended-qns-title">
					Recommended Questions
				</h3>
				<div className="questions-container">
					{newQns &&
						newQns.map(
							(questionObj: any, index: number) => (
								<div className="question-container">
									<div className="question-section">
										<b>{questionObj.section}</b>
									</div>
									<div className="question-text">
										{questionObj.question}
									</div>
									<div className="refresh-icon">
										<FiRefreshCw
											onClick={() =>
												getNewQuestion(questionObj.section)
											}
											color="black"
										/>
									</div>
								</div>
							)
						)}
				</div>
				<div className="confirm-qns-btns">
					<button onClick={getRecommendedQns}>
						Refresh
					</button>
					<button onClick={finaliseQns}>
						Confirm Qns & Join
					</button>
				</div>
			</Box>
		</Dialog>
	);
}

export default FinaliseQnsModal;
